import React from "react";
import '../../css/regist.css'
import {  $mzlApi, $userApi, notify } from '../comm/mzl-core'
import { Button, Form, Input, Radio } from 'antd';
import { md5ToLower, messageInfo, log,showLoadding, hideLoadding } from "../comm/utils";
import StorageTools from "../comm/storageTools";
import {settingAuthority} from "../comm/chainAuthoritySetting";
import APINames from "../comm/api-names"
import { mzlPhone, noCheck } from '../comm/constans';
import { messageTopError, showMessageTopError } from "../comm/api";
import LoadingDialog from "../components/loadingDialog";
import { IModal } from '../components/iModal'
import IButton from "../components/IButton";

let timer = null;
let   chainSetting = [],
      shopSetting = [],
      reportSetting = [];
class register extends React.Component {
    state = {
        confirmDirty: false,
        btnText: '获取验证码',
        time: 60,
        autoCompleteResult: [],
        data: { checkPhone: '' },
        disabled: false,
        isSetting: false,
        isRegister: true,
        isRegisted: false,//是否注册过
        loading: false,
        visible: false,
        userCode: '',
        identyList: [],
        isBos: false,
        loginData: {},
        timer: null,
        type: 1,
        MchType: 0,
        registeText: '继续',
        registeDisable:false,
        mchInfoArr:[]
    };
    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    /**
     * 根据手机号查userCode
     */
    requestAPI_getUserCode() {
        var map = new Map();
        map.set('user', this.state.data.account);
        $userApi.post(APINames.API_getUserCode, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }
    /**
     * 根据手机号查userCode
     */
    requestAPI_getUserCode2() {
        var map = new Map();
        map.set('user', this.state.data.account);
        $userApi.post(APINames.API_getUserCode, map, (res)=>{
            StorageTools.saveStoreCode(res.Data.userCode);
            this.setState({userCode:res.Data.userCode},()=>{
                this.requestQueryUserIdentity2()
            })
        }, this.fail.bind(this), noCheck);
    }
    /**
     * 查询用户身份
     */
    requestQueryUserIdentity() {
        var map = new Map();
        map.set('userCode', this.state.userCode);
        map.set('appFlag', 1);
        map.set('type', this.state.type)
        $mzlApi.post(APINames.API_queryUserIdentity, map, this.success.bind(this), this.fail.bind(this), this);
    }

    /**
     * 查店铺信息
     */
    requestConfirmLogin() {
        var map = new Map();
        map.set('shopCode', this.state.loginData.ShopCode);
        map.set('userCode', this.state.userCode);
        map.set('empId', this.state.loginData.AppUid);
        $mzlApi.post(APINames.API_confirmLogin, map, (res) => {
            StorageTools.saveUserChainIdenflty([])
            StorageTools.saveUserInfo(res);
            StorageTools.saveUserCode(res.StoreCode);
            StorageTools.saveStoreName(res.StoreName);
            StorageTools.saveMchId(parseInt(res.Extra.mchId))
            this.setState({ loading: false });
            window.StoreName = res.StoreName;
            if (!notify.appSend("user-login", res)) {
                //console.log("[ App][ Log]: 无法对客户端进行用户登陆通知")
            }
            if (res.StoreName == '') {
                    this.props.history.push('/home/settingContent/shopSetting');
                    messageInfo('请完善店铺信息')

            } else {
                if (res.ErrCode == 0) {
                    if (this.state.type == 1) {
                        this.props.history.push('/home');
                    } else {
                        this.props.history.push('/chain/ChainIndex');
                    }
                }
            }
        }, this.fail.bind(this), this);
    }

    /**
     * 查询用户身份
     */
    requestQueryUserIdentity2() {
        showLoadding()
        var map = new Map();
        map.set('userCode', this.state.userCode);
        map.set('appFlag', this.state.MchType == 1 ? 128 : 1);
        map.set('type', this.state.type)
        $mzlApi.post(APINames.API_queryUserIdentity, map, (res) => {
            if(res.length > 0 && res[0].AppUid){
                StorageTools.saveMchCode(res[0].mchCode);
                if (res.length > 0 && res[0].AppUid) {
                    clearInterval(this.state.timer);
                    if(this.state.type==1){
                        StorageTools.saveMchInfo(res[0])
                        this.setState({ loginData: res[0] }, () => {
                            this.requestConfirmLogin()
                        })
                    }else {
                            StorageTools.saveMchInfo(res[0]);
                            const ss = res[0];
                            let arrChainObj ={};
                            if (ss.IdentityFlag && ss.Extra) {
                                arrChainObj = settingAuthority(ss.IdentityFlag,ss.Extra)
                            }
                            this.props.history.push('/chain/ChainIndex');
                    }
                        
                }
            }else {
                setTimeout(() => {
                    this.requestQueryUserIdentity2()
                }, 1000);  
            }
            
        }, this.fail.bind(this), this);
    }
    /**
     * 注册
     */
    requestRegister() {
        this.setState({registeDisable:true})
        showLoadding()
        var map = new Map();
        map.set('account', this.state.data.account);
        map.set('pwd', md5ToLower(this.state.data.pwd));
        map.set("checkType", "phone");
        map.set('checkCode', this.state.data.checkCode);
        map.set('data', JSON.stringify({ SalesPhone: '', MchType: this.state.MchType }));
        $mzlApi.post(APINames.API_register, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }

    /**
     * 发送验证码
     */
    requestRequestCheck() {
        var map = new Map();
        map.set('sense', 1);
        map.set('account', this.state.data.account);
        map.set('checkType', 'phone');
        $mzlApi.post(APINames.API_requestCheck, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }

    //授权
    requestSetStoreProbation() {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        $mzlApi.post(APINames.API_setStoreProbation, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }

    //验证验证码
    requestVerifyCheck() {
        var map = new Map();
        map.set('sense', 1);
        map.set('account', this.state.data.account);
        map.set('checkType', 'phone');
        map.set('code', this.state.data.checkCode);
        $mzlApi.post(APINames.API_VerifyCheck, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }


   

    success(res, tag) {
        StorageTools.saveUserInfo(res);
        hideLoadding()
        // this.props.history.push('/login');
        // log('接口success', res);
        switch (tag) {
            case APINames.API_register:
                this.setState({
                    isRegister: true,
                    loading:false,
                });
                this.requestAPI_getUserCode2()
                break;
            case APINames.API_requestCheck:
                //console.log('成功', res, tag);
                // clearInterval(timer)
                //     timer = setInterval(() => {
                //     this.state.time--;
                //     this.setState({time: this.state.time, btnText: this.state.time + 's', disabled: true});
                //     if (this.state.time == 0) {
                //         clearInterval(timer);
                //         this.setState({btnText: '重新发送', time: 60, disabled: false})
                //     }
                // }, 1000)
                break;
            case APINames.API_setStoreProbation:
                //console.log('授权', res)
                break;
            case APINames.API_VerifyCheck:
                //console.log('验证成功', res, tag);
                if (res.ErrCode == 0) {
                    this.setState({ isSetting: true })
                }
                break;
            case APINames.API_getUserCode:
                if (res.Data.userCode) {
                    StorageTools.saveStoreCode(res.Data.userCode);
                    this.setState({ userCode: res.Data.userCode }, () => {
                        this.requestQueryUserIdentity();
                    })
                } else {
                    this.setState({ isRegisted: true, loading: false, registeText: '立即注册' })
                }
                break;
            case APINames.API_queryUserIdentity:
                this.setState({loading:false})
                if (res.length) {
                    this.setState({ visible: true, loading: false})
                } else {
                    this.setState({isRegisted:true,registeText:'立即注册'})
                }
                break;
                


        }
    }

    fail(res, tag) {
        this.setState({
            isRegister: true,
            loading: false
        });
        log('fail', res,tag);
        if(tag==APINames.API_register){
            this.setState({registeDisable:false})
        }
        showMessageTopError(res, tag);
    }

    onClickListener(tag) {
        switch (tag) {
            case '确定':
                if (this.state.registeText == '继续') {
                    if (!this.state.data.account) {
                        messageTopError('请输入手机号码')
                    } else {
                        StorageTools.saveloginPhone(this.state.data.account)
                            this.setState({ loading: true });
                            this.requestAPI_getUserCode()
                    }
                } else {
                    if (!this.state.data.checkCode) {
                        messageTopError('请输入验证码')
                        return
                    }
                    if (!this.state.data.pwd) {
                        messageTopError('请输入密码')
                        return
                    }
                    // if (this.state.MchType == 0) {
                    //     if (!this.state.data.checkPhone) {
                    //         messageTopError('请输入邀请码')
                    //         return
                    //     }
                    // }
                    // if (!this.state.MchType) {
                    //     // this.requestGetSalesmanToPhone()
                    // } else {
                        this.setState({loading:true})
                        this.requestRegister();
                    // }
                }
        }
    }

    onChangeListener(tag, e) {
        var data = this.state.data;
        switch (tag) {
            case 'phone':
                data.account = e.target.value;
                break;
            case 'pass':
                data.pwd = e.target.value;
                break;
            case 'code':
                data.checkCode = e.target.value;
                break;
            case 'checkPhone':
                data.checkPhone = e.target.value;
                break;
            case '类型':
                this.setState({ type: e.target.value })
                if (e.target.value == 1) {
                    this.setState({ MchType: 0 })
                } else {
                    this.setState({ MchType: 1 })
                }
        }
        this.setState({ data: data })
    }

    getCode() {
        this.setState({ disabled: true });
        if (this.state.btnText == '获取验证码' || this.state.btnText == '重新发送') {
            clearInterval(timer);
            timer = setInterval(() => {
                this.state.time--;
                this.setState({ time: this.state.time, btnText: this.state.time + 's' });
                if (this.state.time == 0) {
                    clearInterval(timer);
                    this.setState({ btnText: '重新发送', time: 60, disabled: false })
                }
            }, 1000);
            if (!this.state.disabled) {
                this.requestRequestCheck();
            }

        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { autoCompleteResult } = this.state;
        return (
            <div className="mod-pg-login">
                <div className="comm-w-h comm-col comm-item-center gra-drag-region" style={{ padding: "20px",position:'relative' }}>
                    
                </div>
                <div className='gra-no-drag' style={{ position:'absolute',zIndex:99,top:0,bottom:0,left:0,right:0, margin: 'auto auto', height:580,width: 360, padding: '40px',backgroundColor:'#fff',borderRadius:'20px' }}>
                        <img src={require("../../images/logo.svg")} width="auto" height="60" style={{ width: '100%' , marginBottom: 20 }} />
                        <div style={{  marginBottom: 20 }} className="comm-row  comm-item-justify comm-item-justify-between">
                            <span style={{ fontSize: 24, color: '#111', fontWeight: 600 }}>注册账号</span>
                            <Button type="default" onClick={() => {
                                notify.appSend("user-login-event", { event: "require" });
                                this.props.history.push('/login')
                                }}><span style={{ color: '#111111' }}>返回登录</span> 
                            </Button>
                        </div>
                        <div className="login-user gra-no-drag">
                            <span style={{ color: '#111111' }}>手机号码</span>
                            <Input
                                maxLength='11'
                                style={{ borderBottom: '1px #ccc solid' }}
                                // className='borderNone'
                                // size="large"
                                placeholder="请输入您的手机号码注册"
                                // value={this.state.data.pwd}
                                name='pwd' onChange={this.onChangeListener.bind(this, "phone")} />
                        </div>
                        {
                            <div>
                                <div className="login-pwd gra-no-drag" style={{ marginTop: 5 }}>
                                    <span style={{ color: '#111111' }}>验证码</span>
                                    <div style={{ position: 'relative' }}>
                                        <Input style={{ borderBottom: '1px #ccc solid' }} placeholder='验证码'
                                            //    className='borderNone'
                                            onChange={this.onChangeListener.bind(this, 'code')} />
                                        <a disabled={this.state.disabled} style={{
                                            position: 'absolute',
                                            right: 0,
                                            // top: 5,
                                            border: 'none',
                                            marginRight: 10,
                                            marginTop: 5
                                        }} onClick={this.getCode.bind(this)}>{this.state.btnText}</a>
                                    </div>
                                </div>
                                <div className="login-pwd gra-no-drag" style={{ marginTop: 5 }}>
                                    <span style={{ color: '#111111' }}>密码</span>
                                    <Input
                                        style={{ borderBottom: '1px #ccc solid' }}
                                        // className='borderNone'
                                        // size="large"
                                        type="password" placeholder="请输入密码"
                                        // value={this.state.data.pass}
                                        onChange={this.onChangeListener.bind(this, "pass")} />

                                </div>
                                <div className="gra-no-drag" style={{ marginTop: 20, display: 'none'  }}>
                                    <span style={{ color: '#111' }}>您需要注册的是</span>
                                    <div style={{ marginTop: 10 }}>
                                        <Radio.Group defaultValue={1} onChange={this.onChangeListener.bind(this, '类型')}>
                                            <Radio value={1} style={{ marginRight: 90 }} >单个店铺</Radio>
                                            <Radio value={2}>多个店铺</Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                                {/* {
                                    !this.state.MchType ? <div className="login-pwd gra-no-drag">
                                        <span style={{ color: '#111111' }}>邀请码<span style={{ color: '#666', fontSize: 12 }}>(请拨打66掌柜<span style={{ color: '#111', fontSize: 12 }}>{mzlPhone}</span>获取邀请码)</span></span>
                                        <Input
                                            style={{ borderBottom: '1px #ccc solid' }}
                                            placeholder="请输入邀请码"
                                            maxLength={11}
                                            onChange={this.onChangeListener.bind(this, "checkPhone")} />

                                    </div> : null
                                } */}
                            </div> 
                        }


                        <Button disabled={this.state.registeDisable} type="primary" className="btn-login comm-col comm-item-center comm-item-justify gra-no-drag"
                            onClick={this.onClickListener.bind(this, '确定')}><span
                                style={{ color: "#fff", }}>立即注册</span></Button>
                        <Button type="default" className="btn-register " onClick={() => {
                            notify.appSend("user-login-event", { event: "require" });
                            this.props.history.push('/login')
                        }}><span style={{ color: '#111111' }}>我有账号，去登录</span> </Button>
                        {/* <a style={{ width: '100%', textAlign: 'right', color: '#bbb', marginTop: 10 }} onClick={() => {
                            notify.appSend("user-login-event", { event: "require" });
                            this.props.history.push('/login')
                        }} className='gra-no-drag'>返回登录</a> */}
                    </div>
                <IModal
                    colse={true}
                    width='300px'
                    visible={this.state.visible}
                    footer={null}
                    onCancel={() => {
                        this.setState({ visible: false })
                    }}
                    view={
                        <div>
                            <p style={{
                                textAlign: 'center',
                                marginBottom: 60,
                                color: '#111',
                                fontSize: 20
                            }}>你已注册过66掌柜</p>
                            <div style={{ textAlign: 'center' }}>
                                <IButton type='primary' text='返回登录' onClick={() => {
                                    this.props.history.push('/login')
                                }}></IButton>
                            </div>

                        </div>
                    }>
                </IModal>
            </div>
        );
    }
}

const Register = Form.create()(register);
export default Register;
