import React from 'react';
import {Table,Pagination} from 'antd';
import "../../css/comm.css"
import {debounce} from "../comm/utils"

var indexStart = 0; //
var indexEnd = 20;
var pageSize = 20;

var tabH = 0; //tab的高度
var top =  130; //顶部离tab的距离
var buttom =  80; //底部离tab的距离\
var itemH = 38; //item高度
var currentPage = 1; //当前在第几页
var tableH =0


export default class ITableList extends React.Component {
    constructor() {
        super();
        
    }




    componentWillMount() {
        var that = this;
        that.setCallBack = debounce(that.setCallBack,500)
        
       
        //初始化
        // this.countPageSize();
        // indexEnd = pageSize;
        //监听页面尺寸的变化
        window.onresize = function() {
            that.countPageSize();
           
            that.setState(this)
        }

    }

    //计算tab能显示的最大条数
    countPageSize() {
        tableH = window.document.body.clientHeight - (this.props.top ?this.props.top:0);
        // console.log(9123123,tableH)
    }
    
  


    setCallBack =()=>{
        this.props.onTablePageCallback(indexStart, indexEnd)
    }

    handleChange(){
        if (this.props.onChange != null) {
            this.props.onChange();
        }
    }
    onChange(data, event) {
        
        if(event == null){
            return;
        }
        indexEnd = data * event;
        indexStart = indexEnd - event;
        currentPage = data;
        if (this.props.onTablePageCallback != null) {
            this.props.onTablePageCallback(indexStart, indexEnd)
        }
        //返回页码和页面可以展示条数
        if (this.props.onPageSize != null) {
            this.props.onPageSize(data, event)
        }
        this.setState(this)
    }

    onShowSizeChange(current, size) {
        pageSize = size;
        indexStart = (current - 1) * size;
        indexEnd = indexStart + pageSize;
        if (this.props.onTablePageCallback != null) {
            this.props.onTablePageCallback(indexStart, indexEnd,current)
        }
        //返回页码和页面可以展示条数
        if (this.props.onPageSize != null) {
            this.props.onPageSize(current, size)
        }
        this.setState(this)
        
    }




    render() {
        const {rowSelection,devHeight}  = this.props;
        this.countPageSize();
        return (
            <div className="mod-table-view" style={this.props.style}>
                    <div className="mod-table">
                        <Table 
                            scroll={{ y: tableH }}
                            size={'small'}
                            pagination={this.props.pagination?{
                                size:"small",
                                showQuickJumper:true,
                                showSizeChanger: true,
                                hideOnSinglePage: false,
                                onChange: this.onChange.bind(this),
                                onShowSizeChange: this.onShowSizeChange.bind(this),
                                defaultPageSize:20,
                                total: this.props.totalSize,
                                showTotal: total => `共${total}条记录`,
                            }:false}
                            bordered
                            rowSelection={this.props.rowSelection} 
                            columns={this.props.columns} 
                            dataSource={this.props.data} />
                    </div>
                    
                    
                    {/* {
                        this.props.pagination?
                            <Pagination 
                                pageSize={20}
                                size="small" 
                                total={this.props.data.length} 
                                showSizeChanger 
                                showQuickJumper
                                showTotal= {total => `共${total}条记录`}
                                onChange={this.onChange.bind(this)}
                                onShowSizeChange={this.onShowSizeChange.bind(this)} />
                            :null
                    } */}
                    
                    
                </div>
        );
    }
}






