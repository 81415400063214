import React from 'react'
import {DatePicker} from 'antd'
import {endUnix, getConversionTime} from '../comm/dateHelp'

const {RangePicker} = DatePicker;
// const dateFormat = 'YYYY-MM-DD';
const dateFormat = 'YYYY-MM-DD';
const dateFormatHM = 'YYYY-MM-DD HH:mm';
let beginTime = 0;
let overTime = 0;
export default class IRangePicker extends React.Component {
    constructor() {
        super();
    }

    onChange(date, dateString) {
        beginTime = getConversionTime(dateString[0]);
        if(this.props.showTime){
            overTime = getConversionTime(dateString[1]);
        }else{
            overTime = endUnix(dateString[1]);
        }
        
        this.props.onChange(beginTime, overTime)
    }

    onOk(value) {
        console.log('onOk: ', value);
      }
    render() {
        return (
            <RangePicker
             onOk={this.onOk.bind(this)} 
                showTime={this.props.showTime?this.props.showTime:false}	
                onChange={this.onChange.bind(this)}
                allowClear={false}
                style={this.props.style}
                value={this.props.value}
                format={this.props.showTime?dateFormatHM:dateFormat}
                disabledDate={this.props.disabledDate?this.props.disabledDate:''}
                className='datePicker'>
            </RangePicker>
        )
    }
}