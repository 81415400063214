import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from "../Loadable";
// const  ShareBonus = Loadable(() => import('../js/market/bonus'));

import ChainSetting from "../chain/setting/chainLeftNavContent/chainsetting/chainSetting";
import ShareBonus from "../js/market/bonus";
import {checkLogin, Login} from "../js/user/login";
import Register from "../js/user/register";
import ResetPwd from "../js/user/resetPwd";
import App from '../App';
import ChainApp from "../chainApp";

const  ChainGoodsContent = Loadable(() => import('@chain/goods/goodsContent'));
const  SettingContent = Loadable(() => import('../js/contents/settingContent'));
const  UserCenterContent = Loadable(() => import('../js/contents/userCenterContent'));
const  UserAccoutSetting = Loadable(() => import('../js/userCenter/accoutSettingContent'));
const  MyOrder = Loadable(() => import('../js/userCenter/myOrder'));
const  SoftRenewal = Loadable(() => import('../js/userCenter/softRenewal'));
const  StockContent = Loadable(() => import('../js/contents/stockContent'));
const  GoodsContent = Loadable(() => import('@js/contents/goodsContent'));
const  orderContent = Loadable(() => import('../js/contents/orderContent'));
const  FinanceContent = Loadable(() => import('../js/contents/financeContent'));
const  VipContent = Loadable(() => import('../js/contents/vipContent'));
const  ReportContent = Loadable(() => import('../js/contents/reportContent'));
const  MarketContent = Loadable(() => import('../js/contents/marketContent'));
const  SilverContent = Loadable(() => import('../js/contents/silverContent'));
const  Agree = Loadable(() => import('../js/agrees/agree'));
const  Home = Loadable(() => import('../js/contents/home'));
const  Sliver = Loadable(() => import('../js/silver/silver'));
const  Room = Loadable(() => import('../js/silver/room'));

const  ServiceItemsSetting = Loadable(() => import('../js/settings/serviceItemsSetting'));
const  ServiceItemsSettingView = Loadable(() => import('../js/settings/serviceItemsSettingView'));
const  PostSetting = Loadable(() => import('../js/settings/postSetting'));
const  StaffSetting = Loadable(() => import('../js/settings/staffSetting'));
const  VipSetting = Loadable(() => import('../js/settings/vipSetting'));
const  PositionSetting = Loadable(() => import('../js/settings/positionSetting'));
const  ShopSetting = Loadable(() => import('../js/settings/shopSetting'));
const  ReserverSetting = Loadable(() => import('../js/settings/reserverSetting'));
const  IntegralSetting = Loadable(() => import('../js/settings/integralSetting'));
const  Roulette = Loadable(() => import('../js/settings/roulette'));
const  Backups = Loadable(() => import('../js/settings/backups'));
const  Printer = Loadable(() => import('../js/settings/printer'));
const  PayByCode = Loadable(() => import('../js/settings/payCode/payByCode'));
const  PaySetting = Loadable(() => import('../js/settings/paySetting/paySetting'));
const  ConsumeSetting = Loadable(() => import('../js/settings/consumeSetting/consumeSetting'));
const  CheckWork = Loadable(() => import('../js/settings/checkWork/checkWork'));

const  ChainSett = Loadable(() => import('../chain/setting/chainLeftNavContent/chainsetting'));
const  ChainReport = Loadable(() => import('../chain/report/chainReport'));
const  ChainIndex = Loadable(() => import('../chain/setting/chainindex'));
// const  ChainAccount = Loadable(() => import('../chain/setting/chainLeftNavContent/chainaccountsetting'));


const  Accountsetting = Loadable(() => import('../chain/setting/chainLeftNavContent/chainaccountsetting/accountsetting'));
const  ChainConsume = Loadable(() => import('../chain/setting/chainConsume/chainConsume'));
const  ChainMyOrder = Loadable(() => import('../chain/setting/chainConsume/chainmyOrder'));


const  ChainShopSetting = Loadable(() => import('../chain/setting/chainLeftNavContent/chainsetting/chainshopSetting'));
// const  ChainSetting = Loadable(() => import('../chain/setting/chainLeftNavContent/chainsetting/chainSetting'));

const  OrderList = Loadable(() => import('../js/order/orderList'));
const  Arrears = Loadable(() => import('../js/order/arrears'));
const  WaterListCheck = Loadable(() => import('../js/order/waterListCheck'));

const  VipList = Loadable(() => import('../js/vip/vipList'));
const  MemberList = Loadable(() => import('../js/vip/memberList'));
const  VipAnalysis = Loadable(() => import('../js/vip/vipAnalysis'));
const  OpenCardMarket = Loadable(() => import('../js/market/openCardMarket'));
const  RechargeMarketing = Loadable(() => import('../js/market/rechargeMarketing'));
// const  MessageMar = Loadable(() => import('../js/market/messageMarket'));

const  RetailMarketing = Loadable(() => import('../js/market/retailMarketing'));
const  MessageMarket = Loadable(() => import('../js/market/messageMarket'));
const  EmployeeBonus = Loadable(() => import('../js/market/employeeBonus'));
// const  ShareBonus = Loadable(() => import('../js/market/bonus'));
const  CashCoupon = Loadable(() => import('../js/market/cashCoupon'));
const  LuckDraw = Loadable(() => import('../js/market/luckDraw'));
const  Union = Loadable(() => import('../js/market/union'));

const  PurchaseManage = Loadable(() => import('../js/stock/purchaseManage'));
const  ImportSales = Loadable(() => import('../js/stock/importSales'));
const  GoodsManage = Loadable(() => import('../js/stock/goodsManage'));
const  StockInventory = Loadable(() => import('../js/stock/stockInventory'));
const  OverdueRemind = Loadable(() => import('../js/stock/overdueRemind'));
const  SupplierManage = Loadable(() => import('../js/stock/supplierManage'));
const  StockWarning = Loadable(() => import('../js/stock/stockWarning'));


const  BusinessAnalysis = Loadable(() => import('../js/report/businessAnalysis'));
const  BusinessData2 = Loadable(() => import('../js/report/businessData2'));
const  Vipreport = Loadable(() => import('../js/report/vipreport'));
const  Employeeperformance = Loadable(() => import('../js/report/employeePerformance'));
const  Cousume = Loadable(() => import('../js/report/consume'));



export  const RoutePath = {
    //启动页
    login:"/login",
    register:"/register",
    resetPwd:"/resetPwd",
    chain:"/chain",
    //66掌柜app
    home_settingContent:'/home/settingContent',
    home_userCenterContent:'/home/userCenterContent',
    home_accoutSettingContent:'/home/accoutSettingContent',
    home_stockContent:'/home/stockContent',
    home_goodsContent:'/home/goodsContent', //库存
    home_orderContent:'/home/orderContent',
    home_financeContent:'/home/financeContent',
    home_silver:'/home/silver',
    home_room:'/home/room',
    home_vipContent:'/home/vipContent',
    home_reportContent:'/home/reportContent',
    home_marketContent:'/home/marketContent',
    home_silverContent:'/home/silverContent',
    home_agree:'/home/agree',
    home:'/home',
    //66掌柜连锁
    chain_ChainIndex:'/chain/ChainIndex',
    chain_settingContent_setting:'/chain/settingContent/setting',
    chain_chainReport:'/chain/chainReport',
    chain_accountsetting:'/chain/accountsetting',
    //66掌柜连锁设置
    chain_settingContent_setting_chainasetting:'/chain/settingContent/setting/chainasetting',
    chain_settingContent_setting_shopSetting:'/chain/settingContent/setting/shopSetting',
    chain_accountsetting_index:'/chain/accountsetting/index',
    chain_goods:'/chain/goodsContent/',
    chain_setting_consume:'/chain/settingContent/setting/chainConsume',
    chain_setting_myOrder:'/chain/settingContent/setting/chainmyOrder',

    consumeSetting:"/home/settingContent/consumeSetting",
    checkWork:"/home/settingContent/checkWork",
    consumeReport:"/home/reportContent/consume",
}




export const RoutePath2 = {
    //启动页
    index:[
        {path:'/login',component:Loadable(() => import('../js/user/login'))},
        {path:"/register",Component:Register},
        {path:"/resetPwd",Component:ResetPwd},
        {path:"/chain",Component:ChainApp},
        {path:"/",Component:App},
    ],
    //  //66掌柜app
    // app:[
    //     {path:"/home/settingContent",Component:SettingContent},
    //     {path:"/home/accoutSettingContent",Component:SettingContent},
    //     {path:"/home/stockContent",Component:SettingContent},
    //     {path:"/home/orderContent",Component:SettingContent},
    //     {path:"/home/financeContent",Component:SettingContent},
    //     {path:"/home/silver",Component:SettingContent},
    //     {path:"/home/room",Component:SettingContent},
    //     {path:"/home/vipContent",Component:SettingContent},
    //     {path:"/home/reportContent",Component:SettingContent},
    //     {path:"/home/marketContent",Component:SettingContent},
    //     {path:"/home/silverContent",Component:SettingContent},
    //     {path:"/home/agree",Component:SettingContent},
    //     {path:"/home",Component:SettingContent},
    // ],
    //   //66掌柜连锁
    // chain:[
    //     {path:"/chain/ChainIndex"},
    //     {path:"/chain/settingContent/setting"},
    //     {path:"/chain/chainReport"},
    //     {path:"/chain/accountsetting"}
    // ]
    //66掌柜连锁设置
}




/**
 * 启动页
 */
export function index (){
    return (
        <React.Fragment>
            <Switch>
                {/* {RoutePath2.index.map(item=>(
                    <Route key={item.path} path={item.path} component={item.component} />
                ))}   */}
                <Route path={RoutePath.login} component={Login} onEnter={checkLogin}/>
                <Route path={RoutePath.register} component={Register}/>
                <Route path={RoutePath.resetPwd} component={ResetPwd}/>
                <Route path={RoutePath.chain} component={ChainApp}/>
                <Route path="/" component={App}/>
                
            </Switch>
        </React.Fragment>
        
    )
}


/**
 * 66掌柜app
 */
export function AppRoute (){
    return (
        <React.Fragment>
            <Switch>
                <Route path={RoutePath.home_settingContent} component={SettingContent} />
                <Route path={RoutePath.home_userCenterContent} component={UserCenterContent} />
                {/* <Route path={RoutePath.home_stockContent} component={StockContent} /> */}
                <Route path="/home/goodsContent" component={GoodsContent} />
                <Route path={RoutePath.home_orderContent} component={orderContent} />
                <Route path={RoutePath.home_financeContent} component={FinanceContent} />
                <Route path={RoutePath.home_silver} component={Sliver} />
                <Route path={RoutePath.home_room} component={Room} />
                <Route path={RoutePath.home_vipContent} component={VipContent} />
                <Route path={RoutePath.home_reportContent} component={ReportContent} />
                <Route path={RoutePath.home_marketContent} component={MarketContent} />
                <Route path={RoutePath.home_silverContent} component={SilverContent} />
                <Route path={RoutePath.home_agree} component={Agree} />
                <Route path={RoutePath.home} component={Home} />
                <Route path='/' component={Home} />
            </Switch>
        </React.Fragment>
        
    )
}

/**
 * 66掌柜连锁
 */
export function ChainAppRoute (){
    return (
        <React.Fragment>
            <Switch>
                <Route path={RoutePath.chain_ChainIndex} component={ChainIndex} />
                <Route path={RoutePath.chain_settingContent_setting} component={ChainSett} />
                <Route path={RoutePath.chain_chainReport} component={ChainReport} />
                <Route path={RoutePath.chain_accountsetting_index} component={Accountsetting} />
                <Route path="/chain/goodsContent" component={ChainGoodsContent} />
              
            </Switch>
        </React.Fragment>
        
    )
}
/**
 * 66掌柜连锁设置
 */
export function ChainSettingRoute (){
    return (
        <React.Fragment>
            <Switch>
                <Route path={RoutePath.chain_settingContent_setting_chainasetting} component={ChainSetting}/>
                <Route path={RoutePath.chain_settingContent_setting_shopSetting} component={ChainShopSetting}/>
                <Route path={RoutePath.chain_setting_consume} component={ChainConsume}/>
                <Route path={RoutePath.chain_setting_myOrder} component={ChainMyOrder}/>
            </Switch>
        </React.Fragment>
    )
}





/**
 * 66掌柜店铺设置
 */
export function SettingContentRoute (){
    return (
        <React.Fragment>
            <Switch>
                <Route path='/home/settingContent/shopSetting' component={ShopSetting}/>
                <Route path='/home/settingContent/reserverSetting' component={ReserverSetting}/>
                <Route path='/home/settingContent/integralSetting' component={IntegralSetting}/>
                <Route path='/home/settingContent/roulette' component={Roulette}/>
                <Route path='/home/settingContent/postSetting' component={PostSetting}/>
                <Route path='/home/settingContent/staffSetting' component={StaffSetting}/>
                <Route path='/home/settingContent/vipSetting' component={VipSetting}/>
                <Route path='/home/settingContent/serviceItemsSetting' component={ServiceItemsSetting}/>
                <Route path='/home/settingContent/serviceItemsSettingView' component={ServiceItemsSettingView}/>
                <Route path='/home/settingContent/positionSetting' component={PositionSetting}/>
                <Route path='/home/settingContent/backups' component={Backups}/>
                <Route path='/home/settingContent/printer' component={Printer}/>
                <Route path='/home/settingContent/payByCode' component={PayByCode}/>
                <Route path='/home/settingContent/paySetting' component={PaySetting}/>
                <Route path={RoutePath.consumeSetting} component={ConsumeSetting}/>
                <Route path={RoutePath.checkWork} component={CheckWork}/>
            </Switch>
        </React.Fragment>
        
    )
}
/**
 * 66掌柜用户中心
 */
export function UserCenterContentRoute (){
    return (
        <React.Fragment>
            <Switch>
                <Route path='/home/userCenterContent/userAccoutSetting' component={UserAccoutSetting}/>
                <Route path='/home/userCenterContent/myOrder' component={MyOrder}/>
                <Route path='/home/userCenterContent/softRenewal' component={SoftRenewal}/>
            </Switch>
        </React.Fragment>
        
    )
}


/**
 * 66掌柜订单
 */
export function OrderContentRoute(){
    return (
        <React.Fragment>
             <Switch>
                <Route path='/home/orderContent/orderList' component={OrderList}/>
                <Route path='/home/orderContent/arrears' component={Arrears}/>
                <Route path='/home/orderContent/waterListCheck' component={WaterListCheck}/>
             </Switch>
        </React.Fragment>
        
    )
}

/**
 * 66掌柜会员
 */
export function VipContentRoute(){
    return (
        <React.Fragment>
             <Switch>
                <Route path='/home/vipContent/vipList' component={VipList}/>
                <Route path='/home/vipContent/memberList' component={MemberList}/>
                <Route path='/home/vipContent/openCardMarket' component={OpenCardMarket}/>
                <Route path='/home/vipContent/rechargeMarketing' component={RechargeMarketing}/>
                <Route path='/home/vipContent/messageMarket' component={MessageMarket}/>
                <Route path='/home/vipContent/vipAnalysis' component={VipAnalysis}/>
            </Switch>
        </React.Fragment>
        
    )
}

/**
 * 66掌柜营销
 */
export function MarketContentRoute(){
    return (
        <React.Fragment>
            <Switch>
                <Route path='/home/marketContent/retailMarketing' component={RetailMarketing}/>
                {/* <Route path='/home/marketContent/messageMarket' component={MessageMarket}/> */}
                <Route path='/home/marketContent/employeeBonus' component={EmployeeBonus}/>
                <Route path='/home/marketContent/shareBonus' component={ShareBonus}/>
                <Route path='/home/marketContent/cashCoupon' component={CashCoupon}/>
                <Route path='/home/marketContent/luckDraw' component={LuckDraw}/>
                <Route path='/home/marketContent/union' component={Union}/>
                <Route path='/home/marketContent/messageMarket' component={MessageMarket}/> 
            </Switch>
        </React.Fragment>
        
    )
}

/**
 * 66掌柜库存
 */
export function StockContentRoute(){
    return (
        <React.Fragment>
           <Switch>
                <Route path='/home/stockContent/purchaseManage' component={PurchaseManage}/>
                <Route path='/home/stockContent/importSales' component={ImportSales}/>
                <Route path='/home/stockContent/goodsManage' component={GoodsManage}/>
                <Route path='/home/stockContent/stockInventory' component={StockInventory}/>
                <Route path='/home/stockContent/overdueRemind' component={OverdueRemind}/>
                <Route path='/home/stockContent/stockWarning' component={StockWarning}/>
                <Route path='/home/stockContent/supplierManage' component={SupplierManage}/>
            </Switch>
        </React.Fragment>
        
    )
}

/**
 * 66掌柜报表
 */
export function ReportContentRouer(){
    return (
        <React.Fragment>
            <Switch>
                <Route path='/home/reportContent/businessAnalysis' component={BusinessAnalysis}/>
                <Route path='/home/reportContent/businessData2' component={BusinessData2}/>
                <Route path='/home/reportContent/employeeperformance' component={Employeeperformance}/>
                <Route path='/home/reportContent/vipreport' component={Vipreport}/>
                <Route path={RoutePath.consumeReport} component={Cousume}/>
            </Switch>
        </React.Fragment>
        
    )
}


//打开页面
export function openPage(list,that){
    list.array.forEach(element => {
        if(element.child && element.child.routePath){
            this.props.history.push(element.child.routePath);
        }
    });
    
}