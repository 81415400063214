import React from 'react';
import {Button, Modal} from 'antd';
import "../../css/iCompoments.css"

// import EditableCell from './EditableCell'

/**
 * 必填项样式
 */
export class IModal extends React.Component {
    constructor() {
        super();
    }
    

    handleOk = (e) => {
        this.props.onOk(e);

    };

    handleCancel = (e) => {
        this.props.onCancel(e)
    };

    

    render() {

        // console.log(11122233344,this.props.okButtonProps)
        return (
            // <div className="imodal-view">
            //     <Modal maskClosable={false} bodyStyle={this.props.bodyStyle} destroyOnClose={true} centered  closable={false}
            //            title={this.props.title}
            //            visible={this.props.visible}
            //            onOk={this.handleOk.bind(this)}
            //            onCancel={this.handleCancel.bind(this)}
            //            okText={this.props.okText}
            //            cancelText={this.props.cancelText}
            //            width={this.props.width}
            //            okButtonProps={this.props.okButtonProps}
            //            footer={this.props.footer}>
            //         {this.props.view}
            //         {
            //             this.props.colse ? null :
            //                 <a className="imodal-colse-view" onClick={this.handleCancel.bind(this)}> <i
            //                     className="iconfont icon-guanbi"></i> </a>
            //         }
            //         {this.props.footerButton?<div className="comm-flex-row-between imodal-view-footer-buttom"><span></span><Button onClick={this.handleOk.bind(this)}>{this.props.okText}</Button></div> :null}
            //     </Modal>
            // </div>

            <Modal maskClosable={false} 
                   bodyStyle={this.props.bodyStyle} 
                   destroyOnClose={true} 
                   centered  
                   closable={false}
                    title={this.props.title}
                    visible={this.props.visible}
                    onOk={this.handleOk.bind(this)}
                    onCancel={this.handleCancel.bind(this)}
                    okText={this.props.okText}
                    cancelText={this.props.cancelText}
                    width={this.props.width}
                    okButtonProps={this.props.okButtonProps?this.props.okButtonProps:{loading:this.props.loading}}
                    footer={this.props.footer}>

                    {this.props.view?this.props.view:React.Children.map(this.props.children,function (child) {
                              return <div>{child}</div>
                            })}
                    {
                        this.props.colse ? null :
                            <a className="imodal-colse-view" onClick={this.props.onColse?this.props.onColse:this.handleCancel.bind(this)}> <i
                                className="iconfont icon-guanbi"></i> </a>
                    }
                    {this.props.footerButton?<div className="comm-flex-row-between imodal-view-footer-buttom"><span></span><Button onClick={this.handleOk.bind(this)}>{this.props.okText}</Button></div> :null}
                </Modal>


        );
    }
}







