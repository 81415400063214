import React from 'react'
import { IModal } from '../components/iModal'
import { Button } from 'antd';
import {$mzlApi, notify,$agentApi} from "../comm/mzl-core";
import StorageTools from '../comm/storageTools'
import ApiConfig, { FnMenuId, mzlCopyright, mzlPhone, mzlVersion } from "../comm/constans";
import APINames from '../comm/api-names';
import {update} from "@reducers/iconRedux";

import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter';
@withRouter
@connect(
    state => state.iconRedux,
    {update}
)
class TipModal extends React.Component {
    constructor() {
        super();
        this.state = {
            buyModuleData:{}
        }
    }
    componentDidMount(){
    }
   
    onclick() {
        
        this.props.update({
            activationVisible:true,
            tipModalVisible:false
        })
        this.props.history.push({pathname:"/home/userCenterContent/softRenewal",query: { activation : true }});
       
    }

        render(){
            const {tipModalVisible} =this.props;
            return (
                <IModal
                    title='提示'
                    visible={tipModalVisible}
                    footer={null}
                    onCancel={() => this.props.onClick('tip-Cancel')}
                    view={
                        <div style={{ textAlign: 'center' }}>
                            <span style={{marginRight:20}}>您没有{this.props.data}权限，请先去激活</span>
                    <Button onClick={this.onclick.bind(this)} style={{ margin: '50px 0' }} type='primary'>去激活</Button>
                        </div>
                    }></IModal>
            )
        }
    }

    export default TipModal