/** 员工状态 员工状态，1：在职 2:休假 3:离职 */
export const WorkState = {
    //在职
    Normal: 1,
    // 休假
    RequestLeave: 2,
    // 离职
    Resign: 3
};


/**
 * 产品类型
 **/
export const EItemType = {
    /** 商品 */
    Normal: 1,
    /** 服务 */
    Virtual: 2
};


/** 价格计算方式 */
export const EPriceBasis = {
    /** 原价 */
    Original: 1,
    /** 会员折扣价 */
    Discount: 2,
    /** 自定义价格 */
    Custom: 3,
};


/** 金额/提成依据 */
export const EAmountBasis = {
    /** 未设置 */
    NotSet: 1,
    /** 按金额 */
    Amount: 2,
    /** 按百分比 */
    Percent: 3
};


/** 设置依据 */
export const ESettingsBasis = {
    /** 未设置 */
    None: 1,
    /** 使用全局 */
    Global: 2,
    /** 自定义 */
    Custom: 3
};

// 顾客标志
export const ECustomerFlag = {
    // 已激活的
    ACTIVATED: 1 << 0,
    // 已停用
    STOPPED: 1 << 1,
    // 已办理会员卡
    OWN_CARD: 1 << 2,
    // 已开通套餐卡
    OWN_TIMES_CARD: 1 << 3,
    // 邀请添加
    INVITE_ADD: 1 << 4
};

/**
 *  会员卡的优惠方式
 *  优惠方式,1:不打折 2:折扣 3:赠送礼金 */
export const EVipCardModal = {
    /** 不打折 */
    None: 1,
    /** 折扣 */
    Discount: 2,
    /** 赠送礼金 */
    Give: 3
};


/** 上架状态 */
export const EShelveState = {
    /** 库存商品 */
    InWarehouse: 1,
    /** 已下架 */
    ShelveDown: 2,
    /** 已上架 */
    ShelveOn: 3,
    /** 已违规下架 */
    ShelvesIncorrect: 4
};

/**
 * 订单类型
 */
export const EOrderType = {
    /** 消费订单 */
    Normal: 1,
    /** 暂存订单 */
    Suspend: 2,
    /** 会员卡充值订单 */
    ChargeOrder: 3,
    /** 新开会员卡订单 */
    CardOrder: 4,
    /** 新卡套餐卡订单 */
    TimeCardOrder: 5,
    //todo: 预留一个店铺订单类型
    /** 短信充值订单 */
    //SmsChargeOrder(6),
    /** 手动录入会员卡 */
    ManualCardOrder: 6,
    /** 手动录入套餐卡 */
    ManualTimeCardOrder: 7,
};


/**
 * 订单所有者
 */
export const EBuyer = {
    /** 散客，在平台匿名购买或在店铺匿名消费 */
    Guest: 1,
    /** 会员，在平台产生订单 */
    Member: 2,
    /** 顾客,在线下店铺和商家产生订单 */
    Customer: 3,
    /** 商户 */
    Merchant: 4
};


/**
 * 店铺订单类型
 */
export const EStoreOrderType = {
    /** 消费订单 */
    Normal: 1,
    /** 暂存订单 */
    Suspend: 2,
    /** 会员卡充值订单 */
    ChargeOrder: 3,
    /** 新开会员卡订单 */
    CardOrder: 4,
    /** 新卡套餐卡订单 */
    TimeCardOrder: 5,
    /** 短信充值订单 */
    SmsChargeOrder: 6,
    /** 手动录入会员卡 */
    ManualCardOrder: 7,
    /** 手动录入套餐卡 */
    ManualTimeCardOrder: 8
};


/** 支付标志 */
export const EPaymentFlag = {
    // 余额抵扣
    Balance: 1 << 0,
    // 钱包支付
    Wallet: 1 << 1,
    // 积分兑换
    Integral: 1 << 2,
    // 用户卡
    UserCard: 1 << 3,
    // 用户券
    UserCoupon: 1 << 4,
    // 现金支付
    Cash: 1 << 5,
    // 银行卡支付
    BankCard: 1 << 6,
    // 第三方支付,如支付宝等
    OutSP: 1 << 7,
    // 卖家支付通道
    SellerPay: 1 << 8,
    // 系统支付通道
    SystemPay: 1 << 9,
    /** 套餐卡支付 */
    TimeCard: 1 << 10,
    /** 免单 */
    Free: 1 << 11,
    /** 欠款 */
    Arrears: 1 << 12,
    /** 用户卡赠送金额支付 */
    UserCardPre: 1 << 13,
    /** 美团支付 */
    Meituan: 1 << 14,
    /** 抖音 */
    Koubei: 1 << 15,
    /** 饿了么 */
    Eleme: 1 << 16,
    /** 大众点评 */
    Dazhongdianping: 1 << 17,
};


/** 卡种类 */
export const ECardKind = {
    /** 会员卡 */
    CusCard: 1,
    /** 套餐卡 */
    TimeCard: 2,
    /** 赠送卡 */
    PresentCard: 3,
    /** 充值赠送卡 */
    ChargePresent: 4
};


/** 进销类型 */
export const EIncomingSales = {
    /**进货*/
    Incoming: 1,
    /**销售*/
    Sales: 2,
    /**员工领用*/
    Receive: 3,
    /**盘亏盘盈*/
    ClearPoint: 4,
    /** 报损*/
    Loss: 5
};

/**pms_spec_type类型*/
export const ESpecType = {
    /**商品类别*/
    Goods: 1,
    /**规格类别*/
    SpecType: 2,
    /**供应商类别*/
    Supplier: 3,
    /**领用状态*/
    CollarState: 4,
};


/**  审批状态 **/
export const EMaterialReceiveStatus = {
    /**  1:申请中 **/
    Apply: 1,
    /**  50：拒绝 **/
    ApplyRefuse: 50,
    /**  100：同意 **/
    ApplyConsent: 100
};


/** 卡标志 */
export const ECardFlag = {
    /** 可储值 */
    StoredValue: 1,
    /** 可享折扣 */
    Discount: 2,
    /** 可充值 */
    Charge: 4,
    /** 套餐卡单次使用 */
    SignalTime:8,
    /** 套餐卡套餐次使用 */
    PackageTime:16,
    /** 套餐卡整体次使用 */
    EntiretyTime:32,
    /** 套餐卡无限次使用 */
    UnlimitedTime:64,

};

/**活动分类*/
export const EActivityType = {
    /**开卡营销活动*/
    OpenCardAct: 1,
    /**充值营销活动*/
    RechargeAct: 2,
};
/**  房间状态 **/
export const ECashRegisterStatus = {
    /** 占用 **/
    Occupancy: 1,
    /** 可用 **/
    Available: 2,
    /** 停用 **/
    NoAvailable: 3,
    /** 打扫，清洁**/
    Clean: 4
};
//订单状态
export const EOrderState = {
    // 等待支付
    StatAwaitingPayment: 1,
    // 等待确认
    StatAwaitingConfirm: 2,
    // 等待备货
    StatAwaitingPickup: 3,
    // 等待发货
    StatAwaitingShipment: 4,
    // 部分发货:将订单商品分多个包裹发货
    PartiallyShipped: 5,
    // 完成发货
    StatShipped: 6,
    // 订单完成
    StatCompleted: 7,
    // 订单已拆分
    StatBreak: 8,
    // 系统取消
    StatCancelled: 11,
    // 买家申请取消,等待卖家确认
    StatAwaitingCancel: 12,
    // 卖家谢绝订单,由于无货等原因
    StatDeclined: 13,
    // 已退款,完成取消
    StatRefunded: 14,
    // 已退货
    StatGoodsRefunded: 15,
};

/**店铺是否客位收银 1代表客位收银 0代表不按照客位收银 默认为0*/
export const ECashStatus = {
    /** 房间收银 **/
    RoomCash: 1,
    /** 非房间收银 **/
    NoRoomCash: 0
};

// 打扫方式
export const ECleanType = {
    /** 自动打扫 **/
    AutomaticClean: 1,
    /** 不自动打扫 **/
    NoAutomaticClean: 2
};
export const EBankCardFlag = {
    WalPerson: 1,
    WalPartner: 2
};


/** 校验场景 */
export const ECheckSense = {
    /** 商户注册 */
    MchRegister: 1,
    /** 商户重置密码 */
    MchResetPwd: 2,
    /** 员工重置密码 */
    EmpResetPwd: 3,
    /** 重置店铺信息 */
    StoreReset: 4,
    /** 店铺收银权限验证 */
    StoreReqDisPerm: 5,
    /** 商户绑定银行卡 */
    MchBindBankCard: 6,
    /** 系统验证码 */
    SysVerifyCode: 7,
    /** 取消订单 */
    StoreCancelOrder: 8
};

/**岗位提成方式 */
export const EAwardType = {
    /**
     * 个人按照项目
     */
    PersonalItem: 1,
    /**
     * 个人按照阶梯
     */
    PersonalRange: 2,
    /**
     * 全局按照阶梯
     */
    GlobalRange: 4,

};
/**个人阶梯提成业绩计算方式 */
export const EPerfromPer = {
    /**
     * 未指定
     */
    Undefined: 0,
    /**
     * 均分业绩
     */
    Average: 1,
    /**
     * 全部业绩
     */
    Total: 2,
};
/**个人阶梯提成 提成计算方式 */
export const EAwardPer = {
    /**
     * 未指定
     */
    Undefined: 0,
    /**
     * 全段提成
     */
    Max: 1,
    /**
     * 逐段提成
     */
    Level: 2,
};
/**提成区间类型 */
export const EAwardRangeType = {
    /**
     * 个人阶梯统一提成
     */
    PersonalUnified: 1,
    /**
     * 个人阶梯商品提成
     */
    PersonalNormal: 2,
    /**
     * 个人阶梯服务提成
     */
    PersonalVirtual: 3,
    /**
     * 个人阶梯开会员卡提成
     */
    PersonalCard: 4,
    /**
     * 个人阶梯开套餐卡提成
     */
    PersonalTCard: 5,
    /**
     * 个人阶梯充值提成
     */
    PersonalCharge: 6,
    /**
     * 全局阶梯统一提成
     */
    GlobalUnified: 7,
    /**
     * 全局阶梯商品提成
     */
    GlobalNormal: 8,
    /**
     * 全局阶梯服务提成
     */
    GlobalVirtual: 9,
    /**
     * 全局阶梯开会员卡提成
     */
    GlobalCard: 10,
    /**
     * 全局阶梯开套餐卡提成
     */
    GlobalTCard: 11,
    /**
     * 全局阶梯充值提成
     */
    GlobalCharge: 12,

};
//88代表本身
export const TechnicianOrderModel = {
    /**订单编号*/
    orderNo: 88,
    /**订单类型*/
    orderKind: 88,
    /**订单状态*/
    state: 88,
    /**消费用户ID*/
    buyerId: 88,
    /**订单时间*/
    createTime: 88,
    /**订单ID*/
    orderId: 88,
    /**操作人员*/
    opuName: 88,
    /**订单提成Item*/
    itemList: 88,
    /**顾客姓名*/
    cusName: 88,
    /**顾客电话*/
    cusPhone: 88,
    /**订单类型*/
    orderType: 88,
    /**业绩*/
    performance: 88,
    /**提成来源*/
    awardSource: 88,
    /**提成类型*/
    awardType: 88,

};
/**
 * 阶梯提成类型
 */
export const AwardRangeType = {
    /**
     * 个人阶梯统一提成
     */
    PersonalUnified: 1,
    /**
     * 个人阶梯商品提成
     */
    PersonalNormal: 2,
    /**
     * 个人阶梯服务提成
     */
    PersonalVirtua: 3,
    /**
     * 个人阶梯开会员卡提成
     */
    PersonalCar: 4,
    /**
     * 个人阶梯开套餐卡提成
     */
    PersonalTCar: 5,
    /**
     * 个人阶梯充值提成
     */
    PersonalCharg: 6,
    /**
     * 全局阶梯统一提成
     */
    GlobalUnifie: 7,
    /**
     * 全局阶梯商品提成
     */
    GlobalNorma: 8,
    /**
     * 全局阶梯服务提成
     */
    GlobalVirtua: 9,
    /**
     * 全局阶梯开会员卡提成
     */
    GlobalCard: 0,
    /**
     * 全局阶梯开套餐卡提成
     */
    GlobalTCard: 1,
    /**
     * 全局阶梯充值提成
     */
    GlobalCharge: 2,

};


/**
 * 岗位提成方式
 */

export const RoleAwardType = {
    /**
     * 个人按照项目
     */
    PersonalItem: 1,
    /**
     * 个人按照阶梯
     */
    PersonalRange: 2,
    /**
     * 全局按照阶梯
     */
    GlobalRange: 4,

};


export const GoodsType = {
    /**
     * 个人商品提成
     */
    ItemNormal: 13,
    /**
     * 个人服务提成
     */
    ItemVirtual: 14,
    /**
     * 个人开会员卡提成
     */
    ItemCard: 15,
    /**
     * 个人开套餐卡提成
     */
    ItemTCard: 16,
    /**
     * 个人充值提成
     */
    ItemCharge: 17,

};


export const SEmpAwardTotal = {
    /** 编号 */
    Id: 88,
    /** 员工ID */
    EmpId: 88,
    /** 员工姓名 */
    EmpName: 88,
    /** 提成来源*/
    AwardSource: 88,
    /** 提成汇总*/
    AwardTotal: 88,
    /** 提成类型*/
    AwardType: 88,
    /** 业绩*/
    PerformanceTotal: 88,
    /** 生成时间 */
    CreateTime: 88,
    /** 更新时间 */
    UpdateTime: 88,

};


/**
 * 订单项目类型
 */
export const OrderItemType = {
    /**
     * 商品
     */
    Normal: 1,
    /**
     * 服务
     */
    Virtual: 2,
    /**
     * 会员卡
     */
    Card: 3,
    /**
     * 套餐卡
     */
    TCard: 4,
    /**
     * 充值
     */
    Charge: 5,
};

export const itemType = {
    /**商品类型*/
    itemType: 88,
    /**业绩汇总*/
    performanceSum: 88,
};
/**
 * 员工工作状态
 */
export const type = {
    /**
     * 在职
     */
    Normal: 1,
    /**
     * 请假
     */
    RequestLeave: 2,
    /**
     * 辞职
     */
    Resign: 3

};
/**
 * 销售类型
 */
export const SalesTypeEnum = {
    //试用
    TRIAL: 1,
    //购买
    BUY: 2,
    //升级
    UPGRADE: 3,
    //年费
    ANNUAL_FEE: 4,
};
export const shopType = {
    //美发
    shopType: 2,
    //美容
    shopType: 3,
    //足浴按摩
    shopType: 4,
    //汽车美容
    shopType: 5,
    //健身中心
    shopType: 6,
    //宠物店
    shopType: 7,

};
export const ProdSalesLog = {
    /**
     * 主键id
     */

    /**
     * 商户主键mchId
     */

    /**
     * 店铺Code shopCode
     */

    /**
     * 名称shopName
     */

    /**
     * 购买版本ID versionId
     */

    /**
     * 销售类型salesType
     */

    /**
     * 销售价salePrice
     */

    /**
     * 出厂价格factoryPrice
     */

    /**
     * 佣金brokerage
     */

    /**
     * 员工IDsalesmanId
     */

    /**
     * 员工名字salesmanName
     */

    /**
     * 员工手机号salesmanPhone
     */

    /**
     * 购买时间buyTime
     */

    /**
     * 创建时间createTime
     */

    /**
     * 更新时间updateTime
     */

    /**
     * 试用天数probationaryDay
     */


    /**
     * 版本名字versionName
     *
     * @return
     */

    /**
     * 有效期validTime
     *
     * @return
     */

    /**
     * 店铺详情
     *
     * @return
     */

};

/**
 * 销售类型
 */
export const ProdOrderType = {
    //试用
    TRIAL: 1,
    //购买
    BUY: 2,
    //升级
    UPGRADE: 3,
    //续费 年费
    ANNUAL_FEE: 4
};

/**
 * 用户权限
 */
export const Auto = {
    //退单
    Refund: 1,
    //收银
    Cashier: 2,
    //开卡改价
    AdjustCard: 4,
    //收银改价
    AdjustCashier: 8,
    //允许修改赠送积分
    Integral: 16,
    //允许修改使用积分
    UseIntegral: 32,
    //收银时允许修改业绩提成
    Achievement: 64,
    //允许查看报表
    Report: 128,
     //允许修改开卡赠送项目
    UpdateProject: 256,
};
export const  CusCardSummarizationType = {
    /** 储值新增 */
    StoreIncrease:1,
    /** 套餐卡销售 */
    TCardIncrease:2,
    /** 增送新增 */
    PresentIncrease:3,
    /** 会员卡消耗 */
    CardConsumption:4,
    /** 套餐卡消耗 */
    TCardConsumption:5,
    /** 会员新增 */
    CardIncrease:6

}

/**
 * 来源类型
 */
export const ESourceType = {
    /** 开卡 */
    CreateCard:1,
    /** 充值 */
    Charge:2,
    /** 虚拟服务 */
    Virtual:3,
    /** 实物 */
    Normal:4
}

/**
 * 连锁跨店数据审核状态
 */
export const ChainState = {
    /**
     * 同意
     */
    Agreed:1,
    /**
     * 拒绝
     */
    Refused:2,
    /**
     * 等待中
     */
    Awaiting:3,
}


/**
 * 请假类型
 */
export const LeaveKind={
    /**
     * 事假
     */
    Busy:1,
    /**
     * 病假
     */
    Disease:2,
    /**
     * 产假
     */
    Birth:3,
    /**
     * 陪产假
     */
    AccompanyBirth:4,
    /**
     * 婚假
     */
    Wedding:5,
    /**
     * 丧假
     */
    Death:6,

}


/**
 * 考勤状态
 */
export const AttendanceState ={
    /** 正常考勤 */
    Sign:1,
    /** 迟到 */
    Late:2,
    /** 早退 */
    LeaveEarly:4,
    /** 请假 */
    Leave:8,
    /** 缺卡 */
    Forget:16,
    /** 加班 */
    Overtime:32,
    /** 旷工 */
    Absenteeism:64,
    /** 休息 */
    Rest:128,
    /** 未知 */
    Unknown:256, 
    /** 补卡 */
    Unknown:512, 
  }


export default {
    WorkState: WorkState,
    EItemType: EItemType,
    EPriceBasis: EPriceBasis,
    EAmountBasis: EAmountBasis,
    ECustomerFlag: ECustomerFlag,
    ESettingsBasis: ESettingsBasis,
    EVipCardModal: EVipCardModal,
    EShelveState: EShelveState,
    EOrderType: EOrderType,
    EBuyer: EBuyer,
    EStoreOrderType: EStoreOrderType,
    EPaymentFlag: EPaymentFlag,
    ECardKind: ECardKind,
    EIncomingSales: EIncomingSales,
    ESpecType: ESpecType,
    EMaterialReceiveStatus: EMaterialReceiveStatus,
    ECardFlag: ECardFlag,
    EActivityType: EActivityType,
    ECashRegisterStatus: ECashRegisterStatus,
    EOrderState: EOrderState,
    ECashStatus: ECashStatus,
    ECleanType: ECleanType,
    EAwardType: EAwardType,
    EPerfromPer: EPerfromPer,
    EAwardPer: EAwardPer,
    EAwardRangeType: EAwardRangeType,
    AwardRangeType: AwardRangeType,
    TechnicianOrderModel: TechnicianOrderModel,
    RoleAwardType: RoleAwardType,
    GoodsType: GoodsType,
    SEmpAwardTotal: SEmpAwardTotal,
    OrderItemType: OrderItemType,
    itemType: itemType,
    ProdOrderType: ProdOrderType,
    Auto: Auto,
    CusCardSummarizationType,
    ChainState:ChainState,
    LeaveKind,
    AttendanceState


}