import { $mzlApi, $reportApi, $agentApi, $smsApi, httpPostUrl, $shopApi, $userApi } from '@comm/mzl-core';
import APINames from '@comm/api-names'
import storageTools from '@comm/storageTools'
import * as utils from '@comm/utils'
import IEnum from '@comm/iEnum'
import dateHelp from '@comm/dateHelp'
import { noCheck } from '@comm/constans';



export default class Baseservice {

    constructor() {
        this.APINames = APINames;
        this.$mzlApi = $mzlApi;
        this.$shopApi = $shopApi;
        this.$smsApi = $smsApi;
        this.$agentApi = $agentApi;
        this.$reportApi = $reportApi;
        this.$userApi = $userApi;
        // this.mchCode = storageTools.getMchCode();
        // this.storeCode = storageTools.getUserCode();
        // this.userInfo = storageTools.getUserInfo();
        this.dateHelp = dateHelp;
        this.utils = utils;
        this.log = utils.log;
        this.IEnum = IEnum;
        this.storageTools = storageTools;
        this.httpPostUrl = httpPostUrl;



        // console.log("Baseservice22222",this.mchCode,this.storeCode)
    }



    /**
     * 查询服务
     */
    requestQueryItem(that, storeCode, httpFail) {
        const _this = this;
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('storeCode', storeCode || storageTools.getUserCode());
            map.set('productType', IEnum.EItemType.Virtual);
            map.set('opt', JSON.stringify({ Begin: 0, Over: 999, Opt: { enabled: 1 } }));
            $mzlApi.post(APINames.API_queryItem, map, (res) => {
                resolve(res)
            }, httpFail || _this.basseHttpFail, that);
        });
        return p;

    }

    /**
     * 查询商品
     */
    requestGoodsList(that, storeCode, httpFail) {
        const _this = this;
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('storeCode', storeCode || storageTools.getUserCode());
            map.set('productType', IEnum.EItemType.Normal);
            map.set('opt', JSON.stringify({ Begin: 0, Over: 999, Opt: { enabled: 1 } }));
            $mzlApi.post(APINames.API_queryItem, map, (res) => {
                resolve(res)
            }, httpFail || _this.basseHttpFail, that);
        });
        return p;

    }

    /**
     * 查询店铺信息
     */
    requestGetStore(that, httpFail, storeCode) {
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('storeCode', storeCode ? storeCode : storageTools.getUserCode());
            $mzlApi.post(APINames.API_getStore, map, (res) => {
                resolve(res)
            }, httpFail, that);
        });
        return p;

    }


    /**
       * 获取企业信息
       */
    requestGetMchInfo(that) {
        const _this = this;
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('mchCode', storageTools.getMchCode());
            $mzlApi.post(APINames.API_getMchMerchant, map, (res) => {
                resolve(res)
            }, _this.basseHttpFail, that);
        });
        return p;
    }


    /**
       * 获取会员卡类型
       */
    requestMchCardList(that) {
        const _this = this;
        let p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', storageTools.getUserCode());
            map.set('kind', IEnum.ECardKind.CusCard);
            map.set('opt', JSON.stringify({ Begin: 0, Over: 999, Opt: { enabled: 1 } }));
            $mzlApi.post(APINames.API_queryMchCardList, map, (res) => {
                resolve(res)
            }, _this.basseHttpFail, that);
        });
        return p;


    }


    /**
     * 获取套餐卡
     */
    requestTimeCard(that) {
        const _this = this;
        let p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', storageTools.getUserCode());
            map.set('kind', IEnum.ECardKind.TimeCard);
            map.set('opt', JSON.stringify({ Begin: 0, Over: 999, Opt: { enabled: 1 } }));
            $mzlApi.post(APINames.API_queryMchCardList, map, (res) => {
                resolve(res)
            }, _this.basseHttpFail, that);
        });
        return p;
    }



    /**
     * 查询会员卡信息
     */
    getMembership(that, httpFail, phone) {
        const t = this;

        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('phone', phone);
            map.set('storeCode', storageTools.getUserCode());
            $mzlApi.post(APINames.API_getCustomerAndCard, map, (res) => {
                let vipData = t.setMemberInfo(res)
                resolve(vipData)
            }, httpFail, that);
        });
        return p;

    }


    /**
    * 查询会员卡信息
    */
    getMemberInfo(that, phone) {
        const t = this;
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('phone', phone);
            map.set('storeCode', storageTools.getUserCode());
            $mzlApi.post(APINames.API_getCustomerAndCard, map, (res) => {
                resolve(res)
            }, t.basseHttpFail, that);
        });
        return p;

    }







    setMemberInfo(res) {

        var data = res.Customer;
        var Extra = res.Extra;
        var vipData = res.Card;

        if (vipData == null || vipData == undefined) { //没有会员卡

            vipData = {};
            if (Extra != null) {
                vipData.TradePwdEnabled = Extra.TradePwdEnabled;
            }
            vipData.CusCharacterTag = data.CusCharacterTag;
            vipData.CusSourceTag = data.CusSourceTag;
            vipData.Birthday = data.Birthday;
            vipData.HaveCardType = data.HaveCardType;
            vipData.Phone = data.Phone;
            vipData.RealName = data.RealName;
            vipData.CusName = data.CusName;
            vipData.Sex = data.Sex;
            vipData.Id = data.Id;
            vipData.userCode = data["UserCode"] || "";
            var STOPPED = (data.CusFlag & IEnum.ECustomerFlag.STOPPED) == IEnum.ECustomerFlag.STOPPED;
            var OWN_CARD = (data.CusFlag & IEnum.ECustomerFlag.OWN_CARD) == IEnum.ECustomerFlag.OWN_CARD;
            var OWN_TIMES_CARD = (data.CusFlag & IEnum.ECustomerFlag.OWN_TIMES_CARD) == IEnum.ECustomerFlag.OWN_TIMES_CARD;
            if (STOPPED || OWN_CARD || OWN_TIMES_CARD) {
                vipData.CardName = "套餐卡会员";
            } else {
                vipData.CardName = "散客会员";
            }

            vipData.ScoreBalance = data.ScoreBalance;
            data = vipData;
            data.isUseCard = false;
            data.sk = true; //散客
            return vipData;
        }

        if (Extra != null) {
            vipData.TradePwdEnabled = Extra.TradePwdEnabled;
        }
        vipData.Birthday = data.Birthday;
        //vipData.CardName = data.CardName;
        vipData.CusCharacterTag = data.CusCharacterTag;
        vipData.CusSourceTag = data.CusSourceTag;
        vipData.HaveCardType = data.HaveCardType;
        vipData.Phone = data.Phone;
        vipData.RealName = data.RealName;
        vipData.CusName = data.CusName;
        vipData.Sex = data.Sex;
        vipData.Id = data.Id;
        vipData.userCode = data["UserCode"] || "";
        vipData.ScoreBalance = data.ScoreBalance;
        let _data = vipData;
        _data.isUseCard = true;
        _data.isShow = true;
        if (_data.CardDiscount == 0) {
            _data.CardDiscount = 1000
        }
        if (_data.CardDiscount > 0 && _data.CardDiscount < 1000) {
            _data._discountText = utils.numberConvert(_data.CardDiscount) + "折"
        } else {
            _data._discountText = "无折扣"
        }
        return vipData;

    }



    //查询本店员工
    getEmployeeLis(that, httpFail) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("storeCode", t.storageTools.getUserCode());
            t.$mzlApi.post(t.APINames.API_getEmployeeList, map,
                (res) => {
                    if (res != null || res.Data != null) {
                        var list = JSON.parse(res.Data.Data);
                        let onData = [];
                        list.forEach((item, index) => {
                            /** 员工状态 员工状态，1：在职 2:休假 3:离职 */
                            if (item.WorkState == t.IEnum.WorkState.Normal) {
                                onData.push(item);
                            }
                        });
                        resolve(onData);
                    }

                },
                (res, tag) => {
                    httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

    //查询本店岗位
    getStoreRole(that, httpFail) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set("storeCode", t.storageTools.getUserCode());
            t.$mzlApi.post(t.APINames.API_getStoreRole, map,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    httpFail ? httpFail(that, res, tag) : t.basseHttpFail(res, tag)
                },
                that
            );
        })
        return p;
    }


    /**
     * 新版本查询
     */
    getUserProd(that) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            var map = new FormData();
            map.append("url", APINames.API_getUserProd);
            map.append("params", JSON.stringify({
                userCode: t.storageTools.getUserCode()
            }))
            t.$mzlApi.post(t.APINames.API_getProdApi, map,
                (res) => {
                    if (res.Data) {

                        if (res.Data.modules && res.Data.modules !== '') {
                            res.Data.modules = JSON.parse(res.Data.modules);
                        }
                        if (res.Data.serial && res.Data.serial !== '') {
                            res.Data.serial = JSON.parse(res.Data.serial);
                        }
                        if (res.Data.version && res.Data.version !== '') {
                            res.Data.version = JSON.parse(res.Data.version);
                        }
                        storageTools.saveUserProd(res.Data);
                        resolve(res);
                    }

                },
                (res, tag) => {
                    console.log("获取版本失败:", res)
                },
                that
            );
        })
        return p;

    }



    /**
     * 库存预警气泡  1生日（展示不查）  2库存提醒  3过期提醒
     */
    getTipCount(that, tipType) {
        const _this = this;
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('storeCode', storageTools.getUserCode());
            map.set('tipType', tipType);
            $mzlApi.post(APINames.API_getTipCount, map, (res) => {
                resolve(res)
            }, _this.basseHttpFail, that);
        });
        return p;

    }

    /**
     * 查询店铺设置开关
     * State 状态 1:开启 2：关闭
     * Type : 1 轮牌 2手牌 3房间 4会员是否显余额  5是否开启扣卡提成
     * 
     */

    queryShodisjunctorSettings(that) {
        const _this = this;
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('storeCode', storageTools.getUserCode());
            $mzlApi.post(APINames.API_QueryShodisjunctorSettings, map, (res) => {
                resolve(res)
            }, _this.basseHttpFail, that);
        });
        return p;

    }

    /**
     * 保存店铺设置开关
     * 
     */
    saveShodisjunctorSettings(that, data) {

        const _this = this;
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('storeCode', storageTools.getUserCode());
            map.set('shodisjunctorSettings', JSON.stringify(data));
            $mzlApi.post(APINames.API_SaveShodisjunctorSettings, map, (res) => {
                resolve(res)
            }, _this.basseHttpFail, that);
        });
        return p;

    }



    //获取第三方商店用户信息
    getShopInfo(that, shopCode, long, version, callback) {
        let str = '1ftJN1xrAe4oCDcCxAU1W3g';
        let str1 = 'f7d83fbc139b8a0656f307eeb6d5d988';
        let time = new Date().getTime();
        let str2 = str + str1 + time;
        var b = str2.split("");
        b.sort((a, b) => {
            return b.charCodeAt() - a.charCodeAt()
        });
        var c = b.join("");

        let data = {
            stamp: time + "",  //时间戳
            version: version, //开通版本号 
            long: long,  //到期时间的时间戳
            shopCode: shopCode,  //需要开通的用户身份标识号
            token: utils.sha1Code(c),
        }
        this.sendPost(that, "http://mzl.mlmesm.cn/web/index.php?valid=1", data)
            .then(res => {
                let data = JSON.parse(JSON.parse(res));
                callback(data)
            })
            .catch(res => {
                utils.showMessageError("初始化商城失败");
            });
    }



    /**
     * 保存店铺设置开关
     * 
     */
    sendPost(that, url, data) {
        const _this = this;
        let p = new Promise(function (resolve, reject) {
            let map = new Map();
            map.set('url', url);
            map.set('data', JSON.stringify(data));
            $shopApi.post(APINames.API_sendPost, map, (res) => {
                resolve(res)
            }, _this.basseHttpFail, that);
        });
        return p;
    }


    //初始化  1 -商户 2- 店铺
    initializationPmsGoodsParameter(that, useType) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set("storeCode", t.storageTools.getUserCode());
            map.set("useType", useType);
            t.$mzlApi.post(t.APINames.API_inventory_initializationPmsGoodsParameter, map,
                (res) => {
                    // t.utils.hideLoadding();
                    resolve(res);
                },
                (res, tag) => {
                    t.basseHttpFail(res, tag)
                },
                that
            );
        })
        return p;
    }



    //更换手机号
    updateUserPhone(that, newPhone, userCode, checkCode) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("phone", newPhone);
            map.set("userCode", userCode);
            map.set("checkCode", checkCode);
            $userApi.post(t.APINames.API_changePhone, map, (res) => {
                resolve(res);
            }, (res, tag) => {
                t.basseHttpFail(res, tag)
            }, noCheck);

        })
        return p;
    }


    //使用店铺短信发送短信
    storeRequestCheck(that, account, sense) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('sense', sense || 1);
            map.set('account', account);
            map.set('checkType', 'phone');
            map.set('storeCode', t.storageTools.getUserCode());
            $mzlApi.post(t.APINames.API_storeRequestCheck, map, (res) => {
                resolve(res);
            }, (res, tag) => {
                t.basseHttpFail(res, tag)
            }, noCheck);

        })
        return p;
    }

    //验证验证码
    verifyCheck(that, account, checkCode, sense) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('sense', sense || 1);
            map.set('account', account);
            map.set('checkType', 'phone');
            map.set('code', checkCode);
            $mzlApi.post(t.APINames.API_VerifyCheck, map, (res) => {
                resolve(res);
            }, (res, tag) => {
                t.basseHttpFail(res, tag)
            }, noCheck);

        })
        return p;
    }

    //获取额外支付方式
    getPayExtraMethod(that) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("storeCode", t.storageTools.getUserCode());
            $mzlApi.post(t.APINames.API_getPayExtraMethod, map, (res) => {
                if (res != null && res.Data != null && res.Data.data != null) {
                    resolve(JSON.parse(res.Data.data));
                } else {
                    t.basseHttpFail(res);
                }

            }, (res, tag) => {
                t.basseHttpFail(res, tag)
            }, noCheck);

        })
        return p;
    }

    //保存额外支付方式
    savePayExtraMethod(that, method) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("storeCode", t.storageTools.getUserCode());
            map.set("method", JSON.stringify(method));
            $mzlApi.post(t.APINames.API_savePayExtraMethod, map, (res) => {

            }, (res, tag) => {
                t.basseHttpFail(res, tag)
            }, noCheck);

        })
        return p;
    }




    basseHttpFail(res, tag) {
        utils.showMessageError(res);
    }

}