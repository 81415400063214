import React from 'react'
import { Icon, Input, Button, Tooltip, Radio, Select, Table, Pagination,Checkbox } from 'antd'
import IButton from '@components/IButton'
import IDrawer from '@components/iDrawer'
import ISearch from '@components/ISearch'
import ITableList from '@components/iTableList'
import { IModal } from '@components/iModal'
import IInputString from '@components/iInputString'
import {IInputNumber,IInputMoney} from '@components/iInput'
import StorageTools from "@comm/storageTools";
import { $mzlApi } from "@comm/mzl-core";
import { messageError, messageSuccess } from '@comm/utils';
import ITablePage, { getPageSize, setTabTop } from '@components/iTablePage'
import { FnMenuId } from '@comm/constans'
import APINames from "@comm/api-names"
import { connect } from 'react-redux'
import { setDefaultTitle } from "@reducers/iconRedux";
import { Tabs } from 'antd';
import { goodsMode } from "@js/goods/action";
import { update } from "@js/goods/goodsManage/action";

import ItemVipView from "@js/settings/itemVipView";
import ItemRoleView from "@js/settings/itemRoleView";
import service from "@js/goods/service";
import _ from "lodash";
const { Option } = Select;
const { TabPane } = Tabs;
const { Search } = Input;
const reduxStaste = (state) => {
    return {
        ...state.goodsManageData,
        ...state.goodsData,
        ...state.iconRedux,
    }
}
@connect(
    state => reduxStaste(state),
    { setDefaultTitle, goodsMode,update }
)
class modelType extends React.Component {
    constructor() {
        super();
        this.state = {
            loading:false
        }



    }

    componentDidMount() {
        
    }

    selectChange() {

    }
   

    onShowSizeChange() {

    }

    savePmsGoodsParameter(){
        const that = this;
        const {mode,goodsManageDedail,goodsManageFlag} = this.props;
        goodsManageDedail.ParameterType = goodsManageFlag;
        goodsManageDedail.UseType = mode ==="mch"?1:2;
        this.setState({loading:true})
        service.savePmsGoodsParameter(this,goodsManageDedail)
        .then(res=>{
            this.setState({loading:false})
            that.props.queryPmsGoodsParameter(that,goodsManageFlag);
            that.props.update({goodsManageTypeVisible:false})
        })
      }


    onClickListener(e){
        if(e === "drawer-ok"){
            // this.props.onClickListener();
            // this.props.update({
            //     goodsManageTypeVisible:false,
            // })
            this.savePmsGoodsParameter();
            
        }else{
            this.props.update({
                goodsManageTypeVisible:false,
            })
        }
        // this.props.update({
        //     goodsManageTypeVisible:false,
        // })
        
    }   
    onChange(e) {
        // console.log(123123,e)
        const {goodsManageDedail} = this.props;
        goodsManageDedail.ParameterName = e;
        this.props.update({
            goodsManageDedail:_.clone(goodsManageDedail)
        })
    }
    render() {

        const {goodsManageDedail} = this.props;

        return (
            <IDrawer
                title={this.props.goodsManageTypeTitle} 
                footer={true} 
                onClickListener={this.onClickListener.bind(this)}
                width={400} 
                loading={this.state.loading}
                visible={this.props.goodsManageTypeVisible}
            >
                 <div className="comm-row comm-item-center">
                    <span>{this.props.goodsManageTypeName}</span>
                    <div style={{marginLeft:34}} className="comm-row comm-item-center">
                        <IInputString 
                            style={{width:200,marginRight:10}}
                            value={goodsManageDedail.ParameterName}
                            maxLength={16}
                            onChange={this.onChange.bind(this)}/>
                    </div>
                            
                </div>

            </IDrawer>

        )
    }
}

export default modelType