import ChainConsumeService from "../service/chainConsumeService";
import storageTools from "../../../../js/comm/storageTools";

export const type={
    UPDATE_STATE:'UPDATE_STATE'
}

export function queryMchManagerChainCards(that){

    let map = new Map();
    map.set("mchCode", storageTools.getMchCode());
    map.set("storeCode", storageTools.getUserCode());
    map.set("isSelf", false);
    ChainConsumeService.queryMchManagerChainCards(that,map).then((res)=>{
        return{
            type:type.UPDATE_STATE,
            res
        }
        // console.log("1111ssss",res);
    });
   
    return {type: type.UPDATE_STATE,map};
}




export function saveMchManagerChainCards(that,data){
    // /** 卡类型Id */
    // 1:i32 Id
    // /** 商户Id */
    // 2:i32 MchId
    // /** 店铺Id */
    // 3:i32 StoreId
    // /** 不可跨店消费卡类型ID */
    // 4:i32 UnCardTid
    // /** 状态 */
    // 5:i32 State
    // debugger
    let arr = [];
    data.Items.forEach(item=>{
        if(!item.checked){
            let obj = {};
            obj.StoreId = item.Id;
            obj.UnCardTid = data.Id;
            obj.State = this.IEnum.ChainState.Agreed;
            arr.push(obj);
        }
    })
    // this.setState({loading:true})
    //  that.state.loading = true;
    const t = this;
    saveMchManagerChainCards(JSON.stringify(arr),that).then(()=>{
        // t.setState({loading:false});
        t.utils.messageSuccess("保存成功！")
        t.utils.hideLoadding();
    })


    return{
        type:type.UPDATE_STATE,
    }
    



}