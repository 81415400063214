/* eslint-disable no-unused-expressions */
import React from 'react';
import { Button, Checkbox, Form, Icon, Input, Radio, Select, Tabs, Tooltip, Table, Switch } from 'antd';
import { connect } from 'react-redux'
import * as utils from "../../../../js/comm/utils";
import {
    countConvert,
    dataLineConvert,
    discountTypeConvert,
    hideLoadding,
    messageError,
    moneyConvert,
    numberConvert,
    numberConvert_100,
    numConvert,
    parseIntX100,
    showLoadding,
    showMessageError
} from "../../../../js/comm/utils";
import InputMoney from '../../../../js/components/iInputMoney'
import APINames from "../../../../js/comm/api-names"
import { FnMenuId, money_max } from "../../../../js/comm/constans"
import { setDefaultTitle } from "../../../../js/reduxs/reducers/iconRedux";
import StorageTools from "../../../../js/comm/storageTools";
import { $mzlApi } from "../../../../js/comm/mzl-core";
import iEnum, { ECardFlag, ECardKind, EItemType } from "../../../../js/comm/iEnum";
import IButton from '../../../../js/components/IButton'
import IInputString from '../../../../js/components/iInputString'
import ITablePage, { getPageSize, setTabTop } from '../../../../js/components/iTablePage'
import IDrawer from "../../../../js/components/iDrawer";
import { IModal } from '../../../../js/components/iModal'
import "../../../../css/card.css"
import "../../../../css/tab.css"

let begin = 0;
let over = getPageSize();
let maxNum = 99999;

const Option = Select.Option;
const TabPane = Tabs.TabPane;
const Search = Input.Search;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        sm: { span: 5 },
    },
    wrapperCol: {
        sm: { span: 18 },
    },
};
var 总次数 = 0;
var 门市价 = 0;
var 优惠 = 0;
var 套餐卡售价 = 0;
var 套餐卡总成本 = 0;
var slidingKey = 1;
var h = document.documentElement.clientHeight - 220;
let mchCode = '';
/**
 * 会员卡设置
 */
@connect(
    state => state.iconRedux,
    { setDefaultTitle }
)
class secondCardSettingView extends React.Component {
    constructor() {
        super();
        this.state = {
            /**岗位提成数组 */
            postRateList: [],
            /**展开收起 */
            isShowAll: false,
            isShowAll2: false,
            /**是否储值 */
            isStorage: true,
            /**提成或金额标志 */
            tcFlag: 3,
            /**开卡提成显示隐藏 */
            kkDisabled: false,
            /**充值提成显示隐藏 */
            czDisabled: false,
            /**岗位列表 */
            postList: [],
            disableAll: false,
            secondTitle: '新增套餐卡类型',
            ExpiresDay: 0,
            disabled: false,
            cardKind: null,
            visible: false,
            visible2: false,
            visible3: false,
            visible4: false,
            formData: null,
            isDelete: null,
            modalData: this.initCard(),
            PromBy: 2,
            RadioState: 0,
            editRecord: this.initCard(),
            defaultValue: 2,
            item: [],
            itemData: [],
            cardItem: [],//套餐卡
            openCard: {},//套餐卡
            vipCount: 0,
            isVip: true,
            title: '添加套餐卡',
            totalCount: 0,//总次数
            totalCost: 0,//总成本
            totalMoney: 0,//套餐卡总售价
            singleMoney: 0,//单次价格
            enabled: 0,
            tipMsg: '套餐卡内项目各自独立使用，提成或业绩按套餐卡价计算',
            mealCount: 1,
            columns2: [{
                title: '套餐卡名称',
                dataIndex: 'CardName',
                align: 'center',
                className: 'time',
                render: (text) => (<Tooltip title={text}>{text}</Tooltip>)
            }, {
                title: '计次方式',
                dataIndex: '_CardFlag',
                align: 'center',
            }, {
                title: '次数',
                dataIndex: '_InitialValue',
                align: 'center',
            }, {
                title: '售价',
                dataIndex: 'SalePrice',
                align: 'center',
                render: (text) => (<div>￥{text / 100}</div>)
            }, {
                title: '有效期',
                dataIndex: '_ExpiresDay',
                align: 'center',
                className: 'time',
                render: (text) => (<Tooltip title={text}>{text}</Tooltip>)
            }, {
                title: '状态',
                dataIndex: 'Enable',
                align: 'center',
                render: (text, record) => (<div>{record.Enabled == 1 ? '已启用' : '已停用'}</div>)
            }, {
                title: '操作',
                dataIndex: 'Enabled',
                align: 'center',
                width: '19%',
                render: (text, record) => (
                    <div style={{display:'flex',justifyContent:'space-around'}}>
                        <a onClick={this.stopUse.bind(this, record, 2)}>{record.StatusText == '已启用'?'停用':'启用'}</a>
                        {/* <Switch onChange={this.changeListener.bind(this, '启用禁用', record)} checked={text == 1 ? true : false}></Switch> */}
                        <a onClick={this.editVipType.bind(this, record)}>编辑</a>
                        <a onClick={this.deleteVip.bind(this, record, 2)}>删除</a>
                        
                    </div>
                )
            }],
            columns3: [{
                title: '项目名称',
                dataIndex: 'Title',
                key: 'prjName',
                align: 'center',
                className: 'time',
                render: (text) => (<Tooltip title={text}>{text}</Tooltip>)
            }, {
                title: '成本价',
                dataIndex: 'cbPrice',
                key: 'mPrice',
                align: 'center',
                render: (text) => (<dv>{numberConvert(text).toFixed(2)}</dv>)
            }, {
                title: '门市价',
                dataIndex: 'UnitPrice',
                key: '12',
                align: 'center',
                render: (text) => (<dv>{numberConvert(text).toFixed(2)}</dv>)
            }, {
                title: '操作',
                dataIndex: 'delete',
                key: 'delete',
                align: 'center',
                render: (text, record) => (
                    <span style={{ color: '#FF6666' }}
                        onClick={this.onClickListener.bind(this, 'API_deleteItem', record)}>删除</span>
                )
            }],
            loading:false,
            isDeit:false


        }
    }

    initCard() {
        var data = {
            Id: 0,
            CardName: '',
            Items: [],
            //1会员卡，2套餐卡
            CardKind: 2,
            CardFlag: 8,
            SalePrice: 0,
            Cost: 0,
            InitialValue: 0,
            TotalValue: 0,
            Enabled: 1,
            Rate: {
                /** 优惠方式,1:不打折 2:折扣 3:赠送礼金 */
                /** 优惠折扣 */
                PromDiscount: 0,
                /** 优惠礼金 */
                PromBonus: 0,
            },
            sCardTypeAward: {
                Id: 0,
                CardTid: 0,
                CardValue: 0,
                CardBasis: 3,
                CardSettingsBasis: 2,
                ChargeValue: 0,
                ChargeBasis: 3,
                ChargeSettingsBasis: 2,
                CardUcardValue: 0,
                sCardTypeAwardPost: []
            }
        };
        return data;
    }

    componentDidMount() {
        setTabTop(180)
        over = getPageSize();
        this.requestCardType(2);
        // this.requestQueryItem();
        // this.requestQueryCardItem()
        this.queryMchManagerItem()
    }
    componentWillMount() {
        mchCode = StorageTools.getMchCode()
        this.props.setDefaultTitle({ titleKey: FnMenuId.head_chain_setting, slidingLeftKey: "1" })
    }

    stopUse(record){
        let state = 1;
        if(record.StatusText == '已启用'){
          state = 2
        }
        this.enabledMchManagerCard(record.Id,state)
        //console.log(1111,record)
      }
     /**
   * 停用或启用会员卡
   */
  enabledMchManagerCard(id,state) {
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("typeId", id);
    map.set("state", state);//1启用 2停用
   
    $mzlApi.post(
      APINames.API_enabledMchManagerCard,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }
  deleteVip(record){
    
    this.deleteMchManagerCard(record.Id)
  }
   /**
   * 删除会员卡
   */
  deleteMchManagerCard(id) {
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("id", id);
   
    $mzlApi.post(
      APINames.API_deleteMchManagerCard,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

    // /**
    //  *获取服务
    //  */
    // requestQueryItem(search) {
    //     var map = new Map();
    //     map.set('storeCode', StorageTools.getUserCode());
    //     map.set('productType', EItemType.Virtual);
    //     map.set('opt', JSON.stringify({ Begin: 0, Over: 999, Opt: { "shelve_state": "3", "keyword": search } }));
    //     // map.set('opt', JSON.stringify({Begin: 0, over: 999,Opt:{shelve_state:"3"}));
    //     $mzlApi.post(APINames.API_queryItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    // }


      /**
     * 查询服务mchcode
     */
    queryMchManagerItem() {
        var map = new Map();
        map.set('mchCode', mchCode);
        map.set('productType', EItemType.Virtual);
        map.set('opt', JSON.stringify({Begin: begin, Over: 999, Opt: {enabled: 1, keyword: ''}}));
        $mzlApi.post(APINames.API_queryMchManagerItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    /**
     *
     */
    requestQueryCardItem() {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('productType', EItemType.Normal);
        map.set('opt', JSON.stringify({ Begin: 0, over: 999 ,Opt: {"shelve_state": "3",enabled:1}}));
        $mzlApi.post(APINames.API_queryItem, map, (res) => {
            //////console.log('商品',JSON.parse(res.Data))
            var num = 1;
            var data = JSON.parse(res.Data);
            data.forEach(item => {
                item.key = num;
                num++;
                item._str = item.Title + '(' + numberConvert(item.Price) + '元' + ')'
            });
            this.setState({ cardItem: data })
        }, this.httpFail.bind(this), this);
    }

    // /**
    //  * 获取会员卡类型
    //  */
    // requestCardType(kind) {
    //     showLoadding();
    //     var map = new Map();
    //     map.set('storeCode', StorageTools.getUserCode());
    //     map.set('kind', kind);
    //     map.set('opt', JSON.stringify({ Begin: begin, Over: over, Opt: { enabled: this.state.enabled } }));
    //     $mzlApi.post(APINames.API_queryMchCardList, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    // }


      /**
     * 获取会员卡类型mchcode
     */
    requestCardType(kind) {
        showLoadding();
        var map = new Map();
        map.set('mchCode', mchCode);
        map.set('kind', kind);
        map.set('opt', JSON.stringify({ Begin: begin, Over: over, Opt: { enabled: this.state.enabled } }));
        $mzlApi.post(APINames.API_queryMchManagerCardList, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }
    /**
   * 会员明细 
   */
    requsetAPI_getMchCard(id) {
        showLoadding()
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('id', id);
        $mzlApi.post(APINames.API_getMchCard, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    /**
     * 删除会员卡类型\套餐卡
     */
    requestDeleteMchCard(id) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('id', id);
        $mzlApi.post(APINames.API_deleteMchCard, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    /**
     * 新增会员卡
     * @param {*} obj
     */
    requestSaveMchCard(data) {
        this.setState({loading:true});
        var map = new Map();
        map.set('mchCode', mchCode);
        map.set('card', JSON.stringify(data));
        //////console.log('发的是公司',data)
        $mzlApi.post(APINames.API_saveMchManagerCard, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    /**
     * 查询岗位列表
     */
    requestStoreRole() {
        showLoadding();
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        $mzlApi.post(APINames.API_getStoreRole, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    /**
     * 查询岗位列表
     */
    requestStoreRole2() {
        showLoadding();
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        $mzlApi.post(APINames.API_getStoreRole, map, (res) => {
            hideLoadding();
            if (this.state.editRecord) {
                res.forEach(item => {
                    this.state.editRecord.sCardTypeAward.sCardTypeAwardPost.forEach(val => {
                        if (item.RoleId == val.PostId) {
                            item.CardRate = val.CardValue;
                            item.ChargeRate = 0;
                            if (val.CardBasis == 2) { 
                                item._flag = false
                            } else {
                                item._flag = true
                            }
                            item._CardBasis = val.CardBasis
                        }
                    })
                })
            }
            this.setState({ postList: res, visible3: true, })
        }, this.httpFail.bind(this), this);
    }

    /**
     * 判断会员卡是否使用
     */
    API_checkCardIsUsed(mchId, cardId) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('cardId', cardId);
        $mzlApi.post(APINames.API_checkCardIsUsed, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }
    /**
     * 启用禁用
     * @param {*} id 
     * @param {*} state 
     */
    requestAPI_EnabledMchCard(id, state) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('typeId', id);
        map.set('state', state)
        $mzlApi.post(APINames.API_EnabledMchCard, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    httpSuccess(res, tag) {
        //////console.log('成功', res, tag);
        switch (tag) {
            case APINames.API_queryMchCardList:
                hideLoadding();
                //console.log('获取', JSON.parse(res.Data))
                this.setState({ vipCount: res.Count });
                if (res != null || res.Data != null) {
                    var list = JSON.parse(res.Data);
                    let num = 1;
                    list.forEach((item) => {
                        item.num = num;
                        item.InitialValueText = countConvert(item.InitialValue);
                        item.ExpiresDayText = dataLineConvert(item.ExpiresDay);
                        item.Rate.PromDiscountText = numberConvert(item.Rate.PromDiscount);
                        item.SalePriceText = item.SalePrice / 100 + '元';
                        if (item.Rate.PromBy == 1) {
                            item.Rate.PromBonusText = '不打折'
                        } else if (item.Rate.PromBy == 2) {
                            item.Rate.PromBonusText = discountTypeConvert(item.Rate.PromBy) + numberConvert(item.Rate.PromDiscount) + '折';
                        } else if (item.Rate.PromBy == 3) {
                            item.Rate.PromBonusText = discountTypeConvert(item.Rate.PromBy) + item.Rate.PromBonus / 100 + '元';
                        }
                        if (item.CardFlag == 8) {
                            item._CardFlag = '单项计次'
                        } else if (item.CardFlag == 16) {
                            item._CardFlag = '套餐扣次'
                        } else if (item.CardFlag == 32) {
                            item._CardFlag = '整体计次'
                        } else if (item.CardFlag == 64) {
                            item._CardFlag = '无限次数'
                        }
                        item.Rate.PromByText = discountTypeConvert(item.Rate.PromBy);
                        item.key = num;
                        num++;
                        if (item.CardFlag == 64) {
                            item._InitialValue = '无限'
                        } else {
                            item._InitialValue = item.InitialValue + '次';
                        }

                        if (item.ExpiresDay == 0) {
                            item._ExpiresDay = '永久有效';
                        } else {
                            item._ExpiresDay = '自办卡之日起' + item.ExpiresDay + '天有效';
                        }

                    });
                    this.setState({
                        data: list,
                        data2: list
                    })
                }
                // //////console.log('转化后的数据',this.state.data[0].Rate.PromBy)
                break;
            case APINames.API_queryMchManagerCardList:
                    hideLoadding();
                    //console.log('获取', JSON.parse(res.Data))
                    this.setState({ vipCount: res.Count });
                    if (res != null || res.Data != null) {
                        var list = JSON.parse(res.Data);
                        let num = 1;
                        list.forEach((item) => {
                            item.StatusText = item.Enabled == 1?'已启用':'已停用'
                            item.num = num;
                            item.InitialValueText = countConvert(item.InitialValue);
                            item.ExpiresDayText = dataLineConvert(item.ExpiresDay);
                            item.Rate.PromDiscountText = numberConvert(item.Rate.PromDiscount);
                            item.SalePriceText = item.SalePrice / 100 + '元';
                            if (item.Rate.PromBy == 1) {
                                item.Rate.PromBonusText = '不打折'
                            } else if (item.Rate.PromBy == 2) {
                                item.Rate.PromBonusText = discountTypeConvert(item.Rate.PromBy) + numberConvert(item.Rate.PromDiscount) + '折';
                            } else if (item.Rate.PromBy == 3) {
                                item.Rate.PromBonusText = discountTypeConvert(item.Rate.PromBy) + item.Rate.PromBonus / 100 + '元';
                            }
                            if (item.CardFlag == 8) {
                                item._CardFlag = '单项计次'
                            } else if (item.CardFlag == 16) {
                                item._CardFlag = '套餐扣次'
                            } else if (item.CardFlag == 32) {
                                item._CardFlag = '整体计次'
                            } else if (item.CardFlag == 64) {
                                item._CardFlag = '无限次数'
                            }

                            item.Rate.PromByText = discountTypeConvert(item.Rate.PromBy);
                            item.key = num;
                            num++;
                            if (item.CardFlag == 64) {
                                item._InitialValue = '无限'
                            } else {
                                item._InitialValue = item.InitialValue + '次';
                            }
    
                            if (item.ExpiresDay == 0) {
                                item._ExpiresDay = '永久有效';
                            } else {
                                item._ExpiresDay = '自办卡之日起' + item.ExpiresDay + '天有效';
                            }
    
                        });
                        this.setState({
                            data: list,
                            data2: list
                        })
                    }
                break;
            case APINames.API_deleteMchCard:
                if (res.ErrCode == 0) {
                    //////console.log(this.state.isDelete)
                    this.requestCardType(this.state.isDelete.CardKind);
                }
                break;
            case APINames.API_saveMchCard:
               this.setState({loading:false});
                this.setState({ kkDisabled: false });
                if (res.ErrCode == 0) {
                    this.requestCardType(2);
                }
                break;
            case APINames.API_queryItem:
                //////console.log('成功', JSON.parse(res.Data), tag);
                var data = JSON.parse(res.Data);
                var num = 1;
                data.forEach(item => {
                    item.key = num;
                    num++;
                    item._str = item.Title + '(' + numberConvert(item.Price) + '元' + ')'
                });
                this.setState({ item: data });
                break;
            case APINames.API_queryMchManagerItem:
                    var data1 = JSON.parse(res.Data);
                    //console.log(589589,data1.length)
                    var num = 1;
                    data1.forEach(item => {
                        item.key = num;
                        num++;
                        item._str = item.Title + '(' + numberConvert(item.Price) + '元' + ')'
                    });
                    this.setState({ item: data1 });
                    break;
                break;
            case APINames.API_getStoreRole:
                hideLoadding();
                //////console.log('岗位列表',res,tag)
                var arr = [];
                var num = 1;
                res.forEach(item => {
                    item.key = num;
                    num++;
                    item._flag = true;
                    item._CardBasis = 3;
                    arr.push({
                        Id: 0,
                        CardAwardId: 0,
                        PostId: item.RoleId,
                        CardValue: item.CardRate,
                        CardBasis: item._CardBasis,
                        ChargeValue: 0,
                        ChargeBasis: 3
                    })
                });
                this.setState({ visible3: true, postList: res, postRateList: arr });
                break;
            case APINames.API_checkCardIsUsed:
                //////console.log('判断套餐卡',res,tag);
                if (res.ErrCode == 0) {
                    this.setState({ disableAll: false, disabled: false })
                }
                break;
            case APINames.API_getMchCard:
                //console.log(res)
                this.state.postRateList = res.sCardTypeAward.sCardTypeAwardPost;
                this.state.editRecord = res;
                this.state.totalMoney = res.TotalValue;
                res._Cost=numberConvert(res.Cost)
                res._InitialValue=res.InitialValue
                res._TotalValue=numberConvert(res.TotalValue)
                if(res.InitialValue==0){
                    this.state.singleMoney=0
                }else {
                    this.state.singleMoney=res.TotalValue/res.InitialValue/100
                }
                this.state.itemData = [];
                res.Items.forEach(item => {
                    item.isEdit = true;
                    this.state.item.every((i) => {
                        if (item.ItemId == i.ItemId) {
                            item.isEdit = false;
                            return false
                        }
                        return true;

                    });
                    if (item.isEdit) {
                        this.state.cardItem.every((c) => {
                            if (item.ItemId == c.ItemId) {
                                item.isEdit = false;
                                return false
                            }
                            return true
                        });
                    }


                    item.Title = item.ItemTitle;
                    this.state.itemData.push(item)
                });
                if (res.ExpiresDay == 0) {
                    this.state.RadioState = 1;
                    this.state.ExpiresDay = res.ExpiresDay;
                    this.state.disabled = true
                } else {
                    this.state.RadioState = 2;
                    this.state.ExpiresDay = res.ExpiresDay;
                    this.state.disabled = false
                }
                this.setState({ itemData: this.state.itemData, CardName: res.CardName, disabled: this.state.disabled,singleMoney:this.state.singleMoney });
                this.setState({ visible4: true });
                // let key=record.key;
                //////console.log('record', record)
                if (res.sCardTypeAward.CardSettingsBasis == 2) {
                    this.setState({ kkDisabled: false })
                } else {
                    this.setState({ kkDisabled: true })
                }
                if (res.sCardTypeAward.ChargeSettingsBasis == 2) {
                    this.setState({ czDisabled: false })
                } else {
                    this.setState({ czDisabled: true })
                }
                if (res.CardFlag == 2) {
                    this.setState({ isStorage: false })
                } else {
                    this.setState({ isStorage: true })
                }
                if (res.SalePrice == 0) {
                    this.setState({ defaultValue: 1, disabled: true });
                } else {
                    this.setState({ defaultValue: 2, disabled: false })
                }
                res.sCardTypeAward._CardValue=numberConvert(res.sCardTypeAward.CardValue)
                res.sCardTypeAward._CardUcardValue=numberConvert(res.sCardTypeAward.CardUcardValue)
                this.setState({
                    postRateList: res.sCardTypeAward.sCardTypeAwardPost,
                    editRecord: res,
                    PromBy: res.Rate.PromBy,
                    modalData: res,
                    tcFlag: res.sCardTypeAward.CardBasis
                });
                this.requestStoreRole2()
                break
            case APINames.API_getMchManagerCard:
                    hideLoadding()
                    //console.log(res)
                    //console.log(this.state.item)
                    if(res.IsUsed == 1){
                        // 1使用2未使用
                        // messageError('该卡已被使用,不能编辑！')
                        // return;
                        this.setState({disableAll:true})
                    }
                    this.state.postRateList = res.sCardTypeAward.sCardTypeAwardPost;
                    this.state.editRecord = res;
                    this.state.totalMoney = res.TotalValue;
                    res._Cost=numberConvert(res.Cost)
                    res._InitialValue=res.InitialValue
                    res._TotalValue=numberConvert(res.TotalValue)
                    if(res.InitialValue==0){
                        this.state.singleMoney=0
                    }else {
                        this.state.singleMoney=res.TotalValue/res.InitialValue/100
                    }
                    this.state.itemData = [];
                    res.Items.forEach(item => {
                        item.isEdit = true;
                        this.state.item.every((i) => {
                            i.ItemId = i.Id
                            if (item.ItemId == i.ItemId) {
                                item.isEdit = false;
                                return false
                            }
                            return true;
    
                        });
                        if (item.isEdit) {
                            this.state.cardItem.every((c) => {
                                if (item.ItemId == c.ItemId) {
                                    item.isEdit = false;
                                    return false
                                }
                                return true
                            });
                        }
    
    
                        item.Title = item.ItemTitle;
                        this.state.itemData.push(item)
                    });
                    if (res.ExpiresDay == 0) {
                        this.state.RadioState = 1;
                        this.state.ExpiresDay = res.ExpiresDay;
                        this.state.disabled = true
                    } else {
                        this.state.RadioState = 2;
                        this.state.ExpiresDay = res.ExpiresDay;
                        this.state.disabled = false
                    }
                    this.setState({ itemData: this.state.itemData, CardName: res.CardName, disabled: this.state.disabled,singleMoney:this.state.singleMoney });
                    this.setState({ visible4: true });
                    // let key=record.key;
                    //////console.log('record', record)
                    if (res.sCardTypeAward.CardSettingsBasis == 2) {
                        this.setState({ kkDisabled: false })
                    } else {
                        this.setState({ kkDisabled: true })
                    }
                    if (res.sCardTypeAward.ChargeSettingsBasis == 2) {
                        this.setState({ czDisabled: false })
                    } else {
                        this.setState({ czDisabled: true })
                    }
                    if (res.CardFlag == 2) {
                        this.setState({ isStorage: false })
                    } else {
                        this.setState({ isStorage: true })
                    }
                    if (res.SalePrice == 0) {
                        this.setState({ defaultValue: 1, disabled: true });
                    } else {
                        this.setState({ defaultValue: 2, disabled: false })
                    }
                    res.sCardTypeAward._CardValue = res.sCardTypeAward.CardValue /100
                    res.sCardTypeAward._CardUcardValue = res.sCardTypeAward.CardUcardValue /100
                    this.setState({
                        postRateList: res.sCardTypeAward.sCardTypeAwardPost,
                        editRecord: res,
                        // PromBy: res.Rate.PromBy,
                        modalData: res,
                        tcFlag: res.sCardTypeAward.CardBasis,
                        visible3: true,
                    });
                break;
            case APINames.API_EnabledMchCard:
                this.requestCardType(2)
                break;
                case APINames.API_deleteMchManagerCard:
                        hideLoadding();
                        this.requestCardType(2);
                      break;
            case APINames.API_enabledMchManagerCard:
                            hideLoadding();
                            this.requestCardType(2);
                           //console.log('启用或者停用',res)
                           break;
            case APINames.API_saveMchManagerCard:
                    this.setState({loading:false});
                    this.requestCardType(2);
                break;
        }
    }

    httpFail(res, tag) {
       this.setState({loading:false});
        //////console.log("失败", res, tag)
        switch (tag) {
            case APINames.API_saveMchCard:
                // //console.log('新增会员卡失败后返回的数据', res, tag);
                showMessageError(res, tag)
                // if (slidingKey == 2) {
                    this.requestCardType(2);
                //     slidingKey = 1;
                // } else {
                    // this.requestCardType(1);
                // }
                break;
            case APINames.API_checkCardIsUsed:
                //////console.log('判断失败',res,tag)
                if (res.ErrCode) {
                    this.setState({ disableAll: true, disabled: true })
                }
                break;
            case APINames.API_deleteMchManagerCard:
                    showMessageError(res, tag)
                break;
            default:
                showMessageError(res, tag)
        }
    }


    /**
     * 编辑会员卡类型
     */
    editVipType(record) {
        //console.log(record)
        this.setState({ title: '编辑套餐卡',isDeit:true });
        this.getMchManagerCard(record.Id)
        // this.requsetAPI_getMchCard(record.Id)
        // this.API_checkCardIsUsed(record.MchId, record.Id);
    }
    /**
   * 会员明细新的
   */
  getMchManagerCard(id) {
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("id", id);
    $mzlApi.post(
      APINames.API_getMchManagerCard,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

    /**
     * 删除——对话框
     */
    delete(record, num) {
        //////console.log("record", record)
        if (num == 1) {
            this.setState({ cardKind: '会员卡' })
        } else {
            this.setState({ cardKind: '套餐卡' })
        }
        // this.state.isdelete=record;
        this.setState({ visible2: true, vipName: record.CardName, isDelete: record })

    }

    onClickListener(tag, data) {
        //////console.log(tag, data);
        switch (tag) {
            /**
             * 确认删除
             */
            case 'delete_confirm':
                this.setState({ visible2: false });
                this.requestDeleteMchCard(this.state.isDelete.Id);
                break;
            case 'addVipCard':
                this.requestStoreRole();
                this.setState({
                    isDeit:false,
                    title: '添加套餐卡',
                    disableAll: false,
                    editRecord: {},
                    visible4: true,
                    isStorage: true,
                    PromBy: 2,
                    editRecord: this.initCard(),
                    disabled: false,
                    modalData: this.initCard(),
                    itemData: [],
                    CardName: '',
                    RadioState: 0,
                    ExpiresDay: 0,
                    totalCount: 0,//总次数
                    totalCost: 0,//总成本
                    totalMoney: 0,//套餐卡总售价
                    singleMoney: 0,//单次价格
                });
                break;
            case 'addSecondCard':
                this.setState({
                    visible4: true,
                    secondTitle: '新增套餐卡类型',
                    itemData: [],
                    CardName: '',
                    RadioState: 0,
                    ExpiresDay: 0,
                    openCard: {}
                });
                break;
            case 'drawer-ok':
                if (this.state.modalData.CardName == '') {
                    messageError('请输入套餐卡名称');
                    return;
                }
                if (this.state.RadioState != 1) {
                    if (this.state.ExpiresDay == undefined || this.state.ExpiresDay == "" || this.state.ExpiresDay == "0") {
                        messageError("请输入有效期");
                        return;
                    } else {
                        this.state.openCard.ExpiresDay = parseInt(this.state.ExpiresDay)
                    }
                } else {
                    this.state.openCard.ExpiresDay = 0
                }
                if (this.state.itemData.length == 0) {
                    messageError('请添加套餐卡项目');
                    return;
                }
                //防止暴力点击
                if (!utils.getReqeustState()) {
                    return;
                }
               
                this.state.modalData.CardName = this.state.CardName;
                this.state.modalData.CardKind = ECardKind.TimeCard;
                this.state.modalData.Items = this.state.itemData;

                if (this.state.totalCount) {
                    this.state.modalData.InitialValue = this.state.totalCount;
                } else {
                    // if(this.state.modalData.CardFlag==16){
                    //     this.state.modalData.InitialValue = 总次数
                    // }else {
                    this.state.modalData.InitialValue = 总次数;
                    // }

                }
                if (this.state.modalData.CardFlag == 64) {
                    this.state.modalData.TotalValue = this.state.totalMoney;
                    this.state.modalData.SalePrice = this.state.totalMoney;
                } else {
                    if (this.state.modalData.CardFlag == 32) {
                        this.state.modalData.TotalValue = this.state.totalMoney;
                        this.state.modalData.SalePrice = this.state.totalMoney;
                    } else {
                        // if (this.state.totalMoney) {
                        //     this.state.modalData.TotalValue = this.state.totalMoney;
                        //     this.state.modalData.SalePrice = this.state.totalMoney;
                        // } else {
                            this.state.modalData.TotalValue = 套餐卡售价;
                            this.state.modalData.SalePrice = 套餐卡售价;
                        // }
                    }

                }

                if (this.state.totalCost) {
                    this.state.modalData.Cost = this.state.totalCost;
                } else {
                    this.state.modalData.Cost = 套餐卡总成本;
                }

                this.state.modalData.ExpiresDay = this.state.ExpiresDay;
                this.state.modalData.sCardTypeAward.sCardTypeAwardPost = this.state.postRateList;
                if(this.state.isDeit){
                    this.state.modalData.Items.map((item,index)=>{
                        item.CardItemId = 0//如果是编辑的时候,这个CardItemId参数全部为0
                    })
                }
                //console.log('最终的data', this.state.modalData)
                this.requestSaveMchCard(this.state.modalData);
                this.setState({ visible3: false, });
                break;
            case 'editMemberCardConfirm':
                //////console.log(this.state.editRecord);
                this.requestSaveMchCard(this.state.editRecord);
                this.setState({ visible: false });
                break;
            case 'clickItem':
                //console.log(data)
                data.ItemId = data.Id
                if (!this.state.disableAll) {
                    var isPush = true;
                    this.state.itemData.every((item) => {
                        if (item.ItemId == data.ItemId) {
                            data = item;
                            isPush = false;
                            return false;
                        }
                        return true;
                    });
                    if (isPush) {
                        if (this.state.modalData.CardFlag != 32) {
                            if (this.state.modalData.CardFlag != 64) {
                                if (this.state.modalData.CardFlag != 16) {
                                    data.Quota = 1;
                                } else {
                                    data.Quota = this.state.mealCount;
                                }

                            } else {
                                data.Quota = 0
                            }

                        } else {
                            data.Quota = 0;
                        }

                        data.UnitPrice = data.Price;
                        data.FinalPrice = data.Price;
                        data.TotalAmount = data.Price;
                        this.state.itemData.push(data)
                    } else {
                        if (this.state.modalData.CardFlag != 16) {
                            if (this.state.modalData.CardFlag != 32) {
                                if (this.state.modalData.CardFlag != 64) {
                                    data.Quota = data.Quota + 1;
                                    data.TotalAmount = data.FinalPrice * data.Quota;
                                    data.FinalPrice = data.TotalAmount / data.Quota;
                                }

                            }

                        }

                    }
                    

                    this.setState({ itemData: this.state.itemData });
                }
                break;
            case 'API_deleteItem':
                if (!this.state.disableAll) {
                    var arr = this.state.itemData;
                    arr.splice(arr.indexOf(data), 1);
                    this.setState({ itemData: arr });
                }
                break;
            case 'drawer-cancel':
                this.setState({ visible3: false, kkDisabled: false, czDisabled: false });
                break;
            case 'hideVisible':
                this.setState({ visible: false });
                this.requestCardType(1);
                break;
            case 'hideVisible4':
                this.setState({ visible4: false });
                this.requestCardType(2);
                break;
            case '套餐卡编辑':
                this.state.openCard = data;
                this.state.itemData = [];
                this.setState({ secondTitle: '编辑套餐卡' });
                data.Items.forEach(item => {
                    item.isEdit = true;
                    this.state.item.every((i) => {
                        if (item.ItemId == i.ItemId) {
                            item.isEdit = false;
                            return false
                        }
                        return true;

                    });
                    if (item.isEdit) {
                        this.state.cardItem.every((c) => {
                            if (item.ItemId == c.ItemId) {
                                item.isEdit = false;
                                return false
                            }
                            return true
                        });
                    }


                    item.Title = item.ItemTitle;
                    this.state.itemData.push(item)
                });
                if (data.ExpiresDay == 0) {
                    this.state.RadioState = 1;
                    this.state.ExpiresDay = data.ExpiresDay;
                    this.state.disabled = true
                } else {
                    this.state.RadioState = 2;
                    this.state.ExpiresDay = data.ExpiresDay;
                    this.state.disabled = false
                }
                this.setState({ itemData: this.state.itemData, CardName: data.CardName });
                this.setState({ visible4: true });
                break;
            case '开卡金额聚焦':
                data.target.value = '';
                break;
            case '折扣聚焦':
                data.target.value = ''
        }
    }

    changeListener(tag, e, event) {
        let data = this.state.modalData;
        let recordData = this.state.editRecord;
        switch (tag) {
            case 'all':
                this.setState({ isShowAll: !this.state.isShowAll });
                break;
            case 'all2':
                this.setState({ isShowAll2: !this.state.isShowAll2 });
                break;
            case 'CardName':
                // e.target.value = e.target.value.replace(/[^\u4E00-\u9FA5\uF900-\uFA2D\d]/g, "");  //清除“数字”和“.”以外的字符
                // e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                e.target.value = e.target.value.replace(/[\@\#\$\%\^\&\*\(\)\{\}\:\"\<\>\?\[\]\!]/, '');
                e.target.value = e.target.value.replace(/[\+]/, '＋');
                data.CardName = e.target.value;
                //////console.log(e.target.value)
                break;
            case 'SalePrice':
                e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                if (parseInt(e.target.value) > money_max) {
                    e.target.value = money_max
                }
                maxNum = parseInt(e.target.value);
                data.SalePrice = parseIntX100(moneyConvert(e.target.value));
                break;
            case 'PromBy':
                //////console.log(parseInt(e))
                data.Rate.PromBy = parseInt(e);
                if (parseInt(e) == 2) {
                    data.cardFlag = ECardFlag.StoredValue | ECardFlag.Discount | ECardFlag.Charge
                } else {
                    data.cardFlag = ECardFlag.StoredValue | ECardFlag.Charge
                }
                this.setState({ PromBy: parseInt(e) });
                break;
            case 'PromDiscount':
                e.target.value = e.target.value.replace(/[^0-9/.]/g, '');
                e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                if (e.target.value >= 9.9) {
                    e.target.value = 9.9
                }
                data.Rate.PromDiscount = parseIntX100(moneyConvert(e.target.value));
                //////console.log('折扣', parseFloat(e.target.value))
                break;
            case 'PromBonus':
                if (e.target.value > money_max) {
                    e.target.value = money_max
                }
                e.target.value = e.target.value.replace(/[^0-9/.]/g, '');
                data.Rate.PromBonus = parseIntX100(moneyConvert(e.target.value));
                // data.Rate.PromBonus = moneyConvert(e.target.value);
                //////console.log('---',data.Rate.PromBonus)
                break;
            case 'editCardName':
                recordData.CardName = e;
                break;
            case 'editSalePrice':
                if (parseInt(e.target.value) > money_max) {
                    e.target.value = money_max
                }
                e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                recordData.SalePrice = parseIntX100(moneyConvert(e.target.value));
                break;
            case 'editPromDiscount':
                //////console.log(e.target.value)
                e.target.value = e.target.value.replace(/[^0-9/.]/g, '');
                e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                if (e.target.value >= 9.9) {
                    e.target.value = 9.9
                }

                recordData.Rate.PromDiscount = parseIntX100(moneyConvert(e.target.value));
                break;
            case 'editPromBonus':
                if (e.target.value > money_max) {
                    e.target.value = money_max
                }
                e.target.value = e.target.value.replace(/[^0-9/.]/g, '');
                recordData.Rate.PromBonus = parseIntX100(e.target.value);
                break;
            case 'changeItem':
                // //////console.log(e);
                // this.requestQueryItem(parseInt(e));
                break;
            case 'changeCount':
                //console.log(event.target.value, this.state.itemData)
                // e.Quota = parseInt(numConvert(event.target.value));
                this.setState({ mealCount: parseInt(numConvert(event.target.value)) })
                if (this.state.modalData.CardFlag == 16) {
                    this.state.itemData.forEach(item => {
                        if (item.Quota == "" || item.Quota < 1) {
                            item.Quota = 1;
                        }
                        if (item.FinalPrice == NaN) {
                            item.FinalPrice = item.UnitPrice
                        }
                        item.Quota = parseInt(numConvert(event.target.value));
                        item.TotalAmount = item.FinalPrice * item.Quota;
                        item.FinalPrice = parseInt(item.TotalAmount / item.Quota);
                    })
                } else {
                    if (e.Quota == "" || e.Quota < 1) {
                        event.Quota = 1;
                    }
                    if (e.FinalPrice == NaN) {
                        e.FinalPrice = e.UnitPrice
                    }
                    e.Quota = parseInt(numConvert(event.target.value));
                    e.TotalAmount = e.FinalPrice * e.Quota;
                    e.FinalPrice = parseInt(e.TotalAmount / e.Quota);
                }
                this.setState(this);
                break;
            case 'changeAmount':
                //console.log(event.target.value,e)
                e.TotalAmount = moneyConvert(event.target.value);
                e.TotalAmount = parseIntX100(event.target.value);
                e.FinalPrice = parseInt(e.TotalAmount / e.Quota);
                this.setState(this);
                break;
            case '套餐卡名称':
                data.CardName = e;
                this.setState({ CardName: e });
                break;
            case '有效期':
                e.target.value = e.target.value.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
                //////console.log(e.target.value)
                e.target.value = moneyConvert(e.target.value);
                this.setState({ ExpiresDay: e.target.value });
                break;
            case '改变搜索框内容会员卡':
                if (e.target.value == '') {
                    this.requestCardType(2)
                }
                break;
            case '改变搜索框内容套餐卡':
                if (e.target.value == '') {
                    this.requestCardType(2)
                }
                break;
            case '搜索服务':
                if (e.target.value == '') {
                    this.requestQueryItem();
                }
                break;
            case '搜索商品':
                if (e.target.value == '') {
                    // this.requestQueryCardItem();
                }
                break;
            case '百分比金额':
                data.sCardTypeAward.CardBasis = parseInt(e.target.value);
                recordData.sCardTypeAward.CardValue=0
                this.setState({ tcFlag: e.target.value });
                break;
            case '开卡提成复选框':
                if (e.target.checked) {
                    data.sCardTypeAward.CardSettingsBasis = 3
                } else {
                    data.sCardTypeAward.CardSettingsBasis = 2
                }
                this.setState({ kkDisabled: e.target.checked });
                break;
            case '充值提成复选框':
                if (e.target.checked) {
                    data.sCardTypeAward.ChargeSettingsBasis = 3
                } else {
                    data.sCardTypeAward.ChargeSettingsBasis = 2
                }
                this.setState({ czDisabled: e.target.checked });
                break;
            case '列表百分比金额':
                //////console.log(event.target.value,e,this.state.postRateList,)
                this.state.postRateList.forEach(item => {
                    if (item.PostId == e.RoleId) {
                        item.CardBasis = parseInt(event.target.value)
                    }
                });
                this.state.postList.forEach(item => {
                    if (item.RoleId == e.RoleId) {
                        if (event.target.value == 3) {
                            item._flag = true;
                            if (item.CardRate > 10000) {
                                item.CardRate = 10000
                            }
                        } else {
                            item._flag = false
                        }
                        item._CardBasis = parseInt(event.target.value)
                    }
                });
                this.setState({ postList: this.state.postList, postRateList: this.state.postRateList });
                break;
            case '开卡提成值':
                //console.log(111,e.target.value)
                    if(isNaN(e.target.value)){
                        return;
                      }
                      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
                      e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
                if (this.state.tcFlag == 3) {
                    if (e.target.value >= 100) {
                        e.target.value = 100
                    }
                } else {
                    if (e.target.value >= 9999) {
                        e.target.value = 9999
                    }
                }
                data.sCardTypeAward.CardValue = numberConvert_100(e.target.value);
                recordData.sCardTypeAward.CardValue = numberConvert_100(e.target.value.trim());
                recordData.sCardTypeAward._CardValue = (e.target.value.trim());
                break;
            case '开卡提成-扣卡':
                //console.log(111,e.target.value)
                    if(isNaN(e.target.value)){
                        return;
                      }
                      e.target.value = e.target.value.replace(/\.{2,}/g, ".");
                      e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
                if (this.state.tcFlag == 3) {
                    if (e.target.value >= 100) {
                        e.target.value = 100
                    }
                } else {
                    if (e.target.value >= 9999) {
                        e.target.value = 9999
                    }
                }
                data.sCardTypeAward.CardUcardValue = numberConvert_100(e.target.value);
                recordData.sCardTypeAward.CardUcardValue = numberConvert_100(e.target.value.trim());
                recordData.sCardTypeAward._CardUcardValue = (e.target.value.trim());
                
                break;
            case '充值提成值':
                e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                e.target.value = e.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
                e.target.value = e.target.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                //////console.log(e.target.value)
                data.sCardTypeAward.ChargeValue = numberConvert_100(e.target.value);
                break;
            case '岗位开卡提成值':
                event.target.value = event.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                event.target.value = event.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
                event.target.value = event.target.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                event.target.value = event.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                if (e._flag == true) {
                    if (event.target.value >= 100) {
                        event.target.value = 100
                    }
                } else {
                    if (event.target.value >= 99999) {
                        event.target.value = 99999
                    }
                }
                this.state.postRateList.forEach(item => {
                    if (item.PostId == e.RoleId) {
                        item.CardValue = numberConvert_100(event.target.value)
                    }
                });
                this.state.postList.forEach(item => {
                    if (item.RoleId == e.RoleId) {
                        item.CardRate = numberConvert_100(event.target.value)
                    }
                });
                this.setState({ postRateList: this.state.postRateList,postList:this.state.postList});
                break;
            case '岗位充值提成值':
                event.target.value = event.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                event.target.value = event.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
                event.target.value = event.target.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                event.target.value = event.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                this.state.postRateList.forEach(item => {
                    if (item.PostId == e.RoleId) {
                        item.ChargeValue = numberConvert_100(event.target.value)
                    }
                });
                this.setState({ postRateList: this.state.postRateList });
                break;
            case '计次方式':
                data.CardFlag = e.target.value
                this.state.itemData.forEach(item => {
                    if (data.CardFlag == 8 || data.CardFlag == 16) {
                        item.Quota = 1
                    } else {
                        item.Quota = 0
                    }
                    if (e.target.value != 32) {
                        item.UnitPrice = item.Price;
                        item.FinalPrice = item.Price;
                        item.TotalAmount = item.Price;
                    } else {
                        item.FinalPrice = this.state.totalMoney / this.state.totalCount
                    }
                })
                this.setState(this)
                if (e.target.value == 8) {
                    this.setState({ tipMsg: '套餐卡内项目各自独立使用，提成或业绩按套餐卡价计算' })
                } else if (e.target.value == 16) {
                    this.setState({ tipMsg: '选择套餐卡内任意项目，所有项目均扣次，提成或业绩按套餐卡价计算' })
                } else if (e.target.value == 32) {
                    this.setState({ tipMsg: '总次数以内，任意项目随意组合，业绩或提成按门市价' })
                } else {
                    this.setState({ tipMsg: '套餐卡内项目无限次数使用，业绩或提成按门市价' })
                }
                break
            case '套餐卡总成本':

                e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                data._Cost= e.target.value

                this.setState({ totalCost: numberConvert_100(e.target.value)})
                break;
            case '套餐卡总次数':
                e.target.value = e.target.value.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
                this.setState(this)
                this.setState({ totalCount: e.target.value * 1 })
                if(e.target.value==0){
                    this.state.singleMone=0
                }else {
                    if(e.target.value==''){
                        this.state.singleMone=0
                    }else {
                        this.state.singleMoney = this.state.totalMoney/ e.target.value/100
                    } 
                }
                //console.log(this.state.singleMoney)
                if (this.state.modalData.CardFlag == 32) {
                    this.state.itemData.forEach(item => {
                        item.FinalPrice = numberConvert_100(this.state.singleMoney.toFixed(2))
                    })
                }
                data._InitialValue= e.target.value
                this.setState({ singleMoney: this.state.singleMoney })
                break;
            case '套餐卡总售价':
                //console.log(e.target.value)
                // e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                // e.target.value = e.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
                // e.target.value = e.target.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                if (this.state.modalData.CardFlag == 32) {
                    if (this.state.totalCount == 0) {
                        this.state.singleMoney = 0
                    } else {
                        this.state.singleMoney = e.target.value / this.state.totalCount
                    }
                    this.state.itemData.forEach(item => {
                        item.FinalPrice = numberConvert_100(this.state.singleMoney.toFixed(2))
                    })

                }
                data._TotalValue = e.target.value
                data.TotalValue = numberConvert_100(e.target.value)
                
                this.setState({ totalMoney: numberConvert_100(e.target.value), singleMoney: this.state.singleMoney, itemData: this.state.itemData })
                break
            case '启用禁用':
                if (event) {
                    e.Enabled = 1
                    this.requestAPI_EnabledMchCard(e.Id, 1)
                } else {
                    e.Enabled = 2
                    this.requestAPI_EnabledMchCard(e.Id, 2)
                }
                this.setState(this)
                break;
            case '状态':
                this.setState({ enabled: e }, () => {
                    this.requestCardType(2)
                })



        }
        this.setState({ modalData: data, editRecord: recordData })
    }

    /**
     * 取消操作
     */
    handelCancel() {
        this.setState({
            visible: false,
            visible2: false,
            visible3: false,
            visible4: false,
            openCard: {},
            modalData: this.initCard()
        })
    }

    /**
     * 会员卡套餐卡切换时回调
     */
    changeCardType(key) {
        //////console.log(key);
        if (key == '1') {
            this.setState({ isVip: true })
        } else {
            this.setState({ isVip: false })
        }
        this.requestCardType(key);
    }

    /**
     * 改变优惠类型回调
     */
    changeDiscount(value) {
        //////console.log(`selected ${value}`);
    }

    /**
     * 是否储值按钮事件
     */
    onChangeSwitch(checked) {
        var data = this.state.modalData;
        //////console.log(checked);
        if (checked == true) {
            data.CardFlag = ECardFlag.StoredValue | ECardFlag.Discount | ECardFlag.Charge
        } else {
            data.CardFlag = ECardFlag.Discount;
            data.Rate.PromBy = 2;
        }
        this.setState({ isStorage: checked, modalData: data, PromBy: 2 })
    }

    /**
     * 改变文本框状态
     */
    changeRadio(e) {
        //////console.log('改变radio', e.target.value)
        var data = this.state.editRecord;
        if (e.target.value == 1) {
            data ? data.SalePrice = 0 : null;
            this.setState({ disabled: true, editRecord: data, ExpiresDay: 0 });
        } else {
            this.setState({ disabled: false, ExpiresDay: this.state.editRecord.ExpiresDay })
        }
        this.setState({ RadioState: e.target.value })
    }

    blur(e) {
        if (e.target.value < 0) {
            messageError('开卡金额不能小于0！')
        }

    }

    search1(e) {
        //////console.log(this.state.data)
        var data = [];
        this.state.data.forEach(item => {
            for (var i = 0; i < item.ShortLetter.length; i++) {
                // ////console.log(item.ShortLetter[i])
                if (item.CardName == e || item.CardName.indexOf(e) != -1 || item.ShortLetter.indexOf(e) != -1 || item.ShortLetter.indexOf(e.toUpperCase()) != -1 || item.ShortLetter[0].toLowerCase() == e || item.ShortLetter[0] == e || item.ShortLetter[i] == e) {
                    data.push(item);
                }
            }

        });
        this.setState({ data: [...new Set(data)] })
    }

    search2(e) {
        var data = [];
        this.state.data2.forEach(item => {
            for (var i = 0; i < item.ShortLetter.length; i++) {
                // ////console.log(item.ShortLetter[i])
                if (item.CardName == e || item.CardName.indexOf(e) != -1 || item.ShortLetter.indexOf(e) != -1 || item.ShortLetter.indexOf(e.toUpperCase()) != -1 || item.ShortLetter[0].toLowerCase() == e || item.ShortLetter[0] == e || item.ShortLetter[i] == e) {
                    data.push(item);
                }
            }
        });
        this.setState({ data2: [...new Set(data)] })
    }

    search3(e) {
        var data = [];
        this.state.item.forEach(item => {
            for (var i = 0; i < item.ShortLetter.length; i++) {
                // ////console.log(item.ShortLetter[i])
                if (item.Title == e || item.Title.indexOf(e) != -1 || item.ShortLetter.indexOf(e) != -1 || item.ShortLetter.indexOf(e.toUpperCase()) != -1 || item.ShortLetter[0].toLowerCase() == e || item.ShortLetter[0] == e || item.ShortLetter[i] == e) {
                    data.push(item);
                }
            }
        });
        this.setState({ item: [...new Set(data)] })
    }

    search4(e) {
        var data = [];
        this.state.cardItem.forEach(item => {
            for (var i = 0; i < item.ShortLetter.length; i++) {
                // ////console.log(item.ShortLetter[i])
                if (item.Title == e || item.Title.indexOf(e) != -1 || item.ShortLetter.indexOf(e) != -1 || item.ShortLetter.indexOf(e.toUpperCase()) != -1 || item.ShortLetter[0].toLowerCase() == e || item.ShortLetter[0] == e || item.ShortLetter[i] == e) {
                    data.push(item);
                }
            }
        });
        this.setState({ cardItem: [...new Set(data)] })
    }

    Callback(start, end) {
        begin = start;
        over = end;
        // if(this.state.isVip==true){
        //     this.requestCardType(1)
        // }else {
        this.requestCardType(2)
        // }


    }

    getItemView() {
        if (this.state.isShowAll) {
            return this.state.postList;
        } else {
            var temp = [];
            this.state.postList.forEach((item, index) => {
                if (index < 5) {
                    temp.push(item)
                }
            });
            return temp;
        }
    }

    getItemView2() {
        if (this.state.isShowAll2) {
            return this.state.postList;
        } else {
            var temp = [];
            this.state.postList.forEach((item, index) => {
                if (index < 5) {
                    temp.push(item)
                }
            });
            return temp;
        }
    }

    render() {
        const {loading} = this.state;
        const List = this.getItemView();
        var arr = this.state.itemData;
        总次数 = 0;
        门市价 = 0;
        优惠 = 0;
        套餐卡售价 = 0;
        套餐卡总成本 = 0
        arr.forEach(item => {
            // item.TotalAmount = item.Quota * item.FinalPrice
            总次数 = 总次数 + item.Quota;
            门市价 = 门市价 + item.TotalAmount;
            // 套餐卡售价 = (套餐卡售价 + (item.FinalPrice * item.Quota * 100) / 100);
            套餐卡售价+=item.TotalAmount
            套餐卡总成本 += ((item.Cost * item.Quota * 100)) / 100
        });
        maxNum = numberConvert(门市价);
        //////console.log(总次数)
        优惠 = (门市价 * 100 - 套餐卡售价 * 100) / 100;
        console.log(11111123,this.state.modalData);
        console.log(211111124,this.state.editRecord);
        const div = <div>
            <div class="mod-card-form mod-time-card-form">
                <div class="mod-time-card-form-side items gra-radius-border" style={{ marginTop: 10 }}>
                    <Tabs type='card' tabBarGutter={0} onChange={this.changeListener.bind(this, 'changeItem')}>
                        <TabPane tab="服 务" key="2">
                            <div style={{ textAlign: 'center', borderBottom: '1px #ccc solid' }}>
                                <Search
                                    placeholder="服务名称或首字母"
                                    onSearch={this.search3.bind(this)}
                                    onChange={this.changeListener.bind(this, '搜索服务')}
                                    style={{ width: 210, marginBottom: 10 }}
                                />
                            </div>
                            <div style={{ height: h, overflow: 'auto' }}>
                                {
                                    this.state.item.map((item, index) => {
                                        return (
                                            <p className='time'
                                                style={{ borderBottom: '1px #ccc solid', padding: '0 20px' }}
                                                onClick={this.onClickListener.bind(this, 'clickItem', item)}
                                                key={index}><Tooltip title={item._str}>{item.key}.{item._str}</Tooltip>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </TabPane>
                        <TabPane tab="商 品" key="1" disabled >
                            <div style={{ textAlign: 'center', borderBottom: '1px #ccc solid' }}>
                                <Search
                                    placeholder="商品名称或首字母"
                                    onChange={this.changeListener.bind(this, '搜索商品')}
                                    onSearch={this.search4.bind(this)}
                                    style={{ width: 210, marginBottom: 10 }}
                                />
                            </div>
                            <div style={{ height: h, overflow: 'auto' }}>
                                {
                                    this.state.cardItem.map((item, index) => {
                                        return (
                                            <p disabled={this.state.disableAll} className='time'
                                                style={{ borderBottom: '1px #ccc solid', padding: '0 20px' }}
                                                onClick={this.onClickListener.bind(this, 'clickItem', item)}
                                                key={index}><Tooltip title={item._str}>{item.key}.{item._str}</Tooltip>
                                            </p>
                                        )
                                    })
                                }
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
                <div class="mod-time-card-form-main "
                    style={{ flex: 1, background: '#fff', borderRadius: 6, padding: '10px' }}>
                    <Form className="mod-time-card-form-body">
                        <div style={{ margin: '20px 0', marginTop: 0 }}>
                            <span className="comm-text-red">*</span>
                            <span style={{ width: 100, display: 'inline-Block' }}>套餐卡名称</span>
                            {/* <Input placeholder='请输入套餐卡名称' value={this.state.CardName}
                                   onChange={this.changeListener.bind(this, "套餐卡名称")} style={{width: 300}}/> */}
                            <IInputString
                                disabled={this.state.disableAll}
                                maxLength={20}
                                placeholder='请输入套餐卡名称'
                                value={this.state.CardName}
                                onChange={this.changeListener.bind(this, "套餐卡名称")}
                                style={{ width: 320 }}

                            />
                        </div>
                        <div style={{ marginBottom: 20, borderBottom: '1px #ECECEC solid', paddingBottom: 20 }}>
                            <span className="comm-text-red">*</span>
                            <span style={{ width: 100, display: 'inline-Block' }}>有效期</span>
                            <RadioGroup disabled={this.state.disableAll} value={this.state.RadioState}
                                onChange={this.changeRadio.bind(this)}>
                                <Radio style={{ marginRight: 38 }} value={1}>永久有效</Radio>
                                <Radio value={2}>办卡起</Radio>
                            </RadioGroup>
                            <Input disabled={this.state.disableAll} defaultValue='0'
                                onChange={this.changeListener.bind(this, "有效期")}
                                value={this.state.ExpiresDay} style={{ width: 70, marginRight: 10 }} />
                            <span>日有效</span>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <span className="comm-text-red">*</span>
                            <span style={{ width: 100, display: 'inline-Block' }}>计次方式</span>
                            <RadioGroup disabled={this.state.disableAll}
                                value={this.state.modalData.CardFlag}
                                onChange={this.changeListener.bind(this, '计次方式')}
                            >
                                <Radio value={8}>单项计次</Radio>
                                <Radio value={16}>套餐计次</Radio>
                                <Radio value={32}>整体计次</Radio>
                                <Radio value={64}>无限次数</Radio>
                            </RadioGroup>
                            <div style={{ color: '#999', marginLeft: 110 }}>({this.state.tipMsg})</div>
                        </div>
                        <div>
                            <table cellSpacing="1" border='1' cellPadding='9' style={{ border: '1px #dcdcdc solid', background: '#F3F3F3', borderBottom: 0 }}>
                                {
                                    this.state.modalData.CardFlag == 32 || this.state.modalData.CardFlag == 64 ?
                                        <thead>
                                            <tr align='center'>
                                                <td width='60%'>项目名称</td>
                                                <td width='15%'>成本价</td>
                                                <td width='15%'>门市价</td>
                                                <td width='10%'>操作</td>
                                            </tr>
                                        </thead> : <thead>
                                            <tr align='center'>
                                                <td width='20%'>项目名称</td>
                                                <td width='15%'>成本价</td>
                                                <td width='15%'>门市价</td>
                                                <td width='15%'>次数</td>
                                                <td width='10%'>套餐卡价</td>
                                                <td width='15%'>金额</td>
                                                <td width='10%'>操作</td>
                                            </tr>
                                        </thead>
                                }

                            </table>
                            <table cellSpacing="1" style={{ width: "100%", tableLayout: "fixed" }}
                                border='1' cellPadding='9' bordercolor="#dcdcdc">
                                {
                                    this.state.modalData.CardFlag == 32 || this.state.modalData.CardFlag == 64 ?
                                        <tbody align="center" valign="center">
                                            {
                                                this.state.itemData.map((item, index) => {
                                                    return (
                                                        <tr className={item.isEdit ? "comm-background-f5f5f5" : ""}
                                                            key={index}>
                                                            <td width='60%' className='time'><Tooltip
                                                                title={item.Title}>{item.Title}</Tooltip></td>
                                                            <td width='15%'>{numberConvert(item.Cost).toFixed(2)}</td>
                                                            <td width='15%'>{numberConvert(item.UnitPrice).toFixed(2)}</td>
                                                            <td width='10%'><span style={{ color: '#FF6666' }}
                                                                onClick={this.onClickListener.bind(this, 'API_deleteItem', item)}>删除</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody> : <tbody align="center" valign="center">
                                            {
                                                this.state.itemData.map((item, index) => {
                                                    return (
                                                        <tr className={item.isEdit ? "comm-background-f5f5f5" : ""}
                                                            key={index}>
                                                            <td width='20%' className='time'><Tooltip
                                                                title={item.Title}>{item.Title}</Tooltip></td>
                                                            <td width='15%'>{numberConvert(item.Cost).toFixed(2)}</td>
                                                            <td width='15%'>{numberConvert(item.UnitPrice).toFixed(2)}</td>
                                                            {
                                                                this.state.modalData.CardFlag == 8 ? <td width='15%'>{item.isEdit ? <div>{item.Quota}</div> :
                                                                    <Input type='number' min={1} value={item.Quota}
                                                                        disabled={this.state.disableAll}
                                                                        onChange={this.changeListener.bind(this, 'changeCount', item)}
                                                                        style={{ width: '90%' }} />} </td> : index == 0 ? <td width='15%' rowSpan={this.state.itemData.length} >{item.isEdit ? <div>{item.Quota}</div> :
                                                                            <Input type='number' min={1} value={item.Quota}
                                                                                disabled={this.state.disableAll}
                                                                                onChange={this.changeListener.bind(this, 'changeCount', item)}
                                                                                style={{ width: '90%' }} />} </td> : null
                                                            }
                                                            <td width='10%'>{numberConvert(item.FinalPrice).toFixed(2)}</td>
                                                            <td width='15%'>
                                                                <div
                                                                    className="comm-row comm-item-center comm-item-justify">{item.isEdit ?
                                                                        <div>{numberConvert(item.TotalAmount)}</div> :
                                                                        <Input maxLength='6' style={{ width: '90%', marginRight: 5 }}
                                                                            value={numberConvert(item.TotalAmount)}
                                                                            disabled={this.state.disableAll}
                                                                            onChange={this.changeListener.bind(this, 'changeAmount', item)} />
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td width='10%'><span style={{ color: '#FF6666' }}
                                                                onClick={this.onClickListener.bind(this, 'API_deleteItem', item)}>删除</span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                }

                            </table>
                        </div>
                        {

                            this.state.modalData.CardFlag == 8 ? <div style={{ borderBottom: '1px #ECECEC solid', paddingBottom: 20, marginTop: 20 }}>
                                <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总成本：{numberConvert(套餐卡总成本).toFixed(2)}元</span>
                                <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总次数：<span style={{ color: '#fc1a6e' }}>{总次数}</span>次</span>
                                <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总售价：<span style={{ color: '#fc1a6e' }}>{numberConvert(套餐卡售价).toFixed(2)}</span>元</span>
                            </div> : this.state.modalData.CardFlag == 16 ? <div style={{ borderBottom: '1px #ECECEC solid', paddingBottom: 20, marginTop: 20 }}>
                                <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总成本：{numberConvert(套餐卡总成本).toFixed(2)}元</span>
                                <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总售价：<span style={{ color: '#fc1a6e' }}>{numberConvert(套餐卡售价).toFixed(2)}</span>元</span>
                            </div> : this.state.modalData.CardFlag == 32 ? <div style={{ borderBottom: '1px #ECECEC solid', paddingBottom: 20, marginTop: 20 }}>
                                <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总成本：<Input onFocus={(e) => e.target.value = ''} value={this.state.modalData._Cost} onChange={this.changeListener.bind(this, '套餐卡总成本')} style={{ width: 60, marginRight: 10 }} />元</span>
                                <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总次数：<Input onFocus={(e) => e.target.value = ''} value={this.state.modalData._InitialValue} onChange={this.changeListener.bind(this, '套餐卡总次数')} style={{ width: 60, marginRight: 10 }} />次</span>
                                <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总售价：<Input onFocus={(e) => e.target.value = ''} value={this.state.modalData._TotalValue } onChange={this.changeListener.bind(this, '套餐卡总售价')} style={{ width: 60, marginRight: 10 }} />元</span>
                                <div style={{ color: '#222', marginRight: 20, fontWeight: 600, marginTop: 10 }}>单次价格：{this.state.singleMoney.toFixed(2)}元</div>
                            </div> : <div style={{ borderBottom: '1px #ECECEC solid', paddingBottom: 20, marginTop: 20 }}>
                                            <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总成本：<Input onFocus={(e) => e.target.value = ''} defaultValue={this.state.modalData._Cost} onChange={this.changeListener.bind(this, '套餐卡总成本')} style={{ width: 60, marginRight: 10 }} />元</span>
                                            <span style={{ color: '#222', marginRight: 20, fontWeight: 600 }}>套餐卡总售价：<Input onFocus={(e) => e.target.value = ''} defaultValue={this.state.modalData._TotalValue } onChange={this.changeListener.bind(this, '套餐卡总售价')} style={{ width: 60, marginRight: 10 }} />元</span>
                                        </div>
                        }


                        <div style={{ marginBottom: 10 }}>
                            <span style={{ width: 100, display: 'inline-Block', margin: '20px 0' }}>开卡提成</span>
                            <RadioGroup disabled={this.state.kkDisabled}
                                defaultValue={this.state.editRecord.sCardTypeAward ? this.state.editRecord.sCardTypeAward.CardBasis : 3}
                                onChange={this.changeListener.bind(this, '百分比金额')}>
                                <Radio value={3}>百分比</Radio>
                                <Radio value={2}>金额</Radio>
                            </RadioGroup>
                            <div style={{ display: 'inline' }}>
                                现金
                                <Input
                                    maxLength={6}
                                    onChange={this.changeListener.bind(this, '开卡提成值')}
                                    disabled={this.state.kkDisabled}
                                    value={this.state.editRecord.sCardTypeAward._CardValue ? (this.state.editRecord.sCardTypeAward._CardValue) : ''}
                                    // value={numberConvert(this.state.editRecord.CardValue)}
                                    style={{ width: 70, marginLeft: 10, marginRight: 10 }} />
                                {
                                    this.state.tcFlag == 3 ? <span>%</span> : <span>元</span>
                                }
                            </div>
                            <div style={{ marginLeft: 10,display: 'inline' }}>
                                扣卡
                                <Input
                                    maxLength={6}
                                    onChange={this.changeListener.bind(this, '开卡提成-扣卡')}
                                    disabled={this.state.kkDisabled}
                                    // value={this.state.editRecord.CardUcardValue}
                                    value={this.state.editRecord.sCardTypeAward._CardUcardValue ? (this.state.editRecord.sCardTypeAward._CardUcardValue) : ''}
                                   
                                    style={{ width: 70, marginLeft: 10, marginRight: 10 }} />
                                {
                                    this.state.tcFlag == 3 ? <span>%</span> : <span>元</span>
                                }
                            </div>
                            {/* <div>
                                <Checkbox style={{ marginLeft: 100, marginBottom: 10 }} defaultChecked={this.state.kkDisabled}
                                    onChange={this.changeListener.bind(this, '开卡提成复选框')}>为不同岗位设置不同提成</Checkbox></div> */}
                            {/* <div style={{ marginLeft: 100 }}>
                                {
                                    this.state.kkDisabled ?
                                        <div>{
                                            List.map((item, index) => {
                                                return (
                                                    <div key={index} className='flexBox' style={{ marginBottom: 10 }}>
                                                        <div className='time' style={{ width: 60 }}><Tooltip
                                                            title={item.Name}>{item.Name}</Tooltip></div>
                                                        <RadioGroup defaultValue={item._CardBasis}
                                                            onChange={this.changeListener.bind(this, '列表百分比金额', item)}>
                                                            <Radio value={3}>百分比</Radio>
                                                            <Radio value={2}>金额</Radio>
                                                        </RadioGroup>
                                                        <div style={{ display: 'inline' }}>
                                                            <Input
                                                                maxLength={6}
                                                                onChange={this.changeListener.bind(this, '岗位开卡提成值', item)}
                                                                value={numberConvert(item.CardRate)}
                                                                style={{ width: 70, marginLeft: 10, marginRight: 10 }} />
                                                            {
                                                                item._flag ? <span>%</span> : <span>元</span>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            {this.state.postList.length > 5 ?
                                                <a onClick={this.changeListener.bind(this, "all")}>{this.state.isShowAll ?
                                                    <div>收起<Icon type="up" /></div> :
                                                    <div>展开<Icon type="down" /></div>}</a> : null}
                                        </div> : null
                                }
                            </div> */}
                        </div>
                    </Form>
                </div>
            </div>
        </div>;
        const { getFieldDecorator } = this.props.form;
        return (
            <div class="gra-border-pd15 vip-main-view" style={{ overflow: 'hidden' }}>
                <div style={{ marginBottom: 15, overflow: 'hidden' }}>
                    <Select defaultValue={0} onChange={this.changeListener.bind(this, '状态')} style={{ width: 100, float: 'left', marginRight: 20 }}>
                        <Option value={0}>全部状态</Option>
                        <Option value={1}>已启用</Option>
                        <Option value={2}>已停用</Option>
                    </Select>
                    <Search placeholder='名称或首字母查询' enterButton onChange={this.changeListener.bind(this, '改变搜索框内容会员卡')}
                        onSearch={this.search2.bind(this)} style={{ width: 200, float: 'left' }} />
                    <Button type='primary' style={{ float: 'right' }}
                        onClick={this.onClickListener.bind(this, 'addVipCard')}><span
                            style={{ color: '#fff' }}>添加套餐卡类型</span></Button>
                </div>
                <div className='vip-main-view-content' style={{ left: 15, right: 15, top: 60 }}>
                    <ITablePage scroll={{ x: "100%", y: "62vh" }} pageCount={this.state.vipCount}
                        onTablePageCallback={this.Callback.bind(this)} columns={this.state.columns2}
                        data={this.state.data2} />
                </div>


                <IModal
                    title='提示'
                    visible={this.state.visible2}
                    footer={null}
                    onCancel={this.handelCancel.bind(this)}
                    view={
                        <div>
                            <p>确定删除{this.state.cardKind}类型：<span>{this.state.vipName}</span></p>
                            <div style={{ textAlign: 'right', marginTop: 20 }}><Button style={{ marginRight: 20 }}
                                onClick={this.handelCancel.bind(this)}>取消</Button><IButton
                                    type='primary' text='确定'
                                    onClick={this.onClickListener.bind(this, 'delete_confirm')}></IButton>
                            </div>
                        </div>
                    }
                >

                </IModal>

                <IDrawer title={this.state.title} footer={true} onClickListener={this.onClickListener.bind(this)}
                    width={1100} visible={this.state.visible3} div={div}
                    loading={loading}
                    />
            </div>
        )
    }
}

const secondCardSetting = Form.create()(secondCardSettingView);
export default secondCardSetting;