import React from 'react';
import {Input} from 'antd';
const {TextArea} = Input;
// import EditableCell from './EditableCell'


export default class ITextArea extends React.Component {
    constructor() {
        super();
    }

    onChange(e) {
        //修复第一个字符是小数点 的情况.
        if (e.target.value != '' && e.target.value.substr(0, 1) == '.') {
            e.target.value = "";
        }
        var text = e.target.value;
        // 去掉转义字符
        // 去掉特殊字符
        text = text.replace(/[\@\%\#\$\^\&\*\(\)\{\}\:\"\<\>\?\[\]]/, '');
        text = text.replace(/[\+]/, '＋');
        e.target.value= text;
        if (this.props.onChangeCallback) {
            this.props.onChangeCallback(e)
        }
        if (this.props.onChange) {
            this.props.onChange(e.target.value)
        }
    }

    render() {
        return (
            <TextArea 
                placeholder={this.props.placeholder?this.props.placeholder:"备注最多120字"}  
                rows={this.props.rows}
                value={this.props.value}
                maxlength={120}
                style={this.props.style}
                onChange={this.onChange.bind(this)}/>
        );
    }
}
