import React from 'react'
import { Icon, Input, Button, Tooltip, Radio, Select, Table, Pagination,Checkbox } from 'antd'
import IButton from '@components/IButton'
import IDrawer from '@components/iDrawer'
import ISearch from '@components/ISearch'
import ITableList from '@components/iTableList'
import { IModal } from '@components/iModal'
import IInputString from '@components/iInputString'
import {IInputNumber,IInputMoney} from '@components/iInput'
import StorageTools from "@comm/storageTools";
import { $mzlApi } from "@comm/mzl-core";
import { messageError, messageSuccess } from '@comm/utils';
import ITablePage, { getPageSize, setTabTop } from '@components/iTablePage'
import { FnMenuId } from '@comm/constans'
import APINames from "@comm/api-names"
import { connect } from 'react-redux'
import { setDefaultTitle } from "@reducers/iconRedux";
import { Tabs } from 'antd';
import { goodsMode,update } from "@js/goods/action";
import styles from "./styles.module.css";

import ItemVipView from "@js/settings/itemVipView";
import ItemRoleView from "@js/settings/itemRoleView";
import service from "@js/goods/service";
import _ from "lodash";

import ModelType from "@js/goods/goodsManage/modelType";
const { Option } = Select;
const { TabPane } = Tabs;
const { Search } = Input;
const reduxStaste = (state) => {
    return {
        ...state.goodsData,
    }
}
@connect(
    state => reduxStaste(state),
    { update }
)
class model extends React.Component {
    constructor() {
        super();
        this.state = {
            roleList: [],
            vipList: [],
            saveData: {},
            tableData: [],
            goodsType:0,
            selectList:[],//选中的项目

        }



    }

    componentDidMount() {
        let {inOutDetail} = this.props; 
        if(inOutDetail.ApplicationItem){
            this.setState({
                selectList:inOutDetail.ApplicationItem
            })
        }
        // debugger
        this.queryPmsGoodsParameter(this,1);
        this.queryPmsGoods();
    }


    /**
      *  获取商品参数
      * 类别 1  单位 2  入库类型 3  出库类型 4
      */
     queryPmsGoodsParameter(that,type){
        service.queryPmsGoodsParameter(that,this.props.mode==="mch"?1:2,type)
        .then(res=>{
          if(res != null && res.Data.data != null){
            res = JSON.parse(res.Data.data)
            that.props.update({
                typeList : res
            })
          }
        })
      }

       //获取商品    
    queryPmsGoods(keyword){
        const that = this;
        service.queryPmsGoods(that,this.props.mode==="mch"?1:2,0,999,keyword,this.state.goodsType,1)
        .then(res=>{
          if(res != null && res.Data != null){
              let list = JSON.parse(res.Data);
              let {selectList} = that.state; 
              let {inOutDetail} = that.props; 
              let allSize = 0;
              list.forEach(ele=>{
                ele.GoodsId = ele.Id;
                ele.Id = 0; //新增默认为0
                ele.UseType = that.props.mode === "mch"?1:2;
                ele.Spec = ele.SpecName;
                ele.ApplicationNum = 0; //申请数量
                ele.Cost = ele.Cost/100;
                selectList.forEach(item=>{
                    if(item.GoodsId===ele.GoodsId){
                        allSize = allSize+1;
                        ele._check = true;
                    }
                })
              })
              if(list.length>0){
                inOutDetail._allCheck = allSize===list.length
              }else{
                inOutDetail._allCheck = false;
              }
              that.setState({
                tableData: list,
                totalSize:res.Count
              });
          }
        })
      }

    // requestCardType(){
    //     const that = this;
    //     service.requestCardType(this)
    //     .then(res=>{
    //         that.setState({
    //             vipData: JSON.parse(res.Data)
    //         });
    //     })
    // }




    onShowSizeChange() {

    }

    onClickListener(tag,e){
        const {inOutDetail,typeList} =this.props;
        const {selectList} =this.state;
        if(tag === "drawer-ok"){
            inOutDetail.ApplicationItem = selectList
            this.props.update({
                inOutDetail,
                goodsModelVisible:false
            })
        }else if(tag === "drawer-cancel"){
            this.props.update({
                goodsModelVisible:false
            })
        }else if(tag === "goodsType"){
            this.setState({
                goodsType:e
            },function(){
                this.queryPmsGoods();
            })
        }
        console.log(`onClickListener `,tag);
    }

    onChangeBox(tag,data,e){

        let {selectList,tableData} = this.state; 
        let {inOutDetail} = this.props; 
        //全选
        if(tag === "all"){
            if(e.target.checked){
                tableData.forEach(ele=>{
                    let isPush = true;
                  selectList.forEach(item=>{
                      if(item.GoodsId === ele.GoodsId){
                        isPush = false
                      }
                  })
                  ele._check = true;
                  if(isPush){
                    selectList.push(ele);
                  }
                  
                })
              this.setState({selectList})
              inOutDetail._allCheck = true;
              this.props.update({inOutDetail})
           }else{
            tableData.forEach(ele=>{
                ele._check = false;
              })
              this.setState({selectList:[]});
              inOutDetail._allCheck = false;
              this.props.update({inOutDetail})
           }
        }else if(tag === "single"){
            if(e.target.checked){
                tableData.forEach(ele=>{
                    if(data===ele.GoodsId){
                        ele._check = true;
                        selectList.push(ele)
                    }
                })
                this.setState({selectList,tableData})
            }else{
                tableData.forEach(ele=>{
                    if(data===ele.GoodsId){
                        ele._check = false;
                    }
                })
                let list =  selectList.filter(item=>{
                    return item.GoodsId != data
                })
                inOutDetail._allCheck = false;
                this.setState({selectList:list,tableData})
                this.props.update({inOutDetail})
            }
        }
        


        console.log(`checked = ${e.target.checked}`);
    }

    onClickDel(data){
        let {selectList,tableData} = this.state; 
        let {inOutDetail} = this.props; 
        
        tableData.forEach(ele=>{
            if(data===ele.GoodsId){
                ele._check = false;
            }
        })
        let list =  selectList.filter(item=>{
            return item.GoodsId != data
        })
        inOutDetail._allCheck = false;
        this.setState({selectList:list,tableData})
        this.props.update({inOutDetail})
    }



    render() {

        const { typeList,inOutDetail} = this.props;
       
        const { selectList} = this.state;
        // console.log("selectList12312",selectList)
        return (
            <IDrawer
                title={"设置商品"} 
                footer={true} 
                onClickListener={this.onClickListener.bind(this)}
                 width={800} 
                 visible={this.props.goodsModelVisible}
            >
                <div className="comm-w-h comm-row">
                    <div className={`${styles['model-left']} comm-row` }>
                        <div className={styles['model-type-view']}>
                            <div className={`${styles['model-type-text']} ${this.state.goodsType===0?"comm-text-main":""}`}
                            onClick={this.onClickListener.bind(this,"goodsType",0)}>
                            全部分类</div>
                        
                            {typeList.map(item=>(
                                    <div 
                                        className={`${styles['model-type-text']} ${this.state.goodsType===item.Id?"comm-text-main":""}`}
                                        onClick={this.onClickListener.bind(this,"goodsType",item.Id)}>
                                            {item.ParameterName}
                                        </div>
                                ))}
                        
                            
                        </div>
                        <div className={styles['model-goods-view']}>
                            <Search
                                placeholder="搜索名称"
                                enterButton="搜索"
                                onSearch={value => console.log(value)}
                                />
                            <div className={styles['model-goods-content']} >
                                <div className={styles['model-goods-title']} style={{background:"#f2f2f2",color:"#333"}}>
                                    <Checkbox checked={inOutDetail._allCheck} onChange={this.onChangeBox.bind(this,"all",0)}>全选</Checkbox>  
                                </div>
                                {this.state.tableData.map(item=>(
                                    <div className={styles['model-goods-text']}>
                                      <Checkbox checked={item._check} onChange={this.onChangeBox.bind(this,"single",item.GoodsId)}>{item.GoodsName}</Checkbox>  
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                   
                    <div className={styles["model-rigth"]}>
                        <div className={styles['model-selected-content']} >
                            <div className={`${styles['model-goods-text']} comm-flex-row-between`} style={{background:"#f2f2f2"}}>
                                <span style={{color:"#333"}}>已选</span>
                                <span></span>
                            </div>
                            {selectList.map(item=>(
                                <div className={`${styles['model-goods-text']} comm-flex-row-between`}>
                                    <span>{item.GoodsName}</span>
                                    <i 
                                        class='iconfont' 
                                        style={{color: '#666', fontSize: 16}}
                                        onClick={this.onClickDel.bind(this,item.GoodsId)}
                                    >&#xe6f0;</i>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                   
                </div>

               
            </IDrawer>

        )
    }
}

export default model