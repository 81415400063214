import iEnum from './iEnum'

/**
 *  性别
 *  性别,1:男，2：女，0：未指定 */
export function sexConvert(tag) {
    if (tag == 1) {
        return '男';
    } else if (tag == 2) {
        return '女';
    } else {
        return '未知';
    }

}

/**
 * 是否是收银员
 * 1:否 2:是
 * */
export function cashierConvert(tag) {
    switch (tag) {
        case 0:
            return '否';
        case 2:
            return '是';
        default:
            return '是';
    }
}


/**
 * 员工状态
 *  员工状态，1：在职 2:休假 3:离职 */
export function workStateConvert(tag) {
    switch (tag) {
        case 1:
            return '在职';
        case 2:
            return '休假';
        case 3:
            return '离职';
    }
    return ""
}


export function OrderTypeConvert(tag, flag) {
    var str = '';
    switch (tag) {
        case iEnum.EOrderType.Normal:
            switch (flag) {
                case iEnum.EPaymentFlag.UserCard:
                case iEnum.EPaymentFlag.Cash | iEnum.EPaymentFlag.BankCard | iEnum.EPaymentFlag.OutSP | iEnum.EPaymentFlag.UserCard:
                    str = '会员';
                    break;
                case iEnum.EPaymentFlag.TimeCard:
                    str = '套餐卡';
                    break;
                case iEnum.EPaymentFlag.TimeCard | iEnum.EPaymentFlag.UserCard:
                case iEnum.EPaymentFlag.TimeCard | iEnum.EPaymentFlag.UserCard | iEnum.EPaymentFlag.Cash | iEnum.EPaymentFlag.BankCard | iEnum.EPaymentFlag.OutSP:
                    str = '会员/套餐卡';
                    break;
                default:
                    str = '散客'
            }
            break;
        case iEnum.EOrderType.Suspend:
            str = '散客';
            break;
        case iEnum.EOrderType.ChargeOrder:
            str = '会员充值';
            break;
        case iEnum.EOrderType.CardOrder:
            str = '会员开卡';
            break;
        case iEnum.EOrderType.TimeCardOrder:
            str = '套餐卡开卡';
            break;
        case iEnum.EOrderType.ManualCardOrder:
            str = '会员卡录入';
            break;
        case iEnum.EOrderType.ManualTimeCardOrder:
            str = '套餐卡录入';
            break;
    }
    return str;
}


/**
 * 会员卡状态
 *  员工状态，1：在职 2:休假 3:离职 */
export function cardTypeText(tag) {
    switch (tag) {
        case iEnum.ECardKind.CusCard:
            return '会员卡';
        case 2:
        case iEnum.ECardKind.TimeCard:
            return '套餐卡';
    }
    return ""
}


/**1门市价   2会员价   3自定义*/
export function getPriceBasis(tag) {
    switch (tag) {
        case 1:
            return '门市价';
        case 2:
            return '会员统一价';
        case 3:
            return '会员自定义';
    }
    return ""
}

/**  审批状态 **/
export function getCheckStatus(tag) {
    switch (tag) {
        case iEnum.EMaterialReceiveStatus.Apply:
            return '申请中';
        case iEnum.EMaterialReceiveStatus.ApplyRefuse:
            return '驳回';
        case iEnum.EMaterialReceiveStatus.ApplyConsent:
            return '同意';
    }
    return ""
}


/**  审批状态 **/
export function getNumText(tag) {
    switch (tag) {
        case 0:
            return '一';
        case 1:
            return '二';
        case 2:
            return '三';
        case 3:
            return '四';
        case 4:
            return '五';
        case 5:
            return '六';
        case 6:
            return '七';
        case 7:
            return '八';
        case 8:
            return '九';
        case 9:
            return '十';
        case 10:
            return '十一';
    }
    return ""
}


/**  审批状态 **/
export function getLeaveKindText(tag) {
    switch (tag) {
        case iEnum.LeaveKind.Busy:
            return '事假';
        case iEnum.LeaveKind.Disease:
            return '病假';
        case iEnum.LeaveKind.Birth:
            return '产假';
        case iEnum.LeaveKind.AccompanyBirth:
            return '陪产假';
        case iEnum.LeaveKind.Wedding:
            return '婚假';
        case iEnum.LeaveKind.Death:
            return '丧假';
    }
    return ""
}



