import React from 'react';
import { Drawer } from 'antd';
import IButton from "./IButton";


export default class IDrawerPage extends React.Component {
    state = { visible: false };

    constructor() {
        super();
        this.state = {
            width: "50%"
        }
    }

    componentDidMount() {
        if (this.props.width != null) {
            this.setState({
                width: this.props.width
            })
        }
    }

    render() {
        return (
            <div style={{ heigth: "100vh" }} className=" comm-flex-col-center ">
                <Drawer
                    title={this.props.title}
                    placement={this.props.placement}
                    destroyOnClose={this.props.maskClosable == false ? false : true}
                    maskClosable={this.props.maskClosable == false ? false : true}
                    closable={this.props.closable == false ? false : true}
                    onClose={this.props.onClose?this.props.onClose:this.props.onCancel}
                    visible={this.props.visible}
                    width={this.state.width}
                    zIndex={this.props.zIndex || 1000}
                >
                    <div className="comm-col  idrawer-view ">
                        <div className={this.props.footer ? "idrawer-content-footer" : "idrawer-content"}>
                            {
                                React.Children.map(this.props.children, function (child) {
                                    return <div style={{ height: "100%" }}>{child}</div>
                                })
                            }
                        </div>
                        {
                            this.props.footer ? <div className="idrawer-footer">
                                <div className="idrawer-footer-view">
                                    {
                                        this.props.cancelHide?
                                        null:
                                        <IButton 
                                            style={{ marginRight: 10 }} 
                                            type="default" 
                                            textColor="#666"
                                            onClick={this.props.onCancel}
                                            text={this.props.cancelText ? this.props.cancelText : "取消"} />
                                    }
                                    
                                    
                                    <IButton
                                        style={{ marginRight: 20 }}
                                        loading={this.props.loading}
                                        type="primary"
                                        onClick={this.props.onOK}
                                        htmlType={this.props.htmlType}
                                        text={this.props.confirmText ? this.props.confirmText : "确定"} />
                                </div>
                            </div> : null
                        }
                    </div>

                </Drawer>
            </div>
        );
    }
}
