import StorageTools from "./storageTools";
export function settingAuthority(IdentityFlag,objhavaauthority,needReturn){
    let reportSetting = [],shopSetting=[],chainSetting=[];
    if(IdentityFlag == 64){
        reportSetting = ['总览','营业数据','业绩排名','会员报表']
        shopSetting = ['店铺管理员']
        chainSetting = ['连锁设置','店铺设置','跨店消费']
    }else{
      const authority = JSON.parse(objhavaauthority.authority)
       authority.map((item,index)=>{
           if(item.flag == 1){//连锁设置
             chainSetting.push(item.name)
           }else if(item.flag == 2){//店铺设置
             shopSetting.push(item.name)
           }else if(item.flag == 3){//报表设置
             reportSetting.push(item.name)
           }
       })
    }
    let obj = {chainSetting,reportSetting,shopSetting};
    StorageTools.savemangleAuthority(obj)
    if(needReturn){
        return obj
    }
}




