//  购买模块Id
import React from 'react';
import {StorageTools} from "../comm/storageTools";

export const buyMenuId = {
    head_home: 2,//首页
    head_collect: 11, //收银
    head_collect_zcdd: 13, //暂存的订单
    head_order: 13,  //订单
    head_order_orderList: 13,//订单列表
    head_order_arrears: 14,//欠款
    head_order_sdhd: 45,//水单核对
    head_vip_hymd: 16,//会员-会员名单
    head_vip_kkyx: 17,//会员-开卡营销
    head_vip_czyx: 18,//会员-充值营销
    head_vip_dxyx: 19,//会员-短信营销
    head_marketing_ygfh: 23,//营销-员工分红
    head_marketing_sgyx: 24,//营销-散客营销
    head_marketing_xjq: 21,//营销-现金券
    head_marketing_cj: 22,//营销-抽奖
    head_marketing_lmfh: 25,//营销-闭环拓客
    head_finance: 27,//财务
    head_stock_jhgl: 34,//库存-进货管理
    head_stock_jxbb: 35,//库存-进销报表
    head_stock_ypgl: 36,//库存-院品管理
    head_stock_kcpd: 37,//库存-库存盘点
    head_stock_gqtx: 38,//库存-过期提醒
    head_stock_gcyj: 39,//库存-库存预警
    head_stock_gysgl: 40,//库存-供应商管理
    head_report_yysj: 29,//报表-营业数据
    head_report_ygyj: 30,//报表-员工业绩
    head_report_hybb: 31,//报表-会员报表
    head_report_yyfx: 32,//报表-营业分析
    head_setting_dpsz: 8,//设置-店铺设置
    head_setting_dpsz: 5,//设置-账号设置
    head_setting_fjsz: 9,//设置-房间设置
    head_setting_rjsq: 6,//设置-软件授权
    head_agree_yyqk: 42,//预约情况
    head_agree_yyjl: 43,//"预约记录"
    head_agree_yysz: 44,//"预约设置"
    head_agree_jf: 46,//"积分"
    // head_agree_jf: 46,//"积分"
    // head_agree_yyqk: 2,//预约情况
    // head_agree_yyjl: 2,//"预约记录"
    // head_agree_yysz: 2,//"预约设置"



};


var versionModule = null;
var timeOver = false;
var timeOverHint = false;
var timeOverDay = -1;
var vData = {};
var vList = [];
var time=null

/**
 * 获取购买状态
 */
export function buyState(state) {
    let host = window.location.host;
    if(host.indexOf("nightly.") !== -1){
        return false;
    }
    var bool = true;
    if (versionModule == null || versionModule.modules == null) {
        versionModule = this.getBuyModule()
    }
    if (versionModule != null && versionModule.modules != null) {
        versionModule.modules.forEach((item) => {
            if (state == item.id) {
                bool = false;
            }
        })
    }
    // console.log("查询购买返回结果",bool,versionModule);
    // return false;
    return bool;
}


export function setBuyModule(module) {
    versionModule = module;
    StorageTools.saveUserVsersion(module)
    // versionModule = module;
    // return true;
}


export function getBuyModule() {

    var version = StorageTools.getUserVsersion();
    if (version == null) {
        return {};
    }
    return version;
    // return true;
}

export function setCloseTime(data){
    time=data
}
export function getClostTime(){
    return time
}
export function setTimeOutVersion(v) {
    vData = v;
}
export function setTimeOutVersionList(v){
    vList=v
}
export function getTimeOutVersionList(){
    return vList;
}

export function getTimeOutVersion() {
    return vData;
}

export function getTimeOut() {
    return timeOver
}

export function getTimeOutHint() {
    return timeOverHint;
}

export function getTimeOutDay() {
    return timeOverHint;
}


export const BuyUtils = {
    buyMenuId: buyMenuId,
    setBuyModule: setBuyModule,
    buyState: buyState,
    getBuyModule: getBuyModule,
    setTimeOutVersion: setTimeOutVersion,
    getTimeOutHint: getTimeOutHint,
    getTimeOutDay: getTimeOutDay,
    getTimeOutVersion: getTimeOutVersion,
    getTimeOutVersionList:getTimeOutVersionList,
    setTimeOutVersionList:setTimeOutVersionList,
    setCloseTime:setCloseTime,
    getClostTime:getClostTime
};

export default BuyUtils;