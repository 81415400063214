import { getConversionDate, getConversionTime } from '@comm/dateHelp'
import { ECardFlag } from "@comm/iEnum"
import { calcMultiply, cloneDeep, showMessageError } from '@comm/utils'
import IDrawer from '@components/iDrawerPage'
import { IInputMoney, IInputNumber } from '@components/iInput'
import IInputString from '@components/iInputString'
import { Button, Checkbox, DatePicker, Icon, Input, Radio, Select, Tooltip, Upload, Alert } from 'antd'
import moment from 'moment'
import React from 'react'
import { ActivityState, ItemType, SShPlanCommissionItemRate } from '../../../bean/market/bonus'
import { cardTypeText } from "../../../comm/constantConvert"
import { UploadToOss } from '../../../comm/uploadimagMethod'
import service from '../../../services/marketing/bonusService'
import BonusUmeditor from '../bonusUmeditor'
import '../styles.css'
import BunusItem from './bunusItem'

import StorageTools from "@comm/storageTools";


var slectCardName = "";
var uploadImgName = "";
var cardName = "请选择";
var num = 0;
const dateFormat = 'YYYY-MM-DD';
const { MonthPicker, RangePicker } = DatePicker;
const iEnum = require("@comm/iEnum");
const { Option } = Select;
const { Search } = Input;
class Detail extends React.Component {
    constructor() {
        super();
        this.state = {
            saveData: {},
            tableData: [],
            allCardList: [],
            goodsType: 0,
            goodsId: 0,
            bunusItemVisible: false,
            cardList: [],
            cardTimeList: [],
            serviceList: [],
            goodsList: [],
            rechargeList: [],
            itemRateList: [],//自定义数据
        }
    }

    componentDidMount() {
        num = 0;
        this.getCardData();
        this.getCustomizedData();
    }

    getCustomizedData() {
        service.getGoodsList(this, res => {
            num += 1;
            this.setState({
                serviceList: res.serviceList,
                goodsList: res.goodsList
            }, () => this.initData())
        })
    }

    getCardData() {
        let rechargeList = [];
        const { detailData } = this.props;
        service.getCardList(this, res => {
            num += 1;
            if (res) {
                //    let data =res.cardList.concat(res.cardTimeList);
                let data = res.cardList;
                data.forEach((item, index) => {
                    if ((item.CardFlag & ECardFlag.Charge) == ECardFlag.Charge) {
                        rechargeList.push(item);
                    }
                    if (detailData.CardTypeId === item.Id) {
                        cardName = item.CardName;
                        slectCardName = item.CardName;
                    }
                    item.rowKey = index + "";
                    item.key = index + "";
                    item.Name = item.CardName;
                    item.CardName = item.CardName + `(${cardTypeText(item.CardKind)})`
                    // item._cardType = cardTypeText(item.CardKind);
                    // item._SalePrice = numberConvert(item.SalePrice) + "元"
                });
                res.cardTimeList.forEach((item, index) => {
                    item.Name = item.CardName;
                    // item._cardType = cardTypeText(item.CardKind);
                    // item._SalePrice = numberConvert(item.SalePrice) + "元"
                });
                // debugger
                this.setState({
                    allCardList: data,
                    cardList: res.cardList,
                    cardTimeList: res.cardTimeList,
                    rechargeList

                }, () => this.initData())
            }
        });
    }


    initData() {
        if (num === 2) {
            const { detailData } = this.props;
            const { serviceList, goodsList, cardList, cardTimeList, rechargeList } = this.state;
            detailData._BeginTime = getConversionDate(detailData.BeginTime);
            detailData._OverTime = getConversionDate(detailData.OverTime);
            detailData.PlanFee = detailData.PlanFee / 100;
            if (detailData.ExpiresMonth == 0) {
                detailData._ExpiresMonth = 0;
            } else {
                detailData._ExpiresMonth = 1;
            }

            detailData.ShCommissionRate.UniteRate = detailData.ShCommissionRate.UniteRate / 100;
            detailData.ShCommissionRate.NormalRate = detailData.ShCommissionRate.NormalRate / 100;
            detailData.ShCommissionRate.Virtualrate = detailData.ShCommissionRate.Virtualrate / 100;
            detailData.ShCommissionRate.Cardrate = detailData.ShCommissionRate.Cardrate / 100;
            detailData.ShCommissionRate.Tcardrate = detailData.ShCommissionRate.Tcardrate / 100;
            detailData.ShCommissionRate.Chargerate = detailData.ShCommissionRate.Chargerate / 100;

            detailData.ShEmpCommissionRate.UniteRate = detailData.ShEmpCommissionRate.UniteRate / 100;
            detailData.ShEmpCommissionRate.NormalRate = detailData.ShEmpCommissionRate.NormalRate / 100;
            detailData.ShEmpCommissionRate.Virtualrate = detailData.ShEmpCommissionRate.Virtualrate / 100;
            detailData.ShEmpCommissionRate.Cardrate = detailData.ShEmpCommissionRate.Cardrate / 100;
            detailData.ShEmpCommissionRate.Tcardrate = detailData.ShEmpCommissionRate.Tcardrate / 100;
            detailData.ShEmpCommissionRate.Chargerate = detailData.ShEmpCommissionRate.Chargerate / 100;
            if (detailData.ShCommissionRate._initItem == null) {
                let { NormalRate, Virtualrate, Cardrate, Tcardrate, Chargerate } = detailData.ShCommissionRate;
                //股东分佣
                let _ItemRateList = detailData.ShCommissionRate.ItemRateList;
                let arr = [];

                this.screenGoodsData(arr, _ItemRateList, serviceList, ItemType.Virtual, Virtualrate);
                this.screenGoodsData(arr, _ItemRateList, goodsList, ItemType.Normal, NormalRate);
                this.screenCardData(arr, _ItemRateList, cardList, ItemType.Card, Cardrate);
                this.screenCardData(arr, _ItemRateList, cardTimeList, ItemType.TCard, Tcardrate);
                this.screenCardData(arr, _ItemRateList, rechargeList, ItemType.Charge, Chargerate);
                detailData.ShCommissionRate.ItemRateList = arr;
                detailData.ShCommissionRate._initItem = true;
            }

            if (detailData.ShEmpCommissionRate._initItem == null) {
                let { NormalRate, Virtualrate, Cardrate, Tcardrate, Chargerate } = detailData.ShEmpCommissionRate;
                //次级分佣
                let _ItemRateList = detailData.ShEmpCommissionRate.ItemRateList;
                let arr = [];
                this.screenGoodsData(arr, _ItemRateList, serviceList, ItemType.Virtual, Virtualrate);
                this.screenGoodsData(arr, _ItemRateList, goodsList, ItemType.Normal, NormalRate);
                this.screenCardData(arr, _ItemRateList, cardList, ItemType.Card, Cardrate);
                this.screenCardData(arr, _ItemRateList, cardTimeList, ItemType.TCard, Tcardrate);
                this.screenCardData(arr, _ItemRateList, rechargeList, ItemType.Charge, Chargerate);
                detailData.ShEmpCommissionRate.ItemRateList = arr;
                detailData.ShEmpCommissionRate._initItem = true;
            }
        }
        this.setState(this)

    }


    /**
     * flag 1股东分佣 2次级分佣
     */
    getItemRateList(flag, type) {
        const { detailData } = this.props;
        let data = [];
        let arr = [];
        if (flag === 1) {
            data = detailData.ShCommissionRate.ItemRateList;
        } else {
            data = detailData.ShEmpCommissionRate.ItemRateList;
        }
        data.forEach(item => {
            if (item.ItemType === type) {
                arr.push(item);
            }
        })
        this.setState({
            bunusItemVisible: true,
            itemRateList: arr
        })

    }

    //商品，服务
    screenGoodsData(arr, _ItemRateList, data, type, rate) {
        // debugger
        data.forEach(item => {
            let isAdd = false;
            _ItemRateList.forEach(ele => {
                if (item.ItemId == ele.ItemId) {
                    isAdd = true;
                    ele.ItemPrice = item.Price / 100;
                    ele.ItemRate = ele.ItemRate / 100;
                    ele.ItemName = item.Title;
                    arr.push(ele);
                }
            })
            if (!isAdd) {
                let itemRate = SShPlanCommissionItemRate();
                itemRate.ItemName = item.Title;
                itemRate.ItemType = type;
                itemRate.ItemId = item.ItemId;
                itemRate.ItemPrice = item.Price / 100;
                itemRate.ItemRate = rate;
                arr.push(itemRate);
            }
        })
    }

    //会员卡，套餐卡
    screenCardData(arr, _ItemRateList, data, type, rate) {

        data.forEach(item => {
            let isAdd = false;
            _ItemRateList.forEach(ele => {
                if (item.Id == ele.ItemId) {
                    isAdd = true;
                    ele.ItemPrice = item.SalePrice / 100;
                    ele.ItemRate = ele.ItemRate / 100;
                    ele.ItemName = item.Name;
                    arr.push(ele);
                }
            })
            if (!isAdd) {
                let itemRate = SShPlanCommissionItemRate();
                itemRate.ItemName = item.Name;
                itemRate.ItemType = type;
                itemRate.ItemId = item.Id;
                itemRate.ItemPrice = item.SalePrice / 100;
                itemRate.ItemRate = rate;
                arr.push(itemRate);
            }
        })
    }



    onChangeListener(tag, e, event) {
        const { detailData } = this.props;
        // console.log(tag, e,event.key,this.state.allCardList);
        if (tag == "选择会员卡") {
            var id = parseInt(event.key);
            this.state.allCardList.every(item => {
                // debugger
                if (item.Id == id) {
                    detailData.CardTypeId = item.Id;
                    detailData.CardBalance = item.SalePrice;
                    // debugger
                    console.log("选中的会员卡：", item)
                    cardName = item.CardName;
                    return false
                    // this.setState({
                    //     selectedCard: item,
                    //     isShowCardDetail: true,
                    //     cardId: item.MchId,
                    //     activityViewByCard: { ListSActivityItemView: [] },
                    //     _salePrice: item.SalePrice / 100
                    // });
                }
                return true
            });

        } else {


            detailData[tag] = e.target.checked ? 1 : 2;
        }

        this.setState(this);
    }
    onInputChange(tag, e) {
        const { detailData } = this.props;
        detailData[tag] = e;
        this.setState(this);
        console.log(tag, e)
    }

    onInputDataChange(tag, data, e) {
        if (tag !== "UniteRate") {
            data.ItemRateList.forEach(item => {
                if (tag == "NormalRate" && item.ItemType == ItemType.Normal) {
                    item.ItemRate = e;
                } else if (tag == "Virtualrate" && item.ItemType == ItemType.Virtual) {
                    item.ItemRate = e;
                } else if (tag == "Cardrate" && item.ItemType == ItemType.Card) {
                    item.ItemRate = e;
                } else if (tag == "Tcardrate" && item.ItemType == ItemType.TCard) {
                    item.ItemRate = e;
                } else if (tag == "Chargerate" && item.ItemType == ItemType.Charge) {
                    item.ItemRate = e;
                }
            })
        }

        data[tag] = e;
        this.setState(this);
        console.log(tag, e)

        console.log(12123, this.props.detailData)
    }




    onSearch(val) {
        console.log('search:', val);

    }

    onDateChangeListener(tag, value, dateString) {
        console.log(`selected`, value, dateString);
        const { detailData } = this.props;
        if (dateString || dateString.length > 1) {

            detailData._BeginTime = dateString[0];
            detailData._OverTime = dateString[1];

            detailData.BeginTime = getConversionTime(dateString[0]+ " 00:00:00");
            detailData.OverTime = getConversionTime(dateString[1] + " 23:59:59");

        }
        this.setState(this);
    }

    onEditCallback(content) {
        const { detailData } = this.props;
        detailData.ActivityContent = content;
        this.setState(this)

    }
    onChangeRadio(tag, e) {
        const { detailData } = this.props;
        if (tag == "CommissionType") {
            detailData.ShCommissionRate.CommissionType = e.target.value;
        } else if (tag == "ShEmpCommissionRate") {
            detailData.ShEmpCommissionRate.CommissionType = e.target.value;
        } else {
            detailData[tag] = e.target.value;
        }

        this.setState(this);
        console.log(tag, e)
    }

    onRemoveImg(e, data) {
        const { detailData } = this.props;
        if (e.name === uploadImgName) {
            detailData.BackgroundImage = "";
            this.setState(this)
        }
    }

    beforeUpload = (flag, file) => {
        // console.log(flag, file)
        const { detailData } = this.props;
        const isLt9M = file.size / 1024 / 1024 < 4;
        if (!isLt9M) {
            showMessageError("文件大小不超过4M");
            return;
        }
        uploadImgName = file.name;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            // 使用ossupload覆盖默认的上传方法
            UploadToOss(this, "/mzl-img/upload/", file).then(data => {
                if (data != null && data.res.requestUrls.length > 0) {
                    const list = data.res.requestUrls[0].split("?");
                    detailData.BackgroundImage = list[0];
                    this.setState(this);
                    console.log('图片数据', list)
                }
            });
        };
        return false; // 不调用默认的上传方法
    };

    onOK() {
        let detailData = cloneDeep(this.props.detailData);


        if (detailData.PlanName == "") {
            showMessageError("请输入活动名称");
            return;
        }
        if (detailData.Consumption == 1 && detailData.CardTypeId === 0) {
            showMessageError("请选择可消费的会员卡");
            return;
        }else  if(detailData.PlanFee == "" || detailData.PlanFee <=0){
            showMessageError("入股资金必须大于0");
            return;
        }


        detailData.PlanFee = calcMultiply(detailData.PlanFee, 100);

        detailData.ShCommissionRate.UniteRate = calcMultiply(detailData.ShCommissionRate.UniteRate, 100);
        detailData.ShCommissionRate.NormalRate = calcMultiply(detailData.ShCommissionRate.NormalRate, 100);
        detailData.ShCommissionRate.Virtualrate = calcMultiply(detailData.ShCommissionRate.Virtualrate, 100);
        detailData.ShCommissionRate.Cardrate = calcMultiply(detailData.ShCommissionRate.Cardrate, 100);
        detailData.ShCommissionRate.Tcardrate = calcMultiply(detailData.ShCommissionRate.Tcardrate, 100);
        detailData.ShCommissionRate.Chargerate = calcMultiply(detailData.ShCommissionRate.Chargerate, 100);

        detailData.ShEmpCommissionRate.UniteRate = calcMultiply(detailData.ShEmpCommissionRate.UniteRate, 100);
        detailData.ShEmpCommissionRate.NormalRate = calcMultiply(detailData.ShEmpCommissionRate.NormalRate, 100);
        detailData.ShEmpCommissionRate.Virtualrate = calcMultiply(detailData.ShEmpCommissionRate.Virtualrate, 100);
        detailData.ShEmpCommissionRate.Cardrate = calcMultiply(detailData.ShEmpCommissionRate.Cardrate, 100);
        detailData.ShEmpCommissionRate.Tcardrate = calcMultiply(detailData.ShEmpCommissionRate.Tcardrate, 100);
        detailData.ShEmpCommissionRate.Chargerate = calcMultiply(detailData.ShEmpCommissionRate.Chargerate, 100);
        if (detailData.ShCommissionRate.CommissionType == 1) {
            detailData.ShCommissionRate.ItemRateList = []
        } else {
            let arr = [];
            detailData.ShCommissionRate.ItemRateList.forEach(item => {
                const { NormalRate, Virtualrate, Cardrate, Tcardrate, Chargerate } = detailData.ShCommissionRate;
                item.ItemRate = calcMultiply(item.ItemRate, 100);
                switch (item.ItemType) {
                    case ItemType.Virtual:
                        if (Virtualrate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                    case ItemType.Normal:
                        if (NormalRate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                    case ItemType.Card:
                        if (Cardrate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                    case ItemType.TCard:
                        if (Tcardrate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                    case ItemType.Charge:
                        if (Chargerate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                }
            })
            detailData.ShCommissionRate.ItemRateList = arr;
        }
        if (detailData.ShEmpCommissionRate.CommissionType == 1) {
            detailData.ShEmpCommissionRate.ItemRateList = []
        } else {
            let arr = [];
            detailData.ShEmpCommissionRate.ItemRateList.forEach(item => {
                item.ItemRate = calcMultiply(item.ItemRate, 100);
                const { NormalRate, Virtualrate, Cardrate, Tcardrate, Chargerate } = detailData.ShEmpCommissionRate;
                switch (item.ItemType) {
                    case ItemType.Virtual:
                        if (Virtualrate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                    case ItemType.Normal:
                        if (NormalRate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                    case ItemType.Card:
                        if (Cardrate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                    case ItemType.TCard:
                        if (Tcardrate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;
                    case ItemType.Charge:
                        if (Chargerate != item.ItemRate) {
                            arr.push(item)
                        }
                        break;

                }

            })
            detailData.ShEmpCommissionRate.ItemRateList = arr;
        }
        console.log(923123213, detailData);

        detailData.ActivityContent = detailData.ActivityContent.replace(/&nbsp;/g, ' ');
        // debugger
        // detailData.ActivityContent = this.formatRichText(detailData.ActivityContent);
        this.setState({
            loading: true
        })
        service.saveShPlan(this, detailData).then(res => {
            this.setState({
                loading: false
            })
            this.props.onOK();
        })
    }



    onCancel() {
        this.props.onCancel();
    }

    formatRichText(html) {
        let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
            match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
            match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
            match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
            return match;
        });
        newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
            match = match.replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi, 'width:100%;');
            return match;
        });
        newContent = newContent.replace(/<br[^>]*\/>/gi, '');
        newContent = newContent.replace(/\<img/gi, '<img style="width:100%;height:auto;display:block;margin:10px 0;"');
        return newContent;
    }

    render() {
        const { detailData } = this.props;
        return (
            <IDrawer
                title={"分红方案"}
                loading={this.state.loading}
                footer={detailData._stateText == "未开始"}
                onOK={this.onOK.bind(this)}
                onCancel={this.onCancel.bind(this)}
                width="90%"
                visible={this.props.visible}>
                <div className="comm-w-h comm-row comm-item-justify-between">
                    <div style={{ width: "calc(100% - 370px)", overflow: "auto" }}>
                        <div className="comm-title-view">
                            <div className="comm-title-right">*状态</div>
                            <Radio.Group onChange={this.onChangeRadio.bind(this, "Enabled")} value={detailData.Enabled}>
                                <Radio value={1}>启用</Radio>
                                <Radio value={2}>停用</Radio>
                            </Radio.Group>
                        </div>
                        <div className="comm-title-view">
                            <div className="comm-title-right">*活动名称</div>
                            <IInputString style={{ width: 255 }} value={detailData.PlanName} onChange={this.onInputChange.bind(this, "PlanName")}></IInputString>
                        </div>
                        <div className="comm-title-view">
                            <div className="comm-title-right">*活动有效期</div>
                            <RangePicker
                                onChange={this.onDateChangeListener.bind(this, "time")}
                                placeholder={['开始日期', '结束日期']}
                                value={[moment(detailData._BeginTime, dateFormat), moment(detailData._OverTime, dateFormat)]}
                                style={{ width: 255 }}
                                format={dateFormat}
                            />
                            <div >
                                <Icon type="info-circle" style={{ marginLeft: 10, fontSize: 16, color: "#F5222D" }} />
                                <span style={{ marginLeft: 5, fontSize: 13, color: "#F5222D" }}>活动时间生效后,该活动设置无法修改</span>
                            </div>

                        </div>
                        <div className="comm-title-view">
                            <div className="comm-title-right">*入股资金</div>
                            <IInputMoney
                                style={{ width: 255 }}
                                value={detailData.PlanFee}
                                onChange={this.onInputChange.bind(this, "PlanFee")}>
                            </IInputMoney>
                            <div>
                                <span style={{ paddingLeft: 10, paddingRight: 10 }}>元</span>
                                <Checkbox
                                    checked={detailData.Consumption == 1}
                                    onChange={this.onChangeListener.bind(this, "Consumption")}>
                                    可用于消费
                                </Checkbox>
                            </div>
                        </div>
                        {
                            detailData.Consumption == 1 ?
                                <div className="comm-title-view">
                                    <div className="comm-title-right">*选择消费卡</div>
                                    <Select
                                        showSearch
                                        placeholder="请选择"
                                        style={{ width: 255, height: "30px" }}
                                        onChange={this.onChangeListener.bind(this, "选择会员卡")}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        // onSelect={this.onChangeCallback.bind(this, "selected")}
                                        value={cardName}
                                    >
                                        {
                                            this.state.allCardList.map(d => <Option key={d.Id} value={d.CardName}>
                                                <Tooltip title={d.CardName}>
                                                    <div key={d.key} className="option-view time">{d.CardName}</div>
                                                </Tooltip>
                                            </Option>)
                                        }
                                    </Select>
                                </div> :
                                null
                        }

                        <div className="comm-title-view">
                            <div className="comm-title-right">*股东期限</div>
                            <Radio.Group onChange={this.onChangeRadio.bind(this, "_ExpiresMonth")} value={detailData._ExpiresMonth}>
                                <Radio value={1}>加入起
                                <IInputNumber
                                        disabled={detailData._ExpiresMonth == 0}
                                        onChange={this.onInputChange.bind(this, "ExpiresMonth")}
                                        value={detailData.ExpiresMonth == 0 ? "" : detailData.ExpiresMonth}
                                        style={{ width: 80, marginLeft: 8, marginRight: 5 }}
                                    >
                                    </IInputNumber> 个月</Radio>
                                <Radio value={0}>永久</Radio>
                            </Radio.Group>
                        </div>
                        <div className="comm-row">
                            <div className="comm-title-right" style={{ marginTop: 5 }}>*自推分红</div>
                            <Radio.Group onChange={this.onChangeRadio.bind(this, "CommissionType")} value={detailData.ShCommissionRate.CommissionType}>
                                <Radio style={{ display: "block" }} value={1}>统一<IInputMoney

                                        disabled={detailData.ShCommissionRate.CommissionType == 2}
                                        onChange={this.onInputDataChange.bind(this, "UniteRate", detailData.ShCommissionRate)}
                                        value={detailData.ShCommissionRate.UniteRate}
                                        style={{ width: 80, marginLeft: 22, marginRight: 5 }}>
                                    </IInputMoney> %</Radio>
                                <Radio style={{ display: "block", marginTop: 10 }} value={2}>按分类
                                    {
                                        detailData.ShCommissionRate.CommissionType == 2 ?
                                            (
                                                <div>
                                                    <div className="bonus-line-item">
                                                        <div>商品销售 　
                                                            <IInputMoney
                                                                onChange={this.onInputDataChange.bind(this, "NormalRate", detailData.ShCommissionRate)}
                                                                value={detailData.ShCommissionRate.NormalRate}
                                                                style={{ width: 80, marginLeft: 5, marginRight: 5 }}>
                                                            </IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 1, ItemType.Normal)}>自定义 </a></div>
                                                    </div>
                                                    <div className="bonus-line-item">
                                                        <div>服务销售 　
                                                            <IInputMoney
                                                                onChange={this.onInputDataChange.bind(this, "Virtualrate", detailData.ShCommissionRate)}
                                                                value={detailData.ShCommissionRate.Virtualrate}
                                                                style={{ width: 80, marginLeft: 5, marginRight: 5 }}>
                                                            </IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 1, ItemType.Virtual)}>自定义 </a></div>
                                                    </div>
                                                    <div className="bonus-line-item">
                                                        <div>套餐卡销售　 <IInputMoney onChange={this.onInputDataChange.bind(this, "Tcardrate", detailData.ShCommissionRate)}
                                                            value={detailData.ShCommissionRate.Tcardrate} style={{ width: 80, marginLeft: 5, marginRight: 5 }}></IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 1, ItemType.TCard)}>自定义 </a></div>
                                                    </div>
                                                    <div className="bonus-line-item">
                                                        <div>会员卡销售 <IInputMoney onChange={this.onInputDataChange.bind(this, "Cardrate", detailData.ShCommissionRate)}
                                                            value={detailData.ShCommissionRate.Cardrate} style={{ width: 80, marginLeft: 5, marginRight: 5 }}></IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 1, ItemType.Card)}>自定义 </a></div>
                                                    </div>
                                                    <div className="bonus-line-item">
                                                        <div>会员卡充值 <IInputMoney onChange={this.onInputDataChange.bind(this, "Chargerate", detailData.ShCommissionRate)}
                                                            value={detailData.ShCommissionRate.Chargerate} style={{ width: 80, marginLeft: 5, marginRight: 5 }}></IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 1, ItemType.Charge)}>自定义 </a></div>
                                                    </div>
                                                </div>
                                            ) : null
                                    }

                                </Radio>
                            </Radio.Group>
                        </div>
                        <div className="comm-row" style={{ marginTop: 10 }}>
                            <div className="comm-title-right" style={{ marginTop: 5 }}>*下级推荐分红</div>
                            <Radio.Group onChange={this.onChangeRadio.bind(this, "ShEmpCommissionRate")} value={detailData.ShEmpCommissionRate.CommissionType}>
                                <Radio style={{ display: "block" }} value={1}>统一
                                <IInputMoney disabled={detailData.ShEmpCommissionRate.CommissionType == 2}
                                        onChange={this.onInputDataChange.bind(this, "UniteRate", detailData.ShEmpCommissionRate)}
                                        value={detailData.ShEmpCommissionRate.UniteRate}
                                        style={{ width: 80, marginLeft: 22, marginRight: 5 }}>
                                    </IInputMoney> %</Radio>
                                <Radio style={{ display: "block", marginTop: 10 }} value={2}>按分类
                                    {
                                        detailData.ShEmpCommissionRate.CommissionType == 2 ?
                                            (
                                                <div>
                                                    <div className="bonus-line-item">
                                                        <div>商品销售 　
                                                             <IInputMoney
                                                                onChange={this.onInputDataChange.bind(this, "NormalRate", detailData.ShEmpCommissionRate)}
                                                                value={detailData.ShEmpCommissionRate.NormalRate}
                                                                style={{ width: 80, marginLeft: 5, marginRight: 5 }}>
                                                            </IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 2, ItemType.Normal)}>自定义 </a></div>
                                                    </div>
                                                    <div className="bonus-line-item">
                                                        <div>服务销售 　
                                                             <IInputMoney
                                                                onChange={this.onInputDataChange.bind(this, "Virtualrate", detailData.ShEmpCommissionRate)}
                                                                value={detailData.ShEmpCommissionRate.Virtualrate}
                                                                style={{ width: 80, marginLeft: 5, marginRight: 5 }}>
                                                            </IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 2, ItemType.Virtual)}>自定义 </a></div>
                                                    </div>
                                                    <div className="bonus-line-item">
                                                        <div>套餐卡销售　 <IInputMoney onChange={this.onInputDataChange.bind(this, "Tcardrate", detailData.ShEmpCommissionRate)}
                                                            value={detailData.ShEmpCommissionRate.Tcardrate} style={{ width: 80, marginLeft: 5, marginRight: 5 }}></IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 2, ItemType.TCard)}>自定义 </a></div>
                                                    </div>
                                                    <div className="bonus-line-item">
                                                        <div>会员卡销售 <IInputMoney onChange={this.onInputDataChange.bind(this, "Cardrate", detailData.ShEmpCommissionRate)}
                                                            value={detailData.ShEmpCommissionRate.Cardrate} style={{ width: 80, marginLeft: 5, marginRight: 5 }}></IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 2, ItemType.Card)}>自定义 </a></div>
                                                    </div>
                                                    <div className="bonus-line-item">
                                                        <div>会员卡充值 <IInputMoney onChange={this.onInputDataChange.bind(this, "Chargerate", detailData.ShEmpCommissionRate)}
                                                            value={detailData.ShEmpCommissionRate.Chargerate} style={{ width: 80, marginLeft: 5, marginRight: 5 }}></IInputMoney> % <a style={{ marginLeft: 20 }} onClick={this.getItemRateList.bind(this, 2, ItemType.Charge)}>自定义 </a></div>
                                                    </div>
                                                </div>
                                            ) : null
                                    }

                                </Radio>
                            </Radio.Group>
                        </div>
                        <div className="comm-title-view" style={{ marginTop: 10 }}>
                            <div className="comm-title-right">*活动页首图</div>
                            <Upload
                                beforeUpload={this.beforeUpload.bind(this, "04")}
                                multiple={true}
                                onRemove={this.onRemoveImg.bind(this)}
                            >
                                <div  className="comm-row comm-item-center">
                                    <Button><Icon type="upload" /> 上传图片</Button>
                                    <div>
                                        <Icon type="info-circle" style={{ marginLeft: 10, fontSize: 16, color: "#F5222D" }} />
                                        <span style={{ marginLeft: 5, fontSize: 13, color: "#F5222D" }}>活动页首图不能大于4M,建议图片尺寸为1080×1920</span>
                                    </div>
                                </div>
                                
                            </Upload>
                            
                        </div>
                        <div className="comm-row">
                            <div className="comm-title-right">*活动页内容</div>
                            <div style={{ width: "calc(100% - 100px)" }}>
                                <BonusUmeditor detailData={detailData} onEditCallback={this.onEditCallback.bind(this)} ></BonusUmeditor>
                                <Alert style={{ marginTop: 5 }} message="活动页内容图片不能大于1M,建议图片尺寸为1080×1920" type="warning" />
                            </div>

                        </div>
                    </div>
                    <div style={{ maxWidth: 400, width: 350, height: "100%", }}>
                        <div className='bgActivity' style={{
                            height: 530,
                            marginLeft: 0,
                            padding: 10,
                            position: 'relative'
                        }}>
                            <div style={{ color: '#000', marginTop: 30, marginLeft: 100, marginBottom: 10 }}>活动详情</div>
                            {/* <i class='iconfont' style={{position: 'absolute', top: 11, right: 80, fontSize: 20}}>&#xe696;</i>
                        <span style={{position: 'absolute', top: 15, left: 25, color: '#000', fontSize: 12}}>10:30</span> */}
                            <div className='el_tableDiv' style={{
                                marginLeft: 10,
                                height: 386,
                                width: 290,
                                paddingRight: 45,
                            }}>

                                <img style={{ height: "auto", width: "100%", }} src={detailData.BackgroundImage}></img>
                                <div className='activity' style={{ padding: '0 20px', marginTop: 10, height: '100px', borderRadius: 6, overflow: 'hidden' }}>
                                    <div className='time' style={{
                                        color: '#fff',
                                        fontSize: 16,
                                        height: '55px',
                                        lineHeight: '55px'
                                    }}>
                                        <Tooltip title={detailData.ActivityName}>
                                            <span style={{ color: '#fff' }}>{detailData.PlanName}</span>
                                        </Tooltip>
                                    </div>
                                    <div className='flexBox'>

                                        <span style={{ color: '#fff', fontSize: 12 }}>入股资金：￥{detailData.PlanFee}</span>
                                        {/* <span
                                        style={{
                                            color: '#fff',
                                            fontSize: 12
                                        }}>体验价：￥{6}</span> */}
                                    </div>
                                </div>
                                
                                {
                                    detailData.Consumption ==1?
                                    <div>
                                    <div style={{
                                        marginTop: 10,
                                        padding: 10,
                                        borderTop: '1px #e9e9e9 solid',
                                        borderBottom: '1px #e9e9e9 solid',
                                        fontSize: 12,
                                        color: '#333'
                                        }}>
                                        消费卡
                                        </div>
                                        <div>
                                        <div className='flexBox'
                                            style={{ borderBottom: '1px #e9e9e9 solid', padding: 10, alignItems: 'center' }}>
                                            <div>
                                                <div
                                                    style={{ color: '#333' }}>{cardName}
                                                </div>
                                            </div>
                                            {/* <div style={{color: 'red'}}>￥{4}</div> */}
                                        </div>
                                        {this.state.zmoney ?
                                            <div style={{ textAlign: 'right', borderBottom: '1px #e9e9e9 solid', padding: 10 }}>合计：<span
                                                style={{ color: 'red' }}>￥{this.state.zmoney}</span></div> : null}
                                    </div>
                                </div>:null
                                }
                                
                                
                                <div style={{ padding: 10, borderBottom: '1px #e9e9e9 solid', fontSize: 12, color: '#333' }}>
                                    <span style={{ marginRight: 20 }}>有效期</span>
                                    {getConversionDate(detailData.BeginTime)}至{getConversionDate(detailData.OverTime)}
                                </div>
                                {
                                    detailData.Remark ? <div style={{
                                        whiteSpace: "pre",
                                        padding: 10,
                                        borderBottom: '1px #e9e9e9 solid',
                                        fontSize: 12,
                                        color: '#333'
                                    }}>
                                        {detailData.Remark}
                                    </div> : null
                                }

                                <div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: 2,
                                        background: '#fff',
                                        padding: 10,
                                        borderBottom: '1px #e9e9e9 solid'
                                    }}>
                                        <span style={{ fontSize: 12, color: '#333' }}>{StorageTools.getUserInfo().StoreName}</span>
                                        <span style={{ color: '#333' }}><Icon type="home" /></span>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        marginBottom: 2,
                                        background: '#fff',
                                        padding: 10,
                                        borderBottom: '1px #e9e9e9 solid'
                                    }}>
                                        <span style={{
                                            fontSize: 12,
                                            color: '#333'
                                        }}>{StorageTools.getUserInfo().Phone}</span><span><Icon style={{ color: '#4b98ff' }}
                                            type="phone" /></span></div>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        background: '#fff',
                                        padding: 10,
                                        borderBottom: '1px #e9e9e9 solid'
                                    }}>
                                        <span style={{
                                            fontSize: 12,
                                            color: '#333'
                                        }}>{StorageTools.getUserInfo().Extra.detailAddress}</span><span
                                            style={{ color: '#333' }}><Icon
                                                type="environment" /></span>
                                    </div>
                                </div>
                                <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: this.formatRichText(detailData.ActivityContent) }} ></div>
                            </div>
                            <div style={{
                                position: 'relative',
                                bottom: -15,
                                width: 251,
                                marginLeft: 7,
                                borderBottomLeftRadius: 21,
                                borderBottomRightRadius: 21,
                                background: '#A835FF',
                                textAlign: 'center',
                                height: '45px',
                                lineHeight: '45px',
                                color: '#fff'
                            }}>
                                立刻加入
                            </div>
                        </div>

                        {/* <div  style={{width:"100%",overflow:"auto",height:"100%",backgroundImage:`url(${detailData.BackgroundImage})`,backgroundSize:"100% 100%"}}>
                            <div  dangerouslySetInnerHTML={{ __html: detailData.ActivityContent }} >

                            </div>
                        </div> */}

                    </div>

                    {
                        this.state.bunusItemVisible ? (
                            <BunusItem
                                itemRateList={this.state.itemRateList}
                                visible={this.state.bunusItemVisible}
                                onCancel={() => {
                                    this.setState({ bunusItemVisible: false })
                                }}
                                onOK={() => {
                                    this.setState({ bunusItemVisible: false })
                                }}

                            ></BunusItem>
                        ) : null
                    }

                </div>


            </IDrawer>

        )
    }
}

export default Detail