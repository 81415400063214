import React from 'react'
import { Icon, Input, Button, Tooltip, Radio, Select, Upload, Checkbox ,DatePicker ,Alert} from 'antd'
import IButton from '@components/IButton'
import IDrawer from '@components/iDrawerPage'
import ISearch from '@components/ISearch'
import ITableList from '@components/iTableList'
import { IModal } from '@components/iModal'
import IInputString from '@components/iInputString'
import {IInputNumber,IInputMoney} from '@components/iInput'
import StorageTools from "@comm/storageTools";
import { $mzlApi } from "@comm/mzl-core";
import { messageError, messageSuccess,calcMultiply,calcDivide,calcAdd } from '@comm/utils';
import ITablePage, { getPageSize, setTabTop } from '@components/iTablePage'
import { FnMenuId } from '@comm/constans'
import APINames from "@comm/api-names"
import { connect } from 'react-redux'
import { setDefaultTitle } from "@reducers/iconRedux";
import { goodsMode } from "@js/goods/action";
import { update } from "@js/goods/goodsManage/action";

import ItemVipView from "@js/settings/itemVipView";
import ItemRoleView from "@js/settings/itemRoleView";
import _ from "lodash";


import ModelType from "@js/goods/goodsManage/modelType";
import {SPmsGoods,SCashierReference,SPmsGoodsParameter} from "@js/goods/bean";
import { cloneDeep, showMessageError } from '../../../comm/utils'
import { UploadToOss } from '../../../comm/uploadimagMethod'
import BonusUmeditor from '../bonusUmeditor'
import Goods from './goods'

import moment from 'moment';
import {getConversionYear,getConversionDay ,getConversionTime,getConversionDate} from '@comm/dateHelp';
import { ActivityState } from '../../../bean/market/bonus'
import service from '../../../services/marketing/bonusService'

const dateFormat = 'YYYY-MM-DD';
const { MonthPicker, RangePicker } = DatePicker;
const iEnum = require("@comm/iEnum");
const { Option } = Select;
const { Search } = Input;

var uploadImgName = "";
class Detail extends React.Component {
    constructor() {
        super();
        this.state = {
            saveData: {},
            tableData:[],
            cardList:[],
            goodsType:0,
            goodsId:0,
            goodsVisible:false,
        }
    }

    componentDidMount() {
        
    }
    onClickListener(){
        this.props.onCancel();
    }
    onChangeListener(){

    }
    onInputChange(tag,e){
        const {detailData} = this.props; 
        detailData[tag] = e;
        this.setState(this);
        console.log(tag, e)
    }

    onChangeRadio(tag,e){
        const {detailData} = this.props; 
        if(tag == "CommissionType"){
            detailData.ShCommissionRate.CommissionType =  e.target.value;
        }else if(tag == "ShEmpCommissionRate"){
            detailData.ShEmpCommissionRate.CommissionType =  e.target.value;
        }else{
            detailData[tag] = e.target.value;
        }
        
        this.setState(this);
        console.log(tag, e)
    }
    onSearch(val) {
        console.log('search:', val);
        
      }

    onDateChangeListener(tag,value, dateString){
        console.log(`selected`,value,dateString);
        const {detailData} = this.props; 
        if(dateString || dateString.length>1){
            
            detailData._BeginTime =  dateString[0];
            detailData._OverTime = dateString[1];

            detailData.BeginTime = getConversionTime(dateString[0]) ;
            detailData.OverTime = getConversionTime(dateString[1]+" 23:59:59");  
            
        }
        this.setState(this);
    }

    onEditCallback(content){
        const {detailData} = this.props; 
        detailData.ActivityContent = content;
        this.setState(this)
        
    }

    onRemoveImg(e,data){
        const {detailData} = this.props; 
        if(e.name === uploadImgName){
            detailData.BackgroundImage = "";
            this.setState(this)
        }
    }
    
  beforeUpload = (flag, file) => {
    console.log(123123,file)
    const {detailData} = this.props; 
    const isLt9M = file.size / 1024 / 1024 < 4;
    if (!isLt9M) {
      showMessageError("文件大小不超过4M");
      return;
    }
    uploadImgName= file.name;
    
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // 使用ossupload覆盖默认的上传方法
      UploadToOss(this, "/mzl-img/upload/", file).then(data => {
        if (data != null && data.res.requestUrls.length > 0) {
          const list = data.res.requestUrls[0].split("?");
          detailData.BackgroundImage = list[0];
          this.setState(this);
          console.log('图片数据', list)



        }
      });
    };
    return false; // 不调用默认的上传方法
  };

  onOK(){
    const detailData = cloneDeep(this.props.detailData) ;
    let allActivityPrice = 0;
    let allPrice = 0;
    let allCount = 0;
    if(detailData.ActivityName ==""){
        showMessageError("请输入活动名称");
        return;
    }else if(detailData.Count ==0){
        showMessageError("请输入活动发放数量");
        return;
    } if(detailData.ActivityItems.length ==0){
        showMessageError("请选择活动项目");
        return;
    }

    detailData.ActivityItems.forEach(item => {
        item.ActivityPrice = calcMultiply(item.ActivityPrice,100);
        item.Amount = calcMultiply(item.Amount,100);
        item.Price = calcMultiply(item.Price,100);

        allActivityPrice += calcMultiply(item.ActivityPrice ,item.Count);
        allPrice += calcMultiply(item.Price ,item.Count);
        allCount += item.Count;

        if(detailData._ExpireDay == 0){
            item.ExpireDay = 0
        }else{
            item.ExpireDay = detailData.ExpireDay
        }
        
    });
    detailData.ActivityPrice = allActivityPrice;
    if(detailData.ActivityPrice == "" || detailData.ActivityPrice <=0){
        showMessageError("活动项目总额必须大于0");
        return;
    }

    detailData.BnsCms = calcMultiply(detailData.BnsCms,100);
    // debugger
    detailData.ActivityContent =detailData.ActivityContent.replace(/&nbsp;/g, ' ') ;
    // detailData.ActivityContent =detailData.ActivityContent.replace(/:/g, ' ') ;
    // detailData.ActivityContent =detailData.ActivityContent.replace(/: /g, ' ') ;
    // detailData.ActivityContent =detailData.ActivityContent.replace(/"/g, "'") ;
    // detailData.ActivityContent =detailData.ActivityContent.replace(/微软雅黑, Microsoft YaHei/g, "") ;
    // detailData.ActivityContent =detailData.ActivityContent.replace(/微软雅黑, quot/g, "") ;
    
    // detailData.ActivityContent = detailData.ActivityContent;
    // detailData.ActivityContent = encodeURI(encodeURI(detailData.ActivityContent)) ;
    detailData.Price = allPrice;
    // console.log("onOK",detailData);
    this.setState({
        loading:true
    })
    service.saveShActivity(this,detailData).then(res=>{
        this.setState({
            loading:false
        })
        this.props.onOK();
    })


  }

  getAllMoeny(data){
    let allActivityPrice = 0;
    let allPrice = 0;
    data.forEach(item=>{
        allActivityPrice = calcAdd(allActivityPrice,calcMultiply(item.ActivityPrice ,item.Count));
        allPrice = calcAdd(allPrice,calcMultiply(item.Price ,item.Count))  ;
    })
    return {
        allActivityPrice,
        allPrice
    }
}

formatRichText(html){
    let newContent= html.replace(/<img[^>]*>/gi,function(match,capture){
        match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
        match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
        match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
        return match;
    });
    newContent = newContent.replace(/style="[^"]+"/gi,function(match,capture){
        match = match.replace(/width:[^;]+;/gi, 'width:100%;').replace(/width:[^;]+;/gi, 'width:100%;');
        return match;
    });
    newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    newContent = newContent.replace(/\<img/gi, '<img style="width:100%;height:auto;display:block;margin:10px 0;"');
    return newContent;
  }

    render() {

        const {detailData} = this.props;
        console.log(12313123,detailData)
        
        let allMoenyData = this.getAllMoeny(detailData.ActivityItems);
        return (
            <IDrawer
                title={"分红方案"}  
                loading={this.state.loading}
                footer={detailData._stateText == "未开始"} 
                onOK={this.onOK.bind(this)}
                onCancel={this.props.onCancel}
                width="90%" 
                visible={this.props.visible}>
                <div className="comm-w-h comm-row comm-item-justify-between">
                    <div style={{width:"calc(100% - 370px)" ,overflow:"auto"}}>
                        
                        <div className="comm-title-view">
                            <div className="comm-title-right">*活动名称</div>
                            <IInputString style={{width:255}} value={detailData.ActivityName} onChange={this.onInputChange.bind(this,"ActivityName")}></IInputString>
                        </div>
                        <div className="comm-title-view">
                            <div className="comm-title-right">*状态</div>
                            <Radio.Group onChange={this.onChangeRadio.bind(this,"State")} value={detailData.State}>
                                <Radio value={1}>启用</Radio>
                                <Radio value={2}>停用</Radio>
                            </Radio.Group>
                        </div>
                        <div className="comm-title-view">
                            <div className="comm-title-right">*发放数量</div>
                            <IInputNumber style={{width:255}} 
                             onChange={this.onInputChange.bind(this,"Count")} 
                             value={detailData.Count} ></IInputNumber>
                        </div>
                        <div className="comm-title-view">
                            <div className="comm-title-right">*活动项目</div>
                            <Button type="primary" onClick={()=>this.setState({goodsVisible:true})}>设置活动项目</Button>
                        </div>
                        <div className="comm-title-view">
                            <div className="comm-title-right">*活动有效期</div>
                                <RangePicker
                                    defaultValue={[moment(detailData._BeginTime, dateFormat), moment(detailData._OverTime, dateFormat)]}
                                    onChange={this.onDateChangeListener.bind(this,"商户有效期")}
                                    placeholder={['开始日期', '结束日期']}
                                    style={{width:255}} 
                                    format={dateFormat}
                                />
                                
                                <div >
                                    <Icon type="info-circle" style={{marginLeft:10,fontSize:16,color:"#F5222D"}}/>
                                    <span style={{marginLeft:5,fontSize:13,color:"#F5222D"}}>活动时间生效后,该活动设置无法修改</span>
                                </div>
                        </div>
                        <div className="comm-title-view">
                            <div className="comm-title-right">首次分红</div>
                            <IInputMoney 
                                style={{width:255}}
                                value={detailData.BnsCms}
                                onChange={this.onInputChange.bind(this,"BnsCms")} >
                             </IInputMoney>
                             <span style={{paddingLeft:10}}>元</span>
                        </div>
                        
                       
                       
                        <div className="comm-title-view">
                            <div className="comm-title-right">*活动页首图</div>
                            <Upload
                                beforeUpload={this.beforeUpload.bind(this,"")}
                                onChange={this.handleChange}
                                onRemove={this.onRemoveImg.bind(this)}
                                multiple={true}
                                >
                                <div  className="comm-row comm-item-center">
                                    <Button><Icon type="upload" /> 上传图片</Button>
                                    <div>
                                        <Icon type="info-circle" style={{ marginLeft: 10, fontSize: 16, color: "#F5222D" }} />
                                        <span style={{ marginLeft: 5, fontSize: 13, color: "#F5222D" }}>活动页首图不能大于4M,建议图片尺寸为1080×1920</span>
                                    </div>
                                </div>
                                </Upload>
                        </div>
                        <div className="comm-row">
                            <div className="comm-title-right">*活动页内容</div>
                            <div style={{width:"calc(100% - 100px)"}}> 
                                <BonusUmeditor detailData={detailData} onEditCallback={this.onEditCallback.bind(this)}></BonusUmeditor>
                                
                                <Alert style={{marginTop:5}} message="活动页内容图片不能大于1M,建议图片尺寸为1080×1920" type="warning" />
                            </div>
                           
                        </div>
                    </div>
                    <div style={{maxWidth:400,width:350,height:"100%",padding:"0px",backgroundSize:"100% 100%"}}>

                    <div className='bgActivity' style={{
                        height: 530,
                        marginLeft: 0,
                        padding: 10,
                        position: 'relative'
                    }}>
                        <div style={{color: '#000', marginTop: 30, marginLeft: 100, marginBottom: 10}}>活动详情</div>
                        {/* <i class='iconfont' style={{position: 'absolute', top: 11, right: 80, fontSize: 20}}>&#xe696;</i>
                        <span style={{position: 'absolute', top: 15, left: 25, color: '#000', fontSize: 12}}>10:30</span> */}
                        <div className='el_tableDiv' style={{
                            marginLeft: 10,
                            height: 386,
                            width: 290,
                            paddingRight: 45,
                        }}>
                            
                            <img style={{height:"auto",width:"100%",}} src={detailData.BackgroundImage}></img>
                            <div className='activity'style={{padding: '0 20px',marginTop:10, height: '100px', borderRadius: 6, overflow: 'hidden'}}>
                                <div className='time' style={{
                                    color: '#fff',
                                    fontSize: 16,
                                    height: '55px',
                                    lineHeight: '55px'
                                }}>
                                    <Tooltip title={detailData.ActivityName}>
                                        <span style={{color: '#fff'}}>{detailData.ActivityName}</span>
                                    </Tooltip>
                                </div>
                                <div className='flexBox'>

                                    <del
                                        style={{color: '#fff', fontSize: 12}}>原价：￥{allMoenyData.allPrice}</del>
                                    <span
                                        style={{
                                            color: '#fff',
                                            fontSize: 12
                                        }}>体验价：￥{allMoenyData.allActivityPrice}</span>
                                </div>
                            </div>
                            <div style={{
                                marginTop: 10,
                                padding: 10,
                                borderTop: '1px #e9e9e9 solid',
                                borderBottom: '1px #e9e9e9 solid',
                                fontSize: 12,
                                color: '#333'
                            }}>
                                项目
                            </div>
                            <div>
                                {
                                    detailData.ActivityItems.map((item, index) => {
                                        return (
                                            <div key={index} className='flexBox'
                                                style={{borderBottom: '1px #e9e9e9 solid', padding: 10, alignItems: 'center'}}>
                                                <div>
                                                    <div
                                                        style={{color: '#333'}}>{item.ProductTitle}({item.Count}次)
                                                    </div>
                                                    <div style={{fontSize: 12, color: '#666'}}>
                                                        <del style={{marginRight: 10}}>原价：￥{item.Price}</del>
                                                        <div>体验价：￥{item.ActivityPrice}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{color: 'red'}}>￥{item.ActivityPrice * item.Count}</div>
                                            </div>
                                        )
                                    })
                                }
                                {this.state.zmoney ?
                                    <div style={{textAlign: 'right', borderBottom: '1px #e9e9e9 solid', padding: 10}}>合计：<span
                                        style={{color: 'red'}}>￥{this.state.zmoney}</span></div> : null}
                            </div>
                            <div style={{padding: 10, borderBottom: '1px #e9e9e9 solid', fontSize: 12, color: '#333'}}>
                                <span style={{marginRight: 20}}>有效期</span>
                                {getConversionDate(detailData.BeginTime)}至{getConversionDate(detailData.OverTime)}
                            </div>
                            {
                                detailData.Remark ? <div style={{
                                    whiteSpace: "pre",
                                    padding: 10,
                                    borderBottom: '1px #e9e9e9 solid',
                                    fontSize: 12,
                                    color: '#333'
                                }}>
                                    {detailData.Remark}
                                </div> : null
                            }

                            <div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 2,
                                    background: '#fff',
                                    padding: 10,
                                    borderBottom: '1px #e9e9e9 solid'
                                }}>
                                    <span style={{fontSize: 12, color: '#333'}}>{StorageTools.getUserInfo().StoreName}</span>
                                    <span style={{color: '#333'}}><Icon type="home"/></span>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 2,
                                    background: '#fff',
                                    padding: 10,
                                    borderBottom: '1px #e9e9e9 solid'
                                }}>
                                    <span style={{
                                        fontSize: 12,
                                        color: '#333'
                                    }}>{StorageTools.getUserInfo().Phone}</span><span><Icon style={{color: '#4b98ff'}}
                                                                                            type="phone"/></span></div>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    background: '#fff',
                                    padding: 10,
                                    borderBottom: '1px #e9e9e9 solid'
                                }}>
                                    <span style={{
                                        fontSize: 12,
                                        color: '#333'
                                    }}>{StorageTools.getUserInfo().Extra.detailAddress}</span><span
                                    style={{color: '#333'}}><Icon
                                    type="environment"/></span>
                                </div>
                            </div>
                            <div style={{marginTop:10}} dangerouslySetInnerHTML={{ __html:this.formatRichText(detailData.ActivityContent)  }} ></div>
                        </div>
                        <div style={{
                            position: 'relative',
                            bottom: -15,
                            width: 251,
                            marginLeft: 7,
                            borderBottomLeftRadius: 21,
                            borderBottomRightRadius: 21,
                            background: '#A835FF',
                            textAlign: 'center',
                            height: '45px',
                            lineHeight: '45px',
                            color: '#fff'
                        }}>
                            立即抢购
                        </div>
                    </div>
    
                    {/* <div  style={{width:"100%",overflow:"auto",height:"100%",backgroundImage:`url(${detailData.BackgroundImage})`,backgroundSize:"100% 100%"}}>
                        <div  dangerouslySetInnerHTML={{ __html: detailData.ActivityContent }} >

                        </div>
                    </div> */}
                </div>
                                
                    
                {
                    this.state.goodsVisible?
                    (
                        <Goods 
                        detailData={detailData}
                        visible={this.state.goodsVisible} 
                        onCancel={()=>this.setState({goodsVisible:false})}
                        onOK={()=>this.setState({goodsVisible:false})}/>
                    ):null
                }   
                   
                    
                </div>

            </IDrawer>

        )
    }
}

export default Detail