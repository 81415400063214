
/** 预备订单 */
export const  SPreOrder =()=>{
    return{
    
        AwardSpced:0,
        EmpName:'', 
        EmpId:0,
        PlaceId:0, //房间ID
        PlaceTitle:'', //房间名
        PlateId:0, //手牌ID
        PlateTitle:'', //手牌号
        /** 编号 */
        Id:0,
        /** 订单号 */
        OrderNo:'',
        /** 店铺ID */
        StoreId:0,
        /** 状态 */
        State:0,
        /** 创建时间 */
        CreateTime:0,
        /** 修改时间 */
        UpdateTime:0,
        /** 项目 */
        Items:[],
        Kind:0,
    }
    
}

/** 预备订单项目 */
export const SPreOrderItem=()=>{
    return{
        /** 编号 */
        Id:0,
        /** 项目ID */
        ItemId:0,
        /** 项目ID */
        ItemName:"",
        /** 订单ID */
        OrderId:0,
        /** 房间ID */
        PlaceId:0,
        /** 房间名 */
        PlaceTitle:'',
        /**手牌ID */
        PlateId:0,
        /** 手牌号 */
        PlateTitle:'',
        /** 创建时间 */
        CreateTime:0,
        /** 修改时间 */
        UpdateTime:0,
        /** 提成 */
        Awards:[],
        /** 上钟状态*/
        State:1,
         /** 1 已支付  2为支付*/
        PayState:0,
         /** 1 商品  2 服务*/
        ItemType:0,

    }
    
}

/** 预备订单提成 */
export const SPreOrderAward=()=>{
    return{
        /** 编号 */
        Id:0,
        /** 项目ID */
        ItemId:0,
        /** 订单ID */
        OrderId:0,
        /** 员工ID */
        EmpId:0,
        /** 员工名 */
        EmpName:'',
        /** 创建时间 */
        CreateTime:0,
        /** 修改时间 */
        UpdateTime:0,
        /** 指定提成人员 */
        AwardSpced:0,
    }
    
}

//订单下钟
export const SBoardModel ={
    /** 员工Id*/
    empId:0,
    /** 状态*/
    state:0,
    /** 项目*/
    itemId:0,
    /** iid*/
    preIid:0
}