import oss from "ali-oss";
 
// LTAI5tBMC5BFeuXCG8AmGLEo
// BP8a9k6sdZB0i03jTUmSsamRW6qUMY


// OSS_ENDPOINT: "oss-cn-beijing.aliyuncs.com",
// OSS_BUCKET: "glxt66.oss-cn-beijing.aliyuncs.com"

  // access_key_id: "jm9k4qqmm7sa6lEE",
  // access_key_secret: "H0NQDhFc8ck3DeIOGzDpixpsCjaVlk",
  // OSS_ENDPOINT: "oss-cn-beijing",
  // OSS_BUCKET: "line365-img-source"
const token = {
  access_key_id: "LTAI5tSpnhXSDB1Qphr769qs",
  access_key_secret: "8PZU5mISfiuzoGpnXwwpaPbHoUAAb9",
  OSS_ENDPOINT: "oss-cn-beijing",
  OSS_BUCKET: "glxt66"
}

const client = self => {
 
    return new oss({
      accessKeyId: token.access_key_id,
      accessKeySecret: token.access_key_secret,
      region: token.OSS_ENDPOINT, 
      bucket: token.OSS_BUCKET 
    });
  };
  //五位随机数加时间
function randomNumber() {
    const DateString = new Date().getTime();
    const numberImg = Math.floor(Math.random() * 89999 + 10000) + "" + DateString;
    return numberImg;
  }
  
  const uploadPath = (path, file) => {
    // 上传文件的路径，使用日期命名文件目录
    const name = randomNumber();
    return `${path}${name}-${file.uid}.${file.type.split("/")[1]}`;
  };
export  const UploadToOss = (self, path, file) => {
    const url = uploadPath(path, file);
    return new Promise((resolve, reject) => {
      client(self)
        .multipartUpload(url, file)
        .then(data => {
          console.log("UploadToOss",data)
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };