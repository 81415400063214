
import React from 'react'
import {Tabs,Button,Progress} from 'antd'
import ShopInfo from '../../chainRightNavContent/setting/shopInfo'
import PostSetting from '../../chainRightNavContent/setting/postSetting'
import StaffSetting from '../../chainRightNavContent/setting/staffSetting'
import ServiceItemsSetting from '../../chainRightNavContent/setting/serviceItemsSetting'
import VipSetting from '../../chainRightNavContent/setting/vipSetting'
import SecondCardSetting from '../../chainRightNavContent/setting/secondCardSetting'
import {connect} from "react-redux";
import {setDefaultTitle} from "../../../../js/reduxs/reducers/iconRedux";
import {FnMenuId} from "../../../../js/comm/constans"
import StorageTools from '../../../../js/comm/storageTools';
import {messageInfo,hideLoadding,showMessageError} from '../../../../js/comm/utils';
import {IModal} from '../../../../js/components/iModal'
import '../../../../css/settting.css'
import {api} from './service/ChainSettingService'

const TabPane = Tabs.TabPane;
@connect(
    state => state.iconRedux,
    {setDefaultTitle}
)
class ChainSetting extends React.Component {
    constructor() {
        super();
        this.state = {
            key: '1',
            visible:false,
            serviesList:['会员类型','服务项目','套餐卡类型'],
            visibleStatusWait:false,
            visibleStatusOk:false,
            errVisible:false,
            updataData : {
                index:1,
                batchCode:"",
                vipNum:0,
                serviceNum:0,
                cardNum:0,
            },
            errData:[],
            uploadLoading:false
        }
        
    }

    componentWillMount() {
        this.uploadData();
        
        this.props.setDefaultTitle({
            titleKey: FnMenuId.head_chain_setting,
            slidingLeftKey: "1"
          });
    }

    uploadData(){
        const {updataData} = this.state;
        // const state = this.props.location.state;
        var config = StorageTools.getConfig();
        if(config.uploadState == 1){ // 1 第一次进入连锁后台，需要上传服务、会员、商品
            config.uploadState= 2;
            StorageTools.saveConfig(config);
            this.setState({
                visible:true
            })
        }
    }
    requestUploadData(updataData,mchCode,that,httpFail){
        api.requestUploadData(updataData,mchCode,this,httpFail,(res)=>{
            if(res.state == 1){
                this.setState({updataData:updataData})
            }else{
                if(res.data != null && res.data.length>0){
                    this.setState({errVisible:true,visible:false,errData:res.data})
                }else{
                    updataData.index ++;
                    let errVisible = false;
                    if(updataData.index >3){
                        this.setState({visibleStatusOk:true,visible:false})
                    }else{
                        if(res.data.length>0){
                            errVisible = true;
                        }else{
                            that.requestUploadData(updataData,mchCode,that,httpFail);
                        }
                        this.setState({errData:res.data,errVisible:errVisible})
                    }
                }

                // this.setState({errData:res.data,errVisible:errVisible})
            }
        });
    }
    //继续上传
    onContinueUpdata(httpFail){
        const {updataData,errData} = this.state;
        let mchCode = StorageTools.getMchCode()
        let arr = [];
        errData.forEach(item=>{
             //如果排除重复错误
            if(item.State != 2){
                arr.push(item.ItemId);
            }
        })
        //如果没有错误
        if(arr.length ==0){
            updataData.index ++;
            this.requestUploadData(updataData,mchCode,this,httpFail);
        }
        api.requestContinueUpload(updataData,arr,mchCode,this,httpFail,(res)=>{
            if(res.state == 1){
                this.setState({updataData:updataData})
            }else{
                if(res.data != null && res.data.length>0){
                    this.setState({errVisible:true,visible:false,errData:res.data})
                }else{
                    this.setState({
                        visible:true
                    })
                    if(updataData.index == 3){
                        this.setState({visibleStatusOk:true,visible:false})
                    }else{
                        let errVisible = false;
                        if(res.data.length>0){
                            errVisible = true;
                        }else{
                            updataData.index ++;
                            this.requestUploadData(updataData,mchCode,this,httpFail);
                        }
                        this.setState({errData:res.data,errVisible:errVisible})
                    }
                }
            }
        })
        this.setState({visible:true,errData:[],errVisible:false})
       
    }
    

 httpFail(res,tag){
    //  debugger
    hideLoadding();
    showMessageError(res);
    this.setState({uploadLoading:false})
    
}


    changeTab(e) {
        if (!StorageTools.getMchName()) {
            messageInfo('请完善企业信息')
        } else {
            this.setState({key: e})
        }
       

    }


    getView() {
        switch (this.state.key) {
            case "1":
                return <div className='vip-main-view-content' style={{width: '100%',height:"100%"}}>
                    <ShopInfo style={{width: '100%',height:"100%"}}/>
                </div>;
            case "2":
                return <div className='vip-main-view-content comm-w'>
                    <PostSetting style={{width: '100%'}}/>
                </div>;
            case "3":
                return <div className='vip-main-view-content' style={{width: '100%'}}>
                    <StaffSetting/>
                </div>;
            case "4":
                return <div className='vip-main-view-content' style={{width: '100%'}}>
                    <VipSetting/>
                </div>;
            case "5":
                return <div className='vip-main-view-content' style={{width: '100%'}}>
                    <ServiceItemsSetting style={{width: '100%'}}/>
                </div>;
            case "6":
                return <div className='vip-main-view-content' style={{width: '100%'}}>
                    <SecondCardSetting/>
                </div>
        }
    }
    onChangeListener(){}
    render() {
        
        const {updataData,key,visibleStatusWait,visibleStatusOk,errVisible,errData,uploadLoading} = this.state;
        //console.log(1111,this.state)
        return (
            <div className='gra-radius-border shopSetting vip-main-view' style={{height: '100%', width: '100%'}}>
                 <Tabs type="card" tabBarGutter={0} onChange={this.changeTab.bind(this)} activeKey={key}>
                    <TabPane tab='1.企业信息' key='1'>
                    </TabPane>
                    <TabPane tab='2.组织架构' key='2'></TabPane>
                    <TabPane tab='3.人员管理' key='3'></TabPane>
                    <TabPane tab='4.卡类设置' key='4'></TabPane>
                    <TabPane tab='5.服务设置' key='5'></TabPane>
                    <TabPane tab='6.套餐卡设置' key='6'></TabPane>
                </Tabs> 
                {this.getView()}

                <div style={{height:0}}>
                <IModal
                    colse={true}
                    onOk = {()=>{
                        let mchCode = StorageTools.getMchCode()
                        this.requestUploadData(updataData,mchCode,this,this.httpFail.bind(this));
                        this.setState({uploadLoading:true})    
                    }
                    }
                    okText='导入'
                    cancelText='不导入'
                    width='330px'
                    visible={this.state.visible}
                    // footer={null}
                    onCancel={()=>{this.setState({visible:false})}}
                    okButtonProps={{loading:uploadLoading}}>
                        <div>
                            <p style={{ marginTop: 10, color: '#333',fontSize:16,fontWeight:"bold"}}>将店铺的数据导入连锁 </p>
                            <p>包括     </p>
                            <p>会员类型</p>
                            <Progress  percent={updataData.vipNum} status="active" />
                            <p>服务项目</p>
                            <Progress  percent={updataData.serviceNum}  status="active" />
                            <p>套餐卡类型</p>
                            <Progress  percent={updataData.cardNum}  status="active" />
                           
                        </div>

                </IModal>




                <IModal
                     colse={true}
                    onOk = {()=>{this.setState({visibleStatusOk:true})}}
                    okText='导入'
                    cancelText='不导入'
                    width='300px'
                    visible={visibleStatusOk}
                    footer={null}
                    onCancel={()=>{this.setState({visibleStatusOk:false})}}>
                         <div style={{textAlign:'center'}}>
                                <div>店铺数据导入连锁完成</div>
                                <Button style={{margin:'30px 0',backgroundColor:'#bf75ff'}}
                                    onClick={()=>{this.setState({visibleStatusOk:false})}}
                                >
                                    <span style={{color:'white',}}>我知道了</span>
                                </Button>
                             </div>
                           

                </IModal>

                <IModal
                     colse={true}
                    onOk = {this.onContinueUpdata.bind(this,this.httpFail.bind(this))}
                    okText='重试'
                    cancelText='跳过'
                    width='500px'
                    visible={errVisible}
                    onCancel={()=>{
                        updataData.index++
                        if(updataData.index >3){
                            this.setState({errVisible:false,visibleStatusOk:true,uploadLoading:false})
                        }else{
                            this.setState({errVisible:false,visible:true,uploadLoading:false})
                        }
                        
                        }}>

                        <div  style={{maxHeight:"50vh",overflow:"auto"}}>
                            <div style={{fontWeight:"bold"}}>店铺数据上传失败</div>
                            {errData.map((item)=>(
                                <div className="comm-flex-row-between">
                                    <span>{item.ItemName}</span>
                                    <span>{item.ErrMsg}</span>
                                </div>
                            ))}
                            
                        </div>
                           
                </IModal>


                </div>
                
            </div>
        )
    }
}
export default ChainSetting;
