import {isLog, money_max,ApiConfig} from "./constans";
import {message, Modal, notification} from 'antd'
import ReactDOM from "react-dom";
import React from 'react';
import LoadingDialog, { LoadingDialogAll, LoadingDialogCircle } from "../components/loadingDialog";
import StorageTools from "./storageTools";
import { createHash } from "crypto";
import _ from "lodash";
const { confirm } = Modal;
notification.config({
    placement: 'topLeft',
    topLeft: 550,
    top: 250,
    duration: 2,
});

export const phoneRed="^[1](([3|5|8][\\d])|([4][4,5,6,7,8,9])|([6][2,5,6,7])|([7][^9])|([9][1,2,3,4,5,6,7,8,9]))[\\d]{8}$";

// export function log(message, optionalParams) {
//     if (isLog) {
//         console.log(message, optionalParams)
//     }
// }

export function log(...vars) {
    if (false) {
        console.log(...vars)
    }
}


/**
 * 显示错误的提示
 * @param data
 */
//todo: 格式应为(code,msg)，在外部传递的参数需调整
export function showMessageError(data, msg) {
    message.config({
        top: 300,
        duration: 1.5,
        maxCount: 3,
    });
    if (data == null) {
        message.error("数据请求错误")
    }else if (typeof (data) == 'string') {
        if (data == "店铺不存在") { return }
        message.error(data)
    } else if (data != null && data.ErrMsg != null && data.ErrMsg != '') {
        if (data.ErrMsg == "店铺不存在") { return }
        message.error(data.ErrMsg)
        // notification.error(data.data.ErrMsg)
    } else if (typeof (data) == "number" && typeof (msg) == "string") {
        message.error(msg || "数据请求错误,请重试");
    }else if(data.ErrMsg != null){
        message.error(data.ErrMsg);
    } else {
        message.error("数据请求错误,请重试");
    }
}

/**
 * 错误提示
 * @param content
 */
export function error(content) {
    Modal.error({
        title: '提示',
        content: content,
        okType: "default"
    });
}


/**
 * Confirm提示信息
 * @param content
 */
export function modalConfirm(title, content, onOk, onCancel) {
    confirm({
        centered: true,
        title: title,
        content: content,
        okType: "default",
        onOk() {
            if (onOk != null) {
                onOk();
            }
        },
        onCancel() {
            if (onCancel != null) {
                onCancel();
            }
        },
    });
}




/**
 * 成功提示
 * @param content
 */
export function messageSuccess(content) {
    message.config({
        top: 300,
        duration: 1,
        maxCount: 3,
    });
    message.success("　　" + content + "　　")
}


/**
 * 失败提示
 * @param content
 */
export function messageError(content) {
    message.config({
        top: 300,
        duration: 1,
        maxCount: 3,
    });
    message.error("　　" + content + "　　")
}

/** 消息窗口 */
export function messageInfo(content) {
    message.config({
        top: 300,
        duration: 1,
        maxCount: 3,
    });
    message.info(content);
}

/**
 * notificat提示
 * @param content
 */
export function openNotificationWithIcon(state, content) {
    var type = "";
    switch (state) {
        case 1:
            type = "success";
            break;
        case 2:
            type = "info";
            break;
        case 3:
            type = "warning";
            break;
        case 4:
            type = "error";
            break;
    }

    notification[type]({
        message: '提示',
        description: content,
    });

    // message.success(content)
}

export function sortArr(arr) {
    for (var j = 0; j < arr.length - 1; j++) {
        //两两比较，如果前一个比后一个大，则交换位置。
        for (var i = 0; i < arr.length - 1 - j; i++) {
            if (arr[i] > arr[i + 1]) {
                var temp = arr[i];
                arr[i] = arr[i + 1];
                arr[i + 1] = temp;
            }
        }
    }
    return arr
}


/**
 * 页面跳转
 * @param data
 */
export function toPage(history, url) {
    if (StorageTools.getUserCode() == null) { //没有登录就去登录
        history.push('/login')
    } else {
        history.push(url)
    }
}


/**
 * 数字转换 除以100
 */
export function numberConvert(num) {
    if (num == "-") {
        return num;
    }
    if (num == undefined || num == null ||num == ""||  num == 0) {
        return 0;
    }
    return calcDivide(num, 100);
}


/**
 * 数字转换 乘以100
 */
export function numberConvert_100(num) {
    if (num == undefined || num == null || num == "" || num == 0) {
        return 0;
    }
    return calcMultiply(num, 100);
}

/**
 * 百分比数字转换
 */
export function numberRatio(num, num1) {
    if (num == undefined || num == null || num == "" || num == 0) {
        return 0;
    }
    return calcMultiply(calcDivide(num, 100), 100);
}

/**
 * 百分比数字转换
 */
export function numberRatioX(num, num1) {
    if (num == undefined || num == null || num == "" || num == 0) {
        return 0;
    }
    return calcDivide(calcMultiply(num, num1), 100)
}


/**
 * 百分比数字转换
 */
export function numberX100_100(num) {
    if (num == undefined || num == null || num == "" || num == 0) {
        return 0;
    }
    return calcDivide(calcMultiply(num, 100), 100)
}

/**
 * 流水类型转换
 */
export function walletTypeConvert(num) {
    if (num == 1) {
        return '用户充值'
    } else if (num == 2) {
        return '客服充值'
    } else if (num == 3) {
        return '系统赠送'
    } else if (num == 4) {
        return '失效'
    } else if (num == 5) {
        return '客服调整'
    } else if (num == 6) {
        return '扣除'
    } else if (num == 7) {
        return '转入'
    } else if (num == 8) {
        return '转出'
    } else if (num == 9) {
        return '冻结'
    } else if (num == 10) {
        return '解冻'
    } else if (num == 11) {
        return '转账退款'
    } else if (num == 12) {
        return '提现退还'
    } else if (num == 13) {
        return '支付单退款'
    } else if (num == 14) {
        return '人工提现'
    } else {
        return '提现到第三方'
    }
}


/**
 * 优惠类型转换
 */
export function discountTypeConvert(num) {
    if (num == 1) {
        return '不打折';
    } else if (num == 2) {
        return '开卡享折扣';
    } else if (num == 3) {
        return '开卡送礼金'
    }
}

export function countConvert(num) {
    return num + '次'
}

export function dataLineConvert(num) {
    if (num == 0) {
        return '永久有效'
    } else {
        return '自办卡之日起' + num + '天有效'
    }

}


export function getSex(sex) {
    if (sex == 1) {
        return "男"
    } else if (sex == 2) {
        return "女"
    } else {
        return "女"
    }
}


export function getSexState(sex) {
    if (sex == "男") {
        return 1;
    } else if (sex == "女") {
        return 2;
    } else {
        return 0
    }
}

export function showLoadding() {
    let loading = document.getElementById('mzl-loading');
    if (loading != null) {
        ReactDOM.render(<LoadingDialog />, loading);
    }
}



export function showLoaddingAll(text) {
    let loading = document.getElementById('mzl-loading');
    if (loading != null) {
        ReactDOM.render(<LoadingDialogAll text={text} />, loading);
    }
}





export function showLoaddingCircle(percent) {
    var load = document.getElementById('mzl-loading');
    if (load != null) {
        ReactDOM.render(<LoadingDialogCircle percent={percent} />, load);
    }
}


export function hideLoadding() {
    var load = document.getElementById('mzl-loading');
    if (load != null) {
        ReactDOM.unmountComponentAtNode(load);
    }
}

export function checkMoney(money) {
    if (money == null || money == "" || money < 0) {
        return "";
    } else if (money > 1000000) {
        return 999999
    }
    return money
}

/**
 * 限制最大金额
 * @param money
 * @param maxMoney
 * @returns {*}
 */
export function checkMoneyMax(money, maxMoney) {
    if (money == null || money == "" || money < 0) {
        return "";
    } else if (parseInt(money) > maxMoney) {
        return maxMoney
    } else {
        return money
    }

}

/**
 * 百分比高度
 * @param num
 * @returns {number}
 */
export function heightPercent(num) {
    var height = document.body.clientHeight;
    return calcMultiply(height, num)
}

/**
 * 百分比宽度
 * @param num
 * @returns {number}
 */
export function widthPercent(num) {
    var width = document.body.clientWidth;
    return calcMultiply(width, num)


}


/**
 * 百分比高度
 * @param num
 * @returns {number}
 */
export function heightViewPercent(id, num) {
    var view = document.getElementById(id);

    if (view != null) {
        var height = view.clientHeight;
        return height * num
    }

    return 0
}


/**
 *  作废 电话限制
 * @param num
 * @returns {number}
 */
export function phoneConvert(phone) {

    // phone = phone.replace(/^0*(0\.|[1-9])/, '$1');
    // phone = phone.replace(/\D/g, '');
    // phone = phone.replace(/^([0-9])/, '$1');
    // // money = money.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
    // phone = phone.replace(/[^\d]/g, '');
    return phone
}
/**
 * 手机号校验
 * @param num
 * @returns {number}
 */
export function phoneCheck(phone) {

    var regular = new RegExp(phoneRed);
    if (!regular.test(phone)) {
        return false;
    } else {
        return true;
    }
}




/**
 * 验证座机和手机
 * @param num
 * @returns {number}
 */
export function telephoneConvert(phone) {
    // phone = phone.replace(/^0\d{2,3}-?\d{7,8}$/, '$1');
    // phone = phone.replace(/\D/g, '');
    return phone
}


/**
 * 数字限制
 * @param num
 * @returns {number}
 */
export function numConvert(num) {
    num = num.replace(/^0*(0\.|[1-9])/, '$1');
    num = num.replace(/\D/g, '');
    if (num == "") {
        num = 0;
    }
    return num
}


/**
 * 检验身份证
 * @param num
 * @returns {number}
 */
export function checkIdCard(text) {
    if (text == null || text == "") {
        return true;
    }
    var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (reg.test(text) === false) {
        return false;
    }
    return true;
}







/**
 * 数字限制
 * @param num
 * @returns {number}
 */
export function numConvert2(num) {
    num = num.replace(/^0*(0\.|[1-9])/, '$1');
    num = num.replace(/\D/g, '');
    return num
}


/**
 * 金额限制
 * @param num
 * @returns {number}
 */
export function moneyConvert(money) {
    if (money != '' && money.substr(0, 1) == '.') {
        money = "";
    }
    money = money.replace(/^0*(0\.|[1-9])/, '$1');//解决 粘贴不生效
    money = money.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
    money = money.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
    money = money.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    money = money.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
    if (money.indexOf(".") < 0 && money != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        if (money.substr(0, 1) == '0' && money.length == 2) {
            money = money.substr(1, money.length);
        }
    }
    if (money > money_max) {
        money = money_max
    }
    return money
}

/**
 * 删除最后一个字符
 * @param num
 * @returns {number}
 */
export function deleteText(text) {
    if (text != null && text.length > 1) {
        text = text.substring(0, text.length - 1)

    }
    return text
}


/**
 * copy对象
 * @param num
 * @returns {number}
 */
export function copyProject(json) {
    if (json != null) {
        return JSON.parse(JSON.stringify(json))
    }

}

/**
 * copy对象
 * @param num
 * @returns {number}
 */
export function cloneDeep(json) {
    if (json != null) {
        return  _.cloneDeep(json);
    }

}


/**
 * copy对象
 * @param num
 * @returns {number}
 */
export function parseIntX100(num) {
    if (num == null || num == undefined || num == "") {
        num = 0
    }

    //322.9 *100 有浮点精度问题   改为乘以10000
    return calcMultiply(num, 100);
}


/**
 * 导出文件
 */
export function exportCsv(data, head, name) {

    // var head = [['排名','收入类型','销售数量','收入总额','收入占比']];

    // for(var i = 0 ; i < data.length; i++) {
    //     head.push([data[i].key, data[i].IncomeName, data[i].SalesVolume, data[i].TotalIncome, data[i].RevenueShare]);
    // }
    var csvRows = [];
    for (var i = 0; i < head.length; i++) {
        csvRows.push(head[i].join(','))
    }
    var csvString = csvRows.join('\n');

    //BOM的方式解决EXCEL乱码问题
    var BOM = '\uFEFF';
    csvString = BOM + csvString;

    var a = document.createElement('a');
    a.href = 'data:attachment/csv,' + encodeURI(csvString);
    a.target = '_blank';
    a.download = `${name}.csv`;
    document.body.appendChild(a); // Firefox 中必须这么写，不然不会起效果
    a.click();
    document.body.removeChild(a);
}


/**
 * 导出文件
 */
export function exportCsvFile( head, name) {

    // var head = [['排名','收入类型','销售数量','收入总额','收入占比']];

    // for(var i = 0 ; i < data.length; i++) {
    //     head.push([data[i].key, data[i].IncomeName, data[i].SalesVolume, data[i].TotalIncome, data[i].RevenueShare]);
    // }
    var csvRows = [];
    for (var i = 0; i < head.length; i++) {
        csvRows.push(head[i].join(','))
    }
    var csvString = csvRows.join('\n');

    //BOM的方式解决EXCEL乱码问题
    var BOM = '\uFEFF';
    csvString = BOM + csvString;

    var a = document.createElement('a');
    a.href = 'data:attachment/csv,' + encodeURI(csvString);
    a.target = '_blank';
    a.download = `${name}.csv`;
    document.body.appendChild(a); // Firefox 中必须这么写，不然不会起效果
    a.click();
    document.body.removeChild(a);
}

/**
 * 会员导入转换成json
 */
export function improtFile(file, XLSX, callback) {

    const { files } = file.target;
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = event => {
        try {
            const { result } = event.target;
            // 以二进制流方式读取得到整份excel表格对象
            const workbook = XLSX.read(result, { type: 'binary' });
            // 存储获取到的数据
            var data = [];
            // 遍历每张工作表进行读取（这里默认只读取第一张表）
            for (const sheet in workbook.Sheets) {
                // esline-disable-next-line
                if (workbook.Sheets.hasOwnProperty(sheet)) {
                    // 利用 sheet_to_json 方法将 excel 转成 json 数据
                    data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    // break; // 如果只取第一张表，就取消注释这行
                }
            }
            // 最终获取到并且格式化后的 json 数据
            // message.success('上传成功！');
            // data.forEach(item=>{
            //     item.生日 = this.formatDate(item.生日,"yyyy-MM-dd")
            // })
            callback(true, data);
        } catch (e) {
            // 这里可以抛出文件类型错误不正确的相关提示
            callback(false);
            // message.error('文件类型不正确！');
        }
    };
    // 以二进制方式打开文件
    fileReader.readAsBinaryString(files[0]);
}


/**
 * 导入模板
 */
export function exportMod() {

    var head = [['会员生日', '会员手机', '生日', '会员类型', '性别', '折扣', '余额']];

    for (var i = 0; i < 100; i++) {
        head.push(['小明' + i, '13812311111', '1990/01/01', '八折金卡', '男', '8', '1000']);
    }
    var csvRows = [];
    for (var i = 0; i < head.length; i++) {
        csvRows.push(head[i].join(','))
    }
    var csvString = csvRows.join('\n');
    // var csvString = '小明,13812311111,1990/01/01,八折金卡,男,8,1000';

    //BOM的方式解决EXCEL乱码问题
    var BOM = '\uFEFF';
    csvString = BOM + csvString;

    var a = document.createElement('a');
    a.href = 'data:attachment/csv,' + encodeURI(csvString);
    a.target = '_blank';
    a.download = '会员导入模板.csv';
    document.body.appendChild(a); // Firefox 中必须这么写，不然不会起效果
    a.click();
    document.body.removeChild(a);
}


/**
 * 截取字符串
 */
export function getSubstringText(text, num) {
    if (text == null || text == "" || text.length < num) {
        return text
    }
    return text.substring(0, num);

}


function decNum(a) {/*获取小数位数*/
    if (a == null || a == undefined || a == "") {
        a = 0
    }
    var r = 0;
    a = a.toString();
    if (a.indexOf(".") !== -1) r = a.split(".")[1].length;
    return r;
}

function int(a) {/*去除小数点并转成数值*/
    if (a == null || a == undefined || a == "") {
        a = 0
    }
    return parseInt(a.toString().replace(".", ""));
}

function calc(a, b, type) {//加减乘除
    if (a == null || a == '' || isNaN(a)) {
        a = 0
    }
    if (b == null || b == '' || isNaN(b)) {
        b = 0
    }
    var r,
        da = decNum(a),
        db = decNum(b),
        dsum = da + db,
        dmin = Math.min(da, db),
        dmax = Math.max(da, db);
    dsum += dmax - dmin;
    dsum = Math.pow(10, dsum);
    dmax = Math.pow(10, dmax);
    a = int(a);
    b = int(b);
    if (da > db) {
        b *= Math.pow(10, da - db);
    } else {
        a *= Math.pow(10, db - da);
    }
    switch (type) {
        case "add":
            r = (a + b) / dmax;
            break;
        case "subtract":
            r = (a - b) / dmax;
            break;
        case "multiply":
            r = (a * b) / dsum;
            break;
        case "divide":
            r = a / b;
            break;
    }
    r = r.toString().replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//保留两个小数
    return parseFloat(r);
}

//加法
export function calcAdd(a, b) {
    return calc(a, b, "add");
}

//减法
export function calcSubtract(a, b) {

    return calc(a, b, "subtract");
}

//乘法
export function calcMultiply(a, b) {
    return calc(a, b, "multiply");
}

//除法
export function calcDivide(a, b) {
    if (b == null || b == "" || b == 0) {
        return 0;
    }
    return calc(a, b, "divide");
}


/**
 * 手机号转换
 */
export function hidePhoneConvert(phone) {
    if (phone != null && phone.length > 10) {
        return phone.substr(0, 3) + "****" + phone.substr(7);
    }
    return "";
}

/**
 *  md5 转换
 */
export function md5ToLower(s) {
    return createHash("md5").update(s).digest('hex').toLowerCase();
}


export function sha1Code(s) {
    return createHash("sha1").update(s).digest('hex').toLowerCase();
}

//将数字转换成金额显示
export function toMoneyString(num) {
    if (num == null || isNaN(num) || num == "") {
        return 0;
    }
    num = num.toLocaleString();
    return num;//返回的是字符串23,245.12保留2位小数
}


/**
 * icon 获取阿里Icon地址
 * @param Icon
 * @returns {*}
 */
export function getIcon(Icon) {
    return Icon.createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_564375_whl4hg6juyh.js',
        // scriptUrl: '../../css/iconfont/iconfont.js',
    });
}

export function remove(arr, val) {
    var index = arr.indexOf(val);
    if (index > -1) {
        arr.splice(index, 1)
    }
}

var requestState = true;

/**
 * 防暴力点击 获取请求时状态
 */
export function getReqeustState() {
    return requestState;
}

/**
 * 防暴力点击 设置请求时状态
 */
export function setReqeustState(bool, timeOut) { //默认5秒后自动设置可用状态
    requestState = bool;
    if (!bool) {
        setTimeout(function () { // 默认5秒后自动设置可用状态
            requestState = true
        }, timeOut ? timeOut : 5000);
    }
}


/**
 * 获取表格在当前高度下所展示的总条数
 * top 表格离页面title的高度
 */
export function getTabPageSize(top, timeOut) {
}



export function doPrint() {
    let userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Windows") != -1) {
        if (window.LODOP == null) {
            const that = this;
            Modal.info({
                title: '使用打印小票功能前请先确保您的小票打印机能正常工作，然后安装66掌柜打印环境,安装完毕后请重启66掌柜',
                content: (
                    <div>
                        <a href='https://line365-img-source.oss-cn-beijing.aliyuncs.com/mzl-file/CLodop_Setup_for_Win32NT.exe'>安装打印环境</a>
                        {/*<a style={{marginLeft:20}} onClick={()=>{*/}
                        {/*if(that.props.that.props.history.location.pathname == '/home/silver'){*/}
                        {/*that.props.that.props.history.push("/home/silverContent")*/}
                        {/*}else{*/}
                        {/*that.props.that.props.history.push(that.props.that.props.history.location.pathname)*/}
                        {/*}*/}

                        {/*}}>刷新页面</a>*/}
                    </div>
                ),
                onOk() { },
            });

        } else {
            let view = document.getElementById('carprint');
            if (view != null) {
                // console.log( window.LODOP, window)
                window.LODOP.SET_PRINT_STYLE("FontSize", 12);
                window.LODOP.SET_PRINT_PAGESIZE(3, 480, 1.0, "");
                window.LODOP.ADD_PRINT_HTM(0, 0, "RightMargin:0", "BottomMargin:15mm", view.innerHTML);
                window.LODOP.SET_PREVIEW_WINDOW(0, 0, 0, 0, 0, "");


                var name = StorageTools.getStorePrintName();
                if (name == null || name == "" || name == "自动") {
                    window.LODOP.PRINT();
                } else if (name == "使用默认打印机") {
                    window.LODOP.SET_PRINTER_INDEX(-1);
                    window.LODOP.PRINT();
                } else {
                    window.LODOP.SET_PRINTER_INDEXA(name);
                    window.LODOP.PRINT();
                }







                // window.LODOP.ADD_PRINT_HTML(0,0,"100%","100%",view.innerHTML);
            }
        }
    } else {
        var el = document.getElementById("carprint");
        var iframe = document.createElement('IFRAME');
        var doc = null;
        iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;padding:0px;margin-left:-120px;');
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        doc.write('<div>' + el.innerHTML + '</div>');
        doc.close();
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
    }

}


export function printHtml(id){ 
    var el = document.getElementById(id);
    var iframe = document.createElement('IFRAME');
    var doc = null;
    iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;padding:0px;margin-left:-120px;');
    document.body.appendChild(iframe);
    doc = iframe.contentWindow.document;
    doc.write('<div>' + el.innerHTML + '</div>');
    doc.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    if (navigator.userAgent.indexOf("MSIE") > 0) {
        document.body.removeChild(iframe);
    }
}

//排序
export function bubbleSort(arr) {
    arr.sort(function (a, b) {
        return b.totalAmont - a.totalAmont
    })

    return arr;
}
export function authControl(num) {
    return true; //去掉验证购买
    // var arr =JSON.parse(StorageTools.getBuyModule().auths)
    // var time=StorageTools.getBuyModule().systemTime
    // var time=1577683245
    let userProd =StorageTools.getUserProd();
    let serial = userProd.serial;
    if(serial){
        //版本到期时间
        if(serial.expires_time !== 0 && userProd.currentTime > serial.expires_time){
            return false;
        }
    }

    let modules = userProd.modules;
    let flag = false;
    if(modules && modules !== ''){
        flag = modules.some(e=>{
            return e.module_id === num;
        })
    }
    

    return flag
}

function setTimeDateFmt(s) {  // 个位数补齐十位数
    return s < 10 ? '0' + s : s;
  }
  export function randomNumber() {
    const now = new Date()
    let month = now.getMonth() + 1
    let day = now.getDate()
    let hour = now.getHours()
    let minutes = now.getMinutes()
    let seconds = now.getSeconds()
    month = setTimeDateFmt(month)
    hour = setTimeDateFmt(hour)
    minutes = setTimeDateFmt(minutes)
    seconds = setTimeDateFmt(seconds)
    var year = now.getYear()
    var year = year < 2000 ? year + 1900 : year
    var yy = year.toString().substr(2, 2); 
    return 'MS' + yy + month.toString() + day + hour + minutes + seconds 
  }



// export function hidePhoneConvert(phone){
//     if (phone != null && phone.length > 10) {
//         return phone.substr(0, 3) + "****" + phone.substr(7);
//     }
//     return "";
// }

// module.exports = {
//     log:log,
//     showMessageError:showMessageError,
//     error:error,
//     messageSuccess:messageSuccess,
//     toPage:toPage,
//     numberConvert:numberConvert,
//     numberConvert_100:numberConvert_100,
//     showLoadding:showLoadding,
//     hideLoadding:hideLoadding,
// }

//Json 去重
export function filterByName(data, Name) {   //data是json对象，Name是根据什么字段去重
    var map = {},
      dest = [];
    for (var i = 0; i < data.length; i++) {
        var ai = data[i];
        if (i == 0) {
            dest.push(ai);
        } else {
            var filterData = dest.filter(function (e) {
                return e[Name] == ai[Name];
            })
            if (filterData.length == 0) {
                dest.push(ai);
            }
        }
    }
    return dest;
}


//把秒转化成小时 
export function getTimeH(mun) { 
    if(mun !== null && mun !==""  && mun >0){
        return calcDivide(mun,3600) ;
    }
    return 0;
}

//把小时转化成秒
export function getTimeS(mun) { 
    if(mun !== null && mun !=="" && mun >0){
        return calcMultiply(mun,3600);
    }
    return 0;
}
/**
 * 获取Json中某个属性最大值
 * @param {*} data 
 * @param {*} key 
 */
export function maxArrValue(data,key){
    var list=[]
    data.forEach(item=>{
        list.push(item[key])
    })
    list.sort((num1,num2)=>{
        return num1-num2
    })
    return list[list.length-1]
}

export function getTimeStampText(StatusMinute){	
	var day=parseInt(StatusMinute/60/24);
    var hour=parseInt(StatusMinute/60%24);
	var min= parseInt(StatusMinute % 60);
	StatusMinute="";
	if (day > 0)
	{
	    StatusMinute= day + "天";
	} 
	if (hour>0)
	{
	    StatusMinute += hour + "小时";
	} 
	if (min>0)
	{
	    StatusMinute += parseFloat(min) + "分钟";
	}
	    return StatusMinute;
}

  /**
    * 防抖函数
    func执行的函数func，awaitTime多少秒后number，immediate是否立即执行Boolean
    */
   export function  debounce(func, awaitTime, immediate){
    let timeout;
    return function () {
        const context = this;
        let argus =arguments;
        if(timeout) clearTimeout(timeout)
        if (immediate) {
            //立即执行
            setTimeout(() => {
                timeout = null;
            }, awaitTime)
            if (!timeout) func.apply(context, argus)

        } else {
            timeout = setTimeout(() => {
                //改变执行函数的this指向，和event对象
                func.apply(context, argus)
            }, awaitTime)
        }
    }
}

//参数拼接
export function formateObjToParamStr(paramObj) {
    const sdata = [];
    for (let attr in paramObj) {
      sdata.push(`${attr}=${(paramObj[attr])}`);
    }
    return sdata.join('&');
  };

//播放语音
export function playVoice(text){
   var url2 ="https://tsn.baidu.com/text2audio?lan=zh&ctp=1&cuid=baike&tok=24.0abb408636242d44bc9478dc4316d222.2592000.1625198894.282335-24290955"+ encodeURI(text)
    var url = "https://tts.baidu.com/text2audio?cuid=baike&lan=ZH&ctp=1&pdt=301&vol=5&rate=32&per=4&tex=" + encodeURI(text);        // baidu文字转语音
    var voiceContent = new Audio(url2);
    voiceContent.src = url2;
    voiceContent.play();
};