import IDrawer from '@components/iDrawerPage'
import { IInputMoney } from '@components/iInput'
import ITableList from '@components/iTableList'
import { update } from "@js/goods/action"
import { Steps, Tooltip } from 'antd'
import React from 'react'
import { connect } from 'react-redux'


let begin = 0;
let over = 20;
const { Step } = Steps;
const reduxStaste = (state) => {
    return {
        ...state.goodsData,
    }
}
@connect(
    state => reduxStaste(state),
    { update }
)
class BunusItem extends React.Component {
    constructor() {
        super();
        this.state = {
            columns : [
                {
                    title: '序号',
                    dataIndex: 'ApplicationNo',
                    align: 'center',
                    render: (text,data,index) => <div className="comm-text-over">{index+1}</div>
                  },
                  {
                    title: '项目名称',
                    align: 'center',
                    dataIndex: 'ItemName',
                    render: text => <Tooltip placement="top"title={text}>
                      <div className="comm-text-over">{text}</div>
                    </Tooltip>
                  },
                  
                  {
                    title: '售价',
                    align: 'center',
                    dataIndex: 'ItemPrice',
                    render: text => <Tooltip placement="top"title={text}>
                      <div className="comm-text-over">{text}</div>
                    </Tooltip>
                  },
                  {
                    title: '分红比例',
                    align: 'center',
                    render: (text,data) => (
                      <div className="comm-flex-row-center">
                        <IInputMoney
                        maxLength={8}
                        value={data.ItemRate}
                        placeholder="请输入分红比例"
                        style={{ width: 100, }}
                        onChange={this.onChange.bind(this, "ItemRate",data)}
                      ></IInputMoney>
                         　%
                      </div>
                      
                    )
                  },
                 
              ],
              tableData : []

        }



    }

   

    componentDidMount() {
        this.setState({
          tableData:this.props.itemRateList
        })
    }
 
   
 
    onChange(tag,data,e){
      data[tag]=e;
      this.setState(this)

    }

    selectChange(tag,e) {
       
    }

    onShowSizeChange() {

    }

    onClickListener(tag){
        if(tag === "drawer-ok"){
            this.props.update({
                goodsOutCountVisible:false
            })
        }else if(tag === "drawer-cancel"){
            this.props.update({
                goodsOutCountVisible:false
            })
        }
    }

 
      
    onTablePageCallback(start, end){
      
    }
  
    render() {


      // console.log("123123-itemRateList",this.props.itemRateList)
        return (
            <IDrawer
                title={"详情"} 
                footer={true} 
                cancelHide={true} 
                onCancel={this.props.onCancel}
                onOK={this.props.onOK}
                width={"50%"} 
                visible={this.props.visible}
            >
                <div className="comm-w-h">
                    <ITableList 
                        top={190}
                        columns={this.state.columns} 
                        data={this.state.tableData}
                        // pagination={true}
                        onTablePageCallback={this.onTablePageCallback.bind(this)}>

                    </ITableList>
                </div>
                 
               
            </IDrawer>

        )
    }
}

export default BunusItem