const utils = require("../comm/utils");
const iEnum = require("../comm/iEnum");

export function addf() {

}


export function countVip(data, vipData) {
    var CustomPriceSize = 0;
    var SCashierReferenceSize = 0;
    // var data = res;
    if(data.CustomPrice){
        CustomPriceSize = data.CustomPrice.length;
    }
    if(data.SCashierReference){
        SCashierReferenceSize = data.SCashierReference.length;
    }
    data.PriceText = utils.numberConvert(data.Price);
    if (data.PriceSettingsBasis == 0) {
        data.PriceSettingsBasis = iEnum.ESettingsBasis.Custom
    }
    if (data.AwardSettingsBasis == 0) {
        data.AwardSettingsBasis = iEnum.ESettingsBasis.Custom;
    }
    if (data.AwardBasis == 0) {
        data.AwardBasis = iEnum.EAmountBasis.Percent;
    }
    data.PriceValueText = utils.numberConvert(data.PriceValue);
    data.AwardValueText = utils.numberConvert(data.AwardValue);
    data.AwardValueSpcText = utils.numberConvert(data.AwardValueSpc);
    let tempVipList = [];
    if(vipData.length){
        vipData.forEach((item, index) => {//设置会员价
            var tempData = {};
            tempData.CardName = item.CardName;
            tempData.CardTid = item.Id;
            tempData.UnitPriceText = 0;//初始化自定义价格UnitPrice
            tempData.UnitPrice = 0;//初始化自定义价格UnitPrice
            tempData.CustomizedPrice = 0;//初始化自定义价格CustomizedPrice
            tempData.PromBy = item.Rate.PromBy;
            tempData.PromDiscount = item.Rate.PromDiscount;
            if (item.Rate.PromBy == iEnum.EVipCardModal.Discount && item.Rate.PromDiscount > 0 && item.Rate.PromDiscount < 10000) {
                tempData.Money = (data.Price * item.Rate.PromDiscount / 100000).toFixed(2);//计算会员价
                tempData.UnitPrice = utils.calcMultiply(tempData.Money, 100);
                tempData.CustomizedPrice = tempData.UnitPrice;
                tempData.UnitPriceText = tempData.Money;
            } else {
                tempData.Money = utils.numberConvert(data.Price).toFixed(2);//计算会员价
                tempData.UnitPrice = utils.calcMultiply(tempData.Money, 100);
                tempData.CustomizedPrice = tempData.UnitPrice;
                tempData.UnitPriceText = tempData.Money;
            }
            if(CustomPriceSize>0){
                for (var i = 0; i < CustomPriceSize; i++) {
                    var temp = data.CustomPrice[i];
                    // //console.log("会员卡",item.Id,i.CardTid)
                    if (item.Id == temp.CardTid) {
                        tempData.UnitPrice = temp.UnitPrice;
                        tempData.CustomizedPrice = temp.UnitPrice;
                        tempData.UnitPriceText = utils.numberConvert(temp.UnitPrice);
                        break;
                    }
                }
            }
            //新库存商品
            if(SCashierReferenceSize>0){
                for (var i = 0; i < SCashierReferenceSize; i++) {
                    var temp = data.SCashierReference[i];
                    
                    if (item.Id == temp.CardTid) {
                        console.log("会员卡",item.Id,temp.CardTid,temp)
                        tempData.Id = temp.Id;
                        tempData.CustomizedPrice = temp.CustomizedPrice;
                        tempData.UnitPrice = temp.CustomizedPrice;
                        tempData.UnitPriceText = utils.numberConvert(tempData.UnitPrice);
                        break;
                    }
                }
            }
            tempVipList.push(tempData)
        });
    }
    
    return tempVipList;
}


export function countRole(data, roleData,dataType) {
    var EmployeeAwardSize = 0;
    var SAwardSetModelSize = 0;
    if(data.EmployeeAward){
        EmployeeAwardSize = data.EmployeeAward.length;
    }
    if(data.SAwardSet){
        SAwardSetModelSize = data.SAwardSet.length;
    }
    
    data.AwardValueText = utils.numberConvert(data.AwardValue);
    data.AwardValueSpcText = utils.numberConvert(data.AwardValueSpc);
    data._AwardUcardValeu = utils.numberConvert(data.AwardUcardValeu);
    data._AwardUcardValeuSpc = utils.numberConvert(data.AwardUcardValeuSpc);
    let tempRoleList = [];
    if (roleData != null ) {
        // debugger
        roleData.forEach((item, index) => {
            var tempData = {};
            tempData.Name = item.Name;
            tempData.EmpRid = item.RoleId;
            tempData.PositionId = item.RoleId;
            // tempData.AwardBasis = iEnum.ESettingsBasis.Global;
            tempData.Type = iEnum.EAmountBasis.Percent; //新库存商品  /**1未设置  2金额 3百分比*/
            tempData.AmountBasis = iEnum.EAmountBasis.Percent;
            tempData.AmountBasisSpc = iEnum.EAmountBasis.Percent;
            //商品提成
            if(dataType==="goods"){
                tempData.Value = utils.calcMultiply(item.ProdRate, 100);
                tempData.ValueText = item.ProdRate;
                tempData.ValueSpc = utils.calcMultiply(item.ProdRateSpc, 100);
                tempData.ValueSpcText = item.ProdRateSpc;

                //商品刷卡非指定
                tempData.ValeuUcard = item.UcardSettings.ProdUcardRate;
                tempData._ValeuUcard = utils.numberConvert(item.UcardSettings.ProdUcardRate);
                 //商品刷卡指定
                 tempData.ValeuUcardSpc = 0;
                 tempData._ValeuUcardSpc = 0;
                
            }else{
                tempData.Value = utils.calcMultiply(item.VirtualRate, 100);
                tempData.ValueText = item.VirtualRate;
                tempData.ValueSpc = utils.calcMultiply(item.VirtualRateSpc, 100);
                tempData.ValueSpcText = item.VirtualRateSpc;
                //服务刷卡非指定
                tempData.ValeuUcard = item.UcardSettings.VirtualUcardRate;
                tempData._ValeuUcard = utils.numberConvert(item.UcardSettings.VirtualUcardRate, 100);
                 //服务刷卡指定
                tempData.ValeuUcardSpc = item.UcardSettings.VirtualUcardRateSpc;
                tempData._ValeuUcardSpc = utils.numberConvert(item.UcardSettings.VirtualUcardRateSpc, 100);

                
            }
           
            tempData.RatePrice = tempData.Value;
            // tempData.VirtualRate = item.VirtualRate;
            // tempData.VirtualRateSpc = item.VirtualRateSpc;
            //服务岗位提成
            if(EmployeeAwardSize>0){
                for (var i = 0; i < EmployeeAwardSize; i++) {
                    var temp = data.EmployeeAward[i];
                    if (item.RoleId == temp.EmpRid) {
                        // AwardBasis  全局&自定义
                        // AmountBasis 金额&百分百
                        tempData.Id = temp.Id;
                        tempData.EmpRid = temp.EmpRid;
                        tempData.GoodsId = temp.GoodsId;
                        tempData.AmountBasis = temp.AmountBasis;
                        tempData.AmountBasisSpc = temp.AmountBasisSpc;
                        tempData.Value = temp.Value;
                        tempData.ValueText = utils.numberConvert(temp.Value);
                        tempData.ValueSpc = temp.ValueSpc;
                        tempData.ValueSpcText = utils.numberConvert(temp.ValueSpc);
                        //服务刷卡非指定
                        tempData.ValeuUcard = temp.ValeuUcard;
                        tempData._ValeuUcard = utils.numberConvert(temp.ValeuUcard);
                        //服务刷卡指定
                        tempData.ValeuUcardSpc = temp.ValeuUcardSpc;
                        tempData._ValeuUcardSpc = utils.numberConvert(temp.ValeuUcardSpc);
                        break;
                    }
                }
            }
            //商品岗位提成
            if(SAwardSetModelSize>0){
                for (var i = 0; i < SAwardSetModelSize; i++) {
                    var temp = data.SAwardSet[i];
                    // //console.log("会员卡",item.Id,i.CardTid)
                    if (item.RoleId == temp.PositionId) {

                        
                        tempData.Type = temp.Type;
                        tempData.AmountBasis = temp.Type;
                        tempData.RatePrice = temp.RatePrice;
                        tempData.Value = tempData.RatePrice;
                        tempData.PositionId = temp.PositionId;
                        tempData.EmpRid = temp.PositionId;
                        tempData.RatePriceSpc = temp.RatePriceSpc;
                        tempData.ValueSpc = temp.RatePriceSpc;
                        tempData.TypeSpc = temp.TypeSpc;
                        tempData.AmountBasisSpc = temp.TypeSpc;
                        tempData.Id = temp.Id;
                        tempData.GoodsId = temp.GoodsId;
                        // AwardBasis  全局&自定义
                        // AmountBasis 金额&百分百
                        // tempData.EmpRid = temp.EmpRid;
                        // tempData.AmountBasis = temp.AmountBasis;
                        // tempData.Value = temp.Value;
                        // tempData.AmountBasisSpc = temp.AmountBasisSpc;
                        tempData.ValueText = utils.numberConvert(tempData.Value);
                        tempData.ValueSpcText = utils.numberConvert(tempData.ValueSpc);
                        //商品刷卡非指定
                        tempData.ValeuUcard = temp.RateUcardPrice;
                        tempData._ValeuUcard = utils.numberConvert(temp.RateUcardPrice);
                        //商品刷卡指定
                        tempData.ValeuUcardSpc = 0;
                        tempData._ValeuUcardSpc = 0;
                        break;
                    }
                }

            }
            tempRoleList.push(tempData)
        });
    }
    return tempRoleList;
}