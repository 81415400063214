import React from 'react';
import {Input} from 'antd';
import {money_max} from "../comm/constans";

// import EditableCell from './EditableCell'


export class IInputNumber extends React.Component {
    constructor() {
        super();
    }
    text = "";

    onChange(e) {
        this.text = e.target.value;
        this.text = this.text.replace(/^0*(0\.|[1-9])/, '$1');
        this.text = this.text.replace(/\D/g, '');
        if (this.text == "" || this.text < 0) {
            this.text = 0
        }
        //tag： 标识    num：结果  data：传入的数据
        e.target.value = this.text;
        this.props.onChange(this.text)

    }

    render() {
        return (
            <Input value={this.props.value} disabled={this.props.disabled} placeholder={this.props.placeholder}
                   maxLength={this.props.maxLength} style={this.props.style} onChange={this.onChange.bind(this)}/>
        );
    }
}


export class IInputWaterNum extends React.Component {
    constructor() {
        super();
    }
    text = "";

    onChange(e) {
        this.text = e.target.value;
        this.text = this.text.replace(/[^0-9]/, '');
        this.props.onChange(this.text)

    }

    render() {
        return (
            <Input value={this.props.value} disabled={this.props.disabled} placeholder={this.props.placeholder}
                   maxLength={this.props.maxLength} style={this.props.style} onChange={this.onChange.bind(this)}/>
        );
    }
}



export class IInputPrice extends React.Component {
    constructor() {
        super();
    }

    onChange(e) {
        var text = e.target.value;
        //修复第一个字符是小数点 的情况.
        if (text != '' && text.substr(0, 1) == '.') {
            text = "";
        }
        text = text.replace(/^0*(0\.|[1-9])/, '$1');//解决 粘贴不生效
        text = text.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
        text = text.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        text = text.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        text = text.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
        if (text.indexOf(".") < 0 && text != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
            if (text.substr(0, 1) == '0' && text.length == 2) {
                text = text.substr(1, text.length);
            }
        }
        if (text == "" || text < 0) {
            text = 0
        }

        if (text > money_max) {
            text = money_max
        }


        this.props.onChange(e)
    }


    render() {
        return (
            //   primary default
            <Input disabled={this.props.disabled} maxLength={this.props.maxLength} type="text" style={this.props.style}
                   placeholder={this.props.placeholder} value={this.props.value}
                   onChange={this.onChange.bind(this)} onClick={this.props.onClick}/>
        );
    }
}


export class IInputString extends React.Component {
    constructor() {
        super();
    }

    onChange(e) {
        // var text = e.target.value;
        // if (text != '' && text.substr(0, 1) == '.') {
        //     text = "";
        // }
        // text = text.replace(/[\@\#\$\%\^\&\*\(\)\{\}\:\"\'\<\>\?\[\]]/, '');
        // text = text.replace(/[\+]/, '＋');
        // if (this.props.onChange) {
        //     this.props.onChange(text)
        // } else {
        //     this.props.onCallback(text, this.props.data, this.props.tag)
        // }




         // ////console.log("22312312",e);
        // debugger;
        //修复第一个字符是小数点 的情况.
        if (e.target.value != '' && e.target.value.substr(0, 1) == '.') {
            e.target.value = "";
        }


        var text = e.target.value;
        // 去掉特殊字符
        text = text.replace(/[\@\%\#\$\^\&\*\(\)\{\}\:\"\<\>\?\[\]\-]/, '');
        text = text.replace(/[\+]/, '＋');
        if (this.props.onChange) {
            this.props.onChange(text)
        } else {
            this.props.onCallback(text, this.props.data, this.props.tag)
        }




    }

    render() {
        return (
            <Input value={this.props.value} disabled={this.props.disabled} maxLength={this.props.maxLength}
                   placeholder={this.props.placeholder} style={this.props.style} onChange={this.onChange.bind(this)}/>
        );
    }
}



export  class IInputMoney extends React.Component {
    constructor() {
        super();
    }

    onChange(e) {
        //修复第一个字符是小数点 的情况.
        if (e.target.value != '' && e.target.value.substr(0, 1) == '.') {
            e.target.value = "";
        }
        e.target.value = e.target.value.replace(/^0*(0\.|[1-9])/, '$1');//解决 粘贴不生效
        e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
        e.target.value = e.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        e.target.value = e.target.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
        if (e.target.value.indexOf(".") < 0 && e.target.value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
            if (e.target.value.substr(0, 1) == '0' && e.target.value.length == 2) {
                e.target.value = e.target.value.substr(1, e.target.value.length);
            }
        }
        if (this.props.max) {
            if (e.target.value > this.props.max) {
                e.target.value = this.props.max
            }
        } else {
            if (e.target.value > money_max) {
                e.target.value = money_max
            }
        }


        if (this.props.onChange) {
            this.props.onChange(e.target.value)
        } else {
            this.props.onCallback(e.target.value, this.props.data, this.props.tag)
        }

    }

    render() {
        return (
            <Input disabled={this.props.disabled} type="text" style={this.props.style} value={this.props.value}
                   onChange={this.onChange.bind(this)}
                   className={this.props.className}
                   defaultValue={this.props.defaultValue}
                   placeholder={this.props.placeholder}
                   onFocus={this.props.onFocus}
                   onClick={this.props.onClick}
                   max={this.props.max}/>
        );
    }
}

