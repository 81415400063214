import React from 'react'
import { Button,Tabs ,Select } from 'antd'
import IButton from '../../../components/IButton'
import IDrawer from '../../../components/iDrawer'
import { IModal } from '../../../components/iModal'
import InputString from '../../../components/iInputString'
import InputMoney from '../../../components/iInputMoney'
import StorageTools from "../../../comm/storageTools";
import { $mzlApi } from "../../../comm/mzl-core";
import { messageError, messageSuccess, numberConvert_100, numberConvert, showMessageError, filterByName, showLoadding, hideLoadding, numConvert } from '../../../comm/utils';
import ITablePage, { getPageSize, setTabTop } from '../../../components/iTablePage'
import APINames from "../../../comm/api-names"
import Editor from "react-umeditor";
import { FnMenuId } from "../../../comm/constans"

import ITableList from '@components/iTableList'
import Detail from "./detail";
import SendoutDetail from "./sendoutDetail";

import {getConversionDate,getTime } from '@comm/dateHelp';
import service from '../../../services/marketing/bonusService'
import { SShActivity ,ActivityState} from '../../../bean/market/bonus'
import ActivityDetail from './activityDetail'
let begin = 0;
let over = 20;
const { TabPane } = Tabs;
const { Option } = Select;
/**
 * 拓客活动设置
 */
class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            enabled:0,
            content: "",
            detailData:SShActivity(),
            totalSize:0,
            columns : [
                {
                  width:80,
                  title: '序号',
                  dataIndex: 'name',
                  align: 'center',
                  render: (text,record,index) => <span>{index+begin+1}</span>,
                },
                {
                  title: '活动名称',
                  align: 'center',
                  dataIndex: 'ActivityName',
                },
                {
                  title: '门市价',
                  align: 'center',
                  dataIndex: 'Price',
                },
                {
                  title: '体验价',
                  align: 'center',
                  dataIndex: 'ActivityPrice',
                },
                {
                  title: '发放数量',
                  align: 'center',
                  dataIndex: 'Count',
                  render:(text,data)=>(<a onClick={this.onActivityDetail.bind(this,data)}>{text}</a>)
                },
                {
                  title: '有效期',
                  align: 'center',
                  dataIndex: '_activityTime',
                  width:170
                },
                {
                  title: '状态',
                  align: 'center',
                  dataIndex: '_stateText',
                  render:(text,data)=>(
                    <span>{data.State ===1?data._stateText:"已停止"}</span>
                  )
                },
                {
                  title: '操作',
                  align: 'center',
                  width:120,
                  render: (text,data) => (
                    <div className="comm-flex-row-center">
                        {
                          data.State ===1?
                          (
                            data._stateText == "未开始"|| data._stateText == "进行中"?
                            <a onClick={this.onDelete.bind(this,data,2)}>停止</a>:null
                          ):
                          (
                            data._stateText == "已结束"?null:<a onClick={this.onDelete.bind(this,data,1)}>开启</a>
                          )
                        }
                        <a style={{marginLeft:8}} onClick={this.onDetail.bind(this,data)}>{data._stateText == "未开始"?"编辑":"查看"}</a>
                       
                        
                    </div>
                ),
                }
              ],
            tableData:[],
            detailVisible:false,
            SendoutDetailVisible:false,
            activityDetailVisible:false
        }
    }
    componentWillMount() {
    }
   
    componentDidMount() {
      begin =0;
      over =20;
      this.getPageData();
     
    }

    getPageData(){
      let opt ={ Begin: begin, Over: over,Opt:{state:this.state.enabled}};
      // map.set("opt", JSON.stringify({ Begin: begin, Over: over,Opt:{beginTime, overTime ,keyword,createType ,state ,applicationTypeId}}));
      let time = new  Date().getTime()/1000;
      service.queryShActivity(this,opt).then(res=>{
        let data = JSON.parse(res.Data);
        data.forEach(item => {
          item.Price = item.Price/100;
          item.ActivityPrice = item.ActivityPrice/100;
          item._activityTime= getConversionDate(item.BeginTime)+"-"+getConversionDate(item.OverTime);
         
          if(item.BeginTime> time){
            item._stateText = "未开始"
          }else if(item.BeginTime< time && item.OverTime>time){
            item._stateText = "进行中"
          }else{
            item._stateText = "已结束"
          }
        })
        this.setState({
          tableData:data,
          totalSize:res.Count
        })
      })
    }

    onDetail(detail){
      if(detail == "add"){
        let data = SShActivity()
        data._stateText = "未开始"
        this.setState({
          detailData:data,
          detailVisible:true
        }) 
      }else{
        
        service.getShActivity(this,detail.Id,).then(res=>{
          
          let data = JSON.parse(res.Data.data);
          if(detail._stateText == "未开始"){
            data._stateText = "未开始"
          }
          data._BeginTime = getConversionDate(data.BeginTime);
          data._OverTime = getConversionDate(data.OverTime);
          
          data.BnsCms = data.BnsCms/100 ;
          data.ActivityItems.forEach((item,index)=>{
            if(index ===0){
              if(item.ExpireDay === 0){
                data._ExpireDay=0
              }else{
                data._ExpireDay=1
              }
              data.ExpireDay = item.ExpireDay;
            }
            item.Price = item.Price/100
            item.ActivityPrice = item.ActivityPrice/100
            item.Amount = item.Amount/100
          })
            this.setState({
              detailVisible:true,
              detailData:data
            })
        });
        // this.setState({
        //   detailVisible:true
        // })
      }
      
      
    }

    onDelete(data,state){
      service.enabledShActivity(this,data.Id,state)
      .then(res=>{
        begin =0;
        over =20;
        this.getPageData();
      });
    }

    onActivityDetail(data){
      // debugger
      this.setState({
        activityDetailVisible:true,
        detailData:data
      })
    }


    onTablePageCallback(){

    }
    onClickListener(){

    }

    handleChange(e){
      this.setState({
        enabled:e
      },()=>this.getPageData());
    }
    render() {
        return (
            <div>
                <div className="comm-flex-row-between">
                    <div className="comm-flex-row-center">
                        状态:
                        <Select defaultValue="0" style={{ width: 120,marginLeft:8 }} onChange={this.handleChange.bind(this)}>
                            <Option value="0">全部</Option>
                            <Option value="1">启用</Option>
                            <Option value="2">停用</Option>
                        </Select>
                    </div>
                    <Button type="primary" onClick={this.onDetail.bind(this,'add')}>添加拓客活动</Button>
                </div>

                <ITableList 
                     style={{marginTop:10,marginBottom:20}}
                     pagination={true}
                     top={260}
                     columns={this.state.columns} 
                     data={this.state.tableData}
                     totalSize={this.state.totalSize}
                     onTablePageCallback={this.onTablePageCallback.bind(this)}>

                </ITableList>
                {
                  this.state.detailVisible?
                  (
                    <Detail 
                    onOK={()=>{
                      this.getPageData();
                      this.setState({
                        detailVisible:false
                      })
                    }} 
                    onCancel={()=>{
                      this.setState({
                        detailVisible:false
                      })
                    }} 
                    visible={this.state.detailVisible} 
                    detailData={this.state.detailData}>

                    </Detail>
                  ):null
                }
                
                  {
                    this.state.SendoutDetailVisible?(
                    <SendoutDetail 
                      onOK={this.onClickListener.bind(this)} 
                      onCancel={()=>{
                        this.setState({
                          SendoutDetailVisible:false
                        })
                      }} 
                      visible={this.state.SendoutDetailVisible} 
                      detailData={this.state.detailData}>

                    </SendoutDetail>
                    ):null
                  }
                  {
                    this.state.activityDetailVisible?(
                    <ActivityDetail 
                      onOK={this.onClickListener.bind(this)} 
                      onCancel={()=>{
                        this.setState({
                          activityDetailVisible:false
                        })
                      }} 
                      visible={this.state.activityDetailVisible} 
                      detailData={this.state.detailData}/>
                    ):null
                  }
                
                
            </div>



        )
    }
}

export default Index