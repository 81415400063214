import React from "react";
import { Checkbox, Form, Icon, Input, Radio, Tabs, Table } from 'antd';
import iEnum from "../comm/iEnum";
import IInputMoney from "../components/iInputMoney";
import APINames from "../comm/api-names";
import { numberConvert } from "../comm/utils";

const utils = require("../comm/utils");

const FormItem = Form.Item;
const Search = Input.Search;
const RadioGroup = Radio.Group;
const TabPane = Tabs.TabPane;

export default class ItemRoleView extends React.Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            roleData: [],
            isShowAll: false,
            isShowAllSpc: false,
            tabsKey: 1,


            columns: [
                {
                    title: '岗位',
                    dataIndex: 'Name',
                    key: 'Name',
                },
                {
                    title: '提成方式',
                    dataIndex: 'age',
                    key: 'age',
                    render: (text, item) => (
                        <div>
                            <RadioGroup onChange={this.onItemChange.bind(this, "Radio", item)} value={item.AmountBasis}>
                                <Radio value={iEnum.EAmountBasis.Percent}>百分比</Radio>
                                <Radio value={iEnum.EAmountBasis.Amount}>金额</Radio>
                            </RadioGroup>
                        </div>
                    )
                },
                {
                    title: '现金比例/金额',
                    dataIndex: 'xj',
                    key: 'xj',
                    render: (text, item) => (
                        <div>
                            <IInputMoney style={{ width: "80px", marginLeft: 5 }}
                                value={item.ValueText}
                                onChange={this.onItemChange.bind(this, "Value", item)} />&nbsp;&nbsp;
                            {item.AmountBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}
                        </div>
                    )
                },

                {
                    title: '扣卡比例/金额',
                    dataIndex: 'bl',
                    key: 'bl',
                    render: (text, item) => (
                        <div >
                            <IInputMoney style={{ width: "80px", marginLeft: 5 }}
                                value={item._ValeuUcard}
                                onChange={this.onItemChange.bind(this, "ValeuUcard", item)} />&nbsp;&nbsp;
                            {item.AmountBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}
                        </div>
                    )
                },
            ],
            columnsSpc: [
                {
                    title: '岗位',
                    dataIndex: 'Name',
                    key: 'name',
                },
                {
                    title: '提成方式',
                    dataIndex: 'age',
                    key: 'age',
                    render: (text, item) => (
                        <div>
                            <RadioGroup onChange={this.onItemChange.bind(this, "RadioSpc", item)}
                                value={item.AmountBasisSpc}>
                                <Radio value={iEnum.EAmountBasis.Percent}>百分比</Radio>
                                <Radio value={iEnum.EAmountBasis.Amount}>金额</Radio>
                            </RadioGroup>
                        </div>
                    )
                },
                {
                    title: '现金比例/金额',
                    dataIndex: 'xj',
                    key: 'xj',
                    render: (text, item) => (
                        <div>
                            <IInputMoney style={{ width: "80px", marginLeft: 5 }}
                                value={item.ValueSpcText}
                                onChange={this.onItemChange.bind(this, "ValueSpc", item)} />&nbsp;&nbsp;
                            {item.AmountBasisSpc == iEnum.EAmountBasis.Percent ? "%" : "元"}
                        </div>
                    )
                },
                {
                    title: '扣卡比例/金额',
                    dataIndex: 'bl',
                    key: 'bl',
                    render: (text, item) => (
                        <div>
                            <IInputMoney style={{ width: "80px", marginLeft: 5 }}
                                value={item._ValeuUcardSpc}
                                onChange={this.onItemChange.bind(this, "ValeuUcardSpc", item)} />&nbsp;&nbsp;
                            {item.AmountBasisSpc == iEnum.EAmountBasis.Percent ? "%" : "元"}
                        </div>
                    )
                },
            ],
        }
    }

    componentWillMount() {
    }


    shouldComponentUpdate(news) {
        if (news.saveData.AwardSettingsBasis === 0) {
            news.saveData.AwardSettingsBasis = iEnum.ESettingsBasis.Custom
        }
        if (news.saveData.AwardSettingsBasisSpc === 0) {
            news.saveData.AwardSettingsBasisSpc = iEnum.ESettingsBasis.Custom;
        }

        if (news.saveData.AwardBasis === 0) {
            news.saveData.AwardBasis = iEnum.EAmountBasis.Percent
        }

        if (news.saveData.AwardBasisSpc === 0) {
            news.saveData.AwardBasisSpc = iEnum.EAmountBasis.Percent
        }

        return true;
    }


    componentDidMount() {

        // this.requestStoreRole();
    }


    httpSuccess(res, tag) {
        ////console.log("httpSuccess", tag, res)
        switch (tag) {
            case APINames.API_getStoreRole:
                var pageList = res;
                pageList.forEach((item) => {
                    item.key = item.Id;
                    item._ChargeRate = numberConvert(item.ChargeRate);
                    item._Card2Rate = numberConvert(item.Card2Rate);
                    item._CardRate = numberConvert(item.CardRate);
                    item._VirtualRate = numberConvert(item.VirtualRate);
                    item._ProdRate = numberConvert(item.ProdRate)
                });
                this.setState({
                    roleData: res
                });
                break;
        }
    }

    httpFail(res, tag) {
        ////console.log("httpFail", tag, res)
    }

    onChange(tag, e) {
        console.log(tag, e)
        switch (tag) {
            case 'all':
                this.setState({
                    isShowAll: !this.state.isShowAll
                });
                break;
            case 'allSpc':
                this.setState({
                    isShowAllSpc: !this.state.isShowAllSpc
                });
                break;
            case 'unityRadio':
                //非指定 全局 百分百
                if (e.target.value == iEnum.EAmountBasis.Percent) {
                    this.props.saveData.AwardBasis = iEnum.EAmountBasis.Percent;
                    if (this.props.saveData.AwardValue > 10000) {
                        this.props.saveData.AwardValueText = 100;
                        this.props.saveData.AwardValue = utils.numberConvert_100(100);
                    }
                } else {
                    this.props.saveData.AwardBasis = e.target.value;
                }
                this.setState({
                    unityRadio: e.target.value
                });

                break;
            case 'unityRadioSpc':
                //指定 全局 百分百
                if (e.target.value == iEnum.EAmountBasis.Percent) {
                    this.state.AwardBasisSpc = iEnum.EAmountBasis.Percent;
                    if (this.props.saveData.AwardValueSpc > 10000) {
                        this.props.saveData.AwardValueSpcText = 100;
                        this.props.saveData.AwardValueSpc = utils.numberConvert_100(100);
                    }
                } else {
                    this.state.AwardBasisSpc = iEnum.EAmountBasis.Amount;
                }
                this.props.saveData.AwardBasisSpc = this.state.AwardBasisSpc;
                this.setState({
                    unityRadioSpc: e.target.value
                });
                break;


            case 'unityRole': //非指定 为不同岗位设置不同提成
                if (this.props.saveData.AwardSettingsBasis !== iEnum.ESettingsBasis.Custom) {
                    this.props.saveData.AwardSettingsBasis = iEnum.ESettingsBasis.Custom;
                } else {
                    this.props.saveData.AwardSettingsBasis = iEnum.ESettingsBasis.Global;
                }
                this.props.roleList.forEach(item => {
                    item.AwardBasis = iEnum.ESettingsBasis.Custom;
                });
                this.setState(this);

                break;

            case 'unityRoleSpc': //指定 为不同岗位设置不同提成
                if (this.props.saveData.AwardSettingsBasisSpc !== iEnum.ESettingsBasis.Custom) {
                    this.props.saveData.AwardSettingsBasisSpc = iEnum.ESettingsBasis.Custom;
                } else {
                    this.props.saveData.AwardSettingsBasisSpc = iEnum.ESettingsBasis.Global;
                }
                this.props.roleList.forEach(item => {
                    item.AwardBasisSpc = iEnum.ESettingsBasis.Custom;
                });
                this.setState(this);
                break;
            case 'UnitPriceText':
                if (this.props.saveData.AwardBasis === iEnum.EAmountBasis.Percent) {
                    var money = utils.checkMoneyMax(e, 100);
                    this.props.saveData.AwardValueText = money;
                    this.props.saveData.AwardValue = utils.numberConvert_100(money);
                } else {
                    // var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                    this.props.saveData.AwardValueText = e;
                    this.props.saveData.AwardValue = utils.numberConvert_100(e);
                }
                this.setState(this);
                break;
            case 'UnitPriceSpcText':
                if (this.props.saveData.AwardBasisSpc === iEnum.EAmountBasis.Percent) {
                    var money = utils.checkMoneyMax(e, 100);
                    this.props.saveData.AwardValueSpcText = money;
                    this.props.saveData.AwardValueSpc = utils.numberConvert_100(money);
                } else {
                    // var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                    this.props.saveData.AwardValueSpcText = e;
                    this.props.saveData.AwardValueSpc = utils.numberConvert_100(e);
                }
                this.setState(this);
                break;
            case 'AwardUcardValeu':
                if (this.props.saveData.AwardBasis === iEnum.EAmountBasis.Percent) {
                    var money = utils.checkMoneyMax(e, 100);
                    this.props.saveData._AwardUcardValeu = money;
                    this.props.saveData.AwardUcardValeu = utils.numberConvert_100(money);
                } else {
                    // var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                    this.props.saveData._AwardUcardValeu = e;
                    this.props.saveData.AwardUcardValeu = utils.numberConvert_100(e);
                }
                this.setState(this);
                break;
            case 'AwardUcardValeuSpc':
                if (this.props.saveData.AwardBasisSpc === iEnum.EAmountBasis.Percent) {
                    var money = utils.checkMoneyMax(e, 100);
                    this.props.saveData._AwardUcardValeuSpc = money;
                    this.props.saveData.AwardUcardValeuSpc = utils.numberConvert_100(money);
                } else {
                    // var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                    this.props.saveData._AwardUcardValeuSpc = e;
                    this.props.saveData.AwardUcardValeuSpc = utils.numberConvert_100(e);
                }
                this.setState(this);
                break;
            case 'tabs':
                this.setState({
                    tabsKey: e
                });
                break;

        }

    }

    onItemChange(tag, data, e) {
        switch (tag) {
            case 'UnitPriceText':
                data.UnitPriceText = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                data.UnitPrice = utils.numberConvert_100(data.UnitPriceText);
                this.props.saveData.AwardValue = data.UnitPrice
                break;
            case 'Radio'://单选
                // if (data.AwardBasis == iEnum.ESettingsBasis.Global) {
                //     return;
                // }
                data.AmountBasis = e.target.value;
                if (data.AmountBasis === iEnum.EAmountBasis.Percent) {
                    if (data.ValueText > 100) {
                        data.ValueText = 100;
                        data.Value = 10000;
                    }
                }
                data.Type = data.AmountBasis;
                break;
            case 'RadioSpc'://单选
                // if (data.AwardBasis == iEnum.ESettingsBasis.Global) {
                //     return;
                // }
                data.AmountBasisSpc = e.target.value;
                if (data.AmountBasisSpc === iEnum.EAmountBasis.Percent) {
                    if (data.ValueSpcText > 100) {
                        data.ValueSpcText = 100;
                        data.ValueSpc = 10000;
                    }
                }
                break;
            case 'Value':
                if (data.AmountBasis == iEnum.EAmountBasis.Percent) {
                    var money = utils.checkMoneyMax(e, 100);
                    data.ValueText = money;
                    data.Value = utils.numberConvert_100(money);
                } else {
                    // var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                    data.ValueText = e;
                    data.Value = utils.numberConvert_100(e);
                }
                data.RatePrice = data.Value;
                break;
            case 'ValueSpc':
                if (data.AmountBasisSpc == iEnum.EAmountBasis.Percent) {
                    var money = utils.checkMoneyMax(e, 100);
                    data.ValueSpcText = money;
                    data.ValueSpc = utils.numberConvert_100(money);
                } else {
                    // var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                    data.ValueSpcText = e;
                    data.ValueSpc = utils.numberConvert_100(e);
                }
                break;
            case 'ValeuUcard':
                if (data.AmountBasis == iEnum.EAmountBasis.Percent) {
                    var money = utils.checkMoneyMax(e, 100);
                    data._ValeuUcard = money;
                    data.ValeuUcard = utils.numberConvert_100(money);
                } else {
                    // var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                    data._ValeuUcard = e;
                    data.ValeuUcard = utils.numberConvert_100(e);
                }
                break;
            case 'ValeuUcardSpc':
                if (data.AmountBasisSpc == iEnum.EAmountBasis.Percent) {
                    var money = utils.checkMoneyMax(e, 100);
                    data._ValeuUcardSpc = money;
                    data.ValeuUcardSpc = utils.numberConvert_100(money);
                } else {
                    // var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                    data._ValeuUcardSpc = e;
                    data.ValeuUcardSpc = utils.numberConvert_100(e);
                }
                break;
        }
        this.setState(this);
    }

    getItemView(isShowAll) {
        if (isShowAll) {
            return this.props.roleList;
        } else {
            var temp = [];
            this.props.roleList.forEach((item, index) => {
                if (index < 5) {
                    temp.push(item)
                }
            });
            return temp;
        }
    }
    isBuckleState(columns,columnsSpc){
        if(this.props._buckleState===1){
            columns.push({
                title: '扣卡比例/金额',
                dataIndex: 'bl',
                key: 'bl',
                render: (text, item) => (
                    <div >
                        <IInputMoney style={{ width: "80px", marginLeft: 5 }}
                            value={item._ValeuUcard}
                            onChange={this.onItemChange.bind(this, "ValeuUcard", item)} />&nbsp;&nbsp;
                        {item.AmountBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}
                    </div>
                )
            });
            columnsSpc.push({
                title: '扣卡比例/金额',
                dataIndex: 'bl',
                key: 'bl',
                render: (text, item) => (
                    <div>
                        <IInputMoney style={{ width: "80px", marginLeft: 5 }}
                            value={item._ValeuUcardSpc}
                            onChange={this.onItemChange.bind(this, "ValeuUcardSpc", item)} />&nbsp;&nbsp;
                        {item.AmountBasisSpc == iEnum.EAmountBasis.Percent ? "%" : "元"}
                    </div>
                )
            });
        }
        
    }

    render() {
        // if(this.props.saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom){
        //     this.state.unityRole = true;
        // }
        // this.state.AwardSettingsBasis = this.props.saveData.AwardSettingsBasis;
        // this.state.AwardBasis = this.props.saveData.AwardBasis;

        const { hiedTabPane } = this.props;
        const { tabsKey,  } = this.state;

        const roleList = this.getItemView(this.state.tabsKey == 1 ? this.state.isShowAll : this.state.isShowAllSpc);
        console.log("itemRoleView---- ",roleList);

        const saveData = this.props.saveData;
        //是否显示自定义岗位提成
        const isShowTable = this.state.tabsKey == 1 ? 
            saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom:
            saveData.AwardSettingsBasisSpc == iEnum.ESettingsBasis.Custom;


        const columns = [
                {
                    title: '岗位',
                    dataIndex: 'Name',
                    key: 'Name',
                },
                {
                    title: '提成方式',
                    dataIndex: 'age',
                    key: 'age',
                    render: (text, item) => (
                        <div>
                            <RadioGroup onChange={this.onItemChange.bind(this, "Radio", item)} value={item.AmountBasis}>
                                <Radio value={iEnum.EAmountBasis.Percent}>百分比</Radio>
                                <Radio value={iEnum.EAmountBasis.Amount}>金额</Radio>
                            </RadioGroup>
                        </div>
                    )
                },
                {
                    title: '现金比例/金额',
                    dataIndex: 'xj',
                    key: 'xj',
                    render: (text, item) => (
                        <div>
                            <IInputMoney style={{ width: "80px", marginLeft: 5 }}
                                value={item.ValueText}
                                onChange={this.onItemChange.bind(this, "Value", item)} />&nbsp;&nbsp;
                            {item.AmountBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}
                        </div>
                    )
                },
               
                
            ];

            
        const columnsSpc= [
                {
                    title: '岗位',
                    dataIndex: 'Name',
                    key: 'name',
                },
                {
                    title: '提成方式',
                    dataIndex: 'age',
                    key: 'age',
                    render: (text, item) => (
                        <div>
                            <RadioGroup onChange={this.onItemChange.bind(this, "RadioSpc", item)}
                                value={item.AmountBasisSpc}>
                                <Radio value={iEnum.EAmountBasis.Percent}>百分比</Radio>
                                <Radio value={iEnum.EAmountBasis.Amount}>金额</Radio>
                            </RadioGroup>
                        </div>
                    )
                },
                {
                    title: '现金比例/金额',
                    dataIndex: 'xj',
                    key: 'xj',
                    render: (text, item) => (
                        <div>
                            <IInputMoney style={{ width: "80px", marginLeft: 5 }}
                                value={item.ValueSpcText}
                                onChange={this.onItemChange.bind(this, "ValueSpc", item)} />&nbsp;&nbsp;
                            {item.AmountBasisSpc == iEnum.EAmountBasis.Percent ? "%" : "元"}
                        </div>
                    )
                },
            ];

        this.isBuckleState(columns,columnsSpc);

        return (
            <div className="comm-col comm-padding-top-10">
                <div className="comm-row " style={{ marginLeft: "5px" }}>
                    <div style={{ marginTop: 7 }}>{this.props.title ? this.props.title : "服务提成"}</div>
                    {/* <div>
                        <div className="comm-row " style={{marginLeft: "24px"}}>
                            <RadioGroup style={{marginTop:5}} disabled={saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom}
                                        value={saveData.AwardBasis}
                                        onChange={this.onChange.bind(this, "unityRadio")}>
                                <Radio value={iEnum.EAmountBasis.Percent}>百分比</Radio>
                                <Radio value={iEnum.EAmountBasis.Amount}>金额</Radio>
                            </RadioGroup>
                            <div>
                                <div
                                    className="comm-visibility-visible">
                                        非指定　
                                    <IInputMoney
                                        disabled={saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom}
                                        value={this.props.saveData.AwardValueText}
                                        onChange={this.onChange.bind(this, "UnitPriceText")}
                                        style={{width: 80, marginRight: "10px"}}/>
                                    <span>{saveData.AwardBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}</span>
                                </div>
                                <div
                                    className="comm-visibility-visible"
                                    style={{marginTop:10}}>
                                    指定　　
                                    <IInputMoney
                                        disabled={saveData.AwardSettingsBasisSpc == iEnum.ESettingsBasis.Custom}
                                        value={this.props.saveData.AwardValueSpcText}
                                        onChange={this.onChange.bind(this, "UnitPriceSpcText")}
                                        style={{width: 80, marginRight: "10px"}}/>
                                    <span>{saveData.AwardBasisSpc == iEnum.EAmountBasis.Percent ? "%" : "元"}</span>
                                </div>
                            </div>
                            <div style={{marginLeft:10}}>
                                <div
                                    className="comm-visibility-visible">
                                        扣卡非指定　
                                    <IInputMoney
                                        disabled={saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom}
                                        value={this.props.saveData.AwardValueText}
                                        onChange={this.onChange.bind(this, "UnitPriceText")}
                                        style={{width: 80, marginRight: "10px"}}/>
                                    <span>{saveData.AwardBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}</span>
                                </div>
                                <div
                                    className="comm-visibility-visible"
                                    style={{marginTop:10}}>
                                    扣卡指定　　
                                    <IInputMoney
                                        disabled={saveData.AwardSettingsBasisSpc == iEnum.ESettingsBasis.Custom}
                                        value={this.props.saveData.AwardValueSpcText}
                                        onChange={this.onChange.bind(this, "UnitPriceSpcText")}
                                        style={{width: 80, marginRight: "10px"}}/>
                                    <span>{saveData.AwardBasisSpc == iEnum.EAmountBasis.Percent ? "%" : "元"}</span>
                                </div>
                            </div>
                            
                        </div>
                        <div className="comm-padding-top-10" style={{marginLeft: "4px"}}>
                            <Checkbox checked={saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom}
                                        style={{marginLeft: 20}}
                                        onChange={this.onChange.bind(this, "unityRole")}>为不同岗位设置不同提成</Checkbox>
                        </div>

                    </div>  */}




                    <div className="comm-col">
                        {hiedTabPane ? null : <div style={{ width: "340px", marginLeft: 20, marginTop: 6 }}>
                            <Tabs onChange={this.onChange.bind(this, "tabs")} tabBarGutter={1} type="card"
                                style={{ width: "321px" }}>
                                <TabPane tab="　　非指定客　　　" key="1"></TabPane>
                                <TabPane tab="　　　指定客　　　" key="2"></TabPane>
                            </Tabs>

                        </div>}


                        {tabsKey == 1 ?
                            <div>
                                <div className="comm-row comm-item-center" style={{ marginLeft: "24px" }}>
                                    <RadioGroup disabled={saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom}
                                        value={saveData.AwardBasis}
                                        onChange={this.onChange.bind(this, "unityRadio")}>
                                        <Radio value={iEnum.EAmountBasis.Percent}>百分比</Radio>
                                        <Radio value={iEnum.EAmountBasis.Amount}>金额</Radio>
                                    </RadioGroup>
                                    <div
                                        className="comm-visibility-visible">
                                        现金
                                    <IInputMoney
                                            disabled={saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom}
                                            value={this.props.saveData.AwardValueText}
                                            onChange={this.onChange.bind(this, "UnitPriceText")}
                                            style={{ width: 80, marginRight: "10px", marginLeft: 5 }} />
                                        <span>{saveData.AwardBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}</span>
                                    </div>
                                    {
                                        this.props._buckleState===1?
                                        <div
                                            style={{ marginLeft: 10 }}
                                            className="comm-visibility-visible">
                                            扣卡
                                            <IInputMoney
                                                disabled={saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom}
                                                value={this.props.saveData._AwardUcardValeu}
                                                onChange={this.onChange.bind(this, "AwardUcardValeu")}
                                                style={{ width: 80, marginRight: "10px", marginLeft: 5 }} />
                                            <span>{saveData.AwardBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}</span>
                                        </div>
                                        :null
                                    }
                                    
                                </div>
                                <div className="comm-padding-top-10" style={{ marginLeft: "4px" }}>
                                    <Checkbox checked={saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom}
                                        style={{ marginLeft: 20 }}
                                        onChange={this.onChange.bind(this, "unityRole")}>为不同岗位设置不同提成</Checkbox>
                                </div>

                            </div>
                            :
                            <div>
                                <div className="comm-row comm-item-center" style={{ marginLeft: "24px" }}>
                                    <RadioGroup disabled={saveData.AwardSettingsBasisSpc == iEnum.ESettingsBasis.Custom}
                                        value={saveData.AwardBasisSpc}
                                        onChange={this.onChange.bind(this, "unityRadioSpc")}>
                                        <Radio value={iEnum.EAmountBasis.Percent}>百分比</Radio>
                                        <Radio value={iEnum.EAmountBasis.Amount}>金额</Radio>
                                    </RadioGroup>
                                    <div
                                        className="comm-visibility-visible">
                                        现金
                                    <IInputMoney
                                            disabled={saveData.AwardSettingsBasisSpc == iEnum.ESettingsBasis.Custom}
                                            value={this.props.saveData.AwardValueSpcText}
                                            onChange={this.onChange.bind(this, "UnitPriceSpcText")}
                                            style={{ width: 80, marginRight: "10px", marginLeft: 5 }} />
                                        <span>{saveData.AwardBasisSpc == iEnum.EAmountBasis.Percent ? "%" : "元"}</span>
                                    </div>
                                    {
                                        this.props._buckleState===1?
                                        <div
                                            style={{ marginLeft: 10 }}
                                            className="comm-visibility-visible">
                                            扣卡
                                            <IInputMoney
                                                disabled={saveData.AwardSettingsBasisSpc == iEnum.ESettingsBasis.Custom}
                                                value={this.props.saveData._AwardUcardValeuSpc}
                                                onChange={this.onChange.bind(this, "AwardUcardValeuSpc")}
                                                style={{ width: 80, marginRight: "10px", marginLeft: 5 }} />
                                            <span>{saveData.AwardBasisSpc == iEnum.EAmountBasis.Percent ? "%" : "元"}</span>
                                        </div>
                                        :null
                                    }
                                    
                                </div>
                                <div className="comm-padding-top-10" style={{ marginLeft: "4px" }}>
                                    <Checkbox checked={saveData.AwardSettingsBasisSpc == iEnum.ESettingsBasis.Custom}
                                        style={{ marginLeft: 20 }}
                                        onChange={this.onChange.bind(this, "unityRoleSpc")}>为不同岗位设置不同提成</Checkbox>
                                </div>

                            </div>
                        }


                    </div>
                </div>
                {
                    isShowTable?
                    <div style={{ paddingRight: 10,marginLeft:82 }}>
                        <Table
                            style={{marginBottom:10}}
                            size={'middle'}
                            bordered={false}
                            dataSource={roleList}
                            columns={tabsKey == 1 ? columns : columnsSpc}
                            pagination={false} />
                        {
                            tabsKey == 1 ?
                                this.props.roleList.length > 5 ?
                                    <a
                                        onClick={this.onChange.bind(this, "all")}>
                                        {
                                            this.state.isShowAll ?
                                                <div>收起<Icon type="up" /></div>
                                                :
                                                <div>展开<Icon type="down" /></div>
                                        }
                                    </a>
                                    : null
                                :
                                this.props.roleList.length > 5 ?
                                    <a onClick={this.onChange.bind(this, "allSpc")}>
                                        {
                                            this.state.isShowAllSpc ?
                                                <div>收起<Icon type="up" /></div>
                                                :
                                                <div>展开<Icon type="down" /></div>
                                        }
                                    </a>
                                    : null
                        }
                    </div>
                    :null
                }
                

                {/* {
                    this.state.tabsKey == 1 ? saveData.AwardSettingsBasis == iEnum.ESettingsBasis.Custom ?
                    <div className="comm-padding-top-10" style={{marginLeft: "84px"}}>
                        {roleDataView}
                        {this.props.roleList.length > 5 ?
                            <a onClick={this.onChange.bind(this, "all")}>{this.state.isShowAll ?
                                <div>收起<Icon type="up"/></div> : <div>展开<Icon type="down"/></div>}</a> : null}
                    </div> : null
                    : saveData.AwardSettingsBasisSpc == iEnum.ESettingsBasis.Custom ?
                        <div className="comm-padding-top-10" style={{marginLeft: "84px"}}>
                            {roleDataSpcView}
                            {this.props.roleList.length > 5 ?
                                <a onClick={this.onChange.bind(this, "allSpc")}>{this.state.isShowAllSpc ?
                                    <div>收起<Icon type="up"/></div> : <div>展开<Icon type="down"/></div>}</a> : null}
                        </div> 
                        : null
                } */}

            </div>
        );
    }
}