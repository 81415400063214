
import {
    INIT_SILVER_CONFIG, //初始化
    SET_SERVICE,
    SUBMIT,
    FROM_MODAL_VISIBLE,
    EMP_MODAL_VISIBLE,
    SILVER_VISIBLE,
    STORE_SERVICE,
    STORE_GOODS,
    ADD_ORDER,
    DEL_ORDER,
    DEL_GOODS,
    SET_MODE,
    INIT_DATA, //初始化基础数据
    INIT_EMP_SORT_DATA, //初始化轮牌基础数据
    INIT_SDI_DATA, //初始化手牌基础数据
    INIT_ROOM_DATA, //初始化房间基础数据
    CLEAR_DETAIL,//清除客户数据
    SET_DETAIL, //填写客户数据
    SET_DETAIL_ITEM,//填写客户项目数据
    SET_FROM_MODE,//from 样式
    UPDATE,//from 样式
} from './action';

import {SPreOrder,SPreOrderItem,SPreOrderAward} from './service/bean'







const initData=()=>{
    return {
        silverVisible:true,// 收银界面
        fromModalVisible:false, //from表单
        empModalVisible:false,// 排班情况
        orderModalVisible:false,// 订单
        refresh:true, //更新页面
        employeeList: [], //员工列表
        list:[],
        service:null,
        storeService:[], //店铺服务
        storeGoods:[], //店铺商品
        openOrderList:[], //员工，手牌，房间 订单
        selectOrderList:[], //选择的员工，手牌，房间 订单
        openOrderIds:[], //轮牌单Id
        roleData: [], //岗位列表
        empSortList:[],// 轮牌员工列表
        shiftData: {},
        shiftBorder: { shiftBoards: [], shiftConsumptionItems: [] },//轮牌排序
        sdiList:[],// 手牌列表
        roomList:[],// 房间列表
        mode:'1', // 1员工，2手牌，3房间
        fromMode:'0', //from表单 1员工，2手牌，3房间
        detail:SPreOrder(),
        detailItem:0, //选中的item下标
        selectItem:{},//选中的项目
        submitState:0, //结算状态 1：正常结算 2：保存订单
        selectRoom:{},// 手牌，房间 数据
        kind:0, //订单种类
    }
}

const initialState = initData();

const reducer = (state = initialState, action) => {
    let  {openOrderList,refresh,silverVisible,detail} = state;
    switch (action.type) {
        case UPDATE: 
            return {
                ...state,
                ...action.data
            };
        case INIT_SILVER_CONFIG: 
            return {
                ...state,
                list:[],
                empSortList:[],
                openOrderList:[], //开单选中的服务和商品
                fromModalVisible:false,
                empModalVisible:false,// 排班情况
                detail:SPreOrder(),
                submitState:0, //结算状态 1：正常结算 2：保存订单
                mode:'1',
                fromMode:'0',
                selectRoom:{},// 手牌，房间 数据
                detailItem:0, //选中的item下标
                selectItem:{},//选中的项目
            };
        case CLEAR_DETAIL:
                return {
                    ...state,
                     detail:SPreOrder(),
                     fromMode:'0',
                     detailItem:0, //选中的item下标
                    selectItem:{},//选中的项目
                    selectRoom:{},// 手牌，房间 数据
                };
        case SUBMIT:
            if(action.data === 1){
                silverVisible = true;
            }
            return {
                ...state,
                submitState:action.data,
                silverVisible
            };
        case SET_SERVICE:
            return {
                ...state,
                service:action.data
            };
        case STORE_SERVICE:
            return {
                ...state,
                storeService:action.data,
            };
        case STORE_GOODS:
            return {
                ...state,
                storeGoods:action.data
            };
        case ADD_ORDER:
            return {
                ...state,
                openOrderList:action.data,
                refresh:!refresh
            };
        case DEL_ORDER:
            openOrderList.splice(action.data,1);
            return {
                ...state,
                refresh:!refresh
            };
        case DEL_GOODS:
            detail.Items.splice(action.data,1);
            return {
                ...state,
                detail,
                refresh:!refresh
            };
        case FROM_MODAL_VISIBLE:
            return {
                ...state,
                fromModalVisible:action.visible
            };
        case EMP_MODAL_VISIBLE:
            return {
                ...state,
                empModalVisible:action.data
            };
        case SILVER_VISIBLE:
            return {
                ...state,
                silverVisible:action.data
            };
        case SET_MODE:
            return {
                ...state,
                openOrderList:[],
                mode:action.data
            };
        case SET_FROM_MODE:
            return {
                ...state,
                fromMode:action.data
            };
        case INIT_DATA:
            return {
                ...state,
                ...action.data
            };
        case INIT_EMP_SORT_DATA:
        case INIT_SDI_DATA:
        case INIT_ROOM_DATA:
            return {
                ...state,
                ...action.data
            };
        
        case SET_DETAIL:
            return {
                ...state,
                 detail:{...action.data},
                refresh:!refresh
            };
        case SET_DETAIL_ITEM:
            return {
                ...state,
                 detailItem:action.data,
            };
        default:
            return state;

    }
}

export default reducer;