import React from "react";
import "../../../../css/settingsShopInfo.css";
import { Button, Dropdown, Icon, Input, Menu, Modal } from "antd";
import GaodeMap from "../../../../js/components/gaodeMap";
import { setDefaultTitle } from "../../../../js/reduxs/reducers/iconRedux";
import { updataCompany } from "../../../../js/reduxs/reducers/shopSettingRedux";
import StorageTools from "../../../../js/comm/storageTools";
import * as utils from "../../../../js/comm/utils";
import City from "../../../../js/comm/city";
import {
  hideLoadding,
  messageError,
  messageSuccess,
  showLoadding,
  showMessageError
} from "../../../../js/comm/utils";
import APINames from "../../../../js/comm/api-names";
import { FnMenuId } from "../../../../js/comm/constans";
import { connect } from "react-redux";
import { $mzlApi } from "../../../../js/comm/mzl-core";
import { createQrCodeImg } from "../../../../js/comm/wxqrcode";
import { IModal } from "../../../../js/components/iModal";
import CitySelect from '../../../../js/components/citySelect'
var stepStoreInfo = "{}";
const { TextArea } = Input;
var timeOut;
let mchCode = '';


/**
 * 店铺基本信息
 */
@connect(
  state => state.iconRedux,
  { setDefaultTitle }
)

//企业基本信息
@connect(
  state => state.shopSetting,
  { updataCompany }
)

class ShopInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      isEdit: false,
      categoryList: [],
      storeInfo: {},
      userInfo:StorageTools.getUserInfo(),
      DropdownVisible: false,
      mchMerchant:{},
      DropdownCity:false,
      DropdownCityName:'',
      DropdownArea:false,
      DropdownAreaName:'',
      selectedValues:[],
      defaultCity:[],
      Phone:'-',
      loading:false,

    };
  }

  componentWillMount() {
    
    mchCode = StorageTools.getMchCode()
    this.props.setDefaultTitle({
      titleKey: FnMenuId.head_chain_setting,
      slidingLeftKey: "1"
    });
  }

  componentDidMount() {
    // debugger
    // //console.log(JSON.parse(StorageTools.getMchCode()))
    // ////console.log('检查切换状态',this.props.location.pathname)
    // if (this.props.location.pathname=="/home/newSettingContent/shopInfo") {
    // this.setState({isEdit:true})
    // }else {
    //     this.setState({isEdit:false})
    // }
    //console.log(this.props)
    this.requestCategoryList();
  }

  /**
   * 获取企业信息
   */
  requestGetStore() {
    var map = new Map();
    map.set("mchCode", mchCode);
    $mzlApi.post(
      APINames.API_getMchMerchant,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 获取企业类型列表
   */
  requestCategoryList() {
    showLoadding();
    var map = new Map();
    $mzlApi.post(
      APINames.API_getTradeClasses,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  //获取企业列表名字
  queryEnterprisename(id){
    let obj;
    const{categoryList} = this.state;
    categoryList.map((item,index)=>{
      if(item.ClassId == id){
        obj = item.Name
      }
    })
    return obj?obj : ''
  }

  /**
   * 保存企业信息
   */
  requestSaveStore() {
    //console.log(111,this.state.mchMerchant)
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("merchantRegister", JSON.stringify(this.state.mchMerchant));
    $mzlApi.post(
      APINames.API_editMerchant,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 请求上传店铺位置
   * @param storeCode 店铺编码
   * @return 拉取请求结果
   * @throws TException
   */
  requestStorePosition() {
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    $mzlApi.post(
      APINames.API_requestStorePosition,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 监听已上传的店铺位置信息
   * @param storeCode 店铺编码
   * @return 拉取请求结果
   * @throws TException
   */
  requestListenPostedStorePosition() {
    // ////console.log(111111)
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    map.set("pullKey", this.state.PullKey);
    $mzlApi.post(
      APINames.API_listenPostedStorePosition,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  httpSuccess(res, tag) {
    hideLoadding();
    ////console.log('httpSuccess: ', res)
    switch (tag) {
      case APINames.API_getMchMerchant:
        if(res && res.Data && res.Data.mchMerchant){
          let obj = {str:'',arrIndex:0};
          const shopInfomchMerchant = JSON.parse(res.Data.mchMerchant)
          //console.log(shopInfomchMerchant)
          this.state.mchMerchant.CompanyName = shopInfomchMerchant.CompanyName
          this.state.mchMerchant.CompanyPhone = shopInfomchMerchant.CompanyPhone
          this.state.mchMerchant.CompanyType = shopInfomchMerchant.CompanyType
          this.state.mchMerchant.CompanyTypeName = this.queryEnterprisename(shopInfomchMerchant.CompanyType)
          this.state.mchMerchant.Address = shopInfomchMerchant.Address
          this.state.mchMerchant.Phone = shopInfomchMerchant.Phone//区

          this.state.mchMerchant.Province = shopInfomchMerchant.Province//省
          this.state.mchMerchant.City = shopInfomchMerchant.City//市
          this.state.mchMerchant.District = shopInfomchMerchant.District//区
          this.state.defaultCity[0] = shopInfomchMerchant.Province +''
          this.state.defaultCity[1] = shopInfomchMerchant.City + ''
          this.state.defaultCity[2] = shopInfomchMerchant.District + ''


          this.queryCityName(City,this.state.defaultCity,obj)
          this.state.Phone = res.Data.OwnerPhone

          obj.str += shopInfomchMerchant.Address
          this.state.mchMerchant.detailAddress = obj.str
          StorageTools.saveMchName(shopInfomchMerchant.CompanyName)
          
          //保存企业名字
          this.props.updataCompany({
            companyName:shopInfomchMerchant.CompanyName
          });
          
        }
        this.setState({mchMerchant:this.state.mchMerchant,defaultCity:this.state.defaultCity,Phone:this.state.Phone})
        break;
      case APINames.API_getTradeClasses:
        hideLoadding();
        // var categoryList = JSON.parse(res.Data)
        var categoryList = res;
        //console.log("123", categoryList)
        this.setState({
          categoryList: categoryList
        });
        ////console.log('categoryList: ', categoryList)
        this.requestGetStore();
        break;
      case APINames.API_requestStorePosition:
        hideLoadding();
        var path = `http://ms.sixsix.shop/h5/map/gaodeMap/${JSON.stringify({
          key: res.PullKey,
          storeCode: StorageTools.getUserCode()
        })}`;
        ////console.log("path:", path)
        var data = createQrCodeImg(path, { size: 260 });
        this.setState({
          imagePath: data,
          PullKey: res.PullKey,
          isCodeModal: true
        });
        var that = this;
        timeOut = window.setInterval(() => {
          that.requestListenPostedStorePosition();
        }, 1000); //使用字符串执行方法
        break;
      case APINames.API_listenPostedStorePosition:
        this.state.storeInfo.detailAddress = res.detailAddress;
        this.state.storeInfo.City = res.City;
        this.state.storeInfo.District = res.District;
        this.state.storeInfo.Province = res.Province;
        this.state.storeInfo.Lat = res.Lat;
        this.state.storeInfo.Lng = res.Lng;
        window.clearInterval(timeOut);
        this.setState({
          isCodeModal: false
        });
        break;
      case APINames.API_editMerchant:
        //保存企业名字
        this.props.updataCompany({
          companyName:this.state.mchMerchant.CompanyName
        });
        //console.log(this.props)
        this.requestGetStore()
        this.setState({loading:false});
        // if (res.ErrCode == 0) {
        //   hideLoadding();
        //   messageSuccess("保存成功！");
        //   StorageTools.saveStoreName(this.state.storeInfo.Name);
        //   // if (StorageTools.getStoreName()) {
        //   // } else {
        //   //     ////console.log('1111',window)
        //   //     window.history.push('/home/settingContent/postSetting')
        //   // }
        // }
    }
  }

  httpFail(res, tag) {
    this.setState({loading:false});
    hideLoadding();
    switch (tag) {
      case APINames.API_listenPostedStorePosition:
        break;
      default:
        // showMessageError(res, tag);
        ////console.log('失败',res,tag)
        break;
    }
  }

  showAddress() {
    let that = this;
    Modal.info({
      title: "请选择地址",
      width: "1020px",
      okText: "确定",
      okType: "default",
      content: (
        <div style={{ height: "750px" }}>
          <GaodeMap addAddress={that.addAddress.bind(that)} />
        </div>
      ),
      onOk() {}
    });
  }

  addAddress(res) {
    this.state.storeInfo.detailAddress = res.address;
    this.state.storeInfo.City = res.city;
    this.state.storeInfo.City = res.city;
    this.state.storeInfo.District = res.district;
    this.state.storeInfo.Lat = res.lat;
    this.state.storeInfo.Lng = res.lng;
    this.state.storeInfo.Province = res.province;
    this.setState(this);
    ////console.log('保存地址', this.state)
  }

  /**
   * 输入框回调
   * @param tag
   * @param res
   */

  // this.state.mchMerchant.CompanyName = shopInfomchMerchant.CompanyName
  // this.state.mchMerchant.CompanyPhone = shopInfomchMerchant.CompanyPhone
  // this.state.mchMerchant.CompanyType = shopInfomchMerchant.CompanyType
  // this.state.mchMerchant.CompanyTypeName = this.queryEnterprisename(shopInfomchMerchant.CompanyType)
  
  // this.state.mchMerchant.Address = shopInfomchMerchant.Address


  onChangeListener(tag, res) {
    switch (tag) {
      case "CompanyName":
        this.state.mchMerchant.CompanyName = res.target.value.trim();
        break;
      case "Address":
          this.state.mchMerchant.Address = res.target.value.trim();
        break;
      case "CompanyPhone":
        // //console.log(res.target.value)
        // const reg =  /(\d|-)+/;//数字和 '-'
        // if(reg.test(res.target.value)){
        //   this.state.mchMerchant.CompanyPhone = res.target.value.trim();
        // }
        this.state.mchMerchant.CompanyPhone = res.target.value.trim();
        break;
      case "OwnerName":
          this.state.mchMerchant.OwnerName = res.target.value;
        break;
    }
    this.setState(this);
    ////console.log(tag, this.state);

    // this.setState({
    //     [tag]: res.target.value
    // })
  }

  /**
   * 点击事件分类
   * @param tag
   */
  onClickListener(tag, e) {
    ////console.log(tag)
    switch (tag) {
      case "edit": //编辑
        this.requestGetStore();
        // stepStoreInfo = JSON.stringify(this.state.storeInfo)
        this.setState({
          isEdit: true
        });
        break;
      case "cancel": //取消
        // this.requestGetStore();
        this.setState({
          isEdit: false
          // storeInfo: JSON.parse(stepStoreInfo)
        });
        break;
      case "confirm": //确定
      const reg =  /(\d|-)+/;
        if (!this.state.mchMerchant.CompanyName) {
          messageError("请输入企业名称");
          return;
        }
        if (!this.state.mchMerchant.CompanyPhone) {
          messageError("请输入企业电话");
          return;
        }
        if(!reg.test(this.state.mchMerchant.CompanyPhone)){
          messageError("请输入正确的企业电话");
          return;
        }
          

        if (!this.state.mchMerchant.CompanyType) {
          messageError("请选择企业类型");
          return;
        }
        if (!this.state.mchMerchant.CompanyType) {
          messageError("请选择企业类型");
          return;
        }
        // if(!this.state.mchMerchant.Province){
        //   messageError("请选择所在地区");
        //   return;
        // }
        // if(!this.state.mchMerchant.Address){
        //   messageError("请选择详细地址");
        //   return;
        // }

        // if(this.state.storeInfo.detailAddress == null || this.state.storeInfo.detailAddress == ""){
        //     messageError("请输入店铺地址！")
        //     return;
        // }
        
        ////console.log(this.state.storeInfo);
        //防止暴力点击
        if (!utils.getReqeustState()) {
          return;
        }
        this.setState({loading:true});
        this.requestSaveStore();
        this.setState({
          isEdit: false
        });
        break;
      case "上次地址":
        this.requestStorePosition();
        showLoadding();
        break;
      case "二维码确定":
        window.clearInterval(timeOut);
        this.setState({
          isCodeModal: false
        });
        break;
      case "二维码取消":
        window.clearInterval(timeOut);
        this.setState({
          isCodeModal: false
        });
        break;
      case "DropdownVisible-app":
        this.setState({
          DropdownVisible: true
        });
        break;
      case "DropdownVisible":
        this.setState({
          DropdownVisible: e
        });
        break;
     case "DropdownCity":
        this.setState({
            DropdownCity: e
        });
        break;
        case "DropdownCity-app":
        this.setState({
            DropdownCity: true
        });
        break;
      case 'DropdownArea':
          this.setState({DropdownArea:e})
          break;
      case 'DropdownArea-app':
            this.setState({DropdownArea:true})
          break;
    }
  }

  /**
   * 获取店铺类型
   */
  getCategoryName(id) {
    ////console.log('this.state.categoryList', id, this.state.categoryList)
    if (this.state.categoryList != null && this.state.categoryList.length > 0) {
      for (var i = 0; i < this.state.categoryList.length; i++) {
        let item = this.state.categoryList[i];
        if (item.ClassId == id) {
          return item.Name;
        }
      }
      return "";
    }
  }

  onClickMenu({ key }) {
    
    const item = this.state.categoryList[key];
     this.state.mchMerchant.CompanyType = item.ClassId
     this.state.mchMerchant.CompanyTypeName =item.Name
    this.setState({
      categoryName: item.Name,
      mchMerchant:this.state.mchMerchant,
      DropdownVisible:false
    });
  }
  onClickMenuCity(name){
      this.setState({DropdownCityName:'北京'})
  }
  onClickMenuArea(){
    this.setState({DropdownAreaName:'西城区'})
  }
   // 获取选择值
   getSelectedValues = selectedValues => {
    //console.log(222233,selectedValues)
    this.state.mchMerchant.Province = selectedValues[0].value//省
          this.state.mchMerchant.City = selectedValues[1]?selectedValues[1].value : 0//市
          this.state.mchMerchant.District = selectedValues[2]?selectedValues[2].value : 0//区
    this.setState({
        selectedValues,
        mchMerchant:this.state.mchMerchant
    });
};
queryCityName(cityArr,arrThree,obj){
  cityArr.map((item,index)=>{
    //省
    if(item.value == arrThree[obj.arrIndex]){
      obj.str += item.label
      obj.arrIndex ++ 
      //市
      if(item.children && item.children.length){
      return this.queryCityName(item.children,arrThree,obj)
      }
    }
  })
}



  render() {
    const {loading} = this.state.loading
    const categoryView = this.state.categoryList.length
      ? this.state.categoryList.map((item, index) => (
          <Menu.Item key={index}>{item.Name}</Menu.Item>
        ))
      : null;
    const menu = (
      <Menu onClick={this.onClickMenu.bind(this)}>{categoryView}</Menu>
    );

    // const city = <Menu.Item key={1}>北京</Menu.Item>
       
    // const menuCity = (
    //   <Menu onClick={this.onClickMenuCity.bind(this)}>{city}</Menu>
    // );
    // const area = <Menu.Item key={1}>西城区</Menu.Item>
    // const menuarea =(
    //     <Menu onClick={this.onClickMenuArea.bind(this)}>{area}</Menu>
    // )
   
    return (
      <div class=" gra-border-pd15 comm-h">
        {this.state.isEdit ? (
          <div className="comm-col">
            <span className="comm-font-16 " style={{fontWeight:'bold'}}>企业信息</span>
            
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333" style={{ paddingRight: "10px" }}>
                <span className="comm-text-red">*</span>企业名称
              </span>
              <Input
                type="text"
                style={{ width: "250px" }}
                maxLength={12}
                value={this.state.mchMerchant.CompanyName}
                onChange={this.onChangeListener.bind(this, "CompanyName")}
                maxLength={16}
              />
            </div>
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333" style={{ paddingRight: "10px" }}>
                <span className="comm-text-red">*</span>企业电话
              </span>
              <Input
                // type="number"
                style={{ width: "250px" }}
                value={this.state.mchMerchant.CompanyPhone}
                maxLength={11}
                onChange={this.onChangeListener.bind(this, "CompanyPhone")}
              />
            </div>
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333" style={{ paddingRight: "10px" }}>
                <span className="comm-text-red">*</span>企业类型
              </span>
              <Dropdown
                overlay={menu}
                visible={this.state.DropdownVisible}
                onVisibleChange={this.onClickListener.bind(
                  this,
                  "DropdownVisible"
                )}
              >
                <div
                  onClick={this.onClickListener.bind(
                    this,
                    "DropdownVisible-app"
                  )}
                  className="downStyle"
                >
                {this.state.mchMerchant.CompanyTypeName}{" "}
                  <Icon className="downIcon" type="down" />
                </div>
              </Dropdown>
            </div>

            <div
              className="comm-row comm-item-center comm-padding-top-18 twoInput"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333" style={{ paddingRight: "10px" }}>
                <span className="comm-text-red">*</span>所在地区
              </span>
              <div className='shopInfocityselect'><CitySelect
            getSelectedValues={this.getSelectedValues}
            value={this.state.defaultCity}
            /></div>

              
            </div>



            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333" style={{ paddingRight: "10px" }}>
                <span className="comm-text-red">*</span>详细地址
              </span>
              <div className="comm-row" style={{ width: "390px" }}>
                <TextArea
                  disabled={false}
                  style={{ width: "250px" }}
                  onChange={this.onChangeListener.bind(this, "Address")}
                  rows={2}
                  value={this.state.mchMerchant.Address}
                />
                
              </div>
            </div>
            <span
              className="comm-font-16 "
              style={{ paddingTop: "30px",fontWeight:'bold' }}
            >
              管理员信息
            </span>
            {/* <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333" style={{ paddingRight: "10px" }}>
                <span className="comm-text-red">*</span>姓名
              </span>
              <Input
                type="text"
                style={{ width: "250px" }}
                value={this.state.storeInfo.OwnerName}
                maxLength={16}
                onChange={this.onChangeListener.bind(this, "OwnerName")}
              />
            </div> */}
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333" style={{ paddingRight: "10px" }}>
                <span className="comm-text-red">*</span>手机
              </span>
              <span>{ this.state.Phone}</span>
            </div>
            <div className="btn-edit-view">
              <Button onClick={this.onClickListener.bind(this, "cancel")}>
                取消
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 20 }}
                onClick={this.onClickListener.bind(this, "confirm")}
                loading={loading}
              >
                <span style={{ color: "#fff" }}>确定</span>
              </Button>
            </div>
          </div>
        ) : (
          <div className="comm-col">
            <div className="edit-view">
              <span className="comm-font-16 spanstyle-shopinfo" style={{fontWeight:'bold'}}>企业信息</span>
              <Button
                className="btn-edit"
                onClick={this.onClickListener.bind(this, "edit")}
              >
                编辑
              </Button>
            </div>

            
            
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333 spanstyle-shopinfo" style={{ paddingRight: "10px" }}>
                企业名称
              </span>
              <span style={{ paddingLeft: "20px" }}>
                {this.state.mchMerchant.CompanyName}
              </span>
            </div>
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333 spanstyle-shopinfo" style={{ paddingRight: "10px" }}>
                企业电话
              </span>
              <span style={{ paddingLeft: "20px" }}>
                {this.state.mchMerchant.CompanyPhone}
              </span>
            </div>
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333 spanstyle-shopinfo" style={{ paddingRight: "10px" }}>
                企业类型
              </span>
              <span style={{ paddingLeft: "20px" }}>
                {this.state.mchMerchant.CompanyTypeName}
              </span>
            </div>
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333 spanstyle-shopinfo" style={{ paddingRight: "10px" }}>
                店铺地址
              </span>
              <span style={{ paddingLeft: "20px" }}>
                { this.state.mchMerchant.detailAddress}
              </span>
            </div>

            <span
              className="comm-font-16  comm-padding-top-18"
              style={{ paddingTop: "30px",fontWeight:'bold'  }}
            >
              管理员信息
            </span>
            {/* <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333 spanstyle-shopinfo" style={{ paddingRight: "10px" }}>
                姓名
              </span>
              <span style={{ paddingLeft: "20px" }}>
                {this.state.storeInfo.OwnerName}
              </span>
            </div> */}
            <div
              className="comm-row comm-item-center comm-padding-top-18"
              style={{ paddingLeft: "20px" }}
            >
              <span className="comm-text333 spanstyle-shopinfo" style={{ paddingRight: "10px" }}>
                手机
              </span>
              <span style={{ paddingLeft: "20px" }}>
                { this.state.mchMerchant.Phone}
              </span>
            </div>
          </div>
        )}

        <IModal
          onOk={this.onClickListener.bind(this, "二维码确定")}
          onCancel={this.onClickListener.bind(this, "二维码取消")}
          width={350}
          visible={this.state.isCodeModal}
          title={"扫描二维码上传地址"}
          view={
            <div className="comm-col comm-item-center">
              <img
                className="comm-border-solid-radius-5px"
                style={{ width: 260, height: 260 }}
                src={this.state.imagePath}
              ></img>
            </div>
          }
        ></IModal>
      </div>
    );
  }
}

export default ShopInfo;
