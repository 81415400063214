import axios from './axiosInstance';
import api from './api-config';
import {ApiConfig} from '../comm/constans';


export const httpLogin = {
    login (params) {
        return axios.get(ApiConfig.make.url+api.login, {
            params: params
        });
    }
}

export const http = {
    get (url,params) {
        return axios.get(url, {
            params: params
        });
    },
    post(url,params) {
        return axios.post(url, {
            params: params
        });
    },
}


/**
 * 扫码付
 */
export const httpPay={
    add(params){
        return axios.get(ApiConfig.pay.url+api.add,{
        // return axios.get('http://mzl-api.dev.sixsix.shop/prod/mzl/v2.0',{
            params: params
        });
    },
    imgChange(params){
        return axios.get(ApiConfig.pay.url+api.img,{
            params: params
        });
    },
}

/**
 * 激活
 */
export const httpStage={
    get(url,params,httpSuccess,httpFali){
         axios.get(ApiConfig.stage.url+url,{params:params})
        .then(res=>{
            httpSuccess(res)
        });
    },
    add(params){
        return axios.get(ApiConfig.stage.url+api.add,{
            params: params
        });
    },
    imgChange(params){
        return axios.post(ApiConfig.pay.url+api.img,{
            params: params
        });
    },
    // imgChange(params){
    //     return axios.post(ApiConfig.pay.url+api.img, params);
    // }
}



/**
 * 预约模块
 */
export const httpAgree = {
    // post提交
    login1(apiName,params) {
        return axios.post(ApiConfig.make.url+`${apiName}`, params);
    },
    login (apiName,params) {
        return axios.get(ApiConfig.make.url+`${apiName}`, {
            params: params
        });
    },
    
    httpGet (url,params) {
        return axios.get(url, {
            params: params
        });
    },
    httpPost(url,params) {
        return axios.post(url, {
            params: params
        });
    },
    shopSettingSelect(params){//查询门店预约设置
        return axios.get(ApiConfig.make.url+api.shopSettingSelect, {params:params});
    },
    shopSettingSave(params){//修改门店预约设置
        return axios.post(ApiConfig.make.url+api.shopSettingSave, params);
    },
    selectByStoreCode(params){//查询门店可以预约项目
        return axios.get(ApiConfig.make.url+api.selectByStoreCode, {params: params});
    },
    spSelectByStroeCode(params){//根据店铺code和用户Id查询预约状态以及项目
        return axios.get(ApiConfig.make.url+api.spSelectByStroeCode, {params: params});
    },
    soSelect(params){//根据stroeCode&userId&查询预约
        return axios.get(ApiConfig.make.url+api.soSelect, {params: params});
    },
    shopOrderSave(params){//新增店铺预约
        return axios.post(ApiConfig.make.url+api.shopOrderSave,params);
    },
    shopOrderUpdate(flag,params){//修改预约状或预约信息
        return axios.post(ApiConfig.make.url+api.shopOrderUpdate+"/"+flag,params);
    },
    shopOrderUpdateStatus(params){//占用或者不占用
        return axios.post(ApiConfig.make.url+api.shopOrderUpdateStatus ,params);
    }

}