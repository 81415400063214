import {combineReducers} from 'redux'
import {shopSetting} from "./reducers/shopSettingRedux";
import {iconRedux} from "./reducers/iconRedux";
import {chainConsume} from "../../chain/setting/chainConsume/reducers/index";
import silverConfig from "@js/silver/silverConfig/reducer";
import goodsData from "@js/goods/reducer";
import goodsManageData from "@js/goods/goodsManage/reducer";

// import * as reducers from '@reducers'
export default combineReducers(
    {
     shopSetting,
     iconRedux,
     chainConsume,
     silverConfig,
     goodsData,
     goodsManageData
    })