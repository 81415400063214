
export const SUBMIT = 'GOODS_MANAGEUP_SUBMIT'
export const UPDATE = 'GOODS_MANAGEUP_DATE'; //填写客户项目
export const GOODS_MANAGE_MODE = 'GOODS_MANAGE_MODE'; 

//初始化
export function update(data) {
    return {type: UPDATE,data:data}
}

//from提交
export function submit(data) {
    
    return {type: SUBMIT,data:data}
}


export function goodsManageMode(data) {
    return {type: GOODS_MANAGE_MODE,data:data}
}








