import { $mzlApi } from '@comm/mzl-core';
import BaseService from '@src/base/baseService/baseService';




class service extends BaseService {

    constructor() {
        super();
    }


    
     /**
     * 获取商品列表
     * @param {*} that 
     * @param {*} employeeList 
     * @param {*} roleData 
     */
    queryPmsGoods(that,useType,begin,over,keyword,goodsType,enabled,storeCode){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode',storeCode||t.storageTools.getUserCode());
            map.set("mchCode", t.storageTools.getMchCode());
            map.set('useType', useType);
            map.set('opt', JSON.stringify({Begin: begin, Over: over,Opt:{keyword:keyword,goodsType:goodsType,enabled:enabled}}));
            t.$mzlApi.post(t.APINames.API_inventory_queryPmsGoods, map,
                (res) => {
                    // t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
     
    /**
     * 获取商品详情
     * @param {*} that 
     * @param {*} employeeList 
     * @param {*} roleData 
     */
    getPmsGoods(that,useType,goodsId){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode',t.storageTools.getUserCode());
            map.set("mchCode", t.storageTools.getMchCode());
            map.set('useType', useType);
            map.set('goodsId', goodsId);
            t.$mzlApi.post(t.APINames.API_inventory_getPmsGoods, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    /**
     * 保存商品
     * @param {*} that 
     * @param {*} employeeList 
     * @param {*} roleData 
     */
    savePmsGoods(that,pmsGoods){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set('pmsGoods', JSON.stringify(pmsGoods));
            t.$mzlApi.post(t.APINames.API_inventory_savePmsGoods, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

    /**
     * 删除商品
     * @param {*} that 
     * @param {*} employeeList 
     * @param {*} roleData 
     */
    deletePmsGoods(that,useType,goodsId){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode',t.storageTools.getUserCode());
            map.set("mchCode", t.storageTools.getMchCode());
            map.set('useType', useType);
            map.set('goodsId', goodsId);
            t.$mzlApi.post(t.APINames.API_inventory_deletePmsGoods, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    /**
     * 商品启用和停用功能
     * @param {*} that 
     * @param {*} employeeList 
     * @param {*} roleData 
     */
    updatePmsGoodsEnable(that,useType,goodsId,enable){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode',t.storageTools.getUserCode());
            map.set("mchCode", t.storageTools.getMchCode());
            map.set('useType', useType);
            map.set('goodsId', goodsId);
            map.set('enable', enable);
            t.$mzlApi.post(t.APINames.API_inventory_updatePmsGoodsEnable, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }


    
    //获取供应商
    querySupplier(that,useType,begin,over,keyword) {
        this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set("storeCode", t.storageTools.getUserCode());
            map.set("useType", useType);
            map.set("opt", JSON.stringify({ Begin: begin, Over: over,Opt:{keyword}}));
            t.$mzlApi.post(t.APINames.API_inventory_querySupplier, map,
                (res) => {
                    t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    //保存供应商
    saveSupplier(that,supplier) {
        this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("supplier", JSON.stringify(supplier));
            t.$mzlApi.post(t.APINames.API_inventory_saveSupplier, map,
                (res) => {
                    t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

     //删除供应商
    deleteSupplier(that,useType,supplierId) {
        this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set("storeCode", t.storageTools.getUserCode());
            map.set("supplierId", supplierId);
            map.set("useType", useType);
            t.$mzlApi.post(t.APINames.API_inventory_deleteSupplier, map,
                (res) => {
                    t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
      /**
        * 获取商品参数
        * 类别 1  单位 2  入库类型 3  出库类型 4
        */
     queryPmsGoodsParameter(that,useType,parameterType) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set("storeCode", t.storageTools.getUserCode());
            map.set("useType", useType);
            map.set("parameterType", parameterType);
            t.$mzlApi.post(t.APINames.API_inventory_queryPmsGoodsParameter, map,
                (res) => {
                    // t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

     // 保存商品参数
     savePmsGoodsParameter(that,parameter) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("parameter",JSON.stringify(parameter));
            t.$mzlApi.post(t.APINames.API_inventory_savePmsGoodsParameter, map,
                (res) => {
                    // t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

    // 删除商品参数
    deletePmsGoodsParameter(that,useType,parameterId) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set("storeCode", t.storageTools.getUserCode());
            map.set("useType", useType);
            map.set("parameterId", parameterId);
            t.$mzlApi.post(t.APINames.API_inventory_deletePmsGoodsParameter, map,
                (res) => {
                    // t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    /**
     * 获取商品出出入库统计详情
    *CreateType 创建方式  1 入库  2 出库
    **/
    queryPmsGoodsApplication(that,useType,begin,over,beginTime, overTime ,keyword , createType ,state ,applicationTypeId) {
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set("storeCode", t.storageTools.getUserCode());
            map.set("useType", useType);
            map.set("opt", JSON.stringify({ Begin: begin, Over: over,Opt:{beginTime, overTime ,keyword,createType ,state ,applicationTypeId}}));
            t.$mzlApi.post(t.APINames.API_inventory_queryPmsGoodsApplication, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }






    /**
     * 获取提成人员和岗位
     * @param {*} that 
     * @param {*} employeeList 
     * @param {*} roleData 
     */
    getAwardEmp(that){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            t.getEmployeeLis(that).then(function(res){
                return t.getStoreRole(that,res)
            }).then(function(res){
                resolve(res)
            })
        })
        return p;
    }
  
     /**
     * 获取会员卡类型
     * @param {*} that 
     * @param {*} employeeList 
     * @param {*} roleData 
     */
    requestCardType(that){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode',t.storageTools.getUserCode());
            map.set('kind', 1);
            map.set('opt', JSON.stringify({Begin: 0, Over: 999}));
            t.$mzlApi.post(t.APINames.API_queryMchCardList, map,
                (res) => {
                    t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

  

   
    /**
     * 保存商品出入库申请
     */
    savePmsGoodsApplication(that,application){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("application", JSON.stringify(application));
            t.$mzlApi.post(t.APINames.API_inventory_savePmsGoodsApplication, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }


    /**
     * 修改商品出入库申请状态
     */
    updatePmsGoodsApplicationState(that,useType,state,application){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("application", JSON.stringify(application));
            map.set("useType", useType);
            map.set("state", state);
            t.$mzlApi.post(t.APINames.API_inventory_updatePmsGoodsApplicationState, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    /**
     * 获取商品出入库申请详情
     */
    getPmsGoodsApplication(that,useType,applicationId){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set("applicationId", applicationId);
            
            t.$mzlApi.post(t.APINames.API_inventory_getPmsGoodsApplication, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    /**
     * 获取商品预警
     */
    queryPmsGoodsWarning(that,useType,begin,over,goodsType){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set('opt', JSON.stringify({Begin: begin, Over: over,Opt:{goodsType}}));
            t.$mzlApi.post(t.APINames.API_inventory_queryPmsGoodsWarning, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    /**
     * 获取商品出出入库统计
     */
    queryPmsGoodsApplicationLog(that,useType,begin,over, beginTime ,overTime,createType,goodsTypeId , applicationTypeId , keyword){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set('opt', JSON.stringify({Begin: begin, Over: over,Opt:{beginTime ,overTime,createType,goodsTypeId, applicationTypeId , keyword}}));
            t.$mzlApi.post(t.APINames.API_inventory_queryPmsGoodsApplicationLog, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }


     /**
     * 获取商品出出入库统计详情
     */
    queryPmsGoodsApplicationLogDetails(that,useType,begin,over , beginTime ,overTime,createType,goodsTypeId , applicationTypeId , goodsId){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set('opt', JSON.stringify({Begin: begin, Over: over,Opt:{beginTime ,overTime,createType,goodsTypeId, applicationTypeId , goodsId}}));
            t.$mzlApi.post(t.APINames.API_inventory_queryPmsGoodsApplicationLogDetails, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }


     /**
     * 获取商品转运申请列表
     */
    queryPmsGoodsTransshipment(that,useType,begin,over,beginTime,overTime,state,keyword){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set('opt', JSON.stringify({Begin: begin, Over: over,Opt:{beginTime ,overTime, state,keyword}}));
            t.$mzlApi.post(t.APINames.API_inventory_queryPmsGoodsTransshipment, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

    //保存商品转运申请
    savepmsGoodsTransshipment(that,transshipment){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("transshipment", JSON.stringify(transshipment));
            t.$mzlApi.post(t.APINames.API_inventory_savepmsGoodsTransshipment, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }


    
    // 通过项目id获取所有可转运的同商户商品
    getPmsGoodsTransshipmentItemByTemplateId(that,useType,templateId){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set("templateId", templateId);
            t.$mzlApi.post(t.APINames.API_inventory_getPmsGoodsTransshipmentItemByTemplateId, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

     /**
     * 获取商品转运申请详情
     */
    getPmsGoodsTransshipment(that,useType,transshipmentId){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set('transshipmentId', transshipmentId);
            t.$mzlApi.post(t.APINames.API_inventory_getPmsGoodsTransshipment, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    
     /**
     * 获取商品转运申请列表进度条
     */
    queryPmsGoodsTransshipmentLog(that,useType,transshipmentNo){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set('transshipmentNo', transshipmentNo);
            t.$mzlApi.post(t.APINames.API_inventory_queryPmsGoodsTransshipmentLog, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    //申请详情的出入库统计
    getPmsGoodsApplicationByApplicationNo(that,applicationNo){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            // if(that.props.mode==="mch"){
            //     map.set("mchCode", t.storageTools.getMchCode());
            // }else{
            //     map.set("mchCode", t.storageTools.getMchCode());
            //     map.set('storeCode',t.storageTools.getUserCode());
            // }
            // map.set("useType", useType);
            map.set("applicationNo", applicationNo);
            t.$mzlApi.post(t.APINames.API_inventory_getPmsGoodsApplicationByApplicationNo, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

       // 修改商品转运申请状态
       updatePmsGoodsTransshipmentState(that,useType,transshipment,state){
        // this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            if(that.props.mode==="mch"){
                map.set("mchCode", t.storageTools.getMchCode());
            }else{
                map.set("mchCode", t.storageTools.getMchCode());
                map.set('storeCode',t.storageTools.getUserCode());
            }
            map.set("useType", useType);
            map.set("transshipment", JSON.stringify(transshipment));
            map.set("state", state);
            t.$mzlApi.post(t.APINames.API_inventory_updatePmsGoodsTransshipmentState, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
       // 通过商户code获取所有可转运的店铺信息
       getPmsGoodsTransshipmentShopByMchCode(that){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            t.$mzlApi.post(t.APINames.API_inventory_getPmsGoodsTransshipmentShopByMchCode, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
       // 通过商户code获取所有可转运的店铺信息
       getPmsGoodsTransshipmentCount(that,useType,createType){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set('storeCode',t.storageTools.getUserCode());
            map.set('useType',useType);
            map.set('createType',createType);
            t.$mzlApi.post(t.APINames.API_inventory_getPmsGoodsTransshipmentCount, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
       
    // 获取商品操作日志
    queryProdGoodsOperatingLog(that,applicationNo){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set('applicationNo',applicationNo);
            t.$mzlApi.post(t.APINames.API_inventory_queryProdGoodsOperatingLog, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }
    // 获取商品操作日志
    queryProdGoodsOperatingLog(that,applicationNo){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("mchCode", t.storageTools.getMchCode());
            map.set('applicationNo',applicationNo);
            t.$mzlApi.post(t.APINames.API_inventory_queryProdGoodsOperatingLog, map,
                (res) => {
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }








    httpFail(that, res, tag) {
        // console.log(22222, 12, that, res, tag)
        this.utils.hideLoadding();
        this.utils.showMessageError(res);
        if(that.state.loading){
            that.setState({
                loading:false
            })
        }
        
    }





}
export default new service();
