
export const SUBMIT = 'SILVER-SUBMIT'

export const SET_SERVICE = "SILVER-SET_SERVICE"
export const FROM_MODAL_VISIBLE = 'SILVER-FROM_MODAL_VISIBLE';
export const STORE_SERVICE = 'SILVER-STORE_SERVICE';
export const STORE_GOODS = 'SILVER-STORE_GOODS';
export const ADD_ORDER = 'SILVER-ADD_ORDER';
export const DEL_ORDER = 'SILVER-DEL_ORDER';
export const DEL_GOODS = 'SILVER-DEL_GOODS';
export const SET_MODE = 'SILVER-SET_MODE';
export const SET_FROM_MODE = 'SILVER-SET_FROM_MODE';
export const EMP_MODAL_VISIBLE = 'SILVER-EMP_MODAL_VISIBLE';
export const SILVER_VISIBLE = 'SILVER-SILVER_VISIBLE';
export const INIT_SILVER_CONFIG = 'SILVER-INIT_SILVER_CONFIG';
export const INIT_DATA = 'SILVER-INIT_DATA'; //初始化轮牌基础数据
export const INIT_EMP_SORT_DATA = 'SILVER-INIT_EMP_SORT_DATA'; //初始化轮牌基础数据
export const INIT_SDI_DATA = 'SILVER-INIT_SDI_DATA'; //初始化手牌数据
export const INIT_ROOM_DATA = 'SILVER-INIT_ROOM_DATA'; //初始化房间数据
export const CLEAR_DETAIL = 'SILVER-CLEAR_DETAIL'; //清楚客户数据
export const SET_DETAIL = 'SILVER-SET_DETAIL'; //填写客户数据
export const SET_DETAIL_ITEM = 'SILVER-SET_DETAIL_ITEM'; //填写客户项目
export const UPDATE = 'SILVER-UPDATE'; //填写客户项目

//初始化
export function update(data) {
    return {type: UPDATE,data:data}
}
//初始化
export function initSilverConfig(data) {    
    return {type: INIT_SILVER_CONFIG,data:data}
}
//清楚客户数据
export function clearDetail(data={}) {
    return {type: CLEAR_DETAIL,data:data}
}
//填写客户数据
export function setDetail(data={}) {
    return {type: SET_DETAIL,data:data}
}
//填写客户项目
export function setDetailItem(data) {
    return {type: SET_DETAIL_ITEM,data:data}
}

//from提交
export function submit(data) {
    
    return {type: SUBMIT,data:data}
}

//设置modal显示状态
export function setFromModalVisible(visible) {
    return {type: FROM_MODAL_VISIBLE,visible:visible}
}
//设置modal显示状态
export function setEmpModalVisible(visible) {
    return {type: EMP_MODAL_VISIBLE,data:visible}
}

//显示收银界面
export function setSilverVisible(data) {
    return {type: SILVER_VISIBLE,data:data}
}



export function setService(service) {
    return {type: SET_SERVICE,data:service}
}


//店铺服务
export function setStoreService(data) {
    return {type: STORE_SERVICE,data:data}
}

//店铺商品
export function setStoreGoods(data) {
    return {type: STORE_GOODS,data:data}
}


//添加商品和服务
export function addOrder(data) {
    return {type: ADD_ORDER,data:data}
}
//删除商品和服务
export function delOrder(data) {
    return {type: DEL_ORDER,data:data}
}
//删除商品和服务
export function delGoods(data) {
    return {type: DEL_GOODS,data:data}
}
//收银状态
export function setMode(data) {
    return {type: SET_MODE,data:data}
}
//收银表单状态
export function setFromMode(data) {
    return {type: SET_FROM_MODE,data:data}
}

//初始化基础数据
export function initData(data) {
    return {type: INIT_DATA,data:data}
}

//初始化轮牌基础数据
export function initEmpSortData(data) {
    return {type: INIT_EMP_SORT_DATA,data:data}
}
//初始化手牌数据
export function initSdiData(data) {
    return {type: INIT_SDI_DATA,data:data}
}
//初始化房间数据
export function initRoomData(data) {
    return {type: INIT_ROOM_DATA,data:data}
}


// export function initNet(this){
//     service.requestQueryItem(this).then(res=>{
//         let data = _this.getqueryItemData(res, Enum.EItemType.Virtual)
//         setStoreService(data)
//     });
//     service.requestGoodsList(this).then(res=>{
//         let data = _this.getqueryItemData(res, Enum.EItemType.Normal)
//         setStoreGoods(data)
//     });
// }









