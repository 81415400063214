//loadable.jsx
import React from 'react';
import Loadable from 'react-loadable';

import {Card} from 'antd';
import LoadingDialog from './js/components/loadingDialog';
// 按需加载组件
export default function withLoadable (comp) {
    return Loadable({
        loader:comp,
        loading:(props)=>{
            if (props.error) {
                return <Card style={{width:"100%",height:"100%"}} >
                   <a onClick={()=>{
                       window.location.reload();
                       // this.props.history.push('/home');
                   }}>加载错误。请刷新</a>
                </Card>;
            } else if (props.timedOut) {
                return <Card style={{width:"100%",height:"100%"}} >
                    <a onClick={()=>{
                        window.location.reload();
                        // this.props.history.push('/home');
                    }}>加载超时。请刷新</a>

                </Card>;
            } else if (props.pastDelay) {
                return <Card  style={{width:"100%",height:"100%"}} >
                <LoadingDialog/>
            </Card>;
            } else {
                return null;
            }
        },
        timeout:10000
    })
}