
import {type} from '../action/index'


const initState = {
  pageList:[]
};



export function chainConsume(state = initState,action) {
  switch(action){
    case type.UPDATE_STATE:
       return{
           ...state
       }
       break;
  }
  return state;
}

// export function chainConsume = (state=initalState,action)=>{
  
// }
// break;
//  switch(action){
//      case type.UPDATE_STATE:
      
//     default:
//         return state
//  } 
// }