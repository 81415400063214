import React from 'react';
import { Drawer } from 'antd';
import IButton from "./IButton";


export default class IDrawer extends React.Component {
    state = { visible: false };

    constructor() {
        super();
        this.state = {
            width: "50%"
        }
    }

    onClose = () => {
        this.props.onClickListener("drawer-cancel", "close");
        // this.props.onClickListener("drawer-close");
    };

    onCancel = () => {
        this.props.onClickListener("drawer-cancel");
    };

    onOK() {
        this.props.onClickListener("drawer-ok");
    }
    onContinue(){
        this.props.onClickListener("drawer-continue");
    }

    componentDidMount() {
        if (this.props.width != null) {
            this.setState({
                width: this.props.width
            })
        }
    }

    render() {
        return (
            <div style={{ heigth: "100vh" }} className=" comm-flex-col-center ">
                <Drawer
                    title={this.props.title}
                    placement={this.props.placement}
                    destroyOnClose={this.props.maskClosable == false ? false : true}
                    maskClosable={this.props.maskClosable == false ? false : true}
                    closable={this.props.closable == false ? false : true}
                    onClose={this.props.onClose?this.props.onClose(this):this.onClose.bind(this)}
                    visible={this.props.visible}
                    width={this.state.width}
                    zIndex={this.props.zIndex || 1000}
                >
                    <div className="comm-col  idrawer-view ">
                        <div className={this.props.footer ? "idrawer-content-footer" : "idrawer-content"}>
                            {this.props.div ? this.props.div : React.Children.map(this.props.children, function (child) {
                                return <div style={{ height: "100%" }}>{child}</div>
                            })}


                        </div>
                        {
                            this.props.footer ? <div className="idrawer-footer">
                                <div className="idrawer-footer-view">
                                    <IButton style={{ marginRight: 10 }} type="default" textColor="#666"
                                        onClick={this.onCancel.bind(this)}
                                        text={this.props.cancelText ? this.props.cancelText : "取消"} />
                                        {
                                            this.props.continue?<IButton style={{ marginRight: 10 }} type="default" textColor="#666"
                                            onClick={this.onContinue.bind(this)}
                                            loading={this.props.loading}
                                            text={'保存并继续新增'} />:null
                                        }
                                    
                                    <IButton
                                        style={{ marginRight: 20 }}
                                        loading={this.props.loading}
                                        type="primary"
                                        onClick={this.props.onOK?this.props.onOK(this):this.onOK.bind(this)}
                                        htmlType={this.props.htmlType}
                                        text={this.props.confirmText ? this.props.confirmText : "确定"} />
                                </div>
                            </div> : null
                        }
                    </div>

                </Drawer>
            </div>
        );
    }
}
