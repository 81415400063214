import dateHelp from '../../comm/dateHelp';


//门店设置
export function appointmentSetting(){
    return {
        Id: 0,
        StoreId: 0,
        Phone: '',//预约电话
        LeadTime:0,//提前时间
        AppointmentTime: 0,//预约天数
        ShowPrice: 1,//1.显示门市价2.不显示门市价
        AppointmentType:1,//1.系统智能匹配2.顾客自由安排
        AutomaticOrder: 2,//自动接单
    }
}

/** 预约项目项目 */
export function  getAppointmentCondition(){
    return {
        Id:0,
        /** 店铺id */
        StoreId:0,
        /** 顾客id */
        CusId:0,
        /** 顾客name */
        CusName:'',
        /** 顾客电话 */
        CusPhone:'',
        /** 项目id */
        ItemId:0,
        /** 项目名称 */
        ItemName:'',
        /** 员工id */
        EmpId:0,
        /** 员工姓名 */
        EmpName:'',
        /** 备注 */
        Remark:'',
        /** 状态 */
        State:0,
        /** 预约时间 */
        AppointmentTime:0,
        /** 到店时间 */
        ArriveTime:0,
        /** 创建时间 */
        CreateTime:0,
        /** 更新时间 */
        UpdateTime:0,
        /** 预计结束时间 */
        FinishTime:0,
        /** 预约单号 */
        AppointmentNo:'',
    /** 是否发生短信 1--发送  2-- 不发送 */
        SendSms:1,
        /** 项目价格 */
        ItemPrice:0,
        /** 项目时长 */
        ItemTime:'',
        /** 选择日期 */
        _SelectDate:dateHelp.getNumToDate(0),
        /** 选择时间 */
        _SelectTime:'',

    }
    
}
   /** 操作记录 */
   export function getSAppointmentOperationLog(){
       return {
        /** 编号 */
        Id:0,
        /** 店铺id */
        StoreId:0,
        /** 顾客id */
        CusId:0,
        /** 顾客id */
        CusName:'',
        /** 员工id */
        EmpId:0,
        /** 员工姓名 */
        EmpName:'',
        /** 备注 */
        Content:'',
        /** 日志类型 */
        Type:0,
        /** 预约单号 */
        AppointmentNo:'',
       }
    
  }



export const AppintmentState ={
    /**
     * 待接单
     */
    Waiting:1,
    /**
     * 取消
     */
    Revoke:2,
    /**
     * 接单
     */
    Accept:3,
    /**
     * 爽约
     */
    Timeout:4,
    /**
     * 达店
     */
    Arrive:5,
    /**
     * 修改
     */
    Update:6,
}


