/**
 * name: light.js
 * author: jarrysix (jarrysix@gmail.com)
 * date: 2018-09-22 19:35
 * description: 交通灯
 */

import React from "react"
import Icon from "antd/lib/icon"
import "./light.css"
import {notify} from "../comm/mzl-core"
import {getIcon} from "../comm/utils"
const IIcon = getIcon(Icon);
class TrafficLight extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isMax: false};
    }

    getPlatform() {
        //return "windows";
        let userAgent = window.navigator.userAgent;
        if (userAgent.indexOf("Linux") != -1) return "linux";
        if (userAgent.indexOf("Mac") != -1) return "mac";
        if (userAgent.indexOf("Windows") != -1) return "windows";
        return "unknown";
    }

    minWin() {
        if (!notify.appSend("win-light-state", {do: "min"})) {
            // console.log("最小化");
        }
    }

    maxWin() {
        let w = window.screen.availWidth;
        let h = window.screen.availHeight;
        let w1 = document.documentElement.offsetWidth;
        let h1 = document.documentElement.offsetHeight;
        let isResize = w > w1 || h > h1;
        let data = {
            do: "max",
            current: {width: w1, height: h1},
            screen: {width: w, height: h},
            isResize: isResize,
        };
        console.log("maxWin",data)
        // 如果最大化，且没有调整窗口。那么还原窗口
        if (!isResize && this.state.isMax) {
            data.do = "restore";
            if (!notify.appSend("win-light-state", data)) {
                //console.log("还原");
            }
            this.state.isMax = false;
            return;
        }
        // 最大化
        if (!notify.appSend("win-light-state", data)) {
            //console.log("最大化");
        }
        this.state.isMax = true;
    }

    closeWin() {
        if (!notify.appSend("win-light-state", {do: "close"})) {
        }
    }

    render() {
        let platform = this.getPlatform();
        // platform = "windows";
        // Mac不需要显示交通灯
        if (platform === "mac") return "";

        return (
            <div class={"mod-traffic-light mod-traffic-light-" + platform}>
                {/* <Icon type="minus" theme="outlined" class="min" onClick={this.minWin.bind(this)}/> */}
                <a class="min">
                    {/* <Icon type="minus-circle" theme="outlined" class="min" onClick={this.minWin.bind(this)}/> */}
                    <Icon type="minus" theme="outlined" class="min" style={{color:"#666"}} onClick={this.minWin.bind(this)}/>
                </a>
                <a class="max">
                    {/* <Icon type="plus-circle" theme="outlined" class="max" onClick={this.maxWin.bind(this)}/> */}
                    {/* <Icon type="border" theme="outlined" class="max" style={{color:"#333"}} onClick={this.maxWin.bind(this)}/> */}
                    <IIcon type="icon-zuidahua" theme="outlined" class="max" style={{color:"#666"}} onClick={this.maxWin.bind(this)}/>
                </a>
                <a class="close">
                    {/* <Icon type="close-circle" theme="outlined" class="close" onClick={this.closeWin.bind(this)}/> */}
                    <Icon type="close" theme="outlined" class="close" style={{color:"#666"}} onClick={this.closeWin.bind(this)}/>
                </a>
            </div>
        );
    }
}

export default TrafficLight