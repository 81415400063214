import React from 'react';
import "../../css/comm.css"
import {Icon, Progress, Spin} from 'antd';

const antIcon = <Icon type="loading" style={{fontSize: 30}} spin/>;
const style = {
    container: {
        position: 'relative',
    },
    refresh: {
        display: 'inline-block',
        position: 'relative',
    },
};

export default class LoadingDialog extends React.Component {
    render() {
        return (
            <div className='comm-loading-div' style={style.container}>
                <div>
                    <div className="comm-loading-screen comm-display-none">
                        <Spin size="large"/>
                    </div>
                </div>
            </div>

        )
    }
}


/**
 * 圆形进度条
 */
export class LoadingDialogCircle extends React.Component {
    render() {
        return (
            <div className='comm-loading-div' style={style.container}>
                <div>

                    <Progress className="comm-background-white" strokeColor="#fc1a6e"
                              status="active" percent={this.props.percent} type="circle" width={90}/>

                </div>
            </div>

        )
    }
}



/**
 * 遮住全部的等待进度条
 */
export class LoadingDialogAll extends React.Component {
    render() {
        return (
            <div className="loadding-main">
            <div className="loadding-main-b"></div>
            <div className="loadding-main-content">
                <div className="loadding-main-content-div" style={{padding:"30px 30px 20px 30px"}}>
                    <Spin size="large"/>
                    <span style={{marginTop:10}}>{this.props.text?this.props.text:"请稍后..."}  </span>
                    {/*<a style={{marginTop:10}} onClick={this.cancelPay.bind(this)}>取消</a>*/}

                </div>
            </div>
        </div>

        )
    }
}
