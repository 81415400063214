import React from 'react';
import {DatePicker, Drawer, Form, Icon, Input, Radio, Select, Tabs, Tooltip} from 'antd';
import {messageError, numberConvert, numConvert} from "../comm/utils";
import StorageTools from "../comm/storageTools";
import {$mzlApi} from "../comm/mzl-core";
import IButton from "../components/IButton";
import APINames from "../comm/api-names"
import '../../css/tab.css'
import '../../css/card.css'
import {EItemType} from "../comm/iEnum";

const {MonthPicker, RangePicker} = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const TabPane = Tabs.TabPane;
const Search = Input.Search;
const TextArea = Input.TextArea;
const Option = Select.Option;
const RadioGroup = Radio.Group;
var h = document.documentElement.clientHeight - 220;

export default class Item extends React.Component {
    constructor() {
        super();
        this.state = {
            item: [],
            cardItem: [],
            itemData: [],
            disabled: true,
            ExpireDay: 0
        }
    }

    componentWillMount() {
        this.requestQueryItem();
        this.requestQueryCardItem();
        if (this.props.itemList.length) {
            this.setState({ExpireDay: this.props.itemList[0].ExpireDay}, () => {
                if (this.state.ExpireDay == 0) {
                    this.setState({disabled: true})
                } else {
                    this.setState({disabled: false})
                }
            })
        }
        this.setState({itemData: this.props.itemList})
    }


    onClose = () => {
        this.props.onClickListener('hideItemModal');
    };
    onCancel = () => {

    };

    onOK() {
        this.state.itemData.forEach(item => {
            item.ExpireDay = this.state.ExpireDay
        });
        // console.log(this.state.itemData)
        this.props.onClickListener('项目数据', this.state.itemData);
    }

    /**
     *获取服务
     */
    requestQueryItem(search) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('productType', EItemType.Virtual);
        map.set('opt', JSON.stringify({Begin: 0, Over: 999, Opt: {"shelve_state": "3", "keyword": search,enabled:1}}));
        $mzlApi.post(APINames.API_queryItem, map, (res) => {
            this.setState({item: JSON.parse(res.Data)})
        }, this.httpFail.bind(this), this);
    }

    /**
     *获取商品
     */
    requestQueryCardItem(search) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('productType', EItemType.Normal);
        map.set('opt', JSON.stringify({Begin: 0, over: 999, Opt: {"shelve_state": "3", "keyword": search,enabled:1}}));
        $mzlApi.post(APINames.API_queryItem, map, (res) => {
            //console.log(JSON.parse(res.Data))
            this.setState({cardItem: JSON.parse(res.Data)})
        }, this.httpFail.bind(this), this);
    }

    httpFail(res, tag) {
        messageError(res, tag);
        //console.log('失败', res, tag)
    }

    onClickListener(tag, data) {
        switch (tag) {
            case 'clickItems':
                // console.log(data)
                var isPush = true;
                this.state.itemData.every((item) => {
                    if (item.ItemId == data.ItemId) {
                        data = item;
                        isPush = false;
                        return false;
                    }
                    return true;
                });
                if (isPush) {
                    data.Quota = 1;
                    data.Cost = data.Cost;
                    data.Count = data.Quota;
                    data.Id = data.ItemId;
                    data.ItemTitle = data.Title;
                    data.UnitPrice = data.Price;
                    data.FinalPrice = data.Price;
                    data.ActivityPrice = data.FinalPrice;
                    data.TotalAmount = data.Price;
                    data.Amount = data.ActivityPrice * data.Count;
                    this.state.itemData.push(data)
                } else {
                    data.Quota = data.Quota + 1;
                    data.Count = data.Quota;
                    data.Cost = data.Cost;
                    data.TotalAmount = data.FinalPrice * data.Quota;
                    data.Amount = data.ActivityPrice * data.Count;
                    data.FinalPrice = data.TotalAmount / data.Quota;
                }
                this.setState({itemData: this.state.itemData});
                break;
            case 'API_deleteItem':
                //console.log(data);
                var arr = this.state.itemData;
                arr.splice(arr.indexOf(data), 1);
                this.setState({itemData: arr});
                break;
            case '添加套餐卡确定':
                var arr = this.state.itemData;
                var str = '';
                var arr2 = [];
                var zQuota = 0;//总数量
                var zUnitPrice = 0;//总门市价
                var zFinalPrice = 0;//总体验价
                arr.forEach(item => {
                    // if(item.UnitPrice){
                    //     this.state.confirmActivity.Price += (item.UnitPrice * item.Quota);
                    // }else {
                    this.state.confirmActivity.Price += (item.Price * item.Count);
                    // }
                    // if(item.FinalPrice){
                    //     this.state.confirmActivity.ActivityPrice += (item.FinalPrice * item.Quota);
                    // }else {
                    this.state.confirmActivity.ActivityPrice += (item.ActivityPrice * item.Count);
                    // }
                    zQuota += item.Quota;
                    zUnitPrice += item.UnitPrice;
                    zFinalPrice += item.FinalPrice;
                    arr2.push({
                        ProductId: item.ItemId,
                        ItemTitle: item.Title,
                        Price: item.UnitPrice,
                        ActivityPrice: item.FinalPrice,
                        Count: item.Quota,
                        Amount: item.TotalAmount,
                        ProductType: item.ProductType
                    })
                });
                this.setState({zQuota: zQuota});
                // arr.push({title:'合计',Quota:zQuota,UnitPrice:zUnitPrice,FinalPrice:zFinalPrice})
                //console.log(arr2)
                this.setState({
                    confirmItem: arr2,
                    visible: false
                });
                //console.log('表', this.state.confirmActivity)
                //console.log(this.state.itemData, this.state.confirmActivity)
                break;
            case '搜索服务':
                this.requestQueryItem(data);
                break;
            case '搜索商品':
                this.requestQueryCardItem(data)
        }
    }

    onChangeListener(tag, e, event) {
        switch (tag) {
            case '搜索服务':
                if (e.target.value == '') {
                    this.requestQueryItem();
                }
                break;
            case '搜索商品':
                if (e.target.value == '') {
                    this.requestQueryCardItem();
                }
                break;
            case 'changeCount':
                //console.log(e)
                e.Quota = parseInt(numConvert(event.target.value));
                e.Count = parseInt(numConvert(event.target.value));
                if (e.Quota == "" || e.Quota < 1) {
                    e.Quota = 1;
                    e.Count = 1
                }
                if (e.FinalPrice) {
                    e.TotalAmount = e.FinalPrice * e.Quota;
                    e.Amount = e.FinalPrice * e.Quota;
                    e.FinalPrice = e.TotalAmount / e.Quota;
                } else {
                    e.TotalAmount = e.ActivityPrice * e.Quota;
                    e.Amount = e.ActivityPrice * e.Quota;
                    e.FinalPrice = e.TotalAmount / e.Quota;
                }

                this.setState(this);
                break;
            case 'changeActivityPrice':
                //console.log(e)
                event.target.value = event.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                event.target.value = event.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
                event.target.value = event.target.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                event.target.value = event.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
                e.ActivityPrice = parseInt(event.target.value * 100);
                e.FinalPrice = parseInt(event.target.value * 100);
                if (e.Quota) {
                    e.TotalAmount = e.FinalPrice * e.Quota;
                    e.Amount = e.FinalPrice * e.Quota;
                } else {
                    e.TotalAmount = e.FinalPrice * e.Count;
                    e.Amount = e.FinalPrice * e.Count;
                }

                this.setState(this);
                break;
            case '有效期':
                //console.log(e.target.value)
                if (e.target.value == 2) {
                    this.setState({disabled: false})
                } else {
                    this.setState({disabled: true, ExpireDay: 0})
                }
                break;
            case '输入有效期':
                e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
                this.setState({ExpireDay: e.target.value});
                break

        }
    }

    render() {
        var arr = this.state.itemData;
        var 门市价 = 0;
        var 体验价 = 0;
        var 总数量 = 0;
        arr.forEach(item => {
            if (item.UnitPrice) {
                if (item.Quota) {
                    门市价 += (item.UnitPrice * item.Quota) / 100;
                } else {
                    门市价 += (item.UnitPrice * item.Count) / 100;
                }
            } else {
                门市价 += (item.Price * item.Count) / 100;
            }
            if (item.FinalPrice) {
                if (item.Quota) {
                    体验价 += (item.FinalPrice * item.Quota) / 100;
                } else {
                    体验价 += (item.FinalPrice * item.Count) / 100;
                }

            } else {
                体验价 += (item.ActivityPrice * item.Count) / 100;
            }
            if (item.Quota) {
                总数量 += item.Quota
            } else {
                总数量 += item.Count
            }

        });
        const div =
            <div style={{overflow: 'hidden', display: 'flex', justifyContent: 'space-between'}}>
                <div class="mod-card-form mod-time-card-form">
                    <div class="mod-time-card-form-side items gra-radius-border" style={{marginTop: 10}}>
                        <Tabs type='card' tabBarGutter={0}>
                            <TabPane tab="服 务" key="2">
                                <div style={{textAlign: 'center', borderBottom: '1px #ccc solid'}}>
                                    <Search
                                        placeholder="服务名称或首字母"
                                        onSearch={this.onClickListener.bind(this, '搜索服务')}
                                        style={{width: 210, marginBottom: 10}}
                                    />
                                </div>
                                <div style={{height: h, overflow: 'auto'}}>
                                    {
                                        this.state.item.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     onClick={this.onClickListener.bind(this, 'clickItems', item)}
                                                     style={{
                                                         borderBottom: '1px #ccc solid',
                                                         padding: '10px'
                                                     }}>{index + 1}.{item.Title}({item.Price / 100})元</div>
                                            )
                                        })
                                    }
                                </div>
                            </TabPane>
                            <TabPane tab="商 品" key="1">
                                <div style={{textAlign: 'center', borderBottom: '1px #ccc solid'}}>
                                    <Search
                                        placeholder="商品名称或首字母"
                                        onSearch={this.onClickListener.bind(this, '搜索商品')}
                                        style={{width: 210, marginBottom: 10}}
                                    />
                                </div>
                                <div style={{height: h, overflow: 'auto'}}>
                                    {
                                        this.state.cardItem.map((item, index) => {
                                            return (
                                                <div key={index}
                                                     onClick={this.onClickListener.bind(this, 'clickItems', item)}
                                                     style={{
                                                         borderBottom: '1px #ccc solid',
                                                         padding: '10px'
                                                     }}>{index + 1}.{item.Title}({item.Price / 100})元</div>
                                            )
                                        })
                                    }
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <div
                    style={{flex: 1, minHeight: '50vh', background: '#fff', borderRadius: 6, padding: '0 10px'}}
                >

                    <Form>
                        <div style={{margin: '20px 0'}}>
                            <span style={{width: 100, display: 'inline-Block'}}>活动名称：</span>
                            {this.props.title}
                        </div>
                        <div style={{marginBottom: 10}}>
                            <span style={{width: 100, display: 'inline-Block'}}>项目有效期</span>
                            <RadioGroup defaultValue={this.state.ExpireDay == 0 ? 1 : 2}
                                        onChange={this.onChangeListener.bind(this, '有效期')}>
                                <Radio style={{marginRight: 38}} value={1}>永久有效</Radio>
                                <Radio value={2}>办卡起</Radio>
                            </RadioGroup>
                            <Input
                                onChange={this.onChangeListener.bind(this, '输入有效期')}
                                disabled={this.state.disabled}
                                defaultValue={this.state.ExpireDay}
                                style={{width: 50, marginRight: 10}}/>
                            <span>日有效</span>
                        </div>
                        <div>
                            {/* <Table style={{height:200,border:'1px #ccc solid', borderRadius:5}} columns={this.state.columns3} dataSource={this.state.itemData} scroll={{x:'100%',y:150}} pagination={false} bordered></Table> */}
                            <table border="1" style={{width: "100%", height: "12%"}} bordercolor="#dcdcdc">
                                <tbody align="center" valign="center">
                                <tr style={{height: "40px", backgroundColor: "#f5f5f5"}}>
                                    <td width='20%'>项目名称</td>
                                    <td width='15%'>门市价</td>
                                    <td width='15%'>体验价</td>
                                    <td width='20%'>数量</td>
                                    <td width='20%'>金额</td>
                                    <td width='10%'>删除</td>
                                </tr>
                                </tbody>
                            </table>
                            <div>
                                <table border="1" style={{width: "100%", tableLayout: "fixed"}}
                                       bordercolor="#dcdcdc">
                                    <tbody align="center" valign="center">

                                    {
                                        this.state.itemData.map((item, index) => {
                                            return (
                                                <tr className={item.isEdit ? "comm-background-f5f5f5" : ""}
                                                    key={index} style={{height: "40px"}}>
                                                    <td width='20%' className='time'><Tooltip
                                                        title={item.Title ? item.Title : item.ItemTitle}>{item.Title ? item.Title : item.ItemTitle}</Tooltip>
                                                    </td>
                                                    <td width='15%'>{item.UnitPrice ? numberConvert(item.UnitPrice).toFixed(2) : numberConvert(item.Price).toFixed(2)}元</td>
                                                    <td width='15%'>
                                                        <Input maxLength={6}
                                                               defaultValue={item.FinalPrice ? item.FinalPrice / 100 : item.ActivityPrice / 100}
                                                               onChange={this.onChangeListener.bind(this, 'changeActivityPrice', item)}
                                                               style={{width: '70%'}}
                                                        />元
                                                    </td>
                                                    <td width='20%'>{item.isEdit ?
                                                        <div>{item.Quota ? item.Quota : item.Count}</div> :
                                                        <Input type='number' min={1}
                                                               value={item.Quota ? item.Quota : item.Count}
                                                               onChange={this.onChangeListener.bind(this, 'changeCount', item)}
                                                               style={{width: '60%'}}/>} </td>
                                                    <td width='20%'>
                                                        <div
                                                            className="comm-row comm-item-center comm-item-justify">
                                                            <div>{numberConvert(item.TotalAmount ? item.TotalAmount : item.ActivityPrice * item.Count)}</div>
                                                            元
                                                        </div>
                                                    </td>
                                                    <td width='10%'><Icon type='close'
                                                                          onClick={this.onClickListener.bind(this, 'API_deleteItem', item)}/>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{marginTop: 30}}>
                            <span style={{marginRight: 50}}>门市价：{门市价 ? (门市价).toFixed(2) : 0}</span>
                            <span>体验价：{体验价.toFixed(2)}</span>
                        </div>

                    </Form>

                </div>
            </div>;
        return (
            <div className="comm-h comm-flex-col-center ">
                <Drawer
                    style={{height: '100%'}}
                    title='添加活动项目'
                    destroyOnClose={true}
                    onClose={this.onClose.bind(this, "close")}
                    visible={this.props.visible}
                    width={920}
                    zIndex={1000}
                >
                    <div className="comm-col  idrawer-view " style={{height: '100%'}}>
                        <div className="idrawer-content">
                            {div}
                        </div>
                        <div className="idrawer-footer">
                            <div className="idrawer-footer-view">
                                <IButton style={{marginRight: 10}} type="default" textColor="#999"
                                         onClick={this.onClose.bind(this)}
                                         text="取消"/>
                                <IButton style={{marginRight: 20}} type="primary" onClick={this.onOK.bind(this)}
                                         text="确定"/>
                            </div>
                        </div>
                    </div>

                </Drawer>
            </div>
        );
    }
}
