import React from "react";
import StorageTools from "../../../../js/comm/storageTools";
import {
  hideLoadding,
  messageError,
  numberConvert,
  numberConvert_100,
  showLoadding,
  messageSuccess,
  showMessageError
} from "../../../../js/comm/utils";
import { Icon, Tree, Button, Input, Checkbox, Row, Col } from "antd";
import { FnMenuId } from "../../../../js/comm/constans";
import { connect } from "react-redux";
import { setDefaultTitle } from "../../../../js/reduxs/reducers/iconRedux";
import APINames from "../../../../js/comm/api-names";
import { $mzlApi } from "../../../../js/comm/mzl-core";
import {IModal} from '../../../../js/components/iModal'
import "./chainsetting.css";
import Item from "../../../../js/market/item";
const utils = require("../../../../js/comm/utils.js");
const { TreeNode } = Tree;
let mchCode = ''
let getMchName;
let divHeight;
let flatMap;
/**
 * 员工设置
 */
@connect(
  state => state.iconRedux,
  { setDefaultTitle }
)
class postSetting extends React.Component {
  constructor() {
    super();
    this.state = {
      jobName: "",
      chainSetting: [],
      shopSetting: [],
      reportSetting:[],
      isShowSave: false,
      auathoritys: [],
      postList: [],
      mchManagerRoles: [],
      selectItem: {},
      direction:'plus',
      selectItemFather:{},
      upSelectItem: {},
      selectArr:[],
      colorArr:{left:'gray',right:'gray',up:'gray',down:'gray',plus:'#fc1a6e',close:'gray'},
      index:0,
      delVisibal:false,
      type:'',
      loading:false,
      selectedKeys:[],

    };
  }

  componentWillMount() {
    getMchName = StorageTools.getMchName()
    mchCode = StorageTools.getMchCode()
    this.props.setDefaultTitle({
      titleKey: FnMenuId.head_chain_setting,
      slidingLeftKey: "1"
    });
  }

  componentDidMount() {
    this.queryMchManagerAuthority();
    this.queryMchManagerRoleFirst();
  }

  async onClickListener(tag, data, keys, event) {
    const { mchManagerRoles } = this.state;
    switch (tag) {
      case "选择岗位":
        if(data[0] == undefined){
         this.state.colorArr = {left:'gray',right:'gray',up:'gray',down:'gray',plus:'#fc1a6e',close:'gray'}
         await  this.setState({jobName:'',selectItem:{},chainSetting: [], shopSetting: [],reportSetting:[],isShowSave:true,direction:'plus',selectedKeys:[]})
        }else{
          //查找选择的这个项目item
        this.state.colorArr = {left:'gray',right:'gray',up:'gray',down:'gray',plus:'#fc1a6e',close:'gray'}
        await  this.setState({jobName:'',chainSetting: [], shopSetting: [],reportSetting:[],isShowSave:false,selectedKeys:data})
          this.queryParameter(mchManagerRoles, data[0],'select')
          
        }
        
        break;
    }
  }                                                                                                                                                                                                                                                
  Checkbox = e => {
    this.setState({ chainSetting: e });
  };
  CheckboxShop = e => {
    this.setState({ shopSetting: e });
  };
  CheckboxReport = e =>{
    this.setState({ reportSetting: e });
  }
  onChangeName = e => {
    this.setState({ jobName: e.target.value.trim() });
  };

  
  //查询设置的模板对象
  queryMchManagerAuthority() {
    const map = new Map();
    $mzlApi.post(
      APINames.API_queryMchManagerAuthority,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }
  //查询岗位
  queryMchManagerRole() {
    showLoadding();
    const map = new Map();
    map.set("mchCode", mchCode);
    $mzlApi.post(
      APINames.API_queryMchManagerRole,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }


   //查询岗位
   queryMchManagerRoleFirst() {
    showLoadding();
    const map = new Map();
    map.set("mchCode", mchCode);
    $mzlApi.post(
      APINames.API_queryMchManagerRole,
      map,
      (res)=>{
        if (res && res.Data && res.Data.mchManagerRoles) {
          const mchManagerRoles = JSON.parse(res.Data.mchManagerRoles);
          let str ='';
          let arr =[];
      this.setState({ mchManagerRoles,});
          if(mchManagerRoles.length){
            str += mchManagerRoles[0].Id
            arr.push(str)
            this.setState({selectedKeys:arr})
            this.queryParameter(mchManagerRoles, mchManagerRoles[0].Id,'select')
          }else{
            this.setState({isShowSave:true})
          }
          console.log(987,mchManagerRoles)
            
         
          
        }
      },
      this.httpFail.bind(this),
      this
    );
  }
  httpSuccess(res, tag,type) {
    const {selectItem} = this.state;
    hideLoadding();
    switch (tag) {
      case APINames.API_queryMchManagerAuthority:
        if (res && res.Data && res.Data.Authoritys) {
          const auathoritys = JSON.parse(res.Data.Authoritys);
          this.setState({ auathoritys });
        }

        break;
      case APINames.API_saveMchManagerRole:
        messageSuccess("保存成功！");
        this.setState({isShowSave: false,loading:false});
        this.queryMchManagerRole(); //查询岗位
        break;
      case APINames.API_queryMchManagerRole:
        ////console.log(JSON.parse(res.Data.mchManagerRoles))
        if (res && res.Data && res.Data.mchManagerRoles) {
          const mchManagerRoles = JSON.parse(res.Data.mchManagerRoles);
          this.setState({ mchManagerRoles });
          flatMap = this.flatCate(mchManagerRoles);
          let str='',arr=[];
          str += flatMap[this.state.jobName] && flatMap[this.state.jobName].Id
          arr.push(str);
          this.setState({selectedKeys:arr})
          if( selectItem.Id){
            this.queryParameter(mchManagerRoles, selectItem.Id,'save')
          }
          
        }
        break;
      case APINames.API_deleteMchManagerRole:
          utils.setReqeustState(true);
        messageSuccess("删除成功！");
        this.state.selectItem = {}
        this.setState({selectItem:this.state.selectItem})
        this.queryMchManagerRole(); //查询岗位
        break;
        //左右移动
      case APINames.API_editMchManagerRoleParent:
          utils.setReqeustState(true);
          this.queryMchManagerRole(); //查询岗位
        break;
        //上下移动
      case APINames.API_editMchManagerRoleOrderNum:
          utils.setReqeustState(true);
          this.queryMchManagerRole(); //查询岗位
        ////console.log(1111)
        break;
      case APINames.API_getMchManagerRole:
        let shopSetting = [],chainSetting = [],reportSetting = [];
        if(res && res.Data && res.Data.mchManagerRole){
          const authorityMchManagerRole = JSON.parse(res.Data.mchManagerRole);
          authorityMchManagerRole.Authoritys.map((item,index)=>{
            if(item.Flag == 2){//店铺设置
              shopSetting.push(item.Name)
            }else if(item.Flag == 1){//连锁设置
              chainSetting.push(item.Name)
            }else if(item.Flag == 3){//报表设置
              reportSetting.push(item.Name)
            }
          })
          
        }
        this.setState({shopSetting,chainSetting,reportSetting})
        break;
    }
  }

  httpFail(res, tag) {
    this.setState({loading:false})
    hideLoadding();
    switch(tag){
      case APINames.API_deleteMchManagerRole:
        ////console.log(11111)
        showMessageError(res, tag)
        break;
      case APINames.API_saveMchManagerRole:
          showMessageError(res, tag)
        break;
      
    }
   

  }
  flatCate(cateArray,childKey='MchManagerRoles'){
    ////console.log(5555,cateArray)
    const flatMapList = new Map();
		f(cateArray);

		// 递归方法，找出子孩子
		function f(cateArray){

			cateArray.forEach(function(cateVal){

				let cateID = cateVal.Name;
				flatMapList[cateID] = cateVal;

				// 如果存在子孩子
				cateVal[childKey] instanceof Array &&  f(cateVal[childKey]);
			});
		}

		return flatMapList;
	}

  onClickIcon(type) {
    const {mchManagerRoles,selectItem,upSelectItem,selectArr} = this.state;
    switch (type) {
      case "left":
         //找到我父级的父亲的id
         this.queryParameter(mchManagerRoles, selectItem.ParentRoleId,'left');
        break;
      case "right":
        if(upSelectItem && upSelectItem.Id){
          this.editMchManagerRoleParent(upSelectItem.Id);
        }
          
        break;
      case "up":
          let newSelectArr = [...selectArr],
          selectItemIndex;
          newSelectArr.map((item,index)=>{
          if(item.Id == selectItem.Id){
            selectItemIndex = index;
          }
        })
          if(selectItemIndex){
            newSelectArr.splice(selectItemIndex,1,...newSelectArr.splice(selectItemIndex - 1, 1 , newSelectArr[selectItemIndex]));
            newSelectArr.map((item,index)=>{
              item.OrderNum = index
            })
            this.editMchManagerRoleOrderNum(newSelectArr)
          }
          
         
          
        break;
      case "down":
          let newDownSelectArr = [...selectArr],
          selectItemDownIndex;
          newDownSelectArr.map((item,index)=>{
          if(item.Id == selectItem.Id){
            selectItemDownIndex = index;
          }
        })
        if(selectItemDownIndex !== selectArr.length - 1){
          newDownSelectArr.splice(selectItemDownIndex,1,...newDownSelectArr.splice(selectItemDownIndex + 1, 1 , newDownSelectArr[selectItemDownIndex]));
          newDownSelectArr.map((item,index)=>{
            item.OrderNum = index
          })
          // ////console.log(newDownSelectArr)
          this.editMchManagerRoleOrderNum(newDownSelectArr)
        }

        break;
      case "plus":
        this.setState({
          isShowSave: true,
          jobName: "",
          chainSetting: [],
          shopSetting: [],
          reportSetting:[],
          direction:'plus'
        });
        break;
      case "close":
        if(selectItem.Id){
          this.setState({delVisibal:true})
         
        }
        
        break;
    }
    this.state.colorArr={left:'gray',right:'gray',up:'gray',down:'gray',plus:'#fc1a6e',close:'gray'}
    

  }
  onOkHandelDel=() =>{
    this.setState({delVisibal:false})
    if(this.state.index){
      showMessageError('删除子级后再删除此岗位！')
    }else{
      this.deleteMchManagerRole();
    }
    
  }


  showDivSave() {
    const { jobName, chainSetting, shopSetting,reportSetting,loading } = this.state;
    return (
      <div className="organ-right" style={{}}>
        <div className="head-organ-right">
          <div style={{}}>
            <span style={{ marginRight: 26,width:100 }}>岗位名称:</span>
            <Input
              maxLength={8}
              style={{ width: 200 }}
              value={jobName}
              onChange={this.onChangeName}
            />
          </div>
          <div style={{ marginLeft: 140 }}>
            <Button
              type="primary"
              // style={{ float: "right" }}
              onClick={this.onSaveJob.bind(this)}
              loading = {loading}
            >
              <span style={{ color: "#fff" }}>保存</span>
            </Button>
          </div>
        </div>
        <div style={{ display: "flex", }}>
          <div className="" style={{ marginTop:19,marginLeft:27,width:78,textAlign:'center'}}>
            权限:
          </div>
          <div style={{width:'calc(100% - 86px)'}}>
            <div
              style={{
                width: '100%',
                display: "flex",
                alignItems: "center",
                padding: "15px 0",
                borderBottom: "1px solid #d9d9d9"
              }}
            >
              <span style={{ width: 120 }}>连锁设置</span>
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={this.Checkbox}
                value={chainSetting}
              >
                <Checkbox value="连锁设置">连锁设置</Checkbox>

                <Checkbox value="店铺设置">店铺设置</Checkbox>
                <Checkbox value="跨店消费">跨店消费</Checkbox>
                <Checkbox value="我的订单">我的订单</Checkbox>
              </Checkbox.Group>
            </div>

            <div
              style={{
                width: '100%',
                display: "flex",
                alignItems: "center",
                padding: "15px 0",
                borderBottom: "1px solid #d9d9d9"
              }}
            >
              <span style={{ width: 120 }}>报表设置</span>
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={this.CheckboxReport}
                value={reportSetting}
              >
                <Checkbox value="总览">总览</Checkbox>

                <Checkbox value="营业数据">营业数据</Checkbox>
                <Checkbox value="业绩排名">业绩排名</Checkbox>
                <Checkbox value="会员报表">会员报表</Checkbox>
              </Checkbox.Group>
            </div>




            <div
              style={{
                width: '100%',
                display: "flex",
                alignItems: "center",
                padding: "15px 0",
                borderBottom: "1px solid #d9d9d9"
              }}
            >
              <div style={{ width: 97 }}>店铺设置</div>
              <div>
                {" "}
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={this.CheckboxShop}
                  value={shopSetting}
                >
                  <Checkbox value="店铺管理员">店铺管理员</Checkbox>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  unique (arr) {
    return Array.from(new Set(arr))
   }

   //权限卡控  保存的时候要卡，下面那个函数也要卡 
   requestSaveMchManagerRole(ParentRoleId,ID,type) {
    showLoadding();
    const { jobName, chainSetting, shopSetting, auathoritys,reportSetting } = this.state;
    let newChainSetting = [...chainSetting];

    
    let newArrChainSetting = [];
    newChainSetting.map((item,index)=>{
      if(item == '连锁设置'){
        newArrChainSetting[0] = '连锁设置'
      }else if(item == '店铺设置'){
        newArrChainSetting[1] = '店铺设置'
      }else if(item == '跨店消费'){
        newArrChainSetting[2] = '跨店消费'
      }else if(item == '我的订单'){
        newArrChainSetting[3] = '我的订单'
      }
    })

    let newArrReportSetting = [];
    reportSetting.map((item,index)=>{
      if(item == '总览'){
        newArrReportSetting[0] = '总览'
      }else if(item == '营业数据'){
        newArrReportSetting[1] = '营业数据'
      }else if(item == '业绩排名'){
        newArrReportSetting[2] = '业绩排名'
      }else if(item == '会员报表'){
        newArrReportSetting[3] = '会员报表'
      }
    })
    newArrReportSetting = newArrReportSetting.filter(item => item)
    newArrChainSetting = newArrChainSetting.filter(item => item)
    

    let Authoritys = [...newArrChainSetting, ...shopSetting,...newArrReportSetting];
    let newAuathoritys = [];
    Authoritys.map((item, index) => {
      auathoritys.map((i, num) => {
        if (item == i.Name) {
          newAuathoritys.push(i);
        }
      });
    });
    let obj = {
      Id:ID,
      Name: jobName,
      ParentRoleId,
      InternalRole: 1,
      State: 1,
      Authoritys: newAuathoritys,
      MchManagerRoles: [],
      OrderNum:type == 'edit'?this.state.selectItem.OrderNum:this.state.index
    };
    
    let map = new Map();
    map.set("mchCode", mchCode);
    map.set("mchManagerRole", JSON.stringify(obj));

    $mzlApi.post(
      APINames.API_saveMchManagerRole,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  showDivEdit() {
    const { jobName, chainSetting, shopSetting,reportSetting } = this.state;
    const newchainSetting =  this.unique(chainSetting)
    const newshopSetting =  this.unique(shopSetting)
    const newreportSetting =  this.unique(reportSetting)
    let newArrChainSetting = [];
    newchainSetting.map((item,index)=>{
      if(item == '连锁设置'){
        newArrChainSetting[0] = '连锁设置'
      }else if(item == '店铺设置'){
        newArrChainSetting[1] = '店铺设置'
      }else if(item == '跨店消费'){
        newArrChainSetting[2] = '跨店消费'
      }else if(item == '我的订单'){
        newArrChainSetting[3] = '我的订单'
      }
    })



    
    
    let newArrReportSetting = [];
    newreportSetting.map((item,index)=>{
      if(item == '总览'){
        newArrReportSetting[0] = '总览'
      }else if(item == '营业数据'){
        newArrReportSetting[1] = '营业数据'
      }else if(item == '业绩排名'){
        newArrReportSetting[2] = '业绩排名'
      }else if(item == '会员报表'){
        newArrReportSetting[3] = '会员报表'
      }
    })
    newArrReportSetting = newArrReportSetting.filter(item => item)
    newArrChainSetting = newArrChainSetting.filter(item => item)
    return (
      <div className="organ-right" style={{}}>
        <div className="head-organ-right">
          <div style={{}}>
            <span style={{ marginRight: 26,width:100 }}>岗位名称:</span>
            <span>{jobName}</span>
          </div>
          <div style={{ }}>
            <Button
              type="primary"
              // style={{ float: "right" }}
              onClick={this.onEditJob.bind(this)}
            >
              <span style={{ color: "#fff" }}>修改</span>
            </Button>
          </div>
        </div>
        <div style={{ display: "flex",width:'100%'  }}>
          <div className=""  style={{ marginTop:19,marginLeft:27,width:78,textAlign:'center'}}>
            权限:
          </div>
          <div style={{width:'calc(100% - 78px)'}}>
            <div
              style={{
                width: '100%',
                display: "flex",
                alignItems: "center",
                padding: "15px 0",
                borderBottom: "1px solid #d9d9d9"
              }}
            >
              <span style={{ width: 120 }}>连锁设置</span>
              {newArrChainSetting.length
                ? newArrChainSetting.map(item => (
                    <span style={{ marginRight: 25 }}>{item}</span>
                  ))
                : "-"}
            </div>
            <div
              style={{
                width: '100%',
                display: "flex",
                alignItems: "center",
                padding: "15px 0",
                borderBottom: "1px solid #d9d9d9"
              }}
            >
              <div style={{ width: 116 }}>报表设置</div>
              <div>
                {" "}
                {newArrReportSetting.length
                  ? newArrReportSetting.map(item => (
                      <span style={{ marginRight: 25 }}>{item}</span>
                    ))
                  : "-"}
              </div>
            </div>

            
            <div
              style={{
                width: '100%',
                display: "flex",
                alignItems: "center",
                padding: "15px 0",
                borderBottom: "1px solid #d9d9d9"
              }}
            >
              <div style={{ width: 116 }}>店铺设置</div>
              <div>
                {" "}
                {newshopSetting.length
                  ? newshopSetting.map(item => (
                      <span style={{ marginRight: 25 }}>{item}</span>
                    ))
                  : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  onEditJob() {
    this.setState({
      isShowSave: true,
      direction:'edit',
    });
  }
  onSaveJob() {
    const {direction,selectItem,jobName} = this.state;
   if(!jobName){
    messageError('请输入岗位名称')
    return;
   }
    this.setState({
      loading: true
    });
    switch(direction){
      case'plus':
      const ParentRoleId = selectItem.Id || 0
      this.requestSaveMchManagerRole(ParentRoleId,0,'save');
      this.setState({type:'save'})
      break;
      case 'edit':
        this.requestSaveMchManagerRole(selectItem.ParentRoleId,selectItem.Id,'edit');
        this.setState({type:'edit'})
      break;
    }
    
  }

  queryParameter(arr, querPara,direction) {
    arr.forEach((item, index) => {
      if (item.Id == querPara) {
        if(item.MchManagerRoles){
          this.setState({index:item.MchManagerRoles.length})
        }else{
          this.setState({index:0})
        }

        switch(direction){
          case 'select':
            //判断左移
            if(item.ParentRoleId){
              this.state.colorArr.left = '#fc1a6e'
              ////console.log('左边移动')
            }
            //判断右移和上移
            if(arr[index - 1]){
              this.state.colorArr.right = '#fc1a6e'
              this.state.colorArr.up = '#fc1a6e'
              ////console.log('右边和上移')
            }
            //判断下移
            if(arr[index + 1]){
              this.state.colorArr.down = '#fc1a6e'
              ////console.log('下移')
            }
            this.state.colorArr.close = '#fc1a6e'
            this.state.colorArr.plus = '#fc1a6e'
            this.setState({selectItem:item,jobName:item.Name,upSelectItem:arr[index - 1],selectArr:arr,colorArr:this.state.colorArr})

            this.getMchManagerRole(item.Id)
            break;
          case 'left':
            this.setState({selectItemFather:item})
            this.editMchManagerRoleParent(item.ParentRoleId);
            break;
          case 'right':
            
            break;
          case 'save':
            //判断左移
            if(item.ParentRoleId){
              this.state.colorArr.left = '#fc1a6e'
              ////console.log('左边移动')
            }
            //判断右移和上移
            if(arr[index - 1]){
              this.state.colorArr.right = '#fc1a6e'
              this.state.colorArr.up = '#fc1a6e'
              ////console.log('右边和上移')
            }
            //判断下移
            if(arr[index + 1]){
              this.state.colorArr.down = '#fc1a6e'
              ////console.log('下移')
            }
            this.state.colorArr.close = '#fc1a6e'
            this.state.colorArr.plus = '#fc1a6e'
            this.setState({selectItem:item,upSelectItem:arr[index - 1],selectArr:arr,colorArr:this.state.colorArr})

            break;
        }

        return
      } else {
        if (item.MchManagerRoles && item.MchManagerRoles.length) {
          this.queryParameter(item.MchManagerRoles, querPara,direction);
        }
      }
    });
  }

  //查权限
  getMchManagerRole(Id){
    const {selectItem} = this.state;
    let map = new Map();
    map.set("mchCode", mchCode);
    map.set("roleId", Id);
    
    $mzlApi.post(
      APINames.API_getMchManagerRole,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
    
  }

  //左右移动
  editMchManagerRoleParent(parentId){
    utils.setReqeustState(false);
    const {selectItem} = this.state;
    let map = new Map();
    map.set("mchCode", mchCode);
    map.set("roleId", selectItem.Id);
    map.set("parentId", parentId);
    
    $mzlApi.post(
      APINames.API_editMchManagerRoleParent,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
    
  }
   //上下移动
   editMchManagerRoleOrderNum(data){
    utils.setReqeustState(false);
     ////console.log(data)
    let map = new Map();
    map.set("mchCode", mchCode);
    map.set("mchManagerRoles",JSON.stringify(data));
    
    $mzlApi.post(
      APINames.API_editMchManagerRoleOrderNum,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
    
  }

  //删除岗位
  deleteMchManagerRole() {
    utils.setReqeustState(false);
    const { selectItem } = this.state;
    let map = new Map();
    map.set("mchCode", mchCode);
    map.set("roleId", selectItem.Id);
    $mzlApi.post(
      APINames.API_deleteMchManagerRole,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  render() {
    const { isShowSave, mchManagerRoles,colorArr,selectedKeys} = this.state;
    const loop = data =>
      data.map(item => {
        if (item.MchManagerRoles && item.MchManagerRoles.length) {
          return (
            <TreeNode key={item.Id} title={item.Name}>
              {loop(item.MchManagerRoles)}
            </TreeNode>
          );
        }
        return <TreeNode key={item.Id} title={item.Name} />;
      });


    return (
      <div className="organzation gra-border-pd15 comm-row comm-item-justify-between"
      style={{position: 'relative', height:'100%'}}>
        <div className="organ-left" >
          <div className="head-organ-left" style={{height:'100%'}}>
            <div
              className="comm-flex-row-between"
              style={{ height: '10%', justifyContent: "space-around" }}
            >
              <div
                className="arrow "
                onClick={this.onClickIcon.bind(this, "left")}
              >
                <Icon type="arrow-left"  style={{color:`${colorArr.left}`}} />
              </div>
              <div
                className="arrow right"
                onClick={this.onClickIcon.bind(this, "right")}
              >
                <Icon type="arrow-right" style={{color:`${colorArr.right}`}} />
              </div>
              <div
                className="arrow up"
                onClick={this.onClickIcon.bind(this, "up")}
              >
                <Icon type="arrow-up" style={{color:`${colorArr.up}`}} />
              </div>
              <div
                className="arrow down"
                onClick={this.onClickIcon.bind(this, "down")}
              >
                <Icon type="arrow-down" style={{color:`${colorArr.down}`}} />
              </div>
              <div
                className="arrow plus"
                onClick={this.onClickIcon.bind(this, "plus")}
              >
                <Icon type="plus" className="plus" style={{color:`${colorArr.plus}`}} />
              </div>
              <div
                className="arrow close"
                onClick={this.onClickIcon.bind(this, "close")}
              >
                <Icon type="close" style={{color:`${colorArr.close}`}} />
              </div>
            </div>
            <div style={{ overflow: "auto",height:'90%'}}>
            <h3 style={{textAlign:'center',marginTop:'0.5em'}}>{getMchName}</h3>
            {
              mchManagerRoles.length &&
              <Tree
              defaultExpandAll={true}
            // defaultSelectedKeys={['377']}
              selectedKeys={selectedKeys}
              className="draggable-tree"
              draggable
              blockNode
              onSelect={this.onClickListener.bind(this, "选择岗位")}
            >
              {loop(mchManagerRoles)}
            </Tree>
            }
            
            </div>
            
          </div>
        </div>
          <div style={{marginTop:20,width:1,height:'calc(100% -30px)',backgroundColor:'#d9d9d9'}}></div>
        {isShowSave ? this.showDivSave() : this.showDivEdit()}
        <div style={{height:0}}>
        <IModal title='提示' visible={this.state.delVisibal}
                        onOk={this.onOkHandelDel}
                        onCancel={()=>{this.setState({delVisibal:false})}}
                        view={<p style={{textAlign: 'center'}}>确定删除该岗位？</p>}></IModal>
        </div>
      </div>
    );
  }
}

export default postSetting;
