import axios from 'axios';
import {showMessageError,hideLoadding,setReqeustState} from '../comm/utils';
import StorageTools from "../comm/storageTools";
import {ApiConfig} from "../comm/constans";



/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        case 500:
            if(other !=null){
                showMessageError(other);
            }else{
                showMessageError("远程服务访问失败");
            }
            break;
        default:
            showMessageError("远程服务访问失败");
            // console.log(other);
}}



/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorCode = (code, other) => {
    // 状态码判断
    console.log(code,other)
    switch (code) {
        case 500:
            if(other !=null){
                showMessageError(other);
            }else{
                showMessageError("远程服务访问失败");
            }
            break;
        default:
            showMessageError("远程服务访问失败");

    }}

axios.defaults.withCredentials = true
// 创建axios实例
var instance = axios.create({timeout: 1000 * 30});
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8;';
instance.defaults.withCredentials = false;
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        if(config.url.indexOf(ApiConfig.wx.url+"/api/access_token") === -1){
            const token = StorageTools.getStoreToken();
            token && (config.headers.Authorization = token);
        }
        return config;
    },
    error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res =>{
        if(res.status === 200){
           if(res != null){
               if(res.data != null){
                   if(res.data.code == 0){
                       return  Promise.resolve(res.data);
                   }else if(res.data.access_token != null){
                        return  Promise.resolve(res.data);
                   }else{
                    return  Promise.resolve(res.data);
                   }
               }
               
           }
        }
        setReqeustState(true);
        hideLoadding();
        showMessageError("远程服务访问失败");
        return Promise.reject(res);

    },
    // 请求失败
    error => {
        setReqeustState(true);
        hideLoadding();
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorCode(response.status, response.data.msg);
            return Promise.reject(response); 
        } else {
            // 处理断网的情况
            showMessageError("远程服务访问失败");
            return Promise.reject(response);
        }
    });

export default instance;