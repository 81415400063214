const initState = {
    titleKey: '0',
    slidingLeftKey: '1',
    activationVisible:false,
    tipModalVisible:false,
    identityInfo:{},
    storeInfo:{},
    userInfo:{},
};

export const DEFAULT_TITLE = 'DEFAULT_TITLE';
export const SLIDING_LEFT = 'SLIDING_LEFT';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const MZL_DATA = 'MZL_DATA';
export const UPDATE = 'ICON_REDUX_UPDATE';

export function iconRedux(state = initState, action) {
    switch (action.type) {
        case DEFAULT_TITLE:
            return {...state, titleKey: action.data.titleKey, slidingLeftKey: action.data.slidingLeftKey};
        case SLIDING_LEFT:
            return {...state, slidingLeftKey: action.leftKey};
        case UPDATE_ORDER:
            return {...state, updateOrderSize: action.data.updateOrderSize}
        case MZL_DATA:
            return {...state, agreeTotalSize: action.data.agreeTotalSize}
        case UPDATE:
            return {...state,...action.data}
    }
    return state;
}


export function setDefaultTitle(key) {
    return {type: DEFAULT_TITLE, data: key}
}

export function setSlidingLeft(key) {
    return {type: SLIDING_LEFT, leftKey: key}
}

export function updateOrder(key) {
    return {type: UPDATE_ORDER, data: key}
}
export function updateAgreeTotalSize(key) {
    return {type: MZL_DATA, data: key}
}
export function update(data) {
    return {type: UPDATE, data: data}
}