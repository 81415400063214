import { ModuleTag } from "./constans";
import React from 'react';
import StorageTools from "./storageTools";
import APINames from "./api-names";
import { $agentApi } from "../comm/mzl-core";
import { message } from 'antd'
import mzlHttpApi from "./mzl-core";
import * as DateHelp from "./dateHelp";
import BuyUtils from "../buy/buyUtil";
import { showMessageError } from "./utils";

/**
 * 功能列表
 */
export function requestGetSettingsList(app) {

    var map = new Map();
    map.set('storeCode', StorageTools.getUserCode());
    $agentApi.post(APINames.API_getSettingsList, map, (res) => {
        StorageTools.saveUserModule(res)
    }, httpFail, app);
}


/**
 * 已购买的模块
 * "员工分红"  modFlag 16
 * "现金券"    modFlag 18
 * "抽奖"     modFlag 19
 * "股东分红"  modFlag 20
 *  查询所拥有权限
 */
export function requestNewGetAuthorization(app) {

    var map = new Map();
    map.set('storeCode', StorageTools.getUserCode());
    $agentApi.post(APINames.API_newGetAuthorization, map, (res) => {
        if (res != null) {
            StorageTools.saveBuyModule(res)
        }
    }, httpFail, app);
}




/**
 * 选择的版本
 * @constructor
 */
export function SelectVersion(that, callback) {
    
    
}


function httpFail(res, tag) {
    
}

/**
 * 根据ID获取购买模块的价格
 * @param tag
 */
export function getBuyModuleInfo(tag) {
    var moduleList = StorageTools.getUserModule();
    var data = {};
    if (moduleList != null) {
        moduleList.every(i => {
            if (i.modFlag == tag) {
                data = i;
                return false;
            }
            return true;
        })
    }
    return data;
}


/**
 * 显示错误的提示
 * @param data
 */
//todo: 格式应为(code,msg)，在外部传递的参数需调整
export function showMessageTopError(data, msg) {

    message.config({
        top: 100,
        duration: 1,
        maxCount: 3,
    });
    if (typeof (data) == 'string') {
        message.error(data)
    } else if (data != null && data.ErrMsg != null && data.ErrMsg != '') {
        message.error(data.ErrMsg)
        // notification.error(data.data.ErrMsg)
    } else if (typeof (data) == "number" && typeof (msg) == "string") {
        message.error(msg || "数据请求错误,请重试");
    } else {
        message.error("数据请求错误,请重试");
    }
}


/**
 * 成功提示
 * @param content
 */
export function messageTopSuccess(content) {
    message.config({
        top: 100,
        duration: 1,
        maxCount: 3,
    });
    message.success("　　" + content + "　　")
}


/**
 * 失败提示
 * @param content
 */
export function messageTopError(content) {
    message.config({
        top: 100,
        duration: 1,
        maxCount: 3,
    });
    message.error("　　" + content + "　　")
}



