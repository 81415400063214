import React, {Component} from 'react';
import './css/app.css';
import './css/comm.css';
import './css/iconfont/iconfont.css';
import {Button, Collapse, Dropdown, Icon, Layout, ConfigProvider, Menu} from 'antd';
import {setDefaultTitle, updateAgreeTotalSize, updateOrder} from "./js/reduxs/reducers/iconRedux";
import {updataCompany} from "./js/reduxs/reducers/shopSettingRedux";
import {connect} from 'react-redux'
import withRouter from "react-router-dom/es/withRouter"
// import 'moment/locale/zh-cn';
import StorageTools from "./js/comm/storageTools";
import zhCN from 'antd/es/locale/zh_CN'
import {$mzlApi, Env, httpPostUrl, notify} from "./js/comm/mzl-core"
import ApiConfig, {FnMenuId, mzlCopyright, mzlPhone, mzlVersion} from "./js/comm/constans";
import {copyProject, getIcon, messageError, messageInfo, log} from './js/comm/utils';
import TrafficLight from "./js/components/light";
import "./css/antd.less";
import * as DateHelp from "./js/comm/dateHelp";
import {ChainAppRoute} from "./routes/router";
import APINames from "./js/comm/api-names";
import baseService from "@src/base/baseService/baseService";
var service = new baseService();

const { Header } = Layout;
let   chainSetting = [],
      shopSetting = [],
      reportSetting = [],
       mchCode = '';


const reduxStaste = (state)=>{
    return {
    ...state.iconRedux,
    ...state.shopSetting,
    }
}       
@withRouter
@connect(
    state => reduxStaste(state),
    { updataCompany, setDefaultTitle, updateOrder,updateAgreeTotalSize}
)
class ChainApp extends Component {
    state = {
        toUrl: "",
        toRedirect: false,
        contentView: null,


    };

    constructor() {
        super();
        this.state = {
            slidingMenu: null,
            orderSize: 0,
            titleBar: [],
            useBar: [],
            notUsed: [],
            barVisible: false,
            isEditBar: true,
            visible: false,
            noticeData: [],
            noticeCount: 0,
            isShowNoticeRed: false,//通知提示红点
            noticePopData: {},
            noticePopVisible: false,
            timeOver: false,////版本时间到期
            timeOverHint: false,//过期提醒
            VersionInfo: {},//版本信息
            identyVisible: false,
            identyList: [],
            loginData: {},
            agreeTotalSize: {},
            readNotice:[],
            userName:''
        }
    }

    componentWillMount() {
       this.setState({ identyList: JSON.parse(StorageTools.getUserIdenty()) })
       mchCode = StorageTools.getMchCode()
    //    const ss = StorageTools.getMchInfo();
       chainSetting =  StorageTools.getmangleAuthority().chainSetting
       reportSetting = StorageTools.getmangleAuthority().reportSetting

    }
    

    componentDidMount() {
        this.requestGetStore();
        //初始化库存
        service.initializationPmsGoodsParameter(this,1);
        // this.props.history.push("/chain/settingContent/setting/chainasetting");
    }

/**
   * 获取企业信息
   */
  requestGetStore() {
    
    var map = new Map();
    map.set("mchCode", mchCode);
    $mzlApi.post(
      APINames.API_getMchMerchant,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }
  httpSuccess(res, tag) {
    switch (tag) {
      case APINames.API_getMchMerchant:
          if(res && res.Data && res.Data.mchMerchant){
            const shopInfomchMerchant = JSON.parse(res.Data.mchMerchant)
            //保存企业名字
        this.props.updataCompany({
          companyName:shopInfomchMerchant.CompanyName
        });
          }
        break;
     
    }
  }
  httpFail(res,tag){
  }

 

    onClickTitle(res) {
        if (res.key == FnMenuId.head_reset) { //切换账号
            notify.appSend("user-login-event", { event: "logout" });
            StorageTools.saveUserInfo({});
            // buyUtil.setBuyModule({});
            // buyUtil.setTimeOutVersion({});
            StorageTools.saveUserCode("");
            StorageTools.saveConfig({});
            StorageTools.saveMchCode("");
            StorageTools.saveMchInfo({});
            StorageTools.saveUserChainIdenflty([]);
            StorageTools.saveUserIdenty([]);
            this.props.history.push("/login");
            return;
        }
        switch (res.key) {
            
            case FnMenuId.head_chain_setting:
                  
                this.props.history.push("/chain/settingContent/setting/chainasetting");
                break;
            case FnMenuId.head_chain_report:
                    this.props.history.push("/chain/chainReport/overVier");
                break
            case FnMenuId.head_chain_account:
                this.props.history.push("/chain/accountsetting/index");
                break;
            case FnMenuId.head_chain_setting_index1:
                    this.props.history.push("/chain/chainindex");
                break;
            case FnMenuId.head_stock:
                    this.props.history.push("/chain/goodsContent/goodsManage");
                break;
           

        }

    }
    sldingMenu(key, text) {
        if (key == null) {
            return null;
        }
        return <Menu.Item key={key}>{text}</Menu.Item>
    }

    /**
     *导航条点击事件
     * @param tag
     */
    onClickDropdown(tag) {
        // console.log(tag)
        switch (tag) {
            case "hide":
                this.setState({
                    barVisible: false
                });
                break;
            case "dropdown":
                this.setState({
                    barVisible: !this.state.barVisible
                });
                break;
            default:
                break;
        }
    }


    onItemClick(res) {
        if (!this.state.isEditBar) {
            this.setNavigationData(res.isUsed, res);
            // this.state.useBar
        } else {
            this.onClickTitle(res);
        }
    }
    shopInfo() {
        this.props.history.push('/home/settingContent/shopInfo');
    }



    onClick(e) {
        // console.log()
        e.preventDefault();
        this.props.history.push("/login")
    };


    onClickListener(tag, e) {
        // console.log(tag, e)
        log(tag,e);
        switch (tag) {
            case "setting-app"://对话框取消
                this.setState({
                    DropdownVisible: true
                });
                break;
            case "settingPop"://对话框取消
                this.setState({
                    DropdownVisible: e
                });
                break;
            case "noticePopVisible"://对话框取消
                if (this.state.noticeData != null && this.state.noticeData.length > 0) {
                    if (this.state.isShowNoticeRed) {
                        this.state.isShowNoticeRed = false;
                        StorageTools.saveNoticeTime(this.state.noticeData[0].createTime);
                    }
                }
                this.setState({
                    noticePopVisible: false
                });
                break;
            case "timeOver"://关闭过期提醒
                // var vData = buyUtil.getTimeOutVersion();
                // vData.timeOver = false;
                // vData.timeOverHint = false;
                // vData.setTime = DateHelp.getCurrentTime();
                // vData.isClose = false;

                // StorageTools.saveTimeOutClose();
                // buyUtil.setTimeOutVersion(vData)
                this.setState(this);
                break;
        }

    }


 

    isEdit(key) {
        // if (key === FnMenuId.head_home || key === FnMenuId.head_collect || key === FnMenuId.head_order || key ===FnMenuId.head_agree) {
        if (key === FnMenuId.head_home || key === FnMenuId.head_collect || key === FnMenuId.head_order || key ===FnMenuId.head_agree) {
            return true
        } else {
            return false
        }
    }

   



    render() {

        // console.log("环境测试", this.props.titleKey)
        // const userInfo = StorageTools.getUserInfo();

        // if (userInfo == null) {
        //     notify.appSend("user-login-event", { event: "logout" });
        //     this.props.history.push("/login");
        //     return "";
        // }

        // 获取平台及是否包括外壳
        let os = Env.getOS();
        let frame = Env.isInFrame();
        let appContainerClass = "mod-pg-dash mod-os-" + os;
        if (frame) appContainerClass += " mod-pg-frame-dash-" + os;

        return (
            <ConfigProvider locale={zhCN}>
                <Layout className={appContainerClass} style={{background:"#fff"}}>
                    <Header class="gra-pg-title">
                        <div className="comm-row comm-item-justify-between" style={{alignItems:'center'}}>
                            <div style={{width:210,marginLeft:25,whiteSpace:'nowrap'}}>
                                <img src={require('./images/logo.svg')} alt="" style={{width:28,height:28}} />
                                <span style={{marginLeft:8}}>连锁后台</span>
                            </div>
                        
                            <Menu onClick={this.onClickTitle.bind(this)}
                                selectedKeys={[this.props.titleKey]}
                                mode="horizontal">
                                {/*{this.state.slidingMenu}*/}
                                <Menu.Item style={{ marginTop: "-10px" }} key={FnMenuId.head_chain_setting_index1}>
                                   首页
                                </Menu.Item>
                                {
                                   reportSetting.length > 0 && 
                                   <Menu.Item style={{ marginTop: "-10px" }} key={FnMenuId.head_chain_report}>
                                   报表
                                </Menu.Item>
                               }
                               <Menu.Item style={{ marginTop: "-10px" }} key={FnMenuId.head_stock}>
                                    库存
                                 </Menu.Item>
                                {
                                    chainSetting.length > 0 && 
                                    <Menu.Item style={{ marginTop: "-10px" }} key={FnMenuId.head_chain_setting}>
                                    连锁设置
                                 </Menu.Item>
                                }
                               

                            </Menu>

                            <div style={{ width: "100%",padding:"10px 0px",color:"white" }} className="gra-drag-region">　</div>

                            <Dropdown visible={this.state.DropdownVisible} onVisibleChange={this.onClickListener.bind(this, "settingPop")} overlay={<Menu onClick={this.onClickTitle.bind(this)}>
                                {/* {
                                    chainSetting.length > 0 &&
                                    <Menu.Item key={FnMenuId.head_chain_setting}>连锁设置</Menu.Item>
                                } */}
                                
                                <Menu.Divider></Menu.Divider>
                                <Menu.Item key={FnMenuId.head_chain_account}>账号设置</Menu.Item>
                                <Menu.Divider></Menu.Divider>
                                <Menu.Item key={FnMenuId.head_reset}>退出账号</Menu.Item>
                            </Menu>}>
                                <div className="comm-flex-row-center" style={{ marginRight: 30 }} onClick={this.onClickListener.bind(this, "setting-app")}>
                                    <div style={{ maxWidth: "120px", }} className="comm-text-over">{this.props.companyName}</div>
                                    <Icon type="down" />
                                </div>
                            </Dropdown>

                            {/* <TrafficLight /> */}
                        </div>
                    </Header>

                    <div style={{height:"94vh",background:"#fff"}}>
                        {ChainAppRoute()}
                    </div>
                    <div id="mzl-loading" className="loading-class"></div>
                   
                </Layout>
            </ConfigProvider>
        );
    }


    
}

export default ChainApp;
