import React from 'react'
import { Icon, Input, Button, Tooltip, Radio, Select, Upload, Checkbox ,DatePicker,Table, InputNumber } from 'antd'
import IButton from '@components/IButton'
import IDrawer from '@components/iDrawerPage'
import ISearch from '@components/ISearch'
import ITableList from '@components/iTableList'
import { IModal } from '@components/iModal'
import IInputString from '@components/iInputString'
import {IInputNumber,IInputMoney} from '@components/iInput'
import StorageTools from "@comm/storageTools";
import { $mzlApi } from "@comm/mzl-core";
import { messageError, messageSuccess,calcMultiply,calcDivide } from '@comm/utils';
import {numberConvert } from '@comm/dateHelp';
import ITablePage, { getPageSize, setTabTop } from '@components/iTablePage'
import { FnMenuId } from '@comm/constans'
import APINames from "@comm/api-names"
import { connect } from 'react-redux'
import { setDefaultTitle } from "@reducers/iconRedux";
import { goodsMode } from "@js/goods/action";
import { update } from "@js/goods/goodsManage/action";

import ItemVipView from "@js/settings/itemVipView";
import ItemRoleView from "@js/settings/itemRoleView";
import _ from "lodash";


import ModelType from "@js/goods/goodsManage/modelType";
import {SPmsGoods,SCashierReference,SPmsGoodsParameter} from "@js/goods/bean";
import { calcAdd, cloneDeep, showMessageError } from '../../../comm/utils'
import { UploadToOss } from '../../../comm/uploadimagMethod'
import BonusUmeditor from '../bonusUmeditor'
import service from '../../../services/marketing/bonusService'

import { SShActivityItem } from '../../../bean/market/bonus'
const RadioGroup = Radio.Group;
const ButtonGroup = Button.Group;
const dateFormat = 'YYYY-MM-DD';
const { MonthPicker, RangePicker } = DatePicker;
const iEnum = require("@comm/iEnum");
const { Option } = Select;
const { Search } = Input;
class Detail extends React.Component {
    constructor() {
        super();
        this.state = {
            saveData: {},
            tableData:[{}],
            cardList:[],
            serviceList:[],
            goodsList:[],
            goodsType:0,
            goodsId:0,
            btnKey:"2",
            columns : [
                {
                  title: '项目名称',
                  align: 'center',
                  dataIndex: 'ProductTitle',
                },
                {
                  title: '门市价',
                  align: 'center',
                  dataIndex: 'Price',
                },
                {
                  title: '体验价',
                  align: 'center',
                  render:(text,data)=>(
                    <IInputMoney 
                    value={data.ActivityPrice}
                    placeholder="请输入体验价"
                    style={{ width: 100, }}
                    onChange={this.onChange.bind(this, "ActivityPrice",data)}></IInputMoney>
                  )
                },
                {
                  title: '数量',
                  align: 'center',
                  render:(text,data)=>(
                    <IInputMoney 
                        value={data.Count}
                        placeholder="请输入数量"
                        style={{ width: 100, }}
                        onChange={this.onChange.bind(this, "Count",data)}
                     
                     ></IInputMoney>
                  )
                },
                {
                  title: '金额',
                  align: 'center',
                  dataIndex: 'Amount',
                },
                {
                  title: '操作',
                  align: 'center',
                  width:120,
                  render: (text,data,index) => (
                    <Icon type="close" onClick={this.onDel.bind(this,index)}/>
                  ),
                }
              ],
        }
    }

    componentDidMount() {
        service.getGoodsList(this,(res)=>{
            this.setState({
                serviceList:res.serviceList,
                goodsList:res.goodsList
            })
        })
    }
    onClickListener(tag,data){
        const {detailData} = this.props;
        let isAdd = true;
        detailData.ActivityItems.forEach(item=>{
            if(item.ProductId === data.ItemId){
                item.Count = item.Count+1;
                isAdd =false;
            }
        })
        if(isAdd){
            let obj = SShActivityItem();
            obj.ProductId =data.ItemId;
            obj.Price = data.Price/100;
            obj.ActivityPrice = data.Price/100;
            obj.Amount =  obj.ActivityPrice;
            obj.Count =1;
            obj.ProductTitle =data.Title;
            obj.ProductType =data.ProductType;
            obj.Cost =data.Cost;
            detailData.ActivityItems.push(obj);
        }
        this.setState(this);
        //  console.log(tag,data);
    }
    onChangeListener(tag,e){
        switch(tag){
            case 'changeItem':
                this.setState({btnKey:e})
                break;
        }
    }

    onChange(tag,data,e){
        data[tag]=e;
        this.setState(this)
  
      }

    onDel(index){
        const {detailData} = this.props;
        detailData.ActivityItems.splice(index,1);
        this.setState(this);

    }
    onSearch(val) {
        console.log('search:', val);
        
      }

      onSearch(tag,e) {
        var data = [];
        this.state.item.forEach(item => {
            for (var i = 0; i < item.ShortLetter.length; i++) {
                // //console.log(item.ShortLetter[i])
                if (item.Title == e || item.Title.indexOf(e) != -1 || item.ShortLetter.indexOf(e) != -1 || item.ShortLetter.indexOf(e.toUpperCase()) != -1 || item.ShortLetter[0].toLowerCase() == e || item.ShortLetter[0] == e || item.ShortLetter[i] == e) {
                    data.push(item);
                }
            }
        });
        this.setState({ item: [...new Set(data)] })
    }

    onSearch(e) {
        var data = [];
        this.state.cardItem.forEach(item => {
            for (var i = 0; i < item.ShortLetter.length; i++) {
                // //console.log(item.ShortLetter[i])
                if (item.Title == e || item.Title.indexOf(e) != -1 || item.ShortLetter.indexOf(e) != -1 || item.ShortLetter.indexOf(e.toUpperCase()) != -1 || item.ShortLetter[0].toLowerCase() == e || item.ShortLetter[0] == e || item.ShortLetter[i] == e) {
                    data.push(item);
                }
            }
        });
        this.setState({ cardItem: [...new Set(data)] })
    }

      /**
     * 改变文本框状态
     */
    changeRadio(tag,e) {
        const {detailData} = this.props;
        detailData._ExpireDay = e.target.value;
        this.setState(this);
        ////console.log('改变radio', e.target.value)
    }


    getAllMoeny(data){
        let allActivityPrice = 0;
        let allPrice = 0;
        data.forEach(item=>{
            item.Amount = calcMultiply(item.ActivityPrice ,item.Count);
            allActivityPrice = calcAdd(allActivityPrice,calcMultiply(item.ActivityPrice ,item.Count));
            allPrice = calcAdd(allPrice,calcMultiply(item.Price ,item.Count))  ;
        })
        return {
            allActivityPrice,
            allPrice
        }
    }

    
    render() {

        const {detailData} = this.props;
        const {btnKey} = this.state;
        // console.log(12313123,detailData)
        let allMoenyData = this.getAllMoeny(detailData.ActivityItems);
        return (
            <IDrawer 
                    loading={this.state.confirmLoadding} 
                    title={"活动项目"} 
                    footer={true} 
                    cancelHide={true} 
                    onCancel={this.props.onCancel}
                    onOK={this.props.onOK}
                    width={1100} 
                    visible={this.props.visible} >
                    <div >
                        <div class="comm-row comm-item-justify-between">
                            <div class="mod-time-card-form-side  " style={{width:240, marginTop: 10,border: '1px #e5e5e5 solid'}}>
                            <ButtonGroup style={{width:"240px",marginBottom:10}} >
                                <Button style={{width:"50%"}} type={btnKey == "2"?"primary":""} onClick={this.onChangeListener.bind(this, 'changeItem',"2")}>服 务</Button>
                                <Button style={{width:"50%"}} type={btnKey == "1"?"primary":""} onClick={this.onChangeListener.bind(this, 'changeItem',"1")}>商 品</Button>
                            </ButtonGroup>
                            {
                                btnKey == "2"?
                                <div>
                                    <div style={{ textAlign: 'center', borderBottom: '1px #e5e5e5 solid' }}>
                                            <Search
                                                placeholder="服务名称或首字母"
                                                onSearch={this.onSearch.bind(this)}
                                                onChange={this.onChangeListener.bind(this, '搜索服务')}
                                                style={{ width: 210, marginBottom: 10 }}
                                            />
                                    </div>
                                    <div >
                                        {
                                            this.state.serviceList.map((item, index) => {
                                                return (
                                                    <div  
                                                        style={{ borderBottom: '1px #e5e5e5 solid', padding: '7px 10px' }}
                                                        onClick={this.onClickListener.bind(this, 'clickItem', item)}
                                                        key={index}><Tooltip title={item._str}>{item.key}.{item._str}</Tooltip>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                :null
                            }
                            {
                                btnKey == "1"?
                                <div>
                                    <div style={{ textAlign: 'center', borderBottom: '1px #e5e5e5 solid' }}>
                                            <Search
                                                placeholder="商品名称或首字母"
                                                onChange={this.onChangeListener.bind(this, '搜索商品')}
                                                onSearch={this.onSearch.bind(this)}
                                                style={{ width: 210, marginBottom: 10 }}
                                            />
                                        </div>
                                        <div >
                                            {
                                                this.state.goodsList.map((item, index) => {
                                                    return (
                                                        <div 
                                                            style={{ borderBottom: '1px #e5e5e5 solid', padding: '7px 10px' }}
                                                            onClick={this.onClickListener.bind(this, 'clickItem', item)}
                                                            key={index}><Tooltip title={item._str}>{item.key}.{item._str}</Tooltip>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                </div>
                                :null
                            }
                            

                                
                            </div>
                            <div class="" style={{ flex: 1, background: '#fff', borderRadius: 6, padding: '10px' }}>
                                
                                    <div style={{ margin: '20px 0', marginTop: 0 }}>
                                        <span className="comm-text-red">*</span>
                                        <span style={{display: 'inline-Block' }}>活动名称：{detailData.ActivityName}</span>
                                    </div>
                                    <div style={{ marginBottom: 20, borderBottom: '1px #ECECEC solid', paddingBottom: 20 }}>
                                        <span className="comm-text-red">*</span>
                                        <span style={{ width: 100, display: 'inline-Block' }}>项目有效期</span>
                                        <RadioGroup value={detailData._ExpireDay}
                                            onChange={this.changeRadio.bind(this,"_ExpireDay")}>
                                            <Radio style={{ marginRight: 38 }} value={0}>永久有效</Radio>
                                            <Radio value={1}>购买后</Radio>
                                        </RadioGroup>
                                        <IInputNumber disabled={detailData._ExpireDay==0}
                                            onChange={this.onChange.bind(this, "ExpireDay",detailData)}
                                            value={detailData.ExpireDay} style={{ width: 70, marginRight: 10 }} />
                                        <span>日有效</span>
                                    </div>
                                    <Table pagination={false} size="small" columns={this.state.columns} dataSource={detailData.ActivityItems}/>
                                    <div style={{marginTop:10}}>
                                        <span>门市价：{allMoenyData.allPrice}元</span>
                                        <span style={{paddingLeft:50}}>体验价：{allMoenyData.allActivityPrice}元</span>
                                    </div>
                            </div>
                        </div>
                    </div>
                </IDrawer>

        )
    }
}

export default Detail