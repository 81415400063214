import React from "react";
import '../../css/regist.css'
import { $mzlApi, $userApi, notify } from '../comm/mzl-core'
import { AutoComplete, Button, Form, Input, Select } from 'antd';
import { md5ToLower, messageError, messageInfo, log, messageSuccess } from "../comm/utils";
import StorageTools from "../comm/storageTools";
import IButton from '../components/IButton'
import APINames from "../comm/api-names"
import { IModal } from '../components/iModal'
import { noCheck } from "../comm/constans";
import { messageTopError, showMessageTopError } from "../comm/api";
// var utils = require('../../comm/utils.js');
const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;

class resetPwd extends React.Component {
    state = {
        confirmDirty: false,
        btnText: '获取验证码',
        time: 60,
        autoCompleteResult: [],
        data: {},
        disabled: false,
        isSetting: false,
        userCode: '',
        identyList: [],
        identyAll: [],
        loginData: {},
        visible: false,
        tipVsible: false,
        type: 1,
    };

    componentDidMount() {
        console.log(this.props)
        console.log(this.props.location.search.split('?')[1])
        this.state.data.account = this.props.location.search.split('?')[1];
        this.state.type = this.props.location.search.split('?')[2];
        this.setState({ data: this.state.data, type: this.state.type })
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    /**
     * 根据手机号查userCode
     */
    requestAPI_getUserCode() {
        var map = new Map();
        map.set('user', this.state.data.account);
        $userApi.post(APINames.API_getUserCode, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }

    /**
     * 登录
     */
    requestLogin() {
        var map = new Map();
        map.set('user', this.state.data.account);
        map.set('pwd', this.state.data.pwd);
        map.set("cred ", "phone");
        $userApi.post(APINames.API_Login, map,
            this.success.bind(this), this.fail.bind(this), noCheck);
    }

    /**
     * 查询用户身份
     */
    requestQueryUserIdentity() {
        var map = new Map();
        map.set('userCode', this.state.userCode);
        map.set('appFlag', this.state.type == 1 ? 16 : 128);
        map.set('type', this.state.type)
        $mzlApi.post(APINames.API_queryUserIdentity, map, this.success.bind(this), this.fail.bind(this), this);
    }

    requestQueryUserIdentity2() {
        var map = new Map();
        map.set('userCode', this.state.userCode);
        map.set('appFlag', 128);
        map.set('type', this.state.type)
        $mzlApi.post(APINames.API_queryUserIdentity, map, (res) => {
            //console.log('所有身份',res)
            res.forEach(item => {
                if (item.IdentityFlag == 1) {
                    item._IdentityFlag = '老板'
                } else if (item.IdentityFlag == 2) {
                    item._IdentityFlag = '员工'
                } else if (item.IdentityFlag == 4) {
                    item._IdentityFlag = '收银员'
                } else if (item.IdentityFlag == 8) {
                    item._IdentityFlag = '会员'
                } else if (item.IdentityFlag == 16) {
                    item._IdentityFlag = '合作商家'
                }
            });
            this.setState({ identyAll: res, tipVisible: true })
        }, this.fail.bind(this), this);
    }

    /**
     * 查店铺信息
     */
    requestConfirmLogin() {
        var map = new Map();
        map.set('shopCode', this.state.loginData.ShopCode);
        map.set('userCode', this.state.userCode);
        map.set('empId', this.state.loginData.AppUid);
        $mzlApi.post(APINames.API_confirmLogin, map, this.success.bind(this), this.fail.bind(this), this);
    }
    //重置密码加密
    requestAPI_ResetPwdByCode() {
        var map = new Map();
        map.set('user', this.state.data.account);
        map.set('cred', 'phone');
        map.set('code', this.state.data.checkCode);
        map.set('pwd', md5ToLower(this.state.data.pwd));
        $userApi.post(APINames.API_ResetPwdByCode, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }

    /**
     * 发送验证码
     */
    requestAPI_sendCheckCode() {
        var map = new Map();
        map.set('phone', this.state.data.account);
        $userApi.post(APINames.API_sendCheckCode, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }
    success(res, tag) {
        StorageTools.saveUserInfo(res);
        log('success', res);
        switch (tag) {
            case APINames.API_ResetPwdByCode:
                if (res.ErrCode == 0) {
                    messageSuccess('修改成功！')
                    this.props.history.push('/login')
                }
                break;
            case APINames.API_checkCodeRight:
                //console.log('验证成功',res,tag);
                if (res.ErrCode == 0) {
                    this.setState({ isSetting: true })
                }
                break;
            case APINames.API_sendCheckCode:
                //console.log('成功', res, tag);
                // let timer = setInterval(() => {
                //     this.state.time--;
                //     this.setState({time: this.state.time, btnText: this.state.time + 's', disabled: true});
                //     if (this.state.time == 0) {
                //         clearInterval(timer);
                //         this.setState({btnText: '重新发送', time: 60, disabled: false})
                //     }
                // }, 1000)
                break;
                break;
            case APINames.API_getUserCode:
                if (!res.Data.userCode) {
                    messageError('账号不存在')
                } else {
                    this.setState({ userCode: res.Data.userCode }, () => {
                        this.requestQueryUserIdentity()
                    })

                }
                break;
            case APINames.API_Login:
                //console.log('成功', res, tag)
                StorageTools.saveStoreCode(res.Data.user_code);
                this.setState({ userCode: res.Data.user_code });
                this.requestQueryUserIdentity2();
                // this.requestQueryUserIdentity()
                break;
            case APINames.API_queryUserIdentity:
                var arr = [];
                res.forEach(item => {
                    item._borderColor = '#D8D8D8';
                    item._color = '#333';
                    if (item.IdentityFlag == 1) {
                        item._IdentityFlag = '老板'
                    } else if (item.IdentityFlag == 4) {
                        item._IdentityFlag = '收银员'
                    } else if (item.IdentityFlag == 64) {
                        item._IdentityFlag = item.Extra.RoleName
                    } else if (item.IdentityFlag == 128) {
                        item._IdentityFlag = item.Extra.RoleName
                    }
                    if (this.state.type == 2) {
                        item.ShopName = item.MchName
                    }
                    if (item.IdentityFlag == 1 || item.IdentityFlag == 4 || item.IdentityFlag == 64 || item.IdentityFlag == 128) {
                        arr.push(item)
                    }
                });
                if (this.state.type == 1) {
                    this.requestQueryUserIdentityChain()
                    this.setState({ identyList: arr });
                    StorageTools.saveUserIdenty(arr);
                    if (arr.length == 1) {
                        // StorageTools.saveShopMchCode(arr[0].mchCode)
                        this.setState({ loginData: arr[0], visible: false, isPwd: true, loginTitle: '登录' }, () => {
                            // this.requestConfirmLogin()
                        })
                    } else if (arr.length == 0) {
                        messageError('该手机号没有店铺权限')

                    } else {
                        // this.setState({ visible: true })
                        this.setState({ isPwd: true, loginTitle: '登录' })
                    }
                } else {
                    this.setState({ identyList: res });
                    if (arr.length == 1) {
                        this.setState({ loginData: arr[0], isPwd: true, loginTitle: '登录', visible: false, })
                    } else if (arr.length == 0) {
                        messageError('该手机号未注册连锁')
                    } else {
                        this.setState({ loginTitle: '登录', isPwd: true })
                    }

                    // StorageTools.saveMchInfo(res[0])
                    // this.props.history.push('/chain/ChainSetting');

                }
                break;
            case APINames.API_confirmLogin:
                //console.log('店铺信息', res, tag)
                this.setState({ visible: false });
                StorageTools.saveUserInfo(res);
                StorageTools.saveUserCode(res.StoreCode);
                StorageTools.saveStoreName(res.StoreName);
                StorageTools.saveMchId(parseInt(res.Extra.mchId))


                window.StoreName = res.StoreName;
                if (!notify.appSend("user-login", res)) {
                    //console.log("[ App][ Log]: 无法对客户端进行用户登陆通知")
                }
                if (res.StoreName == '') {
                    if (this.state.type == 1) {
                        this.props.history.push('/home/settingContent/shopSetting');
                        messageInfo('请完善店铺信息')
                    } else {
                        this.props.history.push('/chain/ChainIndex');
                        messageInfo('请完善企业信息')
                    }

                } else {
                    if (res.ErrCode == 0) {
                        // this.requestGetAuthorization();
                        if (this.state.type == 1) {
                            this.props.history.push('/home');
                        } else {
                            this.props.history.push('/chain/ChainSetting');
                        }

                        // this.requestgetSettingsList();
                    }
                }

                break;
        }
    }

    fail(res, tag) {
        // log('fail', res,tag)
        if (tag == APINames.API_ResetPwdByCode) {
            messageTopError('验证码不正确')
        } else {
            showMessageTopError(res, tag);
        }

    }

    onClickListener(tag) {
        switch (tag) {
            case '确定':
                if (!this.state.data.account) {
                    messageTopError('请输入手机号码')
                } else if (!this.state.data.checkCode) {
                    messageTopError('请输入验证码')
                } else if (!this.state.data.pwd) {
                    messageTopError('请输入密码')
                } else {
                    this.requestAPI_ResetPwdByCode();
                }

        }
    }

    onChangeListener(tag, e) {
        var data = this.state.data;
        switch (tag) {
            case 'phone':
                data.account = e.target.value;
                break;
            case 'pass':
                data.pwd = e.target.value;
                break;
            case 'code':
                data.checkCode = e.target.value;
                break;
            case '进入':
                //console.log(e)
                this.state.identyList.forEach(item => {
                    if (item.MchId == e.MchId) {
                        item._borderColor = '#A451F6';
                        item._color = '#fc1a6e'
                    } else {
                        item._borderColor = '#D8D8D8';
                        item._color = '#333'
                    }
                });
                this.setState({ loginData: e, identyList: this.state.identyList }, () => {
                    this.requestConfirmLogin()
                });
                break;
        }
        this.setState({ data: data })
    }

    getCode() {
        this.setState({ disabled: true });
        let timer = setInterval(() => {
            this.state.time--;
            this.setState({ time: this.state.time, btnText: this.state.time + 's' });
            if (this.state.time == 0) {
                clearInterval(timer);
                this.setState({ btnText: '重新发送', time: 60, disabled: false })
            }
        }, 1000);
        if (!this.state.disabled) {
            this.requestAPI_sendCheckCode()
        }


    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { autoCompleteResult } = this.state;
        return (
            <div className="mod-pg-login gra-drag-region">
                <div className="comm-w-h comm-col comm-item-center" style={{ position:'absolute',zIndex:99,top:0,bottom:0,left:0,right:0, margin: 'auto auto', height:580,width: 360, padding: '40px',backgroundColor:'#fff',borderRadius:'20px'  }} >
                    <img src={require("../../images/logo.svg")} width="60" height="60"  />
                    <div style={{ width: 370, padding: '40px', }}>
                        <div className="comm-flex-row-between" style={{ textAlign: 'center', marginBottom: 20 }}>
                            <span style={{ fontSize: 24, color: '#111', fontWeight: 600 }}>忘记密码</span>
                            <Button className="btn-register gra-no-drag" style={{width:100}}
                                    onClick={() => {
                                        notify.appSend("user-login-event", { event: "require" });
                                        this.props.history.push('/login')
                                    }}><span
                                style={{ color: '#111' }}>返回登录</span></Button>
                            </div>
                        <div className="login-user gra-no-drag">
                            <span style={{ color: '#111111' }}>手机号码</span>
                            <Input
                                maxLength='11'
                                style={{ borderBottom: '1px #ccc solid' }}
                                // className='borderNone'
                                // size="large"
                                placeholder="请输入手机号"
                                defaultValue={this.props.location.search.split('?')[1]}
                                // value={this.state.data.pwd}
                                name='pwd' onChange={this.onChangeListener.bind(this, "phone")} />
                        </div>
                        <div className="login-pwd gra-no-drag">
                            <span style={{ color: '#111111' }}>验证码</span>
                            <div style={{ position: 'relative' }}>
                                <Input style={{ borderBottom: '1px #ccc solid' }} placeholder='验证码'
                                    //    className='borderNone'
                                    onChange={this.onChangeListener.bind(this, 'code')} />
                                <a disabled={this.state.disabled} style={{
                                    position: 'absolute',
                                    right: 0,
                                    // top: 10,
                                    border: 'none',
                                    marginRight: 10,
                                    marginTop: 5
                                }} onClick={this.getCode.bind(this)}>{this.state.btnText}</a>
                            </div>
                        </div>
                        <div className="login-pwd gra-no-drag">
                            <span style={{ color: '#111111' }}>密码</span>
                            <Input
                                style={{ borderBottom: '1px #ccc solid' }}
                                // className='borderNone'
                                // size="large"
                                type="password" placeholder="请输入密码"
                                // value={this.state.data.pass}
                                onChange={this.onChangeListener.bind(this, "pass")} />

                        </div>
                        <Button type="primary" className="btn-login comm-col comm-item-center comm-item-justify gra-no-drag"
                            onClick={this.onClickListener.bind(this, '确定')}><span
                                style={{ color: "#fff" }}>提交</span></Button>
                        {/* <Button className="btn-register gra-no-drag"
                            onClick={() => {
                                notify.appSend("user-login-event", { event: "require" });
                                this.props.history.push('/login')
                            }}><span
                                style={{ color: '#111' }}>返回登录</span></Button> */}
                    </div>
                </div>
                <IModal
                    colse={true}
                    width='300px'
                    visible={this.state.tipVisible}
                    footer={null}
                    onCancel={() => {
                        this.setState({ tipVisible: false })
                    }}
                    view={
                        <div>
                            <p style={{ textAlign: 'center', marginTop: 10, color: '#333' }}>账号密码已设置！</p>
                            <div className='comm-flex-col-between'>
                                <div>你的</div>
                                {
                                    this.state.identyAll.map((item, index) => {
                                        return (
                                            <div key={index} style={{ marginTop: 10, color: '#a835ff' }}>
                                                <span
                                                    style={{ color: `${!item.ShopName ? 'red' : '#a835ff'}` }}>{item.ShopName ? item.ShopName : '-'}-{item._IdentityFlag}</span>
                                            </div>
                                        )
                                    })
                                }
                                <div style={{ marginTop: 10 }}>将全部使用新密码登录</div>
                                <IButton style={{ marginTop: 20 }} onClick={() => {
                                    this.setState({ tipVisible: false }, () => {
                                        this.requestQueryUserIdentity()
                                    })
                                }} type='primary' text='确定'></IButton>
                            </div>

                        </div>
                    }>

                </IModal>
                <IModal
                    colse={true}
                    width='300px'
                    visible={this.state.visible}
                    footer={null}
                    onCancel={() => {
                        this.setState({ visible: false })
                    }}
                    view={
                        <div>
                            <p style={{ textAlign: 'center', marginTop: 10, color: '#333' }}>请选择要进入的门店和岗位,我要进入</p>
                            <div className='comm-flex-col-between'>
                                {
                                    this.state.identyList.map((item, index) => {
                                        return (
                                            <div key={index} onClick={this.onChangeListener.bind(this, '进入', item)}
                                                style={{
                                                    boxShadow: '0px 5px 20px 0px rgba(0,0,0,0.1)',
                                                    color: `${item._color}`,
                                                    margin: '10px 0',
                                                    border: `1px ${item._borderColor} solid`,
                                                    borderRadius: 6,
                                                    width: '100%',
                                                    height: '50px',
                                                    lineHeight: '50px',
                                                    textAlign: 'center'
                                                }}>
                                                <span
                                                    style={{ color: `${!item.ShopName ? 'red' : item._color}` }}>{item.ShopName ? item.ShopName : '-'}-{item._IdentityFlag}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }>

                </IModal>

            </div>
        );
    }
}

const ResetPwd = Form.create()(resetPwd);
export default ResetPwd;
