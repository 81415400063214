/* eslint-disable no-unused-expressions */
import {createHash} from "crypto"
import {ApiConfig, noCheck} from "./constans";
import {log,messageError} from "./utils";
import sha1 from "./sha1"
import StorageTools from "./storageTools";
import APINames from "./api-names";
import axios from "axios";

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

function hex(hash, s) {
    return createHash(hash).update(s).digest('hex').toLowerCase();

    // if (hash === "md5") return md5(s);
    // if (hash === "sha1") {
    //     let h = sha1.create();
    //     h.update(s);
    //     return h.hex();
    // }
    // return "?";
}

let isKHD = false;


// 66掌柜核心服务
export const $mzlApi = new ApiClient(ApiConfig.mzl.url, ApiConfig.mzl.key,
    ApiConfig.mzl.secret, "sha1", "mzl", "2.4.0");

// 商店服务
export const $shopApi = new ApiClient(ApiConfig.shop.url, ApiConfig.shop.key,
    ApiConfig.shop.secret, "sha1", "shop", "2.4.0");


// 66掌柜小程序端
export var $micApi = new ApiClient(ApiConfig.mic.url, ApiConfig.mic.key,
    ApiConfig.mic.secret, "sha1", "mic", "2.4.0");

// 66掌柜用户端
export var $userApi = new ApiClient(ApiConfig.user.url, ApiConfig.user.key,
    ApiConfig.user.secret, "sha1", "user", "2.4.0");

// 66掌柜报表
export var $reportApi = new ApiClient(ApiConfig.report.url, ApiConfig.report.key,
    ApiConfig.report.secret, "sha1", "report", "2.4.0");

// 66掌柜短信
export var $smsApi = new ApiClient(ApiConfig.sms.url, ApiConfig.report.key,
    ApiConfig.report.secret, "sha1", "report", "2.4.0");

//代理
export var $agentApi = new ApiClient(ApiConfig.prod.url, ApiConfig.prod.key,
    ApiConfig.prod.secret, "sha1", "agent", "2.4.0");
//会员
export var $memberApi = new ApiClient(ApiConfig.member.url, ApiConfig.member.key,
    ApiConfig.member.secret, "sha1", "agent", "2.4.0");

export var getUserSign = new getApiData(ApiConfig.user.url, ApiConfig.user.key,
    ApiConfig.user.secret, "sha1", "user", "2.4.0");

//支付
export var getPaySign = new getApiData(ApiConfig.pay.url, ApiConfig.pay.key,
    ApiConfig.pay.secret, "sha1", "pay", "2.4.0");



// 接口客户端
function ApiClient(server, key, secret, signType, prod, prodVersion) {
    this.opts = {
        server: server,
        key: key,
        secret: secret,
        signType: signType,
        prod: prod,
        prodVersion: prodVersion
    };
    // 处理错误的HTTP请求
    this.handleFailedXhr = (errCode, errMsg) => {
    },
        this.post = function (api, params, success, fail, app) {
            if (app != null && app != noCheck) {
                if (app.props.location != null && app.props.location.search != '') {
                    isKHD = true;
                }
                // if (!isKHD) {
                //     if (StorageTools.getUserCode() == null || StorageTools.getUserCode() === '') {
                //         app.props.history.push("/login");
                //         return;
                //     }
                // }
            }
            // debugger
            // // 附加参数
            params.set("api", api);
            params.set("key", this.opts.key);
            if (api != APINames.API_checkPatch) {
                params.set("product", this.opts.prod);
                params.set("version", this.opts.prodVersion);
            }
            let sb = this.sortParams(params)
            let sign = hex(this.opts.signType, sb.toString() + this.opts.secret).toLowerCase();
            sb = sb + ("&sign=" + sign + "&sign_type=" + this.opts.signType);
            // this.axiosHttpPost(this.opts.server, api, sb, success, fail);
            this.httpPost(this.opts.server, api, sb, success, fail);
            return sb;





        };



    // 生成有序请求参数data
    this._getSortedQuery = function (params) {
        let s = {};
        params.forEach((value, key, map) => {
            if (null != value) {
                s[key] = value;
            }
        });
        let keys = Object.keys(s);
        keys.sort();
        let i = 0;
        let buffer = "";
        for (let k = 0; k < keys.length; k++) {
            let key = keys[k];
            let value = params.get(key);

            if (key === "sign" || key === "sign_type") continue;
            if (i++ > 0) buffer += "&";
            buffer += key + "=" + escape(value);
        }
        return buffer
    };

    /**
     * 生成有序请求参数data
     *
     * @param params
     * @param encode
     * @return
     * @throws Exception
     */
    this.sortParams = function (params) {
        let stringBuffer = ""; // 存储封装好的请求体信息
        let s = {};
        params.forEach((value, key, map) => {
            if (null != value) {
                s[key] = value;
            }
        });
        let list = Object.keys(s);
        list.sort();
        for (let i = 0; i < list.length; i++) {
            let key = list[i];
            let value = params.get(key);
            stringBuffer = stringBuffer + (key + "=" + value + "&");

        }
        stringBuffer = stringBuffer.substring(0, stringBuffer.length - 1)
        return stringBuffer;
    };
    /**
     * post请求
     */
    this.httpPost = function (url, api, data, successCallback, failCallback) {
        log('网络请求:'+url+","+api+",------>", data)
        failCallback = failCallback || this.handleFailedXhr;
        if (!Env.online()) {
            // console.log(1111)
            failCallback(2, "您已离线，请检查网络连接");
            // messageError('您已离线，请检查网络连接')
            return false;
        }
        // console.log();
        fetch(url, {
            method: "POST",
            body: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8 ;Access-Control-Allow-Origin:*',
            },
        }).then(function (response) {
            if (response.status !== 200) {
                failCallback(response.statusText, api);
                return;
            }
            response.text().then(function (response) {
                if (response[0] === '#') {
                    let arr = response.split("#");
                    switch (arr[1]) {
                        case "10090":
                            failCallback("内部错误：" + api, api);
                            break;
                        case "10093":
                            failCallback("API参数不正确", api);
                            break;
                        default:
                            failCallback(arr[1] + arr[2], api);
                            break;
                    }

                    return false;
                }
                if (response[0] === "{" || response[0] === "[") {
                    const json = JSON.parse(response);
                    if (json.ErrCode != null && json.ErrCode != 0) {
                        failCallback(json, api)
                    } else {
                        successCallback(json, api);
                    }
                    return;
                }
                if (response == null || response == "null") {
                    failCallback(response, api)
                    return;
                }
                if (response.ErrCode != null && response.ErrCode != 0) {
                    failCallback(response, api);
                } else {
                    successCallback(response, api);
                }

            })
        }, function (error) {
            (failCallback || this.handleFailedXhr)(1, error, api)
        })
    };

    /**
     * post请求
     */
    this.axiosHttpPost = function (url, api, data, successCallback, failCallback) {
        log('网络请求:'+url+","+api+",------>", data)
        failCallback = failCallback || this.handleFailedXhr;
        if (!Env.online()) {
            // console.log(1111)
            failCallback(2, "您已离线，请检查网络连接");
            // messageError('您已离线，请检查网络连接')
            return false;
        }
        // console.log();
        axios.post(url, data)
            .then(function (response) {
                if (response.status !== 200) {
                    failCallback(response.statusText, api);
                    return;
                }
                response = response.data;
                if (response[0] === '#') {
                    let arr = response.split("#");
                    switch (arr[1]) {
                        case "10090":
                            failCallback("内部错误：" + api, api);
                            break;
                        case "10093":
                            failCallback("API参数不正确", api);
                            break;
                        default:
                            failCallback(arr[1] + arr[2], api);
                            break;
                    }

                    return false;
                }
                if (response[0] === "{" || response[0] === "[") {
                    const json = JSON.parse(response);
                    if (json.ErrCode != null && json.ErrCode != 0) {
                        failCallback(json, api)
                    } else {
                        successCallback(json, api);
                    }
                    return;
                }
                if (response == null || response == "null") {
                    failCallback(response, api)
                    return;
                }
                if (response.ErrCode != null && response.ErrCode != 0) {
                    failCallback(response, api);
                } else {
                    successCallback(response, api);
                }

            }, function (error) {
                (failCallback || this.handleFailedXhr)(1, error, api)
            })
    };
}



// 接口客户端
function getApiData(server, key, secret, signType, prod, prodVersion) {
    this.opts = {
        server: server,
        key: key,
        secret: secret,
        signType: signType,
        prod: prod,
        prodVersion: prodVersion
    };
    this.getSign = function (api, params) {
        // debugger
        // // 附加参数
        params.set("api", api);
        params.set("key", this.opts.key);
        params.set("product", this.opts.prod);
        params.set("version", this.opts.prodVersion);
        let sb = this.sortParams(params);
        let sign = hex(this.opts.signType, sb.toString() + this.opts.secret).toLowerCase();
        return sign;
    };
    this.getPaySign = function (params) {
        
        // // 附加参数
        // params.set("key", this.opts.key);
        // params.set("product", this.opts.prod);
        // params.set("version", this.opts.prodVersion);
        let sb = this.sortParams(params);
        let sign = hex(this.opts.signType, sb.toString() + this.opts.secret).toLowerCase();
        // debugger
        return sign;
    };



    // 生成有序请求参数data
    this._getSortedQuery = function (params) {
        let s = {};
        params.forEach((value, key, map) => {
            if (null != value) {
                s[key] = value;
            }
        });
        let keys = Object.keys(s);
        keys.sort();
        let i = 0;
        let buffer = "";
        for (let k = 0; k < keys.length; k++) {
            let key = keys[k];
            let value = params.get(key);

            if (key === "sign" || key === "sign_type") continue;
            if (i++ > 0) buffer += "&";
            buffer += key + "=" + escape(value);
        }
        return buffer
    };

    /**
     * 生成有序请求参数data
     *
     * @param params
     * @param encode
     * @return
     * @throws Exception
     */
    this.sortParams = function (params) {
        let stringBuffer = ""; // 存储封装好的请求体信息
        let s = {};
        params.forEach((value, key, map) => {
            if (null != value) {
                s[key] = value;
            }
        });
        let list = Object.keys(s);
        list.sort();
        for (let i = 0; i < list.length; i++) {
            let key = list[i];
            let value = params.get(key);
            stringBuffer = stringBuffer + (key + "=" + value + "&");

        }
        stringBuffer = stringBuffer.substring(0, stringBuffer.length - 1)
        return stringBuffer;
    };

}




function httpGet(url, httpSuccess, failCallback, api) {
    failCallback = failCallback || this.handleFailedXhr;
    if (!Env.online()) {
        failCallback(2, "您已离线，请检查网络连接");
        return false;
    }
    fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }
    }).then(function (response) {
        // response.status //=> number 100–599
        // response.statusText //=> String
        // response.headers //=> Headers
        // response.url //=> String
        response.text().then(function (responseText) {
            var json = JSON.parse(responseText)
            if (json.code === 10000 || json.code === 0) {
                if (json.data == null) {
                    failCallback(json, api)
                    return;
                }

                if (json.data.ErrCode == null) {
                    httpSuccess(json, api);
                } else {
                    if (json.data.ErrCode == 0) {
                        httpSuccess(json, api);
                    } else {
                        failCallback(json, api)
                    }
                }
            } else {
                failCallback(json, api)
            }
        })
    }, function (error) {
        failCallback(error, api);
    })
}




export function qhttpGet(url, httpSuccess, failCallback) {
    failCallback = failCallback || this.handleFailedXhr;
    if (!Env.online()) {
        failCallback(2, "您已离线，请检查网络连接");
        return false;
    }
    fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        }
    }).then(function (response) {
        response.text().then(function (responseText) {
            var json = JSON.parse(responseText)
            if (json.code === 10000 || json.code === 0) {
                if (json.data == null) {
                    failCallback(json)
                    return;
                }

                if (json.data.ErrCode == null) {
                    httpSuccess(json);
                } else {
                    if (json.data.ErrCode == 0) {
                        httpSuccess(json);
                    } else {
                        failCallback(json)
                    }
                }
            } else {
                failCallback(json)
            }
        })
    }, function (error) {
        failCallback(error);
    })
}



/**
 * post请求
 */
export function httpPostUrl(url, data, httpSuccess, httpFail) {
    // debugger
    fetch(url, {
        method: "POST",
        body: data,
        mode: "cors",
        //     headers: {
        //        'Content-Type': 'multipart/form-data;'
        //    }
    }).then(function (response) {
        // response.status //=> number 100–599
        // response.statusText //=> String
        // response.headers //=> Headers
        // response.url //=> String
        response.text().then(function (responseText) {
            if (responseText == null || responseText.length === 0) {
                httpFail("数据为空:" + responseText, url);
                return false;
            }
            if (responseText[0] === '#') {
                let arr = responseText.split("#");
                httpFail(arr[1] + arr[2], url);
                return false;
            }
            if (responseText != null && responseText.length > 0 && responseText[0] === "<") {
                httpFail(response, url);
                return false;
            }
            var json = "";
            try {
                json = JSON.parse(responseText)
            }
            catch {
                httpFail("数据解析错误:" + responseText, url);
                return;
            }
            if (json["ErrCode"] == undefined) {
                httpSuccess(json, url);
                return;
            }

            if (json.ErrCode === 10000 || json.ErrCode === 0) {
                if (json["data"] == undefined) {
                    json["data"] = json["Data"];
                }
                httpSuccess(json, url);
            } else {
                httpFail(json, url);
            }
        })
    })
}



// 通知
export const notify = {
    getSender: () => {
        return undefined;
    },
    // 发送消息，@channel：通道,@msg： 消息
    send: (channel, msg) => {
        let e = this.getSender();
        if (e == null) return;
    },
    // 应用通知，返回true，则表示客户端接收到了通知
    appSend: (channel, msg) => {
        if (window.navigator.userAgent.indexOf("Electron") == -1) {
            return false;
        }
        let id = "$send-msg-[" + channel + "]";
        let f = document.getElementById(id);
        if (f == null) {
            f = document.createElement("IFRAME");
            f.setAttribute("id", id);
            f.style.display = 'none';
            document.body.appendChild(f);
        }
        if (msg instanceof Object) {
            msg = JSON.stringify(msg);
        }
        f.setAttribute("src", `mzl://send-msg?channel=${channel}&data=${msg}`);
        return true;
    },
}
export const Env = {
    OS_UNKNOWN: 0,
    OS_WINDOWS: 1,
    OS_MAC_OX: 2,
    OS_LINUX: 3,
    // 获取操作系统标志
    getOS: function (userAgent) {
        //return this.OS_WINDOWS;
        let agent = userAgent || window.navigator.userAgent;
        if (agent.indexOf("Linux") != -1) return this.OS_LINUX;
        if (agent.indexOf("Mac") != -1) return this.OS_MAC_OX;
        if (agent.indexOf("Windows") != -1) return this.OS_WINDOWS;
        return this.OS_UNKNOWN;
    },
    // 是否包含在外壳中
    isInFrame: function () {
        //return true;
        return window.navigator.userAgent.indexOf("Electron") != -1;
    },
    // 是否联网
    online: () => {
        return window.navigator.onLine;
    }
};
export default {
    "$mzlApi": $mzlApi,
    "$micApi": $micApi,
    "$userApi": $userApi,
    "$reportApi": $reportApi,
    '$agentApi': $agentApi,
    httpGet: httpGet,
    httpPostUrl: httpPostUrl,
    qhttpGet: qhttpGet,
    notify: notify,
    Env: Env,
};