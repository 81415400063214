import IDrawer from '@components/iDrawerPage'
import { IInputMoney } from '@components/iInput'
import IInputString from '@components/iInputString'
import ITextArea from '@components/iTextAreaString'
import ITableList from '@components/iTableList'
import { update } from "@js/goods/action"
import GoodsModel from "@js/goods/goodsInto/goodsModel"
import { Button, Input, Select, Tabs ,Steps,Tooltip} from 'antd'
import React from 'react'
import { connect } from 'react-redux'

import {getTime} from "@comm/dateHelp"
import {calcMultiply,calcAdd} from "@comm/utils"
import service from '../../../services/marketing/bonusService'

let begin = 0;
let over = 20;
const { Step } = Steps;
const { Option } = Select;
const reduxStaste = (state) => {
    return {
        ...state.goodsData,
    }
}
@connect(
    state => reduxStaste(state),
    { update }
)
class ActivityDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            allActivityPrice:0, //收取金额
            tableData : [],
            userList : [],
            selectValue:0,
        }
    }

   

    componentDidMount() {
         this.getPageData();
         this.queryShUser();
     }

     getPageData() {
       const {detailData} = this.props;
      let opt ={ Begin: 0, Over: 999,Opt:{activityId:this.props.detailData.Id}};
      
      // map.set("opt", JSON.stringify({ Begin: begin, Over: over,Opt:{beginTime, overTime ,keyword,createType ,state ,applicationTypeId}}));
      // let time = new  Date().getTime()/1000;
      service.queryShCustomer(this,opt).then(res=>{
        let data = JSON.parse(res.Data);
        let allActivityPrice = 0;
        data.forEach(item => {
          item._CreateTime =  getTime(item.CreateTime);
          item.Price =  detailData.Price;
          item.ActivityPrice =  detailData.ActivityPrice;
          allActivityPrice = calcAdd(allActivityPrice,detailData.ActivityPrice) ;
        })
        this.setState({
          tableData:data,
          allActivityPrice
        })
      })


    }
    
    queryShUser(){
      let opt = { Begin: begin, Over: 9999};
      service.queryShUser(this, opt).then(res => {
        let data = JSON.parse(res.Data);
        let userList = [];
        data.forEach(item => {
          let obj = {};
          obj.value = item.Id;
          obj.lable = item.Name;
          userList.push(obj);
        })
        this.setState({
          userList
        })
      })
    }
 


    selectChange(tag,e) {
       
    }

    onShowSizeChange() {

    }

    handleChange(e){
      this.setState({
        selectValue:e
      })
    }

    listDate(){

      const {tableData,selectValue} = this.state;
      if(selectValue == 0){
        return tableData;
      }
      let arr = [];
      tableData.forEach(item=>{
        if(item.UserId == selectValue){
          arr.push(item);
        }
      })
      return arr;
    }

 
      
 
  
    render() {
      const {detailData} = this.props;
      const {tableData,selectValue} = this.state;
      const columns = [
        {
          width:80,
          title: '序号',
          dataIndex: 'name',
          align: 'center',
          render: (text,record,index) => <span>{index+begin+1}</span>,
        },
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'Name',
        },
        {
          title: '手机',
          align: 'center',
          dataIndex: 'Phone',
        },
        {
          title: '领取时间',
          align: 'center',
          dataIndex: '_CreateTime',
          width:170
        },
        {
          title: '活动名称',
          align: 'center',
          dataIndex: 'ActivityName',
        },
        {
          title: "门市价",
          align: 'center',
          render:(text)=>(<span>{detailData.Price}</span>)
        },
        {
          title: "体验价",
          align: 'center',
          render:(text)=>(<span>{detailData.ActivityPrice}</span>)
        },
        {
          title: "拓客股东",
          align: 'center',
          dataIndex: 'UserName',
        },
      ];

     
        return (
            <IDrawer
                title={"详情"} 
                footer={false} 
                onCancel={this.props.onCancel}
                width={"90%"} 
                visible={this.props.visible}
            >
                <div className="comm-w-h">
                  <div className="comm-flex-row-between" style={{marginBottom:10}}>
                      <div className="comm-flex-row-center">
                          联盟姓名:
                          <Select defaultValue="0" style={{ width: 120,marginLeft:8 }} onChange={this.handleChange.bind(this)}>
                              <Option value="0">全部</Option>
                              {
                                this.state.userList.map(item=>(
                                  <Option value={item.value}>{item.lable}</Option>
                                ))
                              }
                          </Select>
                      </div>
                      <div>
                        <span style={{paddingLeft:20}}>发放数量：{detailData.Count}</span>
                        <span style={{paddingLeft:20}}>领取数量：{detailData.UseCount}</span>
                        <span style={{paddingLeft:20}}>剩余数量：{detailData.Count-detailData.UseCount}</span>
                        <span style={{paddingLeft:20}}>收取金额：{this.state.allActivityPrice}</span>
                      </div>
                  </div>
                    <ITableList 
                        top={170}
                        columns={columns} 
                        data={this.listDate()}
                        totalSize={this.state.totalSize}>

                    </ITableList>
                </div>
                 
               
            </IDrawer>

        )
    }
}

export default ActivityDetail