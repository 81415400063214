import React from 'react';
import {Popover} from 'antd';
import "../../css/iHelpPop.css"
import {$mzlApi,httpPostUrl} from "../comm/mzl-core";
import {ApiConfig} from "../comm/constans";
import api from "../comm/api-names";

// import EditableCell from './EditableCell'
var isContent = false;
var isLoad = false;

/**
 * 帮助中心样式
 */
export class IHelpPop extends React.Component {
    constructor() {
        super();
        this.state = {
            isContent: false,//是否移动到内容上
            isLoad: false,//是否移动到标题上
            visible: false,//是否显示pop
            popData: {},//帮助中心Data
        }

    }

    handleVisibleChange = (visible) => {
        // this.props.data.visible = visible
        this.setState({
            visible: false
        });
    };

    componentDidMount() {
        // var ll = new HelpBean.helpMenuItem();
        // ////console.log(111111,ll)

    }


    /**
   * 帮助中心
   */
  requestQueryMenuItem() {
    var map = new FormData();
    map.set("url", api.API_queryMenuItem);
    map.set("params", JSON.stringify({"itemCode":this.props.code,"menuId":"0"}));
    $mzlApi.post(api.API_getProdApi, map,(res)=>{
      if(res != null && res.Data != null && res.Data.data != null){
        this.setState({
            popData: JSON.parse(res.Data.data),
            visible: true
        });
        // this.setState({

        // })
       
      }
    }, this.httpFail.bind(this), this);

  }
    httpSuccess(res, tag) {
        if (tag === ApiConfig.admin.url + api.API_queryMenuItem) {
            if (res != null && res.data != null) {
                this.setState({
                    popData: res.data,
                    visible: true
                });
            }
        }

        ////console.log("httpSuccess",tag,res);
    }

    httpFail(res, tag) {
        ////console.log("httpFail",tag,res)
    }


    onMouseOver(tag, res) {
        var that = this;
        if (tag == "div") {
            this.setState({
                isLoad: true
            });
            // isLoad = true;
           
            setTimeout(() => {
                if (this.state.isLoad) {
                    that.requestQueryMenuItem();
                }
            }, 1500)
        } else {
            // isContent = true;
            this.setState({
                isContent: true
            })

        }
    }


    onMouseOut(tag, res) {
        if (tag == "div") {
            // isLoad = false;
            this.setState({
                isLoad: false
            });
            this.setPopVisible();
        } else {
            // isContent = false;
            this.setState({
                isContent: false,
            });
            this.setPopVisible();

        }


    }


    setPopVisible() {
        // ////console.log("setPopVisible",isLoad,isContent)
        // debugger
        setTimeout(() => {
            if (!this.state.isContent && !this.state.isLoad) {
                this.setState({
                    visible: false
                })
            }
        }, 1000)
    }


    render() {

        const content = <div 
            onClick={() => {}} 
            className="help-content" 
            onMouseOver={this.onMouseOver.bind(this, "content")}
            onMouseOut={this.onMouseOut.bind(this, "content")}>
            <div className="text">
                <div>
                    <a href={ApiConfig.help.url + `helpPage/${this.state.popData.id}`} target="_blank"> 
                        {this.state.popData.sketch}
                    </a>
                </div>
            </div>
            {
                this.state.popData.video==""?
                null:
                <div className="help-all">
                <a href={ApiConfig.help.url + `helpPageVideo/${this.state.popData.id}`} target="_blank"> 
                    <i className="iconfont comm-text-main" style={{fontSize:16,paddingTop:10}}>&#xe6d9;</i>
                    <span style={{marginLeft:5,marginTop:-5}}>帮助视频</span>
                </a>
            </div>
            }
            
        </div>; 

        return (
            <Popover placement="topLeft"
                     content={content}
                     trigger="click"
                     visible={this.state.visible}
                     onVisibleChange={this.handleVisibleChange} arrowPointAtCenter>
                <span onMouseOver={this.onMouseOver.bind(this, "div")} onMouseOut={this.onMouseOut.bind(this, "div")}>
                    {this.props.div ? this.props.div : React.Children.map(this.props.children, function (child) {
                                return <div >{child}</div>
                            })}
                    {this.props.showNum && this.props.showNum>0?<span className="help-pop-red comm-flex-row-center">{this.props.showNum>99?"99.":this.props.showNum}</span>:null}
                </span>
            </Popover>

        );
    }
}







