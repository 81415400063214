import React from "react";
import gaode from 'react-amap';


class GaodeMap extends React.Component {

    componentWillMount() {

        var that = this;
        const cb = (msg) => {
            that.props.addAddress(msg)
        };
        // let userData = JSON.parse(this.props.match.params.data)
        this.amapEvents = {
            created: (mapInstance) => {
                var AMapUI = window.AMapUI;
                var AMap = window.AMap;
                AMapUI.loadUI(['misc/PositionPicker'], function (PositionPicker) {

                    var map, geolocation;
                    map = new AMap.Map('container', {
                        zoom: 14,
                        scrollWheel: false
                    });

                    AMap.plugin('AMap.Geolocation', function () {
                        geolocation = new AMap.Geolocation({
                            enableHighAccuracy: true,//是否使用高精度定位，默认:true
                            timeout: 10000,          //超过10秒后停止定位，默认：无穷大
                            maximumAge: 0,           //定位结果缓存0毫秒，默认：0
                            convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
                            showButton: true,        //显示定位按钮，默认：true
                            buttonPosition: 'LT',    //定位按钮停靠位置，默认：'LB'，左下角
                            buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                            showMarker: false,        //定位成功后在定位到的位置显示点标记，默认：true
                            showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
                            panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
                            zoomToAccuracy: true      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                        });
                        map.addControl(geolocation);
                        geolocation.getCurrentPosition();
                        AMap.event.addListener(geolocation, 'complete', onComplete);//返回定位信息
                        AMap.event.addListener(geolocation, 'error', onError);      //返回定位出错信息


                        function onComplete(data) {
                            that.setState({
                                lng: data.position.lat,
                                lat: data.position.lng,
                                address: data.address
                            })
                        }

                        function onError(data) {

                        }
                    });
                    var positionPicker = new PositionPicker({
                        mode: 'dragMap',
                        map: map
                    });

                    positionPicker.on('success', function (positionResult) {
                        that.setState({
                            lng: positionResult.position.lng,
                            lat: positionResult.position.lat,
                            address: positionResult.address,
                            province: positionResult.regeocode.addressComponent.province,
                            city: positionResult.regeocode.addressComponent.city,
                            district: positionResult.regeocode.addressComponent.district
                        });
                        {
                            cb(that.state)
                        }


                    });
                    positionPicker.on('fail', function (positionResult) {

                    });
                    var onModeChange = function (e) {
                        positionPicker.setMode(e.target.value)
                    };

                    positionPicker.start();
                    map.panBy(0, 1);

                });
            }
        };
        this.state = {
            address: "",
            modelData: {
                contentText: "",
                confirm: "确定",
                confirmState: true,
                cancel: "取消",
                cancelState: false
            },
        }

    }


    render() {

        return (

            <div>
                {/*<div className="div-hint">*/}
                {/*<Button type="primary" style={{width:"300px"}} onClick={this.onClickLister.bind(this)}> 确定</Button>*/}
                {/*</div>*/}
                <div
                    id='container'
                    style={{
                        width: '880px',
                        height: '750px'
                    }}>
                    <gaode.Map amapkey="75935ba903f71d87d371976876d09ed6" events={this.amapEvents}>
                        {/*<Marker position={this.markerPosition} events={this.markerEvents} />*/}
                    </gaode.Map>
                </div>

            </div>
        );
    }
}

export default GaodeMap;