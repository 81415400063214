import React from 'react'
import {Input} from 'antd'

const Search = Input.Search;
export default class ISearch extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            <Search
                enterButton
                className={this.props.className}
                placeholder={this.props.placeholder}
                style={this.props.style}
                onChange={this.props.onChange}
                onSearch={this.props.onSearch}></Search>
        )
    }
}