import IDrawer from '@components/iDrawerPage'
import { IInputMoney } from '@components/iInput'
import IInputString from '@components/iInputString'
import ITextArea from '@components/iTextAreaString'
import ITableList from '@components/iTableList'
import { update } from "@js/goods/action"
import GoodsModel from "@js/goods/goodsInto/goodsModel"
import { Button, Input, Select, Tabs ,Steps,Tooltip} from 'antd'
import React from 'react'
import { connect } from 'react-redux'

import {getTime} from "@comm/dateHelp"
import {calcMultiply} from "@comm/utils"
import service from "@js/goods/service";

let begin = 0;
let over = 20;
const { Step } = Steps;
const reduxStaste = (state) => {
    return {
        ...state.goodsData,
    }
}
@connect(
    state => reduxStaste(state),
    { update }
)
class model extends React.Component {
    constructor() {
        super();
        this.state = {
              tableData : []

        }



    }

   

    componentDidMount() {
      
     }
 
    


    selectChange(tag,e) {
       
    }

    onShowSizeChange() {

    }

    onClickListener(tag){
      
    }


  
    render() {

        const {detailData} = this.props;

        return (
            <IDrawer
                title={"详情"} 
                footer={false} 
                onCancel={this.props.onCancel}
                width={"400px"} 
                visible={this.props.visible}
            >
                <div className="comm-w-h">
                    <div className="comm-title-view">
                        <div className="comm-title-right">姓名</div>
                        <span>{detailData.Name}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">手机</div>
                        <span>{detailData.Phone}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">加入时间</div>
                        <span>{detailData._CreateTime}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">股东活动</div>
                        <span>{detailData.PlanName}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">入股金额</div>
                        <span>{detailData.Funds/100}元</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">股东期限</div>
                        <span>{detailData._ExpiresTime}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">上级股东</div>
                        <span>{detailData.ParentUserName}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">银行卡号</div>
                        <span>{detailData.BankAcName}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">银行户名</div>
                        <span>{detailData.BankCardNo}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">支付宝</div>
                        <span>{detailData.AliNo}</span>
                    </div>
                    <div className="comm-title-view">
                        <div className="comm-title-right">微信</div>
                        <span>{detailData.WxNo}</span>
                    </div>
                </div>
                 
               
            </IDrawer>

        )
    }
}

export default model