/***************************接口名称***************************************/
const APIConfig = {
    login: "/login", //登录
    shopSettingSave:"/shopSetting/save",//保存门店设置
    shopClassesSelect:'/shopClasses/select',//查询店铺班次设置
    shopClassesSave:'/shopClasses/save',//新增店铺班次设置
    shopClassesUpdate:'/shopClasses/update',//修改店铺班次设置
    shopScheduleSave:'/shopSchedule/save',//新增店铺人员排版
    shopScheduleCopy:'/shopSchedule/copy',//copy店铺人员排版
    shopSettingSelect:'/shopSetting/select',//查询门店预约设置
    shopScheduleSelect:'/shopSchedule/select',//查询店铺人员排班
    shopProjectSave:'/shopProject/save',//新增店铺预约项目
    shopProjectSelectByStoreCode:'/shopProject/selectByStoreCode',//查询预约项目
    shopProjectUpdate:'/shopProject/update',//修改预约项目
    shopPersonnelSave:'/shopPersonnel/save',//新增预约员工设置
    shopPersonnelSelectByStroeCode:'/shopPersonnel/selectByStroeCode',//查询员工预约状态及项目
    selectByStoreCode:'/shopProject/selectByStoreCode',//查询门店可以预约项目
    spSelectByStroeCode:'/shopPersonnel/selectByStroeCode',//根据店铺code和用户Id查询预约状态以及项目
    soSelect:'/shopOrder/select',//根据stroeCode&userId&查询预约
    shopOrderSave:'/shopOrder/save',//新增店铺预约
    shopOrderUpdate:'/shopOrder/update',//修改预约状或预约信息
    shopOrderUpdateStatus:'/shopOrder/updateStatus',//占用或者不占用



    API_activateBySerialNo:'prod_serial_no/activateBySerialNo',//版本激活
    API_getUserProd:'prod_serial_no/getUserProd',//查询版本




    //扫码付接口
    add:'/add',//添加商户
    img:'/img',//图片转换
    adm:'/adm',//省市区查询
    bank:'/bank',//联行查询
    queryMerchantSelf:'/queryMerchantSelf',//内部商户查询
    queryMerchant:'/queryMerchant',//三方商户查询

    getAdapayMerchantInfoEntity:'getAdapayMerchantInfoEntity',//查询店铺信息
    executeMerchantEntry:'executeMerchantEntry',//商户进件
    executeMerchantResident:'executeMerchantResident',//执行一个商户入驻
    queryMerchantEntry:'queryMerchantEntry',//查询的商户进件对象
    executeUpload:'executeUpload',//照片上传
    executeAudit:'executeAudit',//商戶照片上传
    markMerchantcomplete:'markMerchantcomplete',//标记完成
    deleteMerchant:'deleteMerchant',//删除进件



};
export default APIConfig;