import React from 'react';
import { Button,Select , Input,DatePicker} from 'antd';

import ISelect from './ISelect'
import IRangePicker from './IRangePicker'
import moment from 'moment';

import  { daysAgo, getConversionDate, getTime,getfirstTimeandEndtime } from '../comm/dateHelp'
var dateFormat = 'YYYY-MM';
const { Option } = Select;
const { Search } = Input;
const { MonthPicker } = DatePicker;

export default class IButton extends React.Component {
    constructor() {
        super();
        this.state={
            defaultDay:"1",
            startTime: daysAgo(0),
            endTime: daysAgo(0) + 24 * 60 * 60 - 1,
        }
    }

    getView(){
        const {titleViewData} = this.props;
        let left = [];
        let right = [];
        titleViewData.forEach(element => {
            if(element.direction === "right"){
                right.push(element);
            }else{
                left.push(element);
            }
            
        });
        return(
            <div className="comm-w comm-flex-row-between">
                {this.getTitleView(left)}
                {this.getTitleView(right)}
            </div>
        )
    }


    getTitleView(list){
        // console.log(981231,list)
        return(
            <div className="comm-row">
                {
                    list.map(element => (
                            this.getItem(element)
                    ))
                }
            </div>
        );
    }


    changeDate(data,beginTime, overTime,e) {
        // console.log(211122,data,beginTime,overTime,e)
        this.setState({ startTime: beginTime, endTime: overTime, defaultDay: e==null?"":e });
        data.onChange({beginTime, overTime})

    }

    onMonthPickerChange(data,e) {
        // console.log(211122,data,beginTime,overTime,e)
        // this.setState({ startTime: beginTime, endTime: overTime, defaultDay: e==null?"":e });
        data.onChange(getfirstTimeandEndtime(e))

    }


    getItem(data){
        
        if(data.dateFormat){
            dateFormat = data.dateFormat;
        }

        switch(data.type){
            
            case "Button":
                return(
                    <Button size={data.size}
                        loading={data.loading}
                        type={data.type ? data.type : "primary"}
                        disabled={data.disabled}
                        className={data.className}
                        style={data.style}
                        href={data.href}
                        onClick={data.onClick}>
                    </Button>
                ) 
            case "Select":
                return (
                    <div className="comm-flex-row-center" style={{marginRight:10}}>
                        {data.lable==null?null:data.lable}
                        <Select defaultValue="0" style={data.style?data.style:{ width: 120,marginLeft:8 }} onChange={data.onChange}>
                            {data.list.map(item=>(
                                <Option value={item.value}>{item.lable}</Option>
                            ))}
                        </Select>
                    </div>
                ) 
            case "SelectRangePicker":
              
                return (
                    <div className="comm-flex-row-center" style={{marginRight:10}}>
                        <ISelect value={this.state.defaultDay} onChange={this.changeDate.bind(this,data)} />
                        <IRangePicker
                            onChange={this.changeDate.bind(this,data)}
                            value={[moment(getConversionDate(this.state.startTime), dateFormat), moment(getConversionDate(this.state.endTime), dateFormat)]}
                        />
                    </div>
                ) 
            case "DatePickerMonth":
                return (
                    <MonthPicker 
                    allowClear={false}
                     placeholder="请选择日期"
                     onPanelChange={data.onPanelChange}
                     onChange={this.onMonthPickerChange.bind(this,data)}
                     />
                ) 
            case "Search":
                return (
                    <Search
                        placeholder={data.placeholder?data.placeholder:"请输入"}
                        enterButton={data.lable?data.lable:"查询"}
                        style={data.style?data.style:{width:300}}
                        onSearch={data.onSearch}
                    />
                ) 
        }
    }

    handleChange(){

    }

    render() {
        return this.getView();
    }
}
