import { getConversionDate, getTime } from '@comm/dateHelp'
import ITableList from '@components/iTableList'
import { Button, Select, Tabs } from 'antd'
import React from 'react'
import '../../../../css/report.css'
import { ActivityState, SShPlan } from '../../../bean/market/bonus'
import service from '../../../services/marketing/bonusService'
import Detail from "./detail"




let begin = 0;
let over = 20;
const { TabPane } = Tabs;
const { Option } = Select;
class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            content: "",
            detailData:SShPlan(),
            totalSize:0,
            enabled:0,
            columns : [
                {
                  width:80,
                  title: '序号',
                  dataIndex: 'name',
                  align: 'center',
                  render: (text,record,index) => <span>{index+begin+1}</span>,
                },
                {
                  title: '分红方案名称',
                  align: 'center',
                  dataIndex: 'PlanName',
                },
                {
                  title: '入股资金',
                  align: 'center',
                  dataIndex: 'PlanFee',
                },
                {
                  title: '活动有效期',
                  align: 'center',
                  dataIndex: '_activityTime',
                  width:170
                },
                {
                  title: '状态',
                  align: 'center',
                  dataIndex: '_stateText',
                  render:(text,data)=>(
                    <span>{data.Enabled ===1?data._stateText:"已停止"}</span>
                  )
                },
                {
                  title: '最近编辑',
                  align: 'center',
                  dataIndex: '_editTime',
                },
                {
                  title: '操作',
                  align: 'center',
                  width:120,
                  render: (text,data) => (
                      <div className="comm-flex-row-center">
                          {
                            data.Enabled ===1?
                            (
                              data._stateText == "未开始"|| data._stateText == "进行中"?
                              <a onClick={this.onDelete.bind(this,data,2)}>停止</a>:null
                            ):
                            (
                              data._stateText == "已结束"?null:<a onClick={this.onDelete.bind(this,data,1)}>开启</a>
                            )
                          }
                          <a style={{marginLeft:8}} onClick={this.onDetail.bind(this,data)}>{data._stateText == "未开始"?"编辑":"查看"}</a>
                         
                          
                      </div>
                  ),
                }
              ],
            tableData:[],
            detailVisible:false
        }
    }
    componentWillMount() {
    }
    componentDidMount() {
      begin = 0;
      over=20;
      this.getPageData();
    }

    getPageData(){
      let opt ={ Begin: begin, Over: over,Opt:{enabled:this.state.enabled}};
      let time = new  Date().getTime()/1000;
      // map.set("opt", JSON.stringify({ Begin: begin, Over: over,Opt:{beginTime, overTime ,keyword,createType ,state ,applicationTypeId}}));
      service.queryShPlan(this,opt).then(res=>{
        let data = JSON.parse(res.Data);
        data.forEach(item => {
          item._activityTime= getConversionDate(item.BeginTime)+"-"+getConversionDate(item.OverTime);
          item.PlanFee = item.PlanFee/100;
          if(item.UpdateTime ===0){
            item._editTime = "-";
          }else{
            item._editTime = getTime(item.UpdateTime)
          }
          if(item.BeginTime> time){
            item._stateText = "未开始"
          }else if(item.BeginTime< time && item.OverTime>time){
            item._stateText = "进行中"
          }else{
            item._stateText = "已结束"
          }
        })
        this.setState({
          tableData:data,
          totalSize:res.Count
        })
      })
    }

    /**
     * 详情
     * @param {*} data 
     */
    onDetail(detail){
      if(detail === "add"){

        let data = SShPlan()
        data._stateText = "未开始"
        this.setState({
          detailData:data,
          detailVisible:true
        })
      }else{
        service.getShPlan(this,detail.Id).then(res=>{
            let data = JSON.parse(res.Data.data);
            if(detail._stateText == "未开始"){
              data._stateText = "未开始"
            }
            this.setState({
              detailVisible:true,
              detailData:data
            })
            
          }
        );
      }
      
    }
    /**
     * 删除
     * @param {*} data 
     */
    onDelete(data,state){
      service.enabledShPlan(this,data.Id,state).then(res=>{
        begin = 0;
        over=20;
        this.getPageData();
        this.setState(this);
      });
    }

    onTablePageCallback(start, end){
      begin = start;
      over = end;
      this.getPageData();
    }
  
    onClickListener(){

    }

    handleChange(e){
      this.setState({
        enabled:e
      },()=>this.getPageData());
    }
    render() {
        return (
            <div>
                <div className="comm-flex-row-between">
                    <div className="comm-flex-row-center">
                        状态:
                        <Select defaultValue="0" style={{ width: 120,marginLeft:8 }} onChange={this.handleChange.bind(this)}>
                            <Option value="0">全部</Option>
                            <Option value="1">启用</Option>
                            <Option value="2">停用</Option>
                        </Select>
                    </div>
                    <Button type="primary" onClick={this.onDetail.bind(this,"add")}>添加分红活动</Button>
                </div>

                <ITableList 
                     style={{marginTop:10,marginBottom:20}}
                     pagination={true}
                     top={260}
                     columns={this.state.columns} 
                     data={this.state.tableData}
                     totalSize={this.state.totalSize}
                     onTablePageCallback={this.onTablePageCallback.bind(this)}>

                </ITableList>
                {
                  this.state.detailVisible?
                  <Detail 
                    onOK={()=>{
                        this.getPageData();
                        this.setState({
                          detailVisible:false
                        })
                      }} 
                    onCancel={()=>{
                      this.setState({
                        detailVisible:false
                      })
                    }} 
                    visible={this.state.detailVisible} 
                    detailData={this.state.detailData}>

                    </Detail>:null

                }
                
                
            </div>



        )
    }
}

export default Index