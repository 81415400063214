import { $mzlApi } from '@comm/mzl-core';
import BaseService from '../../../base/baseService/baseService';

import { getPaySign } from '../../comm/mzl-core'
import api from '../../api/api-config';
import ApiConfig from "../../comm/constans"

import constans from "../../comm/api-names";
class BonusService extends BaseService {

    constructor() {
        super();
    }



    getGoodsList(that, callback) {
        let serviceList = [], goodsList = [];

        let index = 0;
        this.requestQueryItem(that).then(res => {
            var data = JSON.parse(res.Data);
            var num = 1;
            data.forEach(item => {
                item.key = num;
                num++;
                item._str = item.Title + '(' + this.utils.numberConvert(item.Price) + '元' + ')'
            });
            serviceList = data;
            index++;
            if (index == 2) {
                callback({
                    serviceList,
                    goodsList
                })
            }
        });
        this.requestGoodsList(that).then(res => {
            console.log('商品', res)
            var num = 1;
            var data = JSON.parse(res.Data);
            data.forEach(item => {
                item.key = num;
                num++;
                item._str = item.Title + '(' + this.utils.numberConvert(item.Price) + '元' + ')'
            });
            goodsList = data;
            index++;
            if (index == 2) {
                callback({
                    serviceList,
                    goodsList
                })
            }
        });

    }

    getCardList(that, callback) {
        let cardList = [], cardTimeList = [];
        this.requestMchCardList(that).then(res => {
            cardList = JSON.parse(res.Data);
            this.requestTimeCard(that).then(res => {
                cardTimeList = JSON.parse(res.Data);
                callback({
                    cardList,
                    cardTimeList
                });
            })
        })

    }

    /**
     * 获取联盟股东方案
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
    queryShPlan(that, opt) {
        const t = this;
        
        var p = new Promise(function (resolve, reject) {
            // let ddd = t.APINames;
            // de
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("opt",JSON.stringify(opt));
            t.$mzlApi.post(t.APINames.API_queryShPlan, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

    /**
    *  修改股东方案
    * @param {*} that 
    * @param {*} param 
    * @returns 
    */
    saveShPlan(that, plan) {
        const t = this;
        let params = new Map();
        params.set("storeCode", t.storageTools.getUserCode());
        params.set("plan", JSON.stringify(plan));
        var p = new Promise(function (resolve, reject) {
            t.$mzlApi.post(t.APINames.API_saveShPlan, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
    /**
    *  获取股东方案
    * @param {*} that 
    * @param {*} param 
    * @returns 
    */
    getShPlan(that, planId) {
        const t = this;
        let params = new Map();
        params.set("storeCode", t.storageTools.getUserCode());
        params.set("planId", planId);
        var p = new Promise(function (resolve, reject) {
            t.$mzlApi.post(t.APINames.API_getShPlan, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
    /**
    *  删除股东方案
    * @param {*} that 
    * @param {*} param 
    * @returns 
    */
    deleteShPlan(that, planId) {
        const t = this;
        let params = new Map();
        params.set("storeCode", t.storageTools.getUserCode());
        params.set("planId", planId);
        var p = new Promise(function (resolve, reject) {
            t.$mzlApi.post(t.APINames.API_deleteShPlan, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
    /**
    *   新增拓客活动
    * @param {*} that 
    * @param {*} param 
    * @returns 
    */
    saveShActivity(that, activity) {
        const t = this;
        let params = new Map();
        params.set("storeCode", t.storageTools.getUserCode());
        params.set("activity",JSON.stringify(activity));
        var p = new Promise(function (resolve, reject) {
            t.$mzlApi.post(t.APINames.API_saveShActivity, params,
                (res) => {
                    // t.utils.hideLoadding();
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

    /**
    *   删除拓客活动
    * @param {*} that 
    * @param {*} param 
    * @returns 
    */
     deleteShActivity(that, activityId) {
        const t = this;
        let params = new Map();
        params.set("storeCode", t.storageTools.getUserCode());
        params.set("activityId", activityId);
        var p = new Promise(function (resolve, reject) {
            t.$mzlApi.post(t.APINames.API_deleteShActivity, params,
                (res) => {
                    // t.utils.hideLoadding();
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

    /**
    * 获取拓客活动
    * @param {*} that 
    * @param {*} param 
    * @returns 
    */
     getShActivity(that, activityId) {
        const t = this;
        let params = new Map();
        params.set("storeCode", t.storageTools.getUserCode());
        params.set("activityId", activityId);
        var p = new Promise(function (resolve, reject) {
            t.$mzlApi.post(t.APINames.API_getShActivity, params,
                (res) => {
                    // t.utils.hideLoadding();
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

    /**
    * 停启方案
    * @param {*} that 
    * @param {*} param 
    * @returns 
    */
     enabledShPlan(that, planId,enabled) {
        const t = this;
        let params = new Map();
        params.set("storeCode", t.storageTools.getUserCode());
        params.set("planId", planId);
        params.set("enabled", enabled);
        var p = new Promise(function (resolve, reject) {
            t.$mzlApi.post(t.APINames.API_enabledShPlan, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

    /**
    * 停启活动 
    * @param {*} that 
    * @param {*} param 
    * @returns 
    */
     enabledShActivity(that, activityId,state) {


        const t = this;
        let params = new Map();
        params.set("storeCode", t.storageTools.getUserCode());
        params.set("activityId", activityId);
        params.set("state", state);
        var p = new Promise(function (resolve, reject) {
            t.$mzlApi.post(t.APINames.API_enabledShActivity, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }


      /**
     * 获取拓客活动
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       queryShActivity(that, opt) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("opt", JSON.stringify(opt));
            t.$mzlApi.post(t.APINames.API_queryShActivity, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

      /**
     * 获取所有顾客
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       queryShCustomer(that, opt) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("opt", JSON.stringify(opt));
            t.$mzlApi.post(t.APINames.API_queryShCustomer, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

      /**
     * 获取所有股东
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       queryShUser(that, opt) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("opt", JSON.stringify(opt));
            t.$mzlApi.post(t.APINames.API_queryShUser, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

      /**
     * 获取顾客
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       getShCustomer(that, customerId) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("customerId", customerId);
            t.$mzlApi.post(t.APINames.API_getShCustomer, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
      /**
     * 获取股东
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       getShUser(that, userId) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("userId", userId);
            t.$mzlApi.post(t.APINames.API_getShUser, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
      /**
     * 获取分红明细
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       queryShDividendsDetail(that, opt,userId) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("opt", JSON.stringify(opt));
            t.$mzlApi.post(t.APINames.API_gueryShDividendsDetail, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
    
      /**
     * 获取分红账单
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       queryShUserDividendsBill(that, opt) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("opt", JSON.stringify(opt));
            t.$mzlApi.post(t.APINames.API_queryShUserDividendsBill, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }

    
    
      /**
     * 获取股东分红钱款额
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       getShUserDividendsAmount(that, userId) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("userId", userId);
            t.$mzlApi.post(t.APINames.API_getShUserDividendsAmount, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
    
      /**
     * 获取次级股东分红详情
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       getSubordinateUserDividendsDetails(that, userId) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("userId", userId);
            t.$mzlApi.post(t.APINames.API_getSubordinateUserDividendsDetails, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
    
      /**
     * 标记股东账单
     * state 1 待结算 2 代确认 3 已结算
     * @param {*} that 
     * @param {*} param 
     * @returns 
     */
       markUserBill(that, userId,billId,state) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            let params = new Map();
            params.set("storeCode", t.storageTools.getUserCode());
            params.set("userId", userId);
            params.set("billId", billId);
            params.set("state", state);
            t.$mzlApi.post(t.APINames.API_markUserBill, params,
                (res) => {
                    resolve(res);
                },
                (res, tag) => {
                    t.httpFail(that, res, tag)
                },
                that
            );
        })
        return p;
    }
    









    httpFail(that, res, tag) {
        this.utils.hideLoadding();
        this.utils.showMessageError(res);
        if (that.state.loading) {
            that.setState({
                loading: false
            })
        }

    }





}
export default new BonusService();
