import React from "react";
import {
  Button,
  Cascader,
  Form,
  Icon,
  Input,
  Radio,
  Select,
  Checkbox,
  Tree
} from "antd";
import APINames from "../../../../js/comm/api-names";
import { FnMenuId } from "../../../../js/comm/constans";
import iEnum from "../../../../js/comm/iEnum";
import StorageTools from "../../../../js/comm/storageTools";
import { $mzlApi } from "../../../../js/comm/mzl-core";
import { setDefaultTitle } from "../../../../js/reduxs/reducers/iconRedux";
import { connect } from "react-redux";
import "../../../../css/settting.css";
import { IModal } from "../../../../js/components/iModal";
import {
  checkIdCard,
  messageError,
  moneyConvert,
  phoneCheck

} from "../../../../js/comm/utils";
import { messageTopError } from "../../../../js/comm/api";
import ITablePage, { getPageSize,setTabTop } from "../../../../js/components/iTablePage";
import IInputString from "../../../../js/components/iInputString";
import IDrawer from "../../../../js/components/iDrawer";
import { ISpanMust } from "../../../../js/components/iSpan";
import IButton from "../../../../js/components/IButton";
import "./chainsetting.css";
import { relative } from "path";
const utils = require("../../../../js/comm/utils.js");
const constantConvert = require("../../../../js/comm/constantConvert.js");

const CheckboxGroup = Checkbox.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const FormItem = Form.Item;
const { TreeNode } = Tree;
const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 20 }
};
let begin = 0;
let over = getPageSize(); 
let mchCode =''
let flatMap;
// const formItemLayout = {
//     labelCol: {
//         xs: {span: 14},
//         sm: {span: 14},
//     },a
//     wrapperCol: {
//         xs: {span: 14},
//         sm: {span: 8},
//     },
// };

/**
 * 员工设置
 */
@connect(
  state => state.iconRedux,
  { setDefaultTitle }
)
class StaffSettingView extends React.Component {
  constructor() {
    super();
    this.state = {
      disabled: false,
      modalData: { JobRole: -1 },
      visible: false,
      title: "",
      tipVisible: false,
      sex: 1,
      selsectTag: "on",
      options: [
        {
          value: "请选择岗位",
          label: -1,
          RoleId: -1
        }
      ],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "sequen",
          align: "center",
          className: "table-class-w-10"
        },
        {
          title: "姓名",
          dataIndex: "Name",
          key: "name",
          align: "center",
          className: "table-class-w-14"
        },
        {
          title: "手机",
          dataIndex: "Phone",
          key: "phone",
          align: "center",
          className: "table-phone"
        },
        {
          title: "岗位",
          dataIndex: "NameWork",
          key: "post",
          align: "center",
          className: "table-class-w-14"
        },
        // {
        //     title: '性别',
        //     dataIndex: 'SexText',
        //     key: 'sex',
        //     align: 'center'
        // },
        // {
        //     title: '工号',
        //     dataIndex: 'WorkId',
        //     key: 'wages',
        //     align: 'center',
        //     className: "table-class-w-14",
        // },
        // {
        //     title: '收银权限',
        //     dataIndex: 'CashierText',
        //     key: 'cashier',
        //     align: 'center'
        // },
        {
          title: "状态",
          dataIndex: "WorkStateText",
          key: "state",
          align: "center"
        },
        // {
        //     title: '岗位提成设置',
        //     dataIndex: 'Postset',
        //     key: 'setting',
        //     align: 'center',
        //     className: "table-Name",
        //     render: (text, record) => (
        //         <a onClick={() => this.props.history.push('/home/settingContent/postSetting')}>{text}</a>
        //     )
        // },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          align: "center",
          render: (text, record) => (
            <a href="javaScript:" onClick={this.modifyStaff.bind(this, record)}>
              <Icon type="form" />
            </a>
          )
        }
      ],
      data: [],
      loading: false,
      hasMore: true,
      tipMsg: "",
      isShow: false,
      defaultChecked: [],
      isCash: 0,
      visibleJob: false,
      selectJobType: "",
      selectJob: "",
      mchManagerRoles: [],
      selectItem: {},
      status:1,
      Count:0,
      JobArr:[],
      staffArr:[],
      loading:false
    };
  }

  // }
  /**
   * 清除数据
   * @returns {{}}
   */
  clearData = () => {
    let modalData = {};
    modalData.Id = 0;
    modalData.MchId = 0; //工号
    modalData.Phone = ""; //手机
    modalData.UserCode='';
    modalData.Code = '';
    modalData.WorkNo ='';
    modalData.Name = ""; //名字
    modalData.CardNo = ""; //身份证
    modalData.Sex = 1; //性别
    modalData.LowWage = 0; //基本工资
    modalData.State = 1; //在职情况
    modalData.jobAuthority = ""; //岗位权限
    modalData.partTimeJob = ""; //兼职权限
    modalData.Auth = 0;
    modalData.Avatar = '';
    modalData.password = ''
    modalData.empRoles = []
    return modalData;
  };

  onChange = (tag, e) => {
    if (tag == "role") {
      this.state.modalData.JobRole = e[0];
      this.setState({
        modalData: this.state.modalData
      });
      ////console.log('radio checked', e);
      return;
    }
    ////console.log('radio checked', e.target.value);
    let modalData = this.state.modalData;
    switch (tag) {
      case "Sex":
        modalData.Sex = e.target.value;
        break;
      case "WorkState":
        modalData.State = e.target.value;
        break;
      case "Cashier":
        modalData.Auth = e.target.value;
        this.setState({ isCash: e.target.value });
        if (e.target.value == 2) {
          this.setState({ isShow: true });
        } else {
          this.setState({ isShow: false });
        }
        break;
      case "权限":
        var sum = 2;
        var arr = [];
        e.forEach(item => {
          sum += item;
          arr.push(item);
        });
        modalData.Auth = sum;
        this.setState({ defaultChecked: arr });
    }
    this.setState({
      modalData: modalData
    });
  };

  changeSelect(value) {
    // allData: list,
    //     onData:onData,
    //     leaveData:leaveData,

    switch (value) {
      case "all":
        this.state.status = 0
        
        break;
      case "on":
          this.state.status = 1
        break;
      case "leave":
          this.state.status = 3
        break;
    }
    this.setState({status:this.state.status})
    // this.queryMchManagerRole();
    this.requestEmployeeList();
    ////console.log(`selected ${value}`);
  }

  handleCancle() {
    this.setState({ visible: false });
  }

  addStaff() {
    // let modalData = {}
    // modalData.ID = 0;
    // modalData.WorkId = "";
    // modalData.Phone = "";
    // modalData.RealName = "";
    // modalData.IdCard = "";
    // // modalData.JobRole = this.state.options ? this.state.options[0].RoleId : "";
    // modalData.JobRole = null;
    // modalData.Sex = 1;
    // modalData.LowWage = 0;
    // modalData.WorkState = 1;
    // modalData.Cashier = 0;
    this.setState({
      visible: true,
      isCash: 0,
      title: "添加员工",
      modalData: this.clearData(),
      disabled: false,
      isShow: false,
      defaultChecked: []
    });
   
  }

  /**
   * 编辑员工
   * @param data
   */
  modifyStaff(data) {
    const {mchManagerRoles} = this.state;
    if (data.Auth == 0) {
      this.setState({ isShow: false, isCash: 0 });
    } else {
      this.setState({ isShow: true, isCash: 2 });
    }
    if (data.Auth != 0) {
      var arr = [1, 4, 8, 16, 32, 64];
      var arr2 = [];
      var num = data.Auth - 2;
      arr.forEach(item => {
        if (num & item) {
          arr2.push(item);
        }
      });
    }
    // jobAuthority partTimeJob//type 1为主岗位，2为服岗位
    data.empRoles.map((item,index)=>{
        // this.getMchManagerRole(item.RoleId)
    })
    this.setState({
      visible: true,
      title: "编辑员工",
      modalData: data,
      disabled: true,
      defaultChecked: arr2
    });
  }
  //查权限
  getMchManagerRole(Id){
    const {selectItem} = this.state;
    let map = new Map();
    map.set("mchCode", mchCode);
    map.set("roleId", Id);
    
    $mzlApi.post(
      APINames.API_getMchManagerRole,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
    
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        ////console.log('Received values of form: ', values);
      }
    });
  }

  componentWillMount() {
    mchCode =StorageTools.getMchCode()
    setTabTop(180);
    this.props.setDefaultTitle({
      titleKey: FnMenuId.head_chain_setting,
      slidingLeftKey: "1"
    });
  }

  componentDidMount() {
    this.queryMchManagerRole();
    utils.showLoadding();
    this.requestEmployeeList();
    // this.requestStoreRole();
  }

  changeListener(tag, e) {
    let data = this.state.modalData;
    console.log(23423,this.state.modalData)
    switch (tag) {
      case "phone":
        data.Phone = e.target.value;
        break;
      case "name":
        // money.replace(/^0*(0\.|[1-9])/, '$1');
        data.Name = e;
        break;
      case "WorkId":
        // var text = e;
        // if(e.target.value.length==1){
        //     data.WorkId=e.target.value.replace(/[^1-9]/g,'')
        // }else{
        //     data.WorkId=e.target.value.replace(/\D/g,'')
        // }

        // data.WorkId=text.replace(/[^0-9a-zA-Z]/g,'')
        data.WorkNo = e;

        // data.WorkId = e.target.value.replace(/[^\w\.\/]/ig, "")
        break;
      case "IdCard":
        // data.IdCard=e.replace(/[^0-9a-zA-Z]/g,'')
        data.CardNo = e;
        break;
      case "LowWageText":
        data.Salarytext = moneyConvert(e.target.value);
        data.Salary = utils.numberConvert_100(data.Salarytext);
        break;
      case 'password':
          data.password = e;
        break;
    }
    this.setState({
      modalData: data
    });
  }

  onClickListener(tag, data) {
    const { mchManagerRoles } = this.state;
    switch (tag) {
      case "drawer-cancel":
        this.setState({ visible: false, isShow: false, defaultChecked: [] });
        // this.queryMchManagerRole();
        this.requestEmployeeList();
        // this.requestStoreRole();
        break;
      case "drawer-ok":
        
        if (!phoneCheck(this.state.modalData.Phone)) {
            messageTopError("请输入正确的手机号");
          return;
        }
        if (!this.state.modalData.Name) {
          messageTopError("请输入员工姓名");
          return;
        }
        if (!checkIdCard(this.state.modalData.CardNo)) {
          return messageError("身份证格式错误");
        }
        // if (this.state.modalData.password.length < 6) {
        //   return messageError("请输入密码不少于6位");
        // }

        if (!this.state.modalData.jobAuthority) {
          messageTopError("请选择岗位");
          return;
        }
        //防止暴力点击
        if (!utils.getReqeustState()) {
          return;
        }
        this.setState({loading:true})
        utils.showLoadding();
        //console.log(this.state.modalData)
        this.requestSaveEmployee(this.state.modalData);
        // this.setState({visible: false})
        break;
      case "选择岗位":
        //console.log(data);
        this.queryParameter(mchManagerRoles, data[0]);
        break;
    }
  }
  queryParameter(arr, querPara) {
    arr.forEach((item, index) => {
      if (item.Id == querPara) {
        // this.state.modalData.jobAuthority = item.Name
        this.setState({ selectItem: item });
      } else {
        if (item.MchManagerRoles && item.MchManagerRoles.length) {
          this.queryParameter(item.MchManagerRoles, querPara);
        }
      }
    });
  }

  
  flatCate(cateArray,childKey='MchManagerRoles'){
    const flatMapList = new Map();
		f(cateArray);

		// 递归方法，找出子孩子
		function f(cateArray){

			cateArray.forEach(function(cateVal){

				let cateID = cateVal.Id;
				flatMapList[cateID] = cateVal;

				// 如果存在子孩子
				cateVal[childKey] instanceof Array &&  f(cateVal[childKey]);
			});
		}

		return flatMapList;
	}

  
  //查询岗位
  queryMchManagerRole() {
    utils.showLoadding();
    const map = new Map();
    map.set("mchCode", mchCode);
    $mzlApi.post(
      APINames.API_queryMchManagerRole,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  //查询岗位名字
  queryParameterName(arr, querPara) {
    let obj;
    arr.forEach((item, index) => {
      if (item.Id == querPara) {
       obj = item.Name;
      } else {
        if (item.MchManagerRoles && item.MchManagerRoles.length) {
          this.queryParameter(item.MchManagerRoles, querPara);
        }
      }
    });
    return obj
  }



  /**
   * 获取店铺员工列表
   */
  requestEmployeeList() {
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set('opt', JSON.stringify({Begin: begin, Over: over}));
    map.set('state', this.state.status);

    $mzlApi.post(
      APINames.API_queryMchManagerEmps,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 查询岗位列表
   */
  requestStoreRole() {
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    $mzlApi.post(
      APINames.API_getStoreRole,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 添加修改员工信息
   */
  requestSaveEmployee(data) {
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("mchManagerEmp", JSON.stringify(data));
    $mzlApi.post(
      APINames.API_saveMchManagerEmp,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  httpSuccess(res, tag) {
    utils.hideLoadding();
    ////console.log("httpSuccess", tag, res)
    switch (tag) {
      case APINames.API_queryMchManagerEmps:
       if(res && res.Data){
          let data = [];
          const Data = JSON.parse(res.Data);
          //console.log(Data)
          Data.map((item,index)=>{
            let obj = {};
            obj.index = index + 1;
            obj.Name = item.Name
            obj.Phone = item.Phone
            obj.Salary = item.Salary
            obj.Id = item.Id
              obj.UserCode = item.UserCode?item.UserCode:''
              obj.Code = item.Code?item.Code:''
            if(item.empRoles && item.empRoles.length){
              item.empRoles.map((i,num)=>{
                if(i.Type == 1){
                  obj.NameWork = flatMap[i.RoleId] && flatMap[i.RoleId].Name
                  
                  obj.jobAuthority =  obj.NameWork
                  obj.MchId = i.MchId
                }else if(i.Type == 2){
                  obj.partTimeJob =  flatMap[i.RoleId] && flatMap[i.RoleId].Name
                }
               
              })
            }else{
              item.empRoles = []
            }
            obj.WorkStateText = item.State == 1? '在职':'离职'

            obj.CardNo = item.CardNo
            obj.Sex = item.Sex
            obj.WorkNo = item.WorkNo
            obj.Salarytext = item.Salary/100
            obj.State = item.State
            obj.empRoles = item.empRoles
            obj.Avatar = item.Avatar
            data.push(obj)

          })  
          this.setState({Count:res.Count,data})
          // data
        }
        break;
      case APINames.API_getStoreRole:
        var list = res;
        // var da = new Array();
        // da.insert()
        if (list != null) {
          list.unshift({ Name: "请选择岗位", RoleId: -1 });
        }
        list.forEach((item, index) => {
          item.label = item.Name;
          item.value = item.RoleId;
          item.key = item.index;
        });
        this.setState({
          options: list
        });
        break;
      case APINames.API_saveMchManagerEmp:
        this.setState({loading:false})
        utils.setReqeustState(true);
        // //console.log('保存员工提示',res,tag)
        if (res.ErrCode == 0) {
          // messageSuccess(res.ErrMsg)
          if (res.ErrMsg) {
            if (res.ErrMsg == "添加成功") {
            } else {
              this.setState({ tipVisible: true, tipMsg: res.ErrMsg });
            }
          }
        }
        // this.queryMchManagerRole();
        this.requestEmployeeList();
        this.setState({ visible: false, modalData: {},JobArr:[] });
        break;
      case APINames.API_queryMchManagerRole:
        if (res && res.Data && res.Data.mchManagerRoles) {
          const mchManagerRoles = JSON.parse(res.Data.mchManagerRoles);
          flatMap = this.flatCate(mchManagerRoles);
          this.setState({ mchManagerRoles });
          //console.log(111, mchManagerRoles);
        }
        break;
      case APINames.API_getMchManagerRole:
          if(res && res.Data && res.Data.mchManagerRole){
             // jobAuthority partTimeJob//type 1为主岗位，2为服岗位
            const authorityMchManagerRole = JSON.parse(res.Data.mchManagerRole);
            this.state.modalData.empRoles.map((item,index)=>{
              if(item.RoleId == authorityMchManagerRole.Id){
                if(item.Type == 1){
                  this.state.modalData.jobAuthority = authorityMchManagerRole.Name
                }else{
                  this.state.modalData.partTimeJob = authorityMchManagerRole.Name
                }
              }
            })
            this.setState({modalData:this.state.modalData})
          }
        
        break;
        default:
          break
    }
  }

  httpFail(res, tag) {
    this.setState({loading:false})
    utils.setReqeustState(true);
    utils.hideLoadding();
    switch (tag) {
      case APINames.API_saveEmployee:
          // this.queryMchManagerRole();
        this.requestEmployeeList();
        // this.setState({ modalData: {}})
        break;
        default:
          break
    }
    utils.showMessageError(res, tag);
  }

  //岗位权限
  addStaffJob() {
    const { modalData,mchManagerRoles } = this.state;
    // modalData.jobAuthority = '';//岗位权限
    if(!mchManagerRoles.length){
      utils.showMessageError('请前去设置岗位信息');
      return;
    }
    this.setState({ visibleJob: true, selectJobType: "jobAuthority" });
  }
  //兼职权限
  addStaffPartTime() {
    const { modalData, mchManagerRoles} = this.state;
    if(!mchManagerRoles.length){
      utils.showMessageError('请前去设置岗位信息');
      return;
    }
    this.setState({ visibleJob: true, selectJobType: "partTimeJob" });
    // modalData.partTimeJob = '';//兼职权限
  }

  onTablePageCallback(start, end) {
    begin = start;
    over = end;
    this.requestEmployeeList()

  }
  handleCancel = () => {
    this.setState({ visibleJob: false });
  };
  handleOK = () => {
    const { modalData, selectJobType, selectItem,JobArr} = this.state;
    if (selectJobType == "jobAuthority") {
      if(selectItem.Name ==  this.state.modalData.partTimeJob){
        utils.showMessageError('岗位重复!');
        return;
      }
      this.state.modalData.jobAuthority = selectItem.Name;
      this.state.modalData.empRoles[0]={RoleId:selectItem.Id,Type:1}
      this.state.JobArr[0] = selectItem.Name
    } else {
      if(selectItem.Name ==  this.state.modalData.jobAuthority){
        utils.showMessageError('岗位重复!');
        return;
      }
      this.state.modalData.partTimeJob = selectItem.Name;
      this.state.modalData.empRoles[1] ={RoleId:selectItem.Id,Type:2}
      this.state.JobArr[1] = selectItem.Name
    }
    this.setState({ visibleJob: false, modalData: this.state.modalData,JobArr:this.state.JobArr });
  };
  closePartTimeJbo = ()=>{
    let Arr = [];
    Arr = this.state.modalData.empRoles.filter(item => item.Type !== 2)
    this.state.modalData.empRoles = Arr
    this.state.modalData.partTimeJob = ''
    this.setState({modalData:this.state.modalData})
  }
  render() {
    

    const { modalData, mchManagerRoles,loading} = this.state;
    const div = (
      <div>
        {/*<div className="comm-font-18 comm-text333">{this.state.title}</div>*/}
        <div className="comm-padding-top-15 comm-flex-row-start">
          <span style={{ width: 70 }}><span style={{color:"red"}}>*</span>手机号</span>
          <Input
            placeholder="请输入手机号"
            value={this.state.modalData.Phone}
            maxLength={11}
            onChange={this.changeListener.bind(this, "phone")}
            disabled={this.state.disabled}
            style={{ width: 200 }}
          />
        </div>
        <div className="comm-padding-top-15 comm-flex-row-start">
          <span style={{ width: 70 }}><span style={{color:"red"}}>*</span>姓名</span>
          <IInputString
            placeholder="请输入姓名"
            value={this.state.modalData.Name}
            maxLength={8}
            onChange={this.changeListener.bind(this, "name")}
            style={{ width: 200 }}
          />
        </div>
        <div className="comm-padding-top-15 comm-flex-row-start" style={{marginLeft:8}}>
          <span style={{ width: 70 }}>性别</span>
          <div style={{ width: 200 }}>
            <RadioGroup
              onChange={this.onChange.bind(this, "Sex")}
              value={this.state.modalData.Sex}
            >
              <Radio value={1}>男</Radio>
              <Radio value={2} style={{ marginLeft: 14 }}>
                女
              </Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="comm-padding-top-15 comm-flex-row-start" style={{marginLeft:8}}>
          <span style={{ width: 70 }}>工号</span>
          {/*<ISpanMust value="工号"/>*/}
          <IInputString
            onChange={this.changeListener.bind(this, "WorkId")}
            placeholder="请输入工号"
            maxLength={3}
            value={this.state.modalData.WorkNo}
            style={{ width: 200 }}
          />
          {/* <span style={{ width: 80, textAlign: "right" }}>@0000001</span> */}
        </div>
        {/* <div className="comm-padding-top-15 comm-flex-row-start">
          <span style={{ width: 70 }}>登录密码</span>
          <IInputString
            placeholder="请输入身份证号"
            maxLength={18}
            onChange={this.changeListener.bind(this, "password")}
            value={this.state.modalData.password}
            style={{ width: 200 }}
          />
        </div> */}

        <div className="comm-padding-top-15 comm-flex-row-start" style={{marginLeft:8}}>
          <span style={{ width: 70 }}>身份证号</span>
          <IInputString
            placeholder="请输入身份证号"
            maxLength={18}
            onChange={this.changeListener.bind(this, "IdCard")}
            value={this.state.modalData.CardNo}
            style={{ width: 200 }}
          />
        </div>
        <div className="comm-padding-top-15 comm-flex-row-start" style={{marginLeft:8}}>
          <span style={{ width: 70 }}>基本工资</span>
          <Input
            placeholder="请输入基本工资"
            onChange={this.changeListener.bind(this, "LowWageText")}
            value={this.state.modalData.Salarytext}
            style={{ width: 200 }}
          />
        </div>

        <div className="comm-padding-top-15 comm-flex-row-start" style={{marginLeft:8}}>
          <span style={{ width: 70 }}>员工状态</span>
          <div style={{ width: 200 }}>
            <RadioGroup
              onChange={this.onChange.bind(this, "WorkState")}
              value={this.state.modalData.State}
            >
              <Radio value={1}>在职</Radio>
              <Radio value={3}>离职</Radio>
            </RadioGroup>
          </div>
        </div>
        <div className="comm-padding-top-15 comm-flex-row-start" >
          <span style={{ width: 70 }}><span style={{color:"red"}}>*</span>岗位权限:</span>
          <div class="btn" onClick={this.addStaffJob.bind(this)} style={{marginLeft:7}}>
            选择
          </div>

          <span
            style={{
              marginLeft: 10,
              padding: "5px 10px",
              backgroundColor: modalData.jobAuthority
                ? "rgba(164,76,252,0.2)"
                : ""
            }}
          >
            
            { modalData.jobAuthority && modalData.jobAuthority.length > 5?`${modalData.jobAuthority.slice(0,7)}...` : modalData.jobAuthority || ""}
          </span>
        </div>
        <div className="comm-padding-top-15 comm-flex-row-start" style={{marginLeft:8}}>
          <span style={{ width: 70 }}>兼职权限:</span>

          <div class="btn" onClick={this.addStaffPartTime.bind(this)}>
            选择
          </div>
          <span
            style={{
              position:'relative',
              marginLeft: 10,
              padding: "5px 15px",
              backgroundColor: modalData.partTimeJob
                ? "rgba(164,76,252,0.2)"
                : ""
            }}
          >
           
             { modalData.partTimeJob && modalData.partTimeJob.length > 5?`${modalData.partTimeJob.slice(0,7)}...` : modalData.partTimeJob || ""}
             {
               modalData.partTimeJob && <Icon type="close" style={{position:'absolute',right:0,top:0}} 
               onClick={this.closePartTimeJbo}
               />
             }
             
          </span>
        </div>
      </div>
    );
    const loop = data =>
    
      data.map(item => {
        if (item.MchManagerRoles && item.MchManagerRoles.length) {
            return (
              <TreeNode key={item.Id} title={item.Name}>
                {loop(item.MchManagerRoles)}
              </TreeNode>
            );
          
          
        }
        return <TreeNode key={item.Id} title={item.Name} />;
      });
    
      
    ////console.log("查看数据：", this.state.modalData,this.state.options)
    return (
      <div class="gra-border-pd15 vip-main-view" style={{ overflow: "hidden" }}>
        <div style={{ overflow: "hidden", marginBottom: 20 }}>
          <Select
            style={{ width: 100, float: "left", zIndex: 999 }}
            defaultValue="on"
            onChange={this.changeSelect.bind(this)}
          >
            <Option value="all">全部状态</Option>
            <Option value="on">在职</Option>
            <Option value="leave">离职</Option>
          </Select>
          <Button
            type="primary"
            style={{ float: "right" }}
            onClick={this.addStaff.bind(this)}
          >
            <span style={{ color: "#fff" }}>添加员工</span>
          </Button>
        </div>

        <div
          className="vip-main-view-content"
          style={{ left: 15, right: 15, top: 60 }}
        >
          <ITablePage
            scroll={{ x: "100%", y: "62vh" }}
            onTablePageCallback={this.onTablePageCallback.bind(this)}
            columns={this.state.columns}
            data={this.state.data}
            pageCount={this.state.Count}
          />
        </div>
        {/* 添加员工 */}
        <IDrawer
          title={this.state.title}
          footer={true}
          onClickListener={this.onClickListener.bind(this)}
          width={370}
          visible={this.state.visible}
          div={div}
          loading = {loading}
        />
        {/* 选择岗位 */}
        <IModal
          width="370px"
          // height="400px"
          overflow="hidden"
          visible={this.state.visibleJob}
          onOk={this.handleOK}
          onCancel={this.handleCancel}
          centered={true}
          view={
            <div>
              <h3 style={{fontWeight:'bold'}}>选择岗位</h3>
              <Tree
                className="draggable-tree"
                draggable
                blockNode
                onSelect={this.onClickListener.bind(this, "选择岗位")}
              >
                {loop(mchManagerRoles)}
              </Tree>
            </div>
          }
        ></IModal>

        <IModal
          width="50%"
          visible={this.state.tipVisible}
          footer={null}
          title="提示"
          onCancel={() => {
            this.setState({ tipVisible: false });
          }}
          view={
            <div style={{ textAlign: "center" }}>
              {this.state.tipMsg}
              <div style={{ textAlign: "right", marginTop: 20 }}>
                <IButton
                  type="primary"
                  onClick={() => {
                    this.setState({ tipVisible: false });
                  }}
                  text="确定"
                ></IButton>
              </div>
            </div>
          }
        ></IModal>
      </div>
    );
  }
}

const StaffSetting = Form.create()(StaffSettingView);
export default StaffSetting;
