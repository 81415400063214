import { emit } from 'process';
import BaseService from '../../../../base/baseService/baseService'



class ChainConsumeService extends BaseService{
    constructor(){
        super();
    }
    //查询连锁跨店消费设置
    queryMchManagerChainCards(that,map){
        this.utils.showLoadding();

        const t  = this;
        var p = new Promise(function(resolve,reject){
            var map = new Map();

            map.set("mchCode", t.storageTools.getMchCode());
            map.set("storeCode", t.storageTools.getUserCode());
            t.$mzlApi.post(t.APINames.API_queryMchManagerChainCards,map,
            (res)=>{
                t.utils.hideLoadding();
                t.handleQueryMchManagerChainCards(resolve,res)
                // resolve(res);
            },
            (res,tag)=>{
                t.httpFail(t,res,tag);
            },
            that
            );
        })
        return p;
    }


      //保持连锁跨店消费
      saveMchManagerChainCards(CardTid,items,that){

        this.utils.showLoaddingAll("请稍后...");
        var t  = this;
        var p = new Promise(function(resolve,reject){
            var map = new Map();

            map.set("cardTid", CardTid);
            map.set("mchCode", t.storageTools.getMchCode());
            map.set("items", items);
            t.$mzlApi.post(t.APINames.API_saveMchManagerChainCards,map,
            (res)=>{
                t.utils.hideLoadding();
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(t,res,tag);
            },
            that
            );
        })
        return p;
    }


    
    handleQueryMchManagerChainCards(resolve,res){
        let arr = []
        if(res != null || res.Data != null && res.Data.bases != null){
            let bases = JSON.parse(res.Data.bases);
            let items = JSON.parse(res.Data.items);
            arr = bases;
            // debugger
            arr.forEach(element => {
                let a = 0;
                element.Items.forEach(item=>{
                    item.checked = false;
                    items.forEach(k=>{
                        // console.log(element.Name,k.CardTid, element.Id, k.CardTid == element.Id,  k.StoreId ,  item.Id,  k.StoreId ==  item.Id);
                        if(k.CardTid == element.Id && k.StoreId ==  item.Id){
                            a++;
                            item.ItemId = k.Id;
                            item.CreateTime = k.CreateTime;
                            item.checked = true;
                        }
                    })
                })
                // debugger
                if(a == element.Items.length ){
                    element.checked = true;
                }
            });


            resolve(arr);
        }

    }


    handleSaveMchManagerChainCards(that,data){
        // /** 卡类型Id */
        // 1:i32 Id
        // /** 商户Id */
        // 2:i32 MchId
        // /** 店铺Id */
        // 3:i32 StoreId
        // /** 不可跨店消费卡类型ID */
        // 4:i32 UnCardTid
        // /** 状态 */
        // 5:i32 State
        // debugger
        let arr = [];
        data.Items.forEach(item=>{
            if(item.checked){
                let obj = {};
                obj.StoreId = item.Id;
                obj.CardTid = data.Id;
                obj.State = this.IEnum.ChainState.Agreed;
                obj.Id = item.ItemId;
                obj.CreateTime = item.CreateTime;
                arr.push(obj);
            }
        })
        // this.setState({loading:true})
        //  that.state.loading = true;
        const t = this;
        this.saveMchManagerChainCards(data.Id, JSON.stringify(arr),that).then(()=>{
            // t.setState({loading:false});
            t.utils.messageSuccess("保存成功！")
            t.utils.hideLoadding();
        })

        



    }
    


    


httpFail(that,res,tag){
    this.utils.hideLoadding();
}





}
export default  new ChainConsumeService();