import React from 'react'
import { Icon, Tree, Input, Button,Tabs  } from 'antd'
import IButton from '../../components/IButton'
import IDrawer from '../../components/iDrawer'
import { IModal } from '../../components/iModal'
import InputString from '../../components/iInputString'
import InputMoney from '../../components/iInputMoney'
import StorageTools from "../../comm/storageTools";
import { $mzlApi } from "../../comm/mzl-core";
import { messageError, messageSuccess, numberConvert_100, numberConvert, showMessageError, filterByName, showLoadding, hideLoadding, numConvert } from '../../comm/utils';
import ITablePage, { getPageSize, setTabTop } from '../../components/iTablePage'
import '../../../css/report.css'
import APINames from "../../comm/api-names"
import { FnMenuId } from "../../comm/constans"
import { setDefaultTitle } from "../../reduxs/reducers/iconRedux";
import { connect } from 'react-redux'
import { http } from '../../api/yqHttp'
import BonusSetting from './bonusSetting'
import Customer from './customerSetting'
import Shareholder from './shareholder'
import CustomerList from './customerList'
import Record from './record'
import Settlement from './settlement'
const { TabPane } = Tabs;
@connect(
    state => state.iconRedux,
    { setDefaultTitle }
)
/**
 * 分红活动设置
 */
class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            tagKey: "1",
        }
    }
    componentWillMount() {
        this.props.setDefaultTitle({ titleKey: FnMenuId.head_marketing_gdfh, slidingLeftKey: "10" })
    }
    componentDidMount() {
    }
    componentDidCatch(error, errorInfo) {

        console.log('==============', error, errorInfo);
    }

    onTabsCallback(key) {
        this.setState({
            tagKey:key
        })
        console.log(key);
    }

    getContentView(tag){
        switch(tag){
            case "1":
                return <BonusSetting></BonusSetting>
            case "2":
                return <Customer></Customer>
            case "3":
                return <Shareholder></Shareholder>
            case "4":
                return <CustomerList></CustomerList>
            case "5":
                return <Record></Record>
            case "6":
                return <Settlement></Settlement>
        }
    }


    render() {
        return (
            <div style={{padding:20}}>
                <Tabs onChange={this.onTabsCallback.bind(this)} type="card">
                    <TabPane tab="分红活动设置" key="1">
                    </TabPane>
                    <TabPane tab="拓客活动设置" key="2">
                    </TabPane>
                    <TabPane tab="股东列表" key="3">
                    </TabPane>
                    <TabPane tab="拓客列表" key="4">
                    </TabPane>
                    <TabPane tab="分红记录" key="5">
                    </TabPane>
                    <TabPane tab="分红结算" key="6">
                    </TabPane>
                </Tabs>
                
                {this.getContentView(this.state.tagKey)}
            </div>



        )
    }
}

export default Index