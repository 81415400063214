import Baseservice from "../../../base/baseService/baseService";
import {getSAppointmentOperationLog} from "./bean";
import dateHelp from "../../comm/dateHelp";
import storageTools from "../../comm/storageTools";


class service extends Baseservice{ 
     //保存考勤组
     saveAttendanceGroup(that,group) {
        this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set("storeCode", t.storageTools.getUserCode());
            map.set("group", JSON.stringify(group));
            t.$mzlApi.post(t.APINames.API_saveAttendanceGroup, map,
                (res) => {
                    t.utils.hideLoadding();
                    resolve(res);
                },
                (res,tag)=>{
                    t.httpFail(that,res,tag)
                },
                that
            );
        })
        return p;
    }

    /**
     * 查询预约门店设置
     */
    queryAppointmentSetting(that){
        this.utils.showLoadding();
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            t.$mzlApi.post(t.APINames.API_queryAppointmentSetting, map, (res) => {
                t.utils.hideLoadding();
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;
    }

    //可预约的项目
    queryAppointmentItem(that,text){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            map.set('keyWord', text);
            t.$mzlApi.post(t.APINames.API_queryAppointmentItem, map, (res) => {
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;
       
    }


    //获取可预约的员工
    getStoreRecommendEmps(that,text,itemId,pageDetail){
        let dateTime  = dateHelp.getConversionTime(pageDetail._SelectDate+' 00:00:00');
        let appointmentDate = 0;
        if(pageDetail._SelectTime !== ''){
            appointmentDate = dateHelp.getConversionTime(pageDetail._SelectDate+` ${pageDetail._SelectTime}:00`)
        }


        dateHelp.getConversionTime(pageDetail._SelectDate+` ${pageDetail._SelectTime === ''?'00:00:00':pageDetail._SelectTime+":00"}`)
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            map.set('itemId', itemId);
            map.set('keyWord', text);
            map.set('dateTime', dateTime);
            map.set('appointmentDate', appointmentDate);
            t.$mzlApi.post(t.APINames.API_getStoreRecommendEmps, map, (res) => {
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;
       
    }
  

    //预约
    saveAppointmentCondition(that,appointmentCondition){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            map.set('appointmentCondition',JSON.stringify(appointmentCondition));
            t.$mzlApi.post(t.APINames.API_saveAppointmentCondition, map, (res) => {
                if(res && res.Data && res.Data.AppointmentNo){
                    t.saveAppointmentOperationLog(that,appointmentCondition,res.Data.AppointmentNo);
                }
                
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;
    }


     //保存预约记录
     saveAppointmentOperationLog(that,appointmentCondition,AppointmentNo){
         let data =  getSAppointmentOperationLog();
         data.AppointmentNo = AppointmentNo;
         data.StoreId = appointmentCondition.StoreId;
         data.CusId = appointmentCondition.CusId;
         data.CusName = appointmentCondition.CusName;
         data.Content = appointmentCondition.Remark;
         data.Type = appointmentCondition.State;
         
        
        let userInfo = this.storageTools.getUserInfo();
         data.EmpId = userInfo.EmployeeId;
         data.EmpName = userInfo.RealName;

        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            map.set('log',JSON.stringify(data));
            t.$mzlApi.post(t.APINames.API_saveAppointmentOperationLog, map, (res) => {
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;
    }







    //预约记录
    queryAppointmentRecord(that,state,empId,keyWord,beginTime,overTime){
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            map.set('opt',JSON.stringify({ Begin:0,Over:999,Opt:{BeginTime:beginTime,OverTime:overTime,State:state,EmpId :empId ,KeyWord :keyWord}}));
            t.$mzlApi.post(t.APINames.API_queryAppointmentRecord, map, (res) => {
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;
       
    }



     /**
     * 查询全店可预约员工
     */
    queryAppointmentEmp(that) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            t.$mzlApi.post(t.APINames.API_queryAppointmentEmp, map, (res) => {
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;

    }

     /**
     * 查询订单详情
     */
    queryAppointmentOperationLogs(that,appointmentNo) {
        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            map.set('appointmentNo', appointmentNo);
            t.$mzlApi.post(t.APINames.API_queryAppointmentOperationLogs, map, (res) => {
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;

    }


     /**
     * 保存预约店铺设置
     */
    requestshopSettingSave(that,settingData) {

        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            map.set('appointmentSetting', JSON.stringify(settingData));
            t.$mzlApi.post(t.APINames.API_saveAppointmentSetting, map, (res) => {
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;
       
    }


     /**
     * 获取店铺考勤设置
     */
    querySchedulingAttGroups(that,beginTime,overTime) {

        const t = this;
        var p = new Promise(function (resolve, reject) {
            var map = new Map();
            map.set('storeCode', t.storageTools.getUserCode());
            map.set('beginTime', beginTime);
            map.set('overTime', overTime);
            t.$mzlApi.post(t.APINames.API_querySchedulingAttGroups, map, (res) => {
                resolve(res);
            },
            (res,tag)=>{
                t.httpFail(that,res,tag)
            },
            that)
        })
        return p;
       
    }


    //预约情况
    getAgreeInfo(that,beginTime,overTime,callback){
        let agreeInfoList= [];
        let agreeList = [];
        const _this = this;
        this.queryAppointmentRecord(that,0,0,'',beginTime,overTime).then(res=>{
            if(res.Data){
                let data = JSON.parse(res.Data);
                agreeInfoList =  _this.getAgreeInfoList(data);
                agreeList = data;
                that.setState({
                    agreeList:agreeList,
                    agreeInfoList:agreeInfoList,
                },function(){
                    _this.setAgreeInfoList(that,beginTime)
                })
            }
        })
        this.querySchedulingAttGroups(that,beginTime,overTime).then(res=>{
            // _this.setState({})
            if(res && res.Data && res.Data.attendanceGroupData){
                that.setState({
                    groupList:JSON.parse(res.Data.attendanceGroupData)
                },function(){
                    _this.setAgreeInfoList(that,beginTime)
                })
            }
        })
    }

    getAgreeInfoList(arr){
        let newArr = [];
        arr.forEach(item=>{
            let dateList = dateHelp.getTimeYMDHM(item.AppointmentTime).split(' ');
            item._SelectDate = dateList[0];
            item._SelectTime = dateList[1];
            item._AppointmentTime  = dateHelp.getHM(item.AppointmentTime);
            item._totalNum  = item.FinishTime-item.AppointmentTime;
            let isBoll = true;
            newArr.forEach(dev =>{
                if(item.EmpId === dev.EmpId){
                    isBoll=false;
                }
            })
            if(isBoll){
                newArr.push(this.utils.copyProject(item));
            }
        })

        return newArr;
    }


    setAgreeInfoList(that,beginTime){
        let {agreeInfoList,agreeList,groupList} = that.state;
        if(agreeList === null || groupList === null){
            return;
        }
        // debugger
        agreeInfoList.forEach(item=>{
            item.agreeList = [];
            //排班考勤
            groupList.forEach(dev=>{
                dev.Emps.forEach(emp=>{
                    if(item.EmpId === emp.EmpId){
                        emp.SchedulingEmps.forEach(ele=>{
                            if(item.EmpId === ele.EmpId && beginTime >= ele.SchedulingDateBegin && beginTime <= ele.SchedulingDateOver){
                                item.SchedulingState = ele.SchedulingState; //上班时间
                                item.SchedulingBegin = ele.SchedulingBegin; //上班时间
                                item.SchedulingOver = ele.SchedulingOver;//下班时间
                                item._SchedulingBegin =  dateHelp.getConversionTime(dateHelp.getConversionDate(beginTime) +' '+ele.SchedulingBegin+":00");
                                item._SchedulingOver = dateHelp.getConversionTime(dateHelp.getConversionDate(beginTime) +' '+ele.SchedulingOver+":00");
                                if(item._SchedulingOver <= item._SchedulingBegin){
                                    item._SchedulingOver = item._SchedulingOver+24*60*60;
                                }
                                item._workNum = item._SchedulingOver - item._SchedulingBegin;
                                // item._postion = this.utils.calcMultiply(this.utils.calcDivide(item._totalNum,item._workNum),100)


                            }
                        })
                    }
                })
            })

        

            agreeList.forEach((dev,index)=>{
                // debugger
                 //取消的预约订单不展示
                if(item.EmpId === dev.EmpId  && dev.State !==2){
                    // 没有排班
                    if(item.SchedulingBegin == null){
                        if(dateHelp.getTimeYMD(dev.AppointmentTime) === dateHelp.getAgreeData(0)){
                            item.agreeList.unshift(dev);
                        }
                        // dev._width = 7;
                        // dev._postion = 7*(index)
                       
                    }else if(dev.AppointmentTime >=  item._SchedulingBegin  &&  dev.FinishTime <= item._SchedulingOver ){
                       
                        //预约开始时间大于上班时间，并且预约结束时间小于下班时间
                        if(item._workNum){
                            let num = dev.AppointmentTime - item._SchedulingBegin;
                            dev._width = this.utils.calcMultiply(this.utils.calcDivide(dev._totalNum,item._workNum),100)
                            // dev._width = this.utils.calcDivide(item._workNum,item._totalNum)
                            // dev._postion = this.utils.calcDivide(dev.AppointmentTime,item._SchedulingOver)
                            // dev._postion = this.utils.calcMultiply(this.utils.calcDivide(num,item._workNum),100)
                            dev._postion = this.utils.calcMultiply(this.utils.calcDivide(num,item._workNum),100)
                        }
                        item.agreeList.push(dev);
                    }
                    
                }
            })
        })

        agreeInfoList = agreeInfoList.filter(item=>{
            return item.SchedulingState !== 1 && item.agreeList.length>0;
        })
        // console.log(9999,agreeInfoList)
        that.setState({
            agreeInfoList
        })
    }




    httpFail(that,res){
        this.utils.hideLoadding();
        this.utils.showMessageError(res);
        that.setState({loading:false})
    }


    
    
} 



export default new service();