import React from 'react';
import { Button } from 'antd';

// import EditableCell from './EditableCell'


export default class IButton extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            //   primary default
            <Button size={this.props.size}
                loading={this.props.loading}
                type={this.props.type ? this.props.type : "primary"}
                disabled={this.props.disabled}
                className={this.props.className}
                style={this.props.style}
                href={this.props.href}
                onClick={this.props.onClick}>
                <span
                    style={{ 
                        color: this.props.textColor ? this.props.textColor : this.props.type == "default" ? "#666" : "#fff" ,
                        // fontSize:12
                        }}>
                    {this.props.text}
                </span>
            </Button>
        );
    }
}
