const initModel = {
    //企业号
    id: 0,
    //企业名称
    companyName: "",
    //企业联系电话
    companyPhone: "",
    //所在地省
    province: "",
    //市
    city: "",
    //区（县）
    area: '',
    //详细地址
    address: '',
    //联系人
    contact: '',
    //职务
    duty: '',
    //手机
    contactPhone: '',
    //邮箱
    email: "",
    //体系code
    systemCode: '',
    //纬度
    lat: '',
    //经度
    lng: ''
};

export const UPDATA = 'UPDATA';
export const ADDCOMPON = 'ADDCOMPON'
export function shopSetting(state = initModel, action) {
    switch (action.type) {
        case UPDATA:
            return {
                ...state,
                province: action.updataAddress.province,
                city: action.updataAddress.city,
                area: action.updataAddress.area,
                address: action.updataAddress.address,
                lat: action.updataAddress.lat,
                lng: action.updataAddress.lng,
            }
            break;
        case ADDCOMPON:
                return {
                    ...state,
                    companyName:action.updataCompany.companyName
                }
            break;
    }
    return state
}

export function updataInfo(state) {

}

export function updataAdress(state) {

    return {type: UPDATA, updataAddress: state}

}
export function updataCompany(state) {

    return {type: ADDCOMPON, updataCompany: state}

}




