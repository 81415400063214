import StorageTools from "./storageTools";
import APINames from "./api-names";
import {$mzlApi} from "./mzl-core";
import React from "react";
import {httpAgree} from "../api/yqHttp";
import dateHelp from "./dateHelp";
import service from "../agrees/services/service";

/**
 * 更新未支付订单
 */
export function updateOrderSize(callback, app) {
    requestQueryStoreOrderList(callback, app);
}

/**
 * 查询订单列表
 */
function requestQueryStoreOrderList(callback, app) {

    let code =  StorageTools.getUserCode();

    if(code != null && code != ""){
        var map = new Map();
        map.set("storeCode",code );
        map.set("opt", JSON.stringify({
            Opt: {
                begin_time: "1451581261",
                over_time: "1881646833",
                keyword: "",
                order_type: "1,2,3,4,5",
                order_state: "1"
            }, Begin: 0, Over: 100
        }));
        $mzlApi.post(APINames.API_queryStoreOrderList, map, (res, tag) => {
            if (tag == APINames.API_queryStoreOrderList) {
                if (res.Data != null && res.Data != "") {
                    var list = JSON.parse(res.Data);
                    if (list != null) {
                        let size = list.length;
                        if (size > 99) {
                            size = 99
                        }
                        if (callback != null) {
                            callback(size)
                        }
                    }
                }
            }
        }, (res, tag) => {
        }, app);
    }

}


/**
 * 查询已预约的数据
 */
export function requestAgreeSelect(that,callback) {
    let beginTime = dateHelp.getConversionTime(dateHelp.getNumToDate()+" 00:00:00");
    let overTime = dateHelp.getConversionTime(dateHelp.getNumToDate(7)+" 23:59:59");


    service.queryAppointmentRecord(that,1,0,'',beginTime,overTime).then(res=>{
        if(res.Data){
            // debugger
            let data = JSON.parse(res.Data);
            // agreeInfoList =  _this.getAgreeInfoList(data);
            // agreeList = data;
            callback(data.length)
            // that.setState({
            //     agreeList:agreeList,
            //     agreeInfoList:agreeInfoList,
            // },function(){
              
            // })
        }
    })




    // let token = StorageTools.getStoreToken();
    // let code = StorageTools.getUserCode();
    // if(token != null && token != "" && code != null && code != ""){
    //     let param = {storeCode:code,
    //         page:1,
    //         size:100,
    //         order:"startTime",
    //         sequence:"desc",
    //         status:"1",
    //         flag:"true"
    //     };

    //     httpAgree.soSelect(param).then(res=>{
    //         if(res.data != null){
    //             callback(res.data.length);
    //         }
    //     }).catch(function(reason, data){
    //     });
    // }


}

