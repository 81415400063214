import React from 'react'
import { Button,Tabs ,Select } from 'antd'
import IButton from '../../../components/IButton'
import IDrawer from '../../../components/iDrawer'
import { IModal } from '../../../components/iModal'
import InputString from '../../../components/iInputString'
import InputMoney from '../../../components/iInputMoney'
import TitleView from '../../../components/titleView'
import StorageTools from "../../../comm/storageTools";
import { $mzlApi } from "../../../comm/mzl-core";
import { messageError, messageSuccess, numberConvert_100, numberConvert, showMessageError, filterByName, showLoadding, hideLoadding, numConvert } from '../../../comm/utils';
import ITablePage, { getPageSize, setTabTop } from '../../../components/iTablePage'
import APINames from "../../../comm/api-names"
import Editor from "react-umeditor";
import { FnMenuId } from "../../../comm/constans"

import ITableList from '@components/iTableList'
import Detail from "./detail";

import {getConversionDate,getTime } from '@comm/dateHelp';
import service from '../../../services/marketing/bonusService'
let begin = 0;
let over = 20;
const { TabPane } = Tabs;
const { Option } = Select;
/**
 * 分红记录
 */
class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            content: "",
            detailData:{},
            totalSize:0,
            userId:0,
            parentUserId:0,
            keyWord:"",
            isSelect:false,
            columns : [
                {
                  width:80,
                  title: '序号',
                  dataIndex: 'name',
                  align: 'center',
                  render: (text,record,index) => <span>{index+begin+1}</span>,
                },
                {
                  title: '客户姓名',
                  align: 'center',
                  dataIndex: 'Name',
                },
                {
                  title: '性别',
                  align: 'center',
                  dataIndex: 'Sex',
                  render: (text,record,index) => <span>{text==1?"男":"女"}</span>,
                },
                {
                  title: '手机',
                  align: 'center',
                  dataIndex: 'Phone',
                },
                {
                  title: '所属股东',
                  align: 'center',
                  dataIndex: 'UserName',
                },
                {
                  title: '上级股东',
                  align: 'center',
                  dataIndex: 'ParentUserName',
                },
                {
                  title: '消费总额',
                  align: 'center',
                  dataIndex: 'OrderSum',
                },
                {
                  title: '总次数',
                  align: 'center',
                  dataIndex: 'OrderCount',
                }
              ],
            tableData:[],
            detailVisible:false,
            titleViewData:[{
              type:"Select",
              lable:"所属股东",
              onChange:(res)=>{
                this.setState({
                  userId:res
                },()=>this.getPageData())
                // this.getPageData(res);
              },
              list:[
                {
                  value:"0",
                  lable:"全部"
                },
              ]
            },
            {
              type:"Select",
              lable:"上级股东",
              onChange:(res)=>{
                this.setState({
                  parentUserId:res,
                },()=>this.getPageData())
              },
              list:[
                {
                  value:"0",
                  lable:"全部"
                },
              ]
            },
            {
              type:"Search",
              placeholder:"搜索顾客",
              lable:"搜索",
              direction:"right",
              onSearch:(res)=>{
                
                begin =0;
                over =20;
                this.getPageData(res)
              },
            }
          ]
        }
    }
    componentWillMount() {

    }
    
    componentDidMount() {
      begin =0;
      over =20;
      this.getPageData();
     
    }

 

    
    getPageData(keyword){
      const {userId,parentUserId,titleViewData} = this.state;
      let opt ={ Begin: 0, Over: 9999,Opt:{userId,parentUserId,keyword}};
      service.queryShCustomer(this,opt).then(res=>{
        let data = JSON.parse(res.Data);
        data.forEach(item => {
         item.OrderSum = item.OrderSum/100;
         if(item.UserName ==""){
            item.UserName ="-"
         }
         if(item.ParentUserName ==""){
            item.ParentUserName ="-"
         }
        //  debugger
         if(!this.state.isSelect){
          if(item.UserId != 0){
            let obj ={};
            obj.value = item.UserId;
            obj.lable = item.UserName;
            let isPush = titleViewData[0].list.some(ele=>{
              return ele.value == item.UserId;
            })
            if(!isPush){
              titleViewData[0].list.push(obj);
            }
            
          }
          if(item.ParentUserId != 0){
            let obj1 ={};
            obj1.value = item.ParentUserId;
            obj1.lable = item.ParentUserName;
            let isPush = titleViewData[1].list.some(ele=>{
              return ele.value == item.ParentUserId;
            })
            if(!isPush){
              titleViewData[1].list.push(obj1);
            }
          
          }
         }
        })
        this.setState({
          tableData:data,
          totalSize:res.Count,
          titleViewData,
          isSelect:true
        })
      })
    }



    onDetail(){
        // debugger
        this.setState({
            detailVisible:true
        })
    }

    onTablePageCallback(start, end){
      // begin = start;
      // over = end;
      // this.getPageData();
    }
  
    onClickListener(){

    }

    handleChange(){

    }

  

    render() {


        return (
            <div>
                
                <TitleView titleViewData={this.state.titleViewData}></TitleView>

                <ITableList 
                     style={{marginTop:10,marginBottom:20}}
                     pagination={true}
                     top={260}
                     columns={this.state.columns} 
                     data={this.state.tableData}
                     totalSize={this.state.totalSize}
                     onTablePageCallback={this.onTablePageCallback.bind(this)}>

                </ITableList>
                {
                  this.state.detailVisible?(
                    <Detail 
                    onOk={this.onClickListener.bind(this)} 
                    onCancel={()=>{
                      this.setState({
                        detailVisible:false
                      })
                    }} 
                    visible={this.state.detailVisible} 
                    detailData={this.state.detailData}>

                  </Detail>
                  ):null
                }
                
                
            </div>



        )
    }
}

export default Index