import React from 'react'
import { Button,Tabs ,Select } from 'antd'
import IButton from '../../../components/IButton'
import IDrawer from '../../../components/iDrawer'
import { IModal } from '../../../components/iModal'
import InputString from '../../../components/iInputString'
import InputMoney from '../../../components/iInputMoney'
import TitleView from '../../../components/titleView'
import StorageTools from "../../../comm/storageTools";
import { $mzlApi } from "../../../comm/mzl-core";
import { messageError, messageSuccess, numberConvert_100, numberConvert, showMessageError, filterByName, showLoadding, hideLoadding, numConvert } from '../../../comm/utils';
import ITablePage, { getPageSize, setTabTop } from '../../../components/iTablePage'
import APINames from "../../../comm/api-names"
import Editor from "react-umeditor";
import { FnMenuId } from "../../../comm/constans"

import OrderDetail from "@src/js/contents/orderDetail";
import ITableList from '@components/iTableList'


import { getConversionDate, getTime ,daysAgo} from '@comm/dateHelp';
import service from '../../../services/marketing/bonusService'
let begin = 0;
let over = 20;
const { TabPane } = Tabs;
const { Option } = Select;
/**
 * 分红记录
 */
class Index extends React.Component {
    constructor() {
        super();
        this.state = {
            beginTime: daysAgo(0),
            overTime: daysAgo(0) + 24 * 60 * 60 - 1,
            content: "",
            detailData:{},
            totalSize:0,
            orderAmout:0,
            orderCount:0,
            userDividendsAmount:0,
            parentUserDividendsAmount:0,
            columns : [
                {
                  width:80,
                  title: '序号',
                  dataIndex: 'name',
                  align: 'center',
                  render: (text,record,index) => <span>{index+begin+1}</span>,
                },
                {
                  title: '客户姓名',
                  align: 'center',
                  dataIndex: 'CusName',
                },
                {
                  title: '手机',
                  align: 'center',
                  dataIndex: 'CusPhone',
                },
                {
                  title: '消费时间',
                  align: 'center',
                  dataIndex: '_OrderTime',
                  width:160,
                },
                {
                  title: '订单号',
                  align: 'center',
                  dataIndex: 'OrderNo',
                  width:140,
                  render:(text,data)=>(
                    <span>
                      {text==""?"-":<a onClick={this.onDetail.bind(this,text)}>{text}</a>}
                    </span>
                    
                  )
                },
                {
                  title: '消费金额',
                  align: 'center',
                  dataIndex: 'OrderAmount',
                },
                {
                  title: '一级分红',
                  align: 'center',
                  dataIndex: 'UserDividendsAmount',
                },
                {
                  title: '二级分红',
                  align: 'center',
                  dataIndex: 'ParentUserDividendsAmount',
                }
              ],
            tableData:[{no:"SN210625920825"}],
            detailVisible:false,
            orderNo:"",
            titleViewData:[{
              type:"SelectRangePicker",
              dateFormat :'YYYY-MM-DD',
              onChange:(res)=>{
                begin = 0;
                over = 20;
                this.setState({
                  beginTime:res.beginTime,
                  overTime:res.overTime,
                },function(){
                  this.getPageData();
                })
                
                console.log(11112,res)
              },
            },
            {
              type:"Search",
              placeholder:"手机姓名搜索",
              lable:"搜索",
              direction:"right",
              onSearch:(res)=>{
                begin = 0;
                over = 20;
                this.getPageData(res);
              },
            }
          ]
        }
    }
    componentWillMount() {

    }
    componentDidMount() {
      begin = 0;
      over = 20;
      this.getPageData();
    }

    getPageData(keyword){
      let opt = { Begin: begin, Over: over,Opt:{beginTime:this.state.beginTime, overTime:this.state.overTime ,keyword,}};
      // let time = new  Date().getTime()/1000;
      service.queryShDividendsDetail(this, opt).then(res => {
        let data = JSON.parse(res.Data);
        data.forEach(item => {
          item._OrderTime = getTime(item.OrderTime);
          item.OrderAmount = item.OrderAmount/100;
          item.UserDividendsAmount = item.UserDividendsAmount/100;
          item.ParentUserDividendsAmount = item.ParentUserDividendsAmount/100;
        })
        this.setState({
          tableData: data,
          totalSize:res.Count,
          orderAmout:res.Extras.orderAmout/100,
          orderCount:res.Extras.orderCount,
          userDividendsAmount:res.Extras.userDividendsAmount/100,
          parentUserDividendsAmount:res.Extras.parentUserDividendsAmount/100,

        })
      })
    }

    
    onTablePageCallback(start, end){
      begin = start;
      over = end;
      this.getPageData();
    }

    onBtn(){

    }

    onDetail(data){
        // debugger
        this.setState({
            detailVisible:true,
            orderNo: data
        })
    }

  
    onClickListener(tag,data){
      switch (tag) {
        case "hideModal":
          this.setState({ detailVisible: false });
          break;
      }
    }

    handleChange(){

    }
    render() {
        const {orderAmout,orderCount,userDividendsAmount,parentUserDividendsAmount} = this.state;
        // console.log(912323,orderAmout,orderCount);
        return (
            <div>
                <TitleView titleViewData={this.state.titleViewData}></TitleView>

                <div className="comm-row" style={{padding:"10px 0px"}}>
                  <div>消费金额：{orderAmout}</div>
                  <div style={{marginLeft:20}}>消费人次：{orderCount}</div>
                  <div style={{marginLeft:20}}>一级分红金额：{userDividendsAmount}</div>
                  <div style={{marginLeft:20}}>二级分红金额：{parentUserDividendsAmount}</div>
                </div>

                <ITableList 
                     style={{marginBottom:20}}
                     pagination={true}
                     top={290}
                     columns={this.state.columns} 
                     data={this.state.tableData}
                     totalSize={this.state.totalSize}
                     onTablePageCallback={this.onTablePageCallback.bind(this)}>

                </ITableList>
                {this.state.detailVisible ? (
                  <OrderDetail
                    returnVisible="none"
                    footerVisible="none"
                    visible={this.state.detailVisible}
                    onclickListener={this.onClickListener.bind(this)}
                    modalData={this.state.orderNo}
                  />
                ) : null}
                
            </div>



        )
    }
}

export default Index