
import { daysAgo, lastTime, getConversionYear } from '@comm/dateHelp';

//股东活动
export function SShPlan() {
    return {
        Id: 0,
        MchId: 0,
        ShopId: 0,
        StoreCode: "",
        /**
         * 方案名称
         */
        PlanName: "",
        /**
         * 费用
         */
        PlanFee: 0,
        // /**
        //  * 开始时间
        //  */
        // BeginTime:0,
        // /**
        //  * 结束时间
        //  */
        // OverTime: 0,
        // _BeginTime: "",
        // _OverTime: "",
        /**
         * 开始时间
         */
         BeginTime: daysAgo(0),
         /**
          * 结束时间
          */
         OverTime: lastTime(),
         _BeginTime: getConversionYear(),
         _OverTime: getConversionYear(),
        /**
         * ActivityState 枚举
         */
        Enabled: 1, // 1开启 2停止
        /**
         * 是否可以消费 1 是 2 否
         */
        Consumption: 2,
        /**
         * 卡类型
         */
        CardTypeId: 0,
        /**
         * 卡余额
         */
        CardBalance: 0,
        /**
         * 有效月数 0 为永久
         */
        ExpiresMonth: 0,
        _ExpiresMonth: 0,
        /**
         * 背景图
         */
        BackgroundImage: "",
        /**
         * 内容
         */
        ActivityContent: "",
        CreateTime: 0,
        UpdateTime: 0,
        /**
         * 股东分佣 SShPlanCommissionRate
         */
        ShCommissionRate: SShPlanCommissionRate(),
        /**
         * 次级分佣 SShPlanCommissionRate
         */
        ShEmpCommissionRate: SShPlanCommissionRate()
    }

}


//股东活动分红比例
export function SShPlanCommissionRate() {
    return {
        /**
            * 分佣类型 1 统一 2 按分类
            */
        CommissionType: 1,
        /**
         * 统一比例
         */
        UniteRate: 0,
        /**
         * 商品分红比例
         */
        NormalRate: 0,

        /**
         * 服务分红比例
         */
        Virtualrate: 0,
        /**
         * 开卡分红比例
         */
        Cardrate: 0,
        /**
         * 开套餐卡分红比例
         */
        Tcardrate: 0,
        /**
         * 充值分红比例
         */
        Chargerate: 0,
        /**
         * 自定义项目比例 SShPlanCommissionItemRate
         */
        ItemRateList: []
    }



}



//股东活动项目分红比例
export function SShPlanCommissionItemRate() {
    return {
        /**
         * 项目id
         */
        ItemId: 0,
        /**
         * 项目类型
         */
        ItemType: 0,
        /**
         * 项目名称
         */
        ItemName: "",
        /**
         * 项目售价
         */
        ItemPrice: 0,
        /**
         * 项目比例
         */
        ItemRate: 0,
    }


}




//拓客活动
export function SShActivity() {
    return {
        Id: 0,
        MchId: 0,
        ShopId: 0,
        StoreCode: "",
        /**
         * 活动名称
         */
        ActivityName: "",
        /**
         * 售价
         */
        Price: 0,
        /**
         * 活动价
         */
        ActivityPrice: 0,
        /**
         * 开始时间
         */
        BeginTime: daysAgo(0),
        /**
         * 结束时间
         */
        OverTime: lastTime(),
        _BeginTime: getConversionYear(),
        _OverTime: getConversionYear(),
        /**
         * 数量
         */
        Count: 0,
        /**
         * 使用数量
         */
        UseCount: 0,
        /**
         * ActivityState 枚举
         */
        State: 1, // 1开启 2停止
        /**
         * 背景图
         */
        BackgroundImage: "",
        /**
         * 内容
         */
        ActivityContent: "",
        /**
         * 活动分佣
         */
        BnsCms: 0,
        CreateTime: 0,
        UpdateTime: 0,
        /**
        * 自定义项目比例  SShActivityItem
        */
        ActivityItems: [],

        ExpireDay: 0,
        _ExpireDay: 0,
    }

}


//拓客活动项目
export function SShActivityItem() {
    return {
        Id: 0,
        MchId: 0,
        ShopId: 0,
        /**
         * 活动id
         */
        ActivityId: 0,
        /**
         * 售价
         */
        Price: 0,
        /**
         * 活动价
         */
        ActivityPrice: 0,
        /**
         * 数量
         */
        Count: 0,
        /**
         * 金额
         */
        Amount: 0,
        /**
         * 产品Id
         */
        ProductId: 0,
        /**
         * 产品名
         */
        ProductTitle: "",
        /**
         * 产品类型1商品2服务
         */
        ProductType: 0,
        /**
         * 有效天数 0 永久有效
         */
        ExpireDay: 0,
        _ExpireDay: 0,
        /**
         * 成本价
         */
        Cost: 0,
        CreateTime: 0,
        UpdateTime: 0,
    }

}


//顾客
export function SShCustomer() {
    return {
        Id: 0,
        MchId: 0,
        ShopId: 0,
        StoreCode: "",
        /**
         * 参与活动id
         */
        ActivityId: 0,
        /**
         * 参与活动名
         */
        ActivityName: "",
        /**
         * 顾客id
         */
        CusId: 0,
        /**
         * 用户标识
         */
        UserCode: "",
        /**
         * 电话号码
         */
        Phone: "",
        /**
         * 姓名
         */
        Name: "",
        /**
         * 性别
         */
        Sex: 0,
        /**
         * 股东id
         */
        UserId: 0,
        /**
         * 上一级股东id
         */
        ParentUserId: 0,
        /**
         * 消费次数
         */
        OrderSum: 0,
        /**
         * 订单数
         */
        OrderCount: 0,
        CreateTime: 0,
        UpdateTime: 0,
    }

}



//股东
export function SShUser() {
    return {
        Id: 0,
        MchId: 0,
        ShopId: 0,
        StoreCode: "",
        /**
         * 方案id
         */
        PlanId: 0,
        /**
         * 上一级股东id
         */
        ParentUserId: 0,
        /**
         * 方案名
         */
        PlanName: "",
        /**
         * 到期时间
         */
        ExpiresTime: 0,
        /**
         * 用户id
         */
        UserCode: "",
        /**
         * 电话号码
         */
        Phone: "",
        /**
         * 姓名
         */
        Name: "",
        /**
         * 银行卡名
         */
        BankCardNo: "",
        /**
         * 持卡人姓名
         */
        BankAcName: "",
        /**
         * 微信号
         */
        WxNo: "",
        /**
         * 支付宝号
         */
        AliNo: "",
        /**
         * 股东数量
         */
        SubordinateUserCount: 0,
        /**
         * 顾客数量
         */
        CustomerCount: 0,
        CreateTime: 0,
        UpdateTime: 0,
    }



}






/**
 * 项目类型
 */
export const ItemType = {
    /**
     * 商品
     */
    Normal: 1,

    /**
     * 服务
     */
    Virtual: 2,

    /**
     * 会员卡
     */
    Card: 3,

    /**
     * 套餐卡
     */
    TCard: 4,

    /**
     * 充值
     */
    Charge: 5,

}


/** 活动状态 */
export const ActivityState = {
    /** 未开始 */
    NoStart: 1,
    /** 进行中 */
    Normal: 2,
    /** 已结束 */
    Over: 3,
    /** 中止 */
    Abort: 4
}



//分红详情
export function SShDividendsDetail() {
    return {
        Id: 0,
        MchId: 0,
        ShopId: 0,
        StoreCode: "",
        /**
         * 顾客id
         */
        CusId: 0,
        /**
         * 顾客姓名
         */
        CusName: "",
        /**
         * 顾客手机号
         */
        CusPhone: "",
        /**
         * 订单号
         */
        OrderNo: "",
        /**
         * 订单金额
         */
        OrderAmount: 0,
        /**
         * 所属股东id
         */
        UserId: 0,
        /**
         * 所属股东名
         */
        UserName: "",
        /**
         * 所属股东分红金额
         */
        UserDividendsAmount: 0,
        /**
         * 上一级股东id
         */
        ParentUserId: 0,
        /**
         * 上一级股东名
         */
        ParentUserName: "",
        /**
         * 上一级股东分红金额
         */
        ParentUserDividendsAmount: 0,
        /**
         * 订单时间
         */
        OrderTime: 0,
        CreateTime: 0,
        UpdateTime: 0,
        /**
           * 订单状态
           */
        OrderState: 0,
    }


}

//分红账单
export function SShUserDividendsBill() {
    return {
        Id: 0,
        MchId: 0,
        ShopId: 0,
        StoreCode: "",
        /**
         * 股东id
         */
        UserId: 0,
        /**
         * 股东名
         */
        UserName: "",
        /**
         * 分红金额
         */
        UserDividendsAmount: 0,
        /**
         * 下级分红金额
         */
        SubordinateDividendsAmount: 0,
        /**
         * 1 待结算 2 代确认 3 已结算
         */
        State: 0,
        CreateTime: 0,
        UpdateTime: 0,
    }




}
