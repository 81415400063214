import IDrawer from '@components/iDrawerPage'
import { IInputMoney } from '@components/iInput'
import IInputString from '@components/iInputString'
import ITextArea from '@components/iTextAreaString'
import ITableList from '@components/iTableList'
import { update } from "@js/goods/action"
import GoodsModel from "@js/goods/goodsInto/goodsModel"
import { Button, Input, Select, Tabs ,Steps,Tooltip} from 'antd'
import React from 'react'
import { connect } from 'react-redux'

import {getTime} from "@comm/dateHelp"
import {calcMultiply} from "@comm/utils"
import service from "@js/goods/service";
const {Option} = Select
let begin = 0;
let over = 20;
const { Step } = Steps;
const reduxStaste = (state) => {
    return {
        ...state.goodsData,
    }
}
@connect(
    state => reduxStaste(state),
    { update }
)
class model extends React.Component {
    constructor() {
        super();
        this.state = {
            columns : [
                {
                    title: '序号',
                    dataIndex: 'ApplicationNo',
                    align: 'center',
                    render: text => <Tooltip placement="top"title={text}>
                      <div className="comm-text-over">{text}</div>
                    </Tooltip>
                  },
                  {
                    title: '姓名',
                    align: 'center',
                    dataIndex: 'ApplicationType',
                    render: text => <Tooltip placement="top"title={text}>
                      <div className="comm-text-over">{text}</div>
                    </Tooltip>
                  },
                  
                  {
                    title: '手机',
                    align: 'center',
                    dataIndex: 'SupplierName',
                  },
                  {
                    title: '领取时间',
                    align: 'center',
                    dataIndex: 'SupplierName',
                  },
                  {
                    title: '活动名称',
                    align: 'center',
                    dataIndex: 'SupplierName',
                  },
                  {
                    title: '门市价',
                    align: 'center',
                    dataIndex: 'SupplierName',
                  },
                  {
                    title: '体验价',
                    align: 'center',
                    dataIndex: 'SupplierName',
                  },
                  {
                    title: '拓客股东',
                    align: 'center',
                    dataIndex: 'SupplierName',
                  },
                 
              ],
              tableData : []

        }



    }

   

    componentDidMount() {
        begin = 0;
        over = 20;
         const that = this;
         this.getPageList();
     }
 
     getPageList(){
       const that = this;
       const {beginTime,overTime,goodsType,applicationTypeId,goodsId} = this.props;
       service.queryPmsGoodsApplicationLogDetails(
         that,
         this.props.mode==="mch"?1:2,
         begin,
         over,
         beginTime,
         overTime,
         2,
         goodsType,
         applicationTypeId,
         goodsId
         )
       .then(res=>{
         if(res != null && res.Data != null){
           let list = JSON.parse(res.Data)
           list.forEach(item=>{
              item.CreateTime = getTime(item.CreateTime);
              item.Cost =item.Cost/100;
              item._allCost = calcMultiply(item.Cost,item.ApplicationNum);
           })
           that.setState({
             tableData: list,
             totalSize:res.Count,
           })
         }
       })
       
     }
 


    selectChange(tag,e) {
       
    }

    onShowSizeChange() {

    }

    onClickListener(tag){
        if(tag === "drawer-ok"){
            this.props.update({
                goodsOutCountVisible:false
            })
        }else if(tag === "drawer-cancel"){
            this.props.update({
                goodsOutCountVisible:false
            })
        }
    }

    handleChange(){}
 
      
    onTablePageCallback(start, end){
        begin = start;
        over = end;
        this.getPageList();
      }
  
    render() {



        return (
            <IDrawer
                title={"详情"} 
                footer={false} 
                onCancel={this.props.onCancel}
                width={"90%"} 
                visible={this.props.visible}
            >
                
                <div className="comm-w-h">
                  <div className="comm-flex-row-between" style={{paddingBottom:10}}>
                      <div className="comm-flex-row-center">
                      股东:
                          <Select defaultValue="0" style={{ width: 120,marginLeft:8 }} onChange={this.handleChange.bind(this)}>
                              <Option value="0">全部股东</Option>
                              <Option value="1">一级股东</Option>
                              <Option value="2">二级股东</Option>
                          </Select>
                      </div>
                      <div>
                         <span style={{paddingLeft:30}}>发放数量：300</span>
                         <span style={{paddingLeft:30}}>领取数量：100</span>
                         <span style={{paddingLeft:30}}>剩余数量：300</span>
                         <span style={{paddingLeft:30}}>收取金额：300</span>
                      </div>
                  </div>
                    <ITableList 
                        top={190}
                        columns={this.state.columns} 
                        data={this.state.tableData}
                        totalSize={this.state.totalSize}
                        onTablePageCallback={this.onTablePageCallback.bind(this)}>

                    </ITableList>
                </div>
                 
               
            </IDrawer>

        )
    }
}

export default model