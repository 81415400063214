import React from 'react';
import {Input} from 'antd';
import {money_max} from "../comm/constans";

// import EditableCell from './EditableCell'


export default class IInputMoney extends React.Component {
    constructor() {
        super();
    }

    onChange(e) {
        //修复第一个字符是小数点 的情况.
        if (e.target.value != '' && e.target.value.substr(0, 1) == '.') {
            e.target.value = "";
        }
        e.target.value = e.target.value.replace(/^0*(0\.|[1-9])/, '$1');//解决 粘贴不生效
        e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
        e.target.value = e.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        e.target.value = e.target.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
        if (e.target.value.indexOf(".") < 0 && e.target.value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
            if (e.target.value.substr(0, 1) == '0' && e.target.value.length == 2) {
                e.target.value = e.target.value.substr(1, e.target.value.length);
            }
        }
        if (this.props.max) {
            if (e.target.value > this.props.max) {
                e.target.value = this.props.max
            }
        } else {
            if (e.target.value > money_max) {
                e.target.value = money_max
            }
        }


        if (this.props.onChange) {
            this.props.onChange(e.target.value)
        } else {
            this.props.onCallback(e.target.value, this.props.data, this.props.tag)
        }

    }

    render() {
        return (
            <Input disabled={this.props.disabled} type="text" style={this.props.style} value={this.props.value}
                   onChange={this.onChange.bind(this)}
                   className={this.props.className}
                   defaultValue={this.props.defaultValue}
                   placeholder={this.props.placeholder}
                   onFocus={this.props.onFocus}
                   onClick={this.props.onClick}
                   max={this.props.max}/>
        );
    }
}
