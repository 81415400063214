
import {
    SUBMIT,
    UPDATE,
    GOODS_MANAGE_MODE,
} from './action';









const initData=()=>{
    return {
        refresh:1, //更新页面
        
       
        goodsManageTag:"",
        goodsManageVisible:false,
        goodsManageFlag:1, //类别 1  单位 2  入库类型 3  出库类型 4
        goodsManageDedail:{}, //商品参数详情
        goodsManageTypeList:[], //商品类别管理列表
        goodsManageIntoList:[], //商品入库列表
        goodsManageOutList:[], //商品出库列表
        goodsManageSpecsList:[], //规格单位列表
        goodsManageTypeVisible:false,
        goodsManageTypeTitle:"",
        goodsManageTypeName:"",
        goodsManageTypeText:"",
        supplierVisible:false,
        supplierData:{},
        goodsDetail:{}, //商品详情
        supplierDetail:{}, //供应商详情
    }
}

const initialState = initData();

const reducer = (state = initialState, action) => {
    let  {openOrderList,refresh,silverVisible,detail} = state;
    switch (action.type) {
        case UPDATE: 
            return {
                ...state,
                ...action.data,
                refresh:refresh++
            };
        case SUBMIT:
            return {
                ...state,
                ...action.data
            };
        case GOODS_MANAGE_MODE:
            return {
                ...state,
                mode:action.data
            };
        default:
            return state;

    }
}

export default reducer;