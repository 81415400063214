

export const StorageTools = {
    //保存商户ID
    saveMchId:(data)=>{
        sessionStorage.setItem("mchId", JSON.stringify(data));
    },
    getMchId:()=>{
        return sessionStorage.getItem('mchId')
    },
    // 保存用户信息
    saveUserInfo: (data) => {
        sessionStorage.setItem("userInfo", JSON.stringify(data));
    },
    // 获取用户信息
    getUserInfo: (data) => {
        return sessionStorage.getItem("userInfo");
    },
    // 保存选择的身份信息
    saveIdentity: (data) => {
        sessionStorage.setItem("saveIdentity", JSON.stringify(data));
    },
    // 获取选择的身份信息
    getIdentity: (data) => {
       let  identity = sessionStorage.getItem("saveIdentity")
        if(identity == null){
            return {};
        }
        return JSON.parse(identity);
    },

    // 保存商户信息
    saveMchInfo: (data) => {
        sessionStorage.setItem("mchInfo", JSON.stringify(data));
    },
    getMchInfo: () => {
        return JSON.parse(sessionStorage.getItem("mchInfo"));
    },

    // 保存连锁信息code
    saveMchCode: (data) => {
        sessionStorage.setItem("mchCode", data);
    },
    getMchCode: () => {
        return sessionStorage.getItem("mchCode");
    },
    // 保存基本信息
    saveConfig: (data) => {
        sessionStorage.setItem("AppConfig", JSON.stringify(data));
        // sessionStorage.setItem("AppConfig", data);
    },
    getConfig: () => {
        let appConfig = sessionStorage.getItem("AppConfig")
        if(appConfig == null){
            return {};
        }
        return JSON.parse(appConfig);
    },
    // 保存连锁信息code
    saveMchName: (data) => {
        sessionStorage.setItem("mchName", data);
    },
    getMchName: () => {
        return sessionStorage.getItem("mchName");
    },
    /**保存登录手机号 */
    saveloginPhone: (data) => {
        sessionStorage.setItem("loginPhone", data);
    },
    getloginPhone: () => {
        return sessionStorage.getItem("loginPhone");
    },

    saveStoreName: (data) => {
        sessionStorage.setItem('Name', data);
    },
    saveStoreCode: (data) => {
        sessionStorage.setItem("StoreCode", JSON.stringify(data));
    },
    getStoreCode: () => {
        return sessionStorage.getItem("StoreCode");
    },
    saveStoreToken: (data) => {
        sessionStorage.setItem("StoreToken", data);
    },
    getStoreToken: () => {
        return sessionStorage.getItem("StoreToken");
    },
    // 保存用户身份
    saveUserIdenty: (data) => {
        sessionStorage.setItem("userIdenty", JSON.stringify(data));
    },
    getUserIdenty: (data) => {
        return sessionStorage.getItem("userIdenty");
    },
    getStoreName: (data) => {
        return sessionStorage.getItem("Name");
    },
    saveUserName: (userName, pwd) => {
        sessionStorage.setItem(userName, pwd);
    },
    saveUserPwd: (data) => { //todo: 不允许明文保存密码
        sessionStorage.setItem("userPwd", data);
    },
    getUserName: (userName) => {
        return sessionStorage.getItem(userName);
    },
    getUserPwd: () => {
        return sessionStorage.getItem("userPwd");
    },
    removeUserName: (userName) => {
        sessionStorage.removeItem(userName)
    },
    // 获取用户信息
    getUserInfo: () => {
        return JSON.parse(sessionStorage.getItem("userInfo"));
    },
    // 删除用户信息
    removeUserInfo: () => {
        sessionStorage.removeItem("userInfo");
    },
    // 保存用户userCode
    saveUserCode: (userCode) => {
        sessionStorage.setItem("userCode", userCode);
    },
    // 获取用户userCode
    getUserCode: () => {
        return sessionStorage.getItem("userCode");
    },
    // 删除用户userCode
    removeUserCode: () => {
        sessionStorage.removeItem("userCode");
    },
    //保存用户购买的模块
    saveBuyModule: (data) => {
        sessionStorage.setItem("saveBuyModule", JSON.stringify(data));
    },
    // 获取用户购买的模块
    getBuyModule: () => {
        return JSON.parse(sessionStorage.getItem("saveBuyModule"));
    },
    //保存模块信息
    saveUserModule: (data) => {
        sessionStorage.setItem("userModule", JSON.stringify(data));
    },
    // 获取模块信息
    getUserModule: () => {
        return JSON.parse(sessionStorage.getItem("userModule"));
    }, // 保存登录
    saveUserLogin: (data) => {
        localStorage.setItem("userLogin", JSON.stringify(data));
    },// 获取用户登录
    getUserLogin: () => {
        return JSON.parse(localStorage.getItem("userLogin"));
    },//保存导航栏
    saveNavigationBar: (data) => {
        localStorage.setItem("navigationBar", JSON.stringify(data));
    },// 获取导航栏
    getNavigationBar: () => {
        return JSON.parse(localStorage.getItem("navigationBar"));
    },//保存noticeTime
    saveNoticeTime: (data) => {
        localStorage.setItem("noticeTime", data);
    },// 获取noticeTime
    getNoticeTime: () => {
        return localStorage.getItem("noticeTime");
    },
    // 保存用户版本信息
    saveUserVsersion: (data) => {
        sessionStorage.setItem("userVersion", JSON.stringify(data));
    },
    // 获取用户版本信息
    getUserVsersion: () => {
        return JSON.parse(sessionStorage.getItem("userVersion"));
    },
    // 保存用户版本信息
    saveTimeOutClose: (data) => {
        sessionStorage.setItem("timeOutClose", data);
    },
    // 获取用户信息
    getTimeOutClose: () => {
        return JSON.parse(sessionStorage.getItem("TimeOutClose"));
    },//保存自动备份会员的时间
    saveVipInfoTime: (data) => {
        localStorage.setItem("saveVipInfoTime", data);
    },// 获取自动备份会员的时间
    getVipInfoTime: () => {
        return localStorage.getItem("saveVipInfoTime");
    },//保存自动备份会员
    saveVipData: (data) => {
        localStorage.setItem("saveVipInfoData", JSON.stringify(data));
    },// 获取自动备份会员的
    getVipData: () => {
        let saveVipInfoData = localStorage.getItem("saveVipInfoData");
        if (saveVipInfoData != null) {
            return JSON.parse(saveVipInfoData);
        }
        return null;
    },//保存会员是否开启扫码付
    saveOpenCode: (data) => {
        localStorage.setItem("saveOpenCode1", JSON.stringify(data));
    },// 获取会员是否开启扫码付
    getOpenCode: () => {
        let saveVipInfoData = localStorage.getItem("saveOpenCode1");
        if (saveVipInfoData != null) {
            return JSON.parse(saveVipInfoData);
        }
        return null;
    },//保存是否结算后自动打印
    saveStorePrintSetting: (data) => {
        // debugger
        localStorage.setItem("saveStorePrintSetting", data);
    },// 获取是否结算后自动打印
    getStorePrintSetting: () => {
        let d = localStorage.getItem("saveStorePrintSetting");
        if (d == null) {
            return 2; //1 选中、2 未选中
        }
        return d;
    },//保存打印方式
    saveStorePrintName: (data) => {
        // debugger
        localStorage.setItem("saveStorePrintName", data);
    },// 获取打印方式
    getStorePrintName: () => {
        let d = localStorage.getItem("saveStorePrintName");

        return d;
    },
    //保存打印小票数量
    saveStoreDoPrintNum: (data) => {
        // debugger
        localStorage.setItem("saveStoreDoPrintNum", data);
    },// 获取打印小票数量
    getStoreDoPrintNum: () => {
        let d = localStorage.getItem("saveStoreDoPrintNum");

        return d?d:1;
    },


     // 保存备注模板图片
     saveRemarkImage: (data) => {
        sessionStorage.setItem("remarkImage", JSON.stringify(data));
    },
    // 获取备注模板图片
    getRemarkImage: () => {
        return JSON.parse(sessionStorage.getItem("remarkImage"));
    },
    //录入中选择的卡ID
    saveSelectCardTid: (data) => {
        sessionStorage.setItem("selectCardTid", data);
    },
    getSelectCardTid: () => {
        return JSON.parse(sessionStorage.getItem("selectCardTid"));
    },
     // 保存登录信息usercode
     saveLoginUserCode: (data) => {
        sessionStorage.setItem("loginUserCode", data);
    },
    // 得到登录信息usercode
    getLoginUserCode: () => {
        return sessionStorage.getItem("loginUserCode");
    },
    //保存连锁身份
    saveUserChainIdenflty: (data) => { //
        sessionStorage.setItem("ChainIdenflty", JSON.stringify(data));
    },
    //获取连锁身份
    getUserChainIdenflty: () => {
        return JSON.parse(sessionStorage.getItem("ChainIdenflty"));
    },
    //保存登录人权限
    savemangleAuthority: (data) => { //
        sessionStorage.setItem("Authority", JSON.stringify(data));
    },
    //获取登录人权限
    getmangleAuthority: () => {
        return JSON.parse(sessionStorage.getItem("Authority"));
    },
    
    //保存登录人权限
    savemanguserInfoAuthority: (data) => { //
        sessionStorage.setItem("userInfoAuthority", JSON.stringify(data));
    },
    //获取登录人权限
    getmanguserInfoAuthority: () => {
        return JSON.parse(sessionStorage.getItem("userInfoAuthority"));
    },
    saveUserLoginPhone:(data)=>{
        sessionStorage.setItem("UserLoginPhone", data);
    },
    getUserLoginPhone:(data)=>{
        return sessionStorage.getItem("UserLoginPhone");
    },
    //持久本地
    saveConfigLoacl:(data)=>{
        localStorage.setItem("saveConfigLoacl", JSON.stringify(data));
    },
    getConfigLoacl:(data)=>{
        const appConfig = localStorage.getItem("saveConfigLoacl")
        if(appConfig == null){
            return {};
        }
        return JSON.parse(appConfig);
    },
    //保存店铺信息
    saveShopInfo:(data)=>{
        sessionStorage.setItem("UserLoginPhone", data);
    },
    //获取店铺信息
    getShopInfo:(data)=>{
        return sessionStorage.getItem("UserLoginPhone");
    },
    //房间收银的状态
    saveRoomData: (data) => {
        localStorage.setItem("saveRoomData", JSON.stringify(data));
    },//获取房间收银的状态
    getRoomData: () => {
        let saveRoomData = localStorage.getItem("saveRoomData");
        if (saveRoomData != null) {
            return JSON.parse(saveRoomData);
        }
        return {};
    },
    //用户版本
    saveUserProd: (data) => {
        sessionStorage.setItem("saveUserProd", JSON.stringify(data));
    },//用户版本
    getUserProd: () => {
        let saveUserProd = sessionStorage.getItem("saveUserProd");
        if (saveUserProd != null) {
            return JSON.parse(saveUserProd);
        }
        return {};
    }




    

};

export default StorageTools;