import React from "react";
import {Checkbox, Form, Icon, Input, Radio} from 'antd';
import iEnum from "../comm/iEnum";
import IInputMoney from "../components/iInputMoney";
import StorageTools from "../comm/storageTools";
import {$mzlApi} from "../comm/mzl-core";
import APINames from "../comm/api-names";

const utils = require("../comm/utils");

const FormItem = Form.Item;
const Search = Input.Search;
const RadioGroup = Radio.Group;


export default class ItemVipView extends React.Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            vipData: [],
            isShowAll: false,
            isUnify: false,
            PriceSettingsBasis: iEnum.ESettingsBasis.Custom
        }
    }

    componentWillMount() {
    }


    shouldComponentUpdate(news) {
        if (news.saveData.PriceSettingsBasis != null && news.saveData.PriceSettingsBasis == iEnum.ESettingsBasis.Global) {
            this.state.isUnify = true;
        }
        this.state.PriceSettingsBasis = this.props.saveData.PriceSettingsBasis;
        return true;
    }

    componentDidMount() {
        // if(this.props.saveData.PriceSettingsBasis == iEnum.ESettingsBasis.Global){
        //     this.state.isUnify = true;
        // }
        // this.setState({
        //     PriceSettingsBasis:this.props.saveData.PriceSettingsBasis
        // })

        // this.requestQueryMchCardList();
    }


    /**
     * 获取会员卡类型
     */
    requestQueryMchCardList() {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('kind', 1);
        map.set('opt', JSON.stringify({Begin: 0, Over: 999}));
        $mzlApi.post(APINames.API_queryMchCardList, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    httpSuccess(res, tag) {
        ////console.log("httpSuccess", tag, res)
        switch (tag) {
            case APINames.API_queryMchCardList:
                this.setState({
                    vipData: JSON.parse(res.Data)
                });
                break;
        }
    }

    httpFail(res, tag) {
        ////console.log("httpFail", tag, res)
    }

    onChange(tag, e) {
        switch (tag) {
            case 'vipPrice':
                var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                this.props.saveData.PriceValueText = money;
                this.props.saveData.PriceValue = utils.calcMultiply(money, 100);
                break;
            case 'vip-sy': //所有会员均按此价格收银
                if (this.state.isUnify) {
                    this.state.PriceSettingsBasis = iEnum.ESettingsBasis.Custom;
                } else {
                    this.state.PriceSettingsBasis = iEnum.ESettingsBasis.Global;

                }
                this.props.saveData.PriceSettingsBasis = this.state.PriceSettingsBasis;

                this.setState({
                    isUnify: !this.state.isUnify
                });
                break;
            case 'all':
                this.setState({
                    isShowAll: !this.state.isShowAll
                });
                break;

        }
        this.setState(this);
    }

    onItemChange(tag, data, e) {
        switch (tag) {
            case 'UnitPriceText':
                data.UnitPriceText = utils.checkMoneyMax(e, this.props.saveData.PriceText);
                data.UnitPrice = utils.numberConvert_100(data.UnitPriceText);
                data.CustomizedPrice = data.UnitPrice;
                break;
        }
        this.setState(this);
    }


    getItemView() {
        if (this.state.isShowAll) {
            return this.props.vipList;
        } else {
            var temp = [];
            this.props.vipList.forEach((item, index) => {
                if (index < 5) {
                    temp.push(item)
                }
            });
            return temp;
        }
    }

    render() {
        console.log("itemVipView",this.props.saveData, this.props.vipList);
        const vipList = this.getItemView();
        const vipDataView = vipList.length
            ? vipList.map((item, index) => (
                <div key={index} className="comm-row comm-item-center comm-item-justify-between"
                     style={{width: "100%", height: "44px"}}>
                    <div className="comm-text-over" style={{width: "150px"}}>{item.CardName}</div>
                    <div
                        className="comm-visibility-visible">
                        <IInputMoney value={item.UnitPriceText}
                                     onChange={this.onItemChange.bind(this, "UnitPriceText", item)}
                                     style={{width: 80, marginRight: "10px"}}/>
                        <span>元</span>
                    </div>
                </div>

            )) : null;

        return (
            <div className="comm-col ">
                <div className="comm-row comm-item-center  comm-padding-top-10">
                    <span style={{marginLeft: 8}}>会员售价</span>
                    <Checkbox style={{marginLeft: 20}} checked={this.state.isUnify}
                              onChange={this.onChange.bind(this, "vip-sy")}>所有会员均按此价格收银</Checkbox>
                    {this.state.isUnify ? <IInputMoney value={this.props.saveData.PriceValueText} placeholder="会员售价"
                                                       style={{width: 80, marginLeft: "78px",}}
                                                       onChange={this.onChange.bind(this, "vipPrice")}/> : null}

                </div>
                {this.state.isUnify ? null : <div style={{marginLeft: "82px", paddingTop: "8px"}}>
                    {vipDataView}
                    {this.props.vipList.length > 5 ?
                        <a onClick={this.onChange.bind(this, "all")}>{this.state.isShowAll ?
                            <div>收起<Icon type="up"/></div> : <div>展开<Icon type="down"/></div>}</a> : null}
                </div>}

            </div>
        );
    }
}