import React from 'react';
import {Table,Pagination} from 'antd';
import "../../css/comm.css"
import {debounce} from "../comm/utils"

var indexStart = 0; //
var indexEnd = 20;
var pageSize = 20;

var tabH = 0; //tab的高度
var top =  130; //顶部离tab的距离
var buttom =  80; //底部离tab的距离\
var itemH = 38; //item高度
var currentPage = 1; //当前在第几页



var locat = {filterConfirm: '确定'};
export default class ITablePage extends React.Component {
    constructor() {
        super();

        
    }




    componentWillMount() {
        var that = this;
        that.setCallBack = debounce(that.setCallBack,500)
        
        //带编辑框
        if(this.props.rowSelection){
            itemH = 48;
        }else{
            itemH = 38;
        }
        //初始化
        countPageSize();
        indexEnd = pageSize;
        //监听页面尺寸的变化
        window.onresize = function() {
            countPageSize();
            if (that.props.onTablePageCallback != null) {
                indexEnd = currentPage * pageSize;
                indexStart = indexEnd - pageSize;
                that.setCallBack();

            }
            //返回页码和页面可以展示条数
            if (that.props.onPageSize != null) {
                that.props.onPageSize(currentPage, pageSize)
            }
            that.setState(this)
        }

    }

    
  

    setCallBack =()=>{
        this.props.onTablePageCallback(indexStart, indexEnd)
    }

    handleChange(){
        if (this.props.onChange != null) {
            this.props.onChange();
        }
    }
    onChange(data, event) {
        if(event == null){
            return;
        }
        indexEnd = data * event;
        indexStart = indexEnd - event;
        currentPage = data;
        if (this.props.onTablePageCallback != null) {
            this.props.onTablePageCallback(indexStart, indexEnd)
        }
        //返回页码和页面可以展示条数
        if (this.props.onPageSize != null) {
            this.props.onPageSize(data, event)
        }
        this.setState(this)
    }

    onShowSizeChange(current, size) {
        pageSize = size;
        indexStart = (current - 1) * size;
        indexEnd = indexStart + pageSize;
        if (this.props.onTablePageCallback != null) {
            this.props.onTablePageCallback(indexStart, indexEnd,current)
        }
        //返回页码和页面可以展示条数
        if (this.props.onPageSize != null) {
            this.props.onPageSize(current, size)
        }
        this.setState(this)
        
    }


    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
      };

    render() {
        const {rowSelection,devHeight}  = this.props;
        return (
            <div>
                <Table
                    scroll={this.props.scroll}
                    size={this.props.size?this.props.size:'small'}  //small middle
                    heigth={100}
                    rowSelection={this.props.rowSelection}
                    pagination={{
                        size:"small",
                        showQuickJumper:true,
                        showSizeChanger: true,
                        hideOnSinglePage: false,
                        onChange: this.onChange.bind(this),
                        onShowSizeChange: this.onShowSizeChange.bind(this),
                        pageSize: pageSize,
                        total: this.props.pageCount,
                        showTotal: total => `共${total}条记录`,
                    }}
                    bordered
                    dataSource={this.props.data}
                    columns={this.props.columns}
                    onChange={this.handleChange.bind(this)}
                    onRow={this.props.onRow}
                />
                
            </div>
        );
    }
}

//获取一页最大条数
export function getPageSize() {
    countPageSize();
    return pageSize
}

//设置 顶部离tab的距离
export function setTabTop(h) {
    top = h;
    countPageSize();
}


//设置 底部离tab的距离
export function setTabButtom(h) {
    buttom = h;
    countPageSize();
}

//设置item高度
export function setItmeH(heigth) {
    itemH = heigth;
    countPageSize();
}

//计算tab能显示的最大条数
function  countPageSize() {
   tabH = window.document.body.clientHeight - top - buttom -1;
   pageSize = parseInt(tabH / itemH) -1;
}
