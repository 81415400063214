
import {applyMiddleware, compose, createStore} from 'redux'
import {Provider} from 'react-redux';
import reduxs from './js/reduxs/reduxs'
import Router from "react-router-dom/es/HashRouter"
import Route from "react-router-dom/es/Route"
import Switch from "react-router-dom/es/Switch"
import thunk from "redux-thunk"
import {checkLogin, Login} from "./js/user/login";
import Register from "./js/user/register";
import ResetPwd from "./js/user/resetPwd";
import App from './App';
import ChainApp from "./chainApp";

import {listenNetStatus} from "./js/comm/network";
import './css/index.css';
import {index} from './routes/router';

(async () => {
    let React = await import(/* webpackChunkName:"react" */ 'react');
    let ReactDOM = await import(/* webpackChunkName:"react" */ 'react-dom');
    let root = document.getElementById('root');

    const store = createStore(reduxs, compose(
        applyMiddleware(thunk),
        window.devToolsExtension ? window.devToolsExtension() : f => f
    ));
    listenNetStatus(); // 检测网络状态
    let routes = <Provider store={store}>
        <Router>
            {index()}
        </Router></Provider>;

    ReactDOM.render(routes, root);
})();