
import {
    SUBMIT,
    UPDATE,
    GOODS_MODE,
} from './action';









const initData=()=>{
    return {
        refresh:true, //更新页面
        mode:'store', // store:店铺  mch:商户连锁
        fromMode:'0', //from表单
        goodsIntoVisible:false,
        goodsModelVisible:false,
        goodsModelType:"into",
        goodsApplyVisible:false, //店铺申请model
        goodsApplyDetailVisible:false,//店铺申请-查看-model
        goodsApplyDetailFlag:"", 
        goodsApplyDetailProjectVisible:false,//店铺申请-查看-出入库订单-model
        goodsApplyMultiVisible:false,//连锁申请 多店补货 model
        goodsApplyGoodsVisible:false,//选择商品 model
        goodsApplySingleGoodsVisible:false,//选择单个商品 model
        goodsApplyStoreVisible:false,//选择店铺 model
        goodsIntoCountVisible:false,//入库统计 model
        goodsOutCountVisible:false,//出库统计 model
        mchManyModelVisible:false,////连锁申请 多店补货 model
        mchManyStoreModelVisible:false,////连锁申请 多店补货-选择多个店铺 model
        mchSingleModelVisible:false,////连锁申请 单店补货model
        mchManyGoodsModelVisible:false,////连锁申请 单店补货-多个商品 model
        typeList:[], //商品类别管理列表
        intoList:[], //商品入库列表
        outList:[], //商品出库列表
        specsList:[], //规格单位列表
        detail:{},
        inOutDetail:{},//出入库
        applyDetail:{},//申请详情
        applyOrderDetail:{},//申请订单详情
        applyOrderDetailItem:[],//申请订单详情进度条
    }
}

const initialState = initData();

const reducer = (state = initialState, action) => {
    let  {openOrderList,refresh,silverVisible,detail} = state;
    switch (action.type) {
        case UPDATE: 
            return {
                ...state,
                ...action.data
            };
        case SUBMIT:
            return {
                ...state,
                ...action.data
            };
        case GOODS_MODE:
            return {
                ...state,
                mode:action.data
            };
        default:
            return state;

    }
}

export default reducer;