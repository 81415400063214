/***************************接口名称***************************************/
const APINames = {
    API_checkPatch: "release.checkPatch", //检测版本
    API_storeCompatibleLogin_V1: "account.storeCompatibleLogin_V1", //登录
    API_Login: "user.login", //登录
    API_queryUserIdentity: 'account.queryUserIdentity',//查用户身份
    API_confirmLogin: 'account.confirmLogin',//查店铺信息
    API_requestCheck: "account.requestCheck",//获取验证码
    API_storeCompatibleResetPwd_V1: "account.storeCompatibleResetPwd_V1",//重置密码
    API_VerifyCheck: 'account.verifyCheck',//校验验证码
    API_storeRequestCheck: 'account.storeRequestCheck',//使用店铺短信发送短信
    API_changePhone: 'user.changePhone',//修改绑定手机号

    API_register: "merchant.register",//注册
    API_getUserCode: 'user.getUserCode',//根据手机号查userCode
    API_checkLoginUser: 'merchant.checkLoginUser',//查询是否已注册
    API_getStoreRole: "merchant.getStoreRole",//获取店铺岗位列表
    API_sortStoreRoleSettings:'merchant.sortStoreRoleSettings',//岗位排序
    API_getStoreRoleSettings: 'merchant.getStoreRoleSettings',//岗位提成明细
    API_getStoreRoleSettingsBak: 'merchant.getStoreRoleSettingsBak',//岗位下个月提成明细
    API_saveStoreRoleSettings: "merchant.saveStoreRoleSettings",//新增崗位及提成信息
    API_deleteStoreRole: "merchant.deleteStoreRole",//刪除崗位及所屬配置信息
    API_getEmployeeList: "merchant.getEmployeeList",//获取店铺员工列表
    API_saveEmployee: "merchant.saveEmployee",//添加修改员工信息
    API_deleteEmployee: "merchant.deleteEmployee",//通过员工id删除员工信息
    API_queryMchCardList: "merchant.queryMchCardList",//获取会员卡类型
    API_getMchCard:'merchant.getMchCard',//查会员卡明细
    API_deleteMchCard: "merchant.deleteMchCard",//删除会员卡类型
    API_getBankCardList: "merchant.getBankCardList",//查询银行卡
    API_bankCardAdd: "merchant.bankCardAdd",//绑定银行卡
    API_bankCardDel: "merchant.bankCardDel",//删除银行卡
    API_saveMchCard: "merchant.saveMchCard",//保存会员卡类型
    API_getFilterMchCard: "merchant.getFilterMchCard",//获取筛选后的会员卡、套餐卡
    API_importCusCards: "merchant.importCusCards",//会员卡导入
    API_getImportState: "merchant.getImportState",//会员卡导入进度条
    API_queryImportItems: "merchant.queryImportItems",//会员卡导入结果
    API_checkCardIsUsed: 'merchant.checkCardIsUsed',//判断会员卡是否使用
    API_EnabledMchCard:'merchant.enabledMchCard',//启用禁用卡类型
    API_EnabledItem:'shop.enabledItem',//启用禁用商品服务


    API_sendCheckCode: 'user.sendCheckCode',//获取验证码用户
    API_checkCodeRight: 'user.checkCodeRight',//验证验证码
    API_sendCheckCode1: 'user.sendCheckCode',//获取验证码用户


    API_getStore: "shop.getStore",//获取店铺信息
    API_getTradeClasses: "shop.getTradeClasses",//获取店铺分类列表
    API_saveStore: "shop.saveStore",//获取店铺分类列表
    API_resetAllProdAwardRate: "shop.resetAllProdAwardRate",//重置服务提成比例
    API_queryItem: "shop.queryItem",//查询商品
    API_getItem: "shop.getItem",//获取商品
    API_saveItem: "shop.saveItem",//保存商品
    API_deleteItem: "shop.deleteItem",//删除商品
    API_requestStorePosition: "shop.requestStorePosition",//请求上传店铺位置
    API_listenPostedStorePosition: "shop.listenPostedStorePosition",//监听已上传的店铺位置信息
    API_getSpPay: 'shop.getSpPay',//查询是否开启二维码付款
    API_updateSpPay: 'shop.updateSpPay',//激活二维码付款

    API_SaveShodisjunctorSettings:'shop.saveShodisjunctorSettings',//保存店铺设置开关
    API_QueryShodisjunctorSettings:'shop.queryShodisjunctorSettings',//查询店铺设置开关
    API_SaveHandCardGroup:'shop.saveHandCardGroups',//保存手牌分组
    API_QueryHandCardGroups:'shop.queryHandCardGroups',//查询手牌分组
    API_SaveHandCardInformation:'shop.saveHandCardInformation',//保存店铺手牌
    API_DeleteHandCardInformation:'shop.deleteHandCardInformation',//删除店铺手牌
    API_DeleteHandCardGroup:'shop.deleteHandCardGroup',//删除店铺手牌分组


    API_resultOrderNo: "sms.resultOrderNo",//购买短信验证码
    API_presentMessage: "sms.presentMessage",//赠送短信
    API_getTmpSmsTpl: "sms.getTmpSmsTpl",//查询短信模板
    API_findCardObject: "sms.findCardObject",//查询发送会员卡对象
    API_findRecord: "sms.findRecord",//查询短信使用记录
    API_findBuy: "sms.findBuy",//查询短信购买记录
    API_findCount: "sms.findCount",//查询短信剩余
    API_findTemplate: "sms.findTemplate",//查询当前模板
    API_saveTemplate: "sms.saveTemplate",//保存模板
    API_querySysTemplate: 'sms.querySysTemplate',//查询短信设置模板
    API_updateTplState: 'sms.updateTplState',//更新短信状态


    API_queryStoreOrderList: "order.queryStoreOrderList",//查询订单列表
    API_submitStoreOrder: "order.submitStoreOrder",//提交店铺订单，包括暂存订单
    API_submitStoreOrderByCart: "order.submitStoreOrderByCart",//根据购物车提交店铺订单
    API_submitEditableStoreOrder: "order.submitEditableStoreOrder",//提交店铺订单，包括暂存订单
    API_getStoreOrder: "order.getStoreOrder",//订单详情
    API_cancelStoreOrder: "order.cancelStoreOrder",//取消订单
    API_destroyStoreOrder: "order.destroyStoreOrder",//销毁店铺订单
    API_consumptionRatio: "order.consumptionRatio",//订单分析上部分 订单数 单客价以及消费占比
    API_getConsumption:'new-report-business-data.getConsumption',//男女比例，客户来源
    API_orderTrend: "order.orderTrend",//订单分析下部分折线图部分
    API_businessTurnover: "order.businessTurnover",//营业总额饼图
    API_businessTrend: "order.businessTrend",//营业额走势
    API_businessRanking: "order.businessRanking",//营业额排名
    API_cashierSourceChart: "order.cashierSourceChart",//收银来源饼图
    API_cashierSourceTable: "order.cashierSourceTable",//收银来源表格
    API_cashierSourceOrderLog: "order.cashierSourceOrderLog",//收银来源明细
    API_businessOrderLog: "order.businessOrderLog",//营业明细
    API_IncomeAnalysis: 'order.incomeAnalysis',//收入分析/平均收入
    API_storedValueList: 'order.storedValueList',//开卡、卡余额趋势图
    API_MemberConsumption: 'order.memberConsumption',//会员卡/套餐卡扣卡
    API_prepareStoreOrder: "order.prepareStoreOrder",//校验优惠券


    // API_payMethodDaySummary: "chart.payMethodDaySummary",//按日查询支付方式汇总信息
    API_employeeAwardDaySummary: "chart.employeeAwardDaySummary",//按日查询员工汇总信息
    API_searchCustomer: "customer.searchCustomer",//模糊查询顾客信息
    API_searchChainCustomers: "customer.searchChainCustomers",//模糊查询连锁顾客信息
    API_getMembership: "card.getMembership",//查询会员卡信息
    API_findPhone: "card.findPhone",//查询会员基本信息
    API_findRechargeRecord: "card.findRechargeRecord",//查询会员充值记录
    API_getCard: "card.getCard",//查询套餐卡信息
    API_cardFindRecord: "card.findRecord",//查询会员卡套餐卡记录
    API_offlineDiscount: "payment.offlineDiscount",//线下支付抵扣,并返回抵扣后的订单状态
    API_getCardAndRecharge: "card.getCardAndRecharge",//按周月查询开卡充值报表
    API_walletIncomeChart: "chart.walletIncomeChart",//现金收入报表
    API_findMembershipDetails: "chart.findMembershipDetails",//现金收入报表单条商品明细
    API_findCommodityServiceDetails: "chart.findCommodityServiceDetails",//现金收入报表单条服务明细
    API_employeeAwardChart: "chart.employeeAwardChart",//业绩报表
    API_employeeAwardDetailsChart: "chart.employeeAwardDetailsChart",//业绩报表明细
    API_customerTotalChart: "chart.customerTotalChart",//会员报表
    API_walletIncomeStoreOrderChart: "chart.walletIncomeStoreOrderChart",//店铺钱包
    API_findBillMember: "card.findBillMember",//查询会员账单信息
    API_findBillCard: "card.findBillCard",//查询套餐卡账单信息
    API_updateCusCardExpiresTime:'card.updateCusCardExpiresTime',//修改卡有效期
    API_changeCusType: "customer.changeCusType",//会员卡升级
    API_cardAdd: "card.cardAdd",//会员开卡
    API_saveCard: "card.saveCustomerCard",//会员开卡
    API_getActivityViewByCardTypeId: "customer.getActivityViewByCardTId",//根据会员类型Id获取该活动的详情
    API_deleteEbItemSet: "mod-eb-visit.deleteEbItemSet",//删除设置提醒项目
    API_saveEbItemSet: "mod-eb-visit.saveEbItemSet",//保存提醒项目
    API_activateStore: "activation.serial_no_activation",//授权码激活
    API_getVersionList: "modProd.getVersionList",//获取模板列表
    API_getAnnualFeeList: "modProd.getAnnualFeeList",//获取年费列表
    API_purchaseModuleOrder: "modProd.purchaseModuleOrder",//生成订单
    API_getOrderState: "modProd.getOrderState",//监听订单状态
    API_getAuthorization: "modProd.getAuthorization",//监听授权状态
    API_getPreProcessingOrder: "modProd.getPreProcessingOrder",//查询预处理订单
    API_ResetPwdByCode: 'user.resetPwdByCode',//重置密码加密

    API_getSpread: "modProd.getSpread",//获取推广员姓名
    API_bindSales: "newModProd.bindSales",//绑定推广员（新）
    API_getSettingsList: "newModProd.getSettingsList",//功能列表
    API_newActivateStore: "activation.activateStore",//授权码激活（新）
    API_getSpreadByShopCode: "newModProd.getSpreadByShopCode",//查询是否绑定推广员
    API_activateAgentCode: "activation.activateAgentCode",//
    API_newGetAuthorization: "newModProd.getAuthorization",//查询所拥有权限
    API_getPretreatmentOrder: "newModProd.getPretreatmentOrder",//预处理订单
    API_newPurchaseModuleOrder: "newModProd.purchaseModuleOrder",//生成订单
    API_newGetOrderState: "newModProd.getOrderState",//监听订单状态
    API_upload: 'mzl/upload',

    API_getRefundTradeData: 'after-sales.getRefundTradeData',//作废订单备注

    API_findMemberCardList: "card.findMemberCardList",//获取会员卡列表
    API_findCardList: "card.findCardList",//获取套餐卡列表
    API_getCustomerMarketing: "customer.getCustomerMarketing",//查询会员营销报表
    API_queryIndexTotal: "new-report-business-data.getIndexTotal",//查询连锁总览
    API_saveCusMktGroupRates: "customer.saveCusMktGroupRates",//保存占比
    API_getPagingMktCusInfo: "customer.getPagingMktCusInfo",//查询
    API_getPagingNearNotConsumeCusInfo: "customer.getPagingNearNotConsumeCusInfo",//获取未到店会员信息
    API_getMarketingListByShopCode: "customer.getMarketingListByShopCode",//获取该活动的详情
    API_getActivityDetail: "customer.getActivityDetail",//获取该活动的详情
    API_cardRecharge: "card.cardRecharge",//会员卡充值
    API_oldCardUpdate: "customer.oldCardUpdate",//老会员修改
    API_oldCardAdd: "customer.oldCardAdd",//老会员录入
    API_getCusCountItems: "mod-eb-user.getCusCountItems",//查询散客营销
    API_getMarketing: "mod-eb-visit.getMarketing",//查询散客营销详情
    API_getEbItemSet: "mod-eb-visit.getEbItemSet",
    API_pagingCouponOfStore: "coupon.pagingCouponOfStore",//获取优惠券信息
    API_pagingUserCoupon: "coupon.pagingUserCoupon",//获取用户的优惠券
    API_stopCoupon: "coupon.stopCoupon",//停用优惠券
    API_pagingUsedCouponOfStore: "coupon.pagingUsedCouponOfStore",//获取优惠券使用记录
    API_saveDiscountCouponOfStore: "coupon.saveDiscountCouponOfStore",//保存优惠券
    API_destroyCard: 'card.destroyCard',//删除会员卡
    API_getActivityList: "customer.getActivityList",//查询活动列表
    API_stopMarketingActivity: "customer.stopMarketingActivity",//强制停止营销活动
    API_saveMarketingActivity: "customer.saveMarketingActivity",//保存营销活动
    API_importCard_Beta: "card.importCustomerCard",//会员卡录入修改
    API_getPaymentOrderState: "payment.getPaymentOrderState",//购买短信支付结果
    API_getScanPaymentUrl: "payment.getScanPaymentUrl",//获取二维码地址
    API_getPaymentOrder: 'payment.getPaymentOrder',//查询销卡次数
    API_queryPlanList: "mod-eb-store.queryPlanList",//查询合作方案列表
    API_saveBonusPlan: "mod-eb-store.saveBonusPlan",//保存合作方案
    API_queryUserList: "mod-eb-store.queryUserList",//获取员工列表（分红）
    API_getPlan:'mod-eb-store.getPlan',//查询单个奖励方案
    API_bonusSummaryChart: "mod-eb-store.bonusSummaryChart",//获取分红分析报表
    API_empBonusSummaryChart: "mod-eb-store.empBonusSummaryChart",//获取分页员工分红分析数据
    API_getPagingEmpBonusList: "mod-eb-store.getPagingEmpBonusList",//分红数据详情
    API_getEbPlanByEmpId:'mod-eb-store.getEbPlanByEmpId',//查方案

    API_queryEbCusList: "mod-eb-user.queryEbCusList",//查询客户列表(会员分配)
    API_queryEbUserList: "mod-eb-user.queryEbUserList",//查询员工列表(会员分配)
    API_updateGrantUser: "mod-eb-user.updateGrantUser",//分配（人工分配）
    API_deleteBonusPlan: "mod-eb-store.deleteBonusPlan",//删除合作方案
    API_getVisitStatistics: "mod-eb-visit.getVisitStatistics",//回访记录图形统计
    API_visitStatisticsDetailed: "mod-eb-visit.visitStatisticsDetailed",//回访记录明细统计
    API_visitDetailedDay:'mod-eb-visit.visitDetailedDay',//明细
    API_pagingStoreLottery: "lottery.pagingStoreLottery",//抽奖活动列表
    API_getStoreLottery: "lottery.getStoreLottery",//获取抽奖活动
    API_deleteStoreLottery: "lottery.deleteStoreLottery",//删除抽奖活动
    API_saveStoreLottery: "lottery.saveStoreLottery",//保存抽奖活动
    API_pagingStoreLotteryGrants: "lottery.pagingStoreLotteryGrants",//抽奖记录
    API_storeCustomerAgeCount: 'store-chart.storeCustomerAgeStatistics',//按年龄段查询会员人数
    API_cusCardNumberStatistics: 'store-chart.cusCardNumberStatistics',//会员卡增长
    API_cusCardBalanceStatistics: 'store-chart.cusCardBalanceStatistics',//会员卡余额

    API_getWallet: "wallet.getWallet",//店铺钱包
    API_pagingWalletLog: "wallet.pagingWalletLog",//钱包日志
    API_setStoreProbation: "modProd.setStoreProbation",//使用授权


    API_purchaseSalesReportList: "mod-store-warehouse.purchaseSalesReportList",//进销报表列表
    API_purchaseSalesRecordsList: "mod-store-warehouse.purchaseSalesRecordsList",//进销记录
    API_recordSummary: "mod-store-warehouse.recordSummary",//进销记录头部汇总
    API_goodsPurchaseSalesReportList: "mod-store-warehouse.goodsPurchaseSalesReportList",//进销记录头部汇总
    API_getSpecTypeList: "mod-store-warehouse.getSpecTypeList",//规格查询
    API_getCommodityList: "mod-store-warehouse.getCommodityList",//查询进货管理列表
    API_deleteCommodity: "mod-store-warehouse.deleteCommodity",//删除商品-收银价格-提成设置
    API_stockWarningList: "mod-store-warehouse.stockWarningList",//库存预警
    API_getOverdueRemindingList: "mod-store-warehouse.getOverdueRemindingList",//过期提醒
    API_getLossList: "mod-store-warehouse.getLossList",//报损记录列表
    API_updateLoss: "mod-store-warehouse.updateLoss",//报损记录列表
    API_getStorageList: "mod-store-warehouse.getStorageList",//入库记录
    API_getStorageListByItemId: "mod-store-warehouse.getStorageListByItemId",//订单入库记录
    API_getSupplierList: "mod-store-warehouse.getSupplierList",//获取供应商列表
    API_saveStorage: "mod-store-warehouse.saveStorage",//新增入库
    API_saveStorageList: "mod-store-warehouse.saveStorageList",//新增入库数组
    API_addSpecType: "mod-store-warehouse.addSpecType",//新增规格
    API_saveSupplier: "mod-store-warehouse.saveSupplier",//新增规格
    API_saveCommodity: "mod-store-warehouse.saveCommodity",//保存商品
    API_getCommodity: "mod-store-warehouse.getCommodity",//查询商品明细
    API_materialCheckList: "mod-store-warehouse.materialCheckList",//库存盘点列表
    API_materialCheckCount: "mod-store-warehouse.materialCheckCount",//库存盘点
    API_saveMaterialCheckInfo: "mod-store-warehouse.saveMaterialCheckInfo",//保存盘亏盘盈信息
    API_materialCheckInfoList: "mod-store-warehouse.materialCheckInfoList",//库存盘点列表详情
    API_queryRequisitionList: "mod-store-warehouse.queryRequisitionList",//院品申请列表
    API_userCEvaluation: "mod-store-warehouse.userCEvaluation",//用户综合评估
    API_materialEvaluationList: "mod-store-warehouse.materialEvaluationList",//院品评估
    API_queryMaterialUsageList: "mod-store-warehouse.queryMaterialUsageList",//院品用量查询
    API_materialReceive: "mod-store-warehouse.materialReceive",//院品申请审批
    API_updateMaterialUsage: "mod-store-warehouse.updateMaterialUsage",//院品用量编辑
    API_deletedMaterialUsage: "mod-store-warehouse.deletedMaterialUsage",//院品用量删除
    API_deleteSupplier: "mod-store-warehouse.deleteSupplier",//删除供应商
    API_getSupplierDetailedList: "mod-store-warehouse.getSupplierDetailedList",//查看供应商记录明细
    API_updateSupplierArrears: "mod-store-warehouse.updateSupplierArrears",//已欠款销款
    API_deleteSpecType: "mod-store-warehouse.deleteSpecType",//删除规格
    API_marketingCardTypeIsUse: "customer.marketingCardTypeIsUse",//校验该会员卡Id是否已经使用
    API_mixedPayment: "payment.mixedPayment",//支付单混合支付
    API_createArrearsOrder: "payment.createArrearsOrder",//创建欠款单
    API_payArrearsOrder: "payment.payArrearsOrder",//支付欠款单
    API_getUserStoreCoupon: "coupon.getUserStoreCoupon",//获取用户的店铺优惠券
    API_queryCusInfo: "customer.queryCusInfo",//新接口 通过手机号查询顾客信息
    API_getCustomerAndCard: "customer.getCustomerAndCard",//新接口 通过手机号查询顾客信息
    API_getCusCardList: "card.getCusCardList",//新接口 
    API_checkTradePwd: "customer.checkTradePwd",//校验扣款码
    /** place列表  **/
    API_queryConsumePlaceList: "mod-consume-place.queryConsumePlaceList",
    /** 查询指定店铺可用的区域信息 **/
    API_queryAvailableConsumeArea: "mod-consume-place.queryAvailableConsumeArea",
    API_saveAreaSetting: "mod-consume-place.saveAreaSetting",//房间收银 以及 自动打扫开关
    /** 新增place or 修改place **/
    API_saveConsumePlace: "mod-consume-place.saveConsumePlace",
    /** place删除 **/
    API_deleteConsumePlace: "mod-consume-place.deleteConsumePlace",
    /** 新增区域 or 区域修改 **/
    API_saveConsumeArea: "mod-consume-place.saveConsumeArea",
    /** 区域删除 **/
    API_deleteConsumeArea: "mod-consume-place.deleteConsumeArea",
    /** 区域排序 **/
    API_sortConsumeArea: "mod-consume-place.sortConsumeArea",
    /**占用桌台**/
    API_takePlace: "mod-consume-place.takePlace",
    /**新占用桌台**/
    API_rebindingPlace: "mod-consume-place.rebindingPlace",
    /**清除占用卓台**/
    API_setPlaceState: "mod-consume-place.setPlaceState",
    /**桌台收银，对应的订单**/
    API_placeSales: "mod-consume-place.placeSales",
    API_placeClean: "mod-consume-place.placeClean",
    /**释放桌台**/
    API_releasePlace: "mod-consume-place.releasePlace",
    API_getPlace: "mod-consume-place.getPlace",
    API_queryCusInfoSum: "customer.queryCusInfoSum",//会员分析，卡总合计信息
    API_querySCusTotal: "customer.querySCusTotal",//会员分析，按日月年查询会员和卡趋势
    API_getSCusDayInfo: "customer.getSCusDayInfo",//营业数据会员信息
    API_queryEmpAwardMonthTotal: "employee.queryEmpAwardMonthTotal",//业绩趋势,按月统计
    API_querySEmpDayTotal: "employee.querySEmpDayTotal",//员工业绩排名
    API_queryEmpAwardInfo: "employee.queryEmpAwardInfo",//员工业绩明细
    API_RefundStoreOrder: 'after-sales.refundStoreOrder',//退单


    API_queryUniPlan: "mod-uni.queryUniPlan",//获取合作方案列表
    API_getUniPlan: 'mod-uni.getUniPlan',//根据ID查方案
    API_enableUniPlan: 'mod-uni.enableUniPlan',//更改状态
    API_saveUniPlan: "mod-uni.saveUniPlan",//保存合作方案
    API_deleteUniPlan: "mod-uni.deleteUniPlan",//删除合作方案
    API_queryUniUser: "mod-uni.queryUniUser",//查询分红股东列表
    API_saveUniUser: 'mod-uni.saveUniUser',//同意拒绝
    API_queryUniUserList: "mod-uni.queryUniUserList",//查询分红股东列表
    API_updateUniPlan: 'mod-uni.updateUniPlan',//更新方案状态
    API_queryUniActivity: "mod-uni.queryUniActivity",//查询联盟活动
    API_queryUniActivityTotal: "mod-uni.queryUniActivityTotal",//查询活动汇总信息
    API_queryUniActivityDetail: "mod-uni.queryUniActivityDetail",//查询使用记录
    API_saveUniActivity: "mod-uni.saveUniActivity",//保存联盟活动
    API_getUniRecordSummary: "mod-uni.getUniRecordSummary",//查询分红订单汇总数据
    API_getUniRecordLog: "mod-uni.getUniRecordLog",//查询分红纪录
    API_queryUniUserCusCount: "mod-uni.queryUniUserCusCount",//查询股东列表
    API_queryUniCus: "mod-uni.queryUniCus",//查询顾客
    API_delUniActivity: "mod-uni.delUniActivity",//中止活动


    API_findCusPayPwd: 'cuspay.findCusPayPwd',//根据顾客id查询密码
    API_submitRetailCart: 'retail.submitRetailCart',//提交购物车
    API_getRetailCart: 'retail.getRetailCart',//获取购物车
    API_resetRetailCart: 'retail.resetRetailCart',//重置购物车，如购物车修改后，使用此方法重新绑定购物车项
    API_queryRetailCartList: 'retail.queryRetailCartList',//查询零售购物车列表,返回 List<SRetailCart>
    API_destroyRetailCart: 'retail.destroyRetailCart',//销毁购物车
    API_queryCusRemarks: 'customer.queryCusRemarks',//查询备注
    API_saveCusRemark: 'customer.saveCusRemark',//保存备注
    API_queryConsumptionAnalysis: 'customer.queryConsumptionAnalysis',//报表会员消费分析
    API_queryConsumptionAnalysisInfo: 'customer.queryConsumptionAnalysisInfo',//报表会员消费明细


    API_queryBankCards: 'mzl-user.queryBankCards',//查询银行卡
    API_SaveBankCard: 'mzl-user.saveBankCard',//保存银行卡
    API_getUserByUserCode: 'mzl-user.getUserByUserCode',//创建用户
    API_deleteBankCard: 'mzl-user.deleteBankCard',//删除银行卡
    API_saveWalletPwd: 'wallet.saveWalletPwd',//重置钱包密码
    API_checkWalletPwd: 'wallet.checkWalletPwd',//校验密码
    API_getWalWalletBillTotal: 'wallet.getWalWalletBillTotal',//获取钱包钱款统计
    API_getSumWalTransferRecord: 'wallet.getSumWalTransferRecord',//转账审批统计
    API_queryWalWalletBill: 'wallet.queryWalWalletBill',//钱包流水
    API_queryWalTransferRecord: 'wallet.queryWalTransferRecord',//提现审批
    API_saveWalWithdrawRecord: 'wallet.saveWalWithdrawRecord',//提现
    API_applyTransferCallback: 'mod-uni.applyTransferCallback',//通过拒绝
    API_saveWalRechargeRecord: 'wallet.saveWalRechargeRecord',//充值
    API_destroyCharge: 'wallet.destroyWalOrder',//销毁订单
    API_getWalOrderPaymentUrl: 'wallet.getWalOrderPaymentUrl',//生成支付地址
    API_getWalOrderState: 'wallet.getWalOrderState',//监听支付状态
    API_getTradeFee: 'wallet.getTradeFee',//获取手续费
    queryNoticeRecords: 'shop.queryNoticeRecords',//查询通知中心
    saveNoticeRecord: 'shop.saveNoticeRecord',//保存已读通知
    API_getNotice: '/notice/getNotice',//通知中心
    // API_destroyCharge:'wallet.destroyCharge',//销毁订单
    API_queryCustomers: 'customer.queryCustomers',//查顾客列表
    API_getCusCards: 'customer.getCusCards',//查顾客下面的卡
    API_queryCardItems: 'card.queryCardItems',//查套餐卡项目
    API_queryCardBill: 'card.queryCardBills',//查卡流水
    API_queryCusMemberBillList:'customer.queryCusMemberBillList',//会员消费记录
    API_updateCusBaseInfo: 'customer.updateCusBaseInfo',//编辑会员信息
    API_turnover2: 'new-report-business-data.getCustomerMarketing',//报表 营业额
    API_getArrearsAndRepayCount: 'new-report-business-data.getArrearsAndRepayCount',//欠款历史汇总
    API_sourceCashier2: 'new-report-business-data.getConsumptionSource',//报表 收银来源
    API_getConsumptionFree: 'new-report-business-data.getConsumptionFree',//报表 免单明细
    API_getPerformanceOrderByShopId: 'new-report-business-data.getPerformanceOrderByShopId',//报表 员工业绩
    API_queryArrearsOrder: 'payment.queryArrearsOrder',//欠款列表
    API_getPerformanceClassification: 'new-report-business-data.getPerformanceOrderByShopId', //业绩分类
    API_getConsumptionDifferent: 'new-report-business-data.getConsumptionDifferent', //连锁消费
    API_getTechnicianList: 'employee.getTechnicianList',//个人
    API_getshopOrladder: 'employee.getTechnicianList',//全店和阶梯
    API_getUserForPerformanceByShopId:'new-report-business-data.getUserForPerformanceByShopId',//个人项目查useid

    API_paysendRequest:'pay.sendRequest',//支付转发（解决跨域）

    /* 会员报表 */
    API_getCusCardDataStatistics:'card.getCusCardDataStatistics',//会员余额
    API_getcustomerqueryCusCustomers:'customer.queryCusCustomers',//会员新增
    API_getCusOrderAnalysis:'order.getCusOrderAnalysis',//消费占比
    API_queryCusCardInformation:'customer.queryCusCardInformation',//日期下面储值新增、套餐卡新增、赠送新增、会员耗卡、套餐卡耗卡
    API_queryCusCardSummarizationOfData:'customer.queryCusCardSummarizationOfData',//除会员新增的查看全部的表格数据


    API_queryArrearsOrderDetail: 'payment.queryArrearsOrderDetail',//欠款详情
    API_totallMoneyPersonal: 'employee.getEmpAwardTotal',//提成最上面的总业绩总提成
    API_middletotallMoney: 'employee.getEmpAwardTotal',//提成中间的服务销售业绩总提成
    API_queryEmployeeAwards:'employee.queryEmployeeAwards',//员工业绩页面的tab数据

    API_getEmpsAwardTotal:'employee.getEmpsAwardTotal',//员工业绩
    API_getEmpsPerformanceTotal:'employee.getEmpsPerformanceTotal',//业绩分类

    API_queryPayChannels: 'payment.queryPayChannels',//查询订单支付方式
    API_queryRepayDatas: 'payment.queryRepayDatas',//查询还款记录
    



    API_queryArrearsCustomers: 'payment.queryArrearsCustomers',// 查询欠款人
    API_scanPayment: 'payment.scanPayment',// 扫码枪支付
    

    API_getCustomOrderNo:'order.getCustomOrderNo',//获取水单号
    API_queryCustomOrderList:'order.queryCustomOrderList',//查询水单列表
    API_getCustomOrderOfSum:'order.getCustomOrderOfSum',//获取水单号统计

    API_getSalesRanking:'new-report-business-data.getSalesRanking',//商品服务销售排行
    API_queryCusUnitPrice:'order.queryCusUnitPrice',//客单价走势
    API_getCustomerMarketingAnalyze:'new-report-business-data.getCustomerMarketingAnalyze',//营业额趋势

    API_saveUniShopDeposit:'mod-uni.saveUniShopDeposit',//生成预存款
    API_getUniShopDeposit:'mod-uni.getUniShopDeposit',//获取店铺预存款项
    API_saveUniDepositRecordt:'mod-uni.saveUniDepositRecordt',//生成店铺订单
    API_getUniDepositRecordt:'mod-uni.getUniDepositRecordt',//獲取訂單號
    API_getModOrderPaymentUrl:'mod-uni.getModOrderPaymentUrl',//生成支付地址
    API_destroyDepositRecordt:'mod-uni.destroyDepositRecordt',//銷毀訂單
    API_queryUniDepositBill:'mod-uni.queryUniDepositBill',//服務費列表
    API_getUniDepositBillDetails:'mod-uni.getUniDepositBillDetails',//服务费详情


    
    API_queryCusSources:'shop.queryCusSources',//查询客户来源
    API_saveCusSource:'shop.saveCusSource',//保存客户来源
    API_getScoreRule:'shop.getScoreRule',//查询积分规则

    API_saveScoreRule:'shop.saveScoreRule',//保存积分
    API_queryCusScoreLog:'customer.queryCusScoreLog',//查询积分记录
    API_savePreScore:'payment.savePreScore',//赠送积分


    //保存会员模板
    API_saveShopRemarkTemplate:'shop.saveShopRemarkTemplate',//会员模板
    API_getShopRemarkTemplate:'shop.getShopRemarkTemplate',//得到会员模板



    //连锁店铺
    API_saveMchManagerRole:'merchant.saveMchManagerRole',//保存员工
    API_queryMchManagerAuthority:'merchant.queryMchManagerAuthority',//查询
    API_queryMchManagerRole:'merchant.queryMchManagerRole',//查询员工
    API_deleteMchManagerRole:'merchant.deleteMchManagerRole',//删除
    API_editMchManagerRoleParent:'merchant.editMchManagerRoleParent',//左右移动移动位置
    API_editMchManagerRoleOrderNum:'merchant.editMchManagerRoleOrderNum',//上下移动
    API_getMchManagerRole:'merchant.getMchManagerRole',//获取权限
    API_saveMchManagerEmp:'merchant.saveMchManagerEmp',//添加员工
    API_queryMchManagerEmps:'merchant.queryMchManagerEmps',//查询员工
    API_getMchMerchant:'merchant.getMchMerchant',//获取企业信息
    API_editMerchant:'merchant.editMerchant',//编辑企业信息
    API_saveMchManagerCard:'merchant.saveMchManagerCard',//保存卡类型
    API_queryMchManagerCardList:'merchant.queryMchManagerCardList',//获取会员卡
    API_enabledMchManagerCard:'merchant.enabledMchManagerCard',//会员卡停用
    API_deleteMchManagerCard:'merchant.deleteMchManagerCard',//删除会员卡
    API_getMchManagerCard:'merchant.getMchManagerCard',//单个会员卡的详情
    API_saveMchManagerItem:'merchant.saveMchManagerItem',//添加服务
    API_queryMchManagerItem:'merchant.queryMchManagerItem',//查询服务
    API_enabledMchManagerItem:'merchant.enabledMchManagerItem',//停用或者启用服务
    API_deleteMchManagerItem:'merchant.deleteMchManagerItem',//删除服务
    API_getMchManagerItem:'merchant.getMchManagerItem',//单个服务的详情
    API_addStore:'shop.addStore',//新增店铺
    API_queryShoStore:'merchant.queryShoStore',//连锁后台获取所有店铺的信息
    // API_addStoreAdmin:'shop.addStoreAdmin',//连锁进店


    API_upgradeMerchant:'merchant.upgradeMerchant',//单店升级连锁版
    API_importStoreBase:'merchant.importStoreBase',//单店上传会员、服务、套餐卡
    API_getImportStoreBaseState:'merchant.getImportStoreBaseState',//获取上传会员、服务、套餐卡，进度
    API_getStoreBaseImports:'merchant.getStoreBaseImports',//获取上传会员、服务、套餐卡，的结果
    API_addStoreAdmin:'shop.getStoreAdmin',//连锁进店
    API_querysmsType:'newsms.querySmsType',//查询短信模板类型
    API_querysmsByPage:'newsms.querySmsByPage',//查询店铺模板
    API_queryTemplatesByType:'newsms.queryTemplatesByType',//根据短信模板获取模板内容
    API_sendSmsTX:'newsms.sendSmsTX',//发送短信

    API_queryMchManagerChainCards:'merchant.queryMchManagerChainCards',//连锁跨店消费
    API_queryMchChainCards:'merchant.queryMchChainCards',//本店跨店消费
    API_saveMchManagerChainCards:'merchant.saveMchManagerChainCards',//保持连锁跨店消费
    API_saveMchChainCards:'merchant.saveMchChainCards',//保持本店跨店消费
    API_saveMchChainCard:'merchant.saveMchChainCard',//保持会员卡和此卡后调用
    API_queryMchChainCardRules:'merchant.queryMchChainCardRules',//查询连锁可以使用的会员卡和套餐卡
    API_deleteCusRemark:'customer.deleteCusRemark',//删除会员标签
    API_queryShopTags:'shop.queryShopTags',//查询标签
    API_deleteShopTag:'shop.deleteShopTag',//删除标签
    API_saveShopTags:'shop.saveShopTags',//保存标签
    API_saveCusPwdState:'shop.saveCusPwdState',//小程序电子会员卡密码跳过
    API_getCusPwdState:'shop.getCusPwdState',//小程序电子会员卡密码跳过

    API_queryAnalyzeCustomer:'customer.queryAnalyzeCustomers',//顾客分析

    API_querySmsConfig:'newsms.querySmsConfig',//短信模块ID查询
    API_querySmsTypeTemplateByIds:'newsms.querySmsTypeTemplateByIds',//短信模块查询
    API_setSmsConfigDetail:'newsms.setSmsConfigDetail',//店铺短信模板开关 //1开启 2关闭
    API_querySmsConfigDetail:'newsms.querySmsConfigDetaiByNone',//店铺短信模板开关 //1开启 2关闭
    /* 轮牌 */
    API_getShiftRule:'shift.getShiftRule',//获取轮排规则
    API_saveShiftRule:'shift.saveShiftRule',//保存轮牌规则
    API_saveShiftRuleGroups:'shift.saveShiftRuleGroups',//保存组
    API_deleteShiftRuleGroup:'shift.deleteShiftRuleGroup',//删除组
    API_saveShiftRuleEmps:'shift.saveShiftRuleEmps',//保存员工
    API_deleteShiftRuleEmp:'shift.deleteShiftRuleEmp',//删除员工


    API_updateShiftBoardEmpSort:'shift.updateShiftBoardEmpSort',//轮班模板排序
    API_updateShiftBoardEmpState:'shift.updateShiftBoardEmpState',//轮钟修改员工状态 
    API_queryShiftBoard:'shift.queryShiftBoard',//查询看板员工排序以及项目时长 //1开启 2关闭


    API_saveAttendanceGroup:'attendance.saveAttendanceGroup',//保存考勤组
    API_queryAttendanceGroups:'attendance.queryAttendanceGroups',//查询考勤组列表
    API_getAttendanceGroup:'attendance.getAttendanceGroup',//查询考勤组详情
    API_deleteAttendanceGroup:'attendance.deleteAttendanceGroup',//删除考勤组
    API_queryAttendanceGroupEmps:'attendance.queryAttendanceGroupEmps',//查询已添加考勤组的员工
    API_queryAttendanceTotal:'attendance.queryAttendanceTotal',//考勤记录
    API_queryEmpAttendanceLogs:'attendance.queryEmpAttendanceLogs',//查询个人考勤记录
    API_queryFlowRecords:'attendance.queryFlowRecords',//查询个人审批记录

    API_saveSchedulingSettings:'attendance.saveSchedulingSettings',//保存班次设置 新
    API_querySchedulingSettings:'attendance.querySchedulingSettings',//查询班次设置 新
    API_deleteSchedulingSetting:'attendance.deleteSchedulingSetting',//删除班次设置 新
    API_saveSchedulingEmps:'attendance.saveSchedulingEmps',//保存员工排班 新
    API_querySchedulingEmps:'attendance.querySchedulingEmps',//查询考勤组下员工排班 新
    API_querySchedulingAttGroups:'attendance.querySchedulingAttGroups',//查询考勤组排班
    API_copyScheduling:'attendance.copyScheduling',//拷贝排班
    API_queryAppointmentCondition:'appointment.queryAppointmentCondition',//修改排班时查询受影响的预约单

    API_saveAppointmentSetting:'appointment.saveAppointmentSetting',//门店设置
    API_queryAppointmentSetting:'appointment.queryAppointmentSetting',//查门店设置
    API_saveAppointmentItem:'appointment.saveAppointmentItem',//保存预约项目
    API_deleteAppointmentItem:'appointment.deleteAppointmentItem',//删除预约项目
    API_queryAppointmentItem:'appointment.queryAppointmentItem',//查询预约项目
    API_queryAppointmentEmp:'appointment.queryAppointmentEmp',//查询预约员工管理
    API_saveAppointmentEmp:'appointment.saveAppointmentEmp',//保存预约员工管理
    API_getStoreRecommendEmps:'appointment.getStoreRecommendEmps',//获取可预约的员工
    API_saveAppointmentCondition:'appointment.saveAppointmentCondition',//预约
    API_queryAppointmentRecord:'appointment.queryAppointmentRecord',//预约列表
    API_saveAppointmentOperationLog:'appointment.saveAppointmentOperationLog',//保存预约记录
    API_queryAppointmentOperationLogs:'appointment.queryAppointmentOperationLogs',//获取预约记录
    API_submitPreOrder:'order.submitPreOrder',//提交轮盘、手牌、房间订单
    API_queryPreOrders:'order.queryPreOrders',//获取轮盘、手牌、房间订单
    API_destoryPreOrder:'order.destoryPreOrder',//获取轮盘、手牌、房间订单
    API_updateShiftBoardEmpStateComplete:'shift.updateShiftBoardEmpStateComplete',//强制下单
    API_queryHandCardBoard:'shop.queryHandCardBoard',//查询店铺手牌看板
    API_queryStoreSchedulingEmps:'attendance.queryStoreSchedulingEmps',//查询轮牌看板
    API_resetShiftBoard:'shift.resetShiftBoard',//重置看板员工排序
    // API_getUserProd:'shop.getStoreAuth',//查询版本
    API_getGetTextByShopIdAndUserId: 'new-report-business-data.getTextByShopIdAndUserId',//明细

    API_saveProdOrder: 'prod.saveProdOrder',//购买生成支付订单
    API_getCommissionUrl:'prod.getCommissionUrl',//银行卡获取url
    API_getPayUrl: 'prod.getPayUrl',//生成支付地址
    API_getProdOrder: 'prod.getProdOrder',//获取订单状态
    API_saveProdSmsOrder:'prod.saveProdSmsOrder',//短信订单
    API_queryProdOrder:'prod.queryProdOrder',//查询我的订单
    API_destroyProdOrder: 'prod.destroyProdOrder',// 销毁订单

    API_getSalesmanByPhone: 'prod.getSalesmanByPhone',//检测推广员
    API_queryStoreAuth:'shop.queryStoreAuth',//查询连锁店铺
    API_updateCusCardSign:'card.updateCusCardSign',//修改卡号



    API_queryUserProd:'/user/queryUserProd',//查询版本过期时间
    API_getProdApi : 'shop.getProdApi', //代理
    API_queryMenuItems : '/prod/queryMenuItems', //帮助中心
    API_queryMenuItem : '/prod/queryMenuItem', //帮助中心item
    API_queryMenuItemModel : '/help_menu/queryMenuItemModel', //帮助中心item
    API_queryNotices : '/prod/queryNotices', //通知中心
    API_noticeId : '/prod/getNotice', //通知中心
    API_getUserProd:'/user/getUserProd',//查询版本
    API_activateBySerialNo:'user/activateBySerialNo',//版本激活
    API_querySpPayRecordList:'order.querySpPayRecordList',//扫码付流水
    API_getTipCount:'shop.getTipCount',//库存预警气泡  1生日（展示不查）  2库存提醒  3过期提醒
    API_authorizeInfo:'/api/authorize_info/',
    API_sendPost:'shop.sendPost',//商城中转接口
    API_getShopUrlEntrance:'shop.getShopUrlEntrance',//获取保存商城入口
    API_saveShopUrlEntrance:'shop.saveShopUrlEntrance',//保存商城入口
    API_getCustomerMarketingDetailed:'new-report-business-data.getCustomerMarketingDetailed',
    //新库存
    API_inventory_initializationPmsGoodsParameter:'inventory.initializationPmsGoodsParameter',//初始化
    API_inventory_querySupplier:'inventory.querySupplier',//获取供应商
    API_inventory_saveSupplier:'inventory.saveSupplier',// 保存供应商
    API_inventory_deleteSupplier:'inventory.deleteSupplier',// 删除供应商
    API_inventory_queryPmsGoodsParameter:'inventory.queryPmsGoodsParameter',// 获取商品参数
    API_inventory_savePmsGoodsParameter:'inventory.savePmsGoodsParameter',// 保存商品参数
    API_inventory_deletePmsGoodsParameter:'inventory.deletePmsGoodsParameter',// 删除商品参数
    API_inventory_queryPmsGoods:'inventory.queryPmsGoods',// 获取商品
    API_inventory_savePmsGoods:'inventory.savePmsGoods',// 保存商品
    API_inventory_deletePmsGoods:'inventory.deletePmsGoods',// 删除商品参数
    API_inventory_getPmsGoods:'inventory.getPmsGoods',// 获取商品详情
    API_inventory_queryPmsGoodsWarning:'inventory.queryPmsGoodsWarning',// 获取商品预警
    API_inventory_savePmsGoodsApplication:'inventory.savePmsGoodsApplication',// 保存商品出入库申请
    API_inventory_updatePmsGoodsApplicationState:'inventory.updatePmsGoodsApplicationState',// 修改商品出入库申请状态
    API_inventory_getPmsGoodsApplication:'inventory.getPmsGoodsApplication',// 获取商品出入库申请详情
    API_inventory_queryPmsGoodsApplication:'inventory.queryPmsGoodsApplication',// 获取商品出入库申请
    API_inventory_queryPmsGoodsApplicationLog:'inventory.queryPmsGoodsApplicationLog',// 获取商品出出入库统计
    API_inventory_queryPmsGoodsApplicationLogDetails:'inventory.queryPmsGoodsApplicationLogDetails',// 获取商品出出入库统计详情
    API_inventory_afterPmsGoods:'inventory.afterPmsGoods',// 退还商品
    API_inventory_savepmsGoodsTransshipment:'inventory.savepmsGoodsTransshipment',//保存商品转运申请
    API_inventory_updatePmsGoodsTransshipmentState:'inventory.updatePmsGoodsTransshipmentState',//修改商品转运申请状态
    API_inventory_getPmsGoodsTransshipment:'inventory.getPmsGoodsTransshipment',//获取商品转运申请
    API_inventory_queryPmsGoodsTransshipment:'inventory.queryPmsGoodsTransshipment',// 获取商品转运申请
    API_inventory_queryPmsGoodsTransshipmentLog:'inventory.queryPmsGoodsTransshipmentLog',//获取商品转运申请
    API_inventory_getPmsGoodsTransshipmentItemByTemplateId:'inventory.getPmsGoodsTransshipmentItemByTemplateId',//通过项目id获取所有可转运的同商户商品
    API_inventory_getPmsGoodsTransshipmentShopByMchCode:'inventory.getPmsGoodsTransshipmentShopByMchCode',//通过商户code获取所有可转运的店铺信息
    API_inventory_getPmsGoodsApplicationByApplicationNo:'inventory.getPmsGoodsApplicationByApplicationNo',//申请详情的出入库统计
    API_inventory_updatePmsGoodsEnable:'inventory.updatePmsGoodsEnable',//商品启用和停用功能
    API_inventory_getPmsGoodsTransshipmentCount:'inventory.getPmsGoodsTransshipmentCount',//申请调拨气泡接口
    API_inventory_queryProdGoodsOperatingLog:'inventory.queryProdGoodsOperatingLog',//获取商品操作日志


    API_markPreOrder:'order.markPreOrder',//标记完成准备单
    API_after_sales_queryRefundCardData:'after-sales.queryRefundCardData',//退卡 查询卡详情
    API_after_sales_refundCard:'after-sales.refundCard',//新退卡
    API_queryPreOrdersRecord:'order.queryPreOrdersRecord',//查询准备单记录
    API_getPayExtraMethod:'payment.getPayExtraMethod',// 获取额外支付方式
    API_savePayExtraMethod:'payment.savePayExtraMethod',// 保存额外支付方式
    API_queryEmployeeAwardsOrderCount:'employee.queryEmployeeAwardsOrderCount',// 查询员工提成单量汇总
    API_queryShPlan:'shareholder.queryShPlan',// 获取联盟股东方案
    API_saveShPlan:'shareholder.saveShPlan',// 修改股东方案
    API_deleteShPlan:'shareholder.deleteShPlan',// 删除股东方案
    API_getShPlan:'shareholder.getShPlan',// 获取股东方案
    API_saveShActivity:'shareholder.saveShActivity',// 新增拓客活动
    API_deleteShActivity:'shareholder.deleteShActivity',// 删除拓客活动
    API_getShActivity:'shareholder.getShActivity',// 获取拓客活动
    API_enabledShPlan:'shareholder.enabledShPlan',// 停启方案
    API_enabledShActivity:'shareholder.enabledShActivity',// 停启活动
    API_queryShActivity:'shareholder.queryShActivity',// 获取拓客活动
    API_queryShCustomer:'shareholder.queryShCustomer',// 获取所有顾客
    API_queryShUser:'shareholder.queryShUser',// 获取所有股东
    API_getShCustomer:'shareholder.getShCustomer',// 获取顾客
    API_getShUser:'shareholder.getShUser',// 获取顾客
    API_gueryShDividendsDetail:'shareholder.queryShDividendsDetail',// 获取分红明细
    API_queryShUserDividendsBill:'shareholder.queryShUserDividendsBill',// 获取分红账单
    API_getShUserDividendsAmount:'shareholder.getShUserDividendsAmount',// 获取股东分红钱款额
    API_getSubordinateUserDividendsDetails:'shareholder.getSubordinateUserDividendsDetails',// 获取次级股东分红详情
    API_markUserBill:'shareholder.markUserBill',// 标记股东账单



};
export default APINames;