import React from "react";
import {Link} from "react-router-dom";
import "../../css/login.css"
import { AutoComplete, Button, Checkbox, Icon, Input, Tabs } from 'antd';
import { $mzlApi, $userApi, getUserSign } from '../comm/mzl-core'
import { httpLogin } from '../api/yqHttp';
import { copyProject, messageError, messageInfo, log, md5ToLower } from "../comm/utils";
import StorageTools from "../comm/storageTools";
import {settingAuthority} from "../comm/chainAuthoritySetting";
import APINames from "../comm/api-names"
import APIConfig from "../api/api-config";
import { noCheck, mzlVersion } from "../comm/constans"
import TrafficLight from "../components/light"
import { IModal } from '../components/iModal'
import { messageTopError, showMessageTopError } from "../comm/api";
import { connect } from 'react-redux'
import buyUtil, { BuyUtils } from "../buy/buyUtil";
import LoadingDialog from "../components/loadingDialog";
import ItemRoleView from "../settings/itemRoleView";
import {RoutePath} from "../../routes/router";

import {update} from "@js/reduxs/reducers/iconRedux";

import baseService from "@src/base/baseService/baseService";
var service = new baseService();
// import { url } from "inspector"
// var md5 = require('md5');
// var md5 = require('blueimp-md5');
let { notify } = require("../comm/mzl-core");
const { Option, OptGroup } = AutoComplete;
// const Option = Select.Option;
const Search = Input.Search;
const InputGroup = Input.Group;
const TabPane = Tabs.TabPane
//console.log("---api=", APINames.API_storeCompatibleLogin_V1)
var isDelete = false;
let   chainSetting = [],
      shopSetting = [],
      reportSetting = [];
let widthBoll = true;


@connect(
    state => state.iconRedux,
    { update }
)
class LoginComponent extends React.Component {

    constructor() {
        super();
        this.state = {
            userName: '',
            pwd: '',
            account: "",
            data: { account: "", pwd: "" },
            color: "#000",
            _userLoginList: [],
            isCheck: false,
            isSavePwd: false,
            userLoginList: [],
            loading: false,
            userCode: '',
            loginData: {},
            identyList: [],
            visible: false,
            isPwd: false,
            loginTitle: '继续',
            type: 1,
            mchInfoArr: []
        }
    }

    componentDidMount() {
        // console.log(1111, this.screen);
        var configLoacl = StorageTools.getConfigLoacl()

        // widthBoll =this.document.body.clientWidth
        // let loginRoot = document.getElementById("login-root")
        // if(loginRoot != null && loginRoot.offsetWidth >400){
        //     widthBoll =false;
        //     this.setState(this);
        // }
        if( configLoacl.version == "2.15.5"){
            widthBoll = false;
            this.setState(this);
        }


        StorageTools.saveBuyModule([]);
        var list = StorageTools.getUserLogin();
        if (list != null && list.length > 0) {

            // let arr = []
            // list.forEach(item=>{
            //     arr.push(item.userName)
            // })
            this.setState({
                userLoginList: list,
                _userLoginList: copyProject(list),
            })
        }
        notify.appSend("user-login-event", { event: "require" }); // 通知客户端进行登陆
    }

    /**
     * 根据手机号查userCode
     */
    requestAPI_getUserCode() {
        var map = new Map();
        map.set('user', this.state.data.account);
        $userApi.post(APINames.API_getUserCode, map, this.success.bind(this), this.fail.bind(this), noCheck);
    }


    /**
     * 登录
     */
    requestLogin(loginType) {
        var map = new Map();
        map.set('user', this.state.data.account);
        map.set('pwd', md5ToLower(this.state.data.pwd));
        map.set("cred ", "phone");
        $userApi.post(APINames.API_Login, map,res=>{
            StorageTools.saveStoreCode(res.Data.user_code);
            this.setState({ loading: false, userCode: res.Data.user_code });
            console.log('成功', this.state.loginData)
            BuyUtils.setCloseTime(null)
           this.requestQueryUserIdentity()
            if (res.ErrCode > 0) {
                messageError(res.ErrMsg);
                return false;
            }
            var userData = {};
            if (this.state.isSavePwd) {//记住密码
                userData = { userName: this.state.data.account, pwd: this.state.data.pwd }
            } else {
                userData = { userName: this.state.data.account, pwd: "" }
            }
            if (this.state.userLoginList == null || this.state.userLoginList.length < 1) {
                var arr = [];
                userData.id = 0;
                arr.push(userData);
                StorageTools.saveUserLogin(arr)
            } else {
                var isAdd = true;
                this.state.userLoginList.forEach(item => {
                    if (item.userName == userData.userName) {
                        if (this.state.isSavePwd) {//记住密码
                            item.pwd = this.state.data.pwd
                        } else {
                            item.pwd = ""
                        }
                        StorageTools.saveUserLogin(this.state.userLoginList);
                        isAdd = false;
                    }

                });
                if (isAdd) {
                    userData.id = this.state.userLoginList.length;
                    this.state.userLoginList.unshift(userData);
                    StorageTools.saveUserLogin(this.state.userLoginList)
                }
            }
        }, this.fail.bind(this), noCheck);
    }

    /**
     * 登录
     */
    requestYqLogin() {
        // showLoadding();
        // let that = this;
        // var map = new Map();
        // map.set('user', this.state.data.account);
        // map.set('pwd', md5ToLower(this.state.data.pwd));
        // map.set("cred", "phone");
        // var sign = getUserSign.getSign(APINames.API_Login, map);
        // httpLogin.login({ user: this.state.data.account, pwd: md5ToLower(this.state.data.pwd), version: "2.4.0", sign: sign })
        //     .then(res => {
        //         // console.log(111111,res)
        //         let obj = {}
        //         obj.Data = JSON.parse(res.data)
        //         // that.success(obj, APINames.API_Login);
        //         StorageTools.saveStoreToken(res.token);

        //     })
        //     .catch(res => {
        //         that.setState({
        //             loading: false
        //         })
        //         // console.log(1112222,res)
        //     });

    }








    /**
     * 查询用户身份
     */
    requestQueryUserIdentity() {
        var map = new Map();
        map.set('userCode', this.state.userCode);
        map.set('appFlag', this.state.type == 1 ? 16 : 128);
        map.set('type', this.state.type)
        $mzlApi.post(APINames.API_queryUserIdentity, map, 
            res=>{
                // console.log('身份', res, tag)
                var arr = [];
                res.forEach(item => {
                    item._borderColor = '#D8D8D8';
                    item._color = '#333';
                    if (item.IdentityFlag == 1) {
                        item._IdentityFlag = '店长'
                    } else if (item.IdentityFlag == 4) {
                        item._IdentityFlag = '收银员'
                    } else if (item.IdentityFlag == 64) {
                        item._IdentityFlag = item.Extra.RoleName
                    } else if (item.IdentityFlag == 128) {
                        item._IdentityFlag = item.Extra.RoleName
                    }
                    if (this.state.type == 2) {
                        item.ShopName = item.MchName
                    }
                    if (item.IdentityFlag == 1 || item.IdentityFlag == 4 || item.IdentityFlag == 64 || item.IdentityFlag == 128) {
                        arr.push(item)
                    }

                });
                // this.requestQueryUserIdentityChain()
                if (this.state.type == 1) { 
                    this.setState({ identyList: arr },()=>{
                        this.requestQueryUserIdentityChain()
                    });
                    StorageTools.saveUserIdenty(arr);
                    if (arr.length == 1) {
                        StorageTools.saveMchCode(arr[0].mchCode)
                        this.state.loginData=arr[0]
                        this.setState({ loginData: this.state.loginData }, () => {
                        })
                    } else if (arr.length == 0) {
                        messageError('该手机号没有店铺权限')

                    } else {
                        this.setState({ visible: true })
                    }
                } else {
                    this.setState({ identyList: res });
                    if (arr.length==1) {
                        StorageTools.saveMchCode(arr[0].mchCode)
                        this.state.loginData=arr[0]
                        this.setState({ loginData: this.state.loginData })
                    } else if(arr.length == 0) {
                        messageError('该手机号未注册连锁')
                    }else {
                        this.setState({visible:true})
                    }
                }
                if (this.state.loginData.AppFlag) {
                    StorageTools.saveMchInfo(this.state.loginData)
                    if(this.state.type==1){
                        this.requestConfirmLogin()
                        // StorageTools.savemanguserInfoAuthority(this.state.loginData)
                    }else {
                        settingAuthority(this.state.loginData.IdentityFlag,this.state.loginData.Extra)   
                        this.props.history.push('/chain/ChainIndex')
                    }
                } else {
                        // this.setState({ visible: true })
                }
            }, this.fail.bind(this), this);
    }


    /**
     * 查询用户身份是否有连锁店铺的权限
     */
    requestQueryUserIdentityChain() {
        var map = new Map();
        map.set('userCode', this.state.userCode);
        map.set('appFlag', 128);
        map.set('type', 2)
        $mzlApi.post(APINames.API_queryUserIdentity, map, (res) => {
            // MchType为1有权限 为0没有
            if (res.length) {
                res.forEach(item => {
                    item._borderColor = '#D8D8D8';
                    item._color = '#333';
                    if (item.IdentityFlag == 1) {
                        item._IdentityFlag = '店长'
                    } else if (item.IdentityFlag == 4) {
                        item._IdentityFlag = '收银员'
                    } else if (item.IdentityFlag == 64) {
                        item._IdentityFlag = '超级管理员'
                    } else if (item.IdentityFlag == 128) {
                        item._IdentityFlag = '店铺管理员工'
                    }
                    item.ShopName = item.MchName
                    

                });
                this.state.mchInfoArr = res
                this.setState({ mchInfoArr: this.state.mchInfoArr })
                if(this.state.identyList.length>1){
                    this.setState({visible:true})
                }
            }
            // StorageTools.saveMchInfo(res[0])
            // if(res.length && res[0].Extra.MchType == 1){
            //     StorageTools.saveMchCode(res[0].mchCode)
            // }

        }, this.fail.bind(this), this);
    }

    /**
     * 查店铺信息
     */
    requestConfirmLogin() {
        var map = new Map();
        map.set('shopCode', this.state.loginData.ShopCode);
        map.set('userCode', this.state.userCode);
        map.set('empId', this.state.loginData.AppUid);
        $mzlApi.post(APINames.API_confirmLogin, map, this.success.bind(this), this.fail.bind(this), this);
    }

    /**
     * 查询店铺设置开关
     */
    queryShodisjunctorSettings(){
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        $mzlApi.post(APINames.API_QueryShodisjunctorSettings, map, (res)=>{
            
            if(res.Data.data){
                var data=JSON.parse(res.Data.data);
                let roomData = StorageTools.getRoomData();
                if(data.length>0){
                    // 轮排保存多次，用最后的一条，引起重新进入仍然显示轮排开启
                    let item = data[data.length-1]; 
                    item.mode = roomData.mode;
                    StorageTools.saveRoomData(item);
                }else{
                    StorageTools.saveRoomData({});
                }
                
            }
            
           
        }, (res)=>{}, this);
    }


    success(res, tag) {
        log('success', res, tag);
        switch (tag) {
            case APINames.API_Login:
                StorageTools.saveStoreCode(res.Data.user_code);
                this.setState({ loading: false, userCode: res.Data.user_code });
                console.log('成功', this.state.loginData)
                BuyUtils.setCloseTime(null)
               this.requestQueryUserIdentity()
                if (res.ErrCode > 0) {
                    messageError(res.ErrMsg);
                    return false;
                }
                var userData = {};
                if (this.state.isSavePwd) {//记住密码
                    userData = { userName: this.state.data.account, pwd: this.state.data.pwd }
                } else {
                    userData = { userName: this.state.data.account, pwd: "" }
                }
                if (this.state.userLoginList == null || this.state.userLoginList.length < 1) {
                    var arr = [];
                    userData.id = 0;
                    arr.push(userData);
                    StorageTools.saveUserLogin(arr)
                } else {
                    var isAdd = true;
                    this.state.userLoginList.forEach(item => {
                        if (item.userName == userData.userName) {
                            if (this.state.isSavePwd) {//记住密码
                                item.pwd = this.state.data.pwd
                            } else {
                                item.pwd = ""
                            }
                            StorageTools.saveUserLogin(this.state.userLoginList);
                            isAdd = false;
                        }

                    });
                    if (isAdd) {
                        userData.id = this.state.userLoginList.length;
                        this.state.userLoginList.unshift(userData);
                        StorageTools.saveUserLogin(this.state.userLoginList)
                    }
                }
                break;
            case APINames.API_queryUserIdentity:
                console.log('身份', res, tag)
                var arr = [];
                res.forEach(item => {
                    item._borderColor = '#D8D8D8';
                    item._color = '#333';
                    if (item.IdentityFlag == 1) {
                        item._IdentityFlag = '店长'
                    } else if (item.IdentityFlag == 4) {
                        item._IdentityFlag = '收银员'
                    } else if (item.IdentityFlag == 64) {
                        item._IdentityFlag = item.Extra.RoleName
                    } else if (item.IdentityFlag == 128) {
                        item._IdentityFlag = item.Extra.RoleName
                    }
                    if (this.state.type == 2) {
                        item.ShopName = item.MchName
                    }
                    if (item.IdentityFlag == 1 || item.IdentityFlag == 4 || item.IdentityFlag == 64 || item.IdentityFlag == 128) {
                        arr.push(item)
                    }

                });
                // this.requestQueryUserIdentityChain()
                if (this.state.type == 1) { 
                    this.setState({ identyList: arr },()=>{
                        this.requestQueryUserIdentityChain()
                    });
                    StorageTools.saveUserIdenty(arr);
                    if (arr.length == 1) {
                        StorageTools.saveMchCode(arr[0].mchCode)
                        this.state.loginData=arr[0]
                        this.setState({ loginData: this.state.loginData }, () => {
                        })
                    } else if (arr.length == 0) {
                        messageError('该手机号没有店铺权限')

                    } else {
                        this.setState({ visible: true })
                    }
                } else {
                    this.setState({ identyList: res });
                    if (arr.length==1) {
                        StorageTools.saveMchCode(arr[0].mchCode)
                        this.state.loginData=arr[0]
                        this.setState({ loginData: this.state.loginData })
                    } else if(arr.length == 0) {
                        messageError('该手机号未注册连锁')
                    }else {
                        this.setState({visible:true})
                    }
                }
                if (this.state.loginData.AppFlag) {
                    StorageTools.saveMchInfo(this.state.loginData)
                    if(this.state.type==1){
                        this.requestConfirmLogin()
                        // StorageTools.savemanguserInfoAuthority(this.state.loginData)
                    }else {

                        settingAuthority(this.state.loginData.IdentityFlag,this.state.loginData.Extra)   
                        this.props.history.push('/chain/ChainIndex')
                    }
                } else {
                        // this.setState({ visible: true })
                }

                break;
            case APINames.API_confirmLogin:
                debugger
                this.initStoreInfo(res);
                window.StoreName = res.StoreName;
                if (!notify.appSend("user-login", res)) {
                    //console.log("[ App][ Log]: 无法对客户端进行用户登陆通知")
                }
                if (res.StoreName == '') {
                    this.props.history.push('/home/settingContent/shopSetting');
                    messageInfo('请完善店铺信息')

                } else {
                    if (res.ErrCode == 0) {
                        this.props.history.push('/home');
                    }
                }
                break;
            case APINames.API_getUserCode:
                this.setState({ loading: false });
                if (!res.Data.userCode) {
                    messageError('账号不存在')
                } else {
                    StorageTools.saveUserLoginPhone(this.state.data.account)
                    if (res.Data.isPassWord == true) {
                        this.setState({ isPwd: true, loginTitle: '登录' ,userCode: res.Data.userCode})

                    } else {
                        notify.appSend("user-login-event", { event: "reset_pwd" });
                        this.props.history.push(`/ResetPwd?${this.state.data.account}?${this.state.type}`)
                    }
                }
                break;
                default:
                    break;


        }
    }

    fail(res, tag) {
        //console.log('失败', res, tag)
        this.setState({ loading: false });
        showMessageTopError(res, tag);
    }
    //保存用户数据
    initStoreInfo(res){
        let mchidthis = res.Extra.mchId;
        let isthisStore = [];
        this.state.mchInfoArr.map((item,index)=>{
            if(item.MchId == mchidthis){
                isthisStore.push(item)
            }
        })
        StorageTools.saveUserChainIdenflty(isthisStore)

        console.log('店铺信息', res,this.state.loginData)
        this.setState({ visible: false });
        //保存登录的手机号
        res.loginPhone = this.state.data.account;
        res._AppUid=this.state.loginData.AppUid
        StorageTools.saveUserInfo(res);
        StorageTools.saveIdentity(this.state.loginData);
        StorageTools.saveUserCode(res.StoreCode);
        service.getUserProd(this)
        // this.queryShodisjunctorSettings();
        
        StorageTools.saveStoreName(res.StoreName);
        StorageTools.saveMchId(parseInt(res.Extra.mchId))
        this.props.update({identityInfo:this.state.loginData,userInfo:res})
    }


    searchUser(value) {
        var list = [];
        this.state.userLoginList.forEach((item) => {
            var userName = item.userName.indexOf(value);
            if (userName >= 0 && list.length < 5) {
                list.push(item)
            }
        });
        this.state._userLoginList = list;
        this.setState(this)
    }


    selectUser(e, login) {
        this.state.userLoginList.forEach((item) => {
            if (item.userName == e) {
                this.state.data.account = item.userName;
                this.state.data.pwd = item.pwd;
                if (this.state.data.pwd != null && this.state.data.pwd.length > 5) {
                    this.setState({
                        isSavePwd: true
                    });
                    if (login) {
                        //console.log(11111, "登录");
                    }
                } else {
                    this.setState({
                        isSavePwd: false
                    })
                }
            }
        })
    }

    onChangeListener(tag, e, option) {
        //console.log("onChangeListener",tag,e,option);
        var data = this.state.data;
        switch (tag) {
            case 'phone':
                var data = this.state.data;
                if (e.length > 11) {
                    return;
                }
                data.account = e;
                this.searchUser(e);
                if (e.length > 10) {
                    this.selectUser(e)
                }
                break;
            case 'selected':
                data.account = e;
                this.selectUser(e, true);

                break;
            case '删除-用户':
                option.stopPropagation();
                var index = 0;
                var num = 0;
                var list = [];
                this.state.userLoginList.forEach((item, index) => {
                    if (item.userName != e.userName) {
                        list.push(item)
                    }
                });
                // this.state._userLoginList.forEach((item,index)=>{
                //     if(item.userName == e.userName){
                //         num = index
                //     }
                // });
                // this.state.userLoginList.splice(index,1);
                // this.state._userLoginList.splice(num,1);

                this.setState({
                    userLoginList: list,
                    _userLoginList: copyProject(list)
                });
                StorageTools.saveUserLogin(list);
                break;
            case 'code':
                data.checkCode = e.target.value;
                this.setState(this);
                break;
            case 'pwd':
                // if(e.target.value>=6){
                //     this.setState({disabled:false,color:'#fff'})
                // }
                data.pwd = e.target.value;
                this.setState(this);
                break;
            case '登录':
                if (this.state.data.account.length < 11) {
                    messageTopError("请输入正确的手机号");
                    return;
                }
                if (this.state.loginTitle == '继续') {
                    this.setState({ loading: true });
                    StorageTools.saveloginPhone(this.state.data.account)
                    this.requestAPI_getUserCode()
                } else {
                    if (this.state.data.pwd == null || this.state.data.pwd == "") {
                        messageTopError("请输入密码");
                        return;
                    }
                    if (this.state.data.pwd.length < 6) {
                        messageTopError("密码必须大于6位");
                        return;
                    }
                    this.setState({ loading: true,type:e });
                    this.requestLogin(e);

                }

                break;
            case '记住密码':
                this.setState({
                    isSavePwd: e.target.checked
                });
                break;
            case '进入':
                StorageTools.saveLoginUserCode(this.state.userCode)
                StorageTools.saveMchCode(e.mchCode)
                StorageTools.saveMchInfo(e);
                this.state.identyList.forEach(item => {
                    if (item.AppUid == e.AppUid) {
                        item._borderColor = '#A451F6';
                        item._color = '#fc1a6e';
                    } else {
                        item._borderColor = '#D8D8D8';
                        item._color = '#333'
                    }
                });
                if (this.state.type == 2) {
                    const ss = e;
                    if(e.IdentityFlag && e.Extra){
                        settingAuthority(e.IdentityFlag,e.Extra) 
                    }
                    this.props.history.push('/chain/ChainIndex');

                } else {
                    this.setState({ loginData: e, identyList: this.state.identyList }, () => {
                        this.requestConfirmLogin()
                    });
                }

                break;
            case 'hideCancelModal':
                this.setState({ visible: false })
                break;
            case 'type':
                this.setState({ type: parseInt(e),
                loginTitle:'继续',
                isPwd:false,
                loginData:{} })
                break;
            default:
                break;
        }
    }


    keyDown(e) {
        //console.log(e.keyCode)
        if (e.keyCode == 13) {
            if (this.state.data.account.length < 11) {
                messageTopError("请输入正确的手机号");
                return;
            }
            if (this.state.loginTitle == '继续') {
                this.requestAPI_getUserCode()
            } else {
                if (this.state.data.pwd == null || this.state.data.pwd == "") {
                    messageTopError("请输入密码");
                    return;
                }
                if (this.state.data.pwd.length < 6) {
                    messageTopError("密码必须大于6位");
                    return;
                }
                this.requestLogin();
                this.setState({ loading: true });
            }

        }
    }


    render() {
        //兼容老版本
        var configLoacl = StorageTools.getConfigLoacl();
        if( configLoacl.version == "2.15.5"){
            widthBoll = false;
        }
        if(widthBoll){
            var noticeTime = StorageTools.getNoticeTime();
            widthBoll = false;

            if(noticeTime != null && noticeTime != "" && parseInt(noticeTime)  < 1575461081){
                widthBoll = true;
            }
        }
       

        const account = this.state.data.account;


        return (
            <div id="login-root" className="mod-pg-login" style={widthBoll?{background:"#fff"}:{}} >
               
                {/* {this.state.loading ? <LoadingDialog /> : null} */}
               {/* {
               widthBoll?null:
               <div style={{position:'relative'}} className="comm-w-h comm-col comm-item-center gra-drag-region">
                </div>
                }  */}
                {/* <TrafficLight /> */}
                <div>
                    <div  style={{position:'absolute',zIndex:99,top:0,bottom:0,left:0,right:0, margin: 'auto auto', height:580,width: 360, padding: '40px',backgroundColor:'#fff',borderRadius:'20px' }}>
                        {/* <Tabs onChange={this.onChangeListener.bind(this, 'type')}>
                            <TabPane key={1} tab='店铺客户端'></TabPane>
                            <TabPane key={2} tab='连锁管理后台'></TabPane>
                        </Tabs> */}
                        <img src={require("../../images/logo.svg")} width="auto" height="60" style={{ width: '100%' , marginBottom: 20 }} />
                        <div className="login-user">
                            <span style={{ fontSize: 24, marginBottom: 30, marginLeft: 5, color: '#111111', textAlign: 'center' }}>欢迎使用66掌柜</span>
                            <AutoComplete
                                // style={{marginBottom: 15}}
                                onSelect={this.onChangeListener.bind(this, "selected")}
                                onChange={this.onChangeListener.bind(this, "phone")}
                                value={account}
                                placeholder="请输入手机号"
                                optionLabelProp="value"
                                dataSource={this.state._userLoginList.map(d =>
                                    <Option value={d.userName} key={d.userName}>
                                        <div className="comm-row comm-item-justify-between"><span>{d.userName}</span><Icon
                                            onClick={this.onChangeListener.bind(this, "删除-用户", d)} type="close" /></div>
                                    </Option>
                                )}>

                            </AutoComplete>

                        </div>
                        <div className="login-pwd">
                                <span className="account-hint" style={{ color: '#111111' }}></span>
                                <Input
                                    // size="large"
                                    type="password" placeholder="请输入密码"
                                    value={this.state.data.pwd}
                                    name='pwd' onChange={this.onChangeListener.bind(this, "pwd")}
                                    onPressEnter={this.onChangeListener.bind(this, '登录',1)} />
                            </div>


                        <div className="comm-flex-row-between comm-w gra-no-drag" style={{ marginTop: "20px" }}>
                            <Checkbox style={{ color: '#666666', fontSize: 12 }} checked={this.state.isSavePwd}
                                onChange={this.onChangeListener.bind(this, '记住密码')}>记住密码</Checkbox>
                            <label style={{ color: '#FC1A6E', fontSize: 12, }} onClick={() => {
                                notify.appSend("user-login-event", { event: "reset_pwd" });
                                this.props.history.push(`/ResetPwd?${this.state.data.account}?${this.state.type}`)
                            }} className="login-form-forgot"
                            >忘记密码</label>
                        </div>

                        <Button type="primary" shape="round" className="btn-login comm-col comm-item-center comm-item-justify gra-no-drag"
                            onClick={this.onChangeListener.bind(this, '登录',1)}><span
                                style={{ color: "#fff" }}>立即登录</span></Button>

                        {<Button type="default"  shape="round"  className="btn-register " 
                        onClick={this.onChangeListener.bind(this, '登录',2)}><span style={{ color: '#111111' }}>连锁登录</span> </Button>}
                        <div></div>

                        <Button shape="round"  type="default" style={{marginTop:60}} className="btn-register " onClick={() => {
                            notify.appSend("user-login-event", { event: "register" });
                            this.props.history.push('/Register')
                        }}><span style={{ color: '#111111' }}>没有账号？注册一个吧</span> </Button>
                        <div>

                        </div>
                    </div>
                </div>

                {/* {
                    widthBoll?
                    <div className="comm-w-h comm-col comm-item-center " style={{padding: "0px 20px 20px 20px"}}>
                    <div className="comm-col comm-item-center comm-w gra-drag-region"
                         style={{paddingTop: 20, marginRight: 30}}>
                        <img src={require("../../images/logo.svg")} width="80" height="80"/>
                    </div>

                    <div className="login-user">
                        <span style={{fontSize: 14, marginTop: 5, marginLeft: 5}}>账号</span>

                        <AutoComplete
                            style={{marginBottom: 30}}
                            onSelect={this.onChangeListener.bind(this, "selected")}
                            onChange={this.onChangeListener.bind(this, "phone")}
                            value={account}
                            placeholder="请输入手机号"
                            optionLabelProp="value"
                            dataSource={this.state._userLoginList.map(d =>
                                <Option value={d.userName} key={d.userName}>
                                    <div className="comm-row comm-item-justify-between"><span>{d.userName}</span><Icon
                                        onClick={this.onChangeListener.bind(this, "删除-用户", d)} type="close"/></div>
                                </Option>
                            )}>

                        </AutoComplete>

                    </div>
                    {
                        this.state.isPwd ? <div className="login-pwd ">
                            <span className="account-hint">密码</span>
                            <Input
                                // size="large"
                                type="password" placeholder="请输入密码"
                                value={this.state.data.pwd}
                                name='pwd' onChange={this.onChangeListener.bind(this, "pwd")}
                                onPressEnter={this.onChangeListener.bind(this, '登录')}/>
                        </div> : null
                    }


                    <div className="comm-flex-row-between comm-w gra-no-drag" style={{marginTop: "20px"}}>
                        <Checkbox style={{color: '#666', fontSize: 13}} checked={this.state.isSavePwd}
                                  onChange={this.onChangeListener.bind(this, '记住密码')}>记住密码</Checkbox>
                        <label style={{color: '#666', fontSize: 13,}} onClick={() => {
                            notify.appSend("user-login-event", {event: "reset_pwd"});
                            this.props.history.push('/ResetPwd')
                        }} className="login-form-forgot"
                        >忘记密码</label>
                    </div>

                    <Button type="primary" className="btn-login comm-col comm-item-center comm-item-justify gra-no-drag"
                            onClick={this.onChangeListener.bind(this, '登录')}><span
                        style={{color: "#fff", fontSize: 16}}>{this.state.loginTitle}</span></Button>
                    <Button type="default" className="btn-register " onClick={() => {
                        notify.appSend("user-login-event", {event: "register"});
                        this.props.history.push('/Register')
                    }}>注册账号</Button>
                    <div>

                    </div>
                    <div className="bottom-show-version comm-flex-col-center">版本号：{mzlVersion}</div>

                </div>:
                <div className="gra-no-drag" style={{position:'absolute',zIndex:99,top:0,bottom:0,left:0,right:0,background: 'rgba(255,255,255,0.8)', margin: '130px auto', height:380,width: 360, padding: '15px 40px 30px 40px', borderRadius: 6, boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.15)' }}>
                        <Tabs onChange={this.onChangeListener.bind(this, 'type')}>
                            <TabPane key={1} tab='店铺客户端'></TabPane>
                            <TabPane key={2} tab='连锁管理后台'></TabPane>
                        </Tabs>
                        <div className="login-user">
                            <span style={{ fontSize: 14, marginTop: 5, marginLeft: 5, color: '#111111' }}>账号</span>
                            <AutoComplete
                                // style={{marginBottom: 15}}
                                onSelect={this.onChangeListener.bind(this, "selected")}
                                onChange={this.onChangeListener.bind(this, "phone")}
                                value={account}
                                placeholder="请输入手机号"
                                optionLabelProp="value"
                                dataSource={this.state._userLoginList.map(d =>
                                    <Option value={d.userName} key={d.userName}>
                                        <div className="comm-row comm-item-justify-between"><span>{d.userName}</span><Icon
                                            onClick={this.onChangeListener.bind(this, "删除-用户", d)} type="close" /></div>
                                    </Option>
                                )}>

                            </AutoComplete>

                        </div>
                        {
                            this.state.isPwd ? <div className="login-pwd">
                                <span className="account-hint" style={{ color: '#111111' }}>密码</span>
                                <Input
                                    // size="large"
                                    type="password" placeholder="请输入密码"
                                    value={this.state.data.pwd}
                                    name='pwd' onChange={this.onChangeListener.bind(this, "pwd")}
                                    onPressEnter={this.onChangeListener.bind(this, '登录')} />
                            </div> : null
                        }


                        <div className="comm-flex-row-between comm-w gra-no-drag" style={{ marginTop: "20px" }}>
                            <Checkbox style={{ color: '#666666', fontSize: 12 }} checked={this.state.isSavePwd}
                                onChange={this.onChangeListener.bind(this, '记住密码')}>记住密码</Checkbox>
                            <label style={{ color: '#9013FE', fontSize: 12, }} onClick={() => {
                                notify.appSend("user-login-event", { event: "reset_pwd" });
                                this.props.history.push(`/ResetPwd?${this.state.data.account}?${this.state.type}`)
                            }} className="login-form-forgot"
                            >忘记密码</label>
                        </div>

                        <Button type="primary" className="btn-login comm-col comm-item-center comm-item-justify gra-no-drag"
                            onClick={this.onChangeListener.bind(this, '登录')}><span
                                style={{ color: "#fff" }}>{this.state.loginTitle}</span></Button>
                        <Button type="default" className="btn-register " onClick={() => {
                            notify.appSend("user-login-event", { event: "register" });
                            this.props.history.push('/Register')
                        }}><span style={{ color: '#111111' }}>注册账号</span> </Button>
                        <div>

                        </div>
                    </div>
                } */}

                
               
                
                <IModal
                    width='400px'
                    visible={this.state.visible}
                    footer={null}
                    colse={true}
                    onCancel={this.onChangeListener.bind(this, 'hideCancelModal')}
                    view={
                        <div>
                            <p style={{ textAlign: 'center', marginTop: 10, color: '#333' }}>请选择要登录的店铺/岗位</p>
                            <div style={{maxHeight:500,overflow:'auto',
                                                    paddingLeft:40,
                                                    paddingRight:40}} className='comm-flex-col-between'>
                                {
                                    this.state.identyList.map((item, index) => {
                                        return (
                                            <div key={index} onClick={this.onChangeListener.bind(this, '进入', item)}
                                                style={{
                                                    
                                                    color: `${item._color}`,
                                                    margin: '10px 0',
                                                    border: `1px ${item._color} solid`,
                                                    borderRadius: 30,
                                                    width: '100%',
                                                    height: '50px',
                                                    lineHeight: '50px',
                                                    textAlign: 'center',
                                                    cursor:'pointer',
                                                }}>
                                                <span
                                                    style={{ color: `${!item.ShopName ? 'red' : item._color}` }}>{item.ShopName ? item.ShopName : '-'}-{item._IdentityFlag}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }>

                </IModal>

            </div>
        )
    }
}

export const Login = LoginComponent;

// Route的onEnter不起作用，避免打开首页无数据显示
export function checkLogin(nextState, replaceState) {
    let userCode =StorageTools.getLoginUserCode();
    // console.log('02211111',userCode)

    if (userCode == null || userCode.length == 0) {
        replaceState("/login");
    } else {
        this.props.history.push("/home");
    }
}