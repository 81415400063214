import React from 'react';
import '../../css/tab.css'
import { Button, Popover, Table, Tooltip } from 'antd'
import { getConversionDate, getTime } from '../comm/dateHelp'
import IButton from '../components/IButton'
import APINames from "../comm/api-names"
import StorageTools from '../comm/storageTools';
import { $mzlApi } from '../comm/mzl-core'
import { ECardKind, EPaymentFlag } from '../comm/iEnum'
import { calcAdd, calcDivide, calcSubtract, hideLoadding, showLoadding } from "../comm/utils";
import { IModal } from "../components/iModal";
import IDrawer from "../components/iDrawer";

class orderDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            columns: [
                { title: '手牌', dataIndex: 'PlateTitle', align: 'center',width:80},
                { title: '房间', dataIndex: 'PlaceTitle', align: 'center',width:80},   
                {
                    title: '消费项目', dataIndex: 'ItemName', align: 'center', width: '20%', className: 'extra',
                    render: (text) => (
                        <Tooltip title={text}>
                            {text}
                        </Tooltip>
                    )
                },
                { title: '售价', dataIndex: 'UnitPriceText', align: 'center', width: '15%' },
                { title: '数量', dataIndex: 'Quantity', align: 'center', width: '5%' },
                { title: '实付', dataIndex: 'FinalFeeText', align: 'center', width: '15%' },
                {
                    title: '提成人员', dataIndex: '_Extra', align: 'center', width: '20%', className: 'extra',
                    render: (text, record) => (
                        <Tooltip title={text}>
                            {text}
                        </Tooltip>
                    )
                }],
            data: [],
            tableData: [],
            couponMoney: 0,
            orderData: [],
            returnData: [],
            payFlagList: [],
            repayList: [],
            arrearsList: [],
            receiva: '',
            orderAmount: 0,

        }
    }

    componentDidMount() {
        // console.log('美剧',EPaymentFlag.Cash)
        this.requestGetStoreOrder(this.props.modalData);
        this.requestGetPaymentOrderG(this.props.modalData);
        this.requestAPI_queryPayChannels(this.props.modalData);
        this.requestAPI_queryRepayDatas(this.props.modalData)
    }

    /**
     * 作废备注
     * @param {*} orderNo
     */
    requestAPI_getRefundTradeData(orderNo) {
        var map = new Map();
        map.set('orderNo', orderNo);
        $mzlApi.post(APINames.API_getRefundTradeData, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    requestGetStoreOrder(orderNo) {
        showLoadding();
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('orderNo', orderNo);
        $mzlApi.post(APINames.API_getStoreOrder, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    requestGetPaymentOrderG(orderNo) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('tradeNo', orderNo);
        $mzlApi.post(APINames.API_getPaymentOrder, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    /**
     * 查询订单支付方式
     */
    requestAPI_queryPayChannels(tradeNo) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('tradeNo', tradeNo);
        $mzlApi.post(APINames.API_queryPayChannels, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    /**
     * 查询还款记录
     */
    requestAPI_queryRepayDatas(tradeNo) {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('tradeNo', tradeNo);
        $mzlApi.post(APINames.API_queryRepayDatas, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    httpSuccess(res, tag) {
        switch (tag) {
            case APINames.API_getStoreOrder:
                hideLoadding();
                // console.log('消磁',res,tag)

                var data = res.Items;
                var order = res;
                var num = 0;
                var Quantity = 0;
                var allMoney = 0; //订单总金额
                var couponMoney = 0;//优惠金额 
                var allQuantity = 0;//订单总数量
                var sum = 0;
                data.forEach(item => {
                    item.key = num;
                    item.Title = item.ItemName;
                    item._collect = calcDivide(item.FinalFee, 100);
                    if (item.CardKind != ECardKind.TimeCard && item.CardKind != ECardKind.ChargePresent) {
                        allMoney = calcAdd(allMoney, item._collect)
                    }
                    num++;
                    Quantity += item.Quantity;
                    item.UnitPriceText = calcDivide(item.UnitPrice, 100) + '元';
                    // if (item.CardId) {
                    //     item.FinalFeeText = 0 + '元';
                    //     item.FinalFee = 0;
                    // } else {
                    item.FinalFeeText = calcDivide(item.FinalFee, 100) + '元';
                    // }
                    sum += item.FinalFee;
                    if (item.Awards) {
                        var str = '';
                        item.Awards.forEach((val,index) => {
                            str += val.OperatorName +`(现金:${calcDivide(val.AwardValue, 100)}/${calcDivide(val.Performance, 100)}，扣卡:${calcDivide(val.UcardAwardValue, 100)}/${calcDivide(val.UcardPerformance, 100)})；`;
                        });
                        item._Extra = str;
                    }
                });
                switch (res.Order.State) {
                    case 1:
                        res.Order._state = '未付款';
                        break;
                    case 7:
                        res.Order._state = '已付款';
                        break;
                    case 11:
                        res.Order._state = '已取消';
                        break;
                    case 14:
                        res.Order._state = '已退单';
                        this.requestAPI_getRefundTradeData(this.props.modalData);
                        break;
                    default:
                        break;
                }
                couponMoney = calcSubtract(allMoney, calcDivide(order.Extend.FinalFee, 100));
                //console.log(data, couponMoney)
                this.setState({ tableData: data, data: res, couponMoney: couponMoney, orderAmount: sum });
                break;
            case APINames.API_getPaymentOrder:
                //console.log('订单',res,tag)
                this.setState({ orderData: res });
                break;
            case APINames.API_getRefundTradeData:
                var num = 1;
                res.forEach(item => {
                    item.key = num;
                    num++;
                    if (item.payMethod == EPaymentFlag.Balance) {
                        item._return = '退款至余额'
                        item._payAmount = item.payAmount / 100 + '元';
                    }
                    if (item.payMethod == EPaymentFlag.Wallet) {
                        item._return = '退款至钱包'
                        item._payAmount = item.payAmount / 100 + '元';
                    }
                    if (item.payMethod == EPaymentFlag.UserCard) {
                        item._return = '退会员卡'
                        item._payAmount = item.payAmount / 100 + '元';
                    }
                    if (item.payMethod == EPaymentFlag.Cash) {
                        item._return = '退现金'
                        item._payAmount = item.payAmount / 100 + '元';
                    }
                    if (item.payMethod == EPaymentFlag.BankCard) {
                        item._return = '退款至银行卡'
                        item._payAmount = item.payAmount / 100 + '元';
                    }
                    if (item.payMethod == EPaymentFlag.OutSP) {
                        item._return = '退款至第三方支付平台'
                        item._payAmount = item.payAmount / 100 + '元';
                    }
                    if (item.payMethod == EPaymentFlag.TimeCard) {
                        item._return = '套餐卡';
                        item._payAmount = item.payAmount + '次';
                        item.str = '';
                        item.items.forEach(val => {
                            item.str += val.ItemTitle + 'X' + val.Quantity + '；'
                        })
                    } else {
                        item._payAmount = item.payAmount / 100 + '元';
                        item.str = '';
                    }
                    if (item.payMethod == EPaymentFlag.Integral) {
                        if (item.extras.ScoreKind == 1) {
                            item._return = '赠送积分扣除'
                            item._payAmount = -item.extras.PredScore / 100 + '分'
                        } else {
                            item._return = '返还使用积分'
                            item._payAmount = item.extras.RefundScore / 100 + '分' + '(' + '抵' + item.payAmount / 100 + '元' + ')'
                        }
                    }
                });
                // console.log('作废订单',res,tag);
                this.setState({ returnData: res });
                break;
            case APINames.API_queryPayChannels:
                //console.log('支付方式',res,tag)
                res.forEach(item => {
                    switch (item.Method) {
                        case EPaymentFlag.Wallet:
                            item._payFlag = '钱包支付';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.UserCard:
                            item._payFlag = '会员扣款';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.UserCoupon:
                            item._payFlag = '现金券';
                            item._Amount = '抵' + item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.Cash:
                            item._payFlag = '现金支付';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.BankCard:
                            item._payFlag = '银行卡支付';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.OutSP:
                            if (item.Code == 'alipay') {
                                item._payFlag = '支付宝'
                            } else if (item.Code == 'wxpay') {
                                item._payFlag = '微信'
                            } else {
                                item._payFlag = '扫码付'
                            }
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.TimeCard:
                            item._payFlag = '套餐卡销卡';
                            item._Amount = item.Amount + '次';
                            break;
                        case EPaymentFlag.Free:
                            item._payFlag = '免单';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.Arrears:
                            item._payFlag = '欠款';
                            item._Amount = item.Amount / 100 + '元';
                            break
                        case EPaymentFlag.Integral:
                            item._payFlag = '积分';
                            item._Amount = '抵' + item.Amount / 100 + '元';
                            break
                        case EPaymentFlag.UserCardPre:
                            item._payFlag = '赠送金额支付';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.Meituan:
                            item._payFlag = '美团支付';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.Koubei:
                            item._payFlag = '抖音';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.Eleme:
                            item._payFlag = '饿了么';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                        case EPaymentFlag.Dazhongdianping:
                            item._payFlag = '大众点评';
                            item._Amount = item.Amount / 100 + '元';
                            break;
                    }
                });
                this.setState({ payFlagList: res });
                break;
            case APINames.API_queryRepayDatas:
                //console.log('还款记录',res,tag)
                var sum = 0;
                var arr = [];
                var flag = false;
                res.forEach((item, index) => {
                    if (item.State == 1) {
                        this.setState({ receiva: item.Amount / 100 });
                        flag = true
                    }
                    if (!flag) {
                        this.setState({ receiva: 0 })
                    }
                    if (item.State == 7) {
                        arr.push(item)
                    }
                });
                this.setState({ repayList: arr, arrearsList: res });
            default:
                break;
        }
    }

    httpFail(res, tag) {
        //console.log('失败', res, tag)
    }

    onclickListener(tag) {
        switch (tag) {
            case 'hideModal':
                this.props.onclickListener('hideModal');
                break;
            case 'showPayModal':
                this.props.onclickListener('showPayModal', this.props.data, this.state.data);
                break;
            case 'hideFreeModal':
                this.props.onclickListener('hideFreeModal');
                break;
            case 'drawer-ok':
                this.props.onclickListener('showPayModal', this.props.data, this.state.data);
                break;
            case 'drawer-cancel':
                this.props.onclickListener('hideModal');
                break;
            default:
                break;
        }
    }

    render() {
        const { data } = this.state;
        const div = <div style={{ width: 180 }}>
            {
                this.state.payFlagList.map((item, index) => {
                    return (
                        <div key={index} className='flexBox'>
                            <span>{item._payFlag}：</span>
                            <span>{item._Amount}</span>
                        </div>
                    )
                })
            }

        </div>;
        return (
            <div>
                <IDrawer
                    confirmText='结束'
                    width='90%'
                    title='订单详情'
                    visible={this.props.visible}
                    footer={this.props.footerVisible =='block'}
                    onClickListener={this.onclickListener.bind(this)}
                    onCancel={this.onclickListener.bind(this, 'hideModal')}>

                    <div>
                    <div style={{paddingBottom:10}}>
                            <div style={{ overflow: 'hidden', fontSize: 12 }}>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>订单号：{this.state.data.Order ? this.state.data.Order.OrderNo : ''}</div>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>订单时间：{this.state.data.Order ? getTime(this.state.data.Order.CreateTime) : ''}</div>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>收银员：{this.state.data.Extend ? this.state.data.Extend.OperatorName : '-'}</div>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>赠送积分：{this.state.data.Extra ? this.state.data.Extra.proScore / 100 + '分' : '-'}</div>
                            </div>
                            <div style={{ overflow: 'hidden', fontSize: 12, marginTop: 10 }}>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>姓名：{this.state.data.Extend ? this.state.data.Extend.BuyerName != '' ? this.state.data.Extend.BuyerName : '-' : ''}({this.state.data.Extend ? this.state.data.Extend.BuyerPhone != '' ? this.state.data.Extend.BuyerPhone : '-' : ''})</div>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>开卡店铺：{data.Extra ? data.Extra.cusShopName : ""}</div>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>订单状态：<span
                                    style={{ color: 'red' }}>{this.state.data.Order ? this.state.data.Order._state : ''}</span></div>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>订单金额：<Popover placement='topRight'
                                    content={div}><a>{this.state.orderAmount / 100 + '元'}</a></Popover></div>
                            </div>
                            <div style={{ overflow: 'hidden', fontSize: 12, marginTop: 10 }}>
                                <div style={{
                                    width: '25%',
                                    float: 'left'
                                }}>备注：{this.state.data.Extend ? this.state.data.Extend.Remark != '' ? this.state.data.Extend.Remark : '-' : ''}</div>

                            </div>

                        </div>
                        
                        {
                            this.state.returnData.length > 0 ? <div style={{ borderTop: '1px #ccc solid', marginTop: 10, fontSize: 12 }}>
                                <div
                                    style={{ margin: '10px 0' }}>订单于 {this.state.returnData[0] ? getTime(this.state.returnData[0].createTime) : ''} 由 {this.state.returnData[0] ? this.state.returnData[0].opName : ''}作废。
                                </div>
                                {
                                    this.state.returnData.map((item, index) => {
                                        return (
                                            !item.str ?
                                                <div key={index} style={{ marginBottom: 10 }}>{item._return}：{item._payAmount}</div> :
                                                <div key={index} style={{ marginBottom: 10 }}>退套餐卡：{item.str}</div>
                                        )
                                    })
                                }

                                <div style={{ marginTop: 10 }}>注 现金券不予退还！</div>
                            </div> : null
                        }

                        {
                            this.state.arrearsList.length > 0 ? <div style={{ borderTop: '1px #ccc solid', fontSize: 12 }}>
                                <div
                                    style={{ margin: '10px 0' }}>待收款：{this.state.receiva}元(总欠款：{this.state.arrearsList[0].Amount / 100}元)
                                </div>
                                {
                                    this.state.repayList.map((item, index) => {
                                        return (
                                            <div>
                                                {
                                                    index > 0 ? <div style={{ marginBottom: 10 }}>
                                                        {getConversionDate(item.CreateTime)} 由{item.OptName}操作还款 {item.Amount / 100}元
                                                    </div> : null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div> : null
                        }

                        <div >
                            <Table bordered pagination={false} size='small' 
                                columns={this.state.columns} dataSource={this.state.tableData}></Table>
                        </div>
                        

                        {/* <div style={{
                            textAlign: 'right',
                            marginTop: 10,
                            display: `${this.props.footerVisible}`,
                            borderTop: '1px #ccc solid',
                            paddingTop: 10
                        }}>
                            <Button style={{ marginRight: 20 }}
                                onClick={this.onclickListener.bind(this, 'hideModal')}>取消</Button>
                            <IButton text='结算' onClick={this.onclickListener.bind(this, 'showPayModal')}
                                type='primary'></IButton>
                        </div> */}
                    </div>
                </IDrawer>
            </div>
        )
    }
}


export default orderDetail;