import React from 'react'
import {Select} from 'antd'
import {computeTime, daysAgo, getCurrentYearMonty} from '../comm/dateHelp'
const Option = Select.Option;

let beginTime = 0;
let overTime = 0;
export default class ISelect extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    onChange(e) {
        if (e == '1') {
            beginTime = daysAgo(0);
            overTime = daysAgo(0) + 24 * 60 * 60 - 1;
        } else if (e == '2') {
            beginTime = daysAgo(1);
            overTime = daysAgo(1) + 24 * 60 * 60 - 1;
        } else if (e == '3') {
            beginTime = computeTime(getCurrentYearMonty().split('-')[0], getCurrentYearMonty().split('-')[1])[0] / 1000;
            overTime = computeTime(getCurrentYearMonty().split('-')[0], getCurrentYearMonty().split('-')[1])[1] / 1000 + 24 * 60 * 60 - 1
        } else {
            beginTime = computeTime(getCurrentYearMonty().split('-')[0], getCurrentYearMonty().split('-')[1] - 1)[0] / 1000;
            overTime = computeTime(getCurrentYearMonty().split('-')[0], getCurrentYearMonty().split('-')[1] - 1)[1] / 1000 + 24 * 60 * 60 - 1
        }
        this.props.onChange(beginTime, overTime, e)
    }

    render() {
        return (
            <Select value={this.props.value} onChange={this.onChange.bind(this)} style={{width: 100, marginRight: 10}}>
                <Option value='1'>今日</Option>
                <Option value='2'>昨日</Option>
                <Option value='3'>本月</Option>
                <Option value='4'>上月</Option>
            </Select>
        )
    }
}