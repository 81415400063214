import {notify} from "./mzl-core";

export function listenNetStatus() {
    const alertOnlineStatus = () => {
        notify.appSend("network-status-changed", {online: navigator.onLine});
    };
    window.addEventListener('online', alertOnlineStatus);
    window.addEventListener('offline', alertOnlineStatus)
}

export default listenNetStatus