import React from "react";
import { Form, Input, Radio,Select,AutoComplete} from "antd";
import iEnum from "../../../../../js/comm/iEnum";
import IInputMoney from "../../../../../js/components/iInputMoney";
import ItemVipView from "../../../../../js/settings/itemVipView";
import ItemRoleView from "../../../../../js/settings/itemRoleView";
import IInputString from "../../../../../js/components/iInputString";

const utils = require("../../../../../js/comm/utils");

const FormItem = Form.Item;
const Search = Input.Search;
const RadioGroup = Radio.Group;
const Option = Select.Option

export default class ServiceItemsSettingsModalView extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 0
    };
  }

  componentWillMount() { }

  componentDidMount() { }

  // onChange(tag, e) {
  //   switch (tag) {
  //     case "sy":
  //       this.props.saveData.PriceBasis = e.target.value;
  //       break;
  //     case "Title":
  //       // //console.log(e.target.value)
  //       this.props.saveData.Title = e;
  //       // this.props.vipList.forEach((item, num) => {
  //       //
  //       // });
  //       break;
  //     case "Price":
  //       // e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
  //       // var money = utils.checkMoney(e.target.value);
  //       var money = e;
  //       this.props.saveData.PriceText = money;
  //       this.props.saveData.Price = utils.calcMultiply(money, 100);
  //       this.props.vipList.forEach((item, index) => {
  //         if (
  //           item.PromBy == iEnum.EVipCardModal.Discount &&
  //           item.PromDiscount > 0 &&
  //           item.PromDiscount < 10000
  //         ) {
  //           item.Money = utils.calcDivide(
  //             utils.calcMultiply(money == "" ? 0 : money, item.PromDiscount),
  //             1000
  //           ); //计算会员价
  //         } else {
  //           item.Money = money == "" ? 0 : money; //计算会员价
  //         }
  //         item.UnitPrice = utils.calcMultiply(item.Money, 100);
  //         item.UnitPriceText = item.Money;
  //       });

  //       break;
  //     case "Cost":
  //       var money = e;
  //       this.props.saveData.Cost = money;
  //   }
  //   this.setState(this);
  // }

  onItemChange(tag, data, e) {
    switch (tag) {
      case "UnitPriceText":
        data.UnitPriceText = utils.checkMoneyMax(
          e,
          this.props.saveData.PriceText
        );
        data.UnitPrice = utils.numberConvert_100(data.UnitPriceText);
        break;
      case "Radio": //单选
        if (data.AwardBasis == iEnum.ESettingsBasis.Global) {
          return;
        }
        data.AmountBasis = e.target.value;
        data.AwardBasisSpc = e.target.value;
        break;
      case "Switch":
        if (e) {
          data.AwardBasis = iEnum.ESettingsBasis.Custom;
        } else {
          data.AwardBasis = iEnum.ESettingsBasis.Global;
          data.AmountBasis = iEnum.EAmountBasis.Percent;
          data.ValueText = data.VirtualRate;
          data.Value = utils.numberConvert_100(data.VirtualRate);
        }
        break;
      case "Value":
        if (data.AmountBasis == iEnum.EAmountBasis.Percent) {
          var money = utils.checkMoneyMax(e.target.value, 100);
          data.ValueText = money;
          data.Value = utils.numberConvert_100(money);
        } else {
          var money = utils.checkMoneyMax(
            e.target.value,
            this.props.saveData.PriceText
          );
          data.ValueText = money;
          data.Value = utils.numberConvert_100(money);
        }
        ////console.log("修改数据：", data.Value)
        break;

    }
    this.setState(this);
  }
  onChange(tag, e) {
    // //console.log(tag,e)
    switch (tag) {
      case 'all':
        this.setState({
          isShowAll: !this.state.isShowAll
        });
        break;
      case 'allSpc':
        this.setState({
          isShowAllSpc: !this.state.isShowAllSpc
        });
        break;
      case 'unityRadio':
        //非指定 全局 百分百
        if (e.target.value == iEnum.EAmountBasis.Percent) {
          this.props.saveData.AwardBasis = iEnum.EAmountBasis.Percent;
          this.props.saveData.AwardBasisSpc = iEnum.EAmountBasis.Percent;
          // if (this.props.saveData.AwardValue > 100) {
          //   this.props.saveData.AwardValueText = 100;
          //   this.props.saveData.AwardValue = utils.numberConvert_100(100);
          // }
        } else {
          this.props.saveData.AwardBasis = iEnum.EAmountBasis.Amount;
          this.props.saveData.AwardBasisSpc = iEnum.EAmountBasis.Amount;
        }
        this.props.saveData.AwardValueText = 0
        this.props.saveData.AwardValueSpcText = 0
        this.props.saveData.AwardValue = 0
        this.props.saveData.AwardValueSpc = 0

        this.props.saveData.AwardUcardValeu = 0
        this.props.saveData.AwardUcardValeuSpc = 0
        this.props.saveData._AwardUcardValeu = 0
        this.props.saveData._AwardUcardValeuSpc = 0
        this.setState({
          unityRadio: e.target.value
        });

        break;

      case 'unityRole': //非指定 为不同岗位设置不同提成
        if (this.props.saveData.AwardSettingsBasis !== iEnum.ESettingsBasis.Custom) {
          this.props.saveData.AwardSettingsBasis = iEnum.ESettingsBasis.Custom;
        } else {
          this.props.saveData.AwardSettingsBasis = iEnum.ESettingsBasis.Global;
        }
        this.props.roleList.forEach(item => {
          item.AwardBasis = iEnum.ESettingsBasis.Custom;
        });
        this.setState(this);

        break;

      case 'unityRoleSpc': //指定 为不同岗位设置不同提成
        if (this.props.saveData.AwardSettingsBasisSpc !== iEnum.ESettingsBasis.Custom) {
          this.props.saveData.AwardSettingsBasisSpc = iEnum.ESettingsBasis.Custom;
        } else {
          this.props.saveData.AwardSettingsBasisSpc = iEnum.ESettingsBasis.Global;
        }
        this.props.roleList.forEach(item => {
          item.AwardBasisSpc = iEnum.ESettingsBasis.Custom;
        });
        this.setState(this);
        break;
      case 'UnitPriceText':
        
        if (this.props.saveData.AwardBasis === iEnum.EAmountBasis.Percent) {
          var money = utils.checkMoneyMax(e, 100);
          this.props.saveData.AwardValueText = money;
          this.props.saveData.AwardValue = utils.numberConvert_100(money);
        } else {
          var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
          this.props.saveData.AwardValueText = money;
          this.props.saveData.AwardValue = utils.numberConvert_100(money);
        }
        this.setState(this);
        break;
      case 'UnitPriceSpcText':
        if (this.props.saveData.AwardBasisSpc === iEnum.EAmountBasis.Percent) {
          var money = utils.checkMoneyMax(e, 100);
          this.props.saveData.AwardValueSpcText = money;
          this.props.saveData.AwardValueSpc = utils.numberConvert_100(money);
        } else {
          var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
          this.props.saveData.AwardValueSpcText = money;
          this.props.saveData.AwardValueSpc = utils.numberConvert_100(money);
        }
        this.setState(this);
        break;

      case 'AwardUcardValeu':
        if (this.props.saveData.AwardBasis === iEnum.EAmountBasis.Percent) {
          var money = utils.checkMoneyMax(e, 100);
          this.props.saveData._AwardUcardValeu = money;
          this.props.saveData.AwardUcardValeu = utils.numberConvert_100(money);
        } else {
          var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
          this.props.saveData._AwardUcardValeu = money;
          this.props.saveData.AwardUcardValeu = utils.numberConvert_100(money);
        }
        this.setState(this);
        break;
      case 'AwardUcardValeuSpc':
        if (this.props.saveData.AwardBasisSpc === iEnum.EAmountBasis.Percent) {
          var money = utils.checkMoneyMax(e, 100);
          this.props.saveData._AwardUcardValeuSpc = money;
          this.props.saveData.AwardUcardValeuSpc = utils.numberConvert_100(money);
        } else {
          var money = utils.checkMoneyMax(e, this.props.saveData.PriceText);
          this.props.saveData._AwardUcardValeuSpc = money;
          this.props.saveData.AwardUcardValeuSpc = utils.numberConvert_100(money);
        }
        this.setState(this);
        break;




      case 'tabs':
        this.setState({
          tabsKey: e
        });
        break;
      case "sy":
        this.props.saveData.PriceBasis = e.target.value;
        break;
      case "Title":
        e = e.replace(/[\@\%\#\$\^\&\*\(\)\{\}\:\"\<\>\?\[\]]/, '');
        // //console.log(218218,e)
        this.props.saveData.Title = e;
        // this.props.vipList.forEach((item, num) => {
        //
        // });
        this.setState(this);
        break;
      case 'ItemTime':
        this.props.saveData.ItemTime=e
        this.setState(this);
        break
      case "Price":
        // e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
        // var money = utils.checkMoney(e.target.value);
        //console.log(230,e)
        var money = e;
        this.props.saveData.PriceText = money;
        this.props.saveData.Price = utils.calcMultiply(money, 100);
        this.props.vipList.forEach((item, index) => {
          if (
            item.PromBy == iEnum.EVipCardModal.Discount &&
            item.PromDiscount > 0 &&
            item.PromDiscount < 10000
          ) {
            item.Money = utils.calcDivide(
              utils.calcMultiply(money == "" ? 0 : money, item.PromDiscount),
              1000
            ); //计算会员价
          } else {
            item.Money = money == "" ? 0 : money; //计算会员价
          }
          item.UnitPrice = utils.calcMultiply(item.Money, 100);
          item.UnitPriceText = item.Money;
        });
        this.setState(this);
        break;
      case "Cost":
        this.props.saveData.Cost = e;
        this.props.saveData._Cost = e;
        this.setState(this);
        break;


    }

  }

  render() {
    console.log(323123,this.props.saveData)

    return (
      <div className="comm-col">
        <div className="comm-row comm-item-center" style={{ marginTop: 20 }}>
          <span className="comm-text-red">*</span>
          <span style={{ marginLeft: 8 }}>服务名称</span>
          <IInputString
            disabled={this.props.disabled}
            type="text"
            maxLength={30}
            value={this.props.saveData.Title}
            style={{ width: 396, marginLeft: "20px" }}
            placeholder="请输入服务名称"
            onChange={this.onChange.bind(this, "Title")}
          />
        </div>
        <div className="comm-row comm-item-center" style={{ marginTop: 20 }}>
        <span className="comm-text-red">*</span>
          <span style={{ marginLeft: 8 }}>服务时长</span>
          <AutoComplete
            style={{ width: 380, marginLeft: "20px" }}
            dataSource={["30","45","60","80","90","100","120","150","180"]}
            disabled={this.props.disabled}
            value={this.props.saveData.ItemTime+""} 
            onChange={this.onChange.bind(this, "ItemTime")} 
            />
          {/* <Select onChange={this.onChange.bind(this, "ItemTime")} value={this.props.saveData.ItemTime} disabled={this.props.disabled} 
          style={{ width: 380, marginLeft: "20px" }} 
          placeholder='请选择服务时长'>
            <Option value={30}>30</Option>
            <Option value={45}>45</Option>
            <Option value={60}>60</Option>
            <Option value={80}>80</Option>
            <Option value={90}>90</Option>
            <Option value={100}>100</Option>
            <Option value={120}>120</Option>
            <Option value={150}>150</Option>
            <Option value={180}>180</Option>
          </Select> */}
          分钟
        </div>
        <div
          style={{ marginTop: "15px" }}
          className="comm-row comm-item-center"
        >
          <span className="comm-text-red">*</span>
          <span style={{ marginLeft: 8 }}>门市价</span>
          <IInputMoney
            disabled={this.props.disabled}
            type="number"
            maxLength={6}
            value={this.props.saveData.PriceText}
            placeholder="请输入金额"
            style={{ width: 198, marginLeft: "34px", marginRight: "10px" }}
            onChange={this.onChange.bind(this, "Price")}
          />元
        </div>
        <div
          style={{ marginTop: "15px" }}
          className="comm-row comm-item-center"
        >
          {/* <span className="comm-text-red">*</span> */}
          <span style={{ marginLeft: 8 }}>成本</span>
          <IInputMoney
            disabled={this.props.disabled}
            type="number"
            maxLength={6}
            value={this.props.saveData._Cost}
            placeholder="请输入成本"
            style={{ width: 198, marginLeft: "54px", marginRight: "10px" }}
            onChange={this.onChange.bind(this, "Cost")}
          />元
        </div>

        <div>
          <ItemVipView
            vipList={this.props.vipList}
            saveData={this.props.saveData}
          />
        </div>

        <div>
          <div className="comm-row comm-item-center" style={{marginTop:10}}>
            <div style={{ marginRight: 20, marginLeft: 8 }}>服务提成</div>
              <RadioGroup
                value={this.props.saveData.AwardBasis}
                onChange={this.onChange.bind(this, "unityRadio")}
              >
                <Radio value={iEnum.EAmountBasis.Percent}>百分比</Radio>
                <Radio value={iEnum.EAmountBasis.Amount}>金额</Radio>
              </RadioGroup>

          </div>
          <div style={{ display: 'flex', marginTop: 20,paddingLeft:75 }}>
            <div>
              <span style={{ margin: '0 20px' }}>非指定</span>
              <IInputMoney
                value={this.props.saveData.AwardValueText}
                onChange={this.onChange.bind(this, "UnitPriceText")}
                style={{ width: 80, marginRight: "10px" }}
              />
              <span>
                {this.props.saveData.AwardBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}
              </span>
            </div>
            <div>
              <span style={{ margin: '0 20px' }}>扣卡非指定</span>
              <IInputMoney
                value={this.props.saveData._AwardUcardValeu}
                onChange={this.onChange.bind(this, "AwardUcardValeu")}
                style={{ width: 80, marginRight: "10px" }}
              />
              <span>
                {this.props.saveData.AwardBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}
              </span>
            </div>
          </div>
        </div>

        <div className="comm-row comm-item-center">
          <div style={{ display: 'flex', marginTop: 20,paddingLeft:75 }}>
          <div>
              <span style={{ margin: '0 20px' }}>指定　</span>
              <IInputMoney
                value={this.props.saveData.AwardValueSpcText}
                onChange={this.onChange.bind(this, "UnitPriceSpcText")}
                style={{ width: 80, marginRight: "10px" }}
              />
              <span>
                {this.props.saveData.AwardBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}
              </span>
            </div>
            <div>
              <span style={{ margin: '0 20px' }}>扣卡指定　</span>
              <IInputMoney
                value={this.props.saveData._AwardUcardValeuSpc}
                onChange={this.onChange.bind(this, "AwardUcardValeuSpc")}
                style={{ width: 80, marginRight: "10px" }}
              />
              <span>
                {this.props.saveData.AwardBasis == iEnum.EAmountBasis.Percent ? "%" : "元"}
              </span>
            </div>

          </div>

        </div>
          
      </div>
    );
  }
}
