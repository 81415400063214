import React from 'react';

// import EditableCell from './EditableCell'

/**
 * 必填项样式
 */
export class ISpanMust extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (
            //   primary default
            <span><span className="comm-text-red">*</span> {this.props.value}</span>

        );
    }
}







