var data = {
    year: 0,
    month: 0,
    week: 0,
    day: 0,
    date: "",
    rank: "",
    setp: "",
    showText: "",
    reachStandard: 0,
    leftShow: true,
    rightShow: true,
    medal: 0,
};

/**
 * 格式化时间
 * @param mTime
 * @return
 */
function getTimeRange(mTime) {

    if (null == mTime) {
        return "";
    }

    var seconds_of_1minute = 60;
    var seconds_of_1hour = 60 * 60;
    var seconds_of_1day = 24 * 60 * 60;
    var seconds_of_7days = seconds_of_1day * 7;
    var seconds_of_month = seconds_of_1day * 30;
    // SimpleDateFormat sdf = new SimpleDateFormat("yyyy-MM-dd HH:mm:ss");
    /**获取当前时间*/
    var curDate = new Date(new Date());
    // String dataStrNew= sdf.format(curDate);
    // Date startTime=null;
    // try {
    //     /**将时间转化成Date*/
    //     curDate=sdf.parse(dataStrNew);
    //     startTime = sdf.parse(mTime);
    // } catch ( e) {
    //     return "";
    // }
    /**除以1000是为了转换成秒*/

    var elapsedTime = (curDate.getTime() / 1000 - mTime);

    if (elapsedTime < seconds_of_1minute) {
        return "刚刚";
    }
    if (elapsedTime < seconds_of_1hour) {

        return parseInt(elapsedTime / seconds_of_1minute) + "分钟前";
    }
    if (elapsedTime < seconds_of_1day) {

        return parseInt(elapsedTime / seconds_of_1hour) + "小时前";
    }

    if (elapsedTime < seconds_of_month) {

        return parseInt(elapsedTime / seconds_of_1day) + "天前";
    }

    return parseInt(elapsedTime / seconds_of_month) + "月前";

    return mTime;
}

//获取某一天开始时间戳
function startUnix($date) {
    return (new Date(Date.parse($date.replace(/-/g, "/")))).getTime() / 1000;
}

//获取某一天结束时间戳
function endUnix($date) {
    return new Date().setTime(Date.parse($date.replace(/-/g, "/")) / 1000 + 24 * 60 * 60 - 1);
}

//获取某月最后一天
function getLastDay(year, month) {
    var dt = new Date(year, month - 1, '01');
    dt.setDate(1);
    dt.setMonth(dt.getMonth() + 1);
    var cdt = new Date(dt.getTime() - 1000 * 60 * 60 * 24);
    return cdt.getDate();
}


//获取现在年和月
function getCurrentYearMonty() {
    var date = new Date();
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate = Y + M + D;
    return currentDate;
}


//获取现在年和月
function getCurrentYM() {
    var date = new Date();
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var currentDate = Y + M;
    return currentDate;
}

//获取现在年和月不带日
function getCurrentYearMonth() {
    var date = new Date();
    //年
    var Y = date.getFullYear() + '/';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate = Y + M;
    return currentDate;
}

//获取明天的年月日
function getCurrentYearMontyNextDay() {
    var date = new Date(new Date());
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1;
    var currentDate = Y + M + D;
    return currentDate;
}


/**
 * 日期转换成时间戳
 */
function getConversionTime(str) {
    var date = new Date(Date.parse(str.replace(/-/g, "/")));
    var time = date.getTime() / 1000;
    return parseInt(time);
}


/**
 * 日期转date
 */
function getToDate(str) {
    var date = new Date(Date.parse(str.replace(/-/g, "/")));
    return date;
}


//获取某月的开始时间结束时间戳
function computeTime(year, month) {
    return [
        new Date(year, month - 1, 1).getTime(),
        new Date(year, month, 0).getTime()
    ]
}
//获取某月的开始时间00:00:00 结束时间戳 23:59:59
function computeTimeEnd(year, month) {
    // console.log(888,new Date(year, month, 0).getTime())
    return [
        new Date(year, month - 1, 1).getTime(),
        new Date(year, month, 0).getTime()+86399*1000
    ]
}


/**
 * 获取下一个月的日期时间戳
 */
function getLastmonth(date, num) {
    var date = new Date(Date.parse(date.replace(/-/g, "/")));
    date.setMonth((date.getMonth()) + num);
    date = date.getTime() / 1000;
    return parseInt(date);
}


/**
 * 获取现在的时间戳
 */
function getCurrentTime() {
    var date = new Date(new Date());
    return date.getTime();
}

/**
 * 获取多少天以前的时间戳
 */
function getDayTime(day) {
    var date = new Date(new Date());
    date.setDate(date.getDate() - day);
    // data.setData(date.getDate()-day)
    date = parseInt(date.getTime() / 1000);
    return date;
}


/**
 * 获取多少天之后的日期 格式：2002-09-11
 */
function getDayDate(day) {
    var date = new Date(new Date());
    date.setDate(date.getDate() + day);
    // data.setData(date.getDate()-day)
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate = Y + M + D;
    return currentDate;
}




//时间戳转换成日期
function getConversionDate(str) {
    if (str == 0) {
        return "-";
    }
    var date = new Date(str * 1000);
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate = Y + M + D;
    return currentDate;
}
//时间戳转换成月日
function getConversionDay(str) {
    if (str == 0) {
        return "-";
    }
    var date = new Date(str * 1000);
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate =  M + D;
    return currentDate;
}


//时间戳转换成年份
function getConversionYear(str) {
    var date = new Date();
    //年
    var Y = date.getFullYear() + '/';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate = Y + M + D;
    return currentDate;
}

//时间戳转换成时间 带时分秒
function getTime(str) {
    var date = new Date(str * 1000);
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":";
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    var currentDate = Y + M + D + " " + hh + mm + ss;
    return currentDate;
}




//时间戳转化为年月
function getyearAndmonth(str) {
    var date = new Date(str * 1000);
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);

    var currentDate = Y + M;
    return currentDate;
}

// //月份转化为月份的一号时间戳，和下一月的时间戳
// function getfirstTimeandEndtime(yearAndMonth) {
//     var date = new Date(yearAndMonth),
//         startYear = date.getFullYear(),
//         endYear = date.getFullYear(),
//         startMonth = date.getMonth() + 1 > 12 ? 1:date.getMonth() + 1,
//         endMonth = date.getMonth() + 2 > 12 ? 1:date.getMonth() + 2,
//         obj = {};
//         startYear =  date.getMonth() + 1 > 12 ?startYear +1 :startYear
//         endYear = date.getMonth() + 2 > 12 ? endYear + 1 :endYear

//     obj.startTime = startYear + '-' + stringand0(startMonth) + '-01' + ' ' + '00:00:00';
//     obj.endTime = endYear + '-' + stringand0(endMonth) + '-01' + ' ' + '00:00:00';
//     var dateDay = new Date(endYear + '-' + stringand0(endMonth) + '-01' + ' ' + '00:00:00') - (8 * 60 * 60 * 1000),
//         Day = DD(dateDay);
//     obj.startYYMMDD = startYear + '-' + stringand0(startMonth) + '-01';
//     obj.endYYMMDD = endYear + '-' + stringand0(startMonth) + '-' + Day;
    
//     return obj
// }

//时间戳转换为日
function DD(str) {
    var date = new Date(str),
        day = stringand0(date.getDate());
    return day
}

//小于10带0
function stringand0(str) {
    return str < 10 ? '0' + str : str
}

//获取今天的时间戳转化为年月
function getTimeChangeYearandMonth() {
    var date = new Date(),
        Y = date.getFullYear() + '-',
        M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
        currentDate = Y + M;
    return currentDate;
}

//获取今天的年月日
function getTimeYMD() {
    var date = new Date(),
        Y = date.getFullYear() + '-',
        M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)+ '-',
        D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(), //日
        currentDate = Y + M + D;
    return currentDate;
}





//时间戳转换成时间 格式为 01/11 16:02
function getMonthDate(str) {
    var date = new Date(str * 1000);
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    var currentDate = M + D + "\t" + hh + mm;
    return currentDate;
}

//时间戳转换成时间 格式为 01-11 16:02
function getMonthDateText(str) {
    var date = new Date(str * 1000);
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    var currentDate = M + D + "\t" + hh + mm;
    return currentDate;
}


//时间戳转换成时间 格式为 01/11
function getMD(str) {
    var date = new Date(str * 1000);
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate = M + D + "\t\t\t";
    return currentDate;
}


//获取1-14天的日期
function getDayList(time) {
    var list = [];
    // 获取当前时间
    var timestamp = Date.parse(new Date());
    timestamp = timestamp / 1000;
    var n = timestamp * 1000;
    var date = new Date(n);

    for (var i = 0; i < 14; i++) {
        var timeData = data;
        if (i > 0) {
            date.setDate(date.getDate() - 1)
        }
        if (i == 0) {
            timeData.rightShow = false;
        } else {
            timeData.rightShow = true;
        }
        if (i == 13) {
            timeData.leftShow = false;
        } else {
            timeData.leftShow = true;
        }

        //年
        var Y = date.getFullYear();
        //月
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        //日
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var time = Y + "-" + M + "-" + D;


        timeData.year = Y;
        timeData.month = M;
        timeData.day = D;
        timeData.date = time;
        timeData.showText = M + "/" + D;

        // timeData.day = D;
        list.splice(0, 0, timeData);

    }
    return list;
}


//获取1-8周的日期
function getWeekList() {
    var weekList = [];
    // 获取当前时间
    var timestamp = Date.parse(new Date());
    timestamp = timestamp / 1000;
    var n = timestamp * 1000;
    var date = new Date(n);
    var size = 8;
    for (var i = 0; i < 8; i++) {
        var timeData = {
            year: 0,
            month: 0,
            week: 0,
            day: 0,
            date: "",
            rank: "",
            setp: "",
            showText: "",
            reachStandard: 0,
            leftShow: true,
            rightShow: true,
            medal: 0,
            isSelcted: false,
            id: 0

        };
        if (i > 0) {
            date.setDate(date.getDate() - 7)
        }
        if (i == 0) {
            timeData.rightShow = false;
        } else {
            timeData.rightShow = true;
        }
        if (i == 7) {
            timeData.leftShow = false;
        } else {
            timeData.leftShow = true;
        }

        //年
        var Y = date.getFullYear();
        //月
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        //日
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var time = Y + "-" + M + "-" + D;
        var week = getWeek(time);


        timeData.year = Y;
        timeData.month = M;
        timeData.week = week;
        timeData.showText = week + "周";
        timeData.id = (size - 1) - i;

        // timeData.day = D;
        weekList.splice(0, 0, timeData);

    }


    return weekList;

}

//获取1-12月的日期
function getMonthList() {
    var list = [];
    // 获取当前时间
    var timestamp = Date.parse(new Date());
    timestamp = timestamp / 1000;
    var n = timestamp * 1000;
    var date = new Date(n);
    var size = 12;

    for (var i = 0; i < size; i++) {
        var timeData = {
            year: 0,
            month: 0,
            week: 0,
            day: 0,
            date: "",
            rank: "",
            setp: "",
            showText: "",
            reachStandard: 0,
            leftShow: true,
            rightShow: true,
            medal: 0,
            isSelcted: false,
            id: 0
        };
        if (i > 0) {
            date.setMonth((date.getMonth()) - 1)
        }
        if (i == 0) {
            timeData.rightShow = false;
        } else {
            timeData.rightShow = true;
        }
        if (i == 11) {
            timeData.leftShow = false;
        } else {
            timeData.leftShow = true;
        }

        //年
        var Y = date.getFullYear();
        //月
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        //日
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        // var time = Y + "-" + M + "-" + D;


        timeData.year = Y;
        timeData.month = M;
        timeData.showText = M + "月";
        timeData.id = (size - 1) - i;

        // timeData.day = D;
        list.splice(0, 0, timeData);

    }
    return list;

}

//获取1-7年的日期
function getYearList() {
    var list = [];
    // 获取当前时间
    var timestamp = Date.parse(new Date());
    timestamp = timestamp / 1000;
    var n = timestamp * 1000;
    var date = new Date(n);
    var size = 7;

    for (var i = 0; i < size; i++) {
        var timeData = {
            year: 0,
            month: 0,
            week: 0,
            day: 0,
            date: "",
            rank: "",
            setp: "",
            showText: "",
            reachStandard: 0,
            leftShow: true,
            rightShow: true,
            medal: 0,
            isSelcted: false,
            id: 0
        };
        if (i > 0) {
            date.setFullYear(date.getFullYear() - 1)
        }
        if (i == 0) {
            timeData.rightShow = false;
        } else {
            timeData.rightShow = true;
        }
        if (i == 6) {
            timeData.leftShow = false;
        } else {
            timeData.leftShow = true;
        }

        //年
        var Y = date.getFullYear();
        //月
        // var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
        //日
        // var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        // var time = Y + "-" + M + "-" + D;


        timeData.year = Y;
        // timeData.month = M;
        timeData.showText = Y;
        timeData.id = (size - 1) - i;

        // timeData.day = D;
        list.splice(0, 0, timeData);

    }
    return list;
}


//根据时间返回第几周   如：2017-09-09
function getWeek(time) {
    var time, week, checkDate = new Date(time);
    checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7));
    time = checkDate.getTime();
    checkDate.setMonth(0);
    checkDate.setDate(1);
    week = Math.floor(Math.round((time - checkDate) / 86400000) / 7) + 1;
    return week;
}

function daysAgo(num) {
    var timeStamp = new Date(new Date().setHours(0, 0, 0, 0)) / 1000;
    // 一天是86400秒   故 7 天前的时间戳为
    // var DayAgo = timeStamp - 86400 * num;
    return timeStamp - 86400 * num;
}

//今日以及今日过后的时间戳
function daysAfter(num) {
    var timeStamp = new Date(new Date().setHours(0, 0, 0, 0)) / 1000;
    // 一天是86400秒   故 7 天前的时间戳为
    var DayAfter = timeStamp + 86400 * num;
    var obj = {};
    obj.timeStamp = timeStamp;
    obj.DayAfter = DayAfter;
    return obj;
}


function lastTime() {
    var d = new Date();
    var todayYear =d.getFullYear();
    var todayMonth = d.getMonth();
    var todayDay = d.getDate();
    var todayTime = (new Date(todayYear, todayMonth, todayDay, '23', '59', '59')).getTime();//毫秒
    return todayTime / 1000;
}

/**
 * 获取一年前的时间戳
 */
function preYearTime() {
    return computeTime(getTime(getLastmonth(getCurrentYearMonty(), -12)).split('-')[0], getTime(getLastmonth(getCurrentYearMonty(), -12)).split('-')[1])[0] / 1000
}

function xlsxFormatDate(numb, format) {
    if(numb){
    let time = new Date((numb - 1) * 24 * 3600000 + 1);
    time.setYear(time.getFullYear() - 70);
    let year = time.getFullYear() + '-';
    let month = time.getMonth() + 1;
    let date = time.getDate() + '';
    return year + (month < 10 ? '0' + month : month)+'-' + (date < 10 ? '0' + date : date)
    }else {
        return ''
    }
    
}

//时间戳转换成日期
function getDateText(str) {
    if (str == 0) {
        return "-";
    }
    var date = new Date(str * 1000);
    //年
    var Y = date.getFullYear() + '年';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate = Y + M + D + "日";
    return currentDate;
}


/**
 * 获取天数的时间戳
 * @param day
 * @returns {number}
 */
function getTimeDay(day) {
    return day * 86400;
}

//返回剩余天数
function getTimeOut(time, day) {
    // debugger
    if (time > getTimeDay(day)) { //大于设置的天数，返回-1
        return -1;
    }
    for (var i = day; i >= 0; i--) {//小于设置的天数，返回天数

        if (time > getTimeDay(i)) {
            return i + 1;
        }
    }


}


/**
 * 把日期转换成月份
 *
 */
function getConversionMonth(str) {
    var date = new Date(Date.parse(str.replace(/-/g, "/")));

    var M = (date.getMonth() + 1) + '月';

    return M;
}


//获取预约的日期
function getAgreeData(num) {
    var date = new Date();
    date.setDate(date.getDate()+num);
    //月
    var M = date.getMonth() + 1;
    //日
    var D = date.getDate();
    var currentDate = M +"-"+ D;
    return currentDate;
}




//获取指定日期
function getNumToDate(num) {
    var date = new Date();
    if(num != null && num !== 0){
        date.setDate(date.getDate()+num);
    }
    
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var currentDate = Y + M + D ;
    return currentDate;
}



//获取指定月和日
function getMDToDate(num) {
    var date = new Date();
    date.setDate(date.getDate()+num);

    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return M + D ;
}

//获取预约的日期
function getAgreeDataList(num) {
    var date = new Date();
    date.setDate(date.getDate()+num);
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = date.getMonth() + 1+"-";
    //日
    var D = date.getDate();
    var currentDate = Y + M + D;
    var md = M + D;
    return {date:currentDate,md:md}
}


//获取月份天数
function getNumDay(dayDate){
    if(dayDate == null){return 0}
    let dateList = dayDate.split("-");
    return parseInt(dateList[2]); //本月第几天
}
//获取当前月最近十二个月的月份
function getRecent12(){
    var dataArr1 = [];
    var data1 = new Date();
    var year1 = data1.getFullYear();
    data1.setMonth(data1.getMonth()+1, 1)//获取到当前月份,设置月份
    for (var i = 0; i < 7; i++) {
        data1.setMonth(data1.getMonth() - 1);//每次循环一次 月份值减1
        var m1 = data1.getMonth() + 1;
        m1 = m1 < 10 ? "0" + m1 : m1;
        dataArr1.unshift(data1.getFullYear() + "-" + (m1))
    }
        var dataArr2 = [];
        var data2 = new Date();
        var year2 = data2.getFullYear();
        data2.setMonth(data2.getMonth() , 1); //获取到当前月份,设置月份
        for (var i = 0; i < 6; i++) {
            data2.setMonth(data2.getMonth() + 1); //每次循环一次 月份值减1
            var m2 = data2.getMonth() + 1;
            m2 = m2 < 10 ? "0" + m2 : m2;
            dataArr1.push(data2.getFullYear() + "-" + m2);
        }
        // dataArr1.push(dataArr2)
        return dataArr1
}
//获取当前月之后十二个月的月份
function getRecentAfter12(date){
        var dataArr2 = [];
        var data2 = new Date(date);
        var year2 = data2.getFullYear();
        data2.setMonth(data2.getMonth() , 1); //获取到当前月份,设置月份
        for (var i = 0; i < 12; i++) {
            data2.setMonth(data2.getMonth() + 1); //每次循环一次 月份值减1
            var m2 = data2.getMonth() + 1;
            m2 = m2 < 10 ? "0" + m2 : m2;
            dataArr2.push({
                date:data2.getFullYear() + "-" + m2,
                _check:false,
                _beginTime:new Date(getConversionTime(getfirstTimeandEndtime(data2.getFullYear() + "-" + m2).startTime)*1000),
                _overTime:new Date(getConversionTime(getfirstTimeandEndtime(data2.getFullYear() + "-" + m2).endTime)*1000),

        });
        }
        return dataArr2
}


//时间戳转换成时间 带时分秒
function getTimeYMDHM(str) {
    var date = new Date(str * 1000);
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    var currentDate = Y + M + D + " " + hh + mm ;
    return currentDate;
}



//时间戳转换成月份
function getTimeM(str) {
    var date = new Date(str * 1000);
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) ;
    return M;
}



//获取时、分
function getHM(time) {
    let date = new Date(time*1000);
    let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    return hh + ":"+ mm;
}
  
/**
 * 获取今天的开始时间戳 和 结束时间戳
 */
function getToDayTime(num) {
    let d = new Date();
    d.setDate(d.getDate() + num);
     //年  
     var Y = d.getFullYear()
     //月  
     var M = (d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1)
     //日  
     var D = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
     var time = Y + "-" + M + "-" + D;
    
   return getConversionTime(time);
  }

  
//获取时间对象
function getConTimeObj(str) {
    var date;
    if(str){
        date = new Date(str * 1000);
    }else{
        date = new Date();
    }
    //年
    var Y = date.getFullYear() + '-';
    //月
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    //日
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();

    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":";
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    var currentDate = Y + M + D + " " + hh + mm + ss;
    var currentDateYMD = Y + M + D;
    
    return {
        currentTime:parseInt(date.getTime() / 1000),
        currentDate:currentDate,
        currentDateYMD:currentDateYMD
    };
}


function getFullDate(targetDate) {
    var D, y, m, d;
    if (targetDate) {
        D = new Date(targetDate);
        y = D.getFullYear();
        m = D.getMonth() + 1;
        d = D.getDate();
    } else {
        // y = fullYear;
        // m = month;
        // d = date;
    }
    m = m > 9 ? m : '0' + m;
    d = d > 9 ? d : '0' + d;
    return y + '-' + m + '-' + d;
  };
  
  //月份转化为月份的一号时间戳，和下一月的时间戳
  function getfirstTimeandEndtime(yearAndMonth) {
    var nowDate ;
    var cloneNowDate;
    if(yearAndMonth){
        nowDate = new Date(yearAndMonth);
        cloneNowDate = new Date(yearAndMonth);
    }else{
        nowDate = new Date();
        cloneNowDate = new Date();
    }
    var fullYear = nowDate.getFullYear();
    var month = nowDate.getMonth() + 1; // getMonth 方法返回 0-11，代表1-12月
    var endOfMonth = new Date(fullYear, month, 0).getDate(); // 获取本月最后一天
   
    var starDate = getFullDate(cloneNowDate.setDate(1));//当月第一天
    var endDate = getFullDate(cloneNowDate.setDate(endOfMonth));//当月最后一天
      
      var obj = {};
      obj.startYYMMDD = starDate ;
      obj.endYYMMDD = endDate ;
      obj.startTime = starDate+ ' ' + '00:00:00'
      obj.endTime =endDate+" "+ '23:59:59'
      obj.startTimeNum =getConversionTime( obj.startTime );
      obj.endTimeNum =getConversionTime( obj.endTime );

     return obj
  }

  

module.exports = {
    getConversionTime: getConversionTime,
    getTime: getTime,
    computeTime: computeTime,
    getDayList: getDayList,
    getWeekList: getWeekList,
    getMonthList: getMonthList,
    getYearList: getYearList,
    getWeek: getWeek,
    getConversionDate: getConversionDate,
    getLastmonth: getLastmonth,
    getCurrentYearMonty: getCurrentYearMonty,
    getCurrentYM: getCurrentYM,
    getCurrentTime: getCurrentTime,
    getDayTime: getDayTime,
    getMonthDate: getMonthDate,
    getMonthDateText: getMonthDateText,
    daysAgo: daysAgo,
    getCurrentYearMontyNextDay: getCurrentYearMontyNextDay,
    getTimeRange: getTimeRange,
    startUnix: startUnix,
    endUnix: endUnix,
    lastTime: lastTime,
    getLastDay: getLastDay,
    preYearTime: preYearTime,
    getMD: getMD,
    xlsxFormatDate: xlsxFormatDate,
    getyearAndmonth: getyearAndmonth,
    getDateText: getDateText,
    getTimeChangeYearandMonth: getTimeChangeYearandMonth,
    getfirstTimeandEndtime: getfirstTimeandEndtime,
    getTimeOut: getTimeOut,
    getConversionMonth: getConversionMonth,
    getConversionYear: getConversionYear,
    daysAfter: daysAfter,
    getConversionDay:getConversionDay,
    getAgreeData:getAgreeData,
    getNumToDate:getNumToDate,
    getDayDate:getDayDate,
    getAgreeDataList:getAgreeDataList,
    getToDate:getToDate,
    getNumDay:getNumDay,
    getCurrentYearMonth:getCurrentYearMonth,
    getTimeDay:getTimeDay,
    getRecent12:getRecent12,
    getRecentAfter12:getRecentAfter12,
    computeTimeEnd,
    getTimeYMD,
    getHM,
    getTimeYMDHM,
    getMDToDate,
    getTimeM,
    getToDayTime,
    getConTimeObj
};


