import React from 'react';
import {Input} from 'antd';

// import EditableCell from './EditableCell'


export default class iInputString extends React.Component {
    constructor() {
        super();
    }

    onChange(e) {
        // ////console.log("22312312",e);
        // debugger;
        //修复第一个字符是小数点 的情况.
        if (e.target.value != '' && e.target.value.substr(0, 1) == '.') {
            e.target.value = "";
        }


        var text = e.target.value;
        // 去掉转义字符
        // text = text.replace(/[\'\"\\\/\b\f\n\r\t]/g, '');
        // 去掉特殊字符
        text = text.replace(/[\%\#\$\^\&\*\(\)\{\}\:\"\<\>\?\[\]\-]/, '');
        text = text.replace(/[\+]/, '＋');

        // e.target.value = e.arget.value.replace(/^0*(0\.|[1-9])/, '$1');//解决 粘贴不生效
        // e.target.value = e.target.value.replace(/^[`~!@#$^&*()=|{}':;',\\[\\].<>]*$/g, "");  //清除“数字”和“.”以外的字符
        // e.target.value = e.target.value.replace(/[^\u4E00-\u9FA5\uF900-\uFA2D\d]/g, "");  //清除“数字”和汉子“.”以外的字符

        // 去掉转义字符
        // e.target.value = e.target.value.replace(/[\'\"\\\/\b\f\n\r\t]/g, '');
        // e.target.value = e.target.value.replace(/[^\u4e00-\u9fa5a-zA-Z0-9\w]/g,'')
        // 去掉特殊字符
        // e.target.value = e.target.value.replace(/[\%]/, '');
        // e.target.value = e.target.value.replace(/[\@\#\$\%\^\&\+\*\(\)\{\}\:\"\L\<\>\?\[\]]/, '');
        if (this.props.onChange) {
            this.props.onChange(text)
        } else {
            this.props.onCallback(text, this.props.data, this.props.tag)
        }

    }

    render() {
        return (
            <Input 
                disabled={this.props.disabled} 
                type="text" 
                style={this.props.style} 
                value={this.props.value}
                onChange={this.onChange.bind(this)}
                className={this.props.className}
                onFocus={this.props.onFocus}
                maxLength={this.props.maxLength}
                defaultValue={this.props.defaultValue}
                placeholder={this.props.placeholder}
                onClick={this.props.onClick}
                rows={this.props.rows?this.props.rows:1}/>
        );
    }
}
