import React from 'react'
import { Button,Tabs ,Select,Popconfirm } from 'antd'
import IButton from '../../../components/IButton'
import IDrawer from '../../../components/iDrawer'
import { IModal } from '../../../components/iModal'
import InputString from '../../../components/iInputString'
import InputMoney from '../../../components/iInputMoney'
import StorageTools from "../../../comm/storageTools";
import { $mzlApi } from "../../../comm/mzl-core";
import { messageError, messageSuccess, numberConvert_100, numberConvert, showMessageError, filterByName, showLoadding, hideLoadding, numConvert } from '../../../comm/utils';
import ITablePage, { getPageSize, setTabTop } from '../../../components/iTablePage'
import '../../../../css/report.css'
import APINames from "../../../comm/api-names"
import Editor from "react-umeditor";
import { FnMenuId } from "../../../comm/constans"
import { setDefaultTitle } from "../../../reduxs/reducers/iconRedux";
import { connect } from 'react-redux'
import { http } from '../../../api/yqHttp'

import ITableList from '@components/iTableList'
import Detail from "./detail";

import TitleView from '../../../components/titleView'

import {getConversionDate,getTime,getfirstTimeandEndtime } from '@comm/dateHelp';
import service from '../../../services/marketing/bonusService'

let begin = 0;
let over = 20;
const { TabPane } = Tabs;
const { Option } = Select;
/**
 * 分红结算
 */
class Index extends React.Component {
    constructor() {
        super();
        this.state = {
          
            titleViewData:[{
              type:"DatePickerMonth",
              onChange:(res,data)=>{
                console.log(11112,res,data)
                begin = 0;
                over = 20;
                this.setState({
                  beginTime:res.startTimeNum,
                  overTime:res.endTimeNum,
                },function(){
                  this.getPageData();
                })
              },
            },
            {
              type:"Select",
              lable:"",
              onChange:(res)=>{
                console.log(11112,res)
              },
              list:[
                {
                  value:"0",
                  lable:"全部"
                },
                {
                  value:"1",
                  lable:"未结算"
                },
                {
                  value:"2",
                  lable:"待确定"
                },
                {
                  value:"3",
                  lable:"已结算"
                },
              ]
            },
            {
              type:"Search",
              placeholder:"手机姓名搜索",
              lable:"搜索",
              direction:"right",
              onSearch:(res)=>{
                begin = 0;
                over = 20;
                this.getPageData(res);
              },
            }
          ],
            content: "",
            detailData:{},
            totalSize:0,
            columns : [
                {
                  width:80,
                  title: '序号',
                  dataIndex: 'name',
                  align: 'center',
                  render: (text,record,index) => <span>{index+begin+1}</span>,
                },
                {
                  title: '股东姓名',
                  align: 'center',
                  dataIndex: 'UserName',
                },
                {
                  title: '手机',
                  align: 'center',
                  dataIndex: 'UserPhone',
                },
                {
                  title: '自拓分红',
                  align: 'center',
                  render: (text, data) => (
                    <a onClick={this.onDetail.bind(this, 1, data)}>{data.UserDividendsAmount}</a>
                  )
                },
                {
                  title: '下级分红',
                  align: 'center',
                  render: (text, data) => (
                    <a onClick={this.onDetail.bind(this, 2, data)}>{data.SubordinateDividendsAmount}</a>
                  )
                },
                {
                  title: '分红总额',
                  align: 'center',
                  dataIndex: '_allAmount',
                },
                {
                  title: '结算状态',
                  align: 'center',
                  dataIndex: '_stateText',
                },
                {
                  title: '结算',
                  align: 'center',
                  width:120,
                  render: (text,data) => (
                      <div className="comm-flex-row-center">
                          {
                            data.State===1 && data._isOK==null?(
                              <Popconfirm
                                placement="topRight"
                                title={"是否已汇款结算？"}
                                onConfirm={()=>{
                                  this.onOK(this,data)
                                }}
                                okText="已汇款"
                                cancelText="未汇款"
                              >
                                <a >结算</a>
                              </Popconfirm>
                            ):"-"
                          }
                          
                          
                         
                          
                      </div>
                  ),
                }
              ],
            tableData:[],
            detailVisible:false,
        }
    }
    componentWillMount() {

    }
    
    
    componentDidMount() {
      begin = 0;
      over = 20;
      this.getPageData();
    }

    getPageData(keyword){
      let opt = { Begin: begin, Over: over,Opt:{beginTime:this.state.beginTime, overTime:this.state.overTime ,keyword,}};
      let dateObj = getfirstTimeandEndtime();
      let startTimeNum = dateObj.startTimeNum;
      let endTimeNum = dateObj.endTimeNum;
      // debugger
      service.queryShUserDividendsBill(this, opt).then(res => {
        let data = JSON.parse(res.Data);
        data.forEach(item => {
          if(startTimeNum< item.CreateTime && endTimeNum>item.CreateTime){
            item._isOK = true
          }
          

          item._OrderTime = getTime(item.OrderTime);
          item._allAmount = (item.UserDividendsAmount+item.SubordinateDividendsAmount)/100
          item.UserDividendsAmount = item.UserDividendsAmount/100;
          item.SubordinateDividendsAmount = item.SubordinateDividendsAmount/100;
          // 1 待结算 2 代确认 3 已结算
          if(item.State ===1){
            item._stateText = "待结算";
          }else if(item.State ===2){
            item._stateText = "待确定";
          }else if(item.State ===3){
            item._stateText = "已结算";
          }
        })
        this.setState({
          tableData: data,
          totalSize:res.Count
        })
      })
    }

    
    onTablePageCallback(start, end){
      begin = start;
      over = end;
      this.getPageData();
    }

    onBtn(){

    }

    onDetail(flag,data){
      this.setState({
        detailVisible:true,
        detailData:data,
        type:flag,
      })
        
    }
    onOK(data){
      service.markUserBill(this,data.UserId,data.Id,2).then(res=>{
        this.getPageData();
      })
    }

    onClickListener(){

    }

    handleChange(){

    }
    render() {
      const {detailData} = this.state;
        return (
            <div>
                 <TitleView titleViewData={this.state.titleViewData}></TitleView>


                <ITableList 
                     style={{marginTop:10,marginBottom:20}}
                     pagination={true}
                     top={260}
                     columns={this.state.columns} 
                     data={this.state.tableData}
                     totalSize={this.state.totalSize}
                     onTablePageCallback={this.onTablePageCallback.bind(this)}>

                </ITableList>
                {
                  this.state.detailVisible?
                  (
                    <Detail 
                  type={this.state.type}
                  onCancel={()=>{
                    this.setState({
                      detailVisible:false
                    })
                  }} 
                  visible={this.state.detailVisible} 
                  detailData={this.state.detailData}>

                  </Detail>
                  ):null
                }
                
                
                
            </div>



        )
    }
}

export default Index