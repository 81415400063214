import React from 'react'
import { Button, Tabs, Select } from 'antd'
import IButton from '../../../components/IButton'
import IDrawer from '../../../components/iDrawer'
import { IModal } from '../../../components/iModal'
import InputString from '../../../components/iInputString'
import InputMoney from '../../../components/iInputMoney'
import StorageTools from "../../../comm/storageTools";
import { $mzlApi } from "../../../comm/mzl-core";
import { messageError, messageSuccess, numberConvert_100, numberConvert, showMessageError, filterByName, showLoadding, hideLoadding, numConvert } from '../../../comm/utils';
import ITablePage, { getPageSize, setTabTop } from '../../../components/iTablePage'
import '../../../../css/report.css'
import APINames from "../../../comm/api-names"
import Editor from "react-umeditor";
import { FnMenuId } from "../../../comm/constans"
import { setDefaultTitle } from "../../../reduxs/reducers/iconRedux";
import { connect } from 'react-redux'
import { http } from '../../../api/yqHttp'

import ITableList from '@components/iTableList'
import Detail from "./detail";
import ShareholderListModel from "./shareholderListModel";
import CustomerListDetail from "../customerList/detail";
import BonusDetail from "../bonusSetting/detail";

import TitleView from '../../../components/titleView'


import { getConversionDate, getTime } from '@comm/dateHelp';
import service from '../../../services/marketing/bonusService'
let begin = 0;
let over = 20;
const { TabPane } = Tabs;
const { Option } = Select;
/**
 * 股东列表
 */
class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      content: "",
      detailData: {},
      totalSize: 0,
      columns: [
        {
          width: 80,
          title: '序号',
          dataIndex: 'name',
          align: 'center',
          render: (text, record, index) => <span>{index + begin + 1}</span>,
        },
        {
          title: '股东姓名',
          align: 'center',
          // dataIndex: 'Name',
          render: (text, data) => (
            <a onClick={this.onDetail.bind(this, 2, data)}>{data.Name}</a>
          )
        },
        {
          title: '上级股东',
          align: 'center',
          dataIndex: 'ParentUserName',
        },
        {
          title: '手机',
          align: 'center',
          dataIndex: 'Phone',
        },
        {
          title: '分红活动',
          align: 'center',
          render: (text, data) => (
            <a onClick={this.onDetail.bind(this, 3, data)}>{data.PlanName}</a>
          )
        },
        {
          title: '加入时间',
          align: 'center',
          dataIndex: '_CreateTime',
        },
        {
          title: '股东到期时间',
          align: 'center',
          dataIndex: '_ExpiresTime',
        },
        {
          title: '下级股东',
          align: 'center',
          dataIndex: 'SubordinateUserCount',
          render: (text, data) => (
            <a onClick={this.onDetail.bind(this, 0, data)}>{text}</a>
          )
        },
        {
          title: '拓展新客',
          align: 'center',
          dataIndex: 'CustomerCount',
          render: (text, data) => 
            <a onClick={this.onDetail.bind(this, 1, data)}>{text}</a>
          
        }
      ],
      tableData: [],
      detailVisible: false,
      listVisible: false,
      customerListDetailVisible: false,
      bonusDetailVisible: false,
      planId:0,
      parentUserId:0,
      titleViewData:[{
        type:"Select",
        lable:"分红活动",
        onChange:(res)=>{
          this.setState({
            planId:res
          },()=>this.getPageData())
          // console.log(11112,res)
        },
        list:[
          {
            value:"0",
            lable:"全部"
          },
        ]
      },{
        type:"Select",
        lable:"上级股东",
        onChange:(res)=>{
          this.setState({
            parentUserId:res
          },()=>this.getPageData())
        },
        list:[
          {
            value:"0",
            lable:"全部"
          },
        ]
      }],
    }
  }
  componentWillMount() {
  }


  componentDidMount() {
    this.getPageData();
    this.queryShPlan();

  }


  getPageData() {
    const {planId,parentUserId,titleViewData} = this.state;
    let opt = { Begin: 0, Over: 9999,Opt:{planId,parentUserId}};
    // map.set("opt", JSON.stringify({ Begin: begin, Over: over,Opt:{beginTime, overTime ,keyword,createType ,state ,applicationTypeId}}));
    // let time = new  Date().getTime()/1000;
    service.queryShUser(this, opt).then(res => {
      let data = JSON.parse(res.Data);
      data.forEach(item => {
        if(!this.state.isSelect){

          if(item.ParentUserId != 0){
            let obj = {};
            obj.value = item.ParentUserId;
            obj.lable = item.ParentUserName;

            let isPush = titleViewData[1].list.some(ele=>{
              return ele.value == item.ParentUserId;
            })
            if(!isPush){
              titleViewData[1].list.push(obj);
            }

          }
        }
        if(item.ParentUserName == ""){
          item.ParentUserName = '-';
        }
        if(item.PlanName == ""){
          item.PlanName = '-';
        }
        if(item.AliNo == ""){
          item.AliNo = '-';
        }
        if(item.WxNo == ""){
          item.WxNo = '-';
        }
        if(item.BankAcName == ""){
          item.BankAcName = '-';
        }
        if(item.BankCardNo == ""){
          item.BankCardNo = '-';
        }
        if(item.ExpiresTime === 0){
          item._ExpiresTime = "永久有效"
        }else{
          item._ExpiresTime = getConversionDate(item.ExpiresTime);
        }
        item._CreateTime = getConversionDate(item.CreateTime);
      })
      this.setState({
        tableData: data,
        totalSize:res.Count,
        titleViewData,
        isSelect:true
      })
    })
  }

  
  queryShPlan(){
    const {titleViewData} = this.state;
    let opt ={ Begin: 0, Over: 9999,Opt:{enabled:0}};
    service.queryShPlan(this,opt).then(res=>{
      let data = JSON.parse(res.Data);
      data.forEach(item => {
        let obj = {};
        obj.value = item.Id;
        obj.lable = item.PlanName;
        titleViewData[0].list.push(obj);
      })
      this.setState({titleViewData});
    })
  }





 

  /**
   * 
   * @param {} flag  0 下级股东  1 新拓客 2股东信息  3股东活动
   * @param {*} data 
   */
  onDetail(flag, data) {
    if (flag === 0) {
      this.setState({
        listVisible: true,
        detailData: data
      })

    } else if (flag === 1) {
      this.setState({
        customerListDetailVisible: true,
        detailData: data
      })

    }else if (flag === 2) {
      this.setState({
        detailVisible: true,
        detailData: data
      })
    }else if (flag === 3) {
      service.getShPlan(this,data.PlanId).then(res=>{
        let data = JSON.parse(res.Data.data);
        // data._stateText = "已停止"
        this.setState({
          bonusDetailVisible: true,
          detailData: data
        })
      }
    );
      
    }

  }

  onTablePageCallback(start, end){
    // begin = start;
    //   over = end;
    //   this.getPageData();
  }

  onClickListener() {

  }

  handleChange() {

  }

  // seletePageData(){
  //   const {titleViewData,planId,parentUserId} = this.state;
  //   let arr = [];
  //   tableData.forEach(item=>{
  //     if(planId==0 || parentUserId==0){
  //       if(item.planId == planId){
  //         arr.push(item);
  //       }

  //     }else{
  //       arr.push(item);
  //     }
  //   })
  //   this.setState({
  //     tableData: data,
  //     titleViewData
  //   })
  // }

  render() {
    const { detailData } = this.state;
    return (
      <div>
        {/* <div className="comm-flex-row-between">
          <div className="comm-flex-row-center">
            状态:
            <Select defaultValue="0" style={{ width: 120, marginLeft: 8 }} onChange={this.handleChange.bind(this)}>
              <Option value="0">全部</Option>
              <Option value="1">进行中</Option>
              <Option value="2">已停止</Option>
              <Option value="3">为开始</Option>
            </Select>
          </div>
        </div> */}
        <TitleView titleViewData={this.state.titleViewData}></TitleView>

        <ITableList
          style={{ marginTop: 10, marginBottom: 20 }}
          pagination={true}
          top={260}
          columns={this.state.columns}
          data={this.state.tableData}
          totalSize={this.state.totalSize}
          onTablePageCallback={this.onTablePageCallback.bind(this)}>

        </ITableList>

        {
          this.state.detailVisible ? (

            <Detail
              onCancel={() => {
                this.setState({
                  detailVisible: false
                })
              }}
              visible={this.state.detailVisible}
              detailData={this.state.detailData}>

            </Detail>
          ) : null
        }
        {
          this.state.listVisible ? (

            <ShareholderListModel
              id={detailData.Id}
              onCancel={() => {
                this.setState({
                  listVisible: false
                })
              }}
              visible={this.state.listVisible}
              detailData={this.state.detailData}>

            </ShareholderListModel>
          ) : null
        }
        {
          this.state.customerListDetailVisible ? (

            <CustomerListDetail
              id={detailData.Id}
              onCancel={() => {
                this.setState({
                  customerListDetailVisible: false
                })
              }}
              visible={this.state.customerListDetailVisible}
              detailData={this.state.detailData} />
          ) : null
        }
        {
          this.state.bonusDetailVisible ? (

            <BonusDetail
              id={detailData.Id}
              onCancel={() => {
                this.setState({
                  bonusDetailVisible: false
                })
              }}
              visible={this.state.bonusDetailVisible}
              detailData={this.state.detailData} />
          ) : null
        }

      </div>



    )
  }
}

export default Index