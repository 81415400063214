import React from "react";
import "../../../../css/login.css"
import "../../../../css/table.css"
import "../../../../css/serviceItemsSetting.css"
import {Col, Form, Icon, Input, Modal, Popconfirm, Radio, Row,Select} from 'antd';
import {connect} from "react-redux";
import {setDefaultTitle} from "../../../../js/reduxs/reducers/iconRedux";
import APINames from "../../../../js/comm/api-names"
import {FnMenuId} from "../../../../js/comm/constans"
import IButton from "../../../../js/components/IButton";
import ServiceItemsSettingsModalView from "../setting/chaincomm/serviceModal";
import StorageTools from "../../../../js/comm/storageTools";
import {$mzlApi} from "../../../../js/comm/mzl-core";

import {EItemType, EPriceBasis} from "../../../../js/comm/iEnum";
import {hideLoadding, messageError, messageSuccess, numberConvert, showLoadding, showMessageError} from "../../../../js/comm/utils";
import ITablePage, {setTabTop,getPageSize} from "../../../../js/components/iTablePage";
import IDrawer from "../../../../js/components/iDrawer";

import "../../../../css/card.css"
import "../../../../css/vip.css"

const FormItem = Form.Item;
const Search = Input.Search;
const RadioGroup = Radio.Group;
const utils = require("../../../../js/comm/utils");
const iEnum = require("../../../../js/comm/iEnum");
const businessImpl = require("../../../../js/settings/businessImpl");
var h = document.documentElement.clientHeight * 0.73;
let begin = 0,
tabbegin = 0;
let over = getPageSize();

var deletedIds = [];
const Option = Select.Option;
let mchCode = '';
/**
 * 服务项目设置
 */
@connect(
    state => state.iconRedux,
    {setDefaultTitle}
)
class serviceItemsSettings extends React.Component {

    constructor() {
        super();
        this.state = {
            vipData: [],
            data: [],
            saveData: {},
            roleData: [],
            visible: false,
            selectedRowKeys: [],
            vipList: [],
            roleList: [],
            search: "",
            vipRequest: true,
            Enabled:0,
            Count:0,
            loading:false,
            disabledAll:false

        };
        this.columns = [{title: '序号', dataIndex: 'no', align: 'center'},
            {title: '服务名称', dataIndex: 'Title', align: 'center', className: "table-class-w-25"},
            {title: '成本', dataIndex: 'basicPrice', align: 'center'},
            {title: '门市价', dataIndex: 'Price', align: 'center'},
            {title: '会员售价', dataIndex: 'PriceBasisText', align: 'center',width:'15%'},
            {title: '状态', dataIndex: 'statusText', align: 'center'},
            {
                title: '操作', dataIndex: '', align: 'center',width:'20%',render: (text, record) => (
                    <div  style={{display:'flex',justifyContent:'space-around'}}>
                    <a onClick={this.stopUse.bind(this, record)}>{record.statusText == '已停用'?'启用':'停用'}</a>
                    <a onClick={this.editVipType.bind(this, record)}>编辑</a>
                    <a
                      onClick={this.deleteVip.bind(this, record, 1)}
                    >
                      删除
                    </a>
                    </div>
                  )
            }]
    }

    stopUse(record){
        let state = 1;
        if(record.Enabled == 1){
            state = 2
        }
        this.enabledMchManagerItem(record.Id,state)

    }
    editVipType(record){
        if (this.state.vipRequest) {
            messageError("会员数据还在加载中,请稍后编辑~")
        }
        // this.requestGetItem(data.ItemId);
        this.getMchManagerItem(record.Id)

        this.setState({
            saveData: record,
           
            drawerTitle: "编辑服务"
        });
    }
    deleteVip(record){
        let arr = [];
        arr.push(record.Id)
        this.deleteMchManagerItem(arr)
    }

    /**
     * 删除商品
     */
    deleteMchManagerItem(itemId) {
        var map = new Map();
        map.set('mchCode', mchCode);
        map.set('itemId', JSON.stringify(itemId));
        $mzlApi.post(APINames.API_deleteMchManagerItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }



     /**
     * 启用禁用服务
     * @param {*} id 
     * @param {*} state 
     */
    enabledMchManagerItem(id,state){
        showLoadding()
        var map =new Map();
        map.set('mchCode',mchCode);
        map.set('itemId',id);
        map.set('state',state)
        $mzlApi.post(APINames.API_enabledMchManagerItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    onSelectChange = (selectedRowKeys) => {
        //console.log("123123", selectedRowKeys)
        deletedIds = [];
        deletedIds = selectedRowKeys;
        this.setState({selectedRowKeys});
    };

    onChange(pagination, filters, sorter) {
        ////console.log('params', pagination, filters, sorter);
    }

    onSearch = (value) =>{
        this.state.keyword = value
        this.setState({keyword: this.state.keyword})
        // this.state.keyword = value
        this.queryMchManagerItem();

        // ////console.log("onSearch",index);
    }

    componentWillMount() {
        begin = 0
        tabbegin = 0
        mchCode = StorageTools.getMchCode()
        setTabTop(180);
        this.props.setDefaultTitle({titleKey: FnMenuId.head_chain_setting, slidingLeftKey: "1"})
    }

    componentDidMount() {
        this.requestCardType();
        this.queryMchManagerItem();
        // this.requestStoreRole();
    }


    onClickListener(tag, data, e) {
        ////console.log("点击事件：", tag, data, e)
        switch (tag) {
            case "add":
                //console.log(656654)
                if (this.state.vipRequest) {
                    messageError("会员数据还在加载中,请稍后添加~")
                }
                var data = {};
                data.CustomPrice = [];
                data.EmployeeAward = [];
                data.ItemTime = 30;
                data.Price = 0;
                data.Cost = 0;
                data.CostText=0
                data.Title = "";
                //会员
                data.PriceSettingsBasis = iEnum.EPriceBasis.Custom;
                data.PriceValue = 0;
                data.PriceValueText = 0;
                //提成
                data.AwardSettingsBasis = iEnum.ESettingsBasis.Global;
                data.AwardSettingsBasisSpc = iEnum.ESettingsBasis.Global;
                data.AwardBasis = iEnum.EAmountBasis.Percent;
                data.AwardBasisSpc = iEnum.EAmountBasis.Percent;
                data.AwardValue = 0;
                data.AwardValueSpc = 0;
                data.AwardValueText = 0;
                data.AwardValueSpcText = 0;
                data.AwardUcardValeu = 0;
                data.AwardUcardValeuSpc = 0;
                data._AwardUcardValeu = 0;
                data._AwardUcardValeuSpc = 0;
                // data.PriceBasis = iEnum.EPriceBasis.Discount;
                data.ProductType = EItemType.Virtual;

                this.serviceSetting(data);
                this.setState({
                    visible: true,
                    drawerTitle: "添加服务"
                });
                break;
            case "deleteAll"://多选删除
            
                let that = this;
                Modal.confirm({
                    okType: "default",
                    centered: true,
                    title: "确定删除所选商品/服务吗",
                    onOkColor: "white",
                    onOk: function () {
                        showLoadding();
                        let arr = [];
                        that.state.data.map((item,index)=>{
                            that.state.selectedRowKeys.map((i,num)=>{
                                if(index == i){
                                    arr.push(item.Id)
                                }
                            })
                        })
                        that.deleteMchManagerItem(arr)
                        that.setState({selectedRowKeys:[]})
                    },
                });
                break;
            case "edit"://编辑
            //console.log(data)
                if (this.state.vipRequest) {
                    messageError("会员数据还在加载中,请稍后编辑~")
                }
                // this.requestGetItem(data.ItemId);
                this.getMchManagerItem(data.ItemId)

                this.setState({
                    saveData: data,
                    visible: true,
                    drawerTitle: "编辑服务"
                });
                break;
            case "popconfirm-confirm"://气泡确认
                showLoadding();
                this.requestDeleteItem([data.ItemId]);
                break;
            case "popconfirm-cancel"://气泡取消

                break;
            case "drawer-ok"://对话框确认
                var temp = this.state.saveData;
                if (temp.Price <= 0) {
                    messageError("服务价格必须大于0元");
                    return;
                }
                //防止暴力点击
                if (!utils.getReqeustState()) {
                    return;
                }
                utils.setReqeustState(false);
                showLoadding();
                // temp.Title = temp.Title.replace(/[\+]/, '%20');
                // temp.Title = encodeURI(temp.Title);
                temp.CustomPrice = this.state.vipList;
                temp.EmployeeAward = this.state.roleList;
                temp.Cost = utils.numberConvert_100(temp.Cost);
                temp.Enabled = 1;
                this.saveMchManagerItem(temp);
                this.setState({disabledAll:false})
                break;

            case "drawer-cancel"://对话框取消
                this.setState({visible: false, saveData: {},disabledAll:false});
                break;

        }
    }

    // /**
    //  * 获取会员卡类型
    //  */
    // requestCardType() {
    //     var map = new Map();
    //     map.set('storeCode', StorageTools.getUserCode());
    //     map.set('kind', 1);
    //     map.set('opt', JSON.stringify({Begin: 0, Over: 9999}));
    //     $mzlApi.post(APINames.API_queryMchCardList, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    // }

     /**
   * 获取会员卡类型
   */
  requestCardType() {
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("kind", 1);
    map.set("opt", JSON.stringify({Opt:{storedValue:0,enabled:0,keyword:''},Begin: begin, Over: over}));
    $mzlApi.post(
      APINames.API_queryMchManagerCardList,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

    // /**
    //  * 查询商品
    //  */
    // requestQueryItem(search) {
    //     var map = new Map();
    //     map.set('storeCode', StorageTools.getUserCode());
    //     map.set('productType', EItemType.Virtual);
    //     map.set('opt', JSON.stringify({Begin: 0, Over: 9999, Opt: {"shelve_state": "3", "keyword": search}}));
    //     $mzlApi.post(APINames.API_queryItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    // }
     /**
     * 查询服务mchcode
     */
    queryMchManagerItem() {
        //console.log(1111,begin,over)
        var map = new Map();
        map.set('mchCode', mchCode);
        map.set('productType', EItemType.Virtual);
        map.set('opt', JSON.stringify({Begin: begin, Over: over, Opt: {enabled: this.state.Enabled, keyword: this.state.keyword}}));
        $mzlApi.post(APINames.API_queryMchManagerItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    /**
     * 查询岗位列表
     */
    requestStoreRole() {
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        $mzlApi.post(APINames.API_getStoreRole, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    // /**
    //  * 获取商品
    //  */
    // requestGetItem(itemId) {
    //     var map = new Map();
    //     map.set('storeCode', StorageTools.getUserCode());
    //     map.set('itemId', itemId);
    //     $mzlApi.post(APINames.API_getItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    // }

     /**
     * 获取商品mcd单个详细
     */
    getMchManagerItem(itemId) {
        var map = new Map();
        map.set('mchCode',mchCode);
        map.set('itemId', itemId);
        $mzlApi.post(APINames.API_getMchManagerItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    // /**
    //  * 保存商品
    //  */
    // requestSaveItem(data) {
    //     var map = new Map();
    //     map.set('storeCode', StorageTools.getUserCode());
    //     map.set('item', JSON.stringify(data));
    //     $mzlApi.post(APINames.API_saveItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    // }

     /**
     * 保存商品mchCode
     */
    saveMchManagerItem(data) {
        this.setState({loading:true})
        var map = new Map();
        map.set('mchCode', mchCode);
        map.set('item', JSON.stringify(data));
        $mzlApi.post(APINames.API_saveMchManagerItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    }

    // /**
    //  * 删除商品
    //  */
    // requestDeleteItem(itemId) {
    //     var map = new Map();
    //     map.set('storeCode', StorageTools.getUserCode());
    //     map.set('itemId', JSON.stringify(itemId));
    //     $mzlApi.post(APINames.API_deleteItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    // }
    // /**
    //  * 启用禁用服务
    //  * @param {*} id 
    //  * @param {*} state 
    //  */
    // requestAPI_EnabledItem(id,state){
    //     var map =new Map();
    //     map.set('storeCode',StorageTools.getUserCode());
    //     map.set('itemId',id);
    //     map.set('state',state)
    //     $mzlApi.post(APINames.API_EnabledItem, map, this.httpSuccess.bind(this), this.httpFail.bind(this), this);
    // }


    httpSuccess(res, tag) {
        hideLoadding();
        ////console.log("httpSuccess", tag, res)
        switch (tag) {
            case APINames.API_queryMchCardList:
                this.setState({
                    vipRequest: false,
                    vipData: JSON.parse(res.Data),
                });
                break;
            case APINames.API_queryMchManagerCardList:
                //console.log(555,JSON.parse(res.Data))
                hideLoadding()
                    this.setState({
                        vipRequest: false,
                        vipData: JSON.parse(res.Data),
                    });
                break;
            case APINames.API_queryItem:
                let data = JSON.parse(res.Data);
                data.forEach((item, index) => {
                    item.key = item.ItemId;
                    item.no = index + 1;
                    item.Price = utils.numberConvert(item.Price);
                   
                    switch (item.PriceSettingsBasis) {
                        case EPriceBasis.Original:
                            item.PriceBasisText = "原价";
                            break;
                        case EPriceBasis.Discount:
                            item.PriceBasisText = "会员统一价";
                            break;
                        case EPriceBasis.Custom:
                            item.PriceBasisText = "会员自定义";
                            break;
                    }
                });
                this.setState({
                    data
                });
                break;
            case APINames.API_queryMchManagerItem:
                    let data1 = JSON.parse(res.Data);
                    //console.log(363,begin)
                    data1.forEach((item, index) => {
                        item.key = item.ItemId;
                        item.no = ++tabbegin;
                        item.basicPrice = item.Cost/100
                        item.Price = utils.numberConvert(item.Price);
                        item.statusText = item.Enabled == 1? '已启用' : '已停用'
                        switch (item.PriceSettingsBasis) {
                            case EPriceBasis.Original:
                                item.PriceBasisText = "原价";
                                break;
                            case EPriceBasis.Discount:
                                item.PriceBasisText = "会员统一价";
                                break;
                            case EPriceBasis.Custom:
                                item.PriceBasisText = "会员自定义";
                                break;
                        }
                    });
                    this.setState({
                        data: data1,
                        Count:res.Count
                    });
                break;
            case APINames.API_getStoreRole:
                var pageList = res;
                pageList.forEach((item) => {
                    item.key = item.Id;
                    item.ChargeRate = numberConvert(item.ChargeRate);
                    item.Card2Rate = numberConvert(item.Card2Rate);
                    item.CardRate = numberConvert(item.CardRate);
                    item.VirtualRate = numberConvert(item.VirtualRate);
                    item.VirtualRateSpc = numberConvert(item.VirtualRateSpc);
                    item.ProdRate = numberConvert(item.ProdRate);
                    item.ProdRateSpc = numberConvert(item.ProdRateSpc)
                });
                this.setState({
                    roleData: res
                });
                break;
            case APINames.API_getItem:
                //console.log(res)
                if (res != null) {
                    this.serviceSetting(res)
                }
                break;
            case APINames.API_getMchManagerItem:
                // debugger
                if (res && res.Data && res.Data.MchManagerItem) {
                        const Data = JSON.parse(res.Data.MchManagerItem)
                        //console.log(495,Data)
                        if(Data.IsUsed == 1){
                            // 1使用2未使用
                           this.setState({disabledAll:true})
                        }
                        this.setState({visible: true})
                        this.serviceSetting(Data)
                    }
                break;
            case APINames.API_saveItem:
                utils.setReqeustState(true);
                hideLoadding();
                messageSuccess("保存成功!");
                this.setState({visible: false, saveData: {}});
                this.queryMchManagerItem();
                break;
            case APINames.API_saveMchManagerItem:
                this.setState({loading:false})
                utils.setReqeustState(true);
                hideLoadding();
                messageSuccess("保存成功!");
                this.setState({visible: false, saveData: {}});
                this.queryMchManagerItem()
                break;
            case APINames.API_deleteItem:
                hideLoadding();
                this.queryMchManagerItem();
                break;
            case APINames.API_enabledMchManagerItem:
                this.queryMchManagerItem()
                break;
            case APINames.API_deleteMchManagerItem:
                    this.queryMchManagerItem()
                break;
        }
    }

    httpFail(res, tag) {
        this.setState({loading:false})
        utils.setReqeustState(true);
        hideLoadding();
        showMessageError(res, tag)
        ////console.log("httpFail", tag, res)
    }


    serviceSetting(data) {
        //console.log(541541,data)
        var tempVipList = businessImpl.countVip(data, this.state.vipData);
        var tempRoleList = businessImpl.countRole(data, this.state.roleData);
        data.Cost=data.Cost/100
        data._Cost=data.Cost
        this.setState({
            saveData: data,
            vipList: tempVipList,
            roleList: tempRoleList,
        });
    }
    changeSelectStatus(value) {
        switch (value) {
          case "all":
            this.state.Enabled = 0;
            break;
          case "on":
            this.state.Enabled = 1;
            break;
          case "leave":
            this.state.Enabled = 2;
            break;
        }
        tabbegin = 0
        this.setState({ Enabled: this.state.Enabled });
        this.queryMchManagerItem()
      }


    onTablePageCallback(start, end) {
        begin = start;
        over = end;
        tabbegin = start;
        this.queryMchManagerItem();

    }

    //服务项目设置
    render() {
        const {Count, selectedRowKeys,loading} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
            },
        };
        const lable = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 6},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
        };

        return (
            <div class="gra-border-pd15 vip-main-view" style={{overflow:'hidden'}}>
                <Row type="flex" justify="space-around" align="middle" style={{paddingBottom: "10px"}}>
                    <Col span={12}>
                    <div>
                <Select
                  style={{ width: 100, float: "left", zIndex: 999 }}
                  defaultValue="all"
                  onChange={this.changeSelectStatus.bind(this)}
                >
                  <Option value="all">全部状态</Option>
                  <Option value="on">已启用</Option>
                  <Option value="leave">已停用</Option>
                </Select>
                <Search placeholder="服务名称" enterButton 
                onSearch={value => this.onSearch(value)}
                                style={{width: 200, marginLeft:10}}/>
              </div>
                       
                    </Col>
                    <Col span={12} style={{textAlign: "right", left: "1%"}}>
                   
                        <IButton style={{marginRight: 10}} type="primary"
                                 onClick={this.onClickListener.bind(this, "add")} text="添加服务"/>

                        <IButton type="primary" style={{background: "#f00", borderColor: "#f00"}}
                                 onClick={this.onClickListener.bind(this, "deleteAll")} text="删除"/>
                    </Col>
                </Row>
                <div className='vip-main-view-content' style={{ left: 15, right: 15, top: 60 }}>
                    <ITablePage scroll={{x: "100%", y: "62vh"}} rowSelection={rowSelection}
                                onTablePageCallback={this.onTablePageCallback.bind(this)} columns={this.columns}
                                data={this.state.data}
                                pageCount ={Count}
                                />
                                
                </div>
                {
                   this.state.visible? <IDrawer title={this.state.drawerTitle} footer={true} width={"580px"}
                   loading={loading}
                   onClickListener={this.onClickListener.bind(this)} 
                   visible={this.state.visible}>
                        <div className="app-sliding-div" style={{height: "100%"}}>
                            <ServiceItemsSettingsModalView 
                                vipList={this.state.vipList} 
                                roleList={this.state.roleList}
                                saveData={this.state.saveData} disabled={this.state.disabledAll}
                            />
                        </div>

                   </IDrawer>:null
                }
                
            </div>
        );
    }
}

const serviceItemsSetting = Form.create()(serviceItemsSettings);
export default serviceItemsSetting;