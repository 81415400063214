
import APINames from "../../../../../js/comm/api-names";
import { $mzlApi ,$agentApi} from "../../../../../js/comm/mzl-core";
import { EItemType } from "../../../../../js/comm/iEnum";
import StorageTools from "../../../../../js/comm/storageTools";
import {hideLoadding} from "../../../../../js/comm/utils";
import { json } from "graphlib";



  //获取上传数据
  function requestUploadData(updataData,mchCode,that,httpFail,callback,) {
    //查询数据
    requestUpload(updataData,that,httpFail)
    .then(function (data) {
        //上传数据
        if(data == null || data.length ==0){
            //如果data为空，或者data没有数据，当做上传成功处理
            callback({state:2,data:[]});
        }
        return requestImportStoreBase(mchCode,updataData.index,data,that,httpFail);
    })
    .then(function(data){
        updataData.batchCode = data.Data.batchCode;
        //查询进度
        requestGetImportStoreBaseState(updataData,mchCode,updataData.batchCode,that,httpFail,(data)=>{
            if(data >=100 ){
                //获取上传结果
                return requestGetStoreBaseImports(mchCode,updataData.batchCode,that,httpFail,(data)=>{

                    //返回结果
                    if(data != null){
                        callback({state:2,data:data});
                    }
                })
            }else{
                callback({state:1,num:data});
            }
        })
    })
  }



  //获取继续上传
  function requestContinueUpload(updataData,data,mchCode,that,httpFail,callback) {
    //上传数据
    requestImportStoreBase(mchCode,updataData.index,data,that,httpFail)
    .then(function(data){
        updataData.batchCode = data.Data.batchCode;
        //查询进度
        requestGetImportStoreBaseState(updataData,mchCode,updataData.batchCode,that,httpFail,(data)=>{
            if(data >=100){
                //获取上传结果
                return requestGetStoreBaseImports(mchCode,updataData.batchCode,that,httpFail,(data)=>{

                    //返回结果
                    if(data != null){
                        callback({state:2,data:data});
                    }
                })
            }else{
                callback({state:1,num:data});
            }
        })
    })
  }

  


  function requestUpload(updataData,that,httpFail) {
      let p = new Promise(function(resolve,reject){
        if(updataData.index == 1){
            //查询会员卡
            requestVipCardType(updataData,that,httpFail).then(function(data){
                resolve(data)
            })
        } else if(updataData.index == 2){
             //查询服务
             requestQueryItem(updataData,that,httpFail).then(function(data){
                resolve(data)
            })
             return requestQueryItem(updataData,that,httpFail);
        }else if(updataData.index == 3){
            //查询套餐卡
            requestCardType(updataData,that,httpFail).then(function(data){
                resolve(data)
            })
        }
      })
      return p;
  }






/**
 * 获取会员卡类型
 */
function  requestVipCardType(updataData,that,httpFail) {
  
    var p = new Promise(function (resolve, reject) {        //做一些异步操作

    var map = new FormData();
    map.set('storeCode', StorageTools.getUserCode());
    map.set('kind', 1);
    map.set('opt', JSON.stringify({ Begin: 0, Over: 9999, Opt: { enabled: 0,origin:0}}));
    $mzlApi.post(APINames.API_queryMchCardList, map, (res, tag) => {

        if(res != null && res.Data != null){
            let arr = []
            var list = JSON.parse(res.Data);
            list.forEach(element => {
                arr.push(element.Id)
            });
            resolve(arr);
        }else{
            httpFail(res,tag)
        }
        
    }, httpFail, that);
    });
    return p;

}
 

/**
 * 查询商品
 */
function  requestQueryItem(updataData,that,httpFail) {
    var p = new Promise(function (resolve, reject) {  

        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('productType', EItemType.Virtual);
        map.set('opt', JSON.stringify({Begin: 0, Over: 9999, Opt: {"shelve_state": "3",enabled:0}}));
        $mzlApi.post(APINames.API_queryItem, map, (res,tag)=>{
            if(res != null && res.Data != null){

                let arr = []
                var list = JSON.parse(res.Data);
                list.forEach(element => {
                    arr.push(element.ItemId)
                });
                resolve(arr);
            }else{
                httpFail(res,tag)
            }
            
        }, httpFail, that);
    });
    return p;
    
}




 /**
 * 获取套餐卡类型
 */
function requestCardType(updataData,that,httpFail) {
    var p = new Promise(function (resolve, reject) { 
        var map = new Map();
        map.set('storeCode', StorageTools.getUserCode());
        map.set('kind', 2);
        map.set('opt', JSON.stringify({ Begin: 0, Over: 9999, Opt: { enabled: 0, origin: 2 } }));
        $mzlApi.post(APINames.API_queryMchCardList, map, (res,tag)=>{
            if(res != null && res.Data != null){
                let arr = []
                var list = JSON.parse(res.Data);
                list.forEach(element => {
                    arr.push(element.Id)
                });
                resolve(arr);
            }else{
                httpFail(res,tag)
            }
        }, httpFail, that);
    });
    return p;


}
/**
 * 单店上传会员、服务、套餐卡
 */
function  requestImportStoreBase(mchCode,kind,ids,that,httpFail) {
    var p = new Promise(function (resolve, reject) {        //做一些异步操作
        var map = new FormData();
        map.set('mchCode', mchCode);
        map.set('storeCode', StorageTools.getUserCode());
        map.set('kind',kind);
        map.set('ids', JSON.stringify(ids));
        $mzlApi.post(APINames.API_importStoreBase, map, (res,tag)=>{

            if(res != null){
                resolve(res);
            }else{
                httpFail(res,tag);
            }
        }, httpFail, that);
    });
    return p;



    // var map = new Map();
    // map.set('mchCode', mchCode);
    // map.set('storeCode', StorageTools.getUserCode());
    // map.set('kind',kind);
    // map.set('ids', JSON.stringify(ids));
    // $mzlApi.post(APINames.API_importStoreBase, map, (res)=>{
       
    // }, httpFail, that);
}

/**
 * 获取上传会员、服务、套餐卡的进度
 */
function  requestGetImportStoreBaseState(updataData,mchCode,batchCode,that,httpFail,callback) {

    
    var map = new Map();
    map.set('mchCode', mchCode);
    map.set('batchCode', batchCode);
    $mzlApi.post(APINames.API_getImportStoreBaseState, map, (res,tag)=>{
        if(res != null && res.Data != null && res.Data.state != null){
            if(updataData.index == 1){
                updataData.vipNum = res.Data.state;
            }else if(updataData.index == 2){
                updataData.serviceNum = res.Data.state;
            }else if(updataData.index == 3){
                updataData.cardNum = res.Data.state;
            }
            if(res.Data.state >=100){
                callback(res.Data.state);
            }else{
                callback(res.Data.state);
                setTimeout(() => {
                    requestGetImportStoreBaseState(updataData,mchCode,batchCode,that,httpFail,callback);
                }, 1000);
                
            }
        }else{
            httpFail(res,tag);
        }
    }, httpFail, that);

}


/**
 * //获取上传会员、服务、套餐卡的结果
 */
function  requestGetStoreBaseImports(mchCode,batchCode,that,httpFail,callback) {
   
    var map = new Map();
        map.set('mchCode', mchCode);
        map.set('batchCode', batchCode);
        $mzlApi.post(APINames.API_getStoreBaseImports, map, (res,tag)=>{
            if(res != null && res.Data != null ){
                let arr = JSON.parse(res.Data.result);
                // arr.push({ItemName:"会员卡",ItemId:1,ErrMsg:"上传失败"})
                callback(arr);
            }else{
                httpFail(res,tag);
            }
        }, httpFail, that);
    
}



export const api={
    requestUploadData:requestUploadData,
    requestContinueUpload:requestContinueUpload,
}