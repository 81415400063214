import IDrawer from '@components/iDrawerPage'
import { IInputMoney } from '@components/iInput'
import IInputString from '@components/iInputString'
import ITextArea from '@components/iTextAreaString'
import ITableList from '@components/iTableList'
import { update } from "@js/goods/action"
import GoodsModel from "@js/goods/goodsInto/goodsModel"
import { Button, Input, Select, Tabs ,Steps,Tooltip} from 'antd'
import React from 'react'
import { connect } from 'react-redux'

import {getTime} from "@comm/dateHelp"
import {calcMultiply} from "@comm/utils"
import service from '../../../services/marketing/bonusService'

let begin = 0;
let over = 20;
const { Step } = Steps;
const reduxStaste = (state) => {
    return {
        ...state.goodsData,
    }
}
@connect(
    state => reduxStaste(state),
    { update }
)
class Detail extends React.Component {
    constructor() {
        super();
        this.state = {
            
              tableData : []

        }



    }

   

    componentDidMount() {
         this.getPageData();
     }

     getPageData() {
       const {detailData} = this.props;
      let opt = { Begin: 0, Over: 999,Opt:{userId: detailData.UserId}};
      service.queryShDividendsDetail(this, opt).then(res => {
        let data = JSON.parse(res.Data);
        let arr = []
        data.forEach(item => {
          item._OrderTime = getTime(item.OrderTime);
          item.OrderAmount = item.OrderAmount/100;
          item.UserDividendsAmount = item.UserDividendsAmount/100;
          item.ParentUserDividendsAmount = item.ParentUserDividendsAmount/100;

          if(this.props.type ===1){
            if(detailData.UserId === item.UserId){
              arr.push(item)
            }
          }else{
            if(detailData.UserId === item.ParentUserId){
              arr.push(item)
            }
          }
        })
        this.setState({
          tableData: arr
        })
      })


    }
 


    selectChange(tag,e) {
       
    }

    onShowSizeChange() {

    }


 
      
 
  
    render() {
      const columns = [
        {
          width:80,
          title: '序号',
          dataIndex: 'name',
          align: 'center',
          render: (text,record,index) => <span>{index+begin+1}</span>,
        },
        {
          title: '客户姓名',
          align: 'center',
          dataIndex: 'CusName',
        },
        {
          title: '手机',
          align: 'center',
          dataIndex: 'CusPhone',
        },
        {
          title: '消费时间',
          align: 'center',
          dataIndex: '_OrderTime',
        },
        {
          title: '消费金额',
          align: 'center',
          dataIndex: 'OrderAmount',
        },
        {
          title: this.props.type ===1?"自拓分红":"下级分红",
          align: 'center',
          dataIndex: 'UserDividendsAmount',
          render:(text,data)=>(
            <span>{this.props.type ===1?data.UserDividendsAmount:data.ParentUserDividendsAmount}</span>
          )
        }
      ];


        return (
            <IDrawer
                title={"详情"} 
                footer={false} 
                onCancel={this.props.onCancel}
                width={"80%"} 
                visible={this.props.visible}
            >
                <div className="comm-w-h">
                    <ITableList 
                        top={170}
                        columns={columns} 
                        data={this.state.tableData}
                        totalSize={this.state.totalSize}>

                    </ITableList>
                </div>
                 
               
            </IDrawer>

        )
    }
}

export default Detail