import IDrawer from '@components/iDrawerPage'
import { IInputMoney } from '@components/iInput'
import IInputString from '@components/iInputString'
import ITextArea from '@components/iTextAreaString'
import ITableList from '@components/iTableList'
import { update } from "@js/goods/action"
import GoodsModel from "@js/goods/goodsInto/goodsModel"
import { Button, Input, Select, Tabs ,Steps,Tooltip} from 'antd'
import React from 'react'
import { connect } from 'react-redux'

import {getTime} from "@comm/dateHelp"
import {calcMultiply} from "@comm/utils"
import service from '../../../services/marketing/bonusService'

let begin = 0;
let over = 20;
const { Step } = Steps;
const reduxStaste = (state) => {
    return {
        ...state.goodsData,
    }
}
@connect(
    state => reduxStaste(state),
    { update }
)
class model extends React.Component {
    constructor() {
        super();
        this.state = {
            columns : [
                {
                    title: '序号',
                    dataIndex: 'ApplicationNo',
                    align: 'center',
                    render: (text,data,index) =><div className="comm-text-over">{index+1}</div>
                  },
                  {
                    title: '姓名',
                    align: 'center',
                    dataIndex: 'Name',
                  },
                  
                  {
                    title: '新客手机',
                    align: 'center',
                    dataIndex: 'Phone',
                  },
                 
              ],
              tableData : []

        }



    }

   

    componentDidMount() {
         this.getPageData();
     }
 
     
     getPageData() {
      let opt = { Begin: 0, Over: 9999,Opt:{userId:this.props.id}};
      service.queryShCustomer(this, opt).then(res => {
        let data = JSON.parse(res.Data);
        this.setState({
          tableData: data
        })
      })
    }
 
 


    selectChange(tag,e) {
       
    }

    onShowSizeChange() {

    }

    onClickListener(tag){
        if(tag === "drawer-ok"){
            this.props.update({
                goodsOutCountVisible:false
            })
        }else if(tag === "drawer-cancel"){
            this.props.update({
                goodsOutCountVisible:false
            })
        }
    }

 
      
    onTablePageCallback(start, end){
      }
  
    render() {



        return (
            <IDrawer
                title={"详情"} 
                footer={false} 
                onCancel={this.props.onCancel}
                width={"50%"} 
                visible={this.props.visible}
            >
                <div className="comm-w-h">
                    <ITableList 
                        top={170}
                        columns={this.state.columns} 
                        data={this.state.tableData}
                        totalSize={this.state.totalSize}
                        onTablePageCallback={this.onTablePageCallback.bind(this)}>

                    </ITableList>
                </div>
                 
               
            </IDrawer>

        )
    }
}

export default model