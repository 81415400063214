
//供应商
export function SPmsSupplier() {
    return {
        Id: 0,
        /**
        * 商户id
        **/
        MchId: 0,
        /**供应商名称*/
        SupplierName: "",
        /**供应商联系人名字*/
        ContactsName: "",
        /**供应商联系电话*/
        ContactPhone: "",
        /**地址*/
        ContactAddress: "",
        /**供应商类型编号*/
        SpecTypeId: 0,
        /**首写字母缩写*/
        Letter: "",
        /**
        * 店铺id
        **/
        StoreId: 0,
        /**
        * 使用者类型 1 -商户 2- 店铺
        **/
        UseType: 0,
    }
}

//商品参数
export function SPmsGoodsParameter() {
    return {
        Id: 0,
        /**
        * 商户id
       **/
        MchId: 0,
        /**
        * 店铺id
        **/
        StoreId: 0,
        /**
        * 使用者类型 1 -商户 2- 店铺
        **/
        UseType: 0,
        /**
        * 参数类型
        **/
        ParameterType: 0,
        /**
        * 参数名称
        **/
        ParameterName: "",
        /**创建时间*/
        CreateTime: 0,
        /** 修改时间 */
        UpdateTime: 0,
        /** 1系统增加  2 人工增加 */
        SystemParameters: 2,
    }
}

//库存-商品
export function SPmsGoods() {
    return {
        /**编号*/
        Id: 0,
        /**商家编号*/
        MchId: 0,
        /**店铺编号*/
        StoreId: 0,
        /**商品名称*/
        GoodsName: "",
        /**商品类型*/
        GoodsType: 0,
        /**预警数量*/
        WarningCount: 0,
        /**门市价格*/
        Price: 0,
        /**规格*/
        Spec: 0,
        /**规格单位名称*/
        SpecName: "",
        /**毫升（ML）*/
        Bulk: "",
        /**1按全局    3自定义*/
        PriceBasis: 0,
        /**库存*/
        StockNum: 0,
        /**操作人id*/
        OpeId: 0,
        /**操作人*/
        OpeName: "",
        /**2是无效1有效*/
        Enabled: 1,
        /**商品类型名称*/
        GoodsTypeName: "",
        /**首字母缩写*/
        Letter: "",
        /**会员收银时参照价格表  SCashierReferenceModel*/
        SCashierReference: [],
        /**提成设置表 SAwardSetModel*/
        SAwardSet: [],
        PriceSettingsBasis: 3,//会员价格参照方式 1:未设置 2:按全局 3:自定义',
        PriceValue: 0,//会员统一价',
    
        AwardSettingsBasis: 3,//提成参照方式 1:未设置 2:按全局 3:自定义',
        AwardBasis: 3,//统一提成计算依据 1:未设置 2:按金额 3:按百分',
        AwardValue: 0,//统一提成值',
        /** 刷卡非指定提成*/
        AwardUcardValeu:0,

        /** 提成方式 */
        AwardBasisSpc: 0, 
        /** 统一提成值 */
        AwardValueSpc: 0,
        /** 提成参照方式 */
        AwardSettingsBasisSpc: 2,
        /**使用者类型 1 -商户 2- 店铺*/
        UseType: 0,
        /**商品编码*/
        GoodsCode: "",
        /**成本价*/
        Cost: 0,
        /**商户商品Id*/
        TemplateId: 0,
    }
}


export function SCashierReferenceModel() {
    return {
        /**编号*/
        Id: 0,
        /**商品编号*/
        GoodsId: 0,
        /**卡编号*/
        CardTid: 0,
        /**自定义价*/
        CustomizedPrice: 0,
    }
}

export function SAwardSetModel() {
    return {
        /**编号*/
        Id: 0,
        /**商品编号*/
        GoodsId: 0,
        
        /**提成方式,1:不提成 2:按全局 3:自定义*/
        SettingsBasis: 0,
        /**职位编号*/
        PositionId: 0,
        /**1未设置  2金额 3百分比*/
        Type: 0,
        /**百分比或元*/
        RatePrice: 0,
        /**百分比或元*/
        RatePriceSpc: 0,
        /**1未设置  2金额 3百分比*/
        TypeSpc: 0,
        /**商品刷卡提成*/
        RateUcardPrice:0
    }
}
/**可选店铺信息*/
export function SPmsGoodsTransshipmentShop() {
    return {
        /**
        * 商户id
        **/
        MchId: 0,
        /**
         * 店铺id
         **/
        StoreId: 0,
        /**
         * 使用者类型 1 -商户 2- 店铺
         **/
        UseType: 0,
        /**
         * 名称
         **/
        UseName: "",
        /**
         * 商户Code
         **/
        MchCode: "",
        /**
         * 店铺code
         **/
        StoreCode: ""
    }
}
/**
* 商品出入库申请表
**/
export function SPmsGoodsApplication() {
    return {
        /**编号*/
        Id: 0,
        /**商户编号*/
        MchId: 0,
        /**店铺id*/
        StoreId: 0,
        /**
        * 使用者类型 1 -商户 2- 店铺
        **/
        UseType: 0,
        /**
        *申请单号
        **/
        ApplicationNo: "",
        /**
        *申请类型id
        **/
        ApplicationTypeId: 0,
        /**
        *申请类型 默认的 入库类型 出库类型
        **/
        ApplicationType: "",
        /**
        *单据金额
        **/
        DocumentAmount: 0,
        /**
        *备注
        **/
        Remake: "",
        /**
        *供应商ID
        **/
        SupplierId: 0,
        /**
        *供应商姓名
        **/
        SupplierName: "",
        /**
        *操作人ID
        **/
        OptId: 0,
        /**
        *操作人ID
        **/
        OptName: "",
        /**
        *状态 1 未审核 2 已审核  3 拒绝 4 取消 5 删除
        **/
        State: 0,
        /**
        *审核人ID
        **/
        ReviewId: 0,
        /**
        *审核姓名
        **/
        ReviewName: "",
        /**
        *审核时间
        **/
        ReviewTime: 0,
        /**
        *创建方式  1 入库  2 出库
        **/
        CreateType: 0,
        /**创建时间*/
        CreateTime: 0,
        /** 修改时间 */
        UpdateTime: 0,
        /** 商品出入库項目 SPmsGoodsApplicationItem */
        ApplicationItem: []

    }
}

/**
* 商品出入库項目表
**/
export function SPmsGoodsApplicationItem() {
    return {
        /**编号*/
        Id: 0,
        /**商户编号*/
        MchId: 0,
        /**店铺id*/
        StoreId: 0,
        /**
        * 使用者类型 1 -商户 2- 店铺
        **/
        UseType: 0,
        /**
        *申请单id
        **/
        ApplicationId: 0,
        /**
        *商品id
        **/
        GoodsId: 0,
        /**
        *商品名
        **/
        GoodsName: "",
        /**
        *规格
        **/
        Bulk: "",
        /**
        *单位
        **/
        Spec: "",
        /**
        *现有库存
        **/
        StockNum: 0,
        /**
        *申请数量
        **/
        ApplicationNum: 0,
        /**
        *成本
        **/
        Cost: 0,
        /**创建时间*/
        CreateTime: 0,
        /** 修改时间 */
        UpdateTime: 0,
    }
}

/**附加
* Transshipment application
* 商品出入库申请表
**/
export function SPmsGoodsApplicationLog() {
    return {
        /**编号*/
        Id: 0,
        /**商户编号*/
        MchId: 0,
        /**店铺id*/
        StoreId: 0,
        /**
         * 使用者类型 1 -商户 2- 店铺
         **/
        UseType: 0,
        /**
         *申请单id
         **/
        ApplicationId: 0,
        /**
         *商品id
         **/
        GoodsId: 0,
        /**
         *商品名
         **/
        GoodsName: "",
        /**
         *规格
         **/
        Bulk: "",
        /**
         *单位
         **/
        Spec: "",
        /**
         *现有库存
         **/
        StockNum: 0,
        /**
         *申请数量
         **/
        ApplicationNum: 0,
        /**
         *成本
         **/
        Cost: 0,
        /**创建时间*/
        CreateTime: 0,
        /** 修改时间 */
        UpdateTime: 0,
        /**
         *申请单号
         **/
        ApplicationNo: "",
        /**
         *供应商ID
         **/
        SupplierId: 0,
        /**
         *供应商姓名
         **/
        SupplierName: "",
        /**
         *操作人ID
         **/
        OptId: 0,
        /**
         *操作人ID
         **/
        OptName: "",
        /**
         *创建方式  1 入库  2 出库
         **/
        CreateType: 0,
        /**
        申请类型id
        **/
        ApplicationTypeId: 0,
        /**
        *申请类型 默认的 入库类型 出库类型
         **/
        ApplicationType: "",
        /**
         *商品类型Id
         **/
        GoodsTypeId: 0,
        /**
         *商品类型名
         **/
        GoodsType: "",
    }
}
/**退还商品*/
export function SAfterPmsGoodsItem() {
    return {
        /**编号*/
        Id: 0,
        /**商户编号*/
        MchId: 0,
        /**店铺id*/
        StoreId: 0,
        /**
        * 使用者类型 1 -商户 2- 店铺
        **/
        UseType: 2,
        /**
        *单号
        **/
        ApplicationNo: "",
        /**
         *商品id
        **/
        GoodsId: 0,
        /**
         *数量
        **/
        ApplicationNum: 0,
        /**
         *成本
        **/
        Cost: 0,
        /**
         *操作人ID
        **/
        OptId: 0,
        /**
         *操作人ID
        **/
        OptName: "",
        /**创建时间*/
        CreateTime: 0,
        /** 修改时间 */
        UpdateTime: 0,
    }
}

/**转运商品申请*/
export function SpmsGoodsTransshipmentModel() {
    return {
        /**编号*/
        Id: 0,
        /**申请商户编号*/
        MchId: 0,
        /**申请店铺id*/
        StoreId: 0,
        /**
         * 使用者类型 1 -商户 2- 店铺
         **/
        UseType: 0,
        /**
        *备注
        **/
        Remake: "",
        /**
        * 转运商品信息 SpmsGoodsTransshipmentItemModel
        **/
        ItemModel: [],
        /**
          *创建方式  1 调拨  2 补货
          **/
        CreateType: 0,
        /**
        *  申请店铺名称
        **/
        StoreName: "",
        /**
         *操作人ID
        **/
        OptId: 0,
        /**
         *操作人ID
            **/
        OptName: ""
    }
}

export function SpmsGoodsTransshipmentItemModel() {
    return {
        /**商户编号*/
        MchId: 0,
        /**店铺id*/
        StoreId: 0,
        /**
          * 使用者类型 1 -商户 2- 店铺
          **/
        UseType: 0,
        /**
          *商品id
          **/
        GoodsId: 0,
        /**
          *商品模板id
          **/
        GoodsTemplateId: 0,
        /**
          *商品名
          **/
        GoodsName: "",
        /**
          *申请数量
          **/
        ApplicationNum: 0,
        Spec: "",  //单位
        Cost: 0,  //成本
        /**
          *  转运店铺名称
         **/
        StoreName: ""
    }

}
/**转运商品申请*/
export function SpmsGoodsTransshipment() {
    return {
        Id: 0,  //编号
        MchId: 0, //商户id
        StoreId: 0,  //店铺id
        UseType: 0,//使用者类型1-商户2-店铺
        TransshipmentNo: "",    //申请单号
        TransshipmentMchId: 0,  //转运商户id
        TransshipmentStoreId: 0, //转运店铺id
        TransshipmentName: "", //转运店铺姓名
        TransshipmentUseType: 0,   //转运使用者类型1-商户2-店铺
        ShiptMchId: 0,   //发货商户id
        ShiptStoreId: 0,   //发货转运店铺id
        ShiptName: "", //发货转运店铺姓名
        ShiptUseType: 0,  //发货使用者类型1-商户2-店铺
        Remake: "",   //备注
        OptId: 0,    //操作人id
        OptName: "",   //操作人姓名
        State: 0, //状态
        ReviewId: 0,  //审核人id
        ReviewName: "",//审核姓名
        ReviewTime: 0, //审核时间
        CreateType: 0,  //创建方式1调拨2补货
        CreateTime: 0,  //创建时间
        UpdateTime: 0,  //更新时间
        Items: []   //商品  SpmsGoodsTransshipmentItem
    }
}
/**转运商品申请*/
export function SpmsGoodsTransshipmentItem() {
    return {
        Id: 0, //编号
        MchId: 0, //商户id
        StoreId: 0, //店铺id
        UseType: 0, //使用者类型1-商户2-店铺
        TransshipmentId: 0,  //转运单id
        GoodsId: 0,  //商品id
        GoodsName: "", //商品名
        Bulk: "", //规格
        Spec: "", //单位
        TransshipmentNum: 0,  //申请数量
        Cost: 0, //成本
        CreateTime: 0, //创建时间
        UpdateTime: 0, //更新时间
    }
}

export function SPmsGoodsTransshipmentLog() {
    return {
        Id: 0,  //编号
        MchId: 0,//商户id
        TransshipmentNo: "",   //申请单号
        TransshipmentName: "",   //转运商户名
        AttachNo: "",  //附加单号
        OptId: 0,  //操作人id
        OptName: "",  //操作人姓名
        TransshipmentLogType: 0,   //日志方式方式1审批2出库3入库
        CreateType: 0,   //创建方式1调拨2补货
        CreateTime: 0,  //创建时间
        UpdateTime: 0,  //更新时间
        State: 0,   //状态
        ReviewTime: 0, //审核时间
    }
}