/* eslint-disable no-unused-expressions */
import React from "react";
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  Radio,
  Select,
  Switch,
  Tabs,
  Tooltip
} from "antd";
import { connect } from "react-redux";
import * as utils from "../../../../js/comm/utils";
import {
  countConvert,
  dataLineConvert,
  discountTypeConvert,
  hideLoadding,
  messageError,
  moneyConvert,
  numberConvert,
  numberConvert_100,
  numConvert,
  parseIntX100,
  showLoadding,
  showMessageError
} from "../../../../js/comm/utils";
import APINames from "../../../../js/comm/api-names";
import { FnMenuId, money_max } from "../../../../js/comm/constans";
import { setDefaultTitle } from "../../../../js/reduxs/reducers/iconRedux";
import StorageTools from "../../../../js/comm/storageTools";
import { $mzlApi } from "../../../../js/comm/mzl-core";
import { ECardFlag, ECardKind, EItemType } from "../../../../js/comm/iEnum";
import IButton from "../../../../js/components/IButton";
import IInputString from "../../../../js/components/iInputString";
import ITablePage, {
  getPageSize,
  setTabTop
} from "../../../../js/components/iTablePage";
import IDrawer from "../../../../js/components/iDrawer";
import { IModal } from "../../../../js/components/iModal";
import InputMoney from '../../../../js/components/iInputMoney'
import "../../../../css/card.css";

let begin = 0;
let over = getPageSize();
let maxNum = 99999;

const Option = Select.Option;
const TabPane = Tabs.TabPane;
const Search = Input.Search;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    sm: { span: 5 }
  },
  wrapperCol: {
    sm: { span: 18 }
  }
};
var 总次数 = 0;
var 门市价 = 0;
var 优惠 = 0;
var 套餐卡售价 = 0;
var slidingKey = 1;
var h = document.documentElement.clientHeight * 0.7;
let mchCode = "";
/**
 * 会员卡设置
 */
@connect(state => state.iconRedux, { setDefaultTitle })
class vipSettingView extends React.Component {
  constructor() {
    super();
    this.state = {
      /**岗位提成数组 */
      postRateList: [],
      /**展开收起 */
      isShowAll: false,
      isShowAll2: false,
      /**是否储值 */
      isStorage: true,
      /**提成或金额标志 */
      tcFlag: 3,
      /**开卡提成显示隐藏 */
      kkDisabled: false,
      /**充值提成显示隐藏 */
      czDisabled: false,
      /**岗位列表 */
      postList: [],
      disableAll: false,
      secondTitle: "新增套餐卡类型",
      ExpiresDay: 0,
      disabled: true,
      cardKind: null,
      visible: false,
      visible2: false,
      visible3: false,
      visible4: false,
      formData: null,
      isDelete: null,
      modalData: this.initCard(),
      PromBy: 2,
      RadioState: 0,
      editRecord: this.initCard(),
      defaultValue: 2,
      item: [],
      itemData: [],
      cardItem: [], //套餐卡
      openCard: {}, //套餐卡
      vipCount: 0,
      isVip: true,
      title: "添加卡类型",
      columns: [
        {
          title: "会员卡名称",
          dataIndex: "CardName",
          key: "vipName",
          className: "time",
          align: "center",
          width: "30%",
          render: text => <Tooltip title={text}>{text}</Tooltip>
        },
        {
          title: "储值",
          dataIndex: "storedValue",
          key: "onOff",
          align: "center",
          width: "8%"
        },
        {
          title: "优惠类型",
          dataIndex: "Rate.PromByText",
          key: "type",
          align: "center",
          width: "15%"
        },
        {
          title: "开卡/出售金额",
          dataIndex: "SalePriceText",
          key: "money",
          align: "center",
          width: "15%"
        },
        {
          title: "优惠明细",
          dataIndex: "Rate.PromBonusText",
          key: "detail",
          align: "center",
          width: "20%",
          render: (text, record) => <div>{text}</div>
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "stop",
          align: "center",
          width: "10%",
          render: (text, record) => <div>{text}</div>
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          align: "center",
          width: "25%",
          render: (text, record) => (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <a onClick={this.stopUse.bind(this, record)}>
                {record.status == "已停用" ? "启用" : "停用"}
              </a>
              <a onClick={this.editVipType.bind(this, record)}>编辑</a>
              <a onClick={this.deleteVip.bind(this, record, 1)}>删除</a>
            </div>
          )
        }
      ],
      columns2: [
        {
          title: "套餐卡名称",
          dataIndex: "CardName",
          key: "secondName",
          align: "center",
          width: "30%"
        },
        {
          title: "次数",
          dataIndex: "_InitialValue",
          key: "count",
          align: "center",
          width: "19%"
        },
        {
          title: "有效期",
          dataIndex: "_ExpiresDay",
          key: "date",
          align: "center",
          width: "25%"
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          align: "center",
          width: "19%",
          render: (text, record) => (
            <div>
              <span
                style={{ float: "left" }}
                onClick={this.delete.bind(this, record, 2)}
              >
                <Icon type="close" />
              </span>
              <a
                style={{ float: "right" }}
                onClick={this.onClickListener.bind(this, "套餐卡编辑", record)}
              >
                <Icon type="form" />
              </a>
            </div>
          )
        }
      ],
      columns3: [
        {
          title: "项目名称",
          dataIndex: "Title",
          key: "prjName",
          align: "center"
        },
        {
          title: "门市价",
          dataIndex: "_Price",
          key: "mPrice",
          align: "center"
        },
        {
          title: "套餐卡价",
          dataIndex: "_UnitPrice",
          key: "cPrice",
          align: "center"
        },
        {
          title: "数量",
          dataIndex: "_count",
          key: "count",
          align: "center",
          render: (text, record) => (
            <Input
              defaultValue={text}
              onChange={this.changeListener.bind(this, "changeCount", record)}
            />
          )
        },
        {
          title: "金额",
          dataIndex: "_amount",
          key: "price",
          align: "center",
          render: (text, record) => (
            <div>
              <Input defaultValue={text} />元
            </div>
          )
        },
        {
          title: "删除",
          dataIndex: "delete",
          key: "delete",
          align: "center",
          render: (text, record) => (
            <div className="flexBox">
              <a href="#" onClick={this.stopUse.bind(this, record)}>
                停用
              </a>
              <a onClick={this.editVipType.bind(this, record)}>编辑</a>
              <a
                onClick={this.onClickListener.bind(
                  this,
                  "API_deleteItem",
                  record
                )}
              >
                删除
              </a>
            </div>
          )
        }
      ],
      Enabled: 0,
      storedValue: 0, //是否储值
      keyword: "",
      loading: false,
    };
  }
  stopUse(record) {
    let state = 1;
    if (record.status == "已启用") {
      state = 2;
    }
    this.enabledMchManagerCard(record.Id, state);
    //console.log(1111, record);
  }
  deleteVip(record) {
    this.deleteMchManagerCard(record.Id);
  }

  initCard() {
    var data = {
      Id: 0,
      CardName: "",
      Items: [],
      //1会员卡，2套餐卡
      CardKind: 1,
      ExpiresDay: 0,
      Enabled: 1,
      CardFlag: ECardFlag.StoredValue | ECardFlag.Discount | ECardFlag.Charge,
      SalePrice: 0,
      Rate: {
        /** 优惠方式,1:不打折 2:折扣 3:赠送礼金 */
        PromBy: 2,
        /** 优惠折扣 */
        PromDiscount: 1000,
        /** 优惠礼金 */
        PromBonus: 0
      },
      sCardTypeAward: {
        Id: 0,
        CardTid: 0,
        CardValue: 0,
        CardBasis: 3,
        CardSettingsBasis: 2,
        ChargeValue: 0,
        ChargeBasis: 3,
        ChargeSettingsBasis: 2,
        sCardTypeAwardPost: []
      }
    };
    return data;
  }

  componentDidMount() {
    mchCode = StorageTools.getMchCode();
    setTabTop(180);
    over = getPageSize();
    this.requestCardType(1);
    // this.requestQueryItem();
    // this.requestQueryCardItem();
  }

  /**
   *获取服务
   */
  requestQueryItem(search) {
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    map.set("productType", EItemType.Virtual);
    map.set(
      "opt",
      JSON.stringify({
        Begin: 0,
        Over: 99999,
        Opt: { shelve_state: "3", keyword: search }
      })
    );
    // map.set('opt', JSON.stringify({Begin: 0, over: 999,Opt:{shelve_state:"3"}));
    $mzlApi.post(
      APINames.API_queryItem,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   *
   */
  requestQueryCardItem() {
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    map.set("productType", EItemType.Normal);
    map.set("opt", JSON.stringify({ Begin: 0, over: 99999, Opt: { "shelve_state": "3", "enabled": 1 } }));
    $mzlApi.post(
      APINames.API_queryItem,
      map,
      res => {
        this.setState({ cardItem: JSON.parse(res.Data) });
      },
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 获取会员卡类型
   */
  requestCardType(kind) {
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("kind", kind);
    map.set(
      "opt",
      JSON.stringify({
        Opt: {
          storedValue: this.state.storedValue,
          enabled: this.state.Enabled,
          keyword: this.state.keyword
        },
        Begin: begin,
        Over: over
      })
    );
    $mzlApi.post(
      APINames.API_queryMchManagerCardList,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }
  /**
   * 停用或启用会员卡
   */
  enabledMchManagerCard(id, state) {
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("typeId", id);
    map.set("state", state); //1启用 2停用

    $mzlApi.post(
      APINames.API_enabledMchManagerCard,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }
  /**
   * 删除会员卡
   */
  deleteMchManagerCard(id) {
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("id", id);

    $mzlApi.post(
      APINames.API_deleteMchManagerCard,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 会员明细新的
   */
  getMchManagerCard(id) {
    showLoadding();
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("id", id);
    $mzlApi.post(
      APINames.API_getMchManagerCard,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 删除会员卡类型\套餐卡
   */
  requestDeleteMchCard(id) {
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    map.set("id", id);
    $mzlApi.post(
      APINames.API_deleteMchCard,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 新增会员卡
   * @param {*} obj
   */
  requestSaveMchCard(data) {
    this.setState({ loading: true });
    var map = new Map();
    map.set("mchCode", mchCode);
    map.set("card", JSON.stringify(data));
    ////console.log('发的是公司',data)
    $mzlApi.post(
      APINames.API_saveMchManagerCard,
      map,
      () => {
        this.setState({ loading: false });
        this.requestCardType(1);
      },
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 查询岗位列表
   */
  requestStoreRole() {
    showLoadding();
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    $mzlApi.post(
      APINames.API_getStoreRole,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 查询岗位列表
   */
  requestStoreRole2() {
    showLoadding();
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    $mzlApi.post(
      APINames.API_getStoreRole,
      map,
      res => {
        hideLoadding();
        ////console.log('编辑',res,this.state.postList,this.state.postRateList)
        if (this.state.editRecord) {
          res.forEach(item => {
            this.state.editRecord.sCardTypeAward.sCardTypeAwardPost.forEach(
              val => {
                if (item.RoleId == val.PostId) {
                  item.CardRate = val.CardValue;
                  item.ChargeRate = val.ChargeValue;
                  if (val.CardBasis == 2) {
                    item._flag = false;
                  } else {
                    item._flag = true;
                  }
                  item._CardBasis = val.CardBasis;
                }
              }
            );
          });
        }
        this.setState({ postList: res, visible3: true });
      },
      this.httpFail.bind(this),
      this
    );
  }

  /**
   * 判断会员卡是否使用
   */
  API_checkCardIsUsed(mchId, cardId) {
    var map = new Map();
    map.set("storeCode", StorageTools.getUserCode());
    map.set("cardId", cardId);
    $mzlApi.post(
      APINames.API_checkCardIsUsed,
      map,
      this.httpSuccess.bind(this),
      this.httpFail.bind(this),
      this
    );
  }

  httpSuccess(res, tag) {
    ////console.log('成功', res, tag);
    switch (tag) {
      case APINames.API_queryMchManagerCardList:
        hideLoadding();
        //console.log("获取", JSON.parse(res.Data));
        this.setState({ vipCount: res.Count });
        if (res != null || res.Data != null) {
          var list = JSON.parse(res.Data);
          let num = 1;
          list.forEach(item => {
            item.num = num;
            item.InitialValueText = countConvert(item.InitialValue);
            item.ExpiresDayText = dataLineConvert(item.ExpiresDay);
            item.Rate &&
              (item.Rate.PromDiscountText = numberConvert(
                item.Rate.PromDiscount
              ));
            item.SalePriceText = item.SalePrice / 100 + "元";
            // item.storedValue = "是";
            item.storedValue =
              (item.CardFlag & 1) == 1 || (item.CardFlag & 4) == 4
                ? "是"
                : "否";
            item.status = item.Enabled == 1 ? "已启用" : "已停用";
            if (item.Rate) {
              if (item.Rate.PromBy == 1) {
                item.Rate.PromBonusText = "不打折";
              } else if (item.Rate.PromBy == 2) {
                item.Rate.PromBonusText =
                  discountTypeConvert(item.Rate.PromBy) +
                  numberConvert(item.Rate.PromDiscount) +
                  "折";
              } else if (item.Rate.PromBy == 3) {
                item.Rate.PromBonusText =
                  discountTypeConvert(item.Rate.PromBy) +
                  item.Rate.PromBonus / 100 +
                  "元";
              }
              item.Rate.PromByText = discountTypeConvert(item.Rate.PromBy);
            }

            item.key = num;
            num++;
            item._InitialValue = item.InitialValue + "次";
            if (item.ExpiresDay == 0) {
              item._ExpiresDay = "永久有效";
            } else {
              item._ExpiresDay = "自办卡之日起" + item.ExpiresDay + "天有效";
            }
          });
          this.setState({
            data: list,
            data2: list
          });
        }
        // ////console.log('转化后的数据',this.state.data[0].Rate.PromBy)
        break;
      case APINames.API_deleteMchCard:
        if (res.ErrCode == 0) {
          ////console.log(this.state.isDelete)
          this.requestCardType(this.state.isDelete.CardKind);
        }
        break;
      case APINames.API_saveMchCard:
        utils.setReqeustState(true);
        this.setState({ kkDisabled: false });
        if (res.ErrCode == 0) {
          utils.messageSuccess("操作成功");
          if (slidingKey == 2) {
            this.requestCardType(2);
            slidingKey = 1;
          } else {
            this.requestCardType(1);
          }
        }
        break;
      case APINames.API_queryItem:
        ////console.log('成功', JSON.parse(res.Data), tag);
        this.setState({ item: JSON.parse(res.Data) });
        break;
      case APINames.API_getStoreRole:
        hideLoadding();
        ////console.log('岗位列表',res,tag)
        var arr = [];
        var num = 1;
        res.forEach(item => {
          item.key = num;
          num++;
          item._flag = true;
          item._CardBasis = 3;
          arr.push({
            Id: 0,
            CardAwardId: 0,
            PostId: item.RoleId,
            CardValue: item.CardRate,
            CardBasis: item._CardBasis,
            ChargeValue: item.ChargeRate,
            ChargeBasis: 3
          });
        });
        ////console.log('555',arr,res)
        this.setState({ visible3: true, postList: res, postRateList: arr });
        break;
      case APINames.API_checkCardIsUsed:
        ////console.log('判断会员卡',res,tag);
        if (res.ErrCode == 0) {
          this.setState({ disableAll: false });
        }
        break;
      case APINames.API_getMchCard:
        this.state.postRateList = res.sCardTypeAward.sCardTypeAwardPost;
        this.state.editRecord = res;
        if (res.sCardTypeAward.CardSettingsBasis == 2) {
          this.setState({ kkDisabled: false });
        } else {
          this.setState({ kkDisabled: true });
        }
        if (res.sCardTypeAward.ChargeSettingsBasis == 2) {
          this.setState({ czDisabled: false });
        } else {
          this.setState({ czDisabled: true });
        }
        if (res.CardFlag == 2) {
          this.setState({ isStorage: false });
        } else {
          this.setState({ isStorage: true });
        }
        if (res.SalePrice == 0) {
          this.setState({ defaultValue: 1, disabled: true });
        } else {
          this.setState({ defaultValue: 2, disabled: false });
        }
        res.sCardTypeAward._CardValue = numberConvert(res.sCardTypeAward.CardValue)
        res.sCardTypeAward._ChargeValue = numberConvert(res.sCardTypeAward.ChargeValue)
        res._SalePrice = numberConvert(res.SalePrice)
        this.setState({
          postRateList: res.sCardTypeAward.sCardTypeAwardPost,
          editRecord: res,
          modalData: res,
          tcFlag: res.sCardTypeAward.CardBasis
        });
        if (res.Rate) {
          this.setState({ PromBy: res.Rate.PromBy });
        }
        this.requestStoreRole2();
        break;
      case APINames.API_enabledMchManagerCard:
        hideLoadding();
        this.requestCardType(1);
        //console.log("启用或者停用", res);
        break;
      case APINames.API_deleteMchManagerCard:
        hideLoadding();
        this.requestCardType(1);
        break;
      case APINames.API_getMchManagerCard:
        console.log('明细',res)
        hideLoadding();
        // 1使用 2未使用
        if (res.IsUsed == 1) {
          this.setState({ disableAll: true })
        }
        // this.state.postRateList = res.sCardTypeAward.sCardTypeAwardPost;
        this.state.editRecord = res;
        if (res.sCardTypeAward.CardSettingsBasis == 2) {
          this.setState({ kkDisabled: false });
        } else {
          this.setState({ kkDisabled: true });
        }
        if (res.sCardTypeAward.ChargeSettingsBasis == 2) {
          this.setState({ czDisabled: false });
        } else {
          this.setState({ czDisabled: true });
        }
        if (res.ExpiresDay == 0) {
          this.state.RadioState = 0;
          this.state.ExpiresDay = res.ExpiresDay;
          this.state.disabled = true
      } else {
          this.state.RadioState = 1;
          this.state.ExpiresDay = res.ExpiresDay;
          this.state.disabled = false
      }
        if (res.CardFlag == 2) {
          this.setState({ isStorage: false });
        } else {
          this.setState({ isStorage: true });
        }
        if (res.SalePrice == 0) {
          this.setState({ defaultValue: 1, disabled: true });
        } else {
          this.setState({ defaultValue: 2, disabled: false });
        }
        res._SalePrice = numberConvert(res.SalePrice)
        res.sCardTypeAward._CardValue = res.sCardTypeAward.CardValue / 100
        res.sCardTypeAward._ChargeValue = res.sCardTypeAward.ChargeValue / 100
        this.setState({
          postRateList: res.sCardTypeAward.sCardTypeAwardPost,
          editRecord: res,
          modalData: res,
          tcFlag: res.sCardTypeAward.CardBasis,
          visible3: true
        });
        if (res.Rate) {
          this.setState({ PromBy: res.Rate.PromBy });
        }
        //console.log(780780, res);
        break;
    }
  }

  httpFail(res, tag) {
    this.setState({ loading: false });
    hideLoadding();
    utils.setReqeustState(true);
    ////console.log("失败", res, tag)
    switch (tag) {
      case APINames.API_saveMchCard:
        showMessageError(res, tag);
        ////console.log('新增会员卡失败后返回的数据', res, tag);
        if (slidingKey == 2) {
          this.requestCardType(2);
          slidingKey = 1;
        } else {
          this.requestCardType(1);
        }
        break;
      case APINames.API_checkCardIsUsed:
        ////console.log('判断失败',res,tag)
        if (res.ErrCode) {
          this.setState({ disableAll: true });
        }
        break;
      case APINames.API_deleteMchManagerCard:
        showMessageError(res, tag);
        break;
      default:
        showMessageError(res, tag);
    }
  }

  componentWillMount() {
    this.props.setDefaultTitle({
      titleKey: FnMenuId.head_chain_setting,
      slidingLeftKey: "4"
    });
  }

  /**
   * 编辑会员卡类型
   */
  editVipType(record) {
    this.setState({ title: "编辑卡类型" });
    this.getMchManagerCard(record.Id);
    // this.requsetAPI_getMchCard(record.Id);
    // this.API_checkCardIsUsed(record.MchId, record.Id);
  }

  /**
   * 删除——对话框
   */
  delete(record, num) {
    ////console.log("record", record)
    if (num == 1) {
      this.setState({ cardKind: "会员卡" });
    } else {
      this.setState({ cardKind: "套餐卡" });
    }
    // this.state.isdelete=record;
    this.setState({
      visible2: true,
      vipName: record.CardName,
      isDelete: record
    });
  }

  onClickListener(tag, data) {
    ////console.log(tag, data);
    switch (tag) {
      /**
       * 确认删除
       */
      case "delete_confirm":
        this.setState({ visible2: false });
        this.requestDeleteMchCard(this.state.isDelete.Id);
        break;
      case "addVipCard":
        // this.requestStoreRole();
        this.setState({
          title: "添加卡类型",
          disableAll: false,
          editRecord: {},
          visible3: true,
          isStorage: true,
          PromBy: 2,
          editRecord: this.initCard(),
          disabled: true,
          modalData: this.initCard()
        });
        break;
      case "addSecondCard":
        this.setState({
          visible4: true,
          secondTitle: "新增套餐卡类型",
          itemData: [],
          CardName: "",
          RadioState: 0,
          ExpiresDay: 0,
          openCard: {}
        });
        break;
      case "drawer-ok":
        if (this.state.modalData.CardName == "") {
          messageError("请输入会员卡名称");
          return;
        }
        console.log('数据',this.state.modalData)
        let cardFlag = parseInt(this.state.modalData.CardFlag);
        let chargeOn = (cardFlag & ECardFlag.Charge) == ECardFlag.Charge;
        // if (chargeOn && !this.state.modalData.SalePrice) {
        //     messageError('请输入开卡金额');
        //     return
        // }
        if (this.state.modalData.Rate.PromDiscount == 0) {
          this.state.modalData.Rate.PromDiscount = 1000;
        }
        if (!this.state.modalData.SalePrice) {
          messageError("请输入正确的开卡金额");
          return;
        }
        this.state.modalData.sCardTypeAward.sCardTypeAwardPost = this.state.postRateList;
        this.state.modalData.ExpiresDay=parseInt(this.state.ExpiresDay)
        ////console.log('2222222',this.state.postRateList);
        //防止暴力点击
        if (!utils.getReqeustState()) {
          return;
        }
        utils.setReqeustState(false);
        // //console.log(this.state.modalData)
        this.requestSaveMchCard(this.state.modalData);
        this.setState({ visible3: false, disableAll: false });
        break;
      case "editMemberCardConfirm":
        ////console.log(this.state.editRecord);
        this.requestSaveMchCard(this.state.editRecord);
        this.setState({ visible: false });
        break;
      case "clickItem":
        var isPush = true;
        this.state.itemData.every(item => {
          if (item.ItemId == data.ItemId) {
            data = item;
            isPush = false;
            return false;
          }
          return true;
        });
        if (isPush) {
          data.Quota = 1;
          data.UnitPrice = data.Price;
          data.FinalPrice = data.Price;
          data.TotalAmount = data.Price;
          this.state.itemData.push(data);
        } else {
          data.Quota = data.Quota + 1;
          data.TotalAmount = data.FinalPrice * data.Quota;
          data.FinalPrice = data.TotalAmount / data.Quota;
        }

        this.setState({ itemData: this.state.itemData });
        break;
      case "API_deleteItem":
        ////console.log(data);
        var arr = this.state.itemData;
        arr.splice(arr.indexOf(data), 1);
        this.setState({ itemData: arr });
        break;
      case "drawer-cancel":
        this.setState({
          visible3: false,
          kkDisabled: false,
          czDisabled: false,
          disableAll: false
        });
        break;
      case "hideVisible":
        this.setState({ visible: false });
        this.requestCardType(1);
        break;
      case "hideVisible4":
        this.setState({ visible4: false });
        this.requestCardType(2);
        break;
      case "添加套餐卡确定":
        ////console.log(this.state.ExpiresDay)
        if (this.state.CardName == undefined || this.state.CardName == "") {
          messageError("请输入名称");
          return;
        }
        if (this.state.RadioState != 1) {
          if (
            this.state.ExpiresDay == undefined ||
            this.state.ExpiresDay == "" ||
            this.state.ExpiresDay == "0"
          ) {
            messageError("请输入有效期");
            return;
          } else {
            this.state.openCard.ExpiresDay = parseInt(this.state.ExpiresDay);
          }
        } else {
          this.state.openCard.ExpiresDay = 0;
        }
        if (this.state.itemData.length == 0) {
          messageError("请添加套餐卡项目");
          return;
        }
        // this.state.openCard.Id=0;
        this.state.openCard.CardName = this.state.CardName;
        this.state.openCard.CardKind = ECardKind.TimeCard;
        this.state.openCard.Items = this.state.itemData;
        this.state.openCard.SalePrice = 套餐卡售价;
        slidingKey = 2;
        ////console.log('数据结构',this.state.openCard)
        this.requestSaveMchCard(this.state.openCard);
        this.setState({ visible4: false });
        break;
      case "套餐卡编辑":
        this.state.openCard = data;
        this.state.itemData = [];
        this.setState({ secondTitle: "编辑套餐卡" });
        data.Items.forEach(item => {
          item.isEdit = true;
          this.state.item.every(i => {
            if (item.ItemId == i.ItemId) {
              item.isEdit = false;
              return false;
            }
            return true;
          });
          if (item.isEdit) {
            this.state.cardItem.every(c => {
              if (item.ItemId == c.ItemId) {
                item.isEdit = false;
                return false;
              }
              return true;
            });
          }

          item.Title = item.ItemTitle;
          this.state.itemData.push(item);
        });
        if (data.ExpiresDay == 0) {
          this.state.RadioState = 0;
          this.state.ExpiresDay = data.ExpiresDay;
          this.state.disabled = true;
        } else {
          this.state.RadioState = 1;
          this.state.ExpiresDay = data.ExpiresDay;
          this.state.disabled = false;
        }
        this.setState({
          itemData: this.state.itemData,
          CardName: data.CardName
        });
        this.setState({ visible4: true });
        break;
      case "开卡金额聚焦":
        data.target.value = "";
        break;
      case "折扣聚焦":
        data.target.value = "";
    }
  }

  changeListener(tag, e, event) {
    let data = this.state.modalData;
    let recordData = this.state.editRecord;
    switch (tag) {
      case "all":
        this.setState({ isShowAll: !this.state.isShowAll });
        break;
      case "all2":
        this.setState({ isShowAll2: !this.state.isShowAll2 });
        break;
      case "CardName":
        var text = e.target.value;
        text = text.replace(/[\@\%\#\$\^\&\*\(\)\{\}\:\"\<\>\?\[\]]/, '');
        // e.target.value = e.target.value.replace(/[^\u4E00-\u9FA5\uF900-\uFA2D\d]/g, "");  //清除“数字”和“.”以外的字符
        // e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
        // text = text.replace(/[\@\#\$\%\^\&\*\(\)\{\}\:\"\<\>\?\[\]\!]/, "");
        // text = text.replace(/[\+]/, "＋");
        data.CardName = text;
        this.state.editRecord.CardName = text;
        ////console.log(e.target.value)
        break;
      case "SalePrice":
        maxNum = parseInt(e);
        data.SalePrice = parseIntX100(moneyConvert(e));
        recordData._SalePrice = e;
        break;
      case "PromBy":
        ////console.log(parseInt(e))
        data.Rate.PromBy = parseInt(e);
        if (parseInt(e) == 2) {
          data.CardFlag =
            ECardFlag.StoredValue | ECardFlag.Discount | ECardFlag.Charge;
        } else {
          data.CardFlag = ECardFlag.StoredValue | ECardFlag.Charge;
        }
        this.setState({ PromBy: parseInt(e) });
        break;
      case "PromDiscount":
        e.target.value = e.target.value.replace(/[^0-9/.]/g, "");
        e.target.value = e.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        e.target.value = e.target.value
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        e.target.value = e.target.value.replace(
          /^(\-)*(\d+)\.(\d\d).*$/,
          "$1$2.$3"
        ); //只能输入两个小数
        if (e.target.value >= 10) {
          e.target.value = 10;
        }
        data.Rate.PromDiscount = parseIntX100(moneyConvert(e.target.value));
        ////console.log('折扣', parseFloat(e.target.value))
        break;
      case "PromBonus":
        //console.log(117, money_max);
        if (e.target.value > money_max) {
          e.target.value = money_max;
        }
        e.target.value = e.target.value.replace(/[^0-9/.]/g, "");
        data.Rate.PromBonus = parseIntX100(moneyConvert(e.target.value));
        this.state.editRecord.Rate.PromBonus = parseIntX100(
          moneyConvert(e.target.value)
        );
        // data.Rate.PromBonus = moneyConvert(e.target.value);
        ////console.log('---',data.Rate.PromBonus)
        break;
      case "editCardName":
        recordData.CardName = e.trim();
        break;
      case "editSalePrice":
        if (parseInt(e.target.value) > money_max) {
          e.target.value = money_max;
        }
        e.target.value = e.target.value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
        e.target.value = e.target.value.replace(
          /^(\-)*(\d+)\.(\d\d).*$/,
          "$1$2.$3"
        ); //只能输入两个小数
        recordData.SalePrice = parseIntX100(moneyConvert(e.target.value));
        break;
      case "editPromDiscount":
        ////console.log(e.target.value)
        e.target.value = e.target.value.replace(/[^0-9/.]/g, "");
        e.target.value = e.target.value.replace(
          /^(\-)*(\d+)\.(\d\d).*$/,
          "$1$2.$3"
        ); //只能输入两个小数
        if (e.target.value >= 10) {
          e.target.value = 10;
        }

        recordData.Rate.PromDiscount = parseIntX100(
          moneyConvert(e.target.value)
        );
        break;
      case "editPromBonus":
        if (e.target.value > money_max) {
          e.target.value = money_max;
        }
        e.target.value = e.target.value.replace(/[^0-9/.]/g, "");
        recordData.Rate.PromBonus = parseIntX100(e.target.value);
        break;
      case "changeItem":
        // ////console.log(e);
        // this.requestQueryItem(parseInt(e));
        break;
      case "changeCount":
        e.Quota = parseInt(numConvert(event.target.value));
        if (e.Quota == "" || e.Quota < 1) {
          e.Quota = 1;
        }
        if (e.FinalPrice == NaN) {
          e.FinalPrice = e.UnitPrice;
        }
        e.TotalAmount = e.FinalPrice * e.Quota;
        e.FinalPrice = parseInt(e.TotalAmount / e.Quota);
        this.setState(this);
        break;
      case "changeAmount":
        e.TotalAmount = moneyConvert(event.target.value);
        e.TotalAmount = parseIntX100(event.target.value);
        e.FinalPrice = parseInt(e.TotalAmount / e.Quota);
        this.setState(this);
        break;
      case "套餐卡名称":
        this.setState({ CardName: e.trim() });
        break;
      case "有效期":
        e.target.value = e.target.value.replace(/[^\d]/g, "");  //清除“数字”和“.”以外的字符
        e.target.value = moneyConvert(e.target.value);
        this.setState({ ExpiresDay: e.target.value });
        break;
      case "改变搜索框内容会员卡":
        this.state.keyword = e.target.value;
        this.setState({ keyword: this.state.keyword });
        this.requestCardType(1);
        this.break;
      case "改变搜索框内容套餐卡":
        if (e.target.value == "") {
          this.requestCardType(2);
        }
        break;
      case "搜索服务":
        if (e.target.value == "") {
          this.requestQueryItem();
        }
        break;
      case "搜索商品":
        if (e.target.value == "") {
          // this.requestQueryCardItem();
        }
        break;
      case "百分比金额":
        data.sCardTypeAward.CardBasis = parseInt(e.target.value);
        recordData.sCardTypeAward.CardValue = 0;
        this.setState({ tcFlag: e.target.value });
        break;
      case "开卡提成复选框":
        if (e.target.checked) {
          data.sCardTypeAward.CardSettingsBasis = 3;
        } else {
          data.sCardTypeAward.CardSettingsBasis = 2;
        }
        this.setState({ kkDisabled: e.target.checked });
        break;
      case "充值提成复选框":
        if (e.target.checked) {
          data.sCardTypeAward.ChargeSettingsBasis = 3;
        } else {
          data.sCardTypeAward.ChargeSettingsBasis = 2;
        }
        this.setState({ czDisabled: e.target.checked });
        break;
      case "列表百分比金额":
        //console.log(event.target.value, e, this.state.postList);
        this.state.postRateList.forEach(item => {
          if (item.PostId == e.RoleId) {
            item.CardBasis = parseInt(event.target.value);
          }
        });

        this.state.postList.forEach(item => {
          if (item.RoleId == e.RoleId) {
            if (event.target.value == 3) {
              item._flag = true;
              if (item.CardRate > 10000) {
                item.CardRate = 10000;
              }
            } else {
              item._flag = false;
            }
            item._CardBasis = parseInt(event.target.value);
          }
        });
        this.setState({
          postList: this.state.postList,
          postRateList: this.state.postRateList
        });
        break;
      case "开卡提成值":
        if (isNaN(e.target.value)) {
          return;
        }
        e.target.value = e.target.value.replace(/\.{2,}/g, ".");
        e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
        if (this.state.tcFlag == 3) {
          if (e.target.value >= 100) {
            e.target.value = 100
          }
        } else {
          if (e.target.value > data.SalePrice) {
            e.target.value = numberConvert(data.SalePrice)
          }
          if (e.target.value >= maxNum) {
            e.target.value = maxNum
          }
        }
        // e.target.value = e.target.value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
        // e.target.value = e.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        // e.target.value = e.target.value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        // e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
        // ////console.log(e.target.value)
        // if (this.state.tcFlag == 3) {
        //     if (e.target.value >= 100) {
        //         e.target.value = 100
        //     }
        // } else {
        //     if (e.target.value > data.SalePrice) {
        //         e.target.value = numberConvert(data.SalePrice)
        //     }
        //     if (e.target.value >= maxNum) {
        //         e.target.value = maxNum
        //     }
        // }
        data.sCardTypeAward.CardValue = numberConvert_100(e.target.value);
        recordData.sCardTypeAward.CardValue = numberConvert_100(e.target.value.trim());
        recordData.sCardTypeAward._CardValue = (e.target.value.trim());
        break;
      case "充值提成值":
        if (isNaN(e.target.value)) {
          return;
        }
        e.target.value = e.target.value.replace(/\.{2,}/g, ".");
        e.target.value = e.target.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

        //console.log(e.target.value)
        if (e.target.value >= 100) {
          e.target.value = 100;
        }
        data.sCardTypeAward.ChargeValue = numberConvert_100(e.target.value.trim());
        recordData.sCardTypeAward.ChargeValue = numberConvert_100(
          e.target.value.trim()
        );
        recordData.sCardTypeAward._ChargeValue = e.target.value.trim()


        break;
      case "岗位开卡提成值":
        event.target.value = event.target.value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
        event.target.value = event.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
        event.target.value = event.target.value
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        event.target.value = event.target.value.replace(
          /^(\-)*(\d+)\.(\d\d).*$/,
          "$1$2.$3"
        ); //只能输入两个小数
        //console.log(event.target.value);
        if (e._flag == true) {
          if (event.target.value >= 100) {
            event.target.value = 100;
          }
        } else {
          if (event.target.value > data.SalePrice) {
            event.target.value = numberConvert(data.SalePrice);
          }
          if (event.target.value >= maxNum) {
            event.target.value = maxNum;
          }
        }
        this.state.postList.forEach(item => {
          if (item.RoleId == e.RoleId) {
            item.CardRate = numberConvert_100(event.target.value);
            // if(item.CardRate>10000){
            //     item.CardRate=10000
            // }
          }
        });
        this.state.postRateList.forEach(item => {
          if (item.PostId == e.RoleId) {
            item.CardValue = numberConvert_100(event.target.value);
          }
        });
        ////console.log('3333',this.state.postRateList)
        this.setState({ postRateList: this.state.postRateList });
        break;
      case "岗位充值提成值":
        //console.log(222, event.target.value);
        event.target.value = event.target.value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
        event.target.value = event.target.value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的.
        event.target.value = event.target.value
          .replace(".", "$#$")
          .replace(/\./g, "")
          .replace("$#$", ".");
        event.target.value = event.target.value.replace(
          /^(\-)*(\d+)\.(\d\d).*$/,
          "$1$2.$3"
        ); //只能输入两个小数
        if (event.target.value > 100) {
          event.target.value = 100;
        }
        this.state.postList.forEach(item => {
          if (item.RoleId == e.RoleId) {
            item.ChargeRate = numberConvert_100(event.target.value);
          }
        });
        this.state.postRateList.forEach(item => {
          if (item.PostId == e.RoleId) {
            item.ChargeValue = numberConvert_100(event.target.value);
          }
        });
        this.setState({
          postRateList: this.state.postRateList,
          postList: this.state.postList
        });
        break;
    }
    this.setState({ modalData: data, editRecord: recordData });
  }

  /**
   * 取消操作
   */
  handelCancel() {
    this.setState({
      visible: false,
      visible2: false,
      visible3: false,
      visible4: false,
      openCard: {},
      modalData: this.initCard()
    });
  }

  /**
   * 会员卡套餐卡切换时回调
   */
  changeCardType(key) {
    ////console.log(key);
    if (key == "1") {
      this.setState({ isVip: true });
    } else {
      this.setState({ isVip: false });
    }
    this.requestCardType(key);
  }

  /**
   * 改变优惠类型回调
   */
  changeDiscount(value) {
    ////console.log(`selected ${value}`);
  }

  /**
   * 是否储值按钮事件
   */
  onChangeSwitch(checked) {
    var data = this.state.modalData;
    ////console.log(checked);
    if (checked == true) {
      data.CardFlag =
        ECardFlag.StoredValue | ECardFlag.Discount | ECardFlag.Charge;
    } else {
      data.CardFlag = ECardFlag.Discount;
      data.Rate.PromBy = 2;
    }
    this.setState({ isStorage: checked, modalData: data, PromBy: 2 });
  }

  /**
   * 改变文本框状态
   */
  changeRadio(e) {
    var data = this.state.editRecord;
    if (e.target.value == 0) {
      this.setState({ disabled: true, editRecord: data, ExpiresDay: 0 });
    } else {
      this.setState({ disabled: false, ExpiresDay: this.state.editRecord.ExpiresDay })
    }
    this.setState({ RadioState: e.target.value })
  }

  blur(e) {
    if (e.target.value < 0) {
      messageError("开卡金额不能小于0！");
    }
  }

  search1(e) {
    ////console.log(this.state.data)
    var data = [];
    this.state.data.forEach(item => {
      for (var i = 0; i < item.ShortLetter.length; i++) {
        // ////console.log(item.ShortLetter[i])
        if (
          item.CardName == e ||
          item.CardName.indexOf(e) != -1 ||
          item.ShortLetter.indexOf(e) != -1 ||
          item.ShortLetter.indexOf(e.toUpperCase()) != -1 ||
          item.ShortLetter[0].toLowerCase() == e ||
          item.ShortLetter[0] == e ||
          item.ShortLetter[i] == e
        ) {
          data.push(item);
        }
      }
    });
    this.setState({ data: [...new Set(data)] });
  }

  search2(e) {
    var data = [];
    this.state.data2.forEach(item => {
      for (var i = 0; i < item.ShortLetter.length; i++) {
        // ////console.log(item.ShortLetter[i])
        if (
          item.CardName == e ||
          item.CardName.indexOf(e) != -1 ||
          item.ShortLetter.indexOf(e) != -1 ||
          item.ShortLetter.indexOf(e.toUpperCase()) != -1 ||
          item.ShortLetter[0].toLowerCase() == e ||
          item.ShortLetter[0] == e ||
          item.ShortLetter[i] == e
        ) {
          data.push(item);
        }
      }
    });
    this.setState({ data2: [...new Set(data)] });
  }

  search3(e) {
    var data = [];
    this.state.item.forEach(item => {
      for (var i = 0; i < item.ShortLetter.length; i++) {
        // ////console.log(item.ShortLetter[i])
        if (
          item.Title == e ||
          item.Title.indexOf(e) != -1 ||
          item.ShortLetter.indexOf(e) != -1 ||
          item.ShortLetter.indexOf(e.toUpperCase()) != -1 ||
          item.ShortLetter[0].toLowerCase() == e ||
          item.ShortLetter[0] == e ||
          item.ShortLetter[i] == e
        ) {
          data.push(item);
        }
      }
    });
    this.setState({ item: [...new Set(data)] });
  }

  search4(e) {
    var data = [];
    this.state.cardItem.forEach(item => {
      for (var i = 0; i < item.ShortLetter.length; i++) {
        // ////console.log(item.ShortLetter[i])
        if (
          item.Title == e ||
          item.Title.indexOf(e) != -1 ||
          item.ShortLetter.indexOf(e) != -1 ||
          item.ShortLetter.indexOf(e.toUpperCase()) != -1 ||
          item.ShortLetter[0].toLowerCase() == e ||
          item.ShortLetter[0] == e ||
          item.ShortLetter[i] == e
        ) {
          data.push(item);
        }
      }
    });
    this.setState({ cardItem: [...new Set(data)] });
  }

  Callback(start, end) {
    begin = start;
    over = end;
    if (this.state.isVip == true) {
      this.requestCardType(1);
    } else {
      this.requestCardType(2);
    }
  }

  getItemView() {
    if (this.state.isShowAll) {
      return this.state.postList;
    } else {
      var temp = [];
      this.state.postList.forEach((item, index) => {
        if (index < 5) {
          temp.push(item);
        }
      });
      return temp;
    }
  }

  getItemView2() {
    if (this.state.isShowAll2) {
      return this.state.postList;
    } else {
      var temp = [];
      this.state.postList.forEach((item, index) => {
        if (index < 5) {
          temp.push(item);
        }
      });
      return temp;
    }
  }
  changeSelect = value => {
    switch (value) {
      case "all":
        this.state.storedValue = 0;
        break;
      case "on":
        this.state.storedValue = 1;
        break;
      case "leave":
        this.state.storedValue = 2;
        break;
    }
    this.setState({ storedValue: this.state.storedValue });
    this.requestCardType(1);
  };
  changeSelectStatus(value) {
    switch (value) {
      case "all":
        this.state.Enabled = 0;
        break;
      case "on":
        this.state.Enabled = 1;
        break;
      case "leave":
        this.state.Enabled = 2;
        break;
    }
    this.setState({ Enabled: this.state.Enabled });
    this.requestCardType(1);
  }

  render() {
    const { loading } = this.state;
    const List = this.getItemView();
    const List2 = this.getItemView2();
    const div = (
      <div>
        <div style={{ textAlign: "left" }}>
          <Form>
            <div style={{ marginTop: 20 }}>
              <FormItem {...formItemLayout} required={true} label="是否储值">
                <Switch
                  disabled={this.state.disableAll}
                  defaultChecked={this.state.isStorage}
                  onChange={this.onChangeSwitch.bind(this)}
                />
              </FormItem>
            </div>
            <FormItem {...formItemLayout} required={true} label="卡名称">
              <Input
                disabled={this.state.disableAll}
                placeholder="会员卡名称，限20字符"
                maxLength="20"
                value={this.state.editRecord.CardName}
                onChange={this.changeListener.bind(this, "CardName")}
              />
            </FormItem>
            <FormItem
              {...formItemLayout}
              required={true}
              label="有效期">
              <RadioGroup disabled={this.state.disableAll} value={this.state.RadioState}
                onChange={this.changeRadio.bind(this)}
              >
                <Radio style={{ marginRight: 38 }} value={0}>永久有效</Radio>
                <Radio value={1}>办卡起</Radio>
              </RadioGroup>
              <Input disabled={this.state.disableAll ? true : this.state.disabled} defaultValue='0'
                onChange={this.changeListener.bind(this, "有效期")}
                value={this.state.ExpiresDay} style={{ width: 70, marginRight: 10 }} />
              <span>日有效</span>
            </FormItem>
            <FormItem {...formItemLayout} required={true} label="开卡金额">
              <InputMoney disabled={this.state.disableAll} placeholder="请输入金额"
                minLength='1'
                value={(this.state.editRecord._SalePrice) ? (this.state.editRecord._SalePrice) : ''}
                onChange={this.changeListener.bind(this, 'SalePrice')} />
            </FormItem>
            <div
              style={{
                fontSize: 14,
                color: "#9b9b9b",
                marginTop: -20,
                marginLeft: 100,
                marginBottom: 20
              }}
            >
              该卡初始充值金额 (身份卡即为这张卡的售价)
            </div>

            <FormItem {...formItemLayout} label="优惠类型">
              {this.state.isStorage ? (
                <Select
                  disabled={this.state.disableAll}
                  style={{ width: 140 }}
                  value={this.state.PromBy}
                  onChange={this.changeListener.bind(this, "PromBy")}
                >
                  <Option value={2}>开卡享折扣</Option>
                  <Option value={3}>开卡送礼金</Option>
                </Select>
              ) : (
                  <div style={{ display: "inline", marginRight: 70 }}>
                    开卡享折扣
                </div>
                )}

              {this.state.PromBy != 3 ? (
                <div style={{ display: "inline" }}>
                  <Input
                    key={1}
                    defaultValue="0"
                    disabled={this.state.disableAll}
                    defaultValue={
                      this.state.editRecord.Rate
                        ? numberConvert(this.state.editRecord.Rate.PromDiscount)
                        : ""
                    }
                    onFocus={this.onClickListener.bind(this, "折扣聚焦")}
                    style={{
                      width: 100,
                      marginLeft: 10,
                      marginRight: 10
                    }}
                    onChange={this.changeListener.bind(this, "PromDiscount")}
                  />
                  折
                </div>
              ) : (
                  <div style={{ display: "inline" }}>
                    <Input
                      key={2}
                      defaultValue="0"
                      disabled={this.state.disableAll}
                      defaultValue={
                        this.state.editRecord.Rate
                          ? numberConvert(this.state.editRecord.Rate.PromBonus)
                          : ""
                      }
                      onFocus={e => {
                        e.target.value = "";
                      }}
                      style={{ width: 100, marginLeft: 10, marginRight: 10 }}
                      onChange={this.changeListener.bind(this, "PromBonus")}
                    />
                    元
                </div>
                )}
            </FormItem>
            <FormItem {...formItemLayout} label="开卡提成">
              <RadioGroup
                disabled={this.state.kkDisabled}
                defaultValue={
                  this.state.editRecord.sCardTypeAward
                    ? this.state.editRecord.sCardTypeAward.CardBasis
                    : 3
                }
                onChange={this.changeListener.bind(this, "百分比金额")}
              >
                <Radio value={3}>百分比</Radio>
                <Radio value={2}>金额</Radio>
              </RadioGroup>
              <div style={{ display: "inline" }}>
                <Input
                  maxLength={8}
                  onChange={this.changeListener.bind(this, '开卡提成值')}
                  disabled={this.state.kkDisabled}
                  value={this.state.editRecord.sCardTypeAward._CardValue ? (this.state.editRecord.sCardTypeAward._CardValue) : ''}
                  style={{ width: 70, marginLeft: 10, marginRight: 10 }} />
                {
                  this.state.tcFlag == 3 ? <span>%</span> : <span>元</span>
                }
              </div>

              <div></div>
            </FormItem>
            {this.state.isStorage ? (
              <FormItem {...formItemLayout} label="充值提成">
                <div>
                  <Input
                    maxLength={6}
                    onChange={this.changeListener.bind(this, "充值提成值")}
                    value={
                      this.state.editRecord.sCardTypeAward._ChargeValue
                        ?
                        this.state.editRecord.sCardTypeAward._ChargeValue

                        : ""
                    }
                    disabled={this.state.czDisabled}
                    style={{ width: 70, marginRight: 10 }}
                  />
                  <span>%</span>
                </div>

                <div></div>
              </FormItem>
            ) : null}
          </Form>
        </div>
      </div>
    );
    const { getFieldDecorator } = this.props.form;
    var arr = this.state.itemData;
    总次数 = 0;
    门市价 = 0;
    优惠 = 0;
    套餐卡售价 = 0;
    arr.forEach(item => {
      总次数 = 总次数 + parseInt(item.Quota);
      门市价 = 门市价 + (item.UnitPrice * item.Quota * 100) / 100;
      套餐卡售价 = 套餐卡售价 + (item.FinalPrice * item.Quota * 100) / 100;
    });
    优惠 = (门市价 * 100 - 套餐卡售价 * 100) / 100;
    return (
      <div
        class=" gra-border-pd15 vip-main-view"
        style={{ overflow: "hidden" }}
      >
        <div
          style={{
            marginBottom: 20,
            overflow: "hidden",
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{ display: "flex", alignItems: "center", marginRight: 25 }}
            >
              <div>
                <Select
                  style={{ width: 140, float: "left", zIndex: 999 }}
                  defaultValue="all"
                  onChange={this.changeSelect.bind(this)}
                >
                  <Option value="all">全部存储类型</Option>
                  <Option value="on">是</Option>
                  <Option value="leave">否</Option>
                </Select>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Select
                  style={{ width: 100, float: "left", zIndex: 999 }}
                  defaultValue="all"
                  onChange={this.changeSelectStatus.bind(this)}
                >
                  <Option value="all">全部状态</Option>
                  <Option value="on">已启用</Option>
                  <Option value="leave">已停用</Option>
                </Select>
              </div>
            </div>
          </div>
          <div>
            <Search
              placeholder="名称或首字母查询"
              enterButton
              onChange={this.changeListener.bind(this, "改变搜索框内容会员卡")}
              // onSearch={this.search1.bind(this)}
              style={{ width: 200, float: "left" }}
            />
            <Button
              type="primary"
              style={{ marginLeft: 15 }}
              onClick={this.onClickListener.bind(this, "addVipCard")}
            >
              <span style={{ color: "#fff" }}>添加会员卡类型</span>
            </Button>
          </div>
        </div>
        {this.state.isVip ? (
          <div
            className="vip-main-view-content"
            style={{ left: 15, right: 15, top: 60 }}
          >
            <ITablePage
              scroll={{ x: "100%", y: "62vh" }}
              pageCount={this.state.vipCount}
              onTablePageCallback={this.Callback.bind(this)}
              columns={this.state.columns}
              data={this.state.data}
            />
          </div>
        ) : (
            <div
              className="vip-main-view-content"
              style={{ left: 15, right: 15, height: "500px" }}
            >
              <ITablePage
                scroll={{ x: "100%", y: "62vh" }}
                pageCount={this.state.vipCount}
                onTablePageCallback={this.Callback.bind(this)}
                columns={this.state.columns2}
                data={this.state.data2}
              />
            </div>
          )}

        <IModal
          title="提示"
          visible={this.state.visible2}
          footer={null}
          onCancel={this.handelCancel.bind(this)}
          view={
            <div>
              <p>
                确定删除{this.state.cardKind}类型：
                <span>{this.state.vipName}</span>
              </p>
              <div style={{ textAlign: "right", marginTop: 20 }}>
                <Button
                  style={{ marginRight: 20 }}
                  onClick={this.handelCancel.bind(this)}
                >
                  取消
                </Button>
                <IButton
                  type="primary"
                  text="确定"
                  onClick={this.onClickListener.bind(this, "delete_confirm")}
                ></IButton>
              </div>
            </div>
          }
        ></IModal>

        <IDrawer
          title={this.state.title}
          footer={true}
          onClickListener={this.onClickListener.bind(this)}
          width={600}
          visible={this.state.visible3}
          div={div}
          loading={loading}
        />
      </div>
    );
  }
}

const vipSetting = Form.create()(vipSettingView);
export default vipSetting;
